import { queryString } from 'core/helpers'

export const getItemText = (QS) => {
  const qsVal = queryString.getQSValue(QS)

  if (!qsVal) return ''

  return qsVal
}

export const getItemArray = (QS) => {
  const qsVal = queryString.getQSValue(QS)

  if (!qsVal) return []

  if (qsVal instanceof Array) return qsVal
  else return [qsVal]
}
