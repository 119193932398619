/**
 * Make a string with keys and values for QueryString
 * @param {Object[]} qs
 * @param {string} qs[].key Query string key
 * @param {string} qs[].value Query string value
 * @returns {string} Returns a query string with keys and values
 */
const makeQueryString = (qs) => {
  let string = ''

  string = qs.map((item) => `${item.key}=${item.value}&`)

  return string.join().replace(/,/g, '')
}

export default makeQueryString
