import { memo, useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import * as _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { saveSvgAsPng } from 'save-svg-as-png'
import { useSnackbar } from 'notistack'

import Tooltip from '@material-ui/core/Tooltip'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'

import ReactWordcloud from 'react-wordcloud'

import GetDate from '../../functions/get-date'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import useStyles from './style'

export const NpsType = {
  Promoter: 'promoter',
  Neutral: 'neutral',
  Detractor: 'detractor'
}

const ColorsMap = {
  [NpsType.Promoter]: ['#60BB46', '#58B53F', '#4EAC37', '#44A42F', '#339620'],
  [NpsType.Neutral]: ['#F7A723', '#F69F1F', '#F5961A', '#F38C15', '#F17C0C'],
  [NpsType.Detractor]: ['#F58B88', '#F26561', '#EF3E39', '#EB302C', '#E41B17'],
  default: ['#7FD6F7', '#66CEF5', '#33BEF2', '#00AEEF']
}

const options = (type) => ({
  fontSizes: [15, 65],
  fontFamily: 'Roboto',
  colors: ColorsMap[type],
  enableOptimizations: true
})
const size = [700, 330]

function CloudGenerator({ data, type, t }) {
  const classes = useStyles()
  const wordcloudRef = useRef()

  const { enqueueSnackbar } = useSnackbar()

  const handleExportImage = () => {
    const nameMap = {
      [NpsType.Promoter]: t('word-cloud.file-name.promoter'),
      [NpsType.Neutral]: t('word-cloud.file-name.neutral'),
      [NpsType.Detractor]: t('word-cloud.file-name.detractor')
    }

    const svgElement = wordcloudRef.current.querySelector('svg')

    try {
      saveSvgAsPng(svgElement, `${nameMap[type]}__${GetDate}.png`)

      enqueueSnackbar(t('word-cloud.export.image.success'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    } catch (error) {
      enqueueSnackbar(t('word-cloud.export.error'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })
    }
  }

  const [showIcon, setShowIcon] = useState(false)
  const handleShowIcon = useCallback((value) => {
    setShowIcon(value)
  }, [])

  const memoizedWordcloud = useMemo(
    () => <ReactWordcloud size={size} words={data} options={options(type)} />,
    [data, type]
  )

  return (
    <div
      onMouseEnter={() => handleShowIcon(true)}
      onMouseLeave={() => handleShowIcon(false)}
      className={classes.container}
    >
      <div className={showIcon ? classes.icon : classes.hide}>
        <Tooltip title={t('uiActions.saveAsImage')}>
          <IconButton onClick={handleExportImage}>
            <GetAppIcon color="primary" />
          </IconButton>
        </Tooltip>
      </div>

      <div ref={wordcloudRef} className="cloud">
        {memoizedWordcloud}
      </div>
    </div>
  )
}

CloudGenerator.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string,
  reference: PropTypes.any
}

CloudGenerator.defaultProps = {
  type: 'default'
}

const CompareData = (prevProps, nextProps) => _.isEqual(prevProps, nextProps)

export default withTranslation()(memo(CloudGenerator, CompareData))
