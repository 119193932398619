import { withTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'

import useStyle from './style'

const ReadonlyField = ({ icon, text, handleClick, select, t }) => {
  const classes = useStyle()

  return (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.text} onClick={handleClick}>
        {`${text.from}`}
        {text.to && ` ${t('datepicker.to')} ${text.to}`}
      </div>

      <div className={classes.icon}>
        {icon && <IconButton onClick={handleClick}>{icon}</IconButton>}
      </div>
    </div>
  )
}
export default withTranslation()(ReadonlyField)
