import { withTranslation } from 'react-i18next'
import { Field } from 'formik'
import { FIELD_TYPE } from 'core/enums/field-type'
import { FIELD_TEMPLATE } from 'core/enums/field-template'
import { NPS_CLASSIFICATION } from 'core/enums/nps-classification'
import { nps } from 'core/helpers/index'
import TextFieldCustom from 'components/Form/Text/TextField'
import RadioboxGroup from 'components/Form/Box/RadioboxGroup'
import Radiobox from 'components/Form/Box/Radiobox'
import StarInput from 'components/Form/Box/StarInput'
import ColorScaleInput from 'components/Form/Box/ColorScaleRating'
import {
  FormSection,
  FormFieldItem,
  FormActions,
  FormField,
  Label
} from './style'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup
} from '@material-ui/core'
import 'style/route/write/main.css'
import { Fragment } from 'react'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'

const FormFields = ({ form, theme, formikProps, hideSubmitButton, t }) => {
  const checkDependency = (values, field) => {
    let response = false

    if (!field.FormFieldDependencies || field.FormFieldDependencies.length < 1)
      return response

    //Iteração dos campos
    for (let prop in values) {
      //Procura o campo no objeto de form que temos
      // eslint-disable-next-line no-loop-func
      const item = form.FormFields.find(
        (f) => f?.IdYourviews?.toString() === prop
      )

      //Valida se é multi ou single select ou rating
      if (
        item &&
        (item.Type === FIELD_TYPE.multiSelect ||
          item.Type === FIELD_TYPE.singleSelect ||
          item.Type === FIELD_TYPE.rating)
      ) {
        // Itera sobre as possiveis dependencias
        // eslint-disable-next-line no-loop-func
        field.FormFieldDependencies.forEach((single) => {
          if (values[prop]) {
            //Em caso de set multi select
            if (
              values[prop] instanceof Array &&
              values[prop].indexOf(
                single?.IdFormFieldValueDependancy?.toString()
              ) > -1
            )
              response = true

            //Em caso de ser single select
            if (values[prop] === single?.IdFormFieldValueDependancy?.toString())
              response = true

            //Em caso de ser rating
            let fieldValueDependencie = form.FormFields.find((f) => f.IdYourviews === parseInt(prop)).FormFieldValues[values[prop]-1]
            if (fieldValueDependencie?.IdYourviews === single?.IdFormFieldValueDependancy)
              response = true

            //Dou return para parar iteração
            if (response) return
          }
        })
      }
    }

    return response
  }

  const checkNPS = (values) => {
    const nps = values?.NPS

    if (isNaN(nps) || (parseInt(nps) < 0 || parseInt(nps) > 10)) {
      return false
    }

    return parseInt(nps)
  }

  const isWithinRange = (detractor, neutral, promoter, nps) => {
    if (
      (promoter && (nps === 9 || nps === 10)) ||
      (neutral && (nps === 7 || nps === 8)) ||
      (detractor && (nps >= 0 && nps <= 6))
    ) {
      return true
    }

    return false
  }

  const showCommentField = (values) => {
    const detractor = form.CommentVisibleDetractor
    const neutral = form.CommentVisibleNeutral
    const promoter = form.CommentVisiblePromoter
    const nps = checkNPS(values)

    if (isWithinRange(detractor, neutral, promoter, nps)) {
      return true
    }

    values.Comment = ''
    return false
  }

  const showCommentRequired = (values) => {
    const detractor = form.CommentRequiredDetractor
    const neutral = form.CommentRequiredNeutral
    const promoter = form.CommentRequiredPromoter
    const nps = checkNPS(values)

    return isWithinRange(detractor, neutral, promoter, nps)
  }

  const checkNPSDependency = (values, field) => {
    const { NpsClassificationDependancy } = field

    const npscd = NpsClassificationDependancy
    const val = values.NPS

    if (isNaN(val)) return false

    const nps = parseInt(val)

    if (!npscd || npscd.length < 1) {
      return false
    }

    if (nps < 7 && npscd.indexOf(NPS_CLASSIFICATION.detractor) > -1) {
      return true
    }

    if (nps < 9 && nps > 6 && npscd.indexOf(NPS_CLASSIFICATION.neutral) > -1) {
      return true
    }

    if (nps > 8 && npscd.indexOf(NPS_CLASSIFICATION.promoter) > -1) {
      return true
    }
  }

  const showFieldWithDependency = (field) => {
    const { NpsClassificationDependancy, FormFieldDependencies } = field

    const dependencyShouldBeEmpty =
      !FormFieldDependencies || FormFieldDependencies.length < 1

    const npsDependencyShouldBeEmpty =
      !NpsClassificationDependancy || NpsClassificationDependancy.length < 1

    const checkDep = checkDependency(formikProps.values, field)
    const checkNpsDep = checkNPSDependency(formikProps.values, field)

    return (
      (dependencyShouldBeEmpty && npsDependencyShouldBeEmpty) ||
      checkDep ||
      checkNpsDep
    )
  }

  const getRatingLabels = (fields) => {
    let ratingLabels = {}

    fields.forEach((value, idx) => {
      ratingLabels[idx + 1] = value.Value
    })

    return ratingLabels
  }

  const npsScoreComponent = () =>
    form.Type === 0 && (
      <FormSection>
        <Label htmlFor="NPS">
          {form.Title} {<span style={{ color: 'red' }}> *</span>}
        </Label>

        <FormField>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="gender"
              name="NPS"
              value={formikProps.values['NPS']}
              onChange={formikProps.handleChange}
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              {[...Array(11).keys()].map((item, i) => (
                <FormControlLabel
                  key={item}
                  label={item}
                  id={item?.toString()}
                  value={item?.toString()}
                  control={
                    <Radio
                      color="primary"
                      checkedIcon={
                        <RadioButtonChecked
                          style={{
                            margin: 0,
                            color: theme?.ThemeMainColor
                          }}
                        />
                      }
                    />
                  }
                  style={{ margin: 0 }}
                  data-testid={'NPS-' + item}
                  labelPlacement="bottom"
                />
              ))}
            </RadioGroup>
          </FormControl>
        </FormField>
      </FormSection>
    )

  const commentComponent = () =>
    form.Type === 0 && (
      <>
        {showCommentField(formikProps.values) && (
          <FormSection>
            <Label htmlFor="Comment">
              {nps.returnByInput(
                parseInt(formikProps.values.NPS),
                form.CommentTitlePromoter,
                form.CommentTitleNeutral,
                form.CommentTitleDetractor
              )}
              {showCommentRequired(formikProps.values) && <span style={{ color: 'red' }}> *</span>}
            </Label>

            <FormField>
              <Field name="Comment">
                {({ field }) => (
                  <TextField
                    rows={3}
                    multiline
                    {...field}
                    variant="filled"
                    data-testid={field.name}
                    helperText={formikProps.errors.Comment}
                    error={!!formikProps.errors.Comment}
                    style={{
                      height: 110,
                      width: '100%',
                      maxWidth: 400,
                      marginTop: 10
                    }}
                  />
                )}
              </Field>
            </FormField>
          </FormSection>
        )}
      </>
    )

  const customFieldsComponent = () =>
    form.FormFields.map((field, i) => (
      // Campos Customizados
      <Fragment key={field?.IdYourviews}>
        {showFieldWithDependency(field) && (
          <FormSection key={field?.IdYourviews}>
            <FormFieldItem key={field?.IdYourviews}>
              <>
                <Label htmlFor={field?.IdYourviews}>{field.Title}</Label>
                {field.Required && <span style={{ color: 'red' }}> *</span>}

                {/* Campo de Seleção Única */}
                {field.Type === FIELD_TYPE.singleSelect && (
                  <FormField
                    data-testid={'single-select-form-field-' + field?.IdYourviews}
                  >
                    <RadioboxGroup
                      inBlock
                      field={field}
                      onBlur={() => {}}
                      index={field?.IdYourviews}
                      data-testid={'radio-group-' + field?.IdYourviews}
                      isColorScale={field.Template === FIELD_TEMPLATE.singleSelectColors ? true : false}
                      touched={
                        formikProps.touched[field?.IdYourviews?.toString()]
                      }
                    >
                      {field.FormFieldValues.map((value, index) => (
                        <Field
                          label={value.Value}
                          component={Radiobox}
                          isColorScale={field.Template === FIELD_TEMPLATE.singleSelectColors ? true : false}
                          index={index}
                          themeMainColor={form?.Theme?.ThemeMainColor}
                          formField={field}
                          valuetype={value?.Type}
                          key={value?.IdYourviews}
                          bgcolor={theme?.ThemeMainColor}
                          id={value?.IdYourviews?.toString()}
                          name={field?.IdYourviews?.toString()}
                          data-testid={field?.IdYourviews?.toString()}
                          style={{ padding: '5px' }}
                        />
                      ))}
                    </RadioboxGroup>

                    {!!(
                      formikProps.errors[field?.IdYourviews?.toString()] &&
                      formikProps.touched[field?.IdYourviews?.toString()]
                    ) && (
                      <FormHelperText error>
                        {formikProps.errors[field?.IdYourviews?.toString()]}
                      </FormHelperText>
                    )}

                  </FormField>
                )}

                {/* Campo de Multi Seleção */}
                {field.Type === FIELD_TYPE.multiSelect && (
                  <FormField>
                    <FormControl
                      component="fieldset"
                      style={{ marginLeft: 8 }}
                      id={'group-' + field?.IdYourviews?.toString()}
                      value={
                        formikProps.values[field?.IdYourviews?.toString()]
                      }
                      data-testid={'group-' + field?.IdYourviews?.toString()}
                    >
                      {field.FormFieldValues.map((formField) => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start'
                          }}
                          key={formField?.IdYourviews}
                        >
                          <Field
                            id={field?.IdYourviews?.toString()}
                            name={field?.IdYourviews?.toString()}
                            value={formField?.IdYourviews?.toString()}
                            type="checkbox"
                            checked={formikProps.values[
                              field?.IdYourviews?.toString()
                            ]?.includes(formField?.IdYourviews?.toString())}
                            onChange={formikProps.handleChange}
                            color="primary"
                            component={Checkbox}
                            style={{ padding: '5px' }}
                          />{' '}
                          <span style={{ margin: 'auto 0' }}>
                            {formField.Value}
                          </span>
                        </div>
                      ))}
                    </FormControl>

                    {!!(
                      formikProps.errors[field?.IdYourviews?.toString()] &&
                      formikProps.touched[field?.IdYourviews?.toString()]
                    ) && (
                      <FormHelperText error>
                        {formikProps.errors[field?.IdYourviews?.toString()]}
                      </FormHelperText>
                    )}

                  </FormField>
                )}

                {/* Campo de Estrelas */}
                {field.Type === FIELD_TYPE.rating && (
                  <FormField>
                    {(field.Template === FIELD_TEMPLATE.ratingStars || field.Template === null) && (
                      <Field
                        component={StarInput}
                        color={theme?.ThemeMainColor}
                        id={field?.IdYourviews?.toString()}
                        name={field?.IdYourviews?.toString()}
                        data-testid={field?.IdYourviews?.toString()}
                        activeLabel={getRatingLabels(field.FormFieldValues)}
                      />
                    )}
                    
  
                    {field.Template === FIELD_TEMPLATE.ratingColors && (
                      <Field
                        component={ColorScaleInput}
                        color={theme?.ThemeMainColor}
                        id={field?.IdYourviews?.toString()}
                        name={field?.IdYourviews?.toString()}
                        data-testid={field?.IdYourviews?.toString()}
                        activeLabel={getRatingLabels(field.FormFieldValues)}
                      />
                    )}

                    {!!(
                      formikProps.errors[field?.IdYourviews?.toString()] &&
                      formikProps.touched[field?.IdYourviews?.toString()]
                    ) && (
                      <FormHelperText error>
                        {formikProps.errors[field?.IdYourviews?.toString()]}
                      </FormHelperText>
                    )}

                  </FormField>
                )}

                {/* Campo de Texto */}
                {field.Type === FIELD_TYPE.text && (
                  <FormField>
                    <Field name={field?.IdYourviews?.toString()}>
                      {({ field }) => (
                        <TextField
                          rows={3}
                          multiline
                          {...field}
                          variant="filled"
                          data-testid={field?.name}
                          style={{
                            width: '100%',
                            height: 110,
                            marginTop: 10
                          }}
                        />
                      )}
                    </Field>
                  
                    {!!(
                      formikProps.errors[field?.IdYourviews?.toString()] &&
                      formikProps.touched[field?.IdYourviews?.toString()]
                    ) && (
                      <FormHelperText error>
                        {formikProps.errors[field?.IdYourviews?.toString()]}
                      </FormHelperText>
                    )}

                  </FormField>
                )}
              </>
            </FormFieldItem>
          </FormSection>
        )}
      </Fragment>
    ))

  const emailComponent = () =>
    form.AnonymousPerson && (
      <FormSection>
        <Label htmlFor="PersonEmail">Seu e-mail</Label>
        <FormField>
          <Field
            name="PersonEmail"
            data-testid="PersonEmail"
            noMaxWidth
            component={TextFieldCustom}
          />
        </FormField>
      </FormSection>
    )

  const nameComponent = () =>
    form.AnonymousPerson && (
      <FormSection>
        <Label htmlFor="PersonName">Seu Nome</Label>
        <FormField>
          <Field
            name="PersonName"
            data-testid="PersonName"
            noMaxWidth
            component={TextFieldCustom}
          />
        </FormField>
      </FormSection>
    )

  const formActionsComponent = () => {

    let isDisabled = formikProps.isSubmitting || !(formikProps.isValid && formikProps.dirty)

    return !hideSubmitButton && (
      <FormActions
        style={{
          display: "flex",
          justifyContent: theme?.SendButtonAlign,
        }}>
        <Button
          type="submit"
          variant="contained"
          data-test-id="saveFormBtn"
          disabled={isDisabled}
          style={{
            width: "20%",
            height: "40px",
            backgroundColor: theme?.SendButtonColor,
            color: theme?.SendButtonTextColor,
            borderRadius: theme?.SendButtonStyle === "rounded" ? "50px" : "5px",
            opacity: isDisabled ? ".5" : "1"
          }}
        >
          {t('write.saveBtn')}
        </Button>
      </FormActions>
    )}

  const getOrderedForm = () => {
    switch (form?.FormOrderType) {
      case 2:
        return (
          <>
            {commentComponent()}
            {npsScoreComponent()}
            {customFieldsComponent()}
            {emailComponent()}
            {nameComponent()}
            {formActionsComponent()}
          </>
        )
      case 1:
        return (
          <>
            {npsScoreComponent()}
            {commentComponent()}
            {customFieldsComponent()}
            {emailComponent()}
            {nameComponent()}
            {formActionsComponent()}
          </>
        )
      case 0:
      default:
        return (
          <>
            {npsScoreComponent()}
            {commentComponent()}
            {customFieldsComponent()}
            {emailComponent()}
            {nameComponent()}
            {formActionsComponent()}
          </>
        )
    }
  }

  return getOrderedForm()
}

export default withTranslation()(FormFields)
