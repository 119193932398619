import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    margin: '0px 10px 5px 10px'
  }
}))

export default useStyle
