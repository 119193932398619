import { useState, useCallback } from 'react'

import { useAppContext } from '../../App'
import { useHistory } from 'react-router-dom'

import { withTranslation } from 'react-i18next'

import useGetData from 'core/hooks/useGetData'
import axios from 'core/api'

import AdminItem from './components/AdminItem'
import EditPassword from './components/EditPassword'
import EditStatus from './components/EditStatus'

import Loader from '../../components/Loader'
import Header from '../../components/Header'
import RoundedButton from '../../components/Button'

import { Filters, FilterItem } from '../AppDrawer/styles/Filter'

import '../../style/route/admin/main.css'

const Admin = ({ match, t }) => {
  const { notify } = useAppContext()

  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const handleSetLoading = useCallback((x) => {
    setLoading(x)
  }, [])

  const [admin, setAdmin] = useState([])
  const [modalUserStatus, setModalUserStatus] = useState(-1)
  const [modalUserPassword, setModalUserPassword] = useState(-1)

  const handleClick = () => history.push('administrator/new')

  const changeStatus = (index) => {
    handleSetLoading(true)

    axios
      .post('apiadmin/changestatus?IdAdmin=' + admin[index].IdYourviews)
      .then((res) => {
        if (!res.data) throw new Error('Error on change')

        if (res.data.Result !== 2)
          throw new Error(t('admin.status.error' + res.data.Result))

        let newAdmin = admin
        newAdmin[index].Active = !newAdmin[index].Active

        setAdmin(newAdmin)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        handleSetLoading(false)
        setModalUserStatus(-1)
      })
  }

  useGetData({
    endpoint: 'apiadmin/getall',
    setData: setAdmin,
    setLoading: handleSetLoading
  })

  return (
    <>
      <Header
        type="actions"
        title={t('admin.title')}
        items={
          <Filters justify="flex-end">
            <FilterItem>
              <RoundedButton
                whiteTextLabel
                type="add"
                size="large"
                variant="contained"
                onClick={handleClick}
              />
            </FilterItem>
          </Filters>
        }
      />

      {loading ? (
        <Loader />
      ) : (
        <div className="admin" style={{ marginTop: 10 }}>
          {admin.length > 0 && (
            <ul className="admin-list">
              {admin.map((item, index) => (
                <AdminItem
                  key={index}
                  admin={item}
                  changePassword={() => setModalUserPassword(index)}
                  changeStatus={() => setModalUserStatus(index)}
                  edit={() =>
                    history.push({
                      pathname: `${match.url}/${item.IdYourviews}`,
                      state: item
                    })
                  }
                  setNotifications={() =>
                    history.push(
                      `notifications?IdAdmin=${item.IdYourviews}`,
                      item
                    )
                  }
                />
              ))}
            </ul>
          )}

          {modalUserStatus > -1 && (
            <EditStatus
              active={modalUserStatus > -1 && modalUserPassword < 0}
              user={admin[modalUserStatus]}
              close={() => setModalUserStatus(-1)}
              changeStatus={() => changeStatus(modalUserStatus)}
            />
          )}

          {modalUserPassword > -1 && (
            <EditPassword
              active={modalUserStatus < 0 && modalUserPassword > -1}
              close={() => setModalUserPassword(-1)}
              user={admin[modalUserPassword]}
            />
          )}
        </div>
      )}
    </>
  )
}

export default withTranslation()(Admin)
