import { useForm, Controller } from 'react-hook-form'
import { withTranslation } from 'react-i18next'

import DialogContentText from '@material-ui/core/DialogContentText'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import HelpIcon from '@material-ui/icons/Help'
import SendIcon from '@material-ui/icons/Send'
import { TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { SaveButton, Text } from './style'

import { useState, useEffect, useCallback } from 'react'
import api from 'core/api'

import { useAppContext } from 'App'
import RenderSyntax from '../RenderSyntax'
import postData from 'core/functions/post-data.function'

const defaultValues = {}

function ScriptModal({ open, handleClose, t }) {
  const { notify } = useAppContext()

  const [dimensionList, setDimensionList] = useState(null)

  const getData = async (endpoint, setData) => {
    try {
      const { data } = await api.get(endpoint)

      if (data) setData(data)
    } catch (error) {
      notify(error, 'error')
    } finally {
    }
  }

  useEffect(() => {
    getData('dimension/getallplain', setDimensionList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [dialog, changeDialog] = useState(false)
  const handleDialog = () => changeDialog(true)
  const backDialog = () => changeDialog(false)

  const { handleSubmit, control } = useForm({ defaultValues })

  const onSubmit = (data) => {
    setDiemnsionsRender(data.dimensions)
    changeDialog(false)
  }
  const [dimensionsRender, setDiemnsionsRender] = useState([])
  const submitOn = (data) => {
    sendScript(data, dimensionsRender)
  }
  const sendScript = useCallback((Data, Dimensions) => {
    const To = { Name: null, Email: Data.Email }
    const dados = { To, Dimensions }

    postData({
      endpoint: 'Email/SendWidgetScriptEmail',
      data: dados,
      setData: (data) => {
        if (!data.HasErrors) {
          notify('Script enviado com sucesso', 'success')
        }
      },
      notify: notify
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const storeKey = JSON.parse(window.localStorage.getItem('__s_'))
  let text =
    '//Valores fixos, cliente não deve alterar\n' +
    "<script type='text/javascript'>\n" +
    'window.yvnps=window.yvnps||[];\n' +
    '//Vem por padrão do backend. Cliente não deve alterar\n' +
    'window.yvnps.push({account:' +
    '"' +
    storeKey?.Key +
    '"' +
    '});\n' +
    '//Obrigatório, sistema do cliente deverá preencher\n' +
    "window.yvnps.push({name:'NOME DO CONSUMIDOR'});\n" +
    '//UserId ou E-mail obrigatórios, sistema do cliente deverá preencher\n' +
    "window.yvnps.push({userid:'ID DO USUÁRIO'});\n" +
    "window.yvnps.push({userid:'EMAIL@EMAIL.COM'});\n" +
    '/*Dimensões que o cliente deseja incluir na resposta. \n' +
    'Pode ser valor fixo ou preenchido pelo sistema do cliente*/\n' +
    (dimensionsRender &&
      dimensionsRender
        .map(
          (item, index) =>
            'window.yvnps.push({"dimension_' +
            item.Key +
            '"' +
            ': "substitua aqui o valor"});\n'
        )
        .join('')) +
    '\n(function () {\n' +
    "var yvn = document.createElement('script');\n" +
    "yvn.type = 'text/javascript';\n" +
    'yvn.async = true;\n' +
    "yvn.id = '_yvnps';\n" +
    "yvn.src = 'https://yv-nps-prod-public.s3.amazonaws.com/yvapi.js';\n" +
    "var yvn_script = document.getElementsByTagName('script')[0];\n" +
    'yvn_script.parentNode.insertBefore(yvn, yvn_script);\n' +
    '})();\n' +
    '</script>'

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth="md"
      >
        {!dialog && (
          <>
            <DialogTitle
              style={{ textAlign: 'center' }}
              id="alert-dialog-title"
            >
              <Text
                style={{
                  fontSize: '21px'
                }}
              >
                {t('campaing.edit.form.newWidget.modalTitle')}
              </Text>
              <CloseIcon
                onClick={handleClose}
                style={{
                  position: 'absolute',
                  right: '10px',
                  fontSize: '23px',
                  top: '10px',
                  cursor: 'pointer'
                }}
              />
            </DialogTitle>
            <DialogContent>
              <Text
                onClick={handleDialog}
                style={{
                  fontSize: '15px',
                  textAlign: 'center',
                  color: '#00AEEF',
                  display: 'inherit',
                  cursor: 'pointer'
                }}
              >
                {t('campaing.edit.form.newWidget.linkNextModal')}
              </Text>
            </DialogContent>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t('campaing.edit.form.newWidget.scriptText')}
              </DialogContentText>
              <RenderSyntax
                positionIcon={'relative'}
                floatIcon={'right'}
                Text={text}
                rightIcon={'10px'}
                tooltipPosition={'top'}
                Tema={2}
              />
              <div style={{ width: '586px', maxHeight: '312px' }}></div>
              <DialogContentText id="alert-dialog-description">
                {t('campaing.edit.form.newWidget.emailText')}
              </DialogContentText>
              <form onSubmit={handleSubmit(submitOn)}>
                <Controller
                  name="Email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      style={{ width: '528px', marginBottom: '20px' }}
                      label={t(
                        'campaing.edit.form.newWidget.modalEmailFildText'
                      )}
                      variant="outlined"
                    />
                  )}
                />
                <SaveButton type="submit" send>
                  <SendIcon style={{ fontSize: 28 }} />
                </SaveButton>
              </form>
            </DialogContent>
            <DialogActions style={{ borderTop: '1px solid #E0E0E0' }}>
              <Button onClick={handleClose} color="primary">
                {t('campaing.edit.form.newWidget.closeButtonModal')}
              </Button>
              <SaveButton medium onClick={handleClose}>
                {t('campaing.edit.form.newWidget.saveButtonModal')}
              </SaveButton>
            </DialogActions>
          </>
        )}
        {dialog && (
          <>
            <DialogTitle
              style={{ textAlign: 'center' }}
              id="alert-dialog-title"
            >
              <Text
                style={{
                  fontSize: '21px'
                }}
              >
                {t('campaing.edit.form.newWidget.configModalTitle')}
              </Text>
              <ArrowBackIcon
                onClick={backDialog}
                color="primary"
                style={{
                  position: 'absolute',
                  left: '10px',
                  fontSize: '23px',
                  top: '10px',
                  cursor: 'pointer'
                }}
              />
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent style={{ width: '586px' }}>
                <DialogContentText id="alert-dialog-description">
                  {t('campaing.edit.form.newWidget.configModalText')}
                </DialogContentText>

                <div
                  style={{
                    float: 'right',
                    marginRight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: '28px',
                    marginBottom: '20px'
                  }}
                >
                  <a
                    href="https://help.hiplatform.com/"
                    target="_blanck"
                    style={{ display: 'flex', textDecoration: 'none' }}
                  >
                    <HelpIcon
                      style={{
                        fontSize: '20px',
                        color: '#00AEEF'
                      }}
                    />
                    <Text
                      style={{
                        fontSize: '14px',
                        color: '#00AEEF',
                        marginTop: '-3px',
                        cursor: 'pointer'
                      }}
                    >
                      {t('campaing.edit.form.newWidget.helpLinkModal')}
                    </Text>
                  </a>
                </div>

                <Controller
                  name="dimensions"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={dimensionList ? dimensionList : []}
                      getOptionLabel={(option) => option.Name}
                      filterSelectedOptions
                      value={field.value ?? []}
                      ref={field.ref}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={t(
                            'campaing.edit.form.newWidget.textInputAutoComplete'
                          )}
                        />
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={backDialog} color="primary">
                  {t('campaing.edit.form.newWidget.configModalCancelButton')}
                </Button>
                <SaveButton type="submit" medium>
                  {t('campaing.edit.form.newWidget.configModalSaveButton')}
                </SaveButton>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  )
}
export default withTranslation()(ScriptModal)
