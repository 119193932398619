import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

function MultiSelect({ value, onChange, name, item, values }) {
  const [state, setState] = useState(value)

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const removeFalseValues = (object) =>
    Object.fromEntries(Object.entries(object).filter((item) => item[1]))

  const makeListFromKeys = (object) => Object.keys(object)

  useEffect(() => {
    onChange(
      {
        name: name,
        value: Array.isArray(state)
          ? state
          : makeListFromKeys(removeFalseValues(state))
      },
      item
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{name}</FormLabel>
      <FormGroup>
        {values &&
          values.map((option) => (
            <FormControlLabel
              key={option}
              label={option}
              control={
                <Checkbox
                  name={option}
                  value={option}
                  onChange={handleChange}
                  data-testid="dinamic-form-multiselect"
                  checked={
                    state && !!state.find((item) => item === option ?? false)
                  }
                />
              }
            />
          ))}
      </FormGroup>
    </FormControl>
  )
}

const { string, number, func, oneOfType } = PropTypes

MultiSelect.propTypes = {
  id: number,
  name: string,
  label: string,
  onChange: func,
  value: oneOfType([string, number])
}

MultiSelect.defaultProps = {
  value: ''
}

export default MultiSelect

// import React, { useState } from 'react'
// import PropTypes from 'prop-types'

// import makeId from '../../functions/makeId'

// import Select from '@material-ui/core/Select'

// import useStyles from './style'
// import { Chip, FormControl, InputLabel, MenuItem } from '@material-ui/core'

// function MultiSelect(props) {
//   const classes = useStyles()
//   const labelId = 'label-' + id

//   const makeValue = (value) => {
//     if (typeof value === 'string' && value !== '') return [value]
//     if (Array.isArray(value)) return value
//     if (!value) return []
//   }

//   const [selectedValue, setSelectedValue] = useState(makeValue(value))
//   const handleChange = (target) => {
//     setSelectedValue(target.value)
//     onChange(target, item)
//   }

//   return (
//     <FormControl className={classes.field}>
//       <InputLabel id={makeId(labelId, name)}>{label}</InputLabel>
//       <Select
//         multiple
//         name={name}
//         value={selectedValue}
//         className={classes.field}
//         id={makeId(id, name)}
//         onChange={({ target }) => handleChange(target)}
//         renderValue={(selected) => (
//           <div className={classes.chips}>
//             {selected.map((value) => (
//               <Chip key={value} label={value} className={classes.chip} />
//             ))}
//           </div>
//         )}
//       >
//         {values.map((item, index) => (
//           <MenuItem key={index} value={item}>
//             {item}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   )
// }

// const { string, number, func, oneOfType } = PropTypes

// MultiSelect.propTypes = {
//   id: number,
//   name: string,
//   label: string,
//   onChange: func,
//   value: oneOfType([string, number])
// }

// MultiSelect.defaultProps = {
//   value: ''
// }

// export default MultiSelect
