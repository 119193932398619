import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Navbar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4px 8px;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 0px rgb(0 0 0 / 11%);
  margin-bottom: 24px;
`
export const Logo = styled.img``
export const ChangeLanguage = styled.div`
  margin: 8px;
`
export const Header = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 52px;
  color: #000000;
  margin: 0 auto;
  margin-bottom: 16px;
`
export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 16px;
`

export const Description = styled.div`
  margin: 12px 0px;
`
export const Subtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`

export const Ul = styled.ul`
  margin-bottom: 16px;
  list-style: disc;
  margin-left: 15px;
`
export const Li = styled.li`
  list-style: disc;
`
export const Title = styled.div`
  font-size: 22px;
  color: #00aeef;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  margin: auto 0px;
  float: left;
  line-height: 65px;
`
