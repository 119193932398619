import styled from 'styled-components'

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  ${({ error }) => (error ? 'color: red;' : '')}
  padding: ${({ padding }) => padding ?? 0};
`

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 20px;
  width: 100%;
`

export const Logo = styled.img`
  max-height: 100px;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 10px 0px;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
`