import { useEffect, useState } from 'react'
import { read } from 'localstorage-helpr'
import { USER } from '../constants/local-storage'

function hotjar(h, o, t, j, a, r) {
  h.hj =
    h.hj ||
    function () {
      ;(h.hj.q = h.hj.q || []).push(arguments)
    }
  h._hjSettings = { hjid: 2118592, hjsv: 6 }
  a = o.getElementsByTagName('head')[0]
  r = o.createElement('script')
  r.async = 1
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
  a.appendChild(r)
}

const isHiOrYvEmail = (email) =>
  email.includes('@yourviews.com.br') || email.includes('@hiplatform.com')

const useInitHotjar = () => {
  const [user, setUser] = useState(read(USER))

  useEffect(() => {
    setUser(read(USER))

    if (user?.Email && !isHiOrYvEmail(user?.Email)) {
      hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useInitHotjar
