import styled from 'styled-components'

export const Filters = styled.div`
  display: flex;
  ${({ justify }) => `justify-content: ${justify ? justify : 'space-between'};`}
  flex-wrap: wrap;
`
export const FilterItem = styled.div`
  ${({ paddingTop }) => `padding-top: ${paddingTop};`}
`

export default Filters
