import styled from 'styled-components'

export const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify ?? ''};
  flex-direction: ${({ direction }) => direction ?? ''};

  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`

export const FlexItem = styled.div`
  ${({ margin }) => `margin: ${margin};`}
`
