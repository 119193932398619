import { Fragment, useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import api from 'core/api'
import { DIMENSION_ORIGIN } from 'core/enums/dimension-origin'

import TextField from '@material-ui/core/TextField'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'

import getCurrentTypeSugestion from '../../functions/getCurrentTypeSuggestion'
import ErrorIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { t } from 'i18next'

const filter = createFilterOptions()
const SyntethicEventBuilder = (name, value) => ({ target: { name, value } })
const ReservedNames = ['Nome', 'E-mail', 'Telefone']

const DimensionAutocomplete = ({
  ref,
  item,
  name,
  index,
  onChange,
  disabled,
  dimensions,
  updateDimensionList,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [value, setValue] = useState(
    getCurrentTypeSugestion(
      item.TypeSuggestion,
      item.IdDimension,
      dimensions,
      index
    )
  )

  const addDimension = async (name) => {
    try {
      const res = await api.post('dimension/insertupdate', {
        Key: name,
        Name: name,
        Type: 0,
        Values: [],
        Active: true,
        Origin: DIMENSION_ORIGIN.person
      })

      if (res.data.HasErrors) throw new Error('Error on creating dimension')
      if (res.data.HasMajorErrors)
        throw new Error('Error on creating dimension')

      await updateDimensionList((prevState) => [...prevState, res.data.Element])

      setValue({ ...res.data.Element, Index: index })
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (disabled) setValue('')
  }, [disabled])

  useEffect(() => {
    if (value) onChange(SyntethicEventBuilder(name, value))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const groupedDimensions = useMemo(
    () =>
      dimensions.map((option) => ({
        type: option.DefaultType
          ? t('campaign.dimensionGroupBy.default')
          : t('campaign.dimensionGroupBy.custom'),
        ...option
      })),
    [dimensions]
  )

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start'
      }}
    >
      <Autocomplete
        autoHighlight
        freeSolo
        handleHomeEndKeys
        //
        disabled={disabled}
        value={value}
        options={groupedDimensions}
        groupBy={(option) => option.type}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setValue({
              Name: newValue,
              Index: index
            })
            addDimension(newValue)
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              Name: newValue.inputValue,
              Index: index
            })

            const alreadyCreatedDimension = dimensions.find(
              (x) => x.Name === newValue.inputValue
            )

            const isReserved = ReservedNames.find(
              (x) => x === newValue.inputValue
            )

            if (isReserved) {
              enqueueSnackbar(
                t('campaign.peopleList.form.table.warningDefaultDimensions'),
                { variant: 'warning' }
              )
              setValue({})

              return
            } else if (alreadyCreatedDimension) {
              enqueueSnackbar(
                t('campaign.peopleList.form.table.warningDuplicatedDimensions'),
                {
                  variant: 'warning'
                }
              )

              setValue(alreadyCreatedDimension)

              return
            } else {
              enqueueSnackbar(
                t('campaign.peopleList.form.table.successCreateDimension'),
                {
                  variant: 'success'
                }
              )

              addDimension(newValue.inputValue)
            }
          } else {
            setValue({ ...newValue, Index: index })
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              Name: `${props.t('uiActions.create')} "${params.inputValue}"`,
              Index: index
            })
          }

          return filtered
        }}
        id="peoplelist-dimension-autocomplete"
        data-testid="peoplelist-dimension-autocomplete"
        getOptionLabel={(option) => {
          if (option?.inputValue) {
            return option?.inputValue
          }

          return option?.Name ?? ''
        }}
        renderOption={(option) => option.Name}
        style={{ width: 300 }}
        innerRef={props.ref}
        renderInput={(params) => (
          <TextField
            inputRef={props.ref}
            {...params}
            variant="outlined"
            error={props.duplicate}
            label={props.t('campaign.peopleList.form.associateWithDimension')}
          />
        )}
      />

      {props.duplicate && (
        <div style={{ margin: 'auto  8px' }}>
          <Tooltip
            title={
              props.duplicate && (
                <Fragment>
                  <Typography color="inherit">Dimensão duplicada</Typography>
                  <text>Essa dimensão já foi selecionada.</text>
                  <br />
                  <text>Utilize uma dimensão por coluna da planilha</text>
                </Fragment>
              )
            }
            placement="top"
            arrow
          >
            <ErrorIcon color="error" />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

DimensionAutocomplete.propTypes = {
  dimensions: PropTypes.array,
  onChange: PropTypes.func
}

export default withTranslation()(DimensionAutocomplete)
