import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { getItemText } from 'core/functions/get-qs-value.function'
import toggleCheckbox from './functions/toggleCheckbox'

import './style.css'

const CheckboxFilter = ({
  field,
  form: { setFieldValue },
  queryString,
  options
}) => {
  const [checks] = useState({
    whenTrue: 'cb1-' + field.name,
    whenFalse: 'cb2-' + field.name
  })
  const initialSelected = getItemText(queryString)

  const [selected, setSelected] = useState(initialSelected)

  const handleChange = ({ target }) =>
    target.checked ? setSelected(target.value) : setSelected(null)

  const handleCheckOnInit = (id) => (document.getElementById(id).checked = true)

  useEffect(() => {
    if (selected === 'true') handleCheckOnInit(checks.whenTrue)
    if (selected === 'false') handleCheckOnInit(checks.whenFalse)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFieldValue(field.name, selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected])

  return (
    <div
      id={field.name}
      data-testid={field.name}
      onClick={(e) => toggleCheckbox(e, field.name)}
    >
      <label className="pure-material-checkbox">
        <input
          value="true"
          type="checkbox"
          id={checks.whenTrue}
          onChange={handleChange}
          data-testid={checks.whenTrue}
        />
        <span>{options.whenTrue}</span>
      </label>

      <br />

      <label className="pure-material-checkbox">
        <input
          value="false"
          type="checkbox"
          id={checks.whenFalse}
          onChange={handleChange}
          data-testid={checks.whenFalse}
        />
        <span>{options.whenFalse}</span>
      </label>
    </div>
  )
}

export default withTranslation()(withRouter(CheckboxFilter))
