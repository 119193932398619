import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import {
  QS_TYPES,
  QS_TYPE,
  QS_PAGE,
  QS_CAMPAIGN_LIST,
  QS_DIMENSION_VALUE_LIST
} from 'core/constants/query-strings'
import { withTranslation } from 'react-i18next'
import CustomTabs from 'components/CustomTabs'

function NpsCsatTabs({ match, onTabChange, t }) {
  return (
    <CustomTabs
      match={match}
      queryParams={[QS_TYPES, QS_TYPE]}
      queryParamsToDeleteOnChange={[
        QS_PAGE,
        QS_CAMPAIGN_LIST,
        QS_DIMENSION_VALUE_LIST
      ]}
      onTabChange={onTabChange}
      tabs={[
        { value: NPS_CSAT_TAB.nps.typeParam, label: t('dashboard.nps.title') },
        { value: NPS_CSAT_TAB.csat.typeParam, label: t('dashboard.csat.title') }
      ]}
    />
  )
}

export default withTranslation()(NpsCsatTabs)
