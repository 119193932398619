import { useState, useCallback } from 'react'

import { withTranslation } from 'react-i18next'
import { useAppContext } from 'App'

import api from 'core/api'

import useGetData from 'core/hooks/useGetData'

import { FlexItem, FlexContainer } from 'style/components/Flex'

import Card from './components/Card'
import Form from './components/Form'
import Modal from './components/Modal'

import CardSkelleton from './components/Card/skelleton'
import Header from 'components/Header'

import network from 'assets/img/network.png'

function Integrations({ t }) {
  const { notify } = useAppContext()

  const [loading, setLoading] = useState(false)
  const handleSetLoading = useCallback((x) => {
    setLoading(x)
  }, [])

  const [setError] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [integration, setIntegration] = useState(null)
  const [integrations, setIntegrations] = useState(null)

  useGetData({
    endpoint: 'integration/list',
    setData: setIntegrations,
    setLoading: handleSetLoading
  })

  const handleShow = (integration) => {
    setIntegration(integration)
    setShowModal(true)
  }

  const getList = async () => {
    handleSetLoading(true)

    try {
      const { data } = await api.get('integration/list')

      setIntegrations(data)
    } catch (error) {
      setError(error)
      notify(error.message, 'error')
    } finally {
      handleSetLoading(false)
    }
  }

  const handleClose = async () => {
    setShowModal(false)
    setIntegrationError(null)

    getList()
  }

  const [integrationError, setIntegrationError] = useState(null)
  const handleSwitch = async (state, infos, fromCard) => {
    try {
      await api.post('integration/change', {
        integrationKey: infos.keys,
        active: state,
        type: infos.type.toString()
      })

      if (fromCard) getList()
    } catch (error) {
      setIntegrationError(t('integrations.genericErrorOnActive'))
      handleShow(infos)
    }
  }

  return (
    <>
      <Header type="actions" title={t('integrations.title')} />

      {loading ? (
        <div style={{ display: 'flex', width: '100%', margin: '10px 0px' }}>
          <div style={{ minWidth: 330 }}>
            <CardSkelleton />
          </div>
          <div style={{ minWidth: 330 }}>
            <CardSkelleton />
          </div>
        </div>
      ) : (
        <>
          <FlexContainer margin="10px 0px">
            {integrations &&
              integrations.map((integration, index) => (
                <FlexItem key={index} margin={'0 20px 0 0'}>
                  <Card
                    configure={handleShow}
                    type={integration.Type}
                    title={integration.Name}
                    errors={integration.Errors}
                    active={integration.Active}
                    id={integration.IdYourviews}
                    key={integration.IdYourviews}
                    keys={integration.IntegrationKey}
                    description={integration.Description}
                    image={integration.ImageUrl ?? network}
                    onSwitch={(state, integration) =>
                      handleSwitch(state, integration, true)
                    }
                  />
                </FlexItem>
              ))}
          </FlexContainer>

          <Modal show={showModal} onClose={handleClose}>
            <Form
              infos={integration}
              onSave={handleClose}
              onCancel={handleClose}
              integrationError={integrationError}
              onSwitch={(state) => handleSwitch(state, integration)}
            />
          </Modal>
        </>
      )}
    </>
  )
}

export default withTranslation()(Integrations)
