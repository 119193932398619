import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  badge: {
    right: '10px'
  },
  badgeValue: {
    borderRadius: '5px'
  }
}))

export default useStyles
