import { ListItem, List } from './style'

const PreviewNpsWidget = ({ color }) => {
  const nps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <List>
      {nps.map((number, index) => (
        <ListItem key={index} index={index} color={color}>
          {number}
        </ListItem>
      ))}
    </List>
  )
}

export default PreviewNpsWidget
