import health from 'assets/img/health.svg'

const Health = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      textAlign: 'center',
      margin: 'auto',
      height: '100vh',
      width: '100vw'
    }}
  >
    {/* 
      Não remover este componente,
      ele serve para que o servidor
      tenha um endpoint de consulta
      para verificação de Health.
    */}
    <img src={health} width={600} alt="Health status by Undraw" />
  </div>
)

export default Health
