import { Fragment, useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { MdDesktopWindows, MdSmartphone } from 'react-icons/md'

import { BASE_PATH } from '../../../../router'

import axios from 'core/api'
import Yup from 'core/validations/index'

import TextField from '../../../../components/Form/Text/TextField'
import TemplateBlock from '../TemplateBlock'
import Frame from '../../../../components/Frame'

import Header from '../../../../components/Header'
import { useAppContext } from '../../../../App'
import { getQSValue } from 'core/helpers/functions/queryString'

const TemplateEdit = ({ location, match, history, item, t }) => {
  const { notify } = useAppContext()

  const [template, setTemplate] = useState(location?.state ?? null)
  const [frameLoading, setFrameLoading] = useState(true)

  const [isFromWizard, setIsFromWizard] = useState(false)
  const [emailType, setEmailType] = useState(null)

  const [HTML, setHTML] = useState(null)
  const [isTemplateDesktop, setIsTemplateDesktop] = useState(true)

  const getPreview = (data) => {
    setFrameLoading(true)

    axios
      .post('template/previewtemplate', data)
      .then((res) => {
        setHTML(res.data)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setFrameLoading(false)
      })
  }

  useEffect(() => {
    setIsFromWizard(getQSValue('fromWizard') ?? false)
    setEmailType(getQSValue('emailType') ?? null)

    const item = match?.params?.item

    if (template !== null) {
      getPreview(template)
      return
    }

    if (isNaN(item)) {
      history.push(BASE_PATH + 'template')
      return
    }

    setFrameLoading(true)

    axios
      .get('template/get?idtemplate=' + item)
      .then((res) => {
        setTemplate(res.data)
        getPreview(res.data)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setFrameLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setNewValue = (block, newBlock) => {
    let newTemplate = template
    newTemplate.Blocks[block] = newBlock
    setTemplate(newTemplate)
    getPreview(newTemplate)
  }

  const sendTemplate = (values, setSubmitting) => {
    const templateToSave = template
    template.Name = values.title

    setFrameLoading(true)

    axios
      .post('template/savetemplate', templateToSave)
      .then(() => {
        notify(t('success.save'), 'success')
        if (isFromWizard) {
          history.push(
            BASE_PATH +
              'campaign/email?fromTemplateEdit=true&emailType=' +
              emailType,
            { id: match?.params?.item, name: templateToSave.Name }
          )
        } else {
          history.push(BASE_PATH + 'template')
        }
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setFrameLoading(false)
        setSubmitting(false)
      })
  }

  const handleCancel = () => {
    if (isFromWizard) {
      history.push(
        BASE_PATH +
          'campaign/email?fromTemplateEdit=true&emailType=' +
          emailType
      )
    } else {
      history.push(BASE_PATH + 'template')
    }
  }

  return (
    <>
      {!item && <Header title={t('template.edit.title')} />}

      {template !== null && (
        <Fragment>
          <div className="template-edit-header white-form">
            <Formik
              initialValues={{
                title: template.Name
              }}
              validationSchema={Yup.object().shape({
                title: Yup.string().max(80).required()
              })}
              onSubmit={(values, { setSubmitting }) => {
                sendTemplate(values, setSubmitting)
              }}
            >
              {(propsForm) => {
                return (
                  <Form className="template-edit-form">
                    <Field
                      name="title"
                      label={t('template.edit.titleLabel')}
                      component={TextField}
                      placeholder={t('template.edit.titleDesc')}
                    />
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={propsForm.isSubmitting}
                    >
                      {t('template.edit.saveBtn')}
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      disabled={propsForm.isSubmitting}
                      onClick={handleCancel}
                    >
                      {t('template.edit.cancelBtn')}
                    </Button>
                  </Form>
                )
              }}
            </Formik>
          </div>

          <div className="template-edit-body">
            <section
              className={`
              template-edit-preview 
              ${frameLoading ? 'is-loading' : ''}
            `}
            >
              {frameLoading ? (
                <CircularProgress />
              ) : (
                <Fragment>
                  <Frame title="template" small={!isTemplateDesktop}>
                    <div dangerouslySetInnerHTML={{ __html: HTML }}></div>
                  </Frame>
                  <div className="template-edit-preview-filter">
                    {isTemplateDesktop ? (
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsTemplateDesktop(false)}
                      >
                        {t('template.edit.preview.mobileBtn')}
                        <MdSmartphone />
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => setIsTemplateDesktop(true)}
                      >
                        {t('template.edit.preview.desktopBtn')}
                        <MdDesktopWindows />
                      </Button>
                    )}
                  </div>
                </Fragment>
              )}
            </section>

            <section className="template-edit-itens">
              <ul className="template-edit-blocks">
                {template.Blocks.map((block, bidx) => (
                  <TemplateBlock
                    block={block}
                    apply={(newBlock) => setNewValue(bidx, newBlock)}
                    key={block.TargetId}
                  />
                ))}
              </ul>
            </section>
          </div>
        </Fragment>
      )}
    </>
  )
}

export default withTranslation()(TemplateEdit)
