import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 44px;
  text-align: left;
  margin-bottom: 20px;
`

export const ModalTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`

export const ModalSubtitle = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

  color: rgba(0, 0, 0, 0.54);

  ${({ margin }) => `margin: ${margin};`}
`

export default Title
