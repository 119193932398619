import PropTypes from 'prop-types'

import { ListItem } from './style'

function MaterialListItem({
  id,
  testId,
  index,
  length,
  noHover,
  condensed,
  children
}) {
  return (
    <ListItem
      key={id}
      index={index}
      length={length}
      noHover={noHover}
      data-testid={testId}
      condensed={condensed}
    >
      {children}
    </ListItem>
  )
}

const { number, string, bool, object } = PropTypes

MaterialListItem.propTypes = {
  id: string,
  testId: string,
  index: number,
  length: number,
  condensed: bool,
  children: object
}

export default MaterialListItem
