import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const CheckboxItem = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  id,
  label
}) => {
  return (
    <FormControlLabel
      className="form-field checkbox-field"
      control={
        <Checkbox
          id={id}
          name={name}
          value={value}
          color="primary"
          onBlur={onBlur}
          checked={value}
          onChange={onChange}
        />
      }
      label={label}
    />
  )
}

export default CheckboxItem
