import { useHistory, useLocation } from 'react-router-dom'
import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { IconButton } from '@material-ui/core'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { Position, Step, Steps, StepTitle, Title } from '../Email/style'
import {
  ButtonLabel,
  Container,
  HeaderTitle,
  LeftActions,
  RightActions,
  Row
} from '../Form/style'
import { BASE_PATH } from 'router'
import { Block } from '../Details/style'
import emptyList from 'assets/img/empty-list.svg'
import DispatchEmail from '../DispatchEmail'
import BigButton from 'components/BigButton'
import Add from '@material-ui/icons/Add'
import { Text } from '../Details/components/VerifyDialog/style'
import DialogDispatch from './components/DialogDispatch'
import { useCampaign } from '../Details/context'

function Dispatch({ t }) {
  const history = useHistory()
  const location = useLocation()

  const handlePrevious = () => {
    history.push({
      pathname: BASE_PATH + 'campaign/import',
      search: location.search
    })
  }
  const handleNext = () => {
    history.push({
      pathname: BASE_PATH + 'campaign/review',
      search: location.search
    })
  }
  const {
    dispatch,
    emailListObj,
    emailListArray
  } = useCampaign()

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [openRemove, setOpenRemove] = useState(false)
  const handleOpenRemove = () => setOpenRemove(true)
  const handleCloseRemove = () => setOpenRemove(false)

  return (
    <Container>
      <Row center noBorder>
        <HeaderTitle>{t('dispatch.configDispatch')}</HeaderTitle>
      </Row>

      <Row>
        <LeftActions>
          <IconButton
            data-testid="wizard-back-button-icon"
            onClick={handlePrevious}
          >
            <NavigateBefore color="primary" />
          </IconButton>

          <ButtonLabel
            onClick={handlePrevious}
            data-testid="wizard-back-button"
          >
            {t('person.detail.backBtn')}
          </ButtonLabel>
        </LeftActions>

        <Steps>
          <Step>
            <Position active>1</Position>
            <StepTitle>{t('campaign.wizard.steps.1')}</StepTitle>
          </Step>
          <Step>
            <Position active>2</Position>
            <StepTitle>{t('campaign.wizard.steps.2')}</StepTitle>
          </Step>
          <Step>
            <Position active>3</Position>
            <StepTitle>{t('campaign.wizard.steps.3')}</StepTitle>
          </Step>
          <Step>
            <Position>4</Position>
            <StepTitle>{t('campaign.wizard.steps.4')}</StepTitle>
          </Step>
        </Steps>

        <RightActions>
          <ButtonLabel data-testid="wizard-next-button" onClick={handleNext}>
            {t('campaing.edit.form.email.advance')}
          </ButtonLabel>

          <IconButton
            data-testid="wizard-next-button-icon"
            onClick={handleNext}
          >
            <NavigateNext color="primary" />
          </IconButton>
        </RightActions>
      </Row>

      <Container style={{ width: '800px', margin: '0 auto' }}>
        <Title margin="24px 0px" bold>
          {t('dispatch.emailDispatch')}
        </Title>
        {dispatch && !dispatch.length && (
          <>
            <Block
              style={{
                margin: '0 auto',
                display: 'table',
                marginTop: '16px'
              }}
            >
              <img src={emptyList} alt="empty List" />
            </Block>

            <Block>
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: 21,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  color: 'rgba(0, 0, 0, 0.54)',
                  margin: '35px 0'
                }}
              >
                {t('dispatch.noneSend')}
              </Text>
            </Block>
          </>
        )}

        <Container scrollY>
          {dispatch &&
            dispatch?.map((dispacthItem, index) => (
              <div key={index}>
                <DispatchEmail
                  item={dispacthItem}
                  list={emailListObj[dispacthItem.IdTargetList]}
                  id={dispacthItem.id}
                  openRemove={openRemove}
                  handleCloseRemove={handleCloseRemove}
                  handleOpenRemove={handleOpenRemove}
                  index={index}
                />
              </div>
            ))}
        </Container>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 20
          }}
        >
          <BigButton
            onClick={handleOpen}
            icon={<Add />}
            title={t('dispatch.btn')}
            id="open-element"
          />
        </div>
      </Container>

      <DialogDispatch
        open={open ? open : false}
        emailList={emailListArray}
        handleClose={handleClose}
      />
    </Container>
  )
}
export default withTranslation()(Dispatch)
