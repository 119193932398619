import './index.css'
import { useMemo, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import Pagination from 'components/Pagination'
import queryString from 'query-string'
import { QS_PAGE, QS_PAGE_SIZE } from 'core/constants/query-strings'
import { Skeleton } from '@material-ui/lab'
import useGetData from 'core/hooks/useGetData'
import NoDataRow from 'components/NoDataRow'

const WidgetTable = ({ t }) => {
  const { pathname, search } = useLocation()
  const { push } = useHistory()

  const [listData, setListData] = useState({})
  const [loadingListData, setLoadingListData] = useState(true)
  useGetData({
    endpoint: '/Report/conversion-report/widget/list',
    setData: setListData,
    setLoading: setLoadingListData,
    debounceTime: 500
  })

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const handleChangePagination = useCallback(
    (newPage, newPageSize) => {
      push({
        pathname: pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_PAGE]: newPage,
          [QS_PAGE_SIZE]: newPageSize
        })
      })
    },
    [currentSearch, pathname, push]
  )

  return (
    <div className="conversion-widget-table">
      <TableContainer>
        <Table rows={[]}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t('conversion-report.widget.header.person')}
              </TableCell>
              <TableCell align="left">
                {t('conversion-report.widget.header.seen')}
              </TableCell>
              <TableCell align="left">
                {t('conversion-report.widget.header.status')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!loadingListData && !listData.TotalItemCount && <NoDataRow />}
            {(loadingListData ? [1, 2, 3, 4, 5] : listData.Items).map(
              (row, index) => (
                <TableRow key={index} className="conversion-widget-table-row">
                  <TableCell align="left">
                    <div className="conversion-widget-table-row-name">
                      {loadingListData ? (
                        <Skeleton variant="rect" height={16} />
                      ) : (
                        row.Person?.Name
                      )}
                    </div>
                    <div className="conversion-widget-table-row-email">
                      {row.Person?.Email}
                    </div>
                  </TableCell>

                  <TableCell align="left">
                    <div className="conversion-widget-table-row-times">
                      {loadingListData ? (
                        <Skeleton variant="rect" height={16} />
                      ) : (
                        t('conversion-report.widget.row.times', {
                          count: row.Viewed
                        })
                      )}
                    </div>
                  </TableCell>

                  <TableCell align="left">
                    {loadingListData ? (
                      <Skeleton variant="rect" height={24} width={100} />
                    ) : (
                      <div
                        className="conversion-widget-table-row-status"
                        style={{
                          background: row.Answered ? '#60BB46' : '#E0E0E0',
                          color: row.Answered ? 'white' : '#666666'
                        }}
                      >
                        {row.Answered
                          ? t('conversion-report.widget.row.answered')
                          : t('conversion-report.widget.row.notAnswered')}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <Pagination
          data={listData.MetaData}
          onChangePagination={handleChangePagination}
        />
      </TableContainer>
    </div>
  )
}

export default withTranslation()(WidgetTable)
