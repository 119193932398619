import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div``

export const SaveButton = styled.button`
  color: #fff;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
  background-color: #00aeef;
  width: 103px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  border: none;
  ${({ largeSize }) =>
    largeSize
      ? `
  width:300px;
  line-height: 56px;   
    margin: 0 auto;
    border-radius: 36px;
    height: 56px;
  
  `
      : ``}

  ${({ medium }) =>
    medium
      ? `
  width: 90px;
  height: 37px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 37px;
  `
      : ``}

${({ send }) =>
    send
      ? `
width: 55px;
height: 55px;
border-radius: 50%;
float:right;
line-height: 70px;
`
      : ``}
`

export const Text = styled.label`
  font-size: 16px;
`

export const ListItem = styled.div`
  display: flex;
  flex-direction: ${({ col }) => (col ? 'column' : 'row')};
  justify-content: space-between;

  align-items: center;
  align-content: center;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius ?? '4px'};

  ${({ borderBottomNone }) => (borderBottomNone ? 'border-bottom: none;' : '')}

  padding: 12px 10px;
`

export const Block = styled.div`
  display: flex;

  margin: auto 0px;

  flex-direction: ${({ column }) => (column ? 'column' : 'row')};

  padding: ${({ padding }) => padding ?? 0};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
`
export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #212121;

  padding: ${({ padding }) => padding ?? 0};
`

export const Label = styled.h2`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;

  padding: ${({ padding }) => padding ?? 0};

  ${({ radio }) =>
    radio
      ? `
  line-height:42px;
  font-size:14px;
`
      : ``};
`
export const Subtitle = styled.h2`
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
`

export const useStyles = makeStyles(() => ({
  fab: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'default',
      color: '#fff',
      backgroundColor: '#00aeef'
    },
    cursor: 'default'
  },
  alert: {
    width: '100%',
    color: '#00AEEF'
  },
  saveBtn: {
    borderRadius: 50,
    padding: 16
  }
}))
