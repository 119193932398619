import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import api from 'core/api'
import axios from 'axios'
import { Button, IconButton, Link, Tab, Tabs } from '@material-ui/core'
import {
  CloudUpload,
  People,
  NavigateBefore,
  NavigateNext,
  ChevronRight
} from '@material-ui/icons'
import { Position, Step, Steps, StepTitle } from '../Email/style'
import { Box } from 'modules/Campaign/style'
import {
  ButtonLabel,
  Container,
  HeaderTitle,
  LeftActions,
  RightActions,
  Row
} from '../Form/style'
import {
  Action,
  Actions,
  ActionSubtitle,
  ActionTitle,
  Column,
  HelpBox,
  HelpDescription,
  HelpTitle,
  TabContent,
  TabsContainer,
  Title
} from './style'
import ImportFile from 'components/ImportFile'
import download from 'core/functions/download.function'
import { useAppContext } from 'App'
import { BASE_PATH } from 'router'
import eventBus from 'core/events'
import Errors from './components/Errors'
import Form from './components/Form'
import { usePeopleList } from './context'
import { useCampaign } from '../Details/context'

export const ImportFileEvent = 'importFile'
export const CancelPostEvent = 'cancelPost'
export const ResetImportEvent = 'resetImport'

const excelModelLink =
  'https://yv-misc.s3.amazonaws.com/Modelo_de_Importacao_-_Lista_de_Pessoas.xlsx'

const CancelToken = axios.CancelToken
let source
export const cancelPost = 'cancelPost'

function PeopleList({ onFinally, t }) {
  const history = useHistory()
  const { search } = useLocation()
  const { notify } = useAppContext()
  const { emailListArray } = useCampaign()
  const { disableNext } = usePeopleList()

  const [alreadyHaveList, setAlreadyHaveList] = useState(true)

  const handleIfNoAction = () => {
    setAlreadyHaveList(false)
    setUploadSuccess(false)
  }

  const handleAction = (type) => {
    switch (type) {
      case 'yes':
        history.push({ pathname: '/admin/campaign/dispatch', search })
        break
      case 'no':
        handleIfNoAction()
        break
      default:
        return
    }
  }

  const handlePrevious = () => {
    if (uploadSuccess) {
      setAlreadyHaveList(true)
    } else {
      history.push({ pathname: BASE_PATH + 'campaign/email', search })
    }
  }

  const [currentTab, setCurrentTab] = useState(0)
  const handleChangeCurrentTab = (e, newValue) => setCurrentTab(newValue)

  const handleDownloadModel = () => download(excelModelLink)

  const ErrorTypeEnum = {
    error: 'error',
    major: 'major',
    minor: 'minor',
    noError: 'noError'
  }

  const [file, setFile] = useState(null)
  const [processedFile, setProcessedFile] = useState(null)
  const [processingLog, setProcessingLog] = useState(null)
  const [canContinue, setCanContinue] = useState(false)

  const [errors, setErrors] = useState(null)
  const [errorType, setErrorType] = useState(ErrorTypeEnum.noError)

  const sendFile = async (file, onUploadProgress, onSuccess) => {
    api.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    setFile(file)

    let fileData = new FormData()
    fileData.append('list', file)

    source = CancelToken.source()

    try {
      const res = await api.post('person/import', fileData, {
        onUploadProgress,
        cancelToken: source.token
      })

      if (
        res.data.ProcessFileResult.Success &&
        !res.data.ProcessFileResult.HasAnyErrors
      ) {
        onSuccess(true)
        setProcessingLog(res.data.ProcessFileResult.ProcessingLog)
        setProcessedFile(res.data)

        setTimeout(() => handleContinue(), 2000)
      } else {
        if (res.data.ProcessFileResult.HasMajorErrors) {
          setErrorType(ErrorTypeEnum.major)
          setErrors(res.data.ProcessFileResult.ProcessingLog)

          setCanContinue(false)

          return
        }

        if (res.data.ProcessFileResult.HasMinorErrors) {
          setProcessedFile(res.data)
          setErrorType(ErrorTypeEnum.minor)
          setErrors(res.data.ProcessFileResult.ProcessingLog)

          setCanContinue(true)

          return
        }
      }
    } catch (error) {
      setErrorType(ErrorTypeEnum.error)

      if (axios.isCancel(error)) notify(error.message, 'warning')
      else notify(error.message, 'error')
    } finally {
      api.defaults.headers['Content-Type'] = 'application/json'
    }
  }

  const [uploadSuccess, setUploadSuccess] = useState(false)

  const handleContinue = () => {
    setUploadSuccess(true)
    setErrors(null)
    if (onFinally) onFinally()
  }

  const handleResendFile = () => {
    eventBus.dispatch(ResetImportEvent)
    setErrors(null)
  }

  const handleCancelPost = () => {
    if (source) {
      source.cancel('O envio foi cancelado')
      eventBus.dispatch(CancelPostEvent)
    }
  }

  const handleClick = () => {
    if (disableNext) return

    eventBus.dispatch(ImportFileEvent)
  }

  useEffect(() => {
    if (emailListArray !== undefined && !emailListArray?.length > 0) {
      handleIfNoAction()
    }
  }, [emailListArray])

  return (
    <Container>
      <Row center noBorder>
        <HeaderTitle>Configuração de e-mail</HeaderTitle>
      </Row>

      <Row>
        <LeftActions>
          <IconButton
            data-testid="wizard-back-button-icon"
            onClick={handlePrevious}
          >
            <NavigateBefore color="primary" />
          </IconButton>

          <ButtonLabel
            onClick={handlePrevious}
            data-testid="wizard-back-button"
          >
            {t('campaing.edit.form.back')}
          </ButtonLabel>
        </LeftActions>

        <Steps>
          <Step>
            <Position active>1</Position>
            <StepTitle>{t('campaign.wizard.steps.1')}</StepTitle>
          </Step>

          <Step>
            <Position active>2</Position>
            <StepTitle>{t('campaign.wizard.steps.2')}</StepTitle>
          </Step>

          <Step>
            <Position>3</Position>
            <StepTitle>{t('campaign.wizard.steps.3')}</StepTitle>
          </Step>

          <Step>
            <Position>4</Position>
            <StepTitle>{t('campaign.wizard.steps.4')}</StepTitle>
          </Step>
        </Steps>

        <RightActions>
          <ButtonLabel
            disabled={disableNext}
            data-testid="wizard-next-button"
            onClick={handleClick}
          >
            Avançar
          </ButtonLabel>

          <IconButton
            disabled={disableNext}
            data-testid="wizard-next-button-icon"
            onClick={handleClick}
          >
            <NavigateNext color={disableNext ? 'disabled' : 'primary'} />
          </IconButton>
        </RightActions>
      </Row>

      <Box center>
        <>
          {alreadyHaveList ? (
            <>
              {emailListArray && emailListArray?.length > 0 && (
                <>
                  <Title>{t('campaign.peopleList.haveListTitle')}</Title>

                  <Actions>
                    <Action onClick={() => handleAction('yes')}>
                      <Column width={3}>
                        <People color="primary" />
                      </Column>

                      <Column width={92} noHPadding>
                        <ActionTitle>{t('uiActions.yes')}</ActionTitle>
                        <ActionSubtitle>
                          {t('campaign.peopleList.yesSubtitle')}
                        </ActionSubtitle>
                      </Column>

                      <Column width={5}>
                        <ChevronRight />
                      </Column>
                    </Action>

                    <Action onClick={() => handleAction('no')}>
                      <Column width={3}>
                        <CloudUpload color="primary" />
                      </Column>

                      <Column width={92} noHPadding>
                        <ActionTitle>{t('uiActions.no')}</ActionTitle>
                        <ActionSubtitle>
                          {t('campaign.peopleList.noSubtitle')}
                        </ActionSubtitle>
                      </Column>

                      <Column width={5}>
                        <ChevronRight />
                      </Column>
                    </Action>
                  </Actions>
                </>
              )}
            </>
          ) : (
            <div style={{ padding: 24 }}>
              {!errors && uploadSuccess && (
                <Form
                  file={file}
                  data={processedFile}
                  processingLog={processingLog}
                />
              )}

              {!uploadSuccess && (
                <TabsContainer>
                  <Row height="46px">
                    <Tabs
                      value={currentTab}
                      textColor="primary"
                      indicatorColor="primary"
                      onChange={handleChangeCurrentTab}
                      aria-label="disabled tabs example"
                    >
                      <Tab
                        index={0}
                        label={t('campaign.peopleList.sendFile')}
                      />
                    </Tabs>
                  </Row>
                  <TabContent>
                    {currentTab === 0 && (
                      <>
                        {!uploadSuccess && errors && (
                          <Errors
                            data={errors}
                            type={errorType}
                            continue={canContinue}
                            resendFile={handleResendFile}
                            handleContinue={handleContinue}
                            retry={errorType === ErrorTypeEnum.minor}
                          />
                        )}

                        {!uploadSuccess && !errors && (
                          <ImportFile
                            onUpload={sendFile}
                            cancelPost={handleCancelPost}
                          />
                        )}

                        <HelpBox>
                          <HelpTitle>
                            {t('campaign.peopleList.sendSpreadsheetTitle')}
                          </HelpTitle>
                          <HelpDescription>
                            {t('campaign.peopleList.sendSpreadsheetDesc')}
                          </HelpDescription>

                          <HelpTitle>
                            {t('campaign.peopleList.knowMoreTitle')}
                          </HelpTitle>
                          <HelpDescription>
                            {t('campaign.peopleList.knowMoreDesc')}
                          </HelpDescription>

                          <Link
                            style={{ marginBottom: 40 }}
                            href="https://help.hiplatform.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('campaign.peopleList.helpBtn')}
                          </Link>

                          <Button
                            onClick={handleDownloadModel}
                            variant="contained"
                            color="primary"
                            size="large"
                          >
                            {t('campaign.peopleList.downloadModel')}
                          </Button>
                        </HelpBox>
                      </>
                    )}
                  </TabContent>
                </TabsContainer>
              )}
            </div>
          )}
        </>
      </Box>
    </Container>
  )
}

export default withTranslation()(PeopleList)
