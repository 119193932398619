import MenuItem from '@material-ui/core/MenuItem'

import '../../style/component/options/item.css'

const OptionsItem = ({ title, ...props }) => {
  return (
    <MenuItem onClick={props.onClick} {...props}>
      {title}
    </MenuItem>
  )
}

export default OptionsItem
