import { NPS_COLORS } from 'core/enums/nps-color'
import { NPS_ZONE } from 'core/enums/nps-zone'

function getColor(promoter, neutral, detractor) {
  if (promoter) return NPS_COLORS.promoter.color

  if (neutral) return NPS_COLORS.neutral.color

  if (detractor) return NPS_COLORS.detractor.color

  return NPS_COLORS.neutral.color
}

function getLightColor(promoter, neutral, detractor) {
  if (promoter) return NPS_COLORS.promoter.lightColor

  if (neutral) return NPS_COLORS.neutral.lightColor

  if (detractor) return NPS_COLORS.detractor.lightColor

  return NPS_COLORS.neutral.lightColor
}

function getDarkColor(promoter, neutral, detractor) {
  if (promoter) return NPS_COLORS.promoter.darkColor

  if (neutral) return NPS_COLORS.neutral.darkColor

  if (detractor) return NPS_COLORS.detractor.darkColor

  return NPS_COLORS.neutral.darkColor
}

function getZone(score, decimal) {
  if (!score) return

  const formatedScore = decimal ? score.replace(',', '.') : score

  if (formatedScore >= 80) return NPS_ZONE.excellence.text

  if (formatedScore >= 50) return NPS_ZONE.quality.text

  if (formatedScore >= 0) return NPS_ZONE.improvement.text

  if (formatedScore < 0) return NPS_ZONE.critical.text
}

function getColorByScore(score, returnType) {
  if (score >= 75) return returnType ? 3 : NPS_COLORS.promoter.color

  if (score >= 25) return returnType ? 2 : NPS_COLORS.neutral.color

  if (score < 25) return returnType ? 1 : NPS_COLORS.detractor.color

  return returnType ? 2 : NPS_COLORS.neutral.color
}

function returnByInput(score, promoter, neutral, detractor) {
  if (score < 7) return detractor

  if (score < 9 && score > 6) return neutral

  return promoter
}

export {
  getColor,
  getLightColor,
  getDarkColor,
  getZone,
  getColorByScore,
  returnByInput
}
