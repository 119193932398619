import styled from 'styled-components'

export const Container = styled.div``

export const SaveButton = styled.div`
  color: #fff;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
  background-color: #00aeef;
  width: 103px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  ${({ largeSize }) =>
    largeSize
      ? `
  width:300px;
  line-height: 56px;   
    margin: 0 auto;
    border-radius: 36px;
    height: 56px;
  
  `
      : ``}

  ${({ medium }) =>
    medium
      ? `
  width: 90px;
  height: 37px;
  border-radius: 5px;
  font-size: 14px;
  `
      : ``}
`
export const SimpleButton = styled.div`
  background-color: #e3f6fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 154px;
  height: 40px;
  border-radius: 2px;
  margin: auto;
  color: #00aeef;
  cursor: pointer;
`
export const Label = styled.h2`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;

  padding: ${({ padding }) => padding ?? 0};

  ${({ radio }) =>
    radio
      ? `
  line-height:42px;
  font-size:14px;
`
      : ``};
`

export const BoxGroup = styled.div`
  width: 634px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 13px;
`

export const Text = styled.label`
  ${({ center }) =>
    center
      ? `
  display: flex;
  text-align: center;
`
      : ``}
  font-size: 16px;

  width: 392px;
  margin: 0 auto;

  color: #757575;
  font-family: Roboto;
  line-height: 24px;
  margin-bottom: 19px;
`
export const SubText = styled.label`
  font-size: 14px;
  font-family: Roboto;
  line-height: 24px;
  display: block;
  margin-top: 16px;
`
export const SafeArea = styled.div`
  float: left;
  ${({ center }) =>
    center
      ? `
    float: inherit;
`
      : ``}
  ${({ box }) =>
    box
      ? `
        box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.11),
    0px 8px 10px 1px rgba(0, 0, 0, 0.13), 0px 5px 5px -3px rgba(0, 0, 0, 0.02);
  `
      : ``}
`
export const Row = styled.div`
  display: flex;

  ${({ center }) =>
    center ? 'justify-content: center;' : 'justify-content: flex-start;'}

  flex-direction: row;
  align-content: center;
  align-items: center;

  padding-top: auto;
  padding-bottom: auto;

  height: 68px;
  left: 0px;
  right: 0px;
  top: 0px;

  background: #ffffff;

  ${({ noBorder }) =>
    noBorder ? '' : 'border-bottom: 1px solid rgba(0, 0, 0, 0.12);'}
`

export const Colum = styled.div`
  ${({ medio }) => (medio ? 'width: 50%; float: left' : '')}
`
export const ButtonLabel = styled.div`
  color: #00aeef;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
`
export const Item = styled.div`
  display: grid;

  text-align: center;
  margin-bottom: 16px;
  ${({ starIcon }) => (starIcon ? ' margin: 5px;' : ' margin: 12px;')}
  ${({ floatLeft }) => (floatLeft ? 'float: left' : '')}
`
export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 44px;

  text-align: center;

  color: #000000;
  margin: 0 auto;
`
export const BoxChoice = styled.div`
  background: #e3f6fd;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  float: left;
  height: 93px;
  width: 187px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 7px;
`
export const Box = styled.div`
  width: 600px;

  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 8.71607px 44.5488px 7.74762px rgba(0, 0, 0, 0.11),
    0px 23.2429px 36.8012px 2.90536px rgba(0, 0, 0, 0.13);
  padding: 24px;
`

export const List = styled.div``
