import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: calc(100% - 124 * 2px);
  padding: 0px 124px;
`
export const ListItem = styled.div`
  display: flex;
  flex-direction: ${({ col }) => (col ? 'column' : 'row')};
  justify-content: space-between;
  ${({ left }) => (left ? 'align-items: left;' : 'align-items: center;')};
  ${({ left }) => (left ? 'align-content: left;' : 'align-content: center;')};
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => borderRadius ?? '4px'};
  ${({ borderBottomNone }) => (borderBottomNone ? 'border-bottom: none;' : '')}
  ${({ borderTopNone }) => (borderTopNone ? 'border-top: none;' : '')}
  padding: 12px 10px;
`
export const Block = styled.div`
  display: flex;
  margin: auto 0px;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  padding: ${({ padding }) => padding ?? 0};
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
`
export const FabWrapper = styled.div``
export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #212121;
  padding: ${({ padding }) => padding ?? 0};
`
export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;
  ${({ error }) => (error ? 'color: red;' : '')}
  padding: ${({ padding }) => padding ?? 0};
`
export const Subtitle = styled.h2`
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`
export const Spacer = styled.div`
  width: 20px;
`
export const Divider = styled.div`
  height: 32px;
`
export const useStyles = makeStyles(() => ({
  fab: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      cursor: 'default'
    },
    cursor: 'default'
  },
  alert: {
    width: '100%',
    color: '#00AEEF'
  },
  saveBtn: {
    borderRadius: 50,
    padding: 16
  }
}))
