import { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'

import axios from 'core/api'
import Tags from '../../../../components/Form/Option/Tags'

import Button from '@material-ui/core/Button'

class AnswerTag extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTagging: false,
      possibleTags: []
    }
  }

  render() {
    return (
      <div className="answer-tags animated slideInLeft faster">
        <Tags
          allowNew={true}
          tags={this.state.possibleTags}
          suggestions={this.props.suggestions}
          update={(tags) =>
            this.setState({
              possibleTags: tags
            })
          }
        />

        <Button
          color="primary"
          variant="contained"
          className="btn-answer-tags"
          onClick={() => this.saveTags()}
        >
          {this.props.t('answer.saveTag')}
        </Button>
      </div>
    )
  }

  saveTags = () => {
    if (this.state.possibleTags.length > 0) {
      this.addTag(this.state.possibleTags)
    }
  }

  addTag = (tags) => {
    let requests = []

    tags.forEach((tag) => {
      requests.push(
        axios.post('tag/setnpstag?', {
          IdNps: this.props.id,
          Name: tag.name
        })
      )
    })

    this.setState({ loading: true })

    Promise.all(requests)
      .then((res) => {
        if (res.length < 0) throw Error('Error on Add')

        let newTags = this.props.current || []

        res.forEach((element) => {
          if (element.data.HasErrors) throw Error('Error on Add')

          newTags.push(element.data.Element.Tag)
        })

        this.props.setTags(newTags)
        this.setState({
          possibleTags: []
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }
}

export default withTranslation()(withSnackbar(AnswerTag))
