import { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'

import axios from 'core/api'

import AnswerItem from '../AnswerItem'
import OldPagination from '../../../../components/OldPagination'

class AnswerList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: null
    }
  }

  componentDidMount() {
    if (this.props.tags) {
      this.setState({
        tags: this.props.tags
      })
      return
    }

    axios
      .get('tag/getall')
      .then((res) => {
        this.setState({
          tags: res.data
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.search !== prevProps.location.search)
      window.scrollTo(0, 0)
  }

  render() {
    return (
      <article>
        <section>
          {this.state.tags &&
            this.props.answers.length > 0 &&
            this.props.answers
              .filter((v, i, a) => a.findIndex((t) => t.Key === v.Key) === i)
              .map((item) => (
                <AnswerItem
                  answer={item}
                  key={item.Key}
                  tags={this.state.tags}
                  campaigns={this.props.campaigns}
                />
              ))}
          {this.props.answers.length < 1 && !this.props.app.loading && (
            <div className="answer-list-no-results">
              {this.props.t('answer.resultsEmpty')}
            </div>
          )}
        </section>
        <OldPagination
          current={this.props.pagination.current}
          total={this.props.pagination.total}
        />
      </article>
    )
  }
}

export default withTranslation()(withRouter(withSnackbar(AnswerList)))
