import { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'

import i18n from 'i18next'

import { BASE_PATH } from 'router'

import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/Assignment'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'

import { KeyboardDatePicker } from '@material-ui/pickers'

import {
  useStyles,
  Container,
  Content,
  ListItem,
  Block,
  Title,
  Label,
  Subtitle,
  Spacer,
  Divider
} from './style'

import Header from 'components/Header'
import Form from '../Form'
import Email from '../Email'
import Widget from '../Widget'
import { useCampaign } from './context'

import PeopleList from '../PeopleList'
import Dispatch from '../Dispatch'
import { addMonths, addDays } from 'date-fns'

import VerifyDialog from './components/VerifyDialog'
import CopyToClipboard from 'components/CopyToClipboard'
import { uuid } from 'core/helpers'

import { useAppContext } from 'App'

import ChoiceCampaign from '../ChoiceCampaign'
import { PeopleListProvider } from '../PeopleList/context'
import createLink from 'core/functions/make-link-campaign'
import getData from 'core/functions/get-data.function'
import useGetData from 'core/hooks/useGetData'

import queryString from 'query-string'
import { Skeleton } from '@material-ui/lab'
import { dateToIsoWithTimezone } from 'core/functions/date-to-iso-with-timezone'

const getDefaultFormikValues = (campaign, link) => {
  return {
    IntervalStart:
      campaign.IntervalStartWithTimezone ||
      campaign.IntervalStart ||
      new Date(),
    IntervalEnd:
      campaign.IntervalEndTimezone ||
      campaign.IntervalEnd ||
      addMonths(new Date(), 1),
    Name: campaign.Name || '',
    Description: campaign.Description || '',
    AcceptAnonymousInteraction: campaign.AcceptAnonymousInteraction || false,
    IdForm: campaign.IdForm || '',
    HasExpirationInterval: campaign?.HasExpirationInterval || false,
    hasWidget: campaign?.WidgetConfiguration?.Active || false,
    hasEmail: campaign?.EmailConfiguration?.Active || false,
    AllPersonsCampaign: true,
    Key: campaign.Key || '',
    SetLink: link || ''
  }
}

function Details({ match, t }) {
  const history = useHistory()
  const classes = useStyles()
  const { search } = useLocation()

  const { language } = i18n
  const {
    form,
    details,
    handleSetDetails,
    campaign,
    handleSetCampaign,
    handleSetEmail,
    email,
    handleSetWidget,
    handleSetForm,
    handleSetDispatch,
    handleSetEmailList
  } = useCampaign()

  const changeEmailScreen = () =>
    history.push({ pathname: BASE_PATH + 'campaign/email', search })

  const store = JSON.parse(localStorage.getItem('__s_'))

  const [link, setLink] = useState(createLink())
  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(true)
  const [parsedQS, setParsedQS] = useState(queryString.parse(search))

  const campaignOnChange = useCallback(
    (controlOnChange, changeEvent, name) => {
      const value =
        changeEvent.target.type === 'checkbox'
          ? changeEvent.target.checked
          : changeEvent.target.value

      handleSetCampaign({ ...campaign, [name]: value })
      controlOnChange(changeEvent)
    },
    [campaign, handleSetCampaign]
  )

  const onIntervalStartChange = useCallback(
    (controlOnChange, value) => {
      controlOnChange(value)
      let startWithCorrectHours = new Date(value)
      startWithCorrectHours.setHours(0, 0, 0, 0)
      handleSetCampaign({
        ...campaign,
        IntervalStart: dateToIsoWithTimezone(startWithCorrectHours)
      })
    },
    [campaign, handleSetCampaign]
  )

  const onIntervalEndChange = useCallback(
    (controlOnChange, value) => {
      controlOnChange(value)
      let endWithCorrectHours = new Date(value)
      endWithCorrectHours.setHours(23, 59, 59, 999)
      handleSetCampaign({
        ...campaign,
        IntervalEnd: dateToIsoWithTimezone(endWithCorrectHours)
      })
    },
    [campaign, handleSetCampaign]
  )

  useEffect(() => {
    if (!parsedQS?.Id) {
      setLoading(false)
      setFormLoading(false)

      return
    }

    setParsedQS(queryString.parse(search))

    const initCampaign = async (data) => {
      const { IdForm, IdYourviews } = data

      handleSetEmail(data.EmailConfiguration)
      handleSetWidget(data.WidgetConfiguration)

      const firstFormikValues = getDefaultFormikValues(data, link)
      for (let prop in firstFormikValues) {
        setValue(prop, firstFormikValues[prop])
      }

      const campaignData = data

      const newEmailConfig = { ...campaignData.EmailConfiguration }
      if (
        !!newEmailConfig.GratefulEmailIdTemplate &&
        !newEmailConfig.GratefulEmailNameTemplate
      ) {
        await getData(
          'template/get?IdTemplate=' + newEmailConfig.GratefulEmailIdTemplate,
          (response) => {
            if (response.Name) {
              newEmailConfig.GratefulEmailNameTemplate = response.Name
            }
          }
        )
      }
      if (
        !!newEmailConfig.RememberEmailIdTemplate &&
        !newEmailConfig.RememberEmailNameTemplate
      ) {
        await getData(
          'template/get?IdTemplate=' + newEmailConfig.RememberEmailIdTemplate,
          (response) => {
            if (response.Name) {
              newEmailConfig.RememberEmailNameTemplate = response.Name
            }
          }
        )
      }
      if (
        !!newEmailConfig.NpsRequestEmailIdTemplate &&
        !newEmailConfig.NpsRequestEmailNameTemplate
      ) {
        await getData(
          'template/get?IdTemplate=' + newEmailConfig.NpsRequestEmailIdTemplate,
          (response) => {
            if (response.Name) {
              newEmailConfig.NpsRequestEmailNameTemplate = response.Name
            }
          }
        )
      }
      if (JSON.stringify(email) !== JSON.stringify(newEmailConfig)) {
        handleSetEmail(newEmailConfig)
      }

      delete campaignData.EmailConfiguration
      delete campaignData.WidgetConfiguration

      handleSetDetails(campaignData)

      getData('Form/Get?IdForm=' + IdForm, handleSetForm, setFormLoading)

      getData(
        'DispatchSendingSchedule/GetByIdCampaign?IdCampaign=' + IdYourviews,
        handleSetDispatch,
        setLoading
      )
    }

    if (parsedQS && parsedQS.Id) {
      getData('campaign/get?Id=' + parsedQS?.Id, initCampaign)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!search.includes('Id')) {
      if (match.params.item === 'details' && !campaign.IdForm) {
        history.push(BASE_PATH + 'campaign')
      }
    }

    const newLink = createLink(store.Key, campaign.Key)
    setLink(newLink)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const { handleSubmit, control, setValue } = useForm({
    defaultValues: getDefaultFormikValues(campaign, link)
  })

  const { notify } = useAppContext()

  const onSubmit = async (data) => {
    if (!data.Key) {
      data.Key = uuid.generator()
    }

    if (!form.IdYourviews) {
      notify('Selecione um formulário para prosseguir', 'error')
    } else {
      data.Description = data.Name
      data.Active = campaign.Active

      if (!campaign.HasExpirationInterval) {
        delete campaign.Interval
        delete details.Interval

        delete data.IntervalStart
        delete data.IntervalEnd
      }

      delete data.startDate
      delete data.endDate

      await handleSetDetails({
        ...details,
        ...data
      })

      handleOpen()
    }
  }

  const handleChangeCampaignStatus = (value, type) => {
    switch (type) {
      case 'email':
        return (
          handleSetEmail({
            ...campaign.EmailConfiguration,
            Active: value
          }),
          handleSetDetails({ ...details, hasWidget: value })
        )
      case 'widget':
        return (
          handleSetWidget({
            ...campaign.WidgetConfiguration,
            Active: value
          }),
          handleSetDetails({ ...details, hasWidget: value })
        )
      default:
        return
    }
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const handleSelectForm = useCallback(() => {
    history.push({
      pathname: BASE_PATH + 'campaign/form',
      search
    })
  }, [history, search])

  useGetData({
    endpoint: 'TargetList/GetAll',
    setData: handleSetEmailList
  })

  return (
    <Container>
      {match.params.item === 'new' && <ChoiceCampaign />}

      {!loading && !formLoading && match.params.item === 'form' && <Form />}

      {!loading && match.params.item === 'email' && <Email />}
      {!loading && match.params.item === 'import' && (
        <PeopleListProvider>
          <PeopleList />
        </PeopleListProvider>
      )}

      {!loading && match.params.item === 'dispatch' && <Dispatch />}
      {!loading && match.params.item === 'review' && <Email review />}

      {!loading && match.params.item === 'widget' && <Widget />}

      {match.params.item === 'details' && (
        <>
          <Content>
            {campaign.Type === 0 && (
              <Header title={t('campaign.details.title.nps')} />
            )}
            {campaign.Type === 1 && (
              <Header title={t('campaign.details.title.csat')} />
            )}

            <Divider />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Block column>
                <Title padding="8px 0px">
                  {t('campaign.details.form.title')}
                </Title>
                {loading || formLoading ? (
                  <Skeleton width="100%" height="110px" />
                ) : (
                  <ListItem>
                    <Block>
                      <Fab
                        color="primary"
                        disableFocusRipple
                        disableRipple
                        classes={{ root: classes.fab }}
                      >
                        <AssignmentOutlinedIcon />
                      </Fab>

                      <Block padding="0px 16px" column>
                        <Subtitle>
                          {t('campaign.details.form.subtitle')}
                        </Subtitle>
                        <Label error={!form?.Name}>
                          {form?.Name ?? t('campaign.newDetails.selectForm')}
                        </Label>
                      </Block>
                    </Block>

                    <Button color="primary" onClick={handleSelectForm}>
                      {t('campaign.details.form.changeForm')}
                    </Button>
                  </ListItem>
                )}
              </Block>

              <Block padding="32px 0px 10px 0px" maxWidth="450px">
                {loading ? (
                  <Skeleton width="90%" height="50px" />
                ) : (
                  <Controller
                    name="Name"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        required
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        onBlur={onBlur}
                        variant="outlined"
                        onChange={(e) => campaignOnChange(onChange, e, 'Name')}
                        error={!!errors?.Name}
                        data-testid="campaign-name"
                        label={t('campaign.details.name')}
                        helperText={
                          !!errors.Name
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                      />
                    )}
                  />
                )}
              </Block>

              <Block column>
                <Title padding="8px 0px">
                  {t('campaign.details.channels.title')}
                </Title>

                {loading ? (
                  <Skeleton width="100%" height="110px" />
                ) : (
                  <ListItem borderRadius="4px 4px 0px 0px">
                    <Block>
                      <Fab
                        disableFocusRipple
                        disableRipple
                        classes={{ root: classes.fab }}
                        style={{
                          background: campaign?.EmailConfiguration?.Active
                            ? '#00aeef'
                            : 'rgba(0, 0, 0, 0.05)'
                        }}
                      >
                        <EmailOutlinedIcon
                          style={{
                            fill: campaign?.EmailConfiguration?.Active
                              ? 'white'
                              : 'rgba(0, 0, 0, 0.54)'
                          }}
                        />
                      </Fab>

                      <Block padding="0px 16px" column>
                        <Subtitle>
                          {t('campaign.details.channels.sendBy')}
                        </Subtitle>
                        <Label>{t('campaign.details.channels.email')}</Label>
                      </Block>
                    </Block>

                    <Block>
                      {campaign?.EmailConfiguration?.NpsRequestEmailSubject && (
                        <FormControlLabel
                          control={
                            <Switch
                              name="emailChange"
                              size="small"
                              color="primary"
                              checked={campaign?.EmailConfiguration?.Active}
                              onChange={(e) =>
                                handleChangeCampaignStatus(
                                  e.target.checked,
                                  'email'
                                )
                              }
                            />
                          }
                          label={
                            campaign?.EmailConfiguration?.Active
                              ? 'Ativo'
                              : 'Inativo'
                          }
                        />
                      )}
                      <Button
                        color="primary"
                        data-testid="nps-config-button"
                        onClick={changeEmailScreen}
                      >
                        {t('campaign.details.configAction')}
                      </Button>
                    </Block>
                  </ListItem>
                )}
                {loading ? (
                  <Skeleton width="100%" height="110px" />
                ) : (
                  <>
                    {campaign.Type === 0 && (
                      <ListItem borderTopNone borderRadius="0px 0px 4px 4px">
                        <Block>
                          <Fab
                            disableFocusRipple
                            disableRipple
                            classes={{ root: classes.fab }}
                            style={{
                              background: campaign?.WidgetConfiguration?.Active
                                ? '#00aeef'
                                : 'rgba(0, 0, 0, 0.05)'
                            }}
                          >
                            <PowerOutlinedIcon
                              style={{
                                fill: campaign?.WidgetConfiguration?.Active
                                  ? 'white'
                                  : 'rgba(0, 0, 0, 0.54)'
                              }}
                            />
                          </Fab>

                          <Block padding="0px 16px" column>
                            <Subtitle>
                              {t('campaign.details.channels.insert')}
                            </Subtitle>
                            <Label>
                              {t('campaign.details.channels.system')}
                            </Label>
                          </Block>
                        </Block>

                        <Block>
                          {campaign?.WidgetConfiguration?.Title && (
                            <FormControlLabel
                              control={
                                <Switch
                                  name="widgetChange"
                                  size="small"
                                  color="primary"
                                  checked={
                                    campaign?.WidgetConfiguration?.Active
                                  }
                                  onChange={(e) =>
                                    handleChangeCampaignStatus(
                                      e.target.checked,
                                      'widget'
                                    )
                                  }
                                />
                              }
                              label={
                                campaign?.WidgetConfiguration?.Active
                                  ? 'Ativo'
                                  : 'Inativo'
                              }
                            />
                          )}
                          <Button
                            color="primary"
                            onClick={() =>
                              history.push({
                                pathname: '/admin/campaign/widget',
                                search
                              })
                            }
                            data-testid="widget-config-button"
                          >
                            {t('campaign.details.configAction')}
                          </Button>
                        </Block>
                      </ListItem>
                    )}
                  </>
                )}
              </Block>

              <Block column padding="32px 0px">
                {link !== createLink() && (
                  <>
                    <Block style={{ justifyContent: 'space-between' }}>
                      <Title padding="8px 0px">
                        {t('campaign.details.link.title')}
                      </Title>
                      {link !== createLink() && (
                        <CopyToClipboard
                          dontText={true}
                          position="end"
                          text={link}
                          icone={false}
                        />
                      )}
                    </Block>

                    {/* <Block padding="12px 0px">
                      <Alert severity="info" classes={{ root: classes.alert }}>
                        {t('campaign.details.link.message.info')}
                      </Alert>
                    </Block> */}

                    <Block>
                      {loading ? (
                        <Skeleton width="100%" height="50px" />
                      ) : (
                        <Controller
                          name="SetLink"
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { errors }
                          }) => (
                            <TextField
                              value={link}
                              fullWidth
                              disabled
                              size="small"
                              variant="outlined"
                              id="campaign-link"
                              data-testid="campaign-link"
                              label="Link"
                            />
                          )}
                        />
                      )}
                    </Block>
                  </>
                )}
              </Block>

              <Block column>
                <Title padding="8px 0px">
                  {t('campaign.details.period.title')}
                </Title>

                <Block>
                  <FormControl component="fieldset">
                    <FormGroup>
                      {loading ? (
                        <Skeleton width="150px" height="50px" />
                      ) : (
                        <Controller
                          name="Active"
                          control={control}
                          type="checkbox"
                          render={({ field: { onChange, value } }) => (
                            <div>
                              <Switch
                                onChange={(e) => {
                                  handleSetDetails({
                                    ...campaign,
                                    Active: e.target.checked
                                  })
                                  onChange(e)
                                }}
                                checked={campaign.Active}
                                color="primary"
                                value={value}
                              />
                              {t(
                                'campaign.details.period.activeCampaign.title'
                              )}
                            </div>
                          )}
                        />
                      )}

                      {!loading && (
                        <FormHelperText
                          style={{ fontSize: 14, marginLeft: 60, marginTop: 0 }}
                        >
                          {t('campaign.details.period.activeCampaign.legend')}
                        </FormHelperText>
                      )}

                      {loading ? (
                        <Skeleton width="150px" height="50px" />
                      ) : (
                        <Controller
                          name="HasExpirationInterval"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState
                          }) => (
                            <div>
                              <Switch
                                onBlur={onBlur}
                                onChange={(e) => {
                                  campaignOnChange(
                                    onChange,
                                    e,
                                    'HasExpirationInterval'
                                  )
                                }}
                                checked={value}
                                inputRef={ref}
                                color="primary"
                              />
                              {t('campaign.details.period.withDuration')}
                            </div>
                          )}
                        />
                      )}
                    </FormGroup>
                  </FormControl>
                </Block>

                {!loading && campaign.HasExpirationInterval && (
                  <Block>
                    <Block maxWidth="150px">
                      <Controller
                        name="IntervalStart"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState: { errors }
                        }) => (
                          <KeyboardDatePicker
                            maxDate={addDays(
                              new Date(campaign.IntervalEnd),
                              -1
                            )}
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            id="date-picker-inline"
                            value={value}
                            autoOk
                            PopoverProps={{
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                              },
                              transformOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center'
                              }
                            }}
                            onChange={(e) => onIntervalStartChange(onChange, e)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            label={t('campaign.details.period.startDate')}
                            format={
                              language === 'en' || language === 'en-US'
                                ? 'MM/dd/yyyy'
                                : 'dd/MM/yyyy'
                            }
                          />
                        )}
                      />
                    </Block>

                    <Spacer />

                    <Block maxWidth="150px">
                      <Controller
                        name="IntervalEnd"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState: { errors }
                        }) => (
                          <KeyboardDatePicker
                            minDate={new Date(campaign.IntervalStart)}
                            disableToolbar
                            variant="inline"
                            margin="normal"
                            id="date-picker-inline"
                            value={value}
                            autoOk
                            PopoverProps={{
                              anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                              },
                              transformOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center'
                              }
                            }}
                            onChange={(e) => onIntervalEndChange(onChange, e)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            label={t('campaign.details.period.endDate')}
                            format={
                              language === 'en' || language === 'en-US'
                                ? 'MM/dd/yyyy'
                                : 'dd/MM/yyyy'
                            }
                          />
                        )}
                      />
                    </Block>
                  </Block>
                )}
              </Block>

              <Block padding="32px 0px">
                <Button
                  data-testid="save-campaign-button"
                  classes={{ root: classes.saveBtn }}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit(onSubmit)}
                >
                  {campaign.Active
                    ? t('campaign.details.save')
                    : t('campaign.details.saveDraft')}
                </Button>
              </Block>
            </form>
          </Content>
        </>
      )}

      <VerifyDialog open={open} handleClose={handleClose} />
    </Container>
  )
}

Details.propTypes = {
  form: PropTypes.object
}

export default withTranslation()(Details)
