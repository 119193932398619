import { withTranslation } from 'react-i18next'

import { FaChevronDown, FaChevronUp, FaMinus } from 'react-icons/fa'

import '../../style/component/card/simple.css'

const SimpleCard = ({
  className,
  title,
  height,
  data,
  noResult,
  icon,
  growth,
  hasGrowth,
  label
}) => (
  <div className={'simple-card ' + (className ? className : '')}>
    <label className="simple-card-label">{title}</label>
    <div className="simple-card-body" style={{ minHeight: height }}>
      <label className="simple-card-value">
        <span className="simple-card-value-data">
          {data === 0 && noResult ? '—' : data}
          {icon}
        </span>
        {!(data === 0 && noResult) && !isNaN(growth) && (
          <small className="simple-card-value-growth">
            {hasGrowth ? (
              <span>
                {growth > 0 && <FaChevronUp className="icon-chev" />}
                {growth < 0 && <FaChevronDown className="icon-chev" />}
                {growth === 0 && <FaMinus />}
                {growth} %
              </span>
            ) : (
              <FaMinus />
            )}
          </small>
        )}
      </label>
      {label && <small className="simple-card-description">{label}</small>}
    </div>
  </div>
)

export default withTranslation()(SimpleCard)
