import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 380
  },
  nested: {
    paddingLeft: '40px !important'
  },
  active: {
    color: '#00AEEF'
  },
  itemActive: {
    backgroundColor: '#00aeef1C'
  },
  subItemActive: {
    backgroundColor: '#00aeef1C'
  },
  listItem: {
    paddingLeft: 25
  }
}))

export default useStyles
