/* eslint-disable no-undef */
import queryString from 'query-string'

import { QS_DATE_FROM, QS_DATE_TO, QS_PAGE } from '../constants/query-strings'

/**
 * Configures the URL so that the query string is done correctly according to the selected filter parameters
 * @param {Object} props Props from component
 * @param {Object} values Value for query string
 * @param {Function} finishActionFnCallback Final action function to be executed (ex. Close modal or close filter tab)
 *
 * @returns void
 */
const setFilter = (props, values, finishActionFnCallback, clearPage) => {
  let current = queryString.parse(props.location.search)

  const date = values.date
  if (date && typeof date === 'object') {
    current[QS_DATE_FROM] = date?.startDate?.toISOString()
    current[QS_DATE_TO] = date?.endDate?.toISOString()
  } else if (date && typeof date === 'string') {
    current[QS_DATE_FROM] = date.split('@')[0]
    current[QS_DATE_TO] = date.split('@')[1]
  }

  delete values.date

  for (let key in values) {
    if (
      values[key] &&
      values[key].toString().length > 0 &&
      values[key] !== 'all'
    ) {
      current[key] = values[key]
    } else {
      delete current[key]
    }
  }

  if (clearPage) {
    delete current[QS_PAGE]
  }

  props.history.push({
    pathname: props.location.pathname,
    search: queryString.stringify(current)
  })

  if (finishActionFnCallback) {
    finishActionFnCallback()
  }
}

export default setFilter
