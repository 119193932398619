export const NPS_COLORS = {
  detractor: {
    min: 0,
    max: 7,
    color: '#ff6262',
    lightColor: '#FDEAE9',
    darkColor: '#EF3E39'
  },
  neutral: {
    min: 7,
    max: 9,
    color: '#fcff2d',
    lightColor: '#FDEDD3',
    darkColor: '#F17C0C'
  },
  promoter: {
    min: 9,
    max: 11,
    color: '#69ff44',
    lightColor: '#DFF1DA',
    darkColor: '#339620'
  }
}
