import '../../style/component/nps-color/nps-label.css'
import { nps } from 'core/helpers'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import { useMemo } from 'react'
import { withTranslation } from 'react-i18next'

const NpsLabel = ({ type, rating, t }) => {
  const currentType = useMemo(() => {
    if (type === NPS_CSAT_TAB.csat.typeValue) {
      if (rating < 3) return '1'

      if (rating >= 3 && rating < 4) return '2'

      if (rating >= 4) return '3'
    } else {
      if (rating < 7) return '1'

      if (rating < 9) return '2'

      if (rating > 8) return '3'
    }
  }, [rating, type])

  const color = useMemo(() => {
    return nps.getLightColor(
      currentType === '3',
      currentType === '2',
      currentType === '1'
    )
  }, [currentType])

  const txtColor = useMemo(() => {
    return nps.getDarkColor(
      currentType === '3',
      currentType === '2',
      currentType === '1'
    )
  }, [currentType])

  const typeString = useMemo(
    () => (type === NPS_CSAT_TAB.csat.typeValue ? 'csat' : 'nps'),
    [type]
  )

  return (
    <span
      className="nps-label"
      style={{
        backgroundColor: color,

        color: txtColor
      }}
    >
      {currentType === '3' && t(typeString + 'Type.3')}
      {currentType === '2' && t(typeString + 'Type.2')}
      {currentType === '1' && t(typeString + 'Type.1')}
    </span>
  )
}

export default withTranslation()(NpsLabel)
