import queryString from 'query-string'
import { useState, useEffect, useMemo } from 'react'
import { withTranslation, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { sub } from 'date-fns'
import { dateToLocale } from 'core/functions/format-date.function'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import DateRangeIcon from '@material-ui/icons/DateRange'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import Calendar from '../Calendar'
import ReadonlyField from '../ReadonlyField'
import setDateQs, { parseDateToQs } from 'core/functions/set-date-qs.function'
import getCurrentDate from 'core/functions/get-current-date.function'
import compareDate from 'core/functions/compare-date.function'
import useStyle from './style'
import ReadonlySelect from 'components/ReadonlySelect'
import PreSelectionDate from './dictionaries/preselection-date'
import DateSelectionMap from './dictionaries/date-selection'
import { QS_DATE_FROM, QS_DATE_TO } from 'core/constants/query-strings'

const DateRangePicker = ({ field, form, select, ...props }) => {
  const location = useLocation()
  const history = useHistory()
  const classes = useStyle()
  const { i18n } = useTranslation()

  const [currentLang, setCurrentLang] = useState(i18n.language)
  const [label, setLabel] = useState(dateToLocale(new Date(), currentLang))
  const [anchorEl, setAnchorEl] = useState(null)

  const [{ from, to }] = useState(getCurrentDate(location))
  const [showDatepicker, setShowDatepicker] = useState(false)

  const [selectedRange, setSelectedRange] = useState(field?.value ?? null)
  const handleSetSelectedRange = (value) => {
    setSelectedRange(value)
  }

  const [date, setDate] = useState(
    field?.value?.startDate
      ? field?.value
      : {
          startDate: from ?? sub(new Date(), { years: 10 }),
          endDate: to ?? new Date()
        }
  )

  const makeLabel = (start, end, lang) =>
    compareDate(start, end)
      ? {
          from: dateToLocale(start, lang)
        }
      : {
          from: dateToLocale(start, lang),
          to: dateToLocale(end, lang)
        }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setShowDatepicker(false)
    }
  }

  const handleSetDateQS = (from, to) => {
    if (!field) {
      setDateQs(from, to, location, history)
    } else {
      form?.setFieldValue(field?.name, parseDateToQs(from, to))
    }
  }

  const applySelectedRange = () => {
    setDate(selectedRange)
  }

  const [type, setType] = useState(null)
  const handlePreSelect = (type) => {
    setType(type)

    if (type === 0) return

    setDate(PreSelectionDate.get(type))
  }

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleApply = () => applySelectedRange()

  const handleClose = () => {
    setAnchorEl(null)
    setShowDatepicker(false)
  }

  useEffect(() => {
    const { endDate, startDate } = date

    i18n.on('languageChanged', (lang) => {
      setCurrentLang(lang)
      setLabel(makeLabel(startDate, endDate, lang))
    })

    return () => i18n.off('languageChanged', () => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const currentFromValue = useMemo(() => {
    return queryString.parse(location.search)[QS_DATE_FROM]
  }, [location.search])

  const currentToValue = useMemo(() => {
    return queryString.parse(location.search)[QS_DATE_TO]
  }, [location.search])

  useEffect(() => {
    const { endDate, startDate } = date || {}
    setLabel(makeLabel(startDate, endDate))

    if (
      startDate &&
      endDate &&
      startDate?.toISOString() !== currentFromValue &&
      endDate?.toISOString() !== !currentToValue
    ) {
      handleClose()

      handleSetDateQS(startDate, endDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, location.key])

  const makeLabelForSelect = (x) => `${x.from} ${x.to ? ' - ' + x.to : ''}`

  const getItemByKey = (key, item, list) => list.find((x) => x[key] === item)

  const handleLabel = (type) => {
    if (!type || type === 0) return makeLabelForSelect(label)
    else return props.t(getItemByKey('value', type, DateSelectionMap).title)
  }

  return (
    <div data-testid="dropdown-date-menu">
      {select ? (
        <ReadonlySelect label={handleLabel(type)} handleClick={handleClick} />
      ) : (
        <ReadonlyField
          text={label}
          select={select}
          handleClick={handleClick}
          icon={<CalendarTodayIcon />}
        />
      )}

      <Menu
        keepMounted
        elevation={2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        id="dropdown-date-menu"
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
      >
        <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
          {!showDatepicker && (
            <div>
              {DateSelectionMap.map((dateSelection, i) => (
                <MenuItem
                  key={i}
                  onClick={() => handlePreSelect(dateSelection.value)}
                >
                  {props.t(dateSelection.title)}
                </MenuItem>
              ))}

              <Divider />

              <MenuItem>
                <ReadonlyField
                  text={label}
                  icon={<DateRangeIcon />}
                  handleClick={() => {
                    handlePreSelect(0)
                    setShowDatepicker(true)
                  }}
                />
              </MenuItem>
            </div>
          )}
          {showDatepicker && (
            <Grid container direction="column">
              <Grid item>
                <div className={classes.actions}>
                  <Button
                    className={classes.button}
                    onClick={() => setShowDatepicker(false)}
                  >
                    {props.t('uiActions.back')}
                  </Button>
                  <Button
                    color="primary"
                    className={classes.button}
                    onClick={handleApply}
                  >
                    {props.t('uiActions.apply')}
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <Calendar
                  maxDate={new Date()}
                  onSelect={(date) => handleSetSelectedRange(...date)}
                />
              </Grid>
            </Grid>
          )}
        </MenuList>
      </Menu>
    </div>
  )
}

export default withTranslation()(DateRangePicker)
