import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  table: {
    maxWidth: 400,
    maxHeight: 200
  },
  ratingTable: {
    maxWidth: 250,
    maxHeight: 300
  },
  cardRoot: {
    width: 150,
    height: 190
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  averageCard: {
    width: 150,
    height: 200
  },
  title: {
    fontSize: 50,
    margin: 10
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'center',
    color: '#9E9E9E',
    margin: 20
  },
  description: {
    fontSize: 12,
    color: 'rgba(158, 158, 158, 0.4)',
    margin: 20
  },
  grid: {
    display: 'flex'
  }
}))

export default useStyles
