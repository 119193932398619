function toDropdown(raw, allLabel) {
  let result = []
  if (raw.length > 0) {
    result = raw.map((item) => {
      return {
        id: item.IdYourviews,
        name: item.Name
      }
    })
    if (allLabel) {
      result.unshift({ id: 'all', name: allLabel })
    }
  }
  return result
}

export { toDropdown }
