import { useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router'

import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'

import Menu from './components/Menu'
import Navbar from './components/Navbar'

import { WithoutDrawerRoutes } from './dictionaries/WithoutDrawerRoutes'
import { useAppContext } from 'App'

import useStyles from './style'

const BASE_PATH = '/admin/'

function AppDrawer({ children }) {
  const classes = useStyles()
  const location = useLocation()
  const { menuState, handleSetMenuState } = useAppContext()

  const handleSetState = () => {
    handleSetMenuState(!menuState)
  }

  const [noDrawer, setNoDrawer] = useState(true)

  useEffect(() => {
    setNoDrawer(
      WithoutDrawerRoutes.find(
        (route) => BASE_PATH + route === location.pathname
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: menuState
        })}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        className={`
          ${classes.appBar}
          ${noDrawer ? classes.hide : ''}
        `}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleSetState}
            className={classes.menuButton}
            aria-label={menuState ? 'close drawer' : 'open drawer'}
          >
            {menuState ? <CloseRoundedIcon /> : <MenuIcon />}
          </IconButton>
          <Navbar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={`
        ${clsx(classes.drawer, {
          [classes.drawerOpen]: menuState,
          [classes.drawerClose]: !menuState
        })}
        ${noDrawer ? classes.hide : ''}
        `}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: menuState,
            [classes.drawerClose]: !menuState
          })
        }}
      >
        <div className={classes.toolbar} />
        <Menu closeMenu={menuState} />
      </Drawer>
      <main className={noDrawer ? classes.contentNotLogged : classes.content}>
        {!noDrawer && <div style={{ height: 50 }} />}

        {children}
      </main>
    </div>
  )
}
export default withTranslation()(AppDrawer)
