export const NPS_ZONE = {
  critical: {
    min: -100,
    max: 0,
    text: 'critical',
    color: '#EF3E39'
  },
  improvement: {
    min: 0,
    max: 50,
    text: 'improvement',
    color: '#F7A723'
  },
  quality: {
    min: 50,
    max: 80,
    text: 'quality',
    color: '#60BB46'
  },
  excellence: {
    min: 80,
    max: 101,
    text: 'excellence',
    color: '#00AEEF'
  }
}
