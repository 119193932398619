import { Button } from '@material-ui/core'

import useStyles from './style'

export default function BigButton({ icon, title, onClick, id }) {
  const classes = useStyles()

  return (
    <Button
      size="large"
      color="primary"
      startIcon={icon}
      variant="contained"
      onClick={onClick}
      className={classes.root}
      data-testid={id}
    >
      {title}
    </Button>
  )
}
