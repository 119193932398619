import { useState, useRef } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'
import RoundedButton from 'components/Button'

import 'style/component/options/main.css'

const useStyles = makeStyles(() => ({
  wrap: {
    left: '-20px',
    zIndex: 5
  }
}))

const Options = ({ children, title, className, roundedButton, ...props }) => {
  const [active, setActive] = useState(false)
  const anchorRef = useRef(null)

  return (
    <div
      className={`options ${className ? className : ''}`}
      ref={anchorRef}
      {...props}
    >
      {roundedButton
        ? (
          <RoundedButton
            whiteTextLabel
            type="add"
            size="large"
            variant="contained"
            onClick={() => setActive(!active)}
          />
        )
        : (
          <IconButton
            type="button"
            color="primary"
            onClick={() => setActive(!active)}
          >
            {title}
          </IconButton>
        )
      }
      <Popper
        open={active}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        className={useStyles().wrap}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setActive(false)}>
                <MenuList
                  autoFocusItem={active}
                  id="menu-list-grow"
                  onKeyDown={() => setActive(false)}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

export default Options
