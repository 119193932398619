import axios from 'core/api'

import { queryString as qs } from 'core/helpers'

/**
 * Export answers and download `.xlsx` file
 * @param {objetct} state State from class component to queryString fn
 * @param {object} props Props from component to queryString fn
 * @param {boolean} setLoading Control loading state, you can pass a setState function like `setLoading`
 * @param {Function} notify Call notify on finally
 */
const exportData = async (state, props, setLoading, notify) => {
  setLoading && setLoading(true)
  const query = qs.get(state, true)

  try {
    const res = await axios.get(`nps/exportnpslist${query}`)

    if (!res || !res.data || !res.data.Success)
      throw new Error({ message: 'Error on exporting' })

    var link = document.createElement('a')
    link.href = res.data.Url

    link.click()

    if (res.data.Success) notify({ type: 'success', link: res.data.Url })
  } catch (error) {
    if (error.message) notify({ type: 'error', message: error.message })
  } finally {
    setLoading && setLoading(false)
  }
}

export default exportData
