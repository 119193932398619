import './index.css'
import { withTranslation } from 'react-i18next'
import emptyReport from 'assets/img/empty-report.svg'

const EmptyState = ({ t, description }) => {
  return (
    <div className="conversion-empty-state">
      <img src={emptyReport} alt="Empty report" />

      <div className="conversion-empty-state-title">
        {t('conversion-report.empty.title')}
      </div>

      <div className="conversion-empty-state-description">{description}</div>
    </div>
  )
}

export default withTranslation()(EmptyState)
