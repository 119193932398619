import 'style/route/form/main.css'
import { withTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import moment from 'moment'
import { MdEdit, MdDelete } from 'react-icons/md'
import IconButton from '@material-ui/core/IconButton'
import queryString from 'query-string'
import { QS_TYPES } from 'core/constants/query-strings'
import { useMemo } from 'react'
import { uuid } from 'core/helpers'

const FormCard = ({ form, onFormDelete, t, match }) => {
  const location = useLocation()
  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  form.FormFields.map((item) => {
    item.OrderKey = uuid.generator()
  })

  const dateWithTimeZone = new Date();
  const offsetInMinutes = dateWithTimeZone.getTimezoneOffset();

  return (
    <li className="blue-item" key={form.IdYourviews}>
      <header className="blue-item-header">
        <em className="form-editor-list-item-id">{form.IdYourviews}</em>

        <strong className="form-editor-list-item-name">{form.Name}</strong>

        <div className="form-editor-list-item-action">
          <Link
            to={{
              pathname: `${match.url}/${form.IdYourviews}`,
              state: form,
              search: queryString.stringify(currentSearch)
            }}
          >
            <IconButton
              color="primary"
              className="btn-page-action btn-page-action-small"
              id="editBtn"
              type="button"
            >
              <MdEdit />
            </IconButton>
          </Link>
          <IconButton
            id="deleteBtn"
            type="button"
            onClick={() => onFormDelete(form.IdYourviews)}
            className="btn-page-action btn-page-action-small cancel btn-page-action-cancel"
          >
            <MdDelete />
          </IconButton>
        </div>
      </header>

      <section>
        <p className="form-editor-list-item-type">
          {t('formEditorType.' + currentSearch[QS_TYPES])}
        </p>
        <p className="form-editor-list-item-date">
          {`${t('formEditor.list.lastUpdate')} ${moment(
            form.LastUpdateDate
          ).format('L')} ${moment(form.LastUpdateDate).subtract(offsetInMinutes, 'minutes').format('HH:mm')}`}
        </p>
      </section>
    </li>
  )
}

export default withTranslation()(FormCard)
