import DateFormatMap from '../dictionaries/date-format.dictionary'
import { format } from 'date-fns'

/**
 * Property with current locale from DateFormat dictionary obtained by i18n actual language
 */
const currentLocale = (lang) =>
  DateFormatMap.has(lang) && DateFormatMap.get(lang)

/**
 * Return correct locale prefix from locales date format dictionary
 * @param {string} lang Language prefix to dictionary find correct locale
 */
const dateFormat = (lang) =>
  DateFormatMap.has(lang)
    ? DateFormatMap.get(lang).locale
    : DateFormatMap.get('default').locale

/**
 * Return date to locale with correct date format
 * @param {Date} date Date value for apply correct (actually) format
 * @param {string} locale Locale to date format (If ignored, the default value for the locale will be that obtained by i18n)
 */
const dateToLocale = (date, locale) =>
  date && date.toLocaleDateString(dateFormat(locale ?? currentLocale))

const formatDateToString = ({
  date,
  language,
  showHours = true,
  showMinutes = true,
  showSeconds = true
}) => {
  if (!date) return ''

  let formatString =
    language === 'en' || language === 'en-US' ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
  if (showHours) {
    formatString += ' - hh'
    if (showMinutes) {
      formatString += ':mm'
      if (showSeconds) {
        formatString += ':ss'
      }
    }
  }

  return format(new Date(date), formatString)
}

export { currentLocale, dateFormat, dateToLocale, formatDateToString }
