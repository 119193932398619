import { withTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import Button from '@material-ui/core/Button'

import '../../style/component/modal/dialog.css'

const ModalDialog = ({
  show,
  onClose,
  title,
  cancel,
  accept,
  onCancel,
  onAccept,
  children,
  t
}) => {
  return (
    <Dialog
      open={show}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title ? (
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      ) : null}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button type="button" onClick={() => onCancel()}>
          {cancel ? cancel : t('component.modal.cancel')}
        </Button>
        <Button autoFocus type="button" onClick={() => onAccept()}>
          {accept ? accept : t('component.modal.accept')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(ModalDialog)
