import { useEffect, useState } from 'react'

import qs from 'query-string'

import { useHistory } from 'react-router-dom'

import { withTranslation } from 'react-i18next'

import axios from 'core/api'

import { BASE_PATH } from '../../router'
import { QS_PAGE } from 'core/constants/query-strings'
import { useAuthContext } from 'modules/Authentication'
import { Permissions } from 'modules/AppDrawer/enums/Permissions'

import Header from '../../components/Header'
import Loader from '../../components/Loader'
import PersonItem from './components/PersonItem'
import OldPagination from '../../components/OldPagination'
import RoundedButton from '../../components/Button'

import { Filters, FilterItem } from '../AppDrawer/styles/Filter'

import '../../style/route/person/main.css'
import { useAppContext } from '../../App'

const Person = ({ location, t }) => {
  const { notify } = useAppContext()
  const { user } = useAuthContext()

  const [loading, setLoading] = useState(true)

  const [people, setPeople] = useState([])
  const [pagination, setPagination] = useState({
    current: 1,
    total: 1
  })

  const getCurrentPage = () => qs.parse(location.search)[QS_PAGE]

  const getPersonList = async (order) => {
    const page = getCurrentPage()
    setLoading(true)

    try {
      const { data } = await axios.get(
        `person/getpaged?direction=${order}${page ? `&page=${page}` : ''}`
      )

      setPeople(data.Items)
      setPagination({
        current: data.PageNumber,
        total: data.PageCount
      })
    } catch (error) {
      notify(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPersonList('desc')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const history = useHistory()
  const handleClick = () => history.push(BASE_PATH + 'import')

  return (
    <>
      <Header
        type="actions"
        title={t('person.title')}
        items={
          Permissions.managerRights.includes(user?.Role) && (
            <Filters justify="flex-end">
              <FilterItem>
                <RoundedButton
                  type="add"
                  size="large"
                  whiteTextLabel
                  variant="contained"
                  onClick={handleClick}
                />
              </FilterItem>
            </Filters>
          )
        }
      />

      <div className="person">
        <ul className="person-list">
          {people.length > 0 &&
            !loading &&
            people.map((item) => <PersonItem key={item.Key} person={item} user={user} />)}

          {people.length < 1 && !loading && (
            <li className="person-list-no-results">
              {t('campaing.resultsEmpty')}
            </li>
          )}
        </ul>

        {!loading && (
          <section>
            <OldPagination
              current={pagination.current}
              total={pagination.total}
            />
          </section>
        )}
      </div>

      {loading && <Loader />}
    </>
  )
}

export default withTranslation()(Person)
