import { useState } from 'react'

import { getIn } from 'formik'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import Rating from '@material-ui/lab/Rating'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(() => ({
  stars: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const StarInput = ({
  field,
  activeLabel,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  const [hover, setHover] = useState(-1)

  const StyledRating = withStyles({
    iconFilled: {
      color: props?.color
    }
  })(Rating)

  return (
    <Box
      mb={3}
      component="fieldset"
      borderColor="transparent"
      className={`form-field checkbox-field 
        ${
          !!(getIn(touched, field.name) && getIn(errors, field.name))
            ? 'checkbox-error'
            : ''
        }`}
    >
      {props.label && <Typography component="legend">{props.label}</Typography>}

      <div className={useStyles().stars}>
        <StyledRating
          size="large"
          name={field.name}
          value={isNaN(field.value) ? 0 : parseInt(field.value)}
          onChange={(event, newValue) => {
            setFieldValue(field.name, newValue)
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover)
          }}
        />

        {activeLabel && (
          <Box ml={2}>
            {
              activeLabel[
                hover !== -1
                  ? hover
                  : isNaN(field.value)
                  ? 0
                  : parseInt(field.value)
              ]
            }
          </Box>
        )}
      </div>
    </Box>
  )
}

export default StarInput
