import { useState } from 'react'
import PropTypes from 'prop-types'

import makeId from '../../functions/makeId'

import Select from '@material-ui/core/Select'

import useStyles from './style'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'

function SingleSelect({ value, onChange, item, id, name, label, values }) {
  const classes = useStyles()
  const labelId = 'label-' + id

  const [selectedValue, setSelectedValue] = useState(value ?? [])
  const handleChange = (target) => {
    setSelectedValue(target.value)
    onChange(target, item)
  }

  return (
    <FormControl className={classes.field}>
      <InputLabel id={makeId(labelId, name)}>{label}</InputLabel>
      <Select
        name={name}
        label={label}
        value={selectedValue}
        className={classes.field}
        id={makeId(id, name)}
        data-testid="dinamic-form-singleselect"
        onChange={({ target }) => handleChange(target)}
      >
        {values.map((item, index) => (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const { string, number, func, oneOfType } = PropTypes

SingleSelect.propTypes = {
  id: number,
  name: string,
  label: string,
  onChange: func,
  value: oneOfType([string, number])
}

SingleSelect.defaultProps = {
  value: ''
}

export default SingleSelect
