import api from 'core/api'

const postData = async ({ endpoint, data, setData, setLoading, notify }) => {
  if (setLoading) setLoading(true)

  try {
    const res = await api.post(endpoint, data)

    if (setData) await setData(res.data)
  } catch (error) {
    if (notify) notify(error.message, 'error')
  } finally {
    if (setLoading) setLoading(false)
  }
}

export default postData
