import './index.css'
import { withTranslation } from 'react-i18next'

const ReportTooltip = ({ name, score, quantity, t }) => {
  return (
    <div className="report-tooltip-wrapper">
      <div className="report-tooltip-title">{name}</div>
      <div className="report-tooltip-info-wrapper">
        <div className="report-tooltip-info-label">
          {t('nps-reports.tree.npsScore')}
        </div>
        <div className="report-tooltip-info-value">{score}</div>
      </div>
      <div className="report-tooltip-info-wrapper">
        <div className="report-tooltip-info-label">
          {t('nps-reports.tree.responsesQuantity')}
        </div>
        <div className="report-tooltip-info-value">{quantity}</div>
      </div>
    </div>
  )
}

export default withTranslation()(ReportTooltip)
