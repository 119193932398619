import { useState, useRef, useEffect } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import Collapse from '@material-ui/core/Collapse'

import useOutsideClick from 'core/hooks/useOutsideClick'

import '../../style/component/accordion/accordion-item.css'

const AccordionItem = ({ ignoreOutsideClick, title, children }) => {
  const [show, setShow] = useState(false)
  const wrapperRef = useRef(null)
  const _isMounted = useRef(true)

  useOutsideClick(wrapperRef, () => {
    setTimeout(() => {
      if (_isMounted.current && !ignoreOutsideClick) setShow(false)
    }, 250)
  })

  useEffect(() => {
    return () => {
      _isMounted.current = false
    }
  }, [])

  return (
    <div
      className={'accordion-item ' + (show ? 'accordion-showing' : '')}
      ref={wrapperRef}
    >
      <button
        className="btn accordion-item-btn"
        onClick={() => setShow(!show)}
        type="button"
      >
        <span className="accordion-item-title">{title}</span>
        {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </button>
      <Collapse in={show}>
        <div className="accordion-item-content animated faster lightSpeedIn">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default AccordionItem
