import { useState } from 'react'

import { STORE, USER } from 'core/constants/local-storage'

import useGetLocalStorageData from 'core/hooks/useGetLocalStorageData'
import useRequestPaged from 'core/hooks/useRequestPaged'
import useRequest from 'core/hooks/useRequest'

import Header from '../../components/Header'
import OldPagination from '../../components/OldPagination'

import { CircularProgress } from '@material-ui/core'

import { ListItem, InfoItem, Li } from './style'

function QaTests() {
  const [campaigns, setCampaigns] = useState(null)
  const [pagination, setPagination] = useState({ current: 1, total: 1 })
  useRequestPaged(
    'campaign/getpaged',
    (data) => {
      setCampaigns(data.Items)
    },
    setPagination
  )

  const [persons, setPersons] = useState(null)
  useRequest('person/getpaged?PageSize=10&Page=1&Direction=Desc', (data) =>
    setPersons(data.Items)
  )

  const [user, setUser] = useState(null)
  useGetLocalStorageData(USER, setUser)

  const [store, setStore] = useState(null)
  useGetLocalStorageData(STORE, setStore)

  return (
    <>
      <Header title="Testes de QA" />

      <div id="storeInfo">
        <h3>Informações da Conta/Store</h3>
        <div>
          {store && (
            <ul>
              <Li>
                Nome:{' '}
                <InfoItem
                  id="storeName"
                  className="storeName"
                  data-testid="storeName"
                >
                  {store.Name}
                </InfoItem>
              </Li>
              <Li>
                Key:{' '}
                <InfoItem
                  id="storeKey"
                  className="storeKey"
                  data-testid="storeKey"
                >
                  {store.Key}
                </InfoItem>
              </Li>
            </ul>
          )}
        </div>
      </div>

      <hr />

      <div id="userInfo">
        <h3>Informações do Usuário</h3>
        <div>
          {user && (
            <ul>
              <Li>
                Nome:{' '}
                <InfoItem
                  id="userName"
                  className="userName"
                  data-testid="userName"
                >
                  {user.Name}
                </InfoItem>
              </Li>
              <Li>
                Email:{' '}
                <InfoItem
                  id="userEmail"
                  className="userEmail"
                  data-testid="userEmail"
                >
                  {user.Email}
                </InfoItem>
              </Li>
              <Li>
                ID:{' '}
                <InfoItem id="userId" className="userId" data-testid="userId">
                  {user.IdYourviews}
                </InfoItem>
              </Li>
              <Li>
                Role:{' '}
                <InfoItem
                  id="userRole"
                  className="userRole"
                  data-testid="userRole"
                >
                  {user.Role}
                </InfoItem>
              </Li>
            </ul>
          )}
        </div>
      </div>

      <hr />

      <div id="campaignInfo">
        <h3>Informações de Campanhas</h3>
        <div>
          <ul>
            {!campaigns && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}

            {campaigns &&
              campaigns.map((campaign, index) => (
                <ListItem key={campaign.Key}>
                  Nome:{' '}
                  <span
                    id={'campaign-item-' + index + '-Name'}
                    className="campaignName"
                    data-testid="campaignName"
                  >
                    {campaign.Name}
                  </span>
                  <br />
                  Key:{' '}
                  <span
                    id={'campaign-item-' + index + '-Key'}
                    className="campaignKey"
                    data-testid="campaignKey"
                  >
                    {campaign.Key}
                  </span>
                  <br />
                  ID:{' '}
                  <span
                    id={'campaign-item-' + index + '-Id'}
                    className="campaignId"
                    data-testid="campaignId"
                  >
                    {campaign.IdYourviews}
                  </span>
                </ListItem>
              ))}
          </ul>
        </div>
        <section>
          {campaigns && (
            <OldPagination
              current={pagination.current}
              total={pagination.total}
            />
          )}
        </section>
      </div>

      <hr />

      <div id="personsInfo">
        <h3>Informações de Pessoas</h3>
        <div>
          <ul>
            {!persons && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}

            {persons &&
              persons.map((person, index) => (
                <ListItem key={person.Key}>
                  Nome:{' '}
                  <span
                    id={'person-item-' + index + '-Name'}
                    className="personName"
                    data-testid="personName"
                  >
                    {person.Name}
                  </span>
                  <br />
                  Key:{' '}
                  <span
                    id={'person-item-' + index + '-Key'}
                    className="personKey"
                    data-testid="personKey"
                  >
                    {person.Key}
                  </span>
                  <br />
                  Id:{' '}
                  <span
                    id={'person-item-' + index + '-Id'}
                    className="personId"
                    data-testid="personId"
                  >
                    {person.IdYourviews}
                  </span>
                  <br />
                  Id Externo:{' '}
                  <span
                    id={'person-item-' + index + '-IdExternal'}
                    className="personIdExternal"
                    data-testid="personIdExternal"
                  >
                    {person?.IdExternal}
                  </span>
                </ListItem>
              ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default QaTests
