import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import DeleteIcon from '@material-ui/icons/Delete'
import DoneIcon from '@material-ui/icons/Done'
import AddIcon from '@material-ui/icons/Add'
import Yup from 'core/validations/index'
import { FIELD_TYPE } from 'core/enums/field-type'
import { FIELD_TEMPLATE } from 'core/enums/field-template'
import { uuid } from 'core/helpers/index'
import TextField from 'components/Form/Text/TextField'
import Checkbox from 'components/Form/Box/Checkbox'
import DropdownStateless from 'components/Form/Option/DropdownStateless'
import Dropdown from 'components/Form/Option/Dropdown'
import CheckboxGroup from 'components/Form/Box/CheckboxGroup'
import CheckboxItem from 'components/Form/Box/CheckboxItem'
import CustomFieldValuesEditor from '../CustomFieldValuesEditor'
import queryString from 'query-string'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import { QS_TYPES } from 'core/constants/query-strings'

export const GetDefaultStarFormFieldValues = (t) => [
  {
    Value: t('formEditor.default.rating.terrible'),
    OrderIndex: 1,
    Key: uuid.generator(),
    ValueAsInteger: 1
  },
  {
    Value: t('formEditor.default.rating.bad'),
    OrderIndex: 2,
    Key: uuid.generator(),
    ValueAsInteger: 2
  },
  {
    Value: t('formEditor.default.rating.regular'),
    OrderIndex: 3,
    Key: uuid.generator(),
    ValueAsInteger: 3
  },
  {
    Value: t('formEditor.default.rating.good'),
    OrderIndex: 4,
    Key: uuid.generator(),
    ValueAsInteger: 4
  },
  {
    Value: t('formEditor.default.rating.great'),
    OrderIndex: 5,
    Key: uuid.generator(),
    ValueAsInteger: 5
  }
]

const CustomFieldEditorItem = ({
  field,
  onChange,
  dimensions,
  formDimencionsSelectedHook,
  formFieldsDependenciesHook,
  removeField,
  addDimension,
  conditionalOptions,
  location,
  t,
  fields
}) => {
  const [isAddingDimension, setIsAddingDimension] = useState(false)

  const [selectedDependencieDimension, setSelectedDependencieDimension] = useState({})

  const initialValues = {
    Title: field.Title,
    Required: field.Required,
    NpsClassificationDependancy: field.NpsClassificationDependancy
      ? field.NpsClassificationDependancy.map((item) => item.toString())
      : [],
    FieldClassificationDependancy: field.FormFieldDependencies.length 
      ? field.FormFieldDependencies.map((item) => item.IdFormFieldValueDependancy.toString())
      : [],
    newDimension: ''
  }

  const fieldsValidation = Yup.object().shape({
    Title: Yup.string().max(240).required(),
    Required: Yup.bool(),
    NpsClassificationDependancy: Yup.array(),
    FieldClassificationDependancy: Yup.array(),
    newDimension: Yup.string().max(60)
  })

  const onType = (type) => {
    let newField = field
    newField.Type = type.id

    newField.FormFieldValues =
      field.Type === FIELD_TYPE.rating || field.Type.id === FIELD_TYPE.rating
        ? GetDefaultStarFormFieldValues(t)
        : []
    
    newField.Template =
      field.Type === FIELD_TYPE.rating || field.Type.id === FIELD_TYPE.rating
          ? 0
          : undefined

    newField.FormFieldDependencies = []
          
    delete newField.IdDimension
    onChange(newField)
  }

  const onTemplate = (template) => {
    let newField = field
    newField.Template = template.id
    onChange(newField)
  }

  const onDimension = (dimension) => {
    let newField = field
    newField.IdDimension = dimension.id
    onChange(newField)
    updateDimensionsDropdown()
  }

  const onSelectDimension = (dimensionSelected) => {
    let newField = field
    let newFieldSelected = fields.find((f) => f.IdDimension === dimensionSelected.id)
    newField.FormFieldValues = newFieldSelected.FormFieldValues

    setSelectedDependencieDimension(dimensionSelected)

    let existsFieldDependant = formFieldsDependenciesHook.formFieldsDependencies.findIndex((f) => (field.Id || field.IdYourviews) === f.FormFieldId)
    if (existsFieldDependant > -1) {
      let newFormFieldDependencies = formFieldsDependenciesHook.formFieldsDependencies
      newFormFieldDependencies[existsFieldDependant] = {
        FormFieldId: (field.Id || field.IdYourviews),
        FormFieldDependencieId: (newFieldSelected.Id || newFieldSelected.IdYourviews)
      }
      formFieldsDependenciesHook.setFormFieldsDependencies(newFormFieldDependencies)
    } else {
      formFieldsDependenciesHook.setFormFieldsDependencies([
        ...formFieldsDependenciesHook.formFieldsDependencies,
        {
          FormFieldId: (field.Id || field.IdYourviews),
          FormFieldDependencieId: (newFieldSelected.Id || newFieldSelected.IdYourviews)
        }
      ])
    }
    
    onChange(newField)
  }

  const dataChanged = (values) => {
    let newField = field
    newField.Title = values.Title
    newField.Required = values.Required
    newField.NpsClassificationDependancy = values.NpsClassificationDependancy
    newField.FieldClassificationDependancy = values.FieldClassificationDependancy
    onChange(newField)
  }

  const answersChanged = (values) => {
    let newField = field
    newField.FormFieldValues = values
    onChange(newField)
    

    let FormFieldDependantId = formFieldsDependenciesHook.formFieldsDependencies.find((d) => d.FormFieldDependencieId === (field.Id || field.IdYourviews))?.FormFieldId
    if (FormFieldDependantId) {
      fields.forEach((f) => {
        if (FormFieldDependantId === (f.Id || f.IdYourviews)) {
          f.FormFieldValues = field.FormFieldValues
        }
      })
    }
  }

  const conditionalChanged = (values) => {
    let newField = field
    newField.FormFieldDependencies = values.map((item) => ({
      Key: item.id,
      OrderKey: item.OrderKey
    }))
    onChange(newField)
  }

  // useEffect(() => {
  //   let values = field.FormFieldDependencies.map((item) =>
  //     conditionalOptions.find((x) => x.id === item.Key)
  //   )
  //   let newField = field
  //   newField.FormFieldDependencies = values.map((item) => ({
  //     Key: item.id,
  //     OrderKey: item.OrderKey
  //   }))
  //   onChange(newField)
  // }, [])

  const dimensionName = (dimensionId) => {
    if (dimensionId) {
      const index = dimensions.findIndex((d) => d.id === dimensionId)
      if (index < 0) return false
      return dimensions[index].name
    } else {
      const index = dimensions.findIndex((d) => d.id === field.IdDimension)
      if (index < 0) return false
      return dimensions[index]
    }
  }

  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const filteredDimensions = useMemo(() => {
    if (!dimensions) return []
    return dimensions.filter((d) => d.type === field.Type)
  }, [dimensions, field.Type])

  const filterDependentsOptions = (o, field) => {
    let isDependent
    let hasDependency
    let interDependency
    for (const f of fields) {
      /*
       * o: The option being tested - option on dependency selection.
       * field: The field where the dependency options will be displayed.
       * f: The field that has or does not have dependencies - FormFieldDependencies.
       */
      const optionField = fields.filter((f) => f.OrderKey === o.OrderKey)[0]

      /* check if some field has dependency with the actual field and */
      /* check if the actual option has the same parent of another field */
      isDependent =
        (f.FormFieldDependencies || []).findIndex(
          (d) => d.OrderKey === field.OrderKey && o.OrderKey === f.OrderKey
        ) === -1

      /* check if the actual option has dependency with other field */
      interDependency =
        (optionField.FormFieldDependencies || []).findIndex(
          (d) => d.OrderKey === f.OrderKey
        ) === -1

      /* check if the other field has dependency with the actual field */
      hasDependency =
        (f.FormFieldDependencies || []).findIndex(
          (d) => d.OrderKey === field.OrderKey
        ) === -1

      if (!isDependent || (!interDependency && !hasDependency)) {
        return
      }
    }
    return true
  }

  const filterList = (options) => {
    return options
      .filter(
        (o) =>
          (field.FormFieldValues || []).findIndex((v) => {
            return v.Key === o.id
          }) === -1
      )
      .filter(
        (o) =>
          (field.FormFieldDependencies || []).findIndex((v) => {
            return v.Key === o.id
          }) === -1
      )
      .filter((o) => filterDependentsOptions(o, field))
  }

  const defaultFieldsDropdown = [
    {
      id: FIELD_TYPE.singleSelect,
      name: t('fieldType.' + FIELD_TYPE.singleSelect)
    },
    {
      id: FIELD_TYPE.multiSelect,
      name: t('fieldType.' + FIELD_TYPE.multiSelect)
    },
    {
      id: FIELD_TYPE.rating,
      name: t('fieldType.' + FIELD_TYPE.rating)
    },
    {
      id: FIELD_TYPE.text,
      name: t('fieldType.' + FIELD_TYPE.text)
    }
  ]

  const formFieldTemplates = {
    2: [
      {
        id: FIELD_TEMPLATE.ratingStars,
        name: t('fieldTemplate.' + FIELD_TEMPLATE.ratingStars)
      },
      {
        id: FIELD_TEMPLATE.ratingColors,
        name: t('fieldTemplate.' + FIELD_TEMPLATE.ratingColors)
      }
    ],
    0: [
      {
        id: FIELD_TEMPLATE.singleSelectRadio,
        name: t('fieldTemplate.' + FIELD_TEMPLATE.singleSelectRadio)
      },
      {
        id: FIELD_TEMPLATE.singleSelectColors,
        name: t('fieldTemplate.' + FIELD_TEMPLATE.singleSelectColors)
      }
    ]
  }

  const filterTemplatesOptions = () => {
    return formFieldTemplates[field.Type]
  }

  const updateDimensionsDropdown = () => {
    let newFormDimensionsSelected = []
    fields.forEach((f) => {
      if (!f.IdDimension) return
      if (f.Type === 3) return
      if (!dimensions.length) return
      newFormDimensionsSelected.push({
        id: f.IdDimension,
        name: dimensionName(f.IdDimension)
      })
    })
    if (newFormDimensionsSelected.length) {
      formDimencionsSelectedHook.setFormDimensionsSelected(newFormDimensionsSelected)
    }
  }

  const changeDependency = (answerId, add) => {
    let fieldDependencieId = formFieldsDependenciesHook.formFieldsDependencies.find((d) => d.FormFieldId === (field.Id || field.IdYourviews)).FormFieldDependencieId
    let dependentFieldFormValues = fields.find((f) => (f.Id || f.IdYourviews) === fieldDependencieId).FormFieldValues

    if (add) {
      let newDependentAnswer = {}
      dependentFieldFormValues.forEach((formValue) => {
        if (answerId === (formValue.Id || formValue.IdYourviews || formValue.ValueAsInteger)) {
          newDependentAnswer.IdFormField = fieldDependencieId
          newDependentAnswer.IdFormFieldValueDependancy = answerId
          newDependentAnswer.Key = formValue.Key
        }
      })
      field.FormFieldDependencies.push(newDependentAnswer)
    } else {
      field.FormFieldDependencies = field.FormFieldDependencies.filter((formValue) => formValue.Id === answerId)
    }
  }

  const getActualDimensionSelected = () => {
    if (!field.FormFieldDependencies.length || !formDimencionsSelectedHook.formDimensionsSelected.length) return
    let IdFieldValueDepedencie = field.FormFieldDependencies[0].IdFormFieldValueDependancy
    let IdDimensionFieldDependencie = fields.find((f) => f.FormFieldValues.find((v) => v.IdYourviews === IdFieldValueDepedencie))?.IdDimension
    let dimensionSelected = formDimencionsSelectedHook.formDimensionsSelected.find((d) => d.id === IdDimensionFieldDependencie)
    if (!dimensionSelected) return
    onSelectDimension(dimensionSelected)
  }

  const filteredDimensionsDropdown = () => {
    let dimensionsFiltered = []
    let fieldIndex = fields.findIndex((f) => f === field)
    formDimencionsSelectedHook.formDimensionsSelected.forEach((selectedDimension) => {
      let index = fields.findIndex((f) => f.IdDimension === selectedDimension.id)
      if (fieldIndex > index) 
        dimensionsFiltered.push(selectedDimension)
    })
    return dimensionsFiltered
  }

  useEffect(() => {
    updateDimensionsDropdown()
  }, [dimensions])

  useEffect(() => {
    getActualDimensionSelected()
  }, [formDimencionsSelectedHook.formDimensionsSelected])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={fieldsValidation}
      validateOnBlur={false}
      validate={dataChanged}
    >
      {(formikProps) => {
        return (
          <Form id="customItemForm">
            <div className="form-editor-item-header">
              <span className="form-editor-item-drag-icon">
                <DragHandleIcon />
              </span>
              {!field.undeletable && (
                <IconButton id="delete" onClick={removeField}>
                  <DeleteIcon />
                </IconButton>
              )}
            </div>
            <div className="form-editor-item-body">
              <Field
                id="title"
                name="Title"
                component={TextField}
                label={t('formEditor.field.titleLabel')}
                placeholder={t('formEditor.field.titleDesc')}
              />

              <div className="form-field">
                <Dropdown
                  id="dropdownSelectFieldType"
                  testId="dropdownSelectFieldType"
                  onSelected={(item) => onType(item)}
                  value={field.Type}
                  disabled={field.disableTypeField}
                  items={defaultFieldsDropdown}
                  name={
                    t('fieldType.' + field.Type) ||
                    t('formEditor.field.fieldTypeDesc')
                  }
                />
              </div>
              
              {
                (field.Type === 2 || field.Type === 0) &&
                formFieldTemplates &&
                <div className="form-field">
                  <Dropdown
                    id="dropdownSelectFieldTemplate"
                    testId="dropdownSelectFieldTemplate"
                    onSelected={(item) => onTemplate(item)}
                    items={filterTemplatesOptions()} 
                    value={field.Template || formFieldTemplates[`${field.Type}`][0].id}
                    name={t('formEditor.field.fieldTypeTemplate')}
                  />
                </div>
              }

              <div className="form-editor-item-dimension">
                <DropdownStateless
                  id="selectDimension"
                  name="selectDimension"
                  onSelected={(item) => onDimension(item)}
                  items={filteredDimensions}
                  value={dimensionName()}
                  label={t('formEditor.field.dimensionLabel')}
                />

                <IconButton
                  id="addRemoveItem"
                  data-testid="addRemoveItemForm"
                  color="primary"
                  onClick={() => setIsAddingDimension(!isAddingDimension)}
                >
                  {isAddingDimension ? <RemoveIcon /> : <AddIcon />}
                </IconButton>
              </div>

              {isAddingDimension && (
                <div className="form-editor-adding-dimension">
                  <Field
                    id="newDimension"
                    name="newDimension"
                    component={TextField}
                    label={t('formEditor.field.newDimensionLabel')}
                    placeholder={t('formEditor.field.newDimensionDesc')}
                  />
                  <IconButton
                    id="addDimension"
                    data-testid="addDimensionForm"
                    color="primary"
                    onClick={() => {
                      addDimension(formikProps.values.newDimension)
                      setIsAddingDimension(false)
                    }}
                  >
                    <DoneIcon />
                  </IconButton>
                </div>
              )}

              {(field.Type === FIELD_TYPE.singleSelect ||
                field.Type === FIELD_TYPE.multiSelect) && (
                <CustomFieldValuesEditor
                  FormFieldValues={field.FormFieldValues}
                  onChange={answersChanged}
                />
              )}

              <div
                style={{
                  display:
                    currentSearch[QS_TYPES] === NPS_CSAT_TAB.nps.typeParam ||
                    (currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam && 
                      field.Type === FIELD_TYPE.text &&
                      formDimencionsSelectedHook.formDimensionsSelected.length > 0)
                      ? 'block'
                      : 'none'
                }}
                className="conditional-editor"
              >
                <h4 className="conditional-editor-title">
                  {t('formEditor.field.showOnly')}
                </h4>
                <div className="conditional-editor-area">
                  <p className="conditional-editor-label">
                    { 
                      currentSearch[QS_TYPES] === NPS_CSAT_TAB.nps.typeParam &&
                      t('formEditor.field.showOnlyNPS')
                    }
                  </p>
                  {
                    currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam &&
                    field.Type === FIELD_TYPE.text &&
                    formDimencionsSelectedHook.formDimensionsSelected.length > 0 &&
                    <div className="form-field">
                      <DropdownStateless
                        id="dropdownSelectFieldsCreated"
                        testId="dropdownSelectFieldsCreated"
                        name="selectDimension"
                        onSelected={(item) => onSelectDimension(item)}
                        items={filteredDimensionsDropdown()}
                        value={selectedDependencieDimension}
                        label={t('formEditor.field.dimensionDependantSelect')}
                      />
                    </div>
                  }
                  {
                    currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam &&
                    field.Type === FIELD_TYPE.text &&
                    formDimencionsSelectedHook.formDimensionsSelected.length > 0 &&
                      <CheckboxGroup
                        id="FieldClassificationDependancy"
                        value={formikProps.values.FieldClassificationDependancy}
                        error={formikProps.errors.FieldClassificationDependancy}
                        touched={formikProps.FieldClassificationDependancy}
                        onChange={formikProps.setFieldValue}
                        onBlur={formikProps.setFieldTouched}
                        changeDependency={changeDependency}
                        csat={true}
                        className="conditional-editor-checkbox-group"
                      >
                        {
                          field.FormFieldValues.map((value) => {
                            let id = ""
                            id = value.Id || value.IdYourviews || value.ValueAsInteger || ""
                            return (
                              <Field
                                key={value.Key}
                                name="FieldClassificationDependancy"
                                label={value.Value.toString()}
                                id={id.toString()}
                                component={CheckboxItem}
                              />
                            )
                          })
                        }
                      </CheckboxGroup>
                  }
                  {
                    currentSearch[QS_TYPES] === NPS_CSAT_TAB.nps.typeParam &&
                    <CheckboxGroup
                      id="NpsClassificationDependancy"
                      value={formikProps.values.NpsClassificationDependancy}
                      error={formikProps.errors.NpsClassificationDependancy}
                      touched={formikProps.NpsClassificationDependancy}
                      onChange={formikProps.setFieldValue}
                      onBlur={formikProps.setFieldTouched}
                      className="conditional-editor-checkbox-group"
                    >
                      <Field
                        name="NpsClassificationDependancy"
                        label={t('npsType.1')}
                        id="1"
                        component={CheckboxItem}
                      />
                      <Field
                        name="NpsClassificationDependancy"
                        label={t('npsType.2')}
                        id="2"
                        component={CheckboxItem}
                      />
                      <Field
                        name="NpsClassificationDependancy"
                        label={t('npsType.3')}
                        id="3"
                        component={CheckboxItem}
                      />
                    </CheckboxGroup>
                  }
                </div>

                {/* 
                {conditionalOptions.length > 0 && (
                  <div className="conditional-editor-area">
                    <p className="conditional-editor-label">
                      {t('formEditor.field.showOnlyField')}
                    </p>
                    <Tags
                      tags={field.FormFieldDependencies.map((item) =>
                        conditionalOptions.find((x) => x.id === item.Key)
                      )}
                      suggestions={filterList(conditionalOptions)}
                      placeholder={t('formEditor.field.showOnlyFieldDesc')}
                      update={(tags) => conditionalChanged(tags)}
                      useProps
                      disable={conditionalOptions.length > 0}
                    />
                  </div>
                )} */}
              </div>
              <Field
                id="Required"
                name="Required"
                component={Checkbox}
                label={t('formEditor.requiredField')}
                disabled={field.disableRequiredField}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(CustomFieldEditorItem)
