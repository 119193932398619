import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const Container = styled.div``
export const Row = styled.div`
  display: flex;

  ${({ center }) =>
    center ? 'justify-content: center;' : 'justify-content: space-between;'}

  flex-direction: row;
  align-content: center;
  align-items: center;

  padding-top: auto;
  padding-bottom: auto;

  ${({ height }) => (height ? `  height: ${height};` : `height: 64px;`)}
  width: 100%;

  background: #fff;
  z-index: 5;

  ${({ fixed }) =>
    fixed
      ? `
        position: fixed;
      `
      : ''}

  ${({ noBorder }) =>
    noBorder ? '' : 'border-bottom: 1px solid rgba(0, 0, 0, 0.12);'}
`

export const LeftActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-left: 8px;
`

export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 44px;

  text-align: center;

  color: #000000;
`

export const RightActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-right: 8px;
`

export const ButtonLabel = styled.button`
  color: #00aeef;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;

  &:disabled {
    cursor: default;
    color: rgba(0, 0, 0, 0.26);
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;

  ${({ scrollY }) => scrollY && `overflow-y: scroll;`}

  height: 100%;
  max-height: calc(100vh - 138px);
`

export const InsideContent = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  justify-content: space-between;

  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'scroll')};
  max-height: calc(100vh - 200px);
  height: 100%;

  padding-top: 70px;

  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
`

export const Column = styled.div`
  height: ${({ height }) => height ?? `calc(100vh - 66px)`};
  padding: ${({ padding }) => padding ?? '0px'};
  display: flex;
  flex-direction: column;

  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ greyBg }) => (greyBg ? `background-color: #ECEEF0;` : '')}
  ${({ scrollY }) => scrollY && `overflow-y: scroll;`}
  ${({ scrollYHidden }) => scrollYHidden && `overflow-y: hidden;`}
  ${({ border }) => (border ? `border-right: 1px solid #E0E0E0;` : '')}
`

export const Title = styled.h2`
  font-style: normal;
  ${({ bold }) => (bold ? 'font-weight: bold;' : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : '')}
  font-size: ${({ size }) => (size ? `${size}px;` : '24px')};
  line-height: 34px;
`

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.54);
  margin-top: 4px;
  margin-bottom: 24px;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 24px 0px;
`

export const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 686
  }
}))

export default useStyles
