import i18n from 'i18next'

import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

import pt from './translation/pt.json'
import en from './translation/en.json'
import es from './translation/es.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      pt: {
        translations: pt
      },
      en: {
        translations: en
      },
      es: {
        translations: es
      }
    },
    fallbackLng: 'pt',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false
    },
    returnObjects: true,
    debug: false
  })

i18n.languages = ['pt', 'en', 'es']

export default i18n
