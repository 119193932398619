import { createRef, useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { useStyles, Actions, Container, List, ListItem } from './style'
import AddIcon from '@material-ui/icons/Add'
import {
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import Loader from 'components/Loader'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const BoxList = ({
  items,
  onCreate,
  onSelect,
  noCreateButton,
  selectedAfterUpdate,
  handleSetBoxListCurrentItem,
  loading,
  t
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(selectedAfterUpdate)

  const getItem = (array, id) =>
    array && array.find((x) => x.IdYourviews.toString() === id.toString())

  const handleSetItem = useCallback(
    (items, value) => {
      setSelected(value)
      onSelect(getItem(items, value))
      handleSetBoxListCurrentItem(getItem(items, value))
    },
    [handleSetBoxListCurrentItem, onSelect]
  )

  const handleChange = useCallback(
    ({ target: { value } }) => {
      handleSetItem(items, value)
    },
    [handleSetItem, items]
  )

  const [refs, setRefs] = useState(
    items?.reduce((acc, value) => {
      acc[value.IdYourviews] = createRef()
      return acc
    }, {})
  )

  const scrollToItem = (id) =>
    refs[id]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })

  useEffect(() => {
    if (selectedAfterUpdate) {
      handleSetItem(items, selectedAfterUpdate.toString())

      scrollToItem(selectedAfterUpdate.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAfterUpdate])

  useEffect(() => {
    const value = selectedAfterUpdate ?? items[0]?.IdYourviews

    if (items && items.length > 0) {
      const elRefs = items.reduce((acc, v) => {
        acc[v.IdYourviews] = createRef()
        return acc
      }, {})

      setRefs(elRefs)

      handleSetItem(items, value?.toString())

      scrollToItem(value?.toString())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, loading])

  return (
    <Container>
      {loading ? (
        <Loader margin="100px" noMargin />
      ) : (
        <>
          <List>
            <FormControl className={classes.formControl} component="fieldset">
              <RadioGroup
                id="listItem"
                name="listItem"
                value={selected}
                aria-label="List item"
                onChange={handleChange}
              >
                {items &&
                  items.map((item, i) => (
                    <ListItem
                      className={`
                        ${classes.item}
                        ${
                          item.IdYourviews.toString() === selected
                            ? classes.hovered
                            : ''
                        }
                      `}
                      key={i}
                    >
                      <FormControlLabel
                        label={item.Name}
                        classes={{ label: classes.label }}
                        value={item.IdYourviews.toString()}
                        style={{ width: '100%' }}
                        control={
                          <Radio
                            color="primary"
                            checkedIcon={<CheckCircleIcon />}
                          />
                        }
                        ref={refs[item.IdYourviews.toString()]}
                      />
                    </ListItem>
                  ))}

                {(!items || items.length < 1) && (
                  <div className={classes.item}>
                    <FormControlLabel
                      disabled
                      value="disabled"
                      control={<Radio checkedIcon={<CheckCircleIcon />} />}
                      classes={{ label: classes.label }}
                      label={t('campaign.box-list.empty')}
                    />
                  </div>
                )}
              </RadioGroup>
            </FormControl>
          </List>

          {!noCreateButton && (
            <Actions>
              <Fab
                data-testid="add-button"
                onClick={onCreate}
                color="primary"
                aria-label="add"
              >
                <AddIcon />
              </Fab>
            </Actions>
          )}
        </>
      )}
    </Container>
  )
}

const { array, func, bool, string } = PropTypes

BoxList.propTypes = {
  selectedAfterUpdate: string,
  onCreate: func.isRequired,
  onSelect: func.isRequired,
  items: array.isRequired,
  noCreateButton: bool,
  loading: bool
}

BoxList.defaultProps = {
  noCreateButton: false,
  onCreate: () => {},
  onSelect: () => {},
  loading: false
}

export default withTranslation()(BoxList)
