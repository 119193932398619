import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  row: {
    '&:hover': {
      background: 'rgba(0,0,0, 0.1)'
    }
  }
})

export default useStyles
