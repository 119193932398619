import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { read, create } from 'localstorage-helpr'
import { useLocation } from 'react-router'

import { STORE, TOKEN, USER } from 'core/constants/local-storage'
import { getPermissionsDetails } from 'core/functions/get-user-permissions-details'

import Skin from './components/Skin'

const AuthContext = createContext()

const AuthProvider = ({ children, withSkin }) => {
  const location = useLocation()

  const [user, setUser] = useState(read(USER) ?? null)
  const handleSetUser = useCallback((newValue) => {
    create(USER, newValue)
    setUser(newValue)
  }, [])

  const [store, setStore] = useState(read(STORE) ?? null)
  const handleSetStore = useCallback((newValue) => {
    create(STORE, newValue)
    setStore(newValue)
  }, [])

  const [hasAuth, setHasAuth] = useState(!!read(TOKEN))
  const handleSetHasAuth = useCallback((newValue) => {
    setHasAuth(newValue)
  }, [])

  const [token, setToken] = useState(read(TOKEN) ?? null)
  const handleSetToken = useCallback(
    (newValue) => {
      create(TOKEN, newValue)
      handleSetHasAuth(newValue)
      setToken(newValue)
    },
    [handleSetHasAuth]
  )

  const [storeList, setStoreList] = useState({})
  const handleSetStoreList = useCallback((data) => {
    setStoreList(data)
  }, [])

  const [hasPermission, seHasPermission] = useState(true)
  const handleHasPermission = useCallback((newValue) => {
    seHasPermission(newValue)
  }, [])

  useEffect(() => {
    const token = read(TOKEN)

    if (token) handleSetHasAuth(true)
    else handleSetHasAuth(false)

    const user = read(USER)
    const { matchPath, allowed } = getPermissionsDetails(user, location.pathname)

    if (!matchPath || allowed) handleHasPermission(true)
    else handleHasPermission(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <AuthContext.Provider
      value={{
        user,
        handleSetUser,

        store,
        handleSetStore,

        token,
        handleSetToken,

        hasAuth,
        handleSetHasAuth,

        storeList,
        handleSetStoreList,

        hasPermission,
        handleHasPermission
      }}
    >
      {withSkin ? <Skin>{children}</Skin> : children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  const {
    user,
    handleSetUser,

    store,
    handleSetStore,

    token,
    handleSetToken,

    hasAuth,
    handleSetHasAuth,

    storeList,
    handleSetStoreList,

    hasPermission,
    handleHasPermission
  } = context

  return {
    user,
    handleSetUser,

    store,
    handleSetStore,

    token,
    handleSetToken,

    hasAuth,
    handleSetHasAuth,

    storeList,
    handleSetStoreList,

    hasPermission,
    handleHasPermission
  }
}

export default AuthProvider
