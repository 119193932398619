import { Fab } from '@material-ui/core'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { BASE_PATH } from 'router'
import { useCampaign } from '../../../Details/context'
import useGetData from 'core/hooks/useGetData'

const SuccessDialog = ({ fileName, close }) => {
  const history = useHistory()
  const { search } = useLocation()

  const { t } = useTranslation()

  const handleNext = () => {
    history.push({ pathname: BASE_PATH + 'campaign/dispatch', search })
  }

  const { handleSetEmailList } = useCampaign()

  useGetData({
    endpoint: 'TargetList/GetAll',
    setData: handleSetEmailList
  })

  return (
    <div
      data-testid="success-dialog"
      style={{
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
      }}
    >
      <Fab
        disableRipple
        disableFocusRipple
        style={{ margin: 20 }}
        color="primary"
        aria-label="Import file icon"
      >
        <ImportExportIcon />
      </Fab>
      <h1
        style={{
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: 24
        }}
      >
        {t('campaing.importPeople.importPlan')}
      </h1>

      <legend id={fileName} data-testid="modal-filename">
        {fileName ?? ''}
      </legend>

      <p
        style={{
          margin: 20,
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: 16,
          textAlign: 'center',
          color: 'rgba(0, 0, 0, 0.54)'
        }}
      >
        {t('campaing.importPeople.textProcessTime')}
      </p>

      <Fab
        color="primary"
        variant="extended"
        style={{ margin: 20 }}
        onClick={() => {
          close()
          handleNext()
        }}
        aria-label="Understand button"
      >
        {t('campaing.importPeople.understood')}
      </Fab>
    </div>
  )
}

export default SuccessDialog
