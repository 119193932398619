import './index.css'
import { withTranslation } from 'react-i18next'
import CustomCard from 'components/CustomCard'
import { Link, Check } from '@material-ui/icons'
import EmptyState from '../EmptyState'

const ConversionLink = ({ linkData, loadingLinkData, t }) => {
  return !loadingLinkData && !linkData.Answered && !linkData.Opened ? (
    <EmptyState description={t('conversion-report.empty.descriptionLink')} />
  ) : (
    <div className="conversion-link-wrapper">
      <CustomCard
        loading={loadingLinkData}
        titleText={t('conversion-report.link.opening')}
        titleIconComponent={<Link color="primary" />}
        value={linkData.Opened}
        footerInfo={t('conversion-report.link.openingInfo')}
      />
      <CustomCard
        loading={loadingLinkData}
        titleText={t('conversion-report.link.answered')}
        titleIconComponent={<Check color="primary" />}
        value={linkData.Answered}
        footerInfo={t('conversion-report.link.answeredInfo')}
      />
    </div>
  )
}

export default withTranslation()(ConversionLink)
