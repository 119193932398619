const { makeStyles } = require('@material-ui/core')

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: '8px 0px',
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

export default useStyles
