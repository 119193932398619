import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
  input: { width: 260 },
  success: {
    margin: '35px 0px',
    fontSize: 25
  },
  error: { margin: '10px 0px 20px 0px' },
  formControl: { width: '25ch' },
  title: {
    marginBottom: 30,
    color: 'var(--blueDark_500)',
    fontSize: 34,
    fontWeight: 900
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  inputs: {
    
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  icon: {
    margin: '0px 50px',
    zIndex: 1,
    paddingTop: 18
  },
  message: {
   width: 'max-content',
   color: '#f44336',
   margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    fontFamily: "Roboto", 
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    position: 'absolute'
  },
  buttons:{
    marginTop: '30px'
  }
})

export default useStyle
