import { Link, useHistory, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { BASE_PATH } from '../../../../router'

const Confirmation = ({ t }) => {
  const history = useHistory()
  const location = useLocation()

  if (!location.state || (!location.state.success && !location.state.message)) {
    history.push(BASE_PATH + 'import')
    return null
  }

  const checkError = (erro) => {
    if (erro.indexOf('not found') > -1) {
      return t('import.confirmation.errors.notFound')
    } else {
      return t('import.confirmation.errors.generic')
    }
  }

  return (
    <div className="confirmation">
      {location.state && location.state.success && (
        <div className="confirmation-success">
          <p className="confirmation-text">
            {t('import.confirmation.success')}
          </p>
          <p className="confirmation-text">
            {t('import.confirmation.check')}
            <Link to={BASE_PATH + 'import'}>
              {t('import.confirmation.click')}
            </Link>
          </p>
        </div>
      )}

      {location.state && !location.state.success && (
        <div className="confirmation-error">
          {location.state.message.map((item, idx) => (
            <p className="confirmation-text" key={idx}>
              {checkError(item)}
            </p>
          ))}

          <Link to={BASE_PATH + 'import'} className="confirmation-try-again">
            {t('import.confirmation.tryAgain')}
          </Link>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(Confirmation)
