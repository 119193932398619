import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import Tooltip from '@material-ui/core/Tooltip'
import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from 'components/Form/Text/TextField'
import { Field, Form, Formik } from 'formik'
import axios from 'core/api'
import { useAppContext } from 'App'
import Yup from 'core/validations/index'
import { useAuthContext } from 'modules/Authentication'
import { CircularProgress } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { read, remove } from 'localstorage-helpr'
import useEventAnalytic from 'core/hooks/useEventAnalytic'
import GoogleAnalytics from 'core/constants/google-analytics'
import { STORE, TOKEN, USER } from 'core/constants/local-storage'
import { HORUS_LOGOUT_URL } from 'core/constants/horus'

const SendMailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="240"
    height="136"
    viewBox="0 0 240 136"
    fill="none"
    style={{
      margin: '30px 0'
    }}
  >
    <path
      d="M149.331 102.784C147.712 109.877 140.855 114.36 134.011 112.798C129.341 111.732 125.886 108.12 124.676 103.684C122.367 105.54 119.322 106.365 116.28 105.671C110.892 104.441 107.556 98.9146 108.831 93.3289C109.717 89.4508 112.598 86.5615 116.081 85.573L115.693 85.5765C111.989 70.4835 120.201 54.9778 120.201 54.9778C120.024 63.6242 121.951 70.0067 124.489 74.6331C124.489 74.6331 127.972 81.6537 132.421 83.4392C132.421 83.4392 130.561 81.3825 129.287 77.4197C131.266 73.0608 135.967 70.5208 140.655 71.591C146.044 72.8211 149.38 78.3469 148.105 83.9326C147.592 86.1782 146.401 88.0847 144.822 89.4945C148.593 92.5006 150.517 97.6187 149.339 102.779L149.331 102.784Z"
      fill="#ECEEF1"
      stroke="white"
      stroke-linejoin="round"
    />
    <path
      d="M16.7247 61.9266C7.49078 72.0632 8.26345 87.7987 18.4508 97.0789C25.4041 103.413 34.9523 105.094 43.2428 102.309C42.9632 107.975 45.1248 113.723 49.6526 117.847C57.6749 125.155 70.0744 124.609 77.3459 116.626C82.3944 111.084 83.6392 103.402 81.2373 96.7813L81.6713 97.4056C109.416 87.2679 124.309 57.6375 124.309 57.6375C111.018 67.1207 98.8771 70.8306 88.7846 71.696C88.7846 71.696 73.8857 73.597 66.0605 68.3824C66.0605 68.3824 71.3769 69.169 79.002 66.9881C83.5619 59.1854 82.202 48.9668 75.2217 42.6082C67.1994 35.3002 54.7999 35.847 47.5284 43.8293C44.6051 47.0383 42.9789 50.9709 42.5672 54.9958C33.6062 52.165 23.4417 54.535 16.7239 61.9095L16.7247 61.9266Z"
      fill="#ECEEF1"
      stroke="white"
      stroke-width="2"
      stroke-linejoin="round"
    />
    <path
      d="M230.454 42.321L226.918 47.3514L229.085 53.1375L223.189 51.3212L218.352 55.1437L218.242 48.9803L213.09 45.5475L218.921 43.5669L220.56 37.6183L224.282 42.5586L230.454 42.321Z"
      fill="#ECEEF0"
    />
    <path
      d="M57.7908 18.7845L51.5114 21.7441L50.4041 28.6315L45.6341 23.5546L38.7534 24.6023L42.089 18.4939L38.9527 12.247L45.78 13.5623L50.7119 8.64215L51.6031 15.5688L57.7908 18.7845Z"
      fill="#ECEEF0"
    />
    <path
      d="M112.222 23.2325L94.7552 11.082C93.1906 9.99359 93.8908 7.54113 95.7943 7.44289L240 0L112.222 23.2325Z"
      fill="#ECEEF0"
    />
    <path
      d="M112.222 23.2325L118.03 64.8574L133.518 51.3051L240 0L112.222 23.2325Z"
      fill="#DADEE2"
    />
    <path
      d="M118.029 64.8586L133.518 34.85H171.27L118.029 64.8586Z"
      fill="#B6BDC4"
    />
    <path
      d="M175.308 52.6765L239.999 0L133.517 34.8487L168.731 53.3363C170.858 54.4529 173.446 54.1933 175.308 52.6765Z"
      fill="#ECEEF0"
    />
  </svg>
)

const ModalForm = ({ handleClose, open, modalData, t }) => {
  const [loading, setLoading] = React.useState(false)
  const [sendMail, setSendMail] = React.useState(false)
  const { notify } = useAppContext()

  useEffect(() => {
    if (sendMail && open) {
      setSendMail(false)
    }
  }, [open])

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '500px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '8px',
      boxShadow: theme.shadows[5],
      padding: '46px 96px'
    }
  }))
  const classes = useStyles()

  const phoneRegExp = /^\(\d{2}\) \d{5}-\d{4}$/

  const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const fields = {
    name: modalData?.userName || '',
    company: modalData?.userCompany || '',
    email: modalData?.userEmail || '',
    phone: modalData?.userPhone || ''
  }
  const fieldsValidation = Yup.object().shape({
    name: Yup.string().max(40).required('Informe o seu nome.'),
    company: Yup.string().max(40).required('Identifique sua empresa.'),
    email: Yup.string()
      .max(80)
      .matches(emailRegExp, 'E-mail inválido')
      .required(
        'Informe o seu e-mail corporativo (o mesmo de acesso à sua conta).'
      ),
    phone: Yup.string()
      .max(20)
      .matches(phoneRegExp, { message: 'Telefone inválido' })
      .required('Informe o seu telefone de contato corporativo.')
  })

  const submitSign = (values, setSubmitting) => {
    setLoading(true)

    axios
      .post('horus/ExpressInterest', {
        app: modalData?.selectedProduct,
        name: values.name,
        company: values.company,
        email: values.email,
        phone: values.phone
      })
      .then((res) => {
        setSendMail(true)
      })
      .catch((error) => {
        console.error(error)
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  const body = (
    <>
      {!loading && !sendMail && (
        <div className={classes.paper}>
          <Typography variant="h6">
            Conheça o {modalData?.selectedProduct}
          </Typography>
          <Typography
            variant="caption"
            style={{
              textAlign: 'center',
              margin: '20px 0',
              color: '#666666'
            }}
          >
            Você ainda não tem acesso a este produto. Preencha seus dados para
            falar com alguém da nossa equipe e saber mais.
          </Typography>
          <Formik
            initialValues={fields}
            onSubmit={(values, { setSubmitting }) =>
              submitSign(values, setSubmitting)
            }
            validationSchema={fieldsValidation}
          >
            {(propsForm) => {
              return (
                <Form
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <Field
                    name="name"
                    label={t('dropDownSOO.modalForm.name')}
                    component={TextField}
                    placeholder={t('dropDownSOO.modalForm.name')}
                    className="login-input-form"
                  />
                  <Field
                    name="company"
                    label={t('dropDownSOO.modalForm.company')}
                    component={TextField}
                    placeholder={t('dropDownSOO.modalForm.company')}
                    className="login-input-form"
                  />
                  <Field
                    name="email"
                    label={t('dropDownSOO.modalForm.email')}
                    component={TextField}
                    placeholder={t('dropDownSOO.modalForm.email')}
                    className="login-input-form"
                  />
                  <InputMask
                    mask="(99) 99999-9999"
                    maskChar=""
                    value={propsForm.values.phone}
                    onChange={propsForm.handleChange}
                    onBlur={propsForm.handleBlur}
                  >
                    {() => (
                      <Field
                        name="phone"
                        label={t('dropDownSOO.modalForm.phone')}
                        component={TextField}
                        placeholder={t('dropDownSOO.modalForm.phone')}
                        className="login-input-form"
                      />
                    )}
                  </InputMask>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className="btn-sign"
                    disabled={propsForm.isSubmitting}
                    style={{
                      width: '40%',
                      borderRadius: '100px',
                      height: '40px'
                    }}
                  >
                    {t('dropDownSOO.modalForm.submitBtn')}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
      {loading && (
        <div className={classes.paper}>
          <CircularProgress />
        </div>
      )}
      {!loading && sendMail && (
        <div className={classes.paper}>
          <Typography variant="h6">Recebemos sua solicitação</Typography>
          <SendMailIcon />
          <Typography
            variant="caption"
            style={{
              textAlign: 'center',
              margin: '20px 0',
              color: '#666666'
            }}
          >
            Uma pessoa da nossa equipe entrará em contato em breve com mais
            informações.
          </Typography>
        </div>
      )}
    </>
  )

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  )
}

const DropDown = () => {
  const { t } = useTranslation()
  const { user, store } = useAuthContext()

  const [horusVoicesUser, sethorusVoicesUser] = useState('')
  const [horusApps, sethorusApps] = useState([])

  const [open, setOpen] = React.useState(false)
  const [modalData, setModalData] = React.useState(null)
  const handleOpen = (data) => {
    setModalData(data)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const { track } = useEventAnalytic()
  const { Actions, Category } = GoogleAnalytics

  useEffect(() => {
    if (!read('horus_yv_user') || !read('horus_apps')) {
      remove(USER)
      remove(STORE)
      remove(TOKEN)
      track(Actions.Logout, Category.Interaction)
      window.location.href = HORUS_LOGOUT_URL
      return
    }
    sethorusVoicesUser(read('horus_yv_user'))
    sethorusApps(read('horus_apps'))
  }, [])

  return (
    <>
      <ModalForm
        handleClose={handleClose}
        open={open}
        modalData={modalData}
        t={t}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '360px',
          maxHeight: '75vh'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px'
          }}
        >
          <span>{t('dropDownSOO.myProducts')}</span>
          <Tooltip title={t('dropDownSOO.tooltipMyProducts')} placement="rigth">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.25 5.25H9.75V6.75H8.25V5.25ZM8.25 8.25H9.75V12.75H8.25V8.25ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
                fill="black"
                fillOpacity="0.54"
              />
            </svg>
          </Tooltip>
        </div>
        {horusApps?.apps
          ?.filter((product) =>
            horusVoicesUser?.apps.some(
              (item) => item.name.toLowerCase() === product.name.toLowerCase()
            )
          )
          .map((product, index) => (
            <Button
              key={index}
              variant="text"
              style={{
                height: '72px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '16px',
                backgroundColor: product.name === 'Voices' ? '#E3F6FD' : ''
              }}
              href={product.url}
              target="_blank"
            >
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundImage: `url(${product.icon})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto'
                }}
              ></div>
              <span
                style={{
                  marginLeft: '24px',
                  textTransform: 'capitalize',
                  color: '#757575'
                }}
              >
                {product.name}
              </span>
            </Button>
          ))}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px'
          }}
        >
          <span>{t('dropDownSOO.anotherProducts')}</span>
          <Tooltip
            title={t('dropDownSOO.tooltipOtherProducts')}
            placement="right"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M8.25 5.25H9.75V6.75H8.25V5.25ZM8.25 8.25H9.75V12.75H8.25V8.25ZM9 1.5C4.86 1.5 1.5 4.86 1.5 9C1.5 13.14 4.86 16.5 9 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 9 1.5ZM9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15Z"
                fill="black"
                fillOpacity="0.54"
              />
            </svg>
          </Tooltip>
        </div>
        {horusApps?.apps
          ?.filter(
            (product) =>
              !horusVoicesUser?.apps.some(
                (item) => item.name.toLowerCase() === product.name.toLowerCase()
              )
          )
          .map((product, index) => (
            <Button
              key={index}
              variant="text"
              style={{
                height: '72px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '16px',
                backgroundColor: product.name === 'Voices' ? '#E3F6FD' : ''
              }}
              onClick={() =>
                handleOpen({
                  selectedProduct: product.name,
                  userName: user.Name,
                  userCompany: store.Name,
                  userEmail: user.Email,
                  userPhone: ''
                })
              }
              target="_blank"
            >
              <div
                style={{
                  width: '24px',
                  height: '24px',
                  backgroundImage: `url(${product.icon})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'auto'
                }}
              ></div>
              <span
                style={{
                  marginLeft: '24px',
                  color: '#757575',
                  textTransform: 'capitalize'
                }}
              >
                {product.name}
              </span>
            </Button>
          ))}
        <span
          style={{ border: '1px solid #E0E0E0', margin: '8px 16px' }}
        ></span>
        {!!horusVoicesUser?.apps?.find(
          (app) => app.administrative === true
        ) && (
          <Button style={{ height: '64px', display: 'flex', gap: '13px' }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19 19H5V5H12V3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V12H19V19ZM14 3V5H17.59L7.76 14.83L9.17 16.24L19 6.41V10H21V3H14Z"
                fill="#00AEEF"
              />
            </svg>
            <span style={{ color: '#00aeef' }}>
              {t('dropDownSOO.adminCentral')}
            </span>
          </Button>
        )}
      </div>
    </>
  )
}

export default DropDown
