import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 30
  },
  bar: {
    borderBottom: 'solid 1px #E0E0E0',
    minHeight: 50
  },
  listView: {
    padding: 24
  },
  cloudContainer: {
    marginTop: 56
  },
  tableContainer: {
    marginTop: 56
  },
  skeletonAndEmpty: {
    marginTop: 56,
    marginRight: 24
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  tabs: {
    minWidth: 700
  },
  table: {
    minWidth: 700
  },
  filter: {
    padding: 10,
    width: 300
  },
  label: {
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: 'rgba(0, 0, 0, 0.51)',
    bottom: '-10px'
  },
  filterTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '34px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
    marginLeft: 10
  },
  filterHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center'
  },
  field: {
    margin: '35px 0px'
  },
  stopWordsInput: {
    marginTop: 10
  },
  filterActions: {},
  npsTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '21px',
    lineHeight: '28px',
    color: '#000000'
  }
}))

export default useStyles
