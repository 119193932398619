import { withTranslation } from 'react-i18next'
import { MdMoreVert } from 'react-icons/md'

import Options from '../../../../components/Options/Options'
import OptionsItem from '../../../../components/Options/OptionsItem'
import AdminMenuItems from '../../constants/admin-menu-items.const'

const AdminItem = (props) => (
  <li
    className={`admin-item 
    ${props.admin.Active ? '' : 'admin-inactive'}`}
  >
    <span className="admin-label admin-options">
      <Options title={<MdMoreVert />}>
        {AdminMenuItems.map((item, index) => (
          <OptionsItem
            key={index}
            onClick={() => item.click(props)}
            title={item.title(props)}
          />
        ))}
      </Options>
    </span>

    <span className="admin-label admin-name">{props.admin.Name}</span>
    <span className="admin-label admin-email">{props.admin.Email}</span>
    <span className="admin-label admin-role">
      {props.admin.Roles &&
        props.admin.Roles.map((item) => props.t(`adminType.${item}`)).join(
          ', '
        )}
    </span>
  </li>
)

export default withTranslation()(AdminItem)
