const DateSelectionMap = [
  {
    value: 1,
    title: 'datepicker.preSelection.day'
  },
  {
    value: 2,
    title: 'datepicker.preSelection.yesterday'
  },
  {
    value: 3,
    title: 'datepicker.preSelection.week'
  },
  {
    value: 4,
    title: 'datepicker.preSelection.month'
  },
  {
    value: 5,
    title: 'datepicker.preSelection.currentQuarter'
  },
  {
    value: 6,
    title: 'datepicker.preSelection.lastQuarter'
  },
  {
    value: 7,
    title: 'datepicker.preSelection.year'
  },
  {
    value: 8,
    title: 'datepicker.preSelection.lastYear'
  }
]

export default DateSelectionMap
