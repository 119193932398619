import { getIn } from 'formik'
import {
  FormGroup,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  customWidth: {
    maxWidth: 230,
    textAlign: 'center'
  }
}))

const Visible = ({ field, form: { touched, errors }, ...props }) => {
  const classes = useStyles()

  return (
    <FormGroup
      className={`
        ${
          !!(getIn(touched, field.name) && getIn(errors, field.name))
            ? 'visible-error'
            : ''
        }`}
    >
      <FormControlLabel
        control={
          <IconButton
            aria-label={props.label}
            style={{ border: '1px solid #c4c4c4', borderRadius: '4px' }}
          >
            {props?.tooltip ? (
              <Tooltip
                title={props.tooltip}
                placement="top"
                classes={{ tooltip: classes.customWidth }}
              >
                <input
                  {...field}
                  {...props}
                  name={field.name}
                  id={field.name}
                  value={field.name}
                  checked={field.value}
                  type="checkbox"
                  className="form-editor-checkbox"
                />
              </Tooltip>
            ) : (
              <input
                {...field}
                {...props}
                name={field.name}
                id={field.name}
                value={field.name}
                checked={field.value}
                type="checkbox"
                className="form-editor-checkbox"
              />
            )}
            {!!field.value ? <Visibility color="primary" /> : <VisibilityOff />}
          </IconButton>
        }
      />

      {!!(
        field.name &&
        getIn(touched, field.name) &&
        getIn(errors, field.name)
      ) && <FormHelperText>{getIn(errors, field.name)}</FormHelperText>}
    </FormGroup>
  )
}

export default Visible
