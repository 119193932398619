import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

export const AverageCard = ({ classes, data, t }) => {
  return (
    <Grid item xs>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Paper className={classes.averageCard}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            className={classes.averageCard}
          >
            <Grid item xs>
              <Typography className={classes.title} color="primary">
                {Number(data.Average).toFixed(2) || '-'}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.subtitle}>
                {t('dimension.chart.averageTitle')}
              </Typography>
              <Typography className={classes.description}>
                {t('dimension.chart.averageDescription')}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Grid>
  )
}
