import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 20px 0px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`
export const Dropdown = styled.div`
  margin: 0 10px;
`
export const PageIndicator = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  margin: 0px 48px;
`
export const PageNumberOffset = styled.div``
export const Title = styled.div``
