import { useCallback, useMemo, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'

import { QS_PAGE } from 'core/constants/query-strings'

import { uuid } from 'core/helpers'

import queryString from 'query-string'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import LoaderText from '../../LoaderText'

import '../../../style/component/form/dropdown.css'

const campaignAll = (t) => ({
  id: 'all',
  title: t('component.dropdown.campaignAll')
})

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2)
  },
  formControl: {
    minWidth: 250,
    width: '100%',
    maxWidth: 346
  }
}))

const Dropdown = ({
  campaignAllSelected,
  loading,
  value,
  qs,
  location,
  items,
  onSelected,
  history,
  name,
  fullWidth,
  testId,
  className,
  disabled,
  t
}) => {
  const classes = useStyles()

  const initialCurrent = campaignAllSelected ? campaignAll(t) : null

  const [current, setCurrent] = useState(initialCurrent)
  const [id] = useState(uuid.generator())

  const getCurrentItem = useMemo(() => {
    let result = ''

    if (loading) return ''

    if (value || value === 0) return value

    if (current) return current.id

    if (!qs) return result

    let currentSearch = queryString.parse(location.search)

    let qsString = ''
    if (Array.isArray(qs)) {
      if (qs.length) {
        qsString = qs[0]
      }
    } else {
      qsString = qs
    }

    if (!currentSearch[qsString]) return result

    let itemResult = items.filter((item) => {
      if (item.id.toString() === currentSearch[qsString]) return item

      return null
    })

    if (itemResult.length > 0) return itemResult[0].id.toString()

    return result
  }, [current, items, loading, location.search, qs, value])

  const currentItemIsValid = useMemo(() => {
    return !!items.find((i) => i.id.toString() === getCurrentItem.toString())
  }, [getCurrentItem, items])

  const selectValue = useCallback(
    (value) => {
      let item = items.find((i) => i.id === value)
      setCurrent(item)

      if (onSelected) onSelected(item)

      if (!qs) return

      let current = queryString.parse(location.search)

      if (Array.isArray(qs)) {
        qs.forEach((qs) => {
          current[qs] = item.id
          if (current[qs] === 'all') delete current[qs]
        })
      } else {
        if (current[qs] === item.id) return
        current[qs] = item.id
        if (current[qs] === 'all') delete current[qs]
      }

      delete current[QS_PAGE]
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(current)
      })
    },
    [history, items, location.pathname, location.search, onSelected, qs]
  )
  
  return (
    <FormControl className={classes.formControl}>
      <InputLabel>
        {loading ? <LoaderText /> : name || t('component.dropdown.label')}
      </InputLabel>
      <Select
        fullWidth={fullWidth}
        data-testid={'dropdown-select-' + testId}
        className={'dropdown-select ' + className}
        labelId={id + '-label'}
        id={id}
        value={currentItemIsValid ? getCurrentItem : ''}
        onChange={({ target }) => selectValue(target.value)}
        MenuProps={{ disableScrollLock: true }}
        disabled={disabled}
      >
        {!loading &&
          items.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default withTranslation()(withRouter((props) => <Dropdown {...props} />))
