const { makeStyles } = require('@material-ui/core')

export const useStyles = makeStyles((theme) => ({
  select: {
    margin: 'auto 0xp'
  },
  selectMenu: {
    height: 'none'
  },
  typography: {
    margin: 'auto 0px'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 100
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  listItemIcon: {
    width: '30px !important',
    minWidth: '30px !important'
  },
  icon: {
    color: '#00aeef'
  }
}))
