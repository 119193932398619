import { useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'

import Button from '@material-ui/core/Button'

import { BASE_PATH } from '../../../../router'

import Yup from 'core/validations/index'
import axios from 'core/api'

import * as Pattern from 'core/validations/pattern'

import TextField from '../../../../components/Form/Text/TextField'
import PasswordField from '../../../../components/Form/Text/PasswordField'
import CheckboxGroup from '../../../../components/Form/Box/CheckboxGroup'
import CheckboxItem from '../../../../components/Form/Box/CheckboxItem'

import AdminSubtitle from '../../constants/admin-subtitle.const'

import { useHistory } from 'react-router-dom'
import { useAppContext } from '../../../../App'

import useStyle from './style'
import Loader from '../../../../components/Loader'
import Header from 'components/Header'

const AdminEdit = ({ match, t }) => {
  const { notify } = useAppContext()

  const classes = useStyle()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const locationState = history.location.state
    ? history.location.state
    : { Name: '', Email: '', Roles: [] }
  const [admin, setAdmin] = useState(locationState)

  const [newAdmin] = useState(() => {
    const item = match.params.item

    if (item !== 'new' && isNaN(item)) {
      history.push(BASE_PATH + 'administrator')
      return
    }

    if (item === 'new') return true
    else return false
  })

  useEffect(() => {
    if (!newAdmin && !admin.Email) {
      setLoading(true)

      axios
        .get('apiadmin/get?IdAdmin=' + match.params.item)
        .then((res) => {
          if (!res.data) history.push(BASE_PATH + 'administrator')

          setAdmin(res.data)
        })
        .catch((error) => {
          notify(error.message, 'error')
          history.push(BASE_PATH + 'administrator')
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validationSchema = Yup.object().shape({
    Name: Pattern.name,
    Email: Pattern.email,
    Password: newAdmin ? Pattern.password : Yup.string(),
    PasswordAgain: newAdmin ? Pattern.PasswordAgain : Yup.string(),
    Roles: Yup.array().required()
  })

  const initialValues = {
    Name: admin.Name,
    Email: admin.Email,
    Password: '',
    PasswordAgain: '',
    Roles: admin.Roles.map((item) => item.toString())
  }

  const subimitAdmin = (values, setSubmitting) => {
    setLoading(true)

    axios
      .post('apiadmin/insertupdate', {
        Email: values.Email,
        Name: values.Name,
        Password: values.Password,
        PasswordConfirmation: values.PasswordAgain,
        AdminRoles: values.Roles.map((item) => parseInt(item))
      })
      .then((res) => {
        if (res.data && res.data.HasErrors) throw new Error('Error on update')

        notify(t('success.save'), 'success')

        history.push(BASE_PATH + 'administrator')
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  return (
    <div id="admin-edit-form" className="admin-edit white-form">
      <Header title={t('admin.edit.title')} />

      {loading ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) =>
            subimitAdmin(values, setSubmitting)
          }
          validationSchema={validationSchema}
        >
          {(propsForm) => {
            return (
              <Form>
                <Field
                  id="name"
                  name="Name"
                  component={TextField}
                  label={t('admin.edit.nameLbl')}
                  placeholder={t('admin.edit.nameDesc')}
                />

                <Field
                  id="email"
                  name="Email"
                  disabled={!newAdmin}
                  component={TextField}
                  label={t('admin.edit.emailLbl')}
                  placeholder={t('admin.edit.emailDesc')}
                />

                {newAdmin && (
                  <div>
                    <Field
                      id="password"
                      name="Password"
                      component={PasswordField}
                      label={t('admin.edit.passwordLbl')}
                      placeholder={t('admin.edit.passwordDesc')}
                    />
                    <Field
                      id="passwordAgain"
                      name="PasswordAgain"
                      component={PasswordField}
                      label={t('admin.edit.passwordAgainLbl')}
                      placeholder={t('admin.edit.passwordAgainDesc')}
                    />
                  </div>
                )}

                <CheckboxGroup
                  id="Roles"
                  value={propsForm.values.Roles}
                  error={propsForm.errors.Roles}
                  touched={propsForm.touched.Roles}
                  onChange={propsForm.setFieldValue}
                  onBlur={propsForm.setFieldTouched}
                >
                  {['1', '2', '3', '4'].map((item) => (
                    <Field
                      key={item}
                      name="Roles"
                      component={CheckboxItem}
                      id={item}
                      label={t(`adminType.${item}`)}
                    />
                  ))}
                </CheckboxGroup>

                <div className={classes.subtitle}>
                  {AdminSubtitle.map((item, index) => (
                    <p
                      key={index}
                      id={'subtitle-description-' + item}
                      className="admin-edit-desc"
                    >
                      <strong>{t(item.type)}: </strong> {t(item.description)}
                    </p>
                  ))}
                </div>

                <div>
                  <Button
                    id="save"
                    type="submit"
                    size="medium"
                    color="primary"
                    variant="contained"
                    className={classes.button}
                    disabled={propsForm.isSubmitting}
                  >
                    {t('uiActions.save')}
                  </Button>

                  <Button
                    id="cancel"
                    size="medium"
                    className={classes.button}
                    onClick={(event) => {
                      history.goBack()
                      event.preventDefault()
                    }}
                  >
                    {t('uiActions.cancel')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
    </div>
  )
}

export default withTranslation()(AdminEdit)
