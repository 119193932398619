import { withTranslation } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router-dom'

import Link from './components/Link'
import Upload from './components/Upload'
import Confirmation from './components/Confirmation'

import Header from '../../components/Header'

import '../../style/route/import/main.css'

const Import = ({ history, match, t }) => {
  const currentStep = () => {
    const urls = history.location.pathname.split('/')
    const current = urls[urls.length - 1]

    switch (current) {
      case 'upload':
        return 1

      case 'link':
        return 2

      case 'confirmation':
        return 3

      default:
        return 1
    }
  }

  const nextStep = () => {
    const current = currentStep() + 1
    switch (current) {
      case 1:
        history.push(`${match.url}/upload`)
        break

      case 2:
        history.push(`${match.url}/link`)
        break

      case 3:
        history.push(`${match.url}/confirmation`)
        break

      default:
        break
    }
  }

  return (
    <>
      <Header type="filters" title={t('import.title')} />

      <header className="import-steps">
        <ul>
          <li
            className={
              'import-single-step ' + (currentStep() === 1 && 'active')
            }
          >
            <span className="step-value">1</span>
            <label className="step-label">{t('import.stepIntro')}</label>
          </li>
          <li
            className={
              'import-single-step ' + (currentStep() === 2 && 'active')
            }
          >
            <span className="step-value">2</span>
            <label className="step-label">{t('import.stepLink')}</label>
          </li>
          <li
            className={
              'import-single-step ' + (currentStep() === 3 && 'active')
            }
          >
            <span className="step-value">3</span>
            <label className="step-label">{t('import.stepConfirm')}</label>
          </li>
        </ul>
      </header>
      <main>
        <Switch>
          <Route
            path={`${match.url}/upload`}
            render={(routeProps) => (
              <Upload {...routeProps} nextStep={nextStep} />
            )}
          />
          <Route
            path={`${match.url}/link`}
            render={(routeProps) => (
              <Link {...routeProps} nextStep={nextStep} />
            )}
          />
          <Route
            path={`${match.url}/confirmation`}
            render={(routeProps) => <Confirmation {...routeProps} />}
          />
          <Redirect path={`${match.url}/`} to={`${match.url}/upload`} />
        </Switch>
      </main>
    </>
  )
}

export default withTranslation()(Import)
