function toDropdown(raw) {
  if (raw.length > 0)
    return raw
      .map((item) => {
        return {
          id: item.Key,
          name: item.Name
        }
      })
      .filter((item) => {
        if (item.id && item.name) return item
        return null
      })

  return []
}

function toDropdownById(raw) {
  if (raw.length > 0)
    return raw
      .map((item) => {
        return {
          id: item.IdYourviews,
          name: item.Name,
          type: item.Type
        }
      })
      .filter((item) => {
        if (item.id && item.name) return item
        return null
      })

  return []
}

function valuesToDropdown(raw) {
  if (raw.length > 0) {
    let suggestions = []
    raw.forEach((dimension) => {
      suggestions = suggestions.concat(
        dimension.Values.map((value) => {
          return {
            id: value.IdYourviews,
            name: dimension.Name + ' ' + value.Value
          }
        })
      )
    })

    return suggestions
  }

  return []
}

export { toDropdown, toDropdownById, valuesToDropdown }
