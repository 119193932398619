import './index.css'
import { withTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'

const CustomCard = ({
  children,
  loading,
  padding,
  titleText,
  titleIconComponent,
  value,
  subValue,
  footerInfo,
  footerComponent
}) => {
  return (
    <div className="custom-card-wrapper">
      <div className="custom-card" style={{ padding: padding || '10px 20px' }}>
        <div>
          <div className="custom-card-title">
            {titleText && (
              <div className="custom-card-title-text">{titleText}</div>
            )}
            {titleIconComponent && (
              <div className="custom-card-title-icon">{titleIconComponent}</div>
            )}
          </div>

          {!loading ? (
            <div className="custom-card-value">{value}</div>
          ) : (
            <Skeleton variant="rect" height={20} width={60} />
          )}

          {subValue && (
            <>
              <div className="custom-card-subvalue">
                {!loading ? (
                  subValue
                ) : (
                  <Skeleton variant="rect" height={14} width={65} />
                )}
              </div>
            </>
          )}

          {children}
        </div>

        <div className="custom-card-footer">
          {footerInfo && (
            <div className="custom-card-footer-info">
              {!loading ? (
                footerInfo
              ) : (
                <Skeleton variant="rect" height={14} width="100%" />
              )}
            </div>
          )}
          {footerComponent && (
            <>
              {!loading ? (
                footerComponent
              ) : (
                <Skeleton variant="rect" height={25} width={115} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(CustomCard)
