import './index.css'
import { withTranslation } from 'react-i18next'
import { Chart } from 'react-google-charts'
import { useCallback, useMemo } from 'react'
import {
  getSearchWithDimensionId,
  getSearchWithoutDimensionId
} from '../../functions/get-search-with-dimension-id'
import { useHistory, useLocation } from 'react-router-dom'
import { QS_DIMENSION } from 'core/constants/query-strings'
import queryString from 'query-string'
import { renderToString } from 'react-dom/server'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import TreeViewSkeleton from './components/TreeViewSkeleton'
import i18n from '../../../../../lang'
import ReportTooltip from '../ReportTooltip'
import InfoText from '../InfoText'

const treeHeight = 450

const TreeView = ({
  decimal,
  loadingDecimal,
  dimensionsScoreData,
  loadingDimensionsScoreData,
  valueScoreData,
  loadingValueScoreData,
  t
}) => {
  const { search, pathname } = useLocation()
  const { push } = useHistory()

  const parsedSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const dimensionKeyOnQuery = useMemo(() => {
    return parsedSearch[QS_DIMENSION]
  }, [parsedSearch])

  const parsedData = useMemo(() => {
    const parsedData = [
      ['Dimensão', 'Pai', 'Quantidade', 'NPS'],
      ['NPS', null, 0, 0]
    ]

    if (!dimensionKeyOnQuery) {
      //if has not selected any dimensions, show all dimensions from dimensionsScoreData
      parsedData.push(
        ...dimensionsScoreData.Items.map((item) => {
          return [item.Dimension, 'NPS', item.TotalCount, item.Score]
        })
      )

      //adding one "fake child" element for each dimension,
      //so the graph knows it they have children and show the pointer cursor
      parsedData.push(
        ...dimensionsScoreData.Items.map((item, i) => {
          return [i + 1, item.Dimension, item.TotalCount, item.Score]
        })
      )
    } else {
      //if has selected a dimension, show it's values from valueScoreData
      parsedData.push(
        ...valueScoreData.Items.map((item) => {
          return [item.DimensionValue, 'NPS', item.TotalCount, item.Score]
        })
      )
    }

    return parsedData
  }, [dimensionKeyOnQuery, dimensionsScoreData.Items, valueScoreData.Items])

  const handleClickOnDimension = useCallback(
    (Chart) => {
      const selectedData =
        parsedData[Chart.chartWrapper.getChart().getSelection()[0].row + 1]

      if (selectedData && selectedData[1] === 'NPS') {
        const selectedDimensionKey = selectedData[0].replace(
          / \[[^[]*?\]$/g,
          ''
        )
        push({
          pathname: pathname,
          search: getSearchWithDimensionId(
            search,
            selectedDimensionKey,
            dimensionsScoreData?.Items
          )
        })
      } else {
        push({
          pathname: pathname,
          search: getSearchWithoutDimensionId(search)
        })
      }
    },
    [dimensionsScoreData?.Items, parsedData, pathname, push, search]
  )

  const isLoading = useMemo(() => {
    return loadingDecimal || loadingDimensionsScoreData || loadingValueScoreData
  }, [loadingDecimal, loadingDimensionsScoreData, loadingValueScoreData])

  const tooltipFunc = useCallback(
    (row) => {
      const data = parsedData[row + 1]
      if (data) {
        const name = data[0]
        const score = intlFloatPoint(
          (data[3] || 0).toFixed(decimal),
          i18n.language
        )
        const quantity = data[2]

        return renderToString(
          <ReportTooltip name={name} score={score} quantity={quantity} />
        )
      }
    },
    [decimal, parsedData]
  )

  return (
    <div className="tree-wrapper">
      <div className="tree-scale-text-outer">
        <div className="tree-scale-text-inner">
          <div className="tree-scale-text">
            {t('nps-reports.tree.zoneTextBad')}
          </div>
          <div className="tree-scale-text">
            {t('nps-reports.tree.zoneTextGood')}
          </div>
        </div>
      </div>

      {isLoading ? (
        <TreeViewSkeleton treeHeight={treeHeight} />
      ) : (
        <Chart
          chartType="TreeMap"
          loader={<TreeViewSkeleton treeHeight={treeHeight} />}
          data={parsedData}
          options={{
            maxColorValue: 100,
            minColorValue: -100,
            minColor: '#EF3E39',
            midColor: '#F7A723',
            maxColor: '#60BB46',
            headerHeight: 0,
            fontColor: '#FFFFFF',
            fontSize: 14,
            showScale: true,
            height: treeHeight,
            width: '99.99%',
            generateTooltip: tooltipFunc
          }}
          chartEvents={[
            {
              eventName: 'select',
              callback: handleClickOnDimension
            }
          ]}
        />
      )}
      <InfoText text={t('nps-reports.tree.info')} />
    </div>
  )
}

export default withTranslation()(TreeView)
