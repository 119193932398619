import { useForm, Controller } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router'
import { useLayoutEffect, useState, useEffect, useCallback } from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import { Alert, AlertTitle } from '@material-ui/lab'
import { NavigateBefore } from '@material-ui/icons'
import ErrorIcon from '@material-ui/icons/Error'
import ReplayIcon from '@material-ui/icons/Replay'
import PowerOffIcon from '@material-ui/icons/PowerOff'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Switch from '@material-ui/core/Switch'
import Radio from '@material-ui/core/Radio'
import {
  FormControlLabel,
  Grow,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import {
  ButtonLabel,
  HeaderTitle,
  LeftActions,
  RightActions,
  Row,
  Container,
  Content
} from '../Form/style'
import {
  SaveButton,
  BoxGroup,
  Text,
  SafeArea,
  SimpleButton,
  LastCheckText,
  RequiredText,
  InputIcon
} from './style'
import { WIDGET_COLOR_TYPE } from 'core/enums/widget-color-type'
import FieldSafeArea from '../FieldSafeArea'
import FieldAreaTitle from '../FieldAreaTitle'
import WidgetNps from 'components/WidgetNps'
import ScriptModal from './components/ScriptModal'
import getData from 'core/functions/get-data.function'
import { useCampaign } from '../Details/context'
import { useSnackbar } from 'notistack'
import Close from '@material-ui/icons/Close'
import { debounce } from 'lodash'
import {
  MdSentimentSatisfied,
  MdSentimentNeutral,
  MdSentimentDissatisfied
} from 'react-icons/md'

const NotInstalledAlert = ({ dateTimeNow, handleUpdateAlert, t }) => {
  return (
    <div>
      {' '}
      <Alert
        style={{
          margin: 'auto',
          borderRadius: '4px'
        }}
        icon={<PowerOffIcon />}
        severity="warning"
      >
        <div style={{ width: '515px' }}>
          <AlertTitle style={{ color: '#F5961A' }}>
            <strong>{t('campaing.edit.form.newWidget.alertTitleError')}</strong>
          </AlertTitle>
          {dateTimeNow && (
            <LastCheckText>
              {'Última verificação: ' +
                dateTimeNow.getDate() +
                ' de ' +
                t('admin.months.' + dateTimeNow.getMonth() + '') +
                ' de ' +
                dateTimeNow.getFullYear() +
                ', ' +
                t('admin.days.' + dateTimeNow.getDay() + '') +
                ' ' +
                String(dateTimeNow.getHours()).padStart(2, '0') +
                ':' +
                String(dateTimeNow.getMinutes()).padStart(2, '0')}
            </LastCheckText>
          )}
          <ReplayIcon
            style={{
              color: '#F5961A',
              float: 'right',
              marginTop: '-15px',
              cursor: 'pointer'
            }}
            onClick={handleUpdateAlert}
          />
        </div>
      </Alert>
    </div>
  )
}
const InstalledAlert = ({ dateTimeNow, handleUpdateAlert, t }) => {
  return (
    <div>
      <Alert
        style={{
          margin: 'auto',
          borderRadius: '4px'
        }}
        icon={<CheckCircleIcon />}
        severity="success"
      >
        <div style={{ width: '515px' }}>
          <AlertTitle style={{ color: '#4EAC37' }}>
            <strong>
              {t('campaing.edit.form.newWidget.alertTitleSuccess')}
            </strong>
          </AlertTitle>
          {dateTimeNow && (
            <LastCheckText>
              {'Última verificação: ' +
                dateTimeNow.getDate() +
                ' de ' +
                t('admin.months.' + dateTimeNow.getMonth() + '') +
                ' de ' +
                dateTimeNow.getFullYear() +
                ', ' +
                t('admin.days.' + dateTimeNow.getDay() + '') +
                ' ' +
                String(dateTimeNow.getHours()).padStart(2, '0') +
                ':' +
                String(dateTimeNow.getMinutes()).padStart(2, '0')}
            </LastCheckText>
          )}
          <ReplayIcon
            style={{
              color: '#4EAC37',
              float: 'right',
              marginTop: '-15px',
              cursor: 'pointer'
            }}
            onClick={handleUpdateAlert}
          />
        </div>
      </Alert>
    </div>
  )
}
const NotRequisitedAlert = ({
  dateTimeNow,
  handleUpdateAlert,
  interval,
  t
}) => {
  return (
    <div>
      {' '}
      <Alert
        style={{
          margin: 'auto',
          borderRadius: '4px'
        }}
        icon={<ErrorIcon />}
        severity="warning"
      >
        <div style={{ width: '515px' }}>
          <AlertTitle style={{ color: '#F5961A' }}>
            <strong>
              {t('campaing.edit.form.newWidget.alertTitleDiff') +
                interval +
                ' ' +
                t('campaing.edit.form.newWidget.days')}
            </strong>
          </AlertTitle>
          {dateTimeNow && (
            <LastCheckText>
              {'Última verificação: ' +
                dateTimeNow.getDate() +
                ' de ' +
                t('admin.months.' + dateTimeNow.getMonth() + '') +
                ' de ' +
                dateTimeNow.getFullYear() +
                ', ' +
                t('admin.days.' + dateTimeNow.getDay() + '') +
                ' ' +
                String(dateTimeNow.getHours()).padStart(2, '0') +
                ':' +
                String(dateTimeNow.getMinutes()).padStart(2, '0')}
            </LastCheckText>
          )}
          <Text>
            <a
              style={{
                display: 'flex',
                float: 'right',
                marginRight: '35px',
                color: '#F5961A',
                marginTop: '-13px',
                textDecoration: 'none'
              }}
              href="https://help.hiplatform.com/"
              target="_blanck"
            >
              {t('campaing.edit.form.newWidget.knowMore')}
            </a>
          </Text>
          <ReplayIcon
            style={{
              color: '#F5961A',
              float: 'right',
              marginTop: '-15px',
              cursor: 'pointer',
              marginRight: '-125px'
            }}
            onClick={handleUpdateAlert}
          />
        </div>
      </Alert>
    </div>
  )
}

function Widget({ t }) {
  const history = useHistory()
  const { search } = useLocation()
  const handlePrevious = () => {
    history.push({ pathname: '/admin/campaign/details', search })
  }

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSetWidget, widget } = useCampaign()

  const [fieldsHidden, setFieldsHidden] = useState(
    widget.GratefulSwitch ?? true
  )
  const handleChangeFields = (checked) => {
    setFieldsHidden(checked)
  }

  const [fieldsTitle, setFildsTitle] = useState(widget.GradeSwitch ?? true)
  const handleChangeFildsTitle = (checked) => {
    setFildsTitle(checked)
  }

  const [activeSwitch, setActiveSwitch] = useState(widget.Active)
  const handleChangeActive = (checked) => {
    setActiveSwitch(checked)
  }

  const [fieldHidden, setFieldHidden] = useState(widget.ReopenDelay ? true : false)

  const handleChangeField = (checked) => {
    setFieldHidden(checked)
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const reopenValueFormatted = (value, newIntervalOption) => {
    let ReopenDelay
    if (value && newIntervalOption === 1) {
      ReopenDelay = Math.round((value / 60) * 10) / 10
    } else if (value && newIntervalOption === 2) {
      ReopenDelay = Math.round((value / 60 / 24) * 10) / 10
    }
    return ReopenDelay
  }

  const [newReopenValue, setNewReopenValue] = useState(widget.ReopenDelay)

  const defaultValues = {
    intervalOption: widget.intervalOption || 1,
    NpsButtonsColor: widget.NpsButtonsColor || 0,
    ReopenDelay: reopenValueFormatted(widget.ReopenDelay, widget.intervalOption || 1),
    ShowChance: widget.ShowChance || '30',
    TimeToShow: widget.TimeToShow || '0',

    Title: widget.Title || '',
    Active: widget.Active || false,

    allPeople: widget.SendMessageDetractorlTitle || '',
    SendMessageDetractorlTitle: widget.SendMessageDetractorlTitle || '',
    SendMessageNeutralTitle: widget.SendMessageNeutralTitle || '',
    SendMessagePromoterTitle: widget.SendMessagePromoterTitle || '',

    allPeopleTitle: widget.CommentDetractorTitle || '',
    CommentDetractorTitle: widget.CommentDetractorTitle || '',
    CommentNeutralTitle: widget.CommentNeutralTitle || '',
    CommentPromoterTitle: widget.CommentPromoterTitle || '',

    GradeSwitch: widget.GradeSwitch ?? true,
    GratefulSwitch: widget.GratefulSwitch ?? true,
    ReopenSwitch: widget.ReopenDelay ? true : false
  }

  const { handleSubmit, control, getValues, watch, setValue } = useForm({ defaultValues })

  const [title, setTitle] = useState(widget.Title)
  const setTitleDebounce = debounce((value) => {
    setTitle(value)
  }, 500)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouceRequest = useCallback((value) => setTitleDebounce(value), [])
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'Title') {
        debouceRequest(value.Title)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch])

  const onSubmit = (data) => {
    if (data.intervalOption === 1) {
      data.ReopenDelay = data.ReopenDelay * 60
    } else if (data.intervalOption === 2) {
      data.ReopenDelay = data.ReopenDelay * 60 * 24
    }
    if (fieldsHidden) {
      data.SendMessageDetractorlTitle = data.allPeople
      data.SendMessageNeutralTitle = data.allPeople
      data.SendMessagePromoterTitle = data.allPeople
    }

    if (fieldsTitle) {
      data.CommentDetractorTitle = data.allPeopleTitle
      data.CommentNeutralTitle = data.allPeopleTitle
      data.CommentPromoterTitle = data.allPeopleTitle
    }
    if (!fieldHidden) {
      delete data.intervalOption
      delete data.ReopenDelay
      delete widget.intervalOption
      delete widget.ReopenDelay
    }
    delete data.allPeople
    delete data.allPeopleTitle

    handleSetWidget({
      ...widget,
      ...data
    })
    enqueueSnackbar(t('campaing.edit.form.newWidget.messageSuccess'), {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Grow,
      action
    })
    history.push({ pathname: '/admin/campaign/details', search })
  }

  const action = (key) => (
    <div
      onClick={() => {
        closeSnackbar(key)
      }}
    >
      <Close style={{ cursor: 'pointer' }} />
    </div>
  )
  const [selectedValue, setSelectedValue] = useState(
    defaultValues.NpsButtonsColor
  )

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const [dateTimeNow, setDateTimeNow] = useState(null)
  const [date, setDate] = useState(true)
  const [interval, setInterval] = useState()
  const [alertToShow, setAlertToShow] = useState(null)

  const handleUpdateAlert = async () => {
    await getData('StoreSettings/GetDateWidgetLastRequest', ({ Date }) =>
      setDate(Date)
    )
    let auxDate = new Date()
    let timeDiff =
      (Date.parse(auxDate.toISOString()) - Date.parse(date)) / (1000 * 60 * 60)

    setDateTimeNow(auxDate)

    if (timeDiff >= 24) {
      setInterval(Math.trunc(timeDiff / 24))
      setAlertToShow('notRequisited')
    } else if (timeDiff < 24) {
      setAlertToShow('installed')
    } else if (!date) {
      setAlertToShow('notInstalled')
    }
  }

  useLayoutEffect(() => {
    handleUpdateAlert()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  const AlertsMap = {
    notInstalled: (
      <NotInstalledAlert
        t={t}
        handleUpdateAlert={handleUpdateAlert}
        interval={interval}
        dateTimeNow={dateTimeNow}
      />
    ),
    installed: (
      <InstalledAlert
        t={t}
        handleUpdateAlert={handleUpdateAlert}
        interval={interval}
        dateTimeNow={dateTimeNow}
      />
    ),
    notRequisited: (
      <NotRequisitedAlert
        t={t}
        handleUpdateAlert={handleUpdateAlert}
        interval={interval}
        dateTimeNow={dateTimeNow}
      />
    )
  }

  return (
    <Container>
      <Row fixed>
        <LeftActions>
          <IconButton onClick={handlePrevious}>
            <NavigateBefore color="primary" />
          </IconButton>

          <ButtonLabel onClick={handlePrevious}>
            {t('campaing.edit.form.newWidget.headerBack')}
          </ButtonLabel>
        </LeftActions>

        <HeaderTitle>{t('campaing.edit.form.newWidget.title')}</HeaderTitle>

        <RightActions>
          <SaveButton data-testid="saveButton" onClick={handleSubmit(onSubmit)}>
            {t('campaing.edit.form.newWidget.Save')}
          </SaveButton>
        </RightActions>
      </Row>

      <div style={{ height: 80 }} />

      <Content>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: '100%', marginTop: '20px' }}
        >
          <BoxGroup>
            <FieldSafeArea>
              <FieldAreaTitle
                text={t('campaing.edit.form.newWidget.radiosTitle')}
              />
              <RequiredText>*Campos obrigatórios</RequiredText>
              <Controller
                name="Title"
                control={control}
                rules={{ required: true }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState: { errors }
                }) => (
                  <TextField
                    value={value ?? ''}
                    onChange={onChange}
                    inputRef={ref}
                    style={{ width: '100%' }}
                    label="Titulo inicial"
                    variant="outlined"
                    error={!!errors.Title}
                    helperText={
                      !!errors.Title
                        ? t('campaing.edit.form.newWidget.invalidTextField')
                        : ' '
                    }
                    data-testid="titleNpsColors"
                  />
                )}
              />
              <FieldAreaTitle
                label={t(
                  'campaing.edit.form.newWidget.npsColorButtonsSubtitle'
                )}
              />
              <Controller
                name="NpsButtonsColor"
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <RadioGroup
                    aria-label="gender"
                    style={{ display: 'flex', flexDirection: 'row' }}
                    value={value}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={WIDGET_COLOR_TYPE.colored}
                      control={
                        <Radio
                          color="primary"
                          // eslint-disable-next-line eqeqeq
                          checked={value == WIDGET_COLOR_TYPE.colored}
                        />
                      }
                      label={t(
                        'dispatchColorType.' + WIDGET_COLOR_TYPE.colored
                      )}
                      onChange={handleChange}
                    />
                    <FormControlLabel
                      value={WIDGET_COLOR_TYPE.colorless}
                      control={
                        <Radio
                          color="primary"
                          checked={
                            // eslint-disable-next-line eqeqeq
                            value == WIDGET_COLOR_TYPE.colorless
                          }
                        />
                      }
                      label={t(
                        'dispatchColorType.' + WIDGET_COLOR_TYPE.colorless
                      )}
                      onChange={handleChange}
                    />
                  </RadioGroup>
                )}
              />
            </FieldSafeArea>
          </BoxGroup>
          <BoxGroup>
            <FieldSafeArea>
              <FieldAreaTitle
                text={t('campaing.edit.form.newWidget.titleAfterChoiceGrade')}
              />
              <RequiredText>*Campos obrigatórios</RequiredText>

              {fieldsTitle && (
                <Controller
                  name="allPeopleTitle"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: { errors }
                  }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      style={{ width: '100%' }}
                      label={t('campaing.edit.form.newWidget.allPeople')}
                      variant="outlined"
                      error={!!errors.allPeopleTitle}
                      helperText={
                        !!errors.allPeopleTitle
                          ? t('campaing.edit.form.newWidget.invalidTextField')
                          : ' '
                      }
                      data-testid="titleReasonGrade"
                    />
                  )}
                />
              )}
              {!fieldsTitle && (
                <>
                  <Controller
                    name="CommentPromoterTitle"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputIcon>
                              <MdSentimentSatisfied size={25} color="#60BB46" />
                            </InputIcon>
                          )
                        }}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        style={{ width: '100%' }}
                        label={t('campaing.edit.form.newWidget.promoters')}
                        variant="outlined"
                        error={!!errors.CommentPromoterTitle}
                        helperText={
                          !!errors.CommentPromoterTitle
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                        data-testid="titleReasonGradePromoter"
                      />
                    )}
                  />
                  <Controller
                    name="CommentNeutralTitle"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputIcon>
                              <MdSentimentNeutral size={25} color="#F7A723" />
                            </InputIcon>
                          )
                        }}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        style={{ width: '100%' }}
                        label={t('campaing.edit.form.newWidget.neutrals')}
                        variant="outlined"
                        error={!!errors.CommentNeutralTitle}
                        helperText={
                          !!errors.CommentNeutralTitle
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                        data-testid="titleReasonGradeNeutral"
                      />
                    )}
                  />
                  <Controller
                    name="CommentDetractorTitle"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputIcon>
                              <MdSentimentDissatisfied
                                size={25}
                                color="#EF3E39"
                              />
                            </InputIcon>
                          )
                        }}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        style={{ width: '100%' }}
                        label={t('campaing.edit.form.newWidget.detractors')}
                        variant="outlined"
                        error={!!errors.CommentDetractorTitle}
                        helperText={
                          !!errors.CommentDetractorTitle
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                        data-testid="titleReasonGradeDetractor"
                      />
                    )}
                  />
                </>
              )}
              <Controller
                name="GradeSwitch"
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Switch
                    checked={value}
                    color="primary"
                    onChange={(e) => {
                      handleChangeFildsTitle(e.target.checked)
                      onChange(e)
                    }}
                  />
                )}
              />
              <Text> {t('campaing.edit.form.newWidget.switchText')}</Text>
            </FieldSafeArea>
          </BoxGroup>
          <BoxGroup>
            <FieldSafeArea>
              <FieldAreaTitle
                text={t('campaing.edit.form.newWidget.textTitle')}
              />
              <RequiredText>*Campos obrigatórios</RequiredText>

              {fieldsHidden && (
                <Controller
                  name="allPeople"
                  control={control}
                  rules={{ required: true }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: { errors }
                  }) => (
                    <TextField
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      style={{ width: '100%' }}
                      label={t('campaing.edit.form.newWidget.allPeople')}
                      variant="outlined"
                      error={!!errors.allPeople}
                      helperText={
                        !!errors.allPeople
                          ? t('campaing.edit.form.newWidget.invalidTextField')
                          : ' '
                      }
                      data-testid="titlegratefulGrade"
                    />
                  )}
                />
              )}
              {!fieldsHidden && (
                <>
                  <Controller
                    name="SendMessagePromoterTitle"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputIcon>
                              <MdSentimentSatisfied size={25} color="#60BB46" />
                            </InputIcon>
                          )
                        }}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        style={{ width: '100%' }}
                        label={t('campaing.edit.form.newWidget.promoters')}
                        variant="outlined"
                        error={!!errors.SendMessagePromoterTitle}
                        helperText={
                          !!errors.SendMessagePromoterTitle
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                        data-testid="titlegratefulPromoter"
                      />
                    )}
                  />
                  <Controller
                    name="SendMessageNeutralTitle"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputIcon>
                              <MdSentimentNeutral size={25} color="#F7A723" />
                            </InputIcon>
                          )
                        }}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        style={{ width: '100%' }}
                        label={t('campaing.edit.form.newWidget.neutrals')}
                        variant="outlined"
                        error={!!errors.SendMessageNeutralTitle}
                        helperText={
                          !!errors.SendMessageNeutralTitle
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                        data-testid="titlegratefulNeutral"
                      />
                    )}
                  />
                  <Controller
                    name="SendMessageDetractorlTitle"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputIcon>
                              <MdSentimentDissatisfied
                                size={25}
                                color="#EF3E39"
                              />
                            </InputIcon>
                          )
                        }}
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        style={{ width: '100%' }}
                        label={t('campaing.edit.form.newWidget.detractors')}
                        variant="outlined"
                        error={!!errors.SendMessageDetractorlTitle}
                        helperText={
                          !!errors.SendMessageDetractorlTitle
                            ? t('campaing.edit.form.newWidget.invalidTextField')
                            : ' '
                        }
                        data-testid="titlegratefulDetractor"
                      />
                    )}
                  />
                </>
              )}
              <Controller
                name="GratefulSwitch"
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState
                }) => (
                  <Switch
                    checked={value}
                    color="primary"
                    onChange={(e) => {
                      handleChangeFields(e.target.checked)
                      onChange(e)
                    }}
                  />
                )}
              />
              <Text> {t('campaing.edit.form.newWidget.switchText')}</Text>
            </FieldSafeArea>
          </BoxGroup>
          <BoxGroup>
            <FieldSafeArea>
              <FieldAreaTitle
                text={t('campaing.edit.form.newWidget.textTitle2')}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <SafeArea>
                  <FieldAreaTitle
                    label={t('campaing.edit.form.newWidget.formSubtitle')}
                  />
                  <Controller
                    name="ShowChance"
                    control={control}
                    rules={{
                      validate: () => {
                        return (
                          getValues('ShowChance') >= 0 &&
                          getValues('ShowChance') <= 100 &&
                          getValues('ShowChance') !== ''
                        )
                      }
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        data-testid="widget-percentage-input"
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        type="number"
                        pattern="[^0-9]+"
                        min={0}
                        error={!!errors.ShowChance}
                        helperText={
                          !!errors.ShowChance
                            ? t('campaing.edit.form.newWidget.invalid')
                            : ' '
                        }
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        style={{
                          width: '68px',
                          marginBottom: '0px',
                          textAlignLast: 'center'
                        }}
                      />
                    )}
                  />
                  <Text
                    style={{
                      width: '68px',
                      fontSize: '18px',
                      marginRight: '12px',
                      lineHeight: '40px'
                    }}
                  >
                    %
                  </Text>
                </SafeArea>
                <SafeArea>
                  <FieldAreaTitle
                    label={t(
                      'campaing.edit.form.newWidget.formSubtitleInterval'
                    )}
                  />
                  <Controller
                    name="TimeToShow"
                    control={control}
                    rules={{
                      validate: () => {
                        return (
                          getValues('TimeToShow') >= 0 &&
                          getValues('TimeToShow') !== ''
                        )
                      }
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState: { errors }
                    }) => (
                      <TextField
                        value={value}
                        onChange={onChange}
                        inputRef={ref}
                        min={0}
                        type="number"
                        error={!!errors.TimeToShow}
                        helperText={
                          !!errors.TimeToShow
                            ? t('campaing.edit.form.newWidget.invalid')
                            : ' '
                        }
                        InputProps={{ inputProps: { min: 0 } }}
                        style={{
                          width: '68px',
                          textAlignLast: 'center'
                        }}
                      />
                    )}
                  />
                  <Text
                    style={{
                      width: '68px',
                      fontSize: '16px',
                      marginRight: '12px',
                      lineHeight: '40px'
                    }}
                  >
                    {t('campaing.edit.form.newWidget.intervalAfter')}
                  </Text>
                </SafeArea>
              </div>

              <SafeArea style={{ marginTop: '32px' }}>
                <Text
                  style={{
                    fontWeight: '500',
                    display: 'block',
                    marginBottom: '15px'
                  }}
                >
                  {t('campaing.edit.form.newWidget.actionclose')}
                </Text>
                <Controller
                  name="ReopenSwitch"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <Switch
                      data-testid="close-actions-switch"
                      color="primary"
                      onChange={(e) => {
                        handleChangeField(e.target.checked)
                        onChange(e)
                      }}
                      checked={value}
                      inputRef={ref}
                    />
                  )}
                />

                <Text>{t('campaing.edit.form.newWidget.switchCloseText')}</Text>
                <Controller
                  name="ReopenDelay"
                  control={control}
                  rules={{
                    validate: () => {
                      return (
                        getValues('ReopenDelay') >= 0 &&
                        getValues('ReopenDelay') !== ''
                      )
                    }
                  }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: { errors }
                  }) => (
                    <TextField
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value)
                        setNewReopenValue(
                          getValues("intervalOption") === 1 
                          ? e.target.value * 60 
                          : e.target.value * 60 * 24
                        ) 
                      }}
                      inputRef={ref}
                      type="number"
                      error={!!errors.ReopenDelay}
                      InputProps={{ inputProps: { min: 0 } }}
                      style={{
                        width: '68px',
                        paddingLeft: '5px',
                        paddingRight: '5px',
                        textAlignLast: 'center'
                      }}
                      helperText={
                        !!errors.ReopenDelay
                          ? t('campaing.edit.form.newWidget.invalid')
                          : ' '
                      }
                    />
                  )}
                />
                <Controller
                  name="intervalOption"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState: { errors }
                  }) => (
                    <Select
                      id="demo-simple-select"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value)
                        setValue("ReopenDelay", reopenValueFormatted(newReopenValue, e.target.value))
                      }}
                      ref={ref}
                      style={{
                        position: 'relative',
                        bottom: 3
                      }}
                    >
                      <MenuItem value={1}>
                        {t('campaing.edit.form.newWidget.hours')}
                      </MenuItem>
                      <MenuItem value={2}>
                        {t('campaing.edit.form.newWidget.days')}
                      </MenuItem>
                    </Select>
                  )}
                />
              </SafeArea>
            </FieldSafeArea>
          </BoxGroup>
          <BoxGroup style={{ display: 'block', paddingBottom: '15px' }}>
            <FieldSafeArea>
              <FieldAreaTitle
                text={t('campaing.edit.form.newWidget.textTitle3')}
              />
              <SafeArea
                style={{ marginBottom: '20px', display: 'flex' }}
                center
              >
                <Controller
                  name="Active"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState
                  }) => (
                    <Switch
                      checked={value}
                      color="primary"
                      onChange={(e) => {
                        handleChangeActive(e.target.checked)
                        onChange(e)
                      }}
                    />
                  )}
                />
                <Text
                  style={{
                    fontWeight: '500',
                    display: 'block',
                    lineHeight: '40px'
                  }}
                >
                  {activeSwitch
                    ? t('campaing.edit.form.newWidget.switchActive')
                    : t('campaing.edit.form.newWidget.switchInactive')}
                </Text>
              </SafeArea>

              <SafeArea style={{ marginBottom: '10px' }} center>
                {alertToShow && AlertsMap[alertToShow]}
              </SafeArea>

              <SimpleButton onClick={handleOpen}>
                {t('campaing.edit.form.newWidget.buttonModal')}
              </SimpleButton>
            </FieldSafeArea>
          </BoxGroup>
          <SaveButton
            data-testid="saveButtonLarge"
            onClick={handleSubmit(onSubmit)}
            largeSize
          >
            {t('campaing.edit.form.newWidget.Save')}
          </SaveButton>

          <div style={{ height: 300 }}></div>

          <WidgetNps title={title} color={selectedValue} />
        </form>
      </Content>

      <ScriptModal open={open} handleClose={handleClose} />
    </Container>
  )
}

export default withTranslation()(Widget)
