const { makeStyles } = require('@material-ui/core')

const useStyle = makeStyles((theme) => ({
  subtitle: {
    margin: '20px 10px'
  },
  button: {
    margin: theme.spacing(1),
    marginTop: 20
  }
}))

export default useStyle
