import { withTranslation } from 'react-i18next'
import { IconButton, TextField } from '@material-ui/core'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import StarIcon from '@material-ui/icons/Star'
import { LeftActions } from 'components/Wizard/components/Header/style'
import {
  SaveButton,
  Text,
  Container,
  Row,
  ButtonLabel,
  HeaderTitle,
  Colum,
  BoxChoice,
  Box,
  List,
  Item,
  SubText
} from './style'
import { useHistory, useLocation } from 'react-router'
import vectorC from 'assets/img/Vectorc.svg'
import vectorS from 'assets/img/Vectors.svg'
import vectorSCSAT from 'assets/img/Vectorscsat.svg'
import vectorA from 'assets/img/Vectora.svg'
import vectorT from 'assets/img/Vectort.svg'
import vectorN from 'assets/img/Vectorn.svg'
import vectorP from 'assets/img/Vectorp.svg'
import { useCallback, useEffect, useState } from 'react'
import { useCampaign } from '../Details/context'
import { useAppContext } from 'App'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

const npsArray = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
const npsCsat = ['1', '2', '3', '4', '5']

function ChoiceCampaign({ t }) {
  const history = useHistory()
  const { search } = useLocation()

  const {
    campaign,
    handleSetCampaign,
    handleSetForm,
    handleSetEmail,
    handleSetDetails,
    handleSetDispatch,
    handleSetBoxListItem
  } = useCampaign()

  const { showCsat } = useAppContext()

  const [typeCampaign, setTypeCampaign] = useState(NPS_CSAT_TAB.nps.typeValue)
  const [colorNps, setColorNps] = useState('#e3f6fd')
  const [colorCsat, setColorCsat] = useState('#fff')

  useEffect(() => {
    handleSetCampaign({})
    handleSetForm({})
    handleSetEmail({})
    handleSetDetails({})
    handleSetDispatch([])
    handleSetBoxListItem({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (typeCampaign === NPS_CSAT_TAB.nps.typeValue) {
      setColorNps('#e3f6fd')
      setColorCsat('#fff')
    } else if (typeCampaign === NPS_CSAT_TAB.csat.typeValue) {
      setColorNps('#fff')
      setColorCsat('#e3f6fd')
    }
  }, [typeCampaign])

  const handlePrevious = useCallback(
    () => history.push('/admin/campaign'),
    [history]
  )
  const handleGoToForm = useCallback(() => {
    handleSetDetails({
      ...campaign,
      Type: typeCampaign
    })

    history.push({
      pathname: '/admin/campaign/form',
      search
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, typeCampaign])

  useEffect(() => {
    if (!showCsat) {
      history.push({
        pathname: '/admin/campaign/form',
        search
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <Row fixed style={{ marginBottom: '90px' }}>
        <LeftActions>
          <IconButton onClick={handlePrevious}>
            <NavigateBefore color="primary" />
          </IconButton>

          <ButtonLabel onClick={handlePrevious}>
            {t('campaing.edit.form.newWidget.headerBack')}
          </ButtonLabel>
        </LeftActions>

        <HeaderTitle>{t('campaing.choiceCampaign.selectType')}</HeaderTitle>
      </Row>

      <Colum medio>
        <HeaderTitle
          style={{ fontSize: '36px', width: '381px', marginBottom: '21px' }}
        >
          {t('campaing.choiceCampaign.searchType')}
        </HeaderTitle>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            marginBottom: '18px'
          }}
        >
          <BoxChoice
            style={{ backgroundColor: colorNps }}
            onClick={() => setTypeCampaign(NPS_CSAT_TAB.nps.typeValue)}
          >
            <img src={vectorN} style={{ marginRight: '5px' }} alt="vectorNPS" />
            <img src={vectorP} style={{ marginRight: '3px' }} alt="vectorNPS" />
            <img src={vectorS} alt="vectorNPS" />
          </BoxChoice>
          <BoxChoice
            style={{ backgroundColor: colorCsat }}
            onClick={() => setTypeCampaign(NPS_CSAT_TAB.csat.typeValue)}
          >
            <img src={vectorC} style={{ marginRight: '2px' }} alt="vectorNPS" />
            <img
              src={vectorSCSAT}
              style={{ marginRight: '2px' }}
              alt="vectorNPS"
            />
            <img src={vectorA} style={{ marginRight: '2px' }} alt="vectorNPS" />
            <img src={vectorT} alt="vectorNPS" />
          </BoxChoice>
        </div>
        <Text center>
          {typeCampaign === NPS_CSAT_TAB.nps.typeValue
            ? t('campaing.choiceCampaign.textSubititleSearchNPS')
            : t('campaing.choiceCampaign.textSubititleSearchCSAT')}
        </Text>
        <SaveButton onClick={handleGoToForm} largeSize>
          {t('campaing.choiceCampaign.select')}
        </SaveButton>
      </Colum>
      <Colum medio>
        <HeaderTitle
          style={{ fontSize: '18px', width: '381px', marginBottom: '25px' }}
        >
          {t('campaing.choiceCampaign.exemple')}
        </HeaderTitle>

        <Box>
          {typeCampaign === NPS_CSAT_TAB.nps.typeValue && (
            <>
              <Text style={{ marginBottom: '16px' }}>
                {t('campaing.choiceCampaign.textQuestion')}
              </Text>
              <List>
                {npsArray.map((item, index) => (
                  <Item key={index} floatLeft>
                    <span>
                      <input
                        type="radio"
                        disabled
                        style={{
                          width: '20px',
                          height: '20px',
                          marginBottom: '14px'
                        }}
                      />
                    </span>
                    <span>{item}</span>
                  </Item>
                ))}
              </List>
              <SubText>{t('campaing.choiceCampaign.scoreOneAndSix')}</SubText>
              <Text style={{ marginBottom: '14px' }}>
                {t('campaing.choiceCampaign.textComent')}
              </Text>

              <SubText>
                {t('campaing.choiceCampaign.scoreSevenAndEight')}
              </SubText>
              <Text style={{ marginBottom: '14px' }}>
                {t('campaing.choiceCampaign.textComent')}
              </Text>
              <SubText>{t('campaing.choiceCampaign.scoreNineAndTen')}</SubText>
              <Text style={{ marginBottom: '14px' }}>
                {t('campaing.choiceCampaign.textComent')}
              </Text>
              <TextField
                id="standard-basic"
                label="Campo de texto"
                variant="standard"
                fullWidth
              />
            </>
          )}
          {typeCampaign === NPS_CSAT_TAB.csat.typeValue && (
            <>
              <Text style={{ marginBottom: '16px' }}>
                {t('campaing.choiceCampaign.textQuestionCsat')}
              </Text>
              <List style={{ display: 'flex' }}>
                {npsCsat.map((item, index) => (
                  <Item key={index} floatLeft starIcon>
                    <span>
                      <StarIcon
                        style={{
                          width: '27px',
                          height: '27px',
                          marginBottom: '14px',
                          color: '#BFBFBF'
                        }}
                      />
                    </span>
                  </Item>
                ))}
              </List>
              <br />
              <Text style={{ marginBottom: '14px' }}>
                {t('campaing.choiceCampaign.textComent')}
              </Text>

              <TextField
                id="standard-basic"
                label="Campo de texto"
                variant="standard"
                fullWidth
              />
            </>
          )}
        </Box>
      </Colum>
    </Container>
  )
}
export default withTranslation()(ChoiceCampaign)
