import './index.css'
import { withTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import warning from 'assets/img/warning.svg'

const CopyLinkDialog = ({ rowBeingCopied, handleCancel, handleConfirm, t }) => {
  return (
    <Dialog open={!!rowBeingCopied} onClose={handleCancel}>
      <div className="conversion-email-answertable-dialog">
        <div className="conversion-email-answertable-dialog-img">
          <img src={warning} alt="Warning" height={150} />
        </div>

        <div className="conversion-email-answertable-dialog-title">
          {t('conversion-report.email.answers.dialog.title', {
            name:
              rowBeingCopied.Person?.Name ||
              t('conversion-report.email.answers.dialog.person')
          })}
        </div>

        <div className="conversion-email-answertable-dialog-content">
          {t('conversion-report.email.answers.dialog.content1')}
        </div>
        <div className="conversion-email-answertable-dialog-content">
          {t('conversion-report.email.answers.dialog.content2', {
            name:
              rowBeingCopied.Person?.Name ||
              t('conversion-report.email.answers.dialog.person')
          })}
        </div>

        <div className="conversion-email-answertable-dialog-actions">
          <Button onClick={handleCancel} color="primary">
            {t('conversion-report.email.answers.dialog.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
          >
            {t('conversion-report.email.answers.dialog.confirm')}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default withTranslation()(CopyLinkDialog)
