import './index.css'
import { withTranslation } from 'react-i18next'
import CustomCard from 'components/CustomCard'
import { Visibility, Person, Check } from '@material-ui/icons'
import { roundNumber } from 'core/functions/intl-float-point.function'

const WidgetCards = ({ widgetData, loadingWidgetData, t, i18n }) => {
  const { language } = i18n
  return (
    <div className="conversion-widget-cards">
      <CustomCard
        loading={loadingWidgetData}
        titleText={t('conversion-report.widget.views')}
        titleIconComponent={<Visibility color="primary" />}
        value={roundNumber(widgetData.Viewed, 0)}
        footerInfo={t('conversion-report.widget.viewsInfo')}
      />
      <CustomCard
        loading={loadingWidgetData}
        titleText={t('conversion-report.widget.people')}
        titleIconComponent={<Person color="primary" />}
        value={roundNumber(widgetData.ViewedByPerson, 0, language)}
        footerInfo={t('conversion-report.widget.peopleAverage', {
          total: roundNumber(widgetData.ViewedByPerson, 0, language),
          average: roundNumber(
            widgetData.Viewed / widgetData.ViewedByPerson,
            1,
            language
          )
        })}
      />
      <CustomCard
        loading={loadingWidgetData}
        titleText={t('conversion-report.widget.answered')}
        titleIconComponent={<Check color="primary" />}
        value={
          roundNumber(
            (100 * widgetData.Answered) / widgetData.ViewedByPerson,
            1,
            language
          ) + '%'
        }
        footerInfo={t('conversion-report.widget.answeredTotal', {
          total: roundNumber(widgetData.ViewedByPerson, 0, language),
          answered: roundNumber(widgetData.Answered, 0, language)
        })}
      />
    </div>
  )
}

export default withTranslation()(WidgetCards)
