export const NPS_CSAT_TAB = {
  nps: {
    tab: 0,
    typeValue: 0,
    typeParam: 'nps'
  },
  csat: {
    tab: 1,
    typeValue: 1,
    typeParam: 'csat'
  }
}
