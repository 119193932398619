import queryString from 'query-string'

import { QS_PAGE } from 'core/constants/query-strings'

/**
 * Get queryString from browser URL
 * @param {object} state state from Class based component
 * @param {object} props props from component
 * @param {boolean} exportation flag for export of not
 */
export const get = (state, exportation) => {
  let currentQS = queryString.parse(window.location.search)

  if (exportation) {
    currentQS['PageSize'] = state.pagination.total * 15
    delete currentQS[QS_PAGE]
  }

  let currentQS_URL = queryString.stringify(currentQS)
  if (!currentQS_URL.startsWith('?')) currentQS_URL = '?' + currentQS_URL

  return currentQS_URL
}

export const getQSValue = (QS) => {
  let currentQS = queryString.parse(window.location.search)

  if (!currentQS[QS]) return false

  return currentQS[QS]
}
