import styled from 'styled-components'

const StatusColor = {
  0: '#A0D690',
  1: '#EF3E39',
  2: '#FF8F00'
}

export const Status = styled.div`
  margin: 10px 0px;
  text-align: center;
  max-width: 400px;
`
export const StatusMessage = styled.div`
  ${({ type }) => `background-color: ${StatusColor[type]};`}
  color: #fff;
  padding: 10px;
  max-width: 400px;
  min-width: 300px;
`

export default Status
