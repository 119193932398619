import styled from 'styled-components'

import { makeStyles, withStyles } from '@material-ui/core'
import { Rating as MaterialRating } from '@material-ui/lab'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  margin-top: 24px;
`

export const List = styled.div``

export const ListItem = styled.div`
  display: flex;

  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;

  align-items: center;
  align-content: center;

  padding: 12px;

  ${({ noHover }) =>
    noHover
      ? ''
      : `
    &:hover {
      background-color: #f2fbfe;
    }
  `}

  ${({ index, length }) => {
    const first = index === 0
    const last = index === length - 1

    if (!first && !last && index === 0 && length === 1) {
      return `
      border-radius: 4px;
      border: 1px solid #e0e0e0;
      `
    } else if (first) {
      return `
        border-radius: 4px 4px 0px 0px;
      
        `
    } else if (last) {
      return `
          border-radius: 0px 0px 4px 4px;
          
          border: 1px solid #e0e0e0;
          
        `
    } else {
      return `
          border-radius: 0px;
          border: 1px solid #e0e0e0;
       
        `
    }
  }}
`
export const lineTitle = styled.div`
  width: 100%;
  background-color: red;
`
export const Dropdowns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  align-items: center;
  align-content: center;

  padding: 8px 12px;
`

export const Field = styled.div`
  width: 45%;
`

export const Divider = styled.div`
  margin-top: 24px;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: auto 0px;

  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: auto 0px;

  width: ${({ width }) => width};

  ${({ tableStyled }) =>
    tableStyled
      ? `
    margin-left: 36px;
    line-height: 56px;
    font-size:14px;
  `
      : ''}
`

export const Item = styled.div`
  margin: auto 0px;
  min-width: 28px;

  ${({ title }) =>
    title
      ? `
    margin-left:11px; 
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom:5px;

  `
      : ''}

  ${({ flexEndTitle }) =>
    flexEndTitle
      ? `
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-left: 5px;
        margin-bottom:5px;
      `
      : ''}

  ${({ flexEnd }) =>
    flexEnd
      ? `
      display: flex;
      justify-content: flex-end;
    `
      : ''}

  ${({ flexCenter }) =>
    flexCenter
      ? `
    display: flex;
    justify-content: center;
  `
      : ''}

    ${({ exempleMesage }) =>
    exempleMesage
      ? `
    font-size: 16px;
    line-height:24px;
    max-width:920px;
    margin:0 auto;
    margin-top: 8px;
  `
      : ''}
`

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid #e0e0e0;
  margin-top: 10px;
  padding-bottom: 40px;
`

export const Image = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 50px;
`
export const Title = styled.h4`
  display: flex;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.54);
`
export const SubTitle = styled.span`
  display: flex;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.54);
`

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  rating: {
    margin: '0px 8px'
  }
}))

export const Rating = withStyles({
  iconFilled: {
    color: '#00AEEF'
  },
  iconHover: {
    color: '##00AEEF'
  }
})(MaterialRating)

export default useStyles
