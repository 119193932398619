import styled from 'styled-components'

export const Container = styled.div``
export const Row = styled.div`
  display: flex;

  ${({ center }) =>
    center ? 'justify-content: center;' : 'justify-content: space-between;'}

  flex-direction: row;
  align-content: center;
  align-items: center;

  padding-top: auto;
  padding-bottom: auto;

  height: 68px;
  left: 0px;
  right: 0px;
  top: 0px;

  background: #ffffff;

  ${({ noBorder }) =>
    noBorder ? '' : 'border-bottom: 1px solid rgba(0, 0, 0, 0.12);'}
`

export const LeftActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-left: 8px;
`

export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 44px;

  text-align: center;

  color: #000000;
`

export const RightActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  margin-right: 8px;
`

export const ButtonLabel = styled.div`
  color: #00aeef;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
`

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: cente;
  align-self: center;
  align-content: center;
`
