export const HEADER_AUTH = 'Authorization'

export const URL_API_QA = 'https://npsqa.yourviews.com.br/'

const origin = window.location.origin

export const URL_API =
  origin.indexOf('localhost') > -1 || origin.includes('ngrok')
    ? URL_API_QA
    : origin + '/'
