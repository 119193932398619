import { withTranslation } from 'react-i18next'

const NoDataRow = ({ t }) => {
  return (
    <tr>
      <td colSpan="100%">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '40px 0 20px 0',
            fontSize: 18
          }}
        >
          {t('noData')}
        </div>
      </td>
    </tr>
  )
}

export default withTranslation()(NoDataRow)
