import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import SendIcon from '@material-ui/icons/Send'
import SaveIcon from '@material-ui/icons/Save'
import BackupIcon from '@material-ui/icons/Backup'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import FilterListIcon from '@material-ui/icons/FilterList'
import LiveTvIcon from '@material-ui/icons/LiveTv'

import Button from '@material-ui/core/Button'

import useStyles from './style'

const IconMap = {
  add: <AddIcon />,
  remove: <RemoveIcon />,
  delete: <DeleteIcon />,
  edit: <EditIcon />,
  send: <SendIcon />,
  save: <SaveIcon />,
  upload: <BackupIcon />,
  download: <CloudDownloadIcon />,
  load: <RotateLeftIcon />,
  reload: <RotateRightIcon />,
  export: <ImportExportIcon />,
  filter: <FilterListIcon />,
  tvMode: <LiveTvIcon />
}
const TitleMap = {
  add: 'uiActions.button.add',
  remove: 'uiActions.button.remove',
  delete: 'uiActions.button.delete',
  edit: 'uiActions.button.edit',
  send: 'uiActions.button.send',
  save: 'uiActions.button.save',
  upload: 'uiActions.button.upload',
  download: 'uiActions.button.download',
  load: 'uiActions.button.load',
  reload: 'uiActions.button.reload',
  export: 'uiActions.button.export',
  filter: 'uiActions.button.filter',
  tvMode: 'uiActions.button.tvMode'
}

function RoundedButton({
  size,
  color,
  onClick,
  variant,
  type,
  whiteTextLabel,
  text,
  t
}) {
  const classes = useStyles()

  return (
    <Button
      size={size}
      color={color}
      onClick={onClick}
      data-testid="header-button"
      variant={variant ?? ''}
      startIcon={IconMap[type] ?? <></>}
      className={`
      ${classes.root}
      ${whiteTextLabel && classes.whiteTextLabel}
      `}
    >
      {text || t(TitleMap[type])}
    </Button>
  )
}

const { oneOf, func, string } = PropTypes

RoundedButton.propTypes = {
  type: oneOf([
    'add',
    'remove',
    'delete',
    'edit',
    'send',
    'save',
    'upload',
    'download',
    'load',
    'reload',
    'export',
    'filter',
    'tvMode'
  ]),
  onClick: func,
  textColor: string,
  size: oneOf(['small', 'medium', 'large']),
  color: oneOf(['primary', 'secondary', 'inherit', 'default']),
  variant: oneOf(['contained', 'outlined', 'text'])
}

RoundedButton.defaultProps = {
  onClick: () => {},
  size: 'small',
  color: 'primary'
}

export default withTranslation()(RoundedButton)
