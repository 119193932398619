import { useState, useCallback } from 'react'

import { useAppContext } from '../../../../App'
import { useHistory, useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { withTranslation } from 'react-i18next'

import axios from 'core/api'
import qs from 'query-string'

import { BASE_PATH } from '../../../../router'

import { Switch, Button } from '@material-ui/core'

import useStyle from './style'

import NotificationItems from '../../constants/notifications-items.const'
import Loader from '../../../../components/Loader'
import useGetData from 'core/hooks/useGetData'

const Notifications = ({ t }) => {
  const { notify } = useAppContext()

  const classes = useStyle()
  const history = useHistory()
  const location = useLocation()

  const [admin, setAdmin] = useState(location.state ?? {})

  const [state, setState] = useState({
    DetractorDailyResumeActive: false,
    DetractorNotificationImmediately: false,
    NeutralDailyResumeActive: false,
    NeutralNotificationImmediately: false,
    PromoterDailyResumeActive: false,
    PromoterNotificationImmediately: false
  })
  const handleChange = (event) =>
    setState({
      ...state,
      [event.target.name]: event.target.checked
    })

  const { handleSubmit, control, reset } = useForm({ defaultValues: state })

  const idFromQueryString = qs.parse(location.search)?.IdAdmin

  const idForRequest = `?IdAdmin=${idFromQueryString ?? admin.IdYourviews}`

  const [loading, setLoading] = useState(false)
  const handleSetLoading = useCallback((x) => {
    setLoading(x)
  }, [])

  useGetData({
    endpoint: `/AdminNotificationConfiguration/GetByIdAdmin${idForRequest}`,
    setData: (data) => {
      setState(data)
      reset(data)
    },
    setLoading: handleSetLoading
  })

  useGetData({
    endpoint: `/apiadmin/get${idForRequest}`,
    setData: setAdmin,
    setLoading: handleSetLoading
  })

  const save = (formData) => {
    formData.IdAdmin = admin.IdYourviews
    formData.IdYourviews = state.IdYourviews

    handleSetLoading(true)

    axios
      .post(`/AdminNotificationConfiguration/InsertUpdate`, formData)
      .then((_) => {
        notify(t('success.save'), 'success')

        history.goBack()
      })
      .catch((error) => {
        notify(error.message, 'success')
      })
      .finally(() => {
        handleSetLoading(false)
      })
  }

  return (
    <>
      <div className={classes.pageTitle}>
        {t('admin.notifications.page-title')}
      </div>

      <div className={classes.pageDescription}>
        {t('admin.notifications.page-description')}
      </div>

      <div data-testid="admin-name-label" className={classes.nameDescription}>
        {t('admin.notifications.name-description')}{' '}
        <span className={classes.adminName}>{admin.Name}</span>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(save)}>
          {NotificationItems &&
            NotificationItems.map((item) => (
              <div key={item.id} id={item.id} className={item.className}>
                <div className={classes.answerLabel}>{t(item.title)}</div>

                {item.switchs.map((x, index) => (
                  <div key={index}>
                    <Controller
                      name={x.name}
                      control={control}
                      value={state[x.name]}
                      render={({
                        field: { name, onChange, value, onBlur, ref }
                      }) => (
                        <Switch
                          name={name}
                          size="small"
                          color="primary"
                          checked={value ?? state[x.name]}
                          onChange={(e) => {
                            handleChange(e)
                            onChange(e)
                          }}
                          onBlur={onBlur}
                          inputRef={ref}
                        />
                      )}
                    />{' '}
                    <span>{t(x.label)}</span>
                  </div>
                ))}
              </div>
            ))}

          <div className={classes.box}>
            <Button
              type="submit"
              size="medium"
              color="primary"
              variant="contained"
              className={classes.button}
            >
              {t('uiActions.save')}
            </Button>

            <Button
              size="medium"
              className={classes.button}
              onClick={(event) => {
                history.push(BASE_PATH + 'administrator')
                event.preventDefault()
              }}
            >
              {t('uiActions.cancel')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}

export default withTranslation()(Notifications)
