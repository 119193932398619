import { useState, useMemo } from 'react'
import { withTranslation } from 'react-i18next'

import postData from 'core/functions/post-data.function'

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined'
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DeleteIcon from '@material-ui/icons/Delete'
import CloseIcon from '@material-ui/icons/Close'
import EventIcon from '@material-ui/icons/Event'
import TimerIcon from '@material-ui/icons/Timer'
import LoopIcon from '@material-ui/icons/Loop'
import CheckIcon from '@material-ui/icons/Check'
import CancelIcon from '@material-ui/icons/Cancel'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'

import { Dialog, Button, DialogContent, DialogTitle } from '@material-ui/core'

import { ListItem } from '../Details/style'
import { Text } from 'components/WidgetNps/style'
import { DispatchList, ItemList, DispatchListName } from '../Email/style'
import { OptionLabel, OptionValue, StatusLabel } from './style'

import { useAppContext } from 'App'
import { useCampaign } from '../Details/context'
import { formatDateToString } from 'core/functions/format-date.function'

function DispatchEmail({ item, list, index, t, i18n }) {
  const { language } = i18n
  const { dispatch, handleSetDispatch } = useCampaign()
  const { notify } = useAppContext()
  const handleRemove = (id, IdYourviews) => {
    const dispatchs = [...dispatch]
    if (IdYourviews) {
      const dispa = dispatchs.find(
        (d) => d.IdCampaign && d.IdYourviews === IdYourviews
      )
      if (dispa) {
        postData({
          endpoint:
            'dispatchsendingschedule/changestatus?iddispatchsendingschedule=' +
            IdYourviews,
          data: {},
          setData: ({ HasErrors }) => {
            if (HasErrors) {
              return notify(t('dispatchStatus.anerror'), 'error')
            } else {
              dispa.Active = false
              handleSetDispatch(dispatchs)
              return notify(
                t('dispatchStatus.cancelDispatchSuccess'),
                'success'
              )
            }
          }
        })
      }
    } else {
      handleSetDispatch(dispatchs.filter((d) => d.id !== id))
    }
    handleClose()
  }

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const [expandle, setExpandle] = useState(false)
  const handleExpandle = () => {
    setExpandle(true)
  }
  const handleCloseExpandle = () => {
    setExpandle(false)
  }

  const dipatchType = useMemo(() => {
    const iconStyle = {
      fontSize: '18px',
      float: 'left',
      marginRight: '7px'
    }

    const Label = ({ children }) => (
      <div style={{ float: 'left' }}>{children}</div>
    )

    const TypesMap = new Map([
      [
        'default',
        <>
          <HelpOutlineOutlinedIcon style={iconStyle} />

          <Label>{t('dispatch.unknow')}</Label>
        </>
      ],
      [
        0,
        <>
          <CodeOutlinedIcon style={iconStyle} />

          <Label>{t('dispatch.api')}</Label>
        </>
      ],
      [
        1,
        <>
          <EventIcon style={iconStyle} />

          <Label>{t('dispatch.schedule')}</Label>
        </>
      ],
      [
        2,
        <>
          <TimerIcon style={iconStyle} />

          <Label>{t('dispatch.immediate')}</Label>
        </>
      ]
    ])

    return TypesMap.has(item?.Type)
      ? TypesMap.get(item?.Type)
      : TypesMap.get('default')
  }, [item.Type, t])

  const dispatchStatus = useMemo(() => {
    const iconStyle = (color) => ({
      fontSize: '18px',
      marginRight: '7px',
      color
    })

    const statusMap = {
      0: (
        <>
          <HourglassEmptyIcon color="primary" style={iconStyle()} />

          <StatusLabel style={{ color: '#00aeef' }}>
            {t('dispatch.waiting')}
          </StatusLabel>
        </>
      ),
      1: (
        <>
          <LoopIcon style={iconStyle('#00aeef')} />

          <StatusLabel style={{ color: '#00aeef' }}>
            {t('dispatch.processing')}
          </StatusLabel>
        </>
      ),
      2: (
        <>
          <CheckIcon style={iconStyle('#60BB46')} />

          <StatusLabel style={{ color: '#60BB46' }}>
            {t('dispatch.concluded')}
          </StatusLabel>
        </>
      ),
      3: (
        <>
          <CancelIcon color="primary" style={iconStyle('#EF3E39')} />

          <StatusLabel style={{ color: '#EF3E39' }}>
            {t('dispatch.cancel')}
          </StatusLabel>
        </>
      ),
      4: (
        <>
          <ErrorOutlineIcon color="primary" style={iconStyle('#EF3E39')} />

          <StatusLabel style={{ color: '#EF3E39' }}>
            {t('dispatch.error')}

            {!!item.ErrorMessage &&
              ': ' + t(`dispatch.errorType.${item.ErrorType}`)}
          </StatusLabel>
        </>
      )
    }

    const CanceledStatus = (
      <>
        <CancelIcon color="primary" style={iconStyle('#EF3E39')} />

        <StatusLabel style={{ color: '#EF3E39' }}>
          {t('dispatch.cancel')}
        </StatusLabel>
      </>
    )

    return item.Active ? statusMap[item.Status] : CanceledStatus
  }, [item.Active, item.ErrorMessage, item.ErrorType, item.Status, t])

  return (
    <>
      <DispatchList style={{ marginBottom: '12px' }}>
        <ListItem
          style={{
            padding: '26.5px 18px',
            width: '100%',
            flexWrap: 'wrap'
          }}
          borderRadius="4px"
        >
          <div>
            <DispatchListName style={{ flexGrow: 1 }} size="16">
              {list?.Name}
            </DispatchListName>

            <ItemList
              style={{
                marginTop: '1px',
                fontSize: '14px',
                lineHeight: '19.5px',
                display: 'flex',
                width: '100%'
              }}
            >
              <OptionLabel>{t('dispatch.status')}:</OptionLabel>

              <OptionValue>{dispatchStatus}</OptionValue>

              <div style={{ display: 'flex', marginRight: 10 }}>
                {!(
                  item.Type.toString() === '2' && item.Status.toString() === '0'
                ) &&
                  formatDateToString({
                    date: item.DateTimezone || item.Date,
                    language,
                    showSeconds: false
                  })}
              </div>
            </ItemList>

            <ItemList
              style={{
                marginTop: '1px',
                fontSize: '14px',
                lineHeight: '19.5px',
                display: 'flex',
                width: '100%'
              }}
            >
              <OptionLabel>{t('dispatch.type')}:</OptionLabel>

              <OptionValue>{dipatchType}</OptionValue>
            </ItemList>
          </div>

          <ItemList
            style={{
              marginTop: '-10px'
            }}
          >
            {item.IdCampaign &&
              item.Status.toString() !== '4' &&
              item.Status.toString() !== '3' &&
              item.Status.toString() !== '2' &&
              item.Active === true && (
                <HighlightOffIcon
                  onClick={handleClickOpen}
                  style={{
                    fontSize: '20px',
                    float: 'left',
                    marginRight: '7px',
                    cursor: 'pointer'
                  }}
                />
              )}
            {!item.IdCampaign && item.Status.toString() === '0' && (
              <DeleteIcon
                onClick={handleClickOpen}
                style={{
                  fontSize: '20px',
                  marginRight: '7px',
                  cursor: 'pointer'
                }}
              />
            )}
            {dispatch[index]?.DispatchSendingScheduleList?.length > 0 && (
              <>
                {!expandle && (
                  <ExpandMoreIcon
                    onClick={handleExpandle}
                    style={{
                      fontSize: '22px',
                      float: 'left',
                      marginRight: '7px',
                      cursor: 'pointer'
                    }}
                  />
                )}
                {expandle && (
                  <ExpandLessIcon
                    onClick={handleCloseExpandle}
                    style={{
                      fontSize: '22px',
                      float: 'left',
                      marginRight: '7px',
                      cursor: 'pointer'
                    }}
                  />
                )}
              </>
            )}
          </ItemList>
        </ListItem>

        {expandle && (
          <ListItem style={{ display: 'inline-block', width: '100%' }}>
            {dispatch[index].DispatchSendingScheduleList.map((item, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  display: 'flex',
                  marginBottom: '16px'
                }}
              >
                <ItemList
                  style={{
                    marginTop: '1px',
                    fontSize: '14px',
                    width: '100%',
                    lineHeight: '19.5px'
                  }}
                >
                  {item.Status.toString() === '0' && (
                    <>
                      <EventIcon
                        color="primary"
                        style={{
                          fontSize: '18px',
                          float: 'left',
                          marginRight: '7px'
                        }}
                      />
                      <div
                        style={{
                          float: 'left',
                          color: '#00aeef',
                          marginRight: '4px'
                        }}
                      >
                        {t('dispatch.schedule')}
                      </div>
                    </>
                  )}
                  {item.Status.toString() === '2' && (
                    <>
                      <CheckIcon
                        style={{
                          fontSize: '18px',
                          float: 'left',
                          marginRight: '7px',
                          color: '#60BB46'
                        }}
                      />
                      <div
                        style={{
                          float: 'left',
                          color: '#60BB46',
                          marginRight: '4px'
                        }}
                      >
                        {t('dispatch.concluded')}
                      </div>
                    </>
                  )}
                  {item.Status.toString() === '3' && (
                    <>
                      <CancelIcon
                        color="primary"
                        style={{
                          fontSize: '18px',
                          float: 'left',
                          marginRight: '7px'
                        }}
                      />
                      <div
                        style={{
                          float: 'left',
                          color: '#00aeef',
                          marginRight: '4px'
                        }}
                      >
                        {t('dispatch.cancel')}
                      </div>
                    </>
                  )}

                  <div style={{ float: 'left' }}>
                    {formatDateToString({
                      date: item.Date,
                      language,
                      showSeconds: false
                    })}
                    {t('campaing.edit.dispatch.sendRequestEmail')}
                  </div>
                </ItemList>
              </div>
            ))}
          </ListItem>
        )}
      </DispatchList>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth="sm"
      >
        <DialogTitle
          style={{ width: '430px', marginBottom: '8px' }}
          id="alert-dialog-title"
        >
          <Text
            style={{
              fontSize: '21px'
            }}
          >
            {t('campaing.edit.dispatch.dispacthCancel')}
          </Text>
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: '10px',
              fontSize: '23px',
              top: '10px',
              cursor: 'pointer'
            }}
          />
        </DialogTitle>
        <DialogContent>
          {t('campaign.fields.dispatch.dialog.messageModalDispatch')}
        </DialogContent>

        <DialogContent style={{ marginTop: '17px' }}>
          <Button
            style={{ float: 'right' }}
            variant="contained"
            color="primary"
            id="removeBtn"
            onClick={() => handleRemove(item.id, item.IdYourviews)}
          >
            {t('campaing.edit.dispatch.assurance')}
          </Button>
          <Button
            style={{ float: 'right', marginRight: '23px' }}
            onClick={handleClose}
            color="primary"
          >
            {t('stars.buttonClose')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default withTranslation()(DispatchEmail)
