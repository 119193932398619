import { withTranslation } from 'react-i18next'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import LoaderText from '../../LoaderText'

import { uuid } from 'core/helpers/index'

import '../../../style/component/form/dropdown.css'

const DropdownStateless = ({
  className,
  loading,
  label,
  name,
  value,
  items,
  onSelected,
  t
}) => {
  const id = uuid.generator()
  return (
    <FormControl className={'dropdown ' + (className ? className : '')}>
      <InputLabel id={id + '-label'}>
        {loading ? <LoaderText /> : label || t('component.dropdown.label')}
      </InputLabel>
      <Select
        labelId={id + '-label'}
        data-testid={'dropdown-stateless-' + name}
        id={id}
        value={value.id ? value.id : ''}
        onChange={(event) => {
          let item = items.find((i) => i.id === event.target.value)
          onSelected(item)
        }}
        MenuProps={{
          disableScrollLock: true
        }}
      >
        {!loading &&
          items.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.name}
            </MenuItem>
          ))}
        {items.length < 1 && (
          <MenuItem value="" disabled>
            {t('component.dropdown.noData')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

export default withTranslation()(DropdownStateless)
