import styled from 'styled-components'

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  max-width: 569.72px;
`

export const FormField = styled.div``

export const Label = styled.label`
  margin: 2.5px 0px;
`

export const FormFieldItem = styled.div``

export const FormActions = styled.div``
