/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import api from 'core/api'

import { withTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'

import CircularProgress from '@material-ui/core/CircularProgress'

import { ModalTitle, ModalSubtitle } from '../../../../style/components/Title'
import { FlexItem, FlexSpaceBetween } from '../../../../style/components/Flex'
import { Actions, Container, FormContainer, FormField, Header } from './style'
import { Status, StatusMessage } from '../../styles/Status'

import ItemValueTypeEnum from '../../enums/itemValueType.enum'

import Boolean from '../Boolean'
import MultiSelect from '../MultiSelect'
import RichText from '../RichText'
import SimpleText from '../SimpleText'
import SingleSelect from '../SingleSelect'
import { useAppContext } from '../../../../App'

const SendFormType = { Test: 'test', Save: 'save' }
const StatusType = { Success: 0, Error: 1, Unknown: 2 }

function Form({ infos, integrationError, onSave, onSwitch, onCancel, t }) {
  const { notify } = useAppContext()

  const fillFieldValue = (target) =>
    target.hasOwnProperty('checked') ? target.checked : target.value

  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [state, setState] = useState(null)
  const handleChange = (target, item) => {
    setState({
      ...state,
      [target.name]: {
        name: target.name,
        value: fillFieldValue(target),
        itemValueType: item.ItemValueType,
        valueType: item.ValueType,
        type: item.Type,
        idYourviews: item.IdYourviews,
        required: item?.required
      }
    })
  }

  const [data, setData] = useState([])
  const getConfig = async (type, keys) => {
    setLoading(true)
    try {
      const { data } = await api.get(
        `integration/config?integrationType=${type}&integrationKey=${keys[0]}`
      )
      setData(data)
      let newState = {}

      for (let item of data) {
        newState[item.Name] = {
          name: item.Name,
          value: item.Value,
          values: item.Values,
          required: item.Required,
          itemValueType: item.ItemValueType,
          valueType: item.ValueType,
          type: item.Type,
          idYourviews: item.IdYourviews
        }
      }

      setState(newState)
    } catch (error) {
      notify(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getConfig(infos.type, infos.keys)
  }, [])

  useEffect(() => {
    if (integrationError) {
      setStatus({
        type: StatusType.Error,
        message: integrationError
      })
    }
  }, [integrationError])

  const submitForm = async (type) => {
    const list = _.values(state)

    setLoading(true)

    try {
      const res = await api.post('integration/' + type, {
        type: infos?.type,
        configurations: list,
        idYourviews: infos?.id
      })

      const { Success, Message, HttpStatusCode } = res?.data

      const data = res?.data

      const SendFormTypeActionsMap = {
        [SendFormType.Save]: () => {
          let HasErrors = data[0]?.HasErrors

          if (HasErrors) {
            setStatus({
              type: StatusType.Error,
              message: data[0]?.ErrorList[0]?.Error
            })

            return
          }

          if (!HasErrors) {
            setStatus({
              type: StatusType.Success,
              message: 'Sucesso'
            })

            if (Success && HttpStatusCode === 200) {
              setStatus({
                type: StatusType.Success,
                message: Message,
                httpStatusCode: HttpStatusCode
              })
            }

            onSave()
          }
        },
        [SendFormType.Test]: () => {
          if (Success && HttpStatusCode === 200) {
            setStatus({
              type: StatusType.Success,
              message: Message,
              httpStatusCode: HttpStatusCode
            })
          } else {
            setStatus({
              type: StatusType.Error,
              message: Message,
              httpStatusCode: HttpStatusCode
            })
          }
        }
      }

      SendFormTypeActionsMap[type]()
    } catch (error) {
      if (error.response.data)
        setStatus({
          type: StatusType.Unknown,
          message: t('integrations.genericError'),
          httpStatusCode: error.response.status
        })
    } finally {
      setLoading(false)
    }
  }

  const [switchState, setSwitchState] = useState(infos?.active)
  const handleSwitch = ({ target }) => {
    setSwitchState(target.checked)
    onSwitch(target.checked)
  }

  const FormBuilder = (item) => {
    const componentProps = {
      item: item,
      name: item.Name,
      label: item.Name,
      value: item.Value,
      values: item.Values,
      required: item.required,
      id: item.IdYourviews,
      onChange: handleChange
    }

    return {
      [ItemValueTypeEnum.Boolean]: <Boolean {...componentProps} />,
      [ItemValueTypeEnum.MultiSelect]: <MultiSelect {...componentProps} />,
      [ItemValueTypeEnum.RichText]: <RichText {...componentProps} />,
      [ItemValueTypeEnum.SimpleText]: <SimpleText {...componentProps} />,
      [ItemValueTypeEnum.SingleSelect]: <SingleSelect {...componentProps} />
    }[item.ItemValueType]
  }

  return (
    <Container>
      <Header>
        <FlexSpaceBetween>
          <FlexItem>
            <ModalTitle>
              {t('integrations.modal.title', {
                title: infos?.title
              })}
            </ModalTitle>
            <ModalSubtitle margin={'0 20px 0 0'}>
              {t('integrations.modal.subtitle', {
                title: infos?.title
              })}
            </ModalSubtitle>
          </FlexItem>

          <FlexItem>
            <Switch
              color="primary"
              name="active-integration"
              checked={switchState}
              onChange={handleSwitch}
            />
          </FlexItem>
        </FlexSpaceBetween>
      </Header>

      <FormContainer>
        {data &&
          data.map((item, index) => (
            <FormField key={index}>{FormBuilder(item)}</FormField>
          ))}
      </FormContainer>

      <Status>
        {loading && <CircularProgress />}

        {status && status.message && (
          <StatusMessage type={status.type}>{status.message}</StatusMessage>
        )}
      </Status>

      <Actions>
        <FlexSpaceBetween>
          <FlexItem>
            <Button onClick={onCancel} color="primary">
              {t('uiActions.cancel')}
            </Button>
          </FlexItem>
          <FlexItem>
            <Button
              onClick={() => submitForm(SendFormType.Test)}
              data-testid="dinamic-form-test-btn"
              color="primary"
              autoFocus
            >
              {t('uiActions.test')}
            </Button>
            <Button
              onClick={() => submitForm(SendFormType.Save)}
              data-testid="dinamic-form-save-btn"
              color="primary"
              autoFocus
            >
              {t('uiActions.save')}
            </Button>
          </FlexItem>
        </FlexSpaceBetween>
      </Actions>
    </Container>
  )
}

const { func, any, array } = PropTypes

Form.propTypes = {
  data: array,
  onSwitch: func,
  onSave: func,
  onCancel: func,
  children: any
}

Form.defaultProps = {
  onSave: () => {},
  onCancel: () => {}
}

export default withTranslation()(Form)
