import styled from 'styled-components'

export const Container = styled.div``

export const SaveButton = styled.div`
  color: #fff;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
  background-color: #00aeef;
  width: 103px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  ${({ largeSize }) =>
    largeSize
      ? `
  width:300px;
  line-height: 56px;   
    margin: 0 auto;
    border-radius: 36px;
    height: 56px;
  
  `
      : ``}

  ${({ medium }) =>
    medium
      ? `
  width: 90px;
  height: 37px;
  border-radius: 5px;
  font-size: 14px;
  `
      : ``}
`
export const SimpleButton = styled.div`
  background-color: #e3f6fd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 5px;
  width: 154px;
  border-radius: 2px;
  margin: auto;
  color: #00aeef;
  cursor: pointer;
`
export const RequiredText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 15px 0;
`
export const Label = styled.h2`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  font-size: 16px;

  padding: ${({ padding }) => padding ?? 0};

  ${({ radio }) =>
    radio
      ? `
  line-height:42px;
  font-size:14px;
`
      : ``};
`
export const InputIcon = styled.div`
  padding-right: 10px;
  padding-top: 5px;
`
export const BoxGroup = styled.div`
  width: 634px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  display: flex;
  margin: 0 auto;
  margin-bottom: 13px;
`

export const Text = styled.label`
  font-size: 16px;
`
export const LastCheckText = styled.label`
  font-size: 15px;
`
export const SafeArea = styled.div`
  float: left;
  ${({ center }) =>
    center
      ? `
    float: inherit;
`
      : ``}
  ${({ box }) =>
    box
      ? `
        box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.11),
    0px 8px 10px 1px rgba(0, 0, 0, 0.13), 0px 5px 5px -3px rgba(0, 0, 0, 0.02);
  `
      : ``}
`
