/**
 * Generate a link and click for download item programatically
 *
 * @param {string} URL URL string for download item
 */
const download = (URL) => {
  var link = document.createElement('a')
  link.href = URL

  link.click()
}

export default download
