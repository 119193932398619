import { withTranslation } from 'react-i18next'

import Avatar from '@material-ui/core/Avatar'

import { nps } from 'core/helpers'
import { useCallback, useMemo } from 'react'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

const NpsIcon = ({ type, rating, i18n }) => {
  const roundNumber = useCallback(
    (number, decimals) => {
      if (!isNaN(number)) {
        if (Number.isFinite(number)) {
          return intlFloatPoint(number.toFixed(decimals), i18n.language)
        } else {
          return Math.round(number)
        }
      }
    },
    [i18n.language]
  )

  const currentType = useMemo(() => {
    if (type === NPS_CSAT_TAB.csat.typeValue) {
      if (rating < 3) return '1'

      if (rating >= 3 && rating < 4) return '2'

      if (rating >= 4) return '3'
    } else {
      if (rating < 7) return '1'

      if (rating < 9) return '2'

      if (rating > 8) return '3'
    }
  }, [rating, type])

  const color = useMemo(() => {
    return nps.getLightColor(
      currentType === '3',
      currentType === '2',
      currentType === '1'
    )
  }, [currentType])

  const txtColor = useMemo(() => {
    return nps.getDarkColor(
      currentType === '3',
      currentType === '2',
      currentType === '1'
    )
  }, [currentType])

  return (
    <Avatar
      style={{
        backgroundColor: color,
        color: txtColor
      }}
    >
      {roundNumber(rating, type === NPS_CSAT_TAB.csat.typeValue ? 1 : 0)}
    </Avatar>
  )
}

export default withTranslation()(NpsIcon)
