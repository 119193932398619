import { useEffect } from 'react'

import { Fragment } from 'react'

import { withTranslation } from 'react-i18next'

import { useAuthContext } from '../../../Authentication'

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'

import { useAppContext } from 'App'

import Items from '../../dictionaries/Items'

import MenuListItem from '../MenuListItem'

import useStyles from './style'

const Menu = ({ closeMenu, t }) => {
  const { showCsat, showNewNpsReport } = useAppContext()
  const { user } = useAuthContext()
  const classes = useStyles()

  useEffect(() => {
    const indexOfReports = Items.findIndex((item) => item.id === 'reports')
    const reportChildrens = Items[indexOfReports].childrens

    if (!showCsat) {
      const indexOfCsatItem = reportChildrens.findIndex(
        (item) => item.id === 'reports/stars'
      )

      delete Items[indexOfReports].childrens[indexOfCsatItem]
    }

    if (!showNewNpsReport) {
      const indexOfNewNpsReport = reportChildrens.findIndex(
        (item) => item.id === 'reports/nps'
      )

      delete Items[indexOfReports].childrens[indexOfNewNpsReport]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <List
      component="nav"
      className={classes.root}
      aria-labelledby="nested-list-subheader"
    >
      {Items.map(
        (item, index) =>
          item.permission.some((id) => user?.Role === id) &&
          (item.childrens ? (
            <Fragment key={item.id + '-' + index}>
              <Divider />

              <List
                subheader={
                  closeMenu ? (
                    <ListSubheader>{t(item.title)}</ListSubheader>
                  ) : (
                    false
                  )
                }
                key={'list-' + item.path}
                component="div"
                disablePadding
              >
                {item.childrens.map(
                  (child, index) =>
                    child.permission.some((id) => user?.Role === id) &&
                    !child.hiddenButton && (
                      <MenuListItem
                        key={item.id + '_-_' + index}
                        item={child}
                        type={'item'}
                        closedMenu={closeMenu}
                      />
                    )
                )}
              </List>
            </Fragment>
          ) : (
            <MenuListItem
              key={item.id + '_-_' + index}
              item={item}
              index={index}
              type={'item'}
              closedMenu={closeMenu}
            />
          ))
      )}
    </List>
  )
}

export default withTranslation()(Menu)
