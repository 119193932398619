import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#00AEEF',
    padding: 'auto 0px'
  },
  label: {
    margin: 'auto 8px',
    fontWeight: 'bold'
  },
  icon: {
    margin: 'auto 0px'
  }
})
