import { Component } from 'react'

import { withTranslation } from 'react-i18next'

import { Formik, Form, Field } from 'formik'

import { text } from 'core/helpers/index'
import Yup from 'core/validations/index'

import TextField from '../../../../components/Form/Text/TextField'
import Checkbox from '../../../../components/Form/Box/Checkbox'
import DropdownSearch from '../../../../components/Form/Option/DropdownSearch'

import { FaPlus, FaMinus, FaCheck } from 'react-icons/fa'
class SpreadsheetPreview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdding: false
    }
  }

  componentDidMount() {
    let currentDimension = this.getCurrentDimension()

    this.props.setColumn(
      currentDimension,
      !(currentDimension.name && currentDimension.name.length)
    )
  }

  getDimensions = () => this.getExtraDimensions().concat(this.props.dimensions)

  render() {
    const fieldsValidation = Yup.object().shape({
      add: Yup.string().min(3).max(128).required(),
      notImport: Yup.bool()
    })

    const initialValies = {
      add: '',
      notImport: !(
        this.getCurrentDimension().name &&
        this.getCurrentDimension().name.length
      )
    }

    return (
      <section className="spreadsheet-preview">
        <div className="preview-header white-form">
          <Formik
            enableReinitialize
            validationSchema={fieldsValidation}
            validateOnBlur={false}
            validate={({ notImport }) =>
              this.props.setColumn(this.getCurrentDimension(), notImport)
            }
            onSubmit={(values) => {
              this.addDimension(values)
            }}
            initialValues={initialValies}
          >
            {(props) => {
              return (
                <Form>
                  <div className="preview-header-dimension">
                    <div className="preview-header-dimension-set">
                      <DropdownSearch
                        items={this.getDimensions()}
                        blockTrash
                        value={this.getCurrentDimension()}
                        onSelected={(item) =>
                          this.props.setColumn(item, props.values.notImport)
                        }
                      />

                      <button
                        className="btn preview-add-dimension"
                        type="button"
                        onClick={() =>
                          this.setState({
                            isAdding: !this.state.isAdding
                          })
                        }
                      >
                        {this.state.isAdding ? <FaMinus /> : <FaPlus />}
                      </button>
                    </div>

                    {this.state.isAdding && (
                      <div className="preview-header-dimension-new">
                        <Field
                          name="add"
                          component={TextField}
                          placeholder={this.props.t(
                            'import.link.preview.newDimension'
                          )}
                        />

                        <button
                          className="btn preview-send-dimension"
                          type="submit"
                        >
                          <FaCheck />
                        </button>
                      </div>
                    )}

                    <div className="preview-header-import">
                      {['name', 'email', 'tel'].indexOf(
                        this.getCurrentDimension().id
                      ) < 0 && (
                        <Field
                          name="notImport"
                          label={this.props.t('import.link.preview.notImport')}
                          component={Checkbox}
                        />
                      )}
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>

        <div className="preview-body">
          <div className="preview-body-title">{this.props.data.Header}</div>
          <ul>
            {this.props.data.Values.map((item, index) => (
              <li className="preview-list-item" key={index}>
                {text.maxSize(item, 30)}
              </li>
            ))}
          </ul>
        </div>
      </section>
    )
  }

  getCurrentDimension = () => {
    if (this.props.data.TypeSuggestion === 3 && !this.props.data.Added)
      return {
        id: '',
        name: ''
      }

    if (this.props.data.Added) return this.props.data.Added

    if (this.props.data.TypeSuggestion === 4)
      return {
        id: this.props.data.IdDimension,
        name: this.props.data.TypeSuggestionName
      }

    return this.getExtraDimensions()[this.props.data.TypeSuggestion]
  }

  getExtraDimensions = () => {
    return [
      {
        id: 'email',
        name: this.props.t('import.link.preview.email')
      },
      {
        id: 'name',
        name: this.props.t('import.link.preview.name')
      },
      {
        id: 'tel',
        name: this.props.t('import.link.preview.tel')
      }
    ]
  }

  addDimension = (values) => {
    this.props.addDimension(values.add)
    this.setState({
      isAdding: false
    })
  }
}

export default withTranslation()(SpreadsheetPreview)
