import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { read, create } from 'localstorage-helpr'
import { useSnackbar } from 'notistack'

import { useTranslation, withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { useAuthContext } from 'modules/Authentication'
import { CampaignProvider } from 'modules/Campaign/components/Details/context'

import ReactGA from 'react-ga'
import Analytics from 'react-router-ga'

import GoogleAnalytics from 'core/constants/google-analytics'
import useInitHotjar from 'core/hooks/useInitHotjar'
import useUserPilot from 'core/hooks/useUserPilot'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import AppDrawer from 'modules/AppDrawer'
import Router from 'router'

import { BASE_PATH } from 'router'
import { LANG, MENU } from 'core/constants/local-storage'

import { Grow } from '@material-ui/core'
import * as locales from '@material-ui/core/locale'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import { MatrixProvider } from 'modules/Reports/Nps/contex'

import axios, { checkForUrl } from 'core/api'
import { remove } from 'localstorage-helpr'
import { STORE, TOKEN, USER } from 'core/constants/local-storage'
import useEventAnalytic from 'core/hooks/useEventAnalytic'
import { HORUS_LOGOUT_URL } from 'core/constants/horus'
import PublicRoutes from 'core/dictionaries/public-routes'

const SnackbarOptions = {
  anchor: {
    vertical: 'top',
    horizontal: 'right'
  },
  max: 2
}

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { search } = useLocation()

  const isString = (value) => typeof value === 'string'

  const notify = (message, variant) => {
    enqueueSnackbar(isString(message) ? message : t('app.error.generic'), {
      variant,
      preventDuplicate: true
    })
  }

  const [boxListCurrentItem, setBoxListCurrentItem] = useState(read(LANG))
  const handleSetBoxListCurrentItem = useCallback((newValue) => {
    setBoxListCurrentItem(newValue)
  }, [])

  const [currentLang, setCurrentLang] = useState(read(LANG))
  const handleSetCurrentLang = useCallback((newValue) => {
    setCurrentLang(newValue)
    create(LANG, newValue)
  }, [])

  const [menuState, setMenuState] = useState(read(LANG))
  const handleSetMenuState = useCallback((newValue) => {
    setMenuState(newValue)
    create(MENU, newValue)
  }, [])

  const [queryString, setQueryString] = useState('')
  const handleSetQueryString = useCallback((newValue) => {
    setQueryString(newValue)
  }, [])

  const [showCsat] = useState(true)
  const [showNewNpsReport] = useState(true)

  useEffect(() => {
    handleSetCurrentLang(read(LANG))
    handleSetMenuState(read(MENU))
    handleSetQueryString(search)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AppContext.Provider
      value={{
        notify,

        currentLang,
        handleSetCurrentLang,

        menuState,
        handleSetMenuState,

        queryString,
        handleSetQueryString,

        boxListCurrentItem,
        handleSetBoxListCurrentItem,

        showCsat,
        showNewNpsReport
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  const {
    notify,

    currentLang,
    handleSetCurrentLang,

    menuState,
    handleSetMenuState,

    queryString,
    handleSetQueryString,

    boxListCurrentItem,
    handleSetBoxListCurrentItem,

    showCsat,
    showNewNpsReport
  } = context

  return {
    notify,

    currentLang,
    handleSetCurrentLang,

    menuState,
    handleSetMenuState,

    queryString,
    handleSetQueryString,

    boxListCurrentItem,
    handleSetBoxListCurrentItem,

    showCsat,
    showNewNpsReport
  }
}

const App = () => {
  const history = useHistory()
  const location = useLocation()

  const { hasAuth, user, store } = useAuthContext()

  const { ID } = GoogleAnalytics

  useEffect(() => {
    if (process.env.REACT_APP_STAGE !== 'qa' && user && store) {
      ReactGA.set({
        dimension1: store.Key,
        dimension2: user.IdYourviews,
        dimension3: user.Email
      })
    }

    let pathname = BASE_PATH
    let search = ''
    if (location.pathname !== BASE_PATH || location.pathname !== '/') {
      pathname = location.pathname
      search = location.search
    }

    if (hasAuth) {
      history.push({
        pathname,
        search
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.pathname === '/') history.push(BASE_PATH)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useInitHotjar()
  useUserPilot()

  const currentLang = {
    pt: locales.ptBR,
    en: locales.enUS,
    es: locales.esES
  }[read('i18nextLng')]

  const theme = useMemo(() => {
    return createTheme(
      {
        palette: {
          primary: {
            main: '#00aeef',
            light: '#cceffc',
            dark: '#0084e4',
            contrastText: '#fff'
          }
        }
      },
      currentLang
    )
  }, [currentLang])

  const renderAnalyticsCondition = () => {
    let children = (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router />
      </MuiPickersUtilsProvider>
    )

    if (process.env.REACT_APP_STAGE !== 'qa') {
      children = (
        <Analytics id={ID} trackPathnameOnly>
          {children}
        </Analytics>
      )
    }
    return children
  }

  const MINUTE_MS = 60000 * 5 //5 minutes
  const { track } = useEventAnalytic()
  const { Actions, Category } = GoogleAnalytics

  const checkHorusAuth = () => {
    axios
      .get('Horus/CheckHorusAuth')
      .then((res) => {
        if (!res.data.IsValid) {
          remove(USER)
          remove(STORE)
          remove(TOKEN)
          track(Actions.Logout, Category.Interaction)
          window.location.href = HORUS_LOGOUT_URL
        }
      })
      .catch((error) => {
        console.error(error.message)
      })
  }

  useEffect(() => {
    if (checkForUrl(window.location.href, PublicRoutes)) return
    checkHorusAuth()
    const interval = setInterval(() => checkHorusAuth, MINUTE_MS)
    return () => clearInterval(interval)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={SnackbarOptions.max}
        TransitionComponent={Grow}
        anchorOrigin={SnackbarOptions.anchor}
      >
        <AppProvider>
          <CampaignProvider>
            <MatrixProvider>
              <AppDrawer>{renderAnalyticsCondition()}</AppDrawer>
            </MatrixProvider>
          </CampaignProvider>
        </AppProvider>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default withTranslation()(App)
