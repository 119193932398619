import styled, { keyframes } from 'styled-components'

import { withStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 581px;
  height: 392px;
  min-height: 392px;
  max-height: 392px;

  padding: 24px;

  ${({ noBorder, error }) =>
    noBorder
      ? ''
      : `
        background: #FFFFFF;
        border: 1px dashed ${error ? '#EF3E39' : '#00AEEF'};
        border-radius: 8px;
      `}
  ${({ isDragActive }) =>
    isDragActive
      ? `
        background-color: rgba(0, 174, 239, 0.11);
      `
      : ''}
`
export const Title = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  max-width: 300px;
  color: #001e2e;

  margin-top: 28px;
  margin-bottom: 4px;
`
export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  ${({ error }) => (error ? 'text-align: start;' : 'text-align: center;')}
  ${({ error }) => (error ? 'color: #EF3E39;' : 'color: rgba(0, 0, 0, 0.54);')}

  max-width: 368px;
  margin-top: 4px;
  margin-bottom: 24px;
`

export const ImageBox = styled.div`
  display: flex;
  min-height: 150px;
  align-items: center;
  align-content: center;
`

export const Image = styled.img`
  align-self: center;
  align-items: center;
  align-content: center;

  margin: auto;

  ${({ cloud }) => (cloud ? 'position: relative;' : '')}
  ${({ success }) => (success ? 'position: absolute; margin: auto 54px;' : '')}
`

const bounceAnimation = keyframes`
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-30px); }
  100% { transform: translateY(0); }
`

export const Arrow = styled.img`
  position: absolute;
  margin: auto 54px;

  animation-name: ${bounceAnimation};
  animation-timing-function: linear;

  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
`

export const UploadLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-top: 32px;
`
export const ProgressLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);

  margin-top: 4px;
  margin-bottom: 32px;
`

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
    width: 360,
    margin: 20
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#00AEEF'
  }
}))(LinearProgress)
