import './index.css'
import { withTranslation } from 'react-i18next'
import { InfoOutlined } from '@material-ui/icons'

const InfoText = ({ text }) => {
  return (
    <div className="info-text-wrapper">
      <InfoOutlined className="info-text-icon" />
      <div className="info-text-text">{text}</div>
    </div>
  )
}

export default withTranslation()(InfoText)
