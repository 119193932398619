import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '350px',
    width: '100%',
    minwidth: '250px',
    height: 48,
    color: '#000',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)'
  },
  text: {
    color: '#000',
    lineHeight: '64px',
    width: '100%',
    minWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    color: '#000',
    lineHeight: '55px'
  }
}))
export default useStyle
