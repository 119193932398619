const { makeStyles } = require('@material-ui/core')

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 20
  },
  whiteTextLabel: {
    color: '#fff'
  }
}))

export default useStyles
