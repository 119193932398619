import PropTypes from 'prop-types'

import { Container, Image, Title, Subtitle } from './style'

import BigButton from '../../../../components/BigButton'

import AddIcon from '@material-ui/icons/Add'

import emptyCampaign from '../../../../assets/img/empty.svg'
import { withTranslation } from 'react-i18next'

function Empty({ type, onClick, t }) {
  return (
    <Container>
      <Image src={emptyCampaign} />

      <Title>{t(`campaign.empty.${type}.title`)}</Title>

      <Subtitle>{t(`campaign.empty.${type}.subtitle`)}</Subtitle>

      {type === 'all' && (
        <BigButton
          icon={<AddIcon />}
          onClick={onClick}
          title={t('campaign.newCampaing')}
          data-testid="add-new-campaign-btn-empty-state"
        />
      )}
    </Container>
  )
}

const { oneOf, func } = PropTypes

Empty.propTypes = {
  type: oneOf(['active', 'inactive', 'all']).isRequired,
  onClick: func
}

export default withTranslation()(Empty)
