import { withTranslation } from 'react-i18next'

import DimensionTypeEnum from '../../enums/dimension-type.enum'
import chartMapper from '../../functions/chartMapper'

import { DimensionValueChart } from '../DimensionValueChart'
import { DimensionValueTable } from '../DimensionValueTable'
import { AverageCard } from '../AverageCard'
import { RatingTable } from '../RatingTable'

import Grid from '@material-ui/core/Grid'

import useStyles from '../styles/DimensionChart.style'

import 'style/route/reports/dimension/main.css'

const DimensionChart = ({ data, t, margin }) => {
  const classes = useStyles()

  const header = {
    dimension: t('dimension.chartDimensionTitle'),
    value: t('dimension.chartDimensionValue')
  }
  const chart = chartMapper(data, header)
  const top = margin?.top
  const bottom = margin?.bottom
  return (
    <div
      className={classes.root}
      style={{ marginTop: top, marginBottom: bottom }}
    >
      <Grid container alignItems="center" justifyContent="center">
        {data?.DimensionType === DimensionTypeEnum['Rating'] && (
          <AverageCard classes={classes} data={data} t={t} />
        )}

        {chart && !chart.isAllZero && (
          <DimensionValueChart data={chart.data} t={t} />
        )}

        {(data?.DimensionType === DimensionTypeEnum['SingleSelect'] ||
          data?.DimensionType === DimensionTypeEnum['MultiSelect']) &&
          !chart.isAllZero && (
            <DimensionValueTable classes={classes} t={t} data={data} />
          )}

        {data?.DimensionType === DimensionTypeEnum['Rating'] &&
          !chart.isAllZero && (
            <RatingTable classes={classes} t={t} data={data} />
          )}
      </Grid>
    </div>
  )
}

export default withTranslation()(DimensionChart)
