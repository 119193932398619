import { withTranslation } from 'react-i18next'

import { Picture, Subtitle, Title } from './style'

import notFound from '../../assets/img/404.svg'

import '../../style/route/not-found/main.css'

const NotFound = ({ t }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    }}
  >
    <Picture src={notFound} alt="Not Found" />

    <Title>{t('notFound.title')}</Title>

    <Subtitle>{t('notFound.subtitle')}</Subtitle>
  </div>
)

export default withTranslation()(NotFound)
