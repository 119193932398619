import { withTranslation } from 'react-i18next'

import { Formik, Form, Field } from 'formik'
import Button from '@material-ui/core/Button'

import Yup from 'core/validations/index'
import TextField from '../../../../components/Form/Text/TextField'

import Modal from '../../../../components/Modal/Modal'

const TemplateNewCopy = ({ templateName, isNaming, setIsNaming, copy, t }) => {
  const initialValues = { copyName: templateName + ' - Copy' }

  const fieldsValidation = Yup.object().shape({
    copyName: Yup.string().required()
  })

  return (
    <Modal show={isNaming} onClose={() => setIsNaming(false)}>
      <Formik
        initialValues={initialValues}
        validationSchema={fieldsValidation}
        onSubmit={(values, { setSubmitting }) => copy(values, setSubmitting)}
      >
        {(propsForm) => {
          return (
            <Form>
              <Field
                name="copyName"
                label={t('template.item.copyName.field')}
                component={TextField}
                placeholder={t('template.item.copyName.desc')}
              />

              <Button
                variant="contained"
                className="btn-block"
                type="submit"
                disabled={propsForm.isSubmitting}
              >
                {t('template.item.copyName.createBtn')}
              </Button>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default withTranslation()(TemplateNewCopy)
