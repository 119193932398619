/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import { QS_IMPORT_SESSION } from 'core/constants/query-strings'

import {
  Button,
  CircularProgress,
  Drawer,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { ArrowDropDown, ArrowRight, HelpRounded } from '@material-ui/icons'

import Close from '@material-ui/icons/Close'
import ArrowForward from '@material-ui/icons/ArrowForward'

import useStyles, {
  Container,
  FormHeader,
  FormTitle,
  Box,
  FileBox,
  FileIcon,
  FileName,
  FileInfo,
  Step,
  FormField,
  ImportTable,
  ImportTableHeader,
  ImportTableTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ImportLabel,
  ListActions,
  ListItemContainer
} from '../../style'

import { Position } from '../../../Email/style'

import excel from 'assets/img/excel.svg'
import useGetData from 'core/hooks/useGetData'
import DimensionAutocomplete from '../Autocomplete'

import { Alert, Skeleton } from '@material-ui/lab'

import { Controller, useForm } from 'react-hook-form'
import Helper from '../Helper'
import postData from 'core/functions/post-data.function'
import SuccessDialog from '../SuccessDialog'

import Dialog from '@material-ui/core/Dialog'
import { usePeopleList } from '../../context'
import eventBus from 'core/events'
import { useAppContext } from 'App'
import { ImportFileEvent } from 'modules/Campaign/components/PeopleList'

const DefaultTypeMap = (key) => {
  switch (key) {
    case 'name':
      return 2
    case 'email':
      return 3
    case 'tel':
      return 4
    default:
      return 9
  }
}

const TypeSuggestionSwitchMap = {
  0: true,
  1: true,
  2: true,
  3: false,
  4: true
}

function Form({ data, file, t }) {
  const classes = useStyles()

  const { setDisableNext } = usePeopleList()
  const { notify } = useAppContext()

  const [openHelp, setOpenHelp] = useState(false)
  const handleOpenHelp = () => {
    setOpenHelp(!openHelp)
  }

  const FileIconMap = {
    xlsx: excel,
    csv: excel
  }

  const { ProcessFileResult, FileExtension } = data || {}

  const [expanded, setExpanded] = useState({})

  const handleChange = (newExpanded) => {
    setExpanded({ ...expanded, [newExpanded]: !expanded[newExpanded] })
  }

  const [dimensions, setDimensions] = useState([])
  const [loading, setLoading] = useState(false)

  useGetData({
    endpoint: 'dimension/getallplain',
    setData: (data) => {
      setDimensions([
        { Name: 'Nome', Key: 'name', DefaultType: true, id: 'name' },
        { Name: 'E-mail', Key: 'email', DefaultType: true, id: 'email' },
        { Name: 'Telefone', Key: 'tel', DefaultType: true, id: 'tel' },
        ...data
      ])
    }
  })

  const mapSwitchsStatus = (list = []) => {
    let items = {}

    for (let item of list) {
      items = {
        ...items,
        [item.Index]: TypeSuggestionSwitchMap[item.TypeSuggestion]
      }
    }

    return items
  }

  const [switchs, setSwitchs] = useState(
    mapSwitchsStatus(ProcessFileResult?.Preview)
  )
  const handleSetSwitchs = useCallback(({ target: { name, checked } }) => {
    setSwitchs((prevState) => ({ ...prevState, [name]: checked }))
  }, [])

  const [selectedDimensions, setSelectedDimensions] = useState([])
  const handleChangeSelectedDimension = useCallback((newDimensions) => {
    setSelectedDimensions(Object.values(newDimensions))
  }, [])

  const [listName, setListName] = useState('')
  const handleChangeListName = useCallback(({ target }) => {
    setListName(target.value)
    if (target.value) setDisableNext(false)
    else setDisableNext(true)
  }, [])

  const { control, watch } = useForm()

  const [nameField, setNameField] = useState(true)
  const [emailField, setEmailField] = useState(true)

  useEffect(() => {
    const subscription = watch((values) => {
      handleChangeSelectedDimension(values)
    })

    return () => subscription.unsubscribe()
  }, [watch, switchs])

  const hasField = (list, key) => !!list.find((x) => x?.Key === key)
  useEffect(() => {
    if (selectedDimensions.length > 0) {
      setNameField(hasField(selectedDimensions, 'name'))
      setEmailField(hasField(selectedDimensions, 'email'))
    }
  }, [selectedDimensions])

  const importData = (switchs, dimensions) => {
    let aux = 0

    /*
     * Se a index do primeiro item for igual a zero, a variavel auxiliar é
     * igual a 1 para que as Index sejam enviadas corretamente
     */
    if (dimensions[0]?.Index === 0) aux = 1

    const config = dimensions.map((dimension, index) => ({
      Column: (dimension?.Index ?? index) + aux,
      Ignore: !switchs[dimension?.Index ?? index],
      DefaultType: dimension?.DefaultType ?? false,
      Type: DefaultTypeMap(dimension?.Key ?? null),
      IdDimension: dimension?.IdYourviews ?? null
    }))

    postData({
      endpoint: `person/executeimportsession?${QS_IMPORT_SESSION}=${data.SessionKey}`,
      data: {
        Configurations: Object.values(config),
        TargetList: { Name: listName, Active: true }
      },
      setData: (response) => {
        const {
          HasMajorErrors,
          ProcessingLog,
          ErrorRowsCount,
          RowCount,
          Items
        } = response
        const processed = Items?.length || ErrorRowsCount < RowCount

        // There are processed items and not a huge error
        if (!HasMajorErrors && processed) {
          setShowModal(true)
        } else {
          for (let log of ProcessingLog) {
            if (log.Type === 2 || log.Type === 3) notify(log.Message, 'error')
          }
        }
      },
      setLoading: setLoading,
      notify: (error) => notify(error, 'error')
    })
  }

  const [showModal, setShowModal] = useState(false)
  const handleClose = () => setShowModal(false)

  const eventBusCallback = () => {
    importData(switchs, selectedDimensions)
  }
  useEffect(() => {
    if (!selectedDimensions.length) return
    eventBus.remove(ImportFileEvent, eventBusCallback)
    eventBus.create(ImportFileEvent, eventBusCallback)
    return () => {
      eventBus.remove(ImportFileEvent, eventBusCallback)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimensions, listName])

  var listNames = []
  const extractName = (lists) => {
    lists.map((list, index) => (listNames[index] = list?.Name))

    return listNames
  }

  let filtred

  const hasDuplicate = (list, key, value) => {
    filtred = list.filter((item) => item !== undefined && item === value)
    return filtred.length >= 2
  }

  return (
    <Container>
      <FormHeader>
        <FormTitle>{t('campaign.peopleList.form.title')}</FormTitle>

        <Button
          variant="text"
          color="primary"
          onClick={handleOpenHelp}
          startIcon={<HelpRounded />}
        >
          {t('campaign.peopleList.form.help.title')}
        </Button>
      </FormHeader>

      <Box justifyStart>
        <FileBox>
          <FileIcon src={FileIconMap[FileExtension?.replace('.', '')]} />
          <div style={{ width: '90%', textAlign: 'left' }}>
            <FileName>{file?.name}</FileName>
            <FileInfo>
              {ProcessFileResult?.RowCount ?? '?'}{' '}
              {t('campaign.peopleList.form.foundLines')}
            </FileInfo>
          </div>
        </FileBox>
      </Box>

      <Box>
        <Step style={{ display: 'flex', flexDirection: 'row' }}>
          <Position active>1</Position>
          {t('campaign.peopleList.form.steps.1')}
        </Step>

        <FormField>
          <TextField
            fullWidth
            required
            size="small"
            value={listName}
            variant="outlined"
            style={{ minWidth: 500 }}
            inputProps={{
              'data-testid': 'list-name'
            }}
            onChange={handleChangeListName}
            label={t('campaign.peopleList.form.listName')}
          />
        </FormField>

        <Step style={{ display: 'flex', flexDirection: 'row' }}>
          <Position active>2</Position>
          {t('campaign.peopleList.form.steps.2')}
        </Step>

        {!emailField && (
          <div style={{ margin: '8px 0px' }}>
            <Alert variant="outlined" severity="error">
              {t('campaing.edit.form.textErroEmail')}
            </Alert>
          </div>
        )}

        {!nameField && (
          <div style={{ margin: '8px 0px' }}>
            <Alert variant="outlined" severity="error">
              {t('campaing.edit.form.textErroName')}
            </Alert>
          </div>
        )}
      </Box>

      <Box>
        <ImportTable>
          <ImportTableHeader>
            <ImportTableTitle>
              {t('campaign.peopleList.form.table.columns')}
            </ImportTableTitle>

            <ImportTableTitle>
              {t('campaign.peopleList.form.table.dimensions')}
            </ImportTableTitle>
          </ImportTableHeader>

          <div role="list">
            {!dimensions?.length &&
              [1, 2, 3].map((x) => (
                <Typography
                  data-testid="people-list-item-skeleton"
                  key={x}
                  variant="h1"
                >
                  <Skeleton />
                </Typography>
              ))}

            {!!dimensions?.length &&
              ProcessFileResult?.Preview?.map((item, index) => (
                <ListItemContainer
                  data-testid="people-list-item"
                  key={item.Index}
                >
                  <Accordion
                    square
                    className={classes.accordion}
                    onChange={() => handleChange(item.Index)}
                    style={{
                      borderTop:
                        index === 0 ? '1px solid rgba(0, 0, 0, .125)' : ''
                    }}
                  >
                    <AccordionSummary
                      aria-controls="import-people-list"
                      data-testid="import-people-list"
                      id="import-people-list"
                      classes={{
                        content: classes.accSummaryContent
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row'
                        }}
                      >
                        {expanded[item?.Index] ? (
                          <ArrowDropDown className={classes.expandIcon} />
                        ) : (
                          <ArrowRight className={classes.expandIcon} />
                        )}
                        <span style={{ margin: 'auto 8px' }}>
                          <Typography>{item?.Header}</Typography>
                        </span>
                      </div>

                      <ListActions>
                        <Switch
                          color="primary"
                          name={item?.Index?.toString()}
                          data-testid="switch-import-row"
                          checked={switchs[item?.Index]}
                          onClick={(event) => {
                            event.stopPropagation()
                            handleSetSwitchs(event)
                          }}
                        />

                        <ImportLabel>
                          {t('campaign.peopleList.import')}
                        </ImportLabel>

                        <ArrowForward
                          style={{
                            margin: 'auto 0px',
                            color: 'rgba(0, 0, 0, 0.54)'
                          }}
                        />
                      </ListActions>
                    </AccordionSummary>

                    <AccordionDetails>
                      <List>
                        {item?.Values?.map((preview, index) => (
                          <ListItem key={index}>
                            <ListItemText primary={preview} />
                          </ListItem>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>

                  <FormField>
                    <Controller
                      control={control}
                      name={`${item?.Index}`}
                      render={({ field }) => (
                        <DimensionAutocomplete
                          {...field}
                          item={item}
                          ref={field?.ref}
                          index={item?.Index}
                          disabled={!switchs[item?.Index]}
                          duplicate={hasDuplicate(
                            extractName(selectedDimensions),
                            'Name',
                            field?.value?.Name
                          )}
                          dimensions={dimensions}
                          updateDimensionList={setDimensions}
                        />
                      )}
                    />
                  </FormField>
                </ListItemContainer>
              ))}
          </div>
        </ImportTable>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            padding: '20px 0px'
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Fab
              variant="extended"
              color="primary"
              disabled={!listName}
              style={{ cursor: 'pointer' }}
              data-testid="send-button"
              onClick={() => importData(switchs, selectedDimensions)}
            >
              Avançar
            </Fab>
          )}
        </div>
      </Box>

      <Drawer open={openHelp} anchor="right" onClose={handleOpenHelp}>
        <div style={{ width: 300, padding: '4px 20px' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={handleOpenHelp}>
              <Close />
            </IconButton>
          </div>

          <Helper />
        </div>
      </Drawer>

      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <SuccessDialog fileName={file?.name} close={handleChange} />
      </Dialog>
    </Container>
  )
}

export default withTranslation()(Form)
