import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto 0px'
  },
  divSelector: {
    borderLeft: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0'
  },
  iconSelector: {
    width: '70px',
    margin: '0 auto',
    display: 'block'
  },
  icon: {
    color: '#666'
  },
  flag: {
    width: 20,
    float: 'left',
    margin: 5
  }
}))

export const Flag = styled.img`
  width: 24px;
  height: 24px;
`

export default useStyles
