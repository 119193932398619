import { useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useSnackbar } from 'notistack'

import axios from 'core/api'

import Header from '../../components/Header'
import BigButton from '../../components/BigButton'
import Filters, { FilterItem } from '../AppDrawer/styles/Filter'

import AddIcon from '@material-ui/icons/Add'

import List from './components/List'
import Empty from './components/Empty'
import TabsView from './components/TabsView'

import { Container, Content } from './style'

const notifyOptions = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  },
  variant: 'error'
}

const Campaign = ({ t }) => {
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(true)
  const [miniLoading, setMiniLoading] = useState(false)

  const [active, setActive] = useState(null)
  const [inactive, setInactive] = useState(null)
  const [all, setAll] = useState(null)

  const [tabState, setTabState] = useState('active')

  const getPagination = (tab) => ({ active, inactive, all }[tab]?.MetaData)

  const handleAddNewCampaign = () => history.push('campaign/new')
  const handlePagination = (page, pageSize) =>
    getCampaigns(true, tabState, page, pageSize)

  const TabsMap = { active: true, inactive: false, all: null }

  const query = (tab, page, pageSize) => {
    const activeQS = tab === 'all' ? '' : `Active=${TabsMap[tab] ?? ''}`
    const pageQS = page ? `&Page=${page}` : ''
    const pageSizeQS = pageSize ? `&PageSize=${pageSize}` : ''

    return activeQS + pageQS + pageSizeQS
  }

  const getCampaigns = async (hasLoading, tabState, page, pageSize) => {
    const setStateMap = {
      all: setAll,
      active: setActive,
      inactive: setInactive
    }

    setMiniLoading(true)

    if (hasLoading) setLoading(true)

    try {
      const res = await axios.get(
        '/campaign/getpaged?' + query(tabState, page, pageSize)
      )

      if (res.data) {
        setStateMap[tabState](res.data)
      }
    } catch (error) {
      enqueueSnackbar(t('campaign.notify.getData.error'), notifyOptions)
    } finally {
      setLoading(false)
      setMiniLoading(false)
    }
  }

  useEffect(() => {
    getCampaigns(false, 'all')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!active || !inactive) getCampaigns(true, tabState)

    if (tabState === 'all' && !all) getCampaigns(true, tabState)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabState])

  const handleUpdateItemOnChangeStatus = (tab, item) => {
    if (tab === 'all') {
      const elementIndex = all.Items.findIndex(
        (el) => el.IdYourviews === item.IdYourviews
      )

      let newItemsState = [...all.Items]
      newItemsState[elementIndex] = { ...item }

      setAll({ ...all, Items: newItemsState })
    }
  }

  const handleUpdateActiveStatus = async (item) => {
    const { PageNumber, PageSize } = getPagination(tabState)

    handleUpdateItemOnChangeStatus(tabState, item)

    if (tabState === 'all') {
      await getCampaigns(false, 'active', PageNumber, PageSize)
      await getCampaigns(false, 'inactive', PageNumber, PageSize)

      return
    }

    await getCampaigns(false, tabState, PageNumber, PageSize)

    const switchMap = { active: 'inactive', inactive: 'active' }
    await getCampaigns(false, switchMap[tabState], PageNumber, PageSize)
    await getCampaigns(false, 'all', PageNumber, PageSize)
  }

  const emptyList = (list) => !list || list.length < 1

  return (
    <Container>
      <Header
        title={t('campaign.title')}
        type="actions"
        items={
          <Filters justify="flex-end">
            <FilterItem>
              {!emptyList(all?.Items) && (
                <BigButton
                  icon={<AddIcon />}
                  onClick={handleAddNewCampaign}
                  title={t('campaign.newCampaing')}
                  data-testid="add-new-campaign-btn-header"
                />
              )}
            </FilterItem>
          </Filters>
        }
      />

      <Content>
        {!loading && emptyList(all?.Items) ? (
          <Empty type="all" onClick={handleAddNewCampaign} />
        ) : (
          <TabsView
            loading={loading}
            onChange={setTabState}
            miniLoading={miniLoading}
            all={
              <List
                items={all?.Items}
                pagination={all?.MetaData}
                onChangePagination={handlePagination}
                onUpdateActiveStatus={handleUpdateActiveStatus}
              />
            }
            active={
              emptyList(active?.Items) ? (
                <Empty type="active" />
              ) : (
                <List
                  items={active?.Items}
                  pagination={active?.MetaData}
                  onChangePagination={handlePagination}
                  onUpdateActiveStatus={handleUpdateActiveStatus}
                />
              )
            }
            inactive={
              emptyList(inactive?.Items) ? (
                <Empty type="inactive" />
              ) : (
                <List
                  items={inactive?.Items}
                  pagination={inactive?.MetaData}
                  onChangePagination={handlePagination}
                  onUpdateActiveStatus={handleUpdateActiveStatus}
                />
              )
            }
          />
        )}
      </Content>
    </Container>
  )
}

export default withTranslation()(Campaign)
