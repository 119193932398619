import { ptBR, enUS, es } from 'date-fns/locale'

const DateFormatMap = new Map([
  ['en', { locale: 'en-US', fn: enUS }],
  ['pt', { locale: 'pt-BR', fn: ptBR }],
  ['es', { locale: 'es-ES', fn: es }],
  ['default', { locale: 'pt-BR', fn: ptBR }]
])

export default DateFormatMap
