import queryString from 'query-string'

import { QS_DATE_TO, QS_DATE_FROM, QS_PAGE } from 'core/constants/query-strings'

export const parseDateToQs = (from, to) => {
  //Como não temos seletor de horas, então a data inicial é sempre meia noite (início do dia) e data final é sempre o 23:59 (final do dia)
  if (from) {
    from.setHours(0, 0, 0, 0)
  }

  if (to) {
    to.setHours(23, 59, 59, 59)
  }

  return {
    from: from ? from.toISOString() : null,
    to: to ? to.toISOString() : null
  }
}

/**
 * Set from/to date to queryString for filter data
 *
 * @param {Date} from Start date
 * @param {Date} to Finish date
 * @param {object} location Location object from component (ex props.location)
 * @param {object} history History object from component (ex props.history or history from useHistory hook)
 */
const setDateQs = (from, to, location, history) => {
  let currentQS = queryString.parse(location.search)

  currentQS[QS_DATE_FROM] = parseDateToQs(from, to).from
  currentQS[QS_DATE_TO] = parseDateToQs(from, to).to

  if (!currentQS[QS_DATE_FROM]) delete currentQS[QS_DATE_FROM]
  if (!currentQS[QS_DATE_TO]) delete currentQS[QS_DATE_TO]

  delete currentQS[QS_PAGE]

  history.push({
    pathname: location.pathname,
    search: queryString.stringify(currentQS)
  })
}

export default setDateQs
