import adapter from 'axios/lib/adapters/http'
import axios from 'axios'

import moment from 'moment'
import { create } from 'localstorage-helpr'

import queryString from 'query-string'
import i18n from '../../lang'

import { TOKEN } from '../constants/local-storage'
import { BASE_PATH } from '../../router'
import { HEADER_AUTH, URL_API } from '../constants/api'
import { QS_DATE_FROM, QS_DATE_TO } from '../constants/query-strings'
import {
  APPLY_FULLDATE,
  WITHOUT_TOKEN,
  THROW_ERROR_MANUALLY
} from '../constants/registers'

const request = axios.create({ adapter, baseURL: URL_API })

function token() {
  const token = localStorage.getItem(TOKEN) ?? ''

  if (token.match(/Bearer /)) return token
  else return 'Bearer ' + token
}

function isFullDate(url) {
  return checkForUrl(url, APPLY_FULLDATE)
}

function isUsingToken(url) {
  return !checkForUrl(url, WITHOUT_TOKEN)
}

function canThrow(url) {
  return !checkForUrl(url, THROW_ERROR_MANUALLY)
}

export function checkForUrl(checking, urls) {
  let result = false

  urls.forEach((item) => {
    if (checking.indexOf(item) > -1) result = true
  })

  return result
}

request.interceptors.request.use(function (config) {
  const currentRequest = config.url.split('?')
  const urlSearch = currentRequest.length > 0 ? currentRequest[1] : ''
  let currentQS = queryString.parse(urlSearch)

  if (
    !currentQS[QS_DATE_FROM] &&
    !currentQS[QS_DATE_TO] &&
    isFullDate(config.url)
  ) {
    currentQS[QS_DATE_FROM] = moment().subtract(10, 'years').toDate().toJSON()
    currentQS[QS_DATE_TO] = moment().toDate().toJSON()
  }

  config.url = currentRequest[0] + '?' + queryString.stringify(currentQS)

  if (isUsingToken(config.url)) {
    if (token() === 'Bearer null')
      window.location.href = window.location.origin + BASE_PATH + 'login'
    config.headers[HEADER_AUTH] = token()
  }

  return config
})

request.interceptors.response.use(
  function (response) {
    if (
      response.data.Element &&
      response.data.Element.AuthenticationResult &&
      response.data.Element.AuthenticationResult._jwtToken
    ) {
      create(TOKEN, response.data.Element.AuthenticationResult._jwtToken)
      request.defaults.headers.common[HEADER_AUTH] = token()
    }

    if (response.data._jwtToken) {
      create(TOKEN, response.data._jwtToken)
      request.defaults.headers.common[HEADER_AUTH] = token()
    }

    if (response.data.HasErrors && canThrow(response.config.url))
      throw new Error(response.data.ErrorList[0].Error)

    return response
  },
  function (error) {
    if (error?.message?.indexOf('401') > -1)
      window.location.href = window.location.origin + BASE_PATH + 'login'

    if (error?.message?.indexOf('403') > -1)
      error.message = i18n.t('error.notAllowed')

    throw error
  }
)

export default request
