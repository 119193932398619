import './index.css'
import { useState, useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import DashboardSatisfactionCard from '../DashboardSatisfactionCard'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import { Button } from '@material-ui/core'
import {
  MailOutline,
  QuestionAnswerOutlined,
  ShowChart,
  PowerOutlined
} from '@material-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { BASE_PATH } from 'router'
import DashboardEditGoal from '../DashboardEditGoal'
import queryString from 'query-string'
import { QS_TYPES } from 'core/constants/query-strings'
import { useAuthContext } from 'modules/Authentication'
import { Permissions } from 'modules/AppDrawer/enums/Permissions'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import CustomCard from 'components/CustomCard'

const getTypeObjFromProp = (propName, propValue) => {
  return Object.values(NPS_CSAT_TAB).find((val) => val[propName] === propValue)
}

const DashboardTabs = ({
  decimal,
  npsScore,
  csatScore,
  loading,
  handleNewGoal,
  widgetConversion,
  emailConversion,
  i18n,
  t
}) => {
  const { search } = useLocation()
  const { user } = useAuthContext()
  const { push } = useHistory()

  const currentTabObj = useMemo(() => {
    return (
      getTypeObjFromProp('typeParam', queryString.parse(search)[QS_TYPES]) ||
      NPS_CSAT_TAB.nps
    )
  }, [search])

  const isCsat = useMemo(() => {
    return currentTabObj.typeValue === NPS_CSAT_TAB.csat.typeValue
  }, [currentTabObj.typeValue])

  const handleSeeAnswers = useCallback(() => {
    push({
      pathname: BASE_PATH + 'answer',
      search
    })
  }, [push, search])

  const [showEditGoalModal, setShowEditGoalModal] = useState(false)
  const handleOpenEditGoal = useCallback(() => {
    setShowEditGoalModal(true)
  }, [])
  const handleCloseEditGoal = useCallback(() => {
    setShowEditGoalModal(false)
  }, [])

  // const handleMoreEmailInfo = useCallback(() => {}, [])
  // const handleMoreWidgetInfo = useCallback(() => {}, [])

  return (
    <section className="dashboard-cards-outer-wrapper">
      <section className="dashboard-cards-inner-wrapper">
        <DashboardSatisfactionCard
          decimal={decimal}
          loading={loading}
          cardTitle={
            isCsat
              ? t('dashboard.csat.fullTitle')
              : t('dashboard.nps.fullTitle')
          }
          score={isCsat ? csatScore.CSATScore : npsScore.Score}
          scoreEvolution={isCsat ? undefined : npsScore.ScoreEvolution}
          scoreCircleTitle={isCsat ? 'CSAT' : 'NPS'}
          pastPeriodHasValue={isCsat ? undefined : npsScore.PastPeriodHasValue}
          detractorCount={
            isCsat
              ? csatScore.UnsatisfiedCustommerCount
              : npsScore.DetractorsCount
          }
          detractorPercentage={
            isCsat
              ? csatScore.UnsatisfiedCustomerPercentage
              : npsScore.DetractorsPercentage
          }
          detractorEvolution={isCsat ? undefined : npsScore.DetractorEvolution}
          detractorLabel={
            isCsat
              ? t('dashboard.csat.dissatisfied')
              : t('dashboard.nps.detractor')
          }
          neutralCount={
            isCsat ? csatScore.NeutralCustommerCount : npsScore.NeutralCount
          }
          neutralPercentage={
            isCsat
              ? csatScore.NeutralCustomerPercentage
              : npsScore.NeutralPercentage
          }
          neutralEvolution={isCsat ? undefined : npsScore.NeutralEvolution}
          neutralLabel={
            isCsat ? t('dashboard.csat.neutral') : t('dashboard.nps.neutral')
          }
          promoterCount={
            isCsat ? csatScore.SatisfiedCustommerCount : npsScore.PromoterCount
          }
          promoterPercentage={
            isCsat
              ? csatScore.SatisfiedCustomerPercentage
              : npsScore.PromoterPercentage
          }
          promoterEvolution={isCsat ? undefined : npsScore.PromoterEvolution}
          promoterLabel={
            isCsat ? t('dashboard.csat.satisfied') : t('dashboard.nps.promoter')
          }
          showZone={!isCsat}
          previousPeriodStart={isCsat ? undefined : npsScore.PastPeriodFrom}
          previousPeriodEnd={isCsat ? undefined : npsScore.PastPeriodTo}
        />

        <CustomCard
          loading={loading}
          titleText={t('dashboard.answers.title')}
          titleIconComponent={<QuestionAnswerOutlined color="primary" />}
          value={isCsat ? csatScore.TotalCount : npsScore.TotalCount}
          footerComponent={
            <Button type="button" color="primary" onClick={handleSeeAnswers}>
              {t('dashboard.answers.button')}
            </Button>
          }
        />

        {!isCsat && (
          <CustomCard
            loading={loading}
            titleText={t('dashboard.goal.title')}
            titleIconComponent={<ShowChart color="primary" />}
            value={npsScore.ScoreGoal}
            footerComponent={
              Permissions.higherRights.includes(user?.Role) && (
                <Button
                  type="button"
                  color="primary"
                  onClick={handleOpenEditGoal}
                >
                  {t('dashboard.goal.button')}
                </Button>
              )
            }
          />
        )}

        <CustomCard
          loading={loading}
          titleText={t('dashboard.emails.title')}
          titleIconComponent={<MailOutline color="primary" />}
          value={
            intlFloatPoint(
              emailConversion.Percentage?.toFixed(decimal),
              i18n.language
            ) + '%'
          }
          subValue={t('dashboard.emails.subtitle', {
            answered: emailConversion.TotalAnswers,
            total: emailConversion.TotalSent
          })}
          // footerComponent={
          // <Button type="button" color="primary" onClick={handleMoreEmailInfo}>
          //   {t('dashboard.emails.button')}
          // </Button>
          // }
        />

        {!isCsat && (
          <CustomCard
            loading={loading}
            titleText={t('dashboard.widgets.title')}
            titleIconComponent={<PowerOutlined color="primary" />}
            value={
              intlFloatPoint(
                widgetConversion.Percentage?.toFixed(decimal),
                i18n.language
              ) + '%'
            }
            subValue={t('dashboard.emails.subtitle', {
              answered: widgetConversion.TotalAnswers,
              total: widgetConversion.TotalSent
            })}
            // footerComponent={
            //   <Button
            //     type="button"
            //     color="primary"
            //     onClick={handleMoreWidgetInfo}
            //   >
            //     {t('dashboard.widgets.button')}
            //   </Button>
            // }
          />
        )}
      </section>

      <DashboardEditGoal
        open={showEditGoalModal}
        onClose={handleCloseEditGoal}
        scoreGoal={npsScore?.ScoreGoal}
        handleNewGoal={handleNewGoal}
      />
    </section>
  )
}

export default withTranslation()(DashboardTabs)
