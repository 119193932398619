import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { useSnackbar } from 'notistack'
import { withTranslation } from 'react-i18next'

import useRequest from 'core/hooks/useRequest'
import axios from 'core/api'

import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import TabIcon from '@material-ui/icons/Tab'
import Tooltip from '@material-ui/core/Tooltip'
import Skeleton from '@material-ui/lab/Skeleton'
import CloseIcon from '@material-ui/icons/Close'
import ExportIcon from '@material-ui/icons/GetApp'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ViewListIcon from '@material-ui/icons/ViewList'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import CircularProgress from '@material-ui/core/CircularProgress'

import RoundedButton from '../../../components/Button'
import Header from '../../../components/Header'
import DropdownCampaign from '../../../components/Dropdown/DropdownCampaign'
import Filters, { FilterItem } from '../../AppDrawer/styles/Filter'
import DateRangePicker from '../../../components/DateRangePicker'

import Table from './components/Table'
import CloudGenerator, { NpsType } from './components/CloudGenerator'

import useStyles from './style'
import checkObject from 'core/functions/check-object'

function Wordcloud({ t }) {
  const classes = useStyles()

  const { search } = useLocation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [skeletonLoader, setSkeletonLoader] = useState(true)
  const handleSkeletonLoader = useCallback((x) => {
    setSkeletonLoader(x)
  }, [])

  const [inputValue, setInputValue] = useState('')
  const handleInputValue = useCallback((x) => {
    setInputValue(x)
  }, [])

  const [data, setData] = useState(null)
  const handleData = useCallback((x) => {
    setData(x)
  }, [])

  useRequest('/nps/wordcloud/data', handleData, () =>
    handleSkeletonLoader(false)
  )

  const [filterState, setFilterState] = useState(false)
  const handleFilterState = useCallback(() => {
    setFilterState(!filterState)
  }, [filterState])

  const [stopWords, setStopWords] = useState([])
  const handleStopWords = useCallback((x) => {
    setStopWords(x)
  }, [])

  useRequest(
    '/AdminRemovedWord/GetWords',
    ({ Words }) => Words && handleStopWords(Words)
  )

  const postData = useCallback(
    async (data) => {
      try {
        await axios.post('AdminRemovedWord/InsertUpdate', data)
      } catch (error) {
        enqueueSnackbar(t('word-cloud.stop-word.register.error'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        })
      }
    },
    [enqueueSnackbar, t]
  )

  const getData = useCallback(async () => {
    try {
      const { data } = await axios.get('/nps/wordcloud/data')

      if (data) setData(data)
    } catch (error) {}
  }, [])

  const [wordcloudPromoter, setWordcloudPromoter] = useState(false)
  const [wordcloudNeutral, setWordcloudNeutral] = useState(false)
  const [wordcloudDetractor, setWordcloudDetractor] = useState(false)

  useEffect(() => {
    if(data){

      setWordcloudPromoter(checkObject(data?.Items?.Promoter?.Words))
      setWordcloudNeutral(checkObject(data?.Items?.Neutral?.Words))
      setWordcloudDetractor(checkObject(data?.Items?.Detractor?.Words))

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleAddStopWord = useCallback(
    async (words) => {
      await setStopWords(words)
      await postData({ words })
      getData()
    },
    [getData, postData]
  )

  const [loading, setLoading] = useState(false)
  const handleLoading = useCallback((x) => {
    setLoading(x)
  }, [])

  const notify = useCallback(
    (data) => {
      const actions = (key) => (
        <>
          <Link
            color="primary"
            underline="none"
            href={data.link}
            component={Button}
            style={{ color: '#fff' }}
          >
            {t('uiActions.button.download')}
          </Link>

          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ color: '#fff' }} fontSize="small" />
          </IconButton>
        </>
      )

      const notifyOptions = (variant, action, persist) => ({
        action,
        persist,
        variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })

      const notifyType = {
        start: () => ({
          text: t('uiActions.download.start'),
          options: notifyOptions('default')
        }),
        error: () => ({
          text: data.message,
          options: notifyOptions('error')
        }),
        success: () => ({
          text: t('uiActions.download.success'),
          options: notifyOptions('success', actions, true)
        })
      }

      const { text, options } = notifyType[data.type]()
      enqueueSnackbar(text, options)
    },
    [closeSnackbar, enqueueSnackbar, t]
  )

  const handleExportData = useCallback(async () => {
    handleLoading(true)
    notify({ type: 'start' })

    try {
      const res = await axios.get(`nps/wordcloud/export${search}`)

      if (!res || !res.data || !res.data.Success)
        throw new Error({ message: 'Error on exporting' })

      var link = document.createElement('a')
      link.href = res.data.Url

      link.click()

      if (res.data.Success) notify({ type: 'success', link: res.data.Url })
    } catch (error) {
      if (error.message) notify({ type: 'error', message: error.message })
    } finally {
      handleLoading && handleLoading(false)
    }
  }, [handleLoading, notify, search])

  const [tabState, setTabState] = useState(0)
  const handleChange = useCallback((e, value) => {
    setTabState(value)
  }, [])

  const handleMapData = useCallback(
    (data) => Object.entries(data).map((x) => ({ text: x[0], value: x[1] })),
    []
  )

  const handleKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case ',':
        case ' ': {
          event.preventDefault()
          event.stopPropagation()

          if (event.target.value.length > 0) {
            handleAddStopWord(event.target.value)
          }

          break
        }
        default:
      }
    },
    [handleAddStopWord]
  )

  const ViewModeEnum = { Tabs: 'Tabs', Table: 'Table', Column: 'Column' }
  const [viewMode, setViewMode] = useState(ViewModeEnum.Tabs)

  const PromoterCloud = useMemo(
    () => (
      <>
        {data && data.Items.Promoter && wordcloudPromoter ? (
          <div
            className={
              viewMode === ViewModeEnum.Tabs ? classes.cloudContainer : ''
            }
          >
            <CloudGenerator
              tabState={tabState}
              type={NpsType.Promoter}
              className={classes.cloudContainer}
              data={handleMapData(data?.Items?.Promoter?.Words)}
            />
          </div>
        ) : (
          <div className={classes.skeletonAndEmpty}>
            {skeletonLoader ? (
              <Skeleton>
                <div style={{ width: 700, height: 330 }} />
              </Skeleton>
            ) : (
              <Typography>{t('word-cloud.empty')}</Typography>
            )}
          </div>
        )}
      </>
    ),
    [
      ViewModeEnum.Tabs,
      classes.cloudContainer,
      classes.skeletonAndEmpty,
      data,
      handleMapData,
      skeletonLoader,
      t,
      tabState,
      viewMode,
      wordcloudPromoter
    ]
  )

  const NeutralCloud = useMemo(
    () => (
      <>
        {data && data.Items.Neutral && wordcloudNeutral ? (
          <div
            className={
              viewMode === ViewModeEnum.Tabs ? classes.cloudContainer : ''
            }
          >
            <CloudGenerator
              tabState={tabState}
              type={NpsType.Neutral}
              data={handleMapData(data?.Items?.Neutral?.Words)}
            />
          </div>
        ) : (
          <div className={classes.skeletonAndEmpty}>
            {skeletonLoader ? (
              <Skeleton>
                <div style={{ width: 700, height: 330 }} />
              </Skeleton>
            ) : (
              <Typography>{t('word-cloud.empty')}</Typography>
            )}
          </div>
        )}
      </>
    ),
    [
      ViewModeEnum.Tabs,
      classes.cloudContainer,
      classes.skeletonAndEmpty,
      data,
      handleMapData,
      skeletonLoader,
      t,
      tabState,
      viewMode,
      wordcloudNeutral
    ]
  )
  const DetractorCloud = useMemo(
    () => (
      <>
        {data && data.Items.Detractor && wordcloudDetractor ? (
          <div
            className={
              viewMode === ViewModeEnum.Tabs ? classes.cloudContainer : ''
            }
          >
            <CloudGenerator
              tabState={tabState}
              type={NpsType.Detractor}
              data={handleMapData(data?.Items?.Detractor?.Words)}
            />
          </div>
        ) : (
          <div className={classes.skeletonAndEmpty}>
            {skeletonLoader ? (
              <Skeleton>
                <div style={{ width: 700, height: 330 }} />
              </Skeleton>
            ) : (
              <Typography>{t('word-cloud.empty')}</Typography>
            )}
          </div>
        )}
      </>
    ),
    [
      ViewModeEnum.Tabs,
      classes.cloudContainer,
      classes.skeletonAndEmpty,
      data,
      handleMapData,
      skeletonLoader,
      t,
      tabState,
      viewMode,
      wordcloudDetractor
    ]
  )

  const ViewModeMap = {
    [ViewModeEnum.Tabs]: (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          {tabState === 0 && PromoterCloud}

          {tabState === 1 && NeutralCloud}

          {tabState === 2 && DetractorCloud}
        </Grid>
      </Grid>
    ),
    [ViewModeEnum.Column]: !data?.TotalNps ? (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <div className={classes.skeletonAndEmpty}>
          {skeletonLoader && (
            <Skeleton>
              <div style={{ width: 700, height: 330 }} />
            </Skeleton>
          )}

          {!skeletonLoader && <Typography>{t('word-cloud.empty')}</Typography>}
        </div>
      </Grid>
    ) : (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.listView}
      >
        <Grid item style={{ marginBottom: 30 }}>
          <h1 className={classes.npsTitle}>
            {t('word-cloud.tabs.promoter.title')}
          </h1>

          {PromoterCloud}
        </Grid>

        <Grid item style={{ marginBottom: 30 }}>
          <h1 className={classes.npsTitle}>
            {t('word-cloud.tabs.neutral.title')}
          </h1>

          {NeutralCloud}
        </Grid>
        <Grid item style={{ marginBottom: 30 }}>
          <h1 className={classes.npsTitle} data-testid="title_detractor">
            {t('word-cloud.tabs.detractor.title')}
          </h1>

          {DetractorCloud}
        </Grid>
      </Grid>
    ),
    [ViewModeEnum.Table]: (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <div className={classes.tableContainer}>
            {tabState === 0 &&
              (data && data.Items.Promoter ? (
                <Table
                  className={classes.table}
                  rows={handleMapData(data?.Items?.Promoter?.Words)}
                />
              ) : (
                <div className={classes.skeletonAndEmpty}>
                  {skeletonLoader ? (
                    <Skeleton>
                      <div style={{ width: 700, height: 330 }} />
                    </Skeleton>
                  ) : (
                    <Typography>{t('word-cloud.empty')}</Typography>
                  )}
                </div>
              ))}
          </div>
          <div className={classes.tableContainer}>
            {tabState === 1 &&
              (data && data.Items.Neutral ? (
                <Table
                  className={classes.table}
                  rows={handleMapData(data?.Items?.Neutral?.Words)}
                />
              ) : (
                <div className={classes.skeletonAndEmpty}>
                  {skeletonLoader ? (
                    <Skeleton>
                      <div style={{ width: 700, height: 330 }} />
                    </Skeleton>
                  ) : (
                    <Typography>{t('word-cloud.empty')}</Typography>
                  )}
                </div>
              ))}
          </div>
          <div className={classes.tableContainer}>
            {tabState === 2 &&
              (data && data.Items.Detractor ? (
                <Table
                  className={classes.table}
                  rows={handleMapData(data?.Items?.Detractor?.Words)}
                />
              ) : (
                <div className={classes.skeletonAndEmpty}>
                  {skeletonLoader ? (
                    <Skeleton>
                      <div style={{ width: 700, height: 330 }} />
                    </Skeleton>
                  ) : (
                    <Typography>{t('word-cloud.empty')}</Typography>
                  )}
                </div>
              ))}
          </div>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <Header
        type="actions"
        title={t('word-cloud.title')}
        items={
          <Filters justify="flex-end">
            <FilterItem>
              <RoundedButton
                size="large"
                type="filter"
                whiteTextLabel
                variant="contained"
                onClick={handleFilterState}
              />
            </FilterItem>
          </Filters>
        }
      />

      <Grid container direction="row" className={classes.root}>
        {/* Navbar */}
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.bar}
        >
          <Grid item>
            <Tooltip data-testid="title_tab" title="Ver em Aba">
              <IconButton
                onClick={() => setViewMode(ViewModeEnum.Tabs)}
                color={viewMode === ViewModeEnum.Tabs ? 'primary' : 'default'}
              >
                <TabIcon />
              </IconButton>
            </Tooltip>

            <Tooltip data-testid="title_column" title="Ver em Coluna">
              <IconButton
                onClick={() => setViewMode(ViewModeEnum.Column)}
                color={viewMode === ViewModeEnum.Column ? 'primary' : 'default'}
              >
                <ViewColumnIcon />
              </IconButton>
            </Tooltip>

            <Tooltip data-testid="title_table" title="Ver em Tabela">
              <IconButton
                onClick={() => setViewMode(ViewModeEnum.Table)}
                color={viewMode === ViewModeEnum.Table ? 'primary' : 'default'}
              >
                <ViewListIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item className={classes.tabs}>
            {!(viewMode === ViewModeEnum.Column) && (
              <Tabs
                value={tabState}
                variant="fullWidth"
                textColor="primary"
                aria-label="NPS Tabs"
                onChange={handleChange}
                indicatorColor="primary"
              >
                <Tab
                  data-testid="title_promoter"
                  label={t('word-cloud.tabs.promoter.title')}
                />
                <Tab
                  data-testid="title_neutral"
                  label={t('word-cloud.tabs.neutral.title')}
                />
                <Tab
                  data-testid="title_detractor"
                  label={t('word-cloud.tabs.detractor.title')}
                />
              </Tabs>
            )}
          </Grid>

          <Grid item>
            <Button
              color="primary"
              aria-haspopup="true"
              onClick={handleExportData}
              aria-controls="export-menu"
              startIcon={<ExportIcon fontSize="small" />}
            >
              {t('uiActions.button.export')}
            </Button>
          </Grid>
        </Grid>

        {/* Main Content */}
        <Grid container justifyContent="center" alignItems="center">
          {loading && <CircularProgress style={{ marginTop: 150 }} />}

          {!loading && ViewModeMap[viewMode]}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={filterState} onClose={handleFilterState}>
        <div className={classes.filter}>
          <div className={classes.filterHeader}>
            <IconButton onClick={handleFilterState}>
              <ArrowBackIcon color="primary" />
            </IconButton>
            <h1 className={classes.filterTitle}>
              {t('word-cloud.titles.filter')}
            </h1>
          </div>

          <div className={classes.field}>
            <label htmlFor="period" className={classes.label}>
              {t('word-cloud.titles.period')}
            </label>

            <DateRangePicker
              id="period"
              name="period"
              data-testid="period-filter"
            />
          </div>

          <div className={classes.field}>
            <DropdownCampaign
              id="campaign"
              name="campaign"
              campaignAllSelected
              className="yv-test-home"
            />
          </div>

          <div className={classes.field}>
            <div className={classes.stopWordsInput}>
              <Autocomplete
                multiple
                freeSolo
                id="stop-words"
                disableClearable
                value={stopWords ?? []}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  handleInputValue(newInputValue)
                }}
                options={
                  stopWords ? stopWords.map((word) => word.toString()) : []
                }
                renderTags={(value, getTagProps) =>
                  value.map((word, index) => (
                    <Chip label={word} {...getTagProps({ index })} />
                  ))
                }
                onChange={(e, value) => {
                  if (!value || value === ' ') return
                  handleAddStopWord(value)
                }}
                renderInput={(params) => {
                  params.inputonKeyDown = handleKeyDown

                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder={t('word-cloud.titles.word')}
                      label={t('word-cloud.titles.remove-words')}
                    />
                  )
                }}
              />
            </div>
          </div>

          <div className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleFilterState}
            >
              {t('uiActions.apply')}
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  )
}

export default withTranslation()(Wordcloud)
