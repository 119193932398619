import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border-radius: 4px;
`

export const ListItem = styled.div`
  display: flex;

  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;

  align-items: center;
  align-content: center;

  padding: 12px;

  &:hover {
    background-color: #f2fbfe;
  }

  ${({ index, length }) => {
    const first = index === 0
    const last = index === length - 1

    if (!first && !last && index === 0 && length === 1) {
      return `
      border-radius: 4px;

      border: 1px solid #e0e0e0;
      `
    } else if (first) {
      return `
        border-radius: 4px 4px 0px 0px;
        
        border: 1px solid #e0e0e0;
        `
    } else if (last) {
      return `
          border-radius: 0px 0px 4px 4px;
          
          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        `
    } else {
      return `
          border-radius: 0px;

          border-left: 1px solid #e0e0e0;
          border-right: 1px solid #e0e0e0;
          border-bottom: 1px solid #e0e0e0;
        `
    }
  }}
`

export const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 60%;
  z-index: 1;
  margin-left: 24px;

  @media screen and (min-width: 1920px) {
    width: 75%;
  }
`

export const Title = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  width: fit-content;

  &:hover {
    color: #00aeef;
    cursor: pointer;
    text-decoration: underline;
  }
`
export const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`

export const Infos = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: row;
  width: 20%;
  margin-right: 8px;

  @media screen and (max-width: 1070px) {
    flex-direction: column;
  }
`
export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  align-items: center;
  align-content: center;
`
export const BadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;

  margin: 12px;
  width: 5%;

  @media screen and (min-width: 1920px) {
    width: 10%;
  }
`
export const Badge = styled.div`
  border-radius: 4px;
  min-width: 57px;
  padding: 8px;

  text-align: center;

  ${({ active }) =>
    active
      ? `background: #EEF8EB; opacity: 100%;`
      : `background: rgba(0, 0, 0, 0.05);`}
`
export const BadgeLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  ${({ active }) =>
    active ? `color: #60bb46;` : `color: rgba(0, 0, 0, 0.54);`}
`

export const Label = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  text-align: center;

  ${({ state }) => (state ? `color: #000000;` : 'color: rgba(0, 0, 0, 0.54);')}
`
export const Actions = styled.div``

const useStyles = makeStyles((theme) => ({
  paper: {
    marginRight: theme.spacing(2),
    backgroundColor: '#fff'
  },
  content: {
    marginTop: 20
  },
  icon: {
    marginRight: 8
  },
  menuIcon: {
    marginRight: 12,
    color: 'rgba(0, 0, 0, 0.54)'
  }
}))

export default useStyles
