import { Component } from 'react'

import { Link, withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'

import { Permissions } from 'modules/AppDrawer/enums/Permissions'

import axios from 'core/api'

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import VisibilityIcon from '@material-ui/icons/Visibility'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import '../../../../style/route/person/item.css'

class PersonItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      person: props?.person,
      user: props?.user,
      loading: false
    }
  }

  render() {
    return (
      <li className={'person-item ' + (this.state.person.Active ? '' : 'hide')}>
        <div className="person-item-header">
          <span className="person-item-id">
            {this.state.person.IdYourviews}
          </span>

          <span className="person-item-name">
            {this.state.person.Name || this.props.t('person.item.anonymous')}
          </span>

          {this.state.person.Email && (
            <span className="person-item-email">{this.state.person.Email}</span>
          )}

          <div className="person-item-actions">
            <Link
              to={{
                pathname: `${this.props.match.url}/${this.state.person.IdYourviews}`,
                query: {
                  editing: this.state.person
                }
              }}
            >
              <IconButton color="default" component="span">
                <VisibilityIcon />
              </IconButton>
            </Link>

            {Permissions.managerRights.includes(this.state.user?.Role) && (
              <IconButton
                onClick={() => this.changeStatus()}
                color="default"
                component="span"
              >
                {this.state.person?.Active ? (
                  <CloseIcon />
                ) : (
                  <PowerSettingsNewIcon />
                )}
              </IconButton>
            )}
          </div>
        </div>

        <div className="person-item-body">
          <ul>
            {this.state.person.TargetLists &&
              this.state.person.TargetLists.length > 0 &&
              this.state.person.TargetLists.map((list) => (
                <li className="person-item-list" key={list.IdYourviews}>
                  {list.Name}
                </li>
              ))}

            {this.state.person.TargetLists &&
              this.state.person.TargetLists.length < 1 && (
                <li>{this.props.t('person.item.withoutList')}</li>
              )}
          </ul>
        </div>
      </li>
    )
  }

  changeStatus = () => {
    this.setState({ loading: true })

    axios
      .post(
        'person/changestatus?IdPerson=' +
          this.state.person.IdYourviews +
          '&Active=' +
          !this.state.person.Active,
        null
      )
      .then((res) => {
        if (!res.data || !res.data.Success) throw new Error('Error on update')

        let newPerson = this.state.person
        newPerson.Active = !newPerson.Active

        this.setState({
          person: newPerson
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }
}

export default withTranslation()(withRouter(withSnackbar(PersonItem)))
