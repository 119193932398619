import { useEffect } from 'react'
import { createContext, useCallback, useContext, useState } from 'react'

const CampaignContext = createContext()

export const CampaignProvider = ({ initialValues, children }) => {
  const [campaign, setCampaign] = useState(initialValues?.campaign || {})
  const handleSetCampaign = useCallback((data) => {
    setCampaign(data)
  }, [])

  const [form, setForm] = useState(initialValues?.form || {})
  const handleSetForm = useCallback((data) => {
    setForm(data)
  }, [])

  const [formBackup, setFormBackup] = useState(
    initialValues?.formBackup || undefined
  )
  const handleSetFormBackup = useCallback((data) => {
    setFormBackup(data)
  }, [])

  const [email, setEmail] = useState(initialValues?.email || {})
  const handleSetEmail = useCallback((data) => {
    setEmail(data || {})
  }, [])

  const [emailBackup, setEmailBackup] = useState(
    initialValues?.emailBackup || undefined
  )
  const handleSetEmailBackup = useCallback((data) => {
    setEmailBackup(data)
  }, [])

  const [widget, setWidget] = useState(initialValues?.widget || {})
  const handleSetWidget = useCallback((data) => {
    setWidget(data || {})
  }, [])

  const [details, setDetails] = useState(initialValues?.details || {})
  const handleSetDetails = useCallback((data) => {
    setDetails(data)
  }, [])

  const [dispatch, setDispatch] = useState(initialValues?.dispatch || [])
  const handleSetDispatch = useCallback((data) => {
    setDispatch(data)
  }, [])

  const [boxListItem, setBoxListItem] = useState(
    initialValues?.boxListItem || {}
  )
  const handleSetBoxListItem = useCallback(
    (data, type) => {
      setBoxListItem({
        ...boxListItem,
        [type]: data
      })
    },
    [boxListItem]
  )

  const [emailListObj, setEmailListObj] = useState(initialValues?.emailListObj || {})
  const [emailListArray, setEmailListArray] = useState(initialValues?.emailListArray || [])
  const handleSetEmailList = useCallback((data) => {
    setEmailListArray(data)
    const newEmailListObj = {}
    data.forEach((e) => {
      newEmailListObj[e.IdYourviews] = e
    })
    setEmailListObj(newEmailListObj)
  }, [])

  /**
   * Clean all data from CAmpaign Context
   * This method can be called only on finish, after done campaign
   */
  const cleanCampaignContext = () => {
    handleSetCampaign({})
    handleSetForm({})
    handleSetFormBackup()
    handleSetEmail({})
    handleSetEmailBackup()
    handleSetWidget({})
    handleSetDetails({})
    handleSetDispatch([])
    setBoxListItem({})
    handleSetEmailList([])
  }

  useEffect(() => {
    handleSetCampaign({
      Type: 0,
      ...campaign,
      ...details,
      IdForm: form?.IdYourviews,

      EmailConfiguration: {
        Active: false,
        RememberEmailDaysInterval: 15,
        ResendDaysInterval: 30,
        SenderEmail: 'contato@yourviews.com.br',
        ...email
      },
      WidgetConfiguration: {
        Active: false,
        ShowChance: 30,
        ...widget
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, email, form, widget])

  return (
    <CampaignContext.Provider
      value={{
        campaign,
        handleSetCampaign,
        form,
        handleSetForm,
        formBackup,
        handleSetFormBackup,
        email,
        handleSetEmail,
        emailBackup,
        handleSetEmailBackup,
        details,
        handleSetDetails,
        widget,
        handleSetWidget,
        boxListItem,
        handleSetBoxListItem,
        dispatch,
        handleSetDispatch,
        cleanCampaignContext,
        emailListObj,
        emailListArray,
        handleSetEmailList
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export const useCampaign = () => {
  const context = useContext(CampaignContext)

  const {
    campaign,
    handleSetCampaign,
    form,
    handleSetForm,
    formBackup,
    handleSetFormBackup,
    email,
    handleSetEmail,
    emailBackup,
    handleSetEmailBackup,
    details,
    handleSetDetails,
    widget,
    handleSetWidget,
    boxListItem,
    handleSetBoxListItem,
    dispatch,
    handleSetDispatch,
    cleanCampaignContext,
    emailListObj,
    emailListArray,
    handleSetEmailList
  } = context

  return {
    campaign,
    handleSetCampaign,
    form,
    handleSetForm,
    formBackup,
    handleSetFormBackup,
    email,
    handleSetEmail,
    emailBackup,
    handleSetEmailBackup,
    details,
    handleSetDetails,
    widget,
    handleSetWidget,
    boxListItem,
    handleSetBoxListItem,
    dispatch,
    handleSetDispatch,
    cleanCampaignContext,
    emailListObj,
    emailListArray,
    handleSetEmailList
  }
}
