import './index.css'
import { withTranslation } from 'react-i18next'
import {
  InfoOutlined,
  Send,
  DraftsOutlined,
  DescriptionOutlined,
  Check
} from '@material-ui/icons'
import Tooltip from '@material-ui/core/Tooltip'
import { roundNumber } from 'core/functions/intl-float-point.function'
import { Skeleton } from '@material-ui/lab'
import { useMemo } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { QS_DISPATCH_IDS } from 'core/constants/query-strings'

const FunnelInfo = ({ loading, data, currentDispatchTitle, i18n, t }) => {
  const { language } = i18n
  const { search } = useLocation()

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const currentDispatchId = useMemo(() => {
    return currentSearch[QS_DISPATCH_IDS]
  }, [currentSearch])

  return (
    <div className="conversion-email-funnel-info">
      <div className="conversion-email-funnel-info-title">
        <div>
          {!currentDispatchId
            ? t('conversion-report.email.funnel.title')
            : t('conversion-report.email.funnel.title2')}
        </div>
        <Tooltip
          title={
            !currentDispatchId
              ? t('conversion-report.email.funnel.titleInfo')
              : t('conversion-report.email.funnel.titleInfo2', {
                  dispatchName: currentDispatchTitle
                })
          }
        >
          <InfoOutlined style={{ color: '#757575', marginLeft: 10 }} />
        </Tooltip>
      </div>
      <div className="conversion-email-funnel-info-subtitle">
        {loading ? (
          <Skeleton variant="rect" height={19} width={400} />
        ) : (
          t('conversion-report.email.funnel.subTitle', {
            personCount: roundNumber(data.Sent, 0, language),
            answeredPercentage: roundNumber(
              (100 * data.Answered) / (data.Sent || 1),
              2,
              language
            )
          })
        )}
      </div>
      <div className="conversion-email-funnel-info-table">
        <div className="conversion-email-funnel-info-table-row">
          <div className="conversion-email-funnel-info-table-row-label">
            <Send style={{ color: '#757575', marginRight: 10 }} />
            {t('conversion-report.email.funnel.totalSent')}
          </div>
          <div className="conversion-email-funnel-info-table-row-value">
            <div
              className="conversion-email-funnel-info-table-row-value-percentage"
              style={{ color: '#014F6C' }}
            >
              {loading ? (
                <Skeleton variant="rect" height={23} width={90} />
              ) : (
                roundNumber(data.Sent, 0, language)
              )}
            </div>
          </div>
        </div>

        <div className="conversion-email-funnel-info-table-row-divider"></div>

        <div className="conversion-email-funnel-info-table-row">
          <div className="conversion-email-funnel-info-table-row-label">
            <DraftsOutlined style={{ color: '#757575', marginRight: 10 }} />
            {t('conversion-report.email.funnel.openedEmails')}
          </div>
          <div className="conversion-email-funnel-info-table-row-value">
            <div
              className="conversion-email-funnel-info-table-row-value-percentage"
              style={{ color: '#0B79D0' }}
            >
              {loading ? (
                <Skeleton variant="rect" height={23} width={90} />
              ) : (
                roundNumber(
                  (100 * data.Opened) / (data.Sent || 1),
                  2,
                  language
                ) + '%'
              )}
            </div>
            <div className="conversion-email-funnel-info-table-row-value-total">
              {loading ? (
                <Skeleton
                  variant="rect"
                  height={15}
                  width={130}
                  style={{ marginTop: 8 }}
                />
              ) : (
                t('conversion-report.email.funnel.relativeSent', {
                  relativeCount: roundNumber(data.Opened, 0, language),
                  totalCount: roundNumber(data.Sent, 0, language)
                })
              )}
            </div>
          </div>
        </div>

        <div className="conversion-email-funnel-info-table-row-divider"></div>

        <div className="conversion-email-funnel-info-table-row">
          <div className="conversion-email-funnel-info-table-row-label">
            <DescriptionOutlined
              style={{ color: '#757575', marginRight: 10 }}
            />
            {t('conversion-report.email.funnel.openedForms')}
          </div>
          <div className="conversion-email-funnel-info-table-row-value">
            <div
              className="conversion-email-funnel-info-table-row-value-percentage"
              style={{ color: '#2196F3' }}
            >
              {loading ? (
                <Skeleton variant="rect" height={23} width={90} />
              ) : (
                roundNumber(
                  (100 * data.Clicked) / (data.Sent || 1),
                  2,
                  language
                ) + '%'
              )}
            </div>
            <div className="conversion-email-funnel-info-table-row-value-total">
              {loading ? (
                <Skeleton
                  variant="rect"
                  height={15}
                  width={130}
                  style={{ marginTop: 8 }}
                />
              ) : (
                t('conversion-report.email.funnel.relativeSent', {
                  relativeCount: roundNumber(data.Clicked, 0, language),
                  totalCount: roundNumber(data.Sent, 0, language)
                })
              )}
            </div>
          </div>
        </div>

        <div className="conversion-email-funnel-info-table-row-divider"></div>

        <div className="conversion-email-funnel-info-table-row">
          <div className="conversion-email-funnel-info-table-row-label">
            <Check style={{ color: '#757575', marginRight: 10 }} />
            {t('conversion-report.email.funnel.answers')}
          </div>
          <div className="conversion-email-funnel-info-table-row-value">
            <div
              className="conversion-email-funnel-info-table-row-value-percentage"
              style={{ color: '#64B6F7' }}
            >
              {loading ? (
                <Skeleton variant="rect" height={23} width={90} />
              ) : (
                roundNumber(
                  (100 * data.Answered) / (data.Sent || 1),
                  2,
                  language
                ) + '%'
              )}
            </div>
            <div className="conversion-email-funnel-info-table-row-value-total">
              {loading ? (
                <Skeleton
                  variant="rect"
                  height={15}
                  width={130}
                  style={{ marginTop: 8 }}
                />
              ) : (
                t('conversion-report.email.funnel.relativeSent', {
                  relativeCount: roundNumber(data.Answered, 0, language),
                  totalCount: roundNumber(data.Sent, 0, language)
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(FunnelInfo)
