import { getIn } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

const TextArea = ({ field, form: { touched, errors }, ...props }) => (
  <FormControl
    error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
    className="form-field text-field"
  >
    <InputLabel htmlFor={field.name}>{props.label}</InputLabel>
    <Input
      id={field.name}
      {...field}
      {...props}
      multiline={true}
      minRows={3}
      maxRows={3}
    />
    {!!(
      field.name &&
      getIn(touched, field.name) &&
      getIn(errors, field.name)
    ) && <FormHelperText>{getIn(errors, field.name)}</FormHelperText>}
  </FormControl>
)

export default TextArea
