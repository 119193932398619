import startOfWeek from 'date-fns/startOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import startOfYear from 'date-fns/startOfYear'
import startOfQuarter from 'date-fns/startOfQuarter'
import endOfQuarter from 'date-fns/endOfQuarter'
import endOfYear from 'date-fns/endOfYear'
import addDays from 'date-fns/addDays'
import { addMonths, lastDayOfQuarter } from 'date-fns'
import { subQuarters } from 'date-fns'

const PreSelectionDate = new Map([
  // Today / Hoje
  [1, { startDate: new Date(), endDate: new Date() }],
  // Yesterday / Ontem
  [2, { startDate: addDays(new Date(), -1), endDate: addDays(new Date(), -1) }],
  // This Week / Esta Semana
  [3, { startDate: startOfWeek(new Date()), endDate: new Date() }],
  // This Month / Este Mês
  [4, { startDate: startOfMonth(new Date()), endDate: new Date() }],
  // Current Quarter / Trimestre Atual
  [
    5,
    {
      startDate: startOfQuarter(new Date()),
      endDate: lastDayOfQuarter(new Date())
    }
  ],
  // Last Quarter / Trimestre Anterior
  [
    6,
    {
      startDate: startOfQuarter(subQuarters(new Date(), 1)),
      endDate: endOfQuarter(subQuarters(new Date(), 1))
    }
  ],
  // This Year / Este Ano
  [7, { startDate: startOfYear(new Date()), endDate: endOfYear(new Date()) }],
  // Last Year / Ano Passado
  [
    8,
    {
      startDate: addMonths(startOfYear(new Date()), -12),
      endDate: addMonths(endOfYear(new Date()), -12)
    }
  ]
])

export default PreSelectionDate
