import PropTypes from 'prop-types'

import { Redirect, Route } from 'react-router-dom'

import { useAuthContext } from '../../../modules/Authentication'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { hasAuth, hasPermission } = useAuthContext()

  return (
    <Route
      {...rest}
      render={(props) =>
        hasAuth && hasPermission ? (
          <Component
            {...props}
            path={{
              pathname: props.path,
              search: props.location.search
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: '/admin/login',
              state: { from: props.location },
              search: props.location.search
            }}
          />
        )
      }
    />
  )
}

const { string, func } = PropTypes

PrivateRoute.propTypes = {
  path: string,
  component: func
}

export default PrivateRoute
