import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import InfoIcon from '@material-ui/icons/Info'

import { orange } from '@material-ui/core/colors'

const IconsDictionary = new Map([
  ['0', <WarningIcon style={{ color: orange[500] }} />],
  ['1', <CheckCircleIcon color="primary" />],
  ['2', <ErrorOutlineIcon color="error" />],
  ['3', <ErrorIcon color="error" />],
  ['4', <InfoIcon color="primary" />]
])

export default IconsDictionary
