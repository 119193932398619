import 'style/route/form/main.css'
import { withTranslation } from 'react-i18next'
import FormCard from '../FormCard'

const FormTable = ({ forms, onFormDelete, match, t }) => {
  return (
    <>
      <section className="form-editor-list-body">
        <ul>
          {forms
            .filter((item) => item.Active)
            .map((item) => (
              <FormCard
                form={item}
                onFormDelete={(formId) => onFormDelete(formId)}
                match={match}
                key={item?.IdYourviews}
              />
            ))}
        </ul>

        {forms.length < 1 && (
          <li className="form-editor-list-no-results">
            {t('formEditor.resultsEmpty')}
          </li>
        )}
      </section>
    </>
  )
}

export default withTranslation()(FormTable)
