export const WithoutDrawerRoutes = [
  'sso',
  'login',
  'write',
  'signup',
  'health',
  'recovery',
  'nps-wizard',
  'WidgetScript',
  'CampaignSuccess',
  'campaign/new',
  'campaign/email',
  'campaign/form',
  'campaign/widget',
  'campaign/import',
  'campaign/dispatch',
  'campaign/review',
  'reports/evolution/fullscreen',
  'horus/login',
  'horus/callback'
]
