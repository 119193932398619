import './index.css'
import { withTranslation } from 'react-i18next'
import { Skeleton } from '@material-ui/lab'
import { useMemo } from 'react'

const FunnelChart = ({ loading, data, colors }) => {
  const values = useMemo(() => {
    if (loading) {
      let loadingData = []
      let i = data.length
      while (i !== 0) {
        loadingData.push(i * 100)
        i--
      }
      return loadingData
    }

    return data
  }, [data, loading])

  const biggestValue = useMemo(() => {
    return Math.max(...values)
  }, [values])

  return (
    <div className="conversion-email-funnel-chart">
      {values.map((value, index) => {
        let previousPercentage, percentage, nextPercentage

        if (biggestValue === 0) {
          previousPercentage = 0
          percentage = 0
          nextPercentage = 0
        } else {
          previousPercentage =
            index === 0 ? Infinity : (100 * values[index - 1]) / biggestValue
          percentage = (100 * value) / biggestValue
          nextPercentage =
            index === values.length - 1
              ? -Infinity
              : (100 * values[index + 1]) / biggestValue
        }

        let clipPath
        if (previousPercentage >= percentage) {
          clipPath = `polygon(
              0 0, 
              100% 0, 
              ${100 - (100 - (100 * nextPercentage) / percentage) / 2}% 100%, 
              ${(100 - (100 * nextPercentage) / percentage) / 2}% 100%
            )`
        } else {
          clipPath = `polygon(
            ${100 - (100 - (100 * previousPercentage) / percentage) / 2}% 0%, 
            ${(100 - (100 * previousPercentage) / percentage) / 2}% 0%,
            0 100%, 
            100% 100%
          )`
        }

        if (clipPath.includes('Infinity')) {
          clipPath = ''
        }

        return (
          <div key={index} className="conversion-email-funnel-chart-trapezoid">
            <div
              className="conversion-email-funnel-chart-trapezoid-shape"
              style={{
                background: loading ? 'transparent' : colors[index],
                width: `${percentage}%`,
                clipPath
              }}
            >
              {loading && (
                <Skeleton variant="rect" height="100%" width={'100%'} />
              )}
            </div>

            <div className="conversion-email-funnel-chart-trapezoid-text">
              {!loading && value}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default withTranslation()(FunnelChart)
