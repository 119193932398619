import { useCallback, useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'

import axios from 'core/api'

import Menu from '@material-ui/core/Menu'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import EmailIcon from '@material-ui/icons/EmailOutlined'
import PowerIcon from '@material-ui/icons/PowerOutlined'
import CreateIcon from '@material-ui/icons/Create'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'

import Pagination from 'components/Pagination'

import useStyles, {
  Actions,
  Badge,
  BadgeContainer,
  BadgeLabel,
  Container,
  Infos,
  InfoItem,
  Label,
  ListItem,
  Subtitle,
  Text,
  Title
} from './style'
import Icon from '@mdi/react'
import {
  mdiAlphaN,
  mdiAlphaP,
  mdiAlphaS,
  mdiAlphaC,
  mdiAlphaA,
  mdiAlphaT
} from '@mdi/js'
import { Box } from '../../style'
import { useCampaign } from '../Details/context'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import { Button } from '@material-ui/core'
import { BarChart } from '@material-ui/icons'
import { BASE_PATH } from 'router'

const TypeIconsSize = 1.5
const TypeIconsPositionRate = 23
const TypeIconsColors = {
  nps: '#00AEEF',
  csat: '#33697A'
}
const NPSIcons = [mdiAlphaN, mdiAlphaP, mdiAlphaS]
const CSATIcons = [mdiAlphaC, mdiAlphaS, mdiAlphaA, mdiAlphaT]

const List = ({
  onUpdateActiveStatus,
  items,
  pagination,
  onChangePagination,
  i18n,
  t
}) => {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { cleanCampaignContext } = useCampaign()
  useEffect(() => {
    cleanCampaignContext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [loading, setLoading] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleEdit = (id) => history.push(`/admin/campaign/details?Id=${id}`)

  const notifyOptions = (variant) => ({
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right'
    },
    variant
  })

  const handleChangeActiveStatus = async () => {
    setLoading(true)

    try {
      const newItemState = { ...selectedItem, Active: !selectedItem.Active }

      const res = await axios.post('/campaign/insertupdate', newItemState)

      if (res.data.HasErrors) {
        enqueueSnackbar(
          t('campaign.notify.changeStatus.error'),
          notifyOptions('error')
        )
      }

      if (!res.data.HasErrors && res.data.Element) {
        onUpdateActiveStatus(res.data.Element)

        const message = res.data.Element.Active
          ? t('campaign.notify.changeStatus.successActive')
          : t('campaign.notify.changeStatus.successInactive')

        enqueueSnackbar(message, notifyOptions('success'))
      }
    } catch (error) {
      enqueueSnackbar(
        t('campaign.notify.changeStatus.genericError'),
        notifyOptions('error')
      )
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget)
    setSelectedItem(item)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const LocaleMap = {
    pt: 'pt-BR',
    en: 'en-US',
    es: 'es-Es'
  }

  const getLanguage = () => i18n.language ?? window.localStorage.i18nextLng

  const formatDate = (date) =>
    new Date(date).toLocaleDateString(LocaleMap[getLanguage()])

  const formatHour = (date) =>
    new Date(date).toLocaleTimeString(LocaleMap[getLanguage()], {
      hour: '2-digit',
      minute: '2-digit'
    })

  const makeSubtitle = (initialDate, endDate) =>
    `${t('labels.from')} ${formatDate(initialDate)} ${t(
      'labels.at'
    )} ${formatHour(initialDate)}  ${t('labels.to')} ${formatDate(endDate)} ${t(
      'labels.at'
    )} ${formatHour(endDate)}`

  const handleSeeReport = useCallback(
    (campaign) => {
      history.push(
        BASE_PATH +
          `reports/conversion?idcampaign=${campaign.IdYourviews}&idcampaignlist=${campaign.IdYourviews}`
      )
    },
    [history]
  )

  return (
    <>
      {items?.length > 0 && (
        <>
          <Container data-testid="campaign-list-container">
            {items &&
              items.map((item, index) => (
                <ListItem
                  key={item.IdYourviews}
                  //
                  index={index}
                  length={items.length}
                  //
                  data-testid={'campaign-list-item'}
                >
                  <div style={{ width: 55 }} />
                  <div style={{ position: 'absolute', display: 'flex' }}>
                    {item.Type === 0 && (
                      <>
                        {NPSIcons.map((icon, index) => (
                          <Icon
                            key={icon}
                            style={{
                              position: 'relative',
                              right: TypeIconsPositionRate * index,
                              color: TypeIconsColors.nps
                            }}
                            path={icon}
                            size={TypeIconsSize}
                          />
                        ))}
                      </>
                    )}
                    {item.Type === 1 && (
                      <>
                        {CSATIcons.map((icon, index) => (
                          <Icon
                            key={icon}
                            style={{
                              position: 'relative',
                              right: TypeIconsPositionRate * index,
                              color: TypeIconsColors.csat
                            }}
                            path={icon}
                            size={TypeIconsSize}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  <Text>
                    <Title
                      onClick={() => handleEdit(item?.IdYourviews)}
                      data-testid={`campaign-list-item-${item?.IdYourviews}-title`}
                    >
                      {item?.Name}
                    </Title>

                    <Subtitle
                      data-testid={`campaign-list-item-${item?.IdYourviews}-subtitle`}
                    >
                      {item?.HasExpirationInterval &&
                        `${makeSubtitle(
                          item?.IntervalStartTimezone,
                          item?.IntervalEndTimezone
                        )}`}

                      {!item?.HasExpirationInterval &&
                        t('campaign.noExpiration')}
                    </Subtitle>
                  </Text>

                  <Infos>
                    <InfoItem
                      style={{
                        visibility:
                          item.Type === NPS_CSAT_TAB.nps.typeValue
                            ? 'unset'
                            : 'hidden'
                      }}
                    >
                      <PowerIcon
                        fontSize="small"
                        className={classes.icon}
                        color={
                          item?.WidgetConfiguration?.Active
                            ? 'primary'
                            : 'disabled'
                        }
                        data-testid={`campaign-list-item-${item?.IdYourviews}-widget-icon`}
                      />
                      <Label state={item?.WidgetConfiguration?.Active}>
                        {t('labels.widget')}
                      </Label>
                    </InfoItem>

                    <InfoItem>
                      <EmailIcon
                        fontSize="small"
                        className={classes.icon}
                        color={
                          item?.EmailConfiguration?.Active
                            ? 'primary'
                            : 'disabled'
                        }
                        data-testid={`campaign-list-item-${item?.IdYourviews}-email-icon`}
                      />
                      <Label state={item?.EmailConfiguration?.Active}>
                        {t('labels.email')}
                      </Label>
                    </InfoItem>
                  </Infos>

                  <BadgeContainer>
                    <Badge active={item?.Active}>
                      <BadgeLabel
                        active={item?.Active}
                        data-testid={`campaign-list-item-${item?.IdYourviews}-active-state-badge`}
                      >
                        {item?.Active
                          ? t('labels.active')
                          : t('labels.inactive')}
                      </BadgeLabel>
                    </Badge>
                  </BadgeContainer>

                  <InfoItem>
                    <Button
                      color="primary"
                      onClick={() => handleSeeReport(item)}
                    >
                      <BarChart style={{ marginRight: 5 }} color="primary" />
                    </Button>
                  </InfoItem>

                  <Actions>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, item)}
                      data-testid={`campaign-list-item-${item?.IdYourviews}-open-menu-icon`}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Actions>
                </ListItem>
              ))}

            <Paper>
              <Menu
                open={open}
                keepMounted
                anchorEl={anchorEl}
                onClose={handleClose}
                id="campaign-actions-menu"
                TransitionComponent={Fade}
                data-testid={`campaign-list-item-${selectedItem?.IdYourviews}-menu`}
              >
                <MenuItem
                  onClick={() => handleEdit(selectedItem?.IdYourviews)}
                  data-testid={`campaign-list-item-${selectedItem?.IdYourviews}-menu-edit-icon`}
                >
                  <CreateIcon className={classes.menuIcon} fontSize="small" />
                  <Typography variant="inherit">
                    {t('campaign.menu.edit')}
                  </Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    if (loading) return
                    handleChangeActiveStatus()
                  }}
                  data-testid={`campaign-list-item-${selectedItem?.IdYourviews}-menu-change-status-icon`}
                >
                  <PowerSettingsNewIcon
                    className={classes.menuIcon}
                    fontSize="small"
                  />

                  <Typography align="center" variant="inherit">
                    {selectedItem?.Active
                      ? t('campaign.menu.inactive')
                      : t('campaign.menu.active')}
                  </Typography>
                </MenuItem>
              </Menu>
            </Paper>
          </Container>

          <Box row flexEnd marginTop="10px">
            {pagination && (
              <Pagination
                data={pagination}
                onChangePagination={onChangePagination}
              />
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default withTranslation()(List)
