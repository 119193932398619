import styled from 'styled-components'

const containerWidth = (size) => {
  switch (size) {
    case '1':
      return `
      height: 70px;
      width: 70px; 
    `
    case '2':
      return `
      height: 90px;
      width: 90px; 
    `
    case '3':
      return `
      height: 180px;
      width: 180px; `
    default:
      return `
      height: 90px;
      width: 90px; 
    `
  }
}

const fontSizeBasedOnSize = (size, decimal) => {
  if (decimal) {
    switch (size) {
      case '1':
        return `font-size: 12px;`
      case '2':
        return 'font-size: 15px'
      case '3':
        return `font-size: 24px;`
      default:
        return 'font-size: 15px'
    }
  } else {
    switch (size) {
      case '1':
        return `font-size: 16px;`
      case '2':
        return `font-size: 24px;`
      case '3':
        return `font-size: 36px;`

      default:
        return `font-size: 24px;`
    }
  }
}

export const Container = styled.div`
  display: grid;

  text-align: center;

  ${({ size }) => containerWidth(size)}
`
export const Layer1 = styled.div`
  grid-column: 1;
  grid-row: 1;

  ${({ size }) => containerWidth(size)}
`
export const Layer2 = styled.div`
  grid-column: 1;
  grid-row: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  align-self: center;
  margin: auto;
  text-align: center;

  font-style: normal;
  font-weight: bold;
  ${({ size, decimal }) => fontSizeBasedOnSize(size, decimal)}
`
