export const ORDENATION_TYPE = {
  DateDesc: 'DateDesc',
  DateAsc: 'DateAsc',
  NpsScoreAsc: 'NpsScoreAsc',
  NpsScoreDesc: 'NpsScoreDesc',
  TotalAsc: 'TotalAsc',
  TotalDesc: 'TotalDesc',
  DetractorAsc: 'DetractorAsc',
  DetractorDesc: 'DetractorDesc',
  PromoterAsc: 'PromoterAsc',
  PromoterDesc: 'PromoterDesc',
  NeutralAsc: 'NeutralAsc',
  NeutralDesc: 'NeutralDesc',
  ScoreEvolutionAsc: 'ScoreEvolutionAsc',
  ScoreEvolutionDesc: 'ScoreEvolutionDesc'
}
