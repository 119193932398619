import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import Dialog from './Dialog'

/**
 * Component that calls Modal Dialog to ask user to perform an boolean action
 *
 * @component
 */
const Confirmation = ({ show, accept, close, message, t }) => (
  <Dialog
    show={show}
    onClose={() => close()}
    onCancel={() => close()}
    onAccept={() => accept()}
  >
    <div>
      <h3 className="modal-title">{t('admin.status.title')}</h3>
      <div className="modal-text">{message}</div>
    </div>
  </Dialog>
)

const { func, bool, object } = PropTypes

Confirmation.propTypes = {
  /**
   * Boolean that show or hide modal
   */
  show: bool,
  /**
   * Action to execute when confirmation is "Accept" or "Yes"
   */
  accept: func,
  /**
   * Close Action to execute when confirmation is "Close"
   */
  close: func,
  /**
   * Message to show when modal open to ask action
   */
  message: object
}

Confirmation.defaultProps = {
  show: false
}

export default withTranslation()(Confirmation)
