import { useMemo, useCallback } from 'react'
import { Container } from './style'
import { useTranslation } from 'react-i18next'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import BarChartIcon from '@material-ui/icons/BarChart'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import Pagination from 'components/Pagination'
import { useStyles } from './style'
import EvolutionPercentage from 'components/EvolutionPercentage'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router'
import ViewMode from '../../dictionaries/view-mode'
import { BASE_PATH } from 'router'
import { Skeleton } from '@material-ui/lab'
import { getSearchWithDimensionId } from '../../functions/get-search-with-dimension-id'
import { QS_PAGE } from 'core/constants/query-strings'
import { QS_PAGE_SIZE } from 'core/constants/query-strings'
import { QS_DIMENSION } from 'core/constants/query-strings'
import ScoreCircle from 'components/ScoreCircle'

const DimensionList = ({
  decimal,
  loadingDecimal,
  loadingDimensionsScoreData,
  dimensionsScoreData,
  valueScoreData,
  loadingValueScoreData
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { search, pathname } = useLocation()
  const { push } = useHistory()

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const selectedDimensionKey = useMemo(() => {
    return currentSearch[QS_DIMENSION]
  }, [currentSearch])

  const data = useMemo(() => {
    return selectedDimensionKey ? valueScoreData : dimensionsScoreData
  }, [dimensionsScoreData, selectedDimensionKey, valueScoreData])

  const HeadersMap = useMemo(() => {
    return [
      !selectedDimensionKey
        ? t('nps-reports.list.dimensions')
        : selectedDimensionKey,
      'NPS',
      t('nps-reports.list.evolution'),
      t('nps-reports.list.quantity')
    ]
  }, [selectedDimensionKey, t])

  const handleGoToEvolution = useCallback(
    (dimension) => {
      const newSearchObj = queryString.parse(
        getSearchWithDimensionId(search, dimension, dimensionsScoreData?.Items)
      )

      delete newSearchObj[QS_PAGE]

      push({
        pathname: BASE_PATH + `reports/nps/${ViewMode.evolution}`,
        search: queryString.stringify(newSearchObj)
      })
    },
    [dimensionsScoreData?.Items, push, search]
  )

  const handleClickOnDimension = useCallback(
    (dimension) => {
      const newSearchObj = queryString.parse(
        getSearchWithDimensionId(search, dimension, dimensionsScoreData?.Items)
      )

      delete newSearchObj[QS_PAGE]

      push({
        pathname: pathname,
        search: queryString.stringify(newSearchObj)
      })
    },
    [dimensionsScoreData?.Items, pathname, push, search]
  )

  const loading = useMemo(() => {
    return loadingDimensionsScoreData || loadingDecimal || loadingValueScoreData
  }, [loadingDecimal, loadingDimensionsScoreData, loadingValueScoreData])

  const handleChangePagination = useCallback(
    (newPage, newPageSize) => {
      push({
        pathname: pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_PAGE]: newPage,
          [QS_PAGE_SIZE]: newPageSize
        })
      })
    },
    [currentSearch, pathname, push]
  )

  return (
    <Container>
      <div className={classes.root}>
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table} aria-label="dinamic-table" rows={[]}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {HeadersMap.map((headerTitle, index) => (
                  <TableCell
                    key={index}
                    align={index === 0 ? 'left' : 'center'}
                  >
                    {headerTitle}
                  </TableCell>
                ))}
                <TableCell align="right"> </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading
                ? [0, 1, 2].map((_, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.row}
                        component="th"
                        scope="row"
                      >
                        <div className={classes.link}>
                          {!selectedDimensionKey && (
                            <FolderOpenIcon className={classes.tableRowIcon} />
                          )}
                          <span
                            style={{
                              margin: 'auto 4px'
                            }}
                          >
                            <Skeleton
                              data-testid="dimensions-table-skeleton"
                              width="270px"
                            />
                          </span>
                        </div>
                      </TableCell>

                      <TableCell className={classes.row} align="center">
                        <div className={classes.scoreCircleContainer}>
                          <Skeleton
                            data-testid="dimensions-table-skeleton"
                            variant="circle"
                            width={50}
                            height={50}
                          />
                        </div>
                      </TableCell>

                      <TableCell
                        className={classes.row}
                        align="center"
                        style={{ textAlign: '-webkit-center' }}
                      >
                        <Skeleton
                          data-testid="dimensions-table-skeleton"
                          width="50px"
                        />
                      </TableCell>

                      <TableCell
                        className={classes.row}
                        align="center"
                        style={{ textAlign: '-webkit-center' }}
                      >
                        <Skeleton
                          data-testid="dimensions-table-skeleton"
                          width="50px"
                        />
                      </TableCell>

                      <TableCell
                        style={{
                          minWidth: 120,
                          height: 38
                        }}
                        align="center"
                      ></TableCell>
                    </TableRow>
                  ))
                : data?.Items?.map((row, index) => (
                    <TableRow
                      className={classes.tableRow}
                      key={row.Dimension || row.DimensionValue}
                      id={row.Dimension || row.DimensionValue}
                    >
                      <TableCell
                        className={classes.row}
                        data-testid="dimensions-table-item"
                        component="th"
                        scope="row"
                      >
                        <div className={classes.link}>
                          {!selectedDimensionKey && (
                            <FolderOpenIcon className={classes.tableRowIcon} />
                          )}
                          <span
                            onClick={() =>
                              handleClickOnDimension(row.Dimension)
                            }
                            className={`${classes.tableRowName} ${
                              !selectedDimensionKey
                                ? classes.tableRowNameClickable
                                : ''
                            }`}
                          >
                            {!selectedDimensionKey
                              ? row.Dimension
                              : row.DimensionValue}
                          </span>
                        </div>
                      </TableCell>

                      <TableCell className={classes.row} align="center">
                        <div className={classes.scoreCircleContainer}>
                          <ScoreCircle
                            tooltip
                            size="1"
                            decimal={decimal}
                            value={row.Score}
                            promoterCount={row.PromoterCount}
                            neutralCount={row.NeutralCount}
                            detractorCount={row.DetractorsCount}
                            promoterPercentage={row?.PromoterPercentage?.toFixed(
                              2
                            )}
                            neutralPercentage={row?.NeutralPercentage?.toFixed(
                              2
                            )}
                            detractorPercentage={row?.DetractorsPercentage?.toFixed(
                              2
                            )}
                          />
                        </div>
                      </TableCell>

                      <TableCell className={classes.row} align="center">
                        <EvolutionPercentage
                          evolution={(row.NpsScoreEvolution || 0).toString()}
                          evolutionDownColor="#EF3E39"
                          evolutionStableColor="#9E9E9E"
                          evolutionUpColor="#60BB46"
                        />
                      </TableCell>

                      <TableCell className={classes.row} align="center">
                        {row.TotalCount}
                      </TableCell>

                      <TableCell
                        style={{
                          minWidth: 120,
                          height: 38
                        }}
                        align="center"
                        className={classes.tableRowAction}
                      >
                        {!selectedDimensionKey && (
                          <Button
                            onClick={() => handleGoToEvolution(row.Dimension)}
                            startIcon={<BarChartIcon />}
                            color="primary"
                          >
                            {t('uiActions.evolution')}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          data={data?.MetaData}
          onChangePagination={handleChangePagination}
        />
      </div>
    </Container>
  )
}

export default DimensionList
