import { Component } from 'react'

import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'

import { Formik, Form, Field } from 'formik'

import moment from 'moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'

import axios from 'core/api'

import Yup from 'core/validations/index'
import TextArea from '../../../../components/Form/Text/TextArea'
import Loader from '../../../../components/Loader'

import Button from '@material-ui/core/Button'

class AnswerStoreComment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      comment: props.comment,
      loading: false
    }
  }

  render() {
    moment.locale(
      this.props.i18n.language === 'pt' ? 'pt-br' : this.props.i18n.language
    )
    const fields = {
      storeComment: ''
    }
    const fieldsValidation = Yup.object().shape({
      storeComment: Yup.string().max(5000).min(8).required()
    })

    return (
      <div className="answer-item-store-comment">
        {this.state.comment &&
          this.state.comment.map((item, index) => (
            <div className="store-comment-item" key={index}>
              <span className="store-comment-item-date">
                {moment(item.Date).format('L')}
              </span>
              <p className="store-comment-item-comment">{item.Note}</p>
            </div>
          ))
        }

        {this.props.showBtn && (
          <div className="answer-item-store-comment-new animated slideInLeft faster">
            <Formik
              initialValues={fields}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                this.submitComment(values, setSubmitting, resetForm)
              }}
              validationSchema={fieldsValidation}
            >
              {(props) => {
                return (
                  this.state.loading ? (
                    <Loader size={50} noMargin margin="50px" />
                  ) : (
                    <Form>
                      <Field
                        name="storeComment"
                        label={this.props.t('answer.storeCommentLabel')}
                        component={TextArea}
                        placeholder={this.props.t(
                          'answer.storeCommentPlaceholder'
                        )}
                      />

                      <Button
                        color="primary"
                        type="submit"
                        variant="contained"
                        disabled={props.isSubmitting}
                        style={{ marginTop: '10px'}}
                      >
                        {this.props.t('answer.btnStoreCommentSend')}
                      </Button>
                    </Form>
                  )
                )
              }}
            </Formik>
          </div>
        )}
      </div>
    )
  }

  submitComment = (values, setSubmitting, resetForm) => {
    this.setState({ loading: true })

    axios
      .post('AdminNote/InsertUpdate', {
        IdNps: this.props.id,
        Note: values.storeComment
      })
      .then((res) => {
        if (!res.data || res.data.HasErrors) throw Error('Error on comment')

        let newComment = this.state.comment
        newComment = newComment || []
        newComment.push(res.data.Element)

        this.setState(
          {
            comment: newComment
          },
          resetForm
        )
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
        setSubmitting(false)
      })
  }
}

export default withTranslation()(withSnackbar(AnswerStoreComment))
