import queryString from 'query-string'

import { QS_DATE_FROM, QS_DATE_TO } from 'core/constants/query-strings'

/**
 * Cast string Date to new Date object
 *
 * @param {Date} date Date string to cast as new Date
 */
export const castToDate = (date) => (date ? new Date(date) : null)

/**
 * Get current date from queryString on actual route location
 *
 * @param {object} location Location object from component (ex props.location)
 */
const getCurrentDate = (location) => {
  let currentQS = queryString.parse(location.search)

  const from = castToDate(currentQS[QS_DATE_FROM])
  const to = castToDate(currentQS[QS_DATE_TO])

  return { from, to }
}

export default getCurrentDate
