import styled from 'styled-components'

import { Button, withStyles } from '@material-ui/core'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import { makeStyles } from '@material-ui/core'

export const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const ImportLabel = styled.div`
  margin: auto 8px;
`
export const ListActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const FileBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 8px 20px;

  width: 300px;

  margin-top: 8px;
`
export const FileInfo = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
`
export const FileName = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
`
export const FileIcon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 20px;
`

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  margin-top: 32px;
`

export const FormField = styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 512px;
  width: 30%;
  margin: 4px 0px;
  margin-left: 20px;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;

  ${({ justifyStart }) =>
    justifyStart
      ? 'justify-content: flex-start;'
      : 'justify-content: space-between;'}
`
export const DimensionHelpTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`
export const DimensionHelpItemTitle = styled.div`
  font-weight: bold;
`
export const DimensionHelpText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #000000;

  margin: 24px 0px;
`

export const Container = styled.div`
  width: 900px;
`

export const FormTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #212121;
`

export const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 581px;
  height: 392px;
  min-height: 392px;
  max-height: 392px;

  padding: 24px;
  background: #ffffff;
  border: 1px dashed #ef3e39;
  border-radius: 8px;
`

export const BottomActions = styled.div`
  margin-top: 12px;
  margin-bottom: 4px;
`
export const ErrorItems = styled.div`
  min-height: 100px;
  max-height: 100px;
  overflow-y: auto;
`

export const ErrorQuantityBadge = styled.div``

export const ErrorTitle = styled.h5`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 16px;
`
export const ErrorQuantity = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ef3e39;
`

export const Error = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  text-align: left;

  padding: 8px 24px;

  width: 100%;
`

export const Message = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  text-align: start;

  margin-left: 8px;

  width: 100%;
`

export const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #000000;

  margin-top: 58px;
`
export const Actions = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;

  margin: 20px 0px;
`
export const Action = styled.li`
  display: flex;
  justify-content: space-between;
  width: 647px;
  height: 72px;
  top: 247px;

  background: #ffffff;

  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 8px 0px;

  &:hover {
    border: 1px solid #00aeef;
    background-color: rgba(0, 174, 239, 0.11);
    color: #00aeef;
    cursor: pointer;
  }
`
export const ActionTitle = styled.h5`
  text-align: start;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: #000000;
`
export const ActionSubtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;

  width: ${({ width }) => width}%;

  margin: auto 0px;

  ${({ noHPadding }) =>
    noHPadding
      ? `
        padding: 12px 0px;
      `
      : `
        padding: 12px 24px;
      `}
`

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  padding-top: 24px;

  flex-grow: 1;
`

export const TabContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

export const HelpBox = styled.div`
  display: flex;
  flex-direction: column;

  text-align: start;

  width: 316px;
  height: 392px;
  min-height: 392px;
  max-height: 392px;

  padding: 24px;
  margin: 24px 0px;
  margin-left: 24px;

  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`

export const HelpTitle = styled.h5`
  font-size: 16px;
  line-height: 26px;
  color: rgba(0, 0, 0, 0.87);

  margin-bottom: 8px;
`
export const HelpDescription = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.54);

  text-align: justify;

  margin-bottom: 16px;
`

export const ImportTable = styled.div`
  display: flex;
  flex-direction: column;
`
export const ImportTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  padding: 20px 0px;
  text-align: left;
`
export const ImportTableTitle = styled.div`
  text-align: left;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #000000;

  width: 29.5%;
`
export const PreviewItem = styled.div`
  margin: 4px;
`
export const PreviewList = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;

  border-top: 1px solid #bdbdbd;

  padding: 12px 0px;

  width: 100%;
`
export const ImportTableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  align-self: center;
  width: 100%;

  cursor: pointer;
`
export const ImportTableColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  align-items: center;

  width: ${({ width }) => (width ? width : '100%')};

  ${({ noBorder }) => (noBorder ? '' : 'border-bottom: 1px solid #bdbdbd;')}
`

export const DownloadButton = withStyles((theme) => ({
  root: {
    bottom: 0,
    boxShadow: 'none',
    color: '#00AEEF',
    backgroundColor: 'rgba(0, 174, 239, 0.11);',
    '&:hover': {
      backgroundColor: 'rgba(0, 174, 239, 0.2)'
    }
  }
}))(Button)

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion)

export const AccordionSummary = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: '1px solid rgba(0, 0, 0, .125)'
  }
}))(MuiAccordionDetails)

const useStyles = makeStyles(() => ({
  expandIcon: {
    margin: 'auto 0px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  accordion: {
    width: '70%'
  },
  accSummaryContent: {
    width: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}))

export default useStyles
