import '../../../style/route/api/main.css'
import Loader from '../../../components/Loader'
import { withTranslation } from 'react-i18next'
import { useCallback, useState } from 'react'
import axios from 'core/api'
import { useAppContext } from 'App'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from '@material-ui/core'

const UpdateApiUserStatusDialog = ({
  user,
  status,
  open,
  onClose,
  onSuccess,
  t
}) => {
  const { notify } = useAppContext()
  const [loading, setLoading] = useState(false)

  const changeUserStatus = useCallback(async () => {
    setLoading(true)
    await axios
      .post('BasicAuthUser/InsertUpdateV2', {
        Active: status,
        Label: user.Label,
        IdYourviews: user.IdYourviews
      })
      .then((res) => {
        if (res && res.data && !res.data.HasErrors) {
          onSuccess()
          onClose()
        }
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user, status, onSuccess, onClose, notify])

  return (
    <Dialog open={open}>
      {loading && (
        <div className="api-loader-wrapper">
          <Loader size={35} noMargin />
        </div>
      )}

      <DialogTitle>
        {status
          ? t('api.statusDialog.activate.title')
          : t('api.statusDialog.inactivate.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {status
            ? t('api.statusDialog.activate.content')
            : t('api.statusDialog.inactivate.content')}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="api-dialog-actions">
        <div></div>
        <div className="api-dialog-actions-buttons">
          <Button onClick={onClose} color="primary">
            {t('api.statusDialog.cancel')}
          </Button>
          <Button
            onClick={changeUserStatus}
            variant="contained"
            color="primary"
          >
            {t('api.statusDialog.confirm')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(UpdateApiUserStatusDialog)
