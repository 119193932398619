import { makeStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import Rainbow from 'core/functions/get-gradient-colors'

const useStyles = makeStyles({
  noMargin: {
    margin: 0
  },
  radioBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "auto",
    marginBottom: "2px",
    padding: "0 10px",
    minWidth: "calc(20% - 2px);",
    boxSizing: "border-box",
    height: "40px",
    userSelect: "none",
    backgroundColor: "#e1e0e1",
    "& .MuiIconButton-root" : {
      display: "none"
    }
  }
})

const Radiobox = ({ field, isColorScale, formField, themeMainColor, index, ...props }) => {

  const classes = useStyles();

  let color = ""
  if (formField?.FormFieldValues?.length === 1 && isColorScale) {
    color = themeMainColor || "#00aeef"
  } else if (formField?.FormFieldValues?.length > 1 && isColorScale) {
    let gradientArray = []  
    let rainbow = new Rainbow(); 
    rainbow.setNumberRange(1, formField?.FormFieldValues?.length);
    rainbow.setSpectrum('#e94b5c', "#f9e382", '#26ba54');
    for (let i = 1; i <= formField?.FormFieldValues?.length; i++) {
        let hexColour = rainbow.colourAt(i);
        gradientArray.push('#' + hexColour)
    }
    color = gradientArray[index]
  }
  
  return (
  <FormControlLabel
    label={props.label}
    className={`
      ${classes.noMargin} 
      ${isColorScale ? classes.radioBox : ""}
    `}
    style={((field.value === props.id) && isColorScale) ? {backgroundColor: color} : null}
    labelPlacement={props.valuetype === 0 ? 'end' : 'bottom'}
    //
    control={
      <Radio
        {...field}
        {...props}
        size="small"
        color="primary"
        value={props.id}
        className={useStyles().noMargin}
        checked={field.value === props.id}
        data-testid={`${field.name}-${field.id}`}
        checkedIcon={
          <RadioButtonCheckedIcon
            fontSize="small"
            style={{
              margin: 0,
              color: props?.bgcolor
            }}
          />
        }
      />
    }
  />
)}

export default Radiobox
