import './index.css'
import { withTranslation } from 'react-i18next'
import BigButton from 'components/BigButton'
import AddIcon from '@material-ui/icons/Add'
import { useCallback } from 'react'
import dashboardNoCampaign from 'assets/img/dashboard-no-campaign.svg'
import { useHistory } from 'react-router-dom'
import { BASE_PATH } from 'router'

const DashboardNoCampaign = ({ t }) => {
  const { push } = useHistory()

  const handleAddNewCampaign = useCallback(() => {
    push(BASE_PATH + 'campaign/new')
  }, [push])

  return (
    <div className="dashboard-nocampaign">
      <div className="dashboard-nocampaign-text">
        <div className="dashboard-nocampaign-text-title">
          {t('dashboard.noCampaign.title')}
        </div>
        <div className="dashboard-nocampaign-text-description">
          {t('dashboard.noCampaign.description')}
        </div>
        <div className="dashboard-nocampaign-text-button">
          <BigButton
            icon={<AddIcon />}
            onClick={handleAddNewCampaign}
            title={t('dashboard.noCampaign.button')}
            data-testid="dashboard-new-campaign-btn"
          />
        </div>
      </div>
      <div className="dashboard-nocampaign-image">
        <img src={dashboardNoCampaign} alt={t('dashboard.noCampaign.image')} />
      </div>
    </div>
  )
}

export default withTranslation()(DashboardNoCampaign)
