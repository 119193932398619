import { useState } from 'react'

import Files from 'react-files'

import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { useAppContext } from '../../../../App'

import { BASE_PATH } from '../../../../router'

import { FaUpload } from 'react-icons/fa'

import axios from 'core/api'

import FileIcon from '../FileIcon'
import ProcessingLog from '../ProcessingLog'
import ProcessingFiles from '../ProcessingFiles'
import AccordionItem from '../../../../components/AccordionItem'
import Loader from '../../../../components/Loader'

const Upload = ({ t, history }) => {
  const { notify } = useAppContext()

  const [loading, setLoading] = useState(false)

  const [file, setFile] = useState(null)
  const [errors, setErrors] = useState([])
  const [processedFile, setProcessedFile] = useState(null)
  const [checkContinue, setCheckContinue] = useState(false)

  const type = ['.xlsx', '.xls', '.csv']

  const onFilesChange = (files) => {
    setFile(files[0])
    setErrors([])
  }

  const onFilesError = (error) => notify(error.message, 'error')

  const setError = (errors, checkContinue) => {
    if (checkContinue) {
      setErrors(errors)
      setCheckContinue(true)
    } else {
      setErrors(errors)
      setCheckContinue(null)
    }
  }

  const sendFile = () => {
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    let fileData = new FormData()
    fileData.append('list', file)

    setLoading(true)

    axios
      .post('person/import', fileData)
      .then((res) => {
        if (res.data.ProcessFileResult.HasMajorErrors) {
          setError(res.data.ProcessFileResult.ProcessingLog)
          throw new Error(t('error.uploadError'))
        }

        if (res.data.ProcessFileResult.HasMinorErrors) {
          setError(res.data.ProcessFileResult.ProcessingLog, true)
          setProcessedFile(res.data)
          return
        }

        history.push(`${BASE_PATH}import/link`, res.data)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        axios.defaults.headers['Content-Type'] = 'application/json'
      })
  }

  return (
    <div className="upload">
      <p className="upload-warning">
        {t('import.upload.description')}

        <br />

        {t('import.upload.warning')}
      </p>

      {type.length > 0 && (
        <Files
          className="files-dropzone"
          onChange={onFilesChange}
          onError={onFilesError}
          accepts={type}
          multiple={false}
          maxFileSize={5000000}
          minFileSize={0}
          clickable
        >
          <div className="upload-zone">
            <label className="upload-zone-label">
              <FaUpload /> {t('import.upload.fileDesc')}
            </label>

            {file && (
              <div className="upload-zone-file">
                <div className="upload-zone-file-item">
                  <div className="upload-zone-file-icon">
                    <FileIcon extension={file.extension} />
                  </div>
                  <label className="upload-zone-file-label">{file.name}</label>
                </div>
              </div>
            )}
          </div>
        </Files>
      )}

      {errors.length > 0 && <ProcessingLog logs={errors} />}

      {checkContinue && (
        <div className="upload-minor-error">
          <p className="upload-minor-error-warning">
            {t('import.upload.checkContinue.warning')}
          </p>

          <button
            className="btn upload-btn upload-minor-error-btn"
            onClick={() => {
              setFile(null)
              setErrors([])
              setCheckContinue(false)
            }}
          >
            {t('import.upload.checkContinue.again')}
          </button>

          <button
            className="btn upload-btn"
            onClick={() =>
              history.push(`${BASE_PATH}import/link`, processedFile)
            }
          >
            {t('import.upload.checkContinue.continue')}
          </button>
        </div>
      )}

      {file && !checkContinue && (
        <button className="btn upload-btn" onClick={sendFile}>
          {t('import.upload.btnImport')}
        </button>
      )}

      {loading && <Loader noMargin />}

      <AccordionItem title={t('import.processing.title')}>
        <ProcessingFiles />
      </AccordionItem>
    </div>
  )
}

export default withTranslation()(withRouter(Upload))
