import {
  Badge,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@material-ui/core'

import { withTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import { BASE_PATH } from 'router'

import CustomBadge from 'components/CustomBadge'

import useStyles from '../Menu/style'
import { useMemo } from 'react'

const MenuListItem = ({
  children,
  item,
  index,
  type,
  closedMenu,
  onClick,
  t
}) => {
  const classes = useStyles()
  const location = useLocation()

  const dataTestIdMap = {
    item: `menu_item_${item.id}`,
    children: `menu_child_${item.id}`,
    itemWithSub: `menu-item-${item.id}`
  }

  const isActive = useMemo(() => {
    return {
      itemWithSub:
        location.pathname.indexOf(BASE_PATH + item.id) > -1
          ? classes.itemActive
          : '',
      item: !!location.pathname.match(
        new RegExp(BASE_PATH + item.id + '(\\/|$)', 'g')
      )
        ? classes.active
        : '',
      children: !!location.pathname.match(
        new RegExp(BASE_PATH + item.id + '(\\/|$)', 'g')
      )
        ? classes.active
        : ''
    }[type]
  }, [classes.active, classes.itemActive, item.id, location.pathname, type])

  const showBadge = (hasBadge, closed) => {
    if (hasBadge && closed) return true
    if (hasBadge && !closed) return false

    if (!hasBadge && closed) return true
    if (!hasBadge && !closed) return true
  }

  return (
    <ListItem
      button
      component={NavLink}
      id={'menu_' + item.id}
      to={
        type !== 'itemWithSub'
          ? BASE_PATH + item.path
          : location.pathname + location.search
      }
      key={item.id + '-' + index}
      data-testid={dataTestIdMap[type]}
      onClick={type === 'itemWithSub' ? onClick : () => {}}
      className={`
        ${isActive}
        ${type === 'children' && closedMenu ? classes.nested : ''}
        ${classes.listItem}
      `}
    >
      <Tooltip placement="right" title={closedMenu ? '' : t(item.title)}>
        <ListItemIcon className={type !== 'itemWithSub' ? isActive : ''}>
          <Badge
            color="secondary"
            variant="dot"
            invisible={showBadge(item.badge, closedMenu)}
          >
            {item.icon}
          </Badge>
        </ListItemIcon>
      </Tooltip>

      <ListItemText primary={t(item.title)} />

      {closedMenu && item.badge && (
        <CustomBadge color={'error'} content={t('stars.badge.text')} />
      )}

      {children}
    </ListItem>
  )
}

export default withTranslation()(MenuListItem)
