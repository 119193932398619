import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const Container = styled.div(({ margin }) => ({
  display: 'flex',
  width: '100%',
  margin: margin ?? '0px'
}))

export const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  tableContainer: {
    width: '100%'
  },
  table: {
    width: '100%'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: 'rgba(0, 174, 239, 0.05)',
      '& $tableRowIcon': {
        color: '#00aeef'
      },
      '& $tableRowNameClickable': {
        color: '#00aeef',
        textDecoration: 'underline',
        cursor: 'pointer'
      },
      '& $tableRowAction': {
        opacity: 1
      }
    }
  },
  tableRowIcon: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  tableRowName: {
    margin: 'auto 4px',
    color: '#000000',
    textDecoration: 'none'
  },
  tableRowNameClickable: {},
  tableRowAction: {
    opacity: 0
  },
  tableHead: {
    width: '100%',
    backgroundColor: '#fff'
  },
  row: {
    padding: '0px 16px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  scoreCircleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  link: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    margin: 'auto 0px'
  }
})

export default useStyles
