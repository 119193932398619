import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { DateRange } from 'react-date-range'
import { useLocation } from 'react-router-dom'
import { sub } from 'date-fns'
import { withTranslation } from 'react-i18next'

import { currentLocale } from 'core/functions/format-date.function'
import getCurrentDate from 'core/functions/get-current-date.function'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const Calendar = ({ onSelect, maxDate, i18n }) => {
  const [currentLang, setCurrentLang] = useState(i18n.language)
  const [locale, setLocale] = useState(currentLocale(currentLang))
  const location = useLocation()

  const [{ from, to }] = useState(getCurrentDate(location))
  const [state, setState] = useState([
    {
      startDate: from ?? sub(new Date(), { years: 10 }),
      endDate: to ?? new Date(),
      key: 'selection'
    }
  ])

  const max = maxDate ? maxDate : false

  useEffect(() => setCurrentLang(i18n.language), [i18n])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onSelect(state), [state])

  useEffect(() => setLocale(currentLocale(i18n.language)), [i18n])

  return (
    <DateRange
      maxDate={max}
      ranges={state}
      locale={locale.fn}
      editableDateInputs={true}
      moveRangeOnFirstSelection={false}
      onChange={(item) => setState([item.selection])}
    />
  )
}

Calendar.propTypes = {
  onSelect: PropTypes.func,
  maxDate: PropTypes.instanceOf(Date),
  date: PropTypes.object
}

Calendar.defaultProps = {
  onSelect: () => {},
  maxDate: false
}

export default withTranslation()(Calendar)
