import { withTranslation } from 'react-i18next'

import '../../style/component/app-status/loader-text.css'

const LoaderText = ({ t }) => (
  <div className="loading-text">
    {t('component.loader')}
    <span className="loading-text-dots"></span>
  </div>
)

export default withTranslation()(LoaderText)
