import { withTranslation } from 'react-i18next'
import { useState, useMemo, useEffect } from 'react'
import Header from 'components/Header'
import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'
import DateRangePicker from 'components/DateRangePicker'
import DropdownCampaign from 'components/Dropdown/DropdownCampaign'
import ViewTypeSelector from './components/ViewTypeSelector'
import InfoBar from './components/InfoBar'
import { Divider } from '@material-ui/core'
import { useStyles, ViewModeContainer } from './style'
import Evolution from './components/Evolution'
import DimensionList from './components/DimensionList'
import useGetData from 'core/hooks/useGetData'
import queryString from 'query-string'
import { DATE_INTERVAL } from 'core/enums/date-interval'
import { QS_ACTIVE, QS_ORDENATION } from 'core/constants/query-strings'
import { ORDENATION_TYPE } from 'core/enums/ordenation-type'
import { QS_PAGE } from 'core/constants/query-strings'
import { QS_PAGE_SIZE } from 'core/constants/query-strings'
import { QS_DATE_INTERVAL } from 'core/constants/query-strings'
import { QS_DATE_FROM } from 'core/constants/query-strings'
import ViewMode from './dictionaries/view-mode'
import { isEqual } from 'lodash'
import { QS_ID_DIMENSION } from 'core/constants/query-strings'
import { QS_ID_DIMENSION_LIST } from 'core/constants/query-strings'
import { QS_DATE_TO } from 'core/constants/query-strings'
import { QS_CAMPAIGN } from 'core/constants/query-strings'
import { QS_CAMPAIGN_LIST } from 'core/constants/query-strings'
import { QS_TYPES } from 'core/constants/query-strings'
import Matrix from './components/Matrix'
import { QS_DIMENSION } from 'core/constants/query-strings'
import TreeView from './components/TreeView'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import useExecuteCallbackOnEvent from 'core/hooks/useExecuteCallbackOnEvent'
import eventBus from 'core/events'

const Nps = ({ history, location, match, t }) => {
  const classes = useStyles()

  const [answersDateFilter, setAnswersDateFilter] = useState({
    from: '',
    to: ''
  })

  useEffect(() => {
    setAnswersDateFilter({
      [QS_DATE_FROM]: '',
      [QS_DATE_TO]: ''
    })
  }, [match?.params?.view])

  const [histogramData, setHistogramData] = useState([])
  const [loadingHistogramData, setLoadingHistogramData] = useState(false)
  const getHistogramData = useGetData({
    endpoint: 'nps/getnpsdatehistogramwithnofiltercomparition',
    setData: setHistogramData,
    setLoading: setLoadingHistogramData,
    debounceTime: 500,
    paramsFromUrl: [
      QS_DATE_FROM,
      QS_DATE_TO,
      QS_CAMPAIGN,
      QS_ID_DIMENSION,
      QS_DATE_INTERVAL
    ]
  })

  const [decimal, setDecimal] = useState(0)
  const [loadingDecimal, setLoadingDecimal] = useState(false)
  useGetData({
    endpoint: '/StoreSettings/GetNpsScoreDecimals',
    setData: setDecimal,
    setLoading: setLoadingDecimal,
    debounceTime: 500,
    paramsFromUrl: []
  })

  const [answersData, setAnswersData] = useState([])
  const [loadingAnswersData, setLoadingAnswersData] = useState(false)
  const getAnswersDataSpecificParams = useMemo(() => {
    let params = {
      [QS_ORDENATION]: ORDENATION_TYPE.DateDesc,
      [QS_TYPES]: NPS_CSAT_TAB.nps.typeParam,
      [QS_ACTIVE]: true
    }
    if (answersDateFilter.from && answersDateFilter.to) {
      params = { ...params, ...answersDateFilter }
    }
    return params
  }, [answersDateFilter])
  const getAnswersData = useGetData({
    endpoint: 'nps/getbyfilter',
    setData: (data) => setAnswersData(data),
    setLoading: setLoadingAnswersData,
    specificParams: getAnswersDataSpecificParams,
    debounceTime: 500,
    paramsFromUrl: [
      QS_DATE_FROM,
      QS_DATE_TO,
      QS_CAMPAIGN_LIST,
      QS_ID_DIMENSION_LIST,
      QS_PAGE,
      QS_PAGE_SIZE,
      QS_TYPES,
      QS_ORDENATION
    ]
  })

  const [npsScore, setNpsScore] = useState({})
  const [loadingNpsGoal, setLoadingNpsGoal] = useState()
  const getMetaNps = useGetData({
    endpoint: 'nps/getnpsscoreconsolidation',
    setData: setNpsScore,
    setLoading: setLoadingNpsGoal,
    debounceTime: 500,
    paramsFromUrl: [QS_DATE_FROM, QS_DATE_TO, QS_CAMPAIGN]
  })

  const [dimensionsScoreData, setDimensionsScoreData] = useState({
    Items: []
  })
  const getDimensionsScoreDataSpecificParams = useMemo(() => {
    return {
      [QS_ORDENATION]: ORDENATION_TYPE.TotalDesc
    }
  }, [])
  const [loadingDimensionsScoreData, setLoadingDimensionScoresData] =
    useState(false)
  const getDimensionsScoreData = useGetData({
    endpoint: '/nps/getalldimensionscore',
    setData: (val) => {
      val.Items = val.Items.filter((item) => !!item.Dimension)
      setDimensionsScoreData(val)
    },
    setLoading: setLoadingDimensionScoresData,
    debounceTime: 500,
    specificParams: getDimensionsScoreDataSpecificParams,
    paramsFromUrl: [
      QS_DATE_FROM,
      QS_DATE_TO,
      QS_CAMPAIGN,
      QS_PAGE,
      QS_PAGE_SIZE,
      QS_DIMENSION
    ]
  })

  const [isMounted, setIsMounted] = useState(true)
  useEffect(() => {
    setIsMounted(true)

    return () => {
      setIsMounted(false)
    }
  }, [])

  const [valueScoreData, setValueScoreData] = useState({
    Items: []
  })
  const getValueScoreDataSpecificParams = useMemo(() => {
    return {
      [QS_ORDENATION]: ORDENATION_TYPE.TotalDesc
    }
  }, [])
  const [loadingValueScoreData, setLoadingValueScoreData] = useState(false)
  const getValueScoreData = useGetData({
    endpoint: 'nps/getnpsscoreevolutionbydimension/withtotals',
    setData: (val) => {
      val.Items = val.Items.filter((item) => !!item.DimensionValue)
      if (isMounted) setValueScoreData(val)
    },
    setLoading: setLoadingValueScoreData,
    debounceTime: 500,
    specificParams: getValueScoreDataSpecificParams,
    paramsFromUrl: [
      QS_DATE_FROM,
      QS_DATE_TO,
      QS_DIMENSION,
      QS_CAMPAIGN,
      QS_PAGE,
      QS_PAGE_SIZE
    ]
  })

  const getEvolutionData = () => {
    getAnswersData.getData()
    getHistogramData.getData()
  }
  const getListData = () => {
    getDimensionsScoreData.getData()
    getValueScoreData.getData()
  }
  const getMatrixData = () => {
    getDimensionsScoreData.getData()
    getMetaNps.getData()
    getValueScoreData.getData()
  }
  const getTreeData = () => {
    getDimensionsScoreData.getData()
    getValueScoreData.getData()
  }

  useExecuteCallbackOnEvent(
    [ViewMode.evolution, ViewMode.list, ViewMode.matrix, ViewMode.tree],
    [getEvolutionData, getListData, getMatrixData, getTreeData]
  )

  const handleUpdateData = (viewMode) => eventBus.dispatch(viewMode)

  const getCurrentViewBasedOnRoute = useMemo(() => {
    return {
      [ViewMode.evolution]: (
        <Evolution
          loadingAnswersData={loadingAnswersData}
          answersData={answersData}
          decimal={decimal}
          loadingDecimal={loadingDecimal}
          histogramData={histogramData}
          loadingHistogramData={loadingHistogramData}
          onUpdateAnswersDateFilter={setAnswersDateFilter}
        />
      ),
      [ViewMode.list]: (
        <DimensionList
          decimal={decimal}
          loadingDecimal={loadingDecimal}
          loadingDimensionsScoreData={loadingDimensionsScoreData}
          dimensionsScoreData={dimensionsScoreData}
          valueScoreData={valueScoreData}
          loadingValueScoreData={loadingValueScoreData}
        />
      ),
      [ViewMode.tree]: (
        <TreeView
          decimal={decimal}
          loadingDecimal={loadingDecimal}
          dimensionsScoreData={dimensionsScoreData}
          loadingDimensionsScoreData={loadingDimensionsScoreData}
          valueScoreData={valueScoreData}
          loadingValueScoreData={loadingValueScoreData}
        />
      ),
      [ViewMode.matrix]: (
        <Matrix
          decimal={decimal}
          loadingDecimal={loadingDecimal}
          dimensionsScoreData={dimensionsScoreData}
          loadingDimensionsScoreData={loadingDimensionsScoreData}
          valueScoreData={valueScoreData}
          loadingValueScoreData={loadingValueScoreData}
          npsGoal={npsScore?.ScoreGoal}
          loadingNpsGoal={loadingNpsGoal}
        />
      )
    }[match?.params?.view]
  }, [
    loadingAnswersData,
    answersData,
    decimal,
    loadingDecimal,
    histogramData,
    loadingHistogramData,
    loadingDimensionsScoreData,
    dimensionsScoreData,
    valueScoreData,
    loadingValueScoreData,
    npsScore?.ScoreGoal,
    loadingNpsGoal,
    match?.params?.view
  ])

  const currentSearch = useMemo(() => {
    return queryString.parse(location?.search)
  }, [location?.search])

  useEffect(() => {
    setTimeout(() => {
      if (!currentSearch[QS_DATE_INTERVAL]) {
        currentSearch[QS_DATE_INTERVAL] = DATE_INTERVAL.day
      }
      if (!currentSearch[QS_PAGE]) {
        currentSearch[QS_PAGE] = '1'
      }
      if (!currentSearch[QS_PAGE_SIZE]) {
        currentSearch[QS_PAGE_SIZE] = '30'
      }

      if ([ViewMode.tree, ViewMode.matrix].includes(match?.params?.view)) {
        if (currentSearch[QS_PAGE] !== '1') {
          currentSearch[QS_PAGE] = '1'
        }
        if (currentSearch[QS_PAGE_SIZE] !== '30') {
          currentSearch[QS_PAGE_SIZE] = '30'
        }
      }

      if (!isEqual(currentSearch, queryString.parse(location?.search))) {
        history?.push({
          pathname: location.pathname,
          search: queryString.stringify(currentSearch)
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch, match?.params?.view])

  useEffect(() => {
    setAnswersDateFilter({
      [QS_DATE_FROM]: '',
      [QS_DATE_TO]: ''
    })
  }, [match?.params?.view])

  return (
    <div>
      <Header
        type="filters"
        title={t('nps-reports.title')}
        data-testid="nps-report-header"
        items={
          <Filters>
            <FilterItem>
              <DateRangePicker select data-testid="nps-report-datepicker" />
            </FilterItem>

            <FilterItem>
              <DropdownCampaign
                data-testid="nps-report-campaign-dropdown"
                type={NPS_CSAT_TAB.nps.typeValue}
                campaignAllSelected
              />
            </FilterItem>
          </Filters>
        }
      />

      <InfoBar
        dimensionsScoreData={dimensionsScoreData}
        loadingDimensionsScoreData={loadingDimensionsScoreData}
        handleUpdateData={handleUpdateData}
        decimal={decimal}
        viewMode={match?.params?.view}
        data-testid="nps-report-info-bar"
        npsScore={npsScore}
      />

      <Divider className={classes.divider} data-testid="nps-report-divider" />

      {match?.params?.view !== ViewMode.evolution && (
        <ViewTypeSelector data-testid="nps-report-view-type-selector" />
      )}

      <ViewModeContainer>{getCurrentViewBasedOnRoute}</ViewModeContainer>
    </div>
  )
}

export default withTranslation()(Nps)
