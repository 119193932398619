import { useCallback, useState, useEffect } from 'react'

import { useDropzone } from 'react-dropzone'

import { withTranslation } from 'react-i18next'

import {
  Container,
  Title,
  Description,
  ImageBox,
  Image,
  Arrow,
  BorderLinearProgress,
  ProgressLabel,
  UploadLabel
} from './style'

import BigButton from 'components/BigButton'
import Add from '@material-ui/icons/Add'

import errorImg from 'assets/img/error.svg'
import arrowImg from 'assets/img/arrow.svg'
import cloudsImg from 'assets/img/clouds.svg'
import defaultImg from 'assets/img/import.svg'
import successImg from 'assets/img/success.svg'

import { Button } from '@material-ui/core'
import eventBus from 'core/events'
import { CancelPostEvent } from 'modules/Campaign/components/PeopleList'
import { ResetImportEvent } from 'modules/Campaign/components/PeopleList'

const ImportFile = ({ onUpload, cancelPost, t }) => {
  const [file, setFile] = useState(null)
  const handleSetFile = useCallback((value) => setFile(value), [])

  const [uploading, setUploading] = useState(false)
  const handleSetUploading = useCallback((value) => setUploading(value), [])

  const [sizeError, setSizeError] = useState(false)
  const handleSetSizeError = useCallback((value) => setSizeError(value), [])

  const [typeError, setTypeError] = useState(false)
  const handleSetTypeError = useCallback((value) => setTypeError(value), [])

  const [quantityError, setQuantityError] = useState(false)
  const handleSetQuantityError = useCallback(
    (value) => setQuantityError(value),
    []
  )

  const [uploadSuccess, setUploadSuccess] = useState(false)
  const handleSetUploadSuccess = useCallback((value) => {
    setUploadSuccess(value)
  }, [])

  const [percent, setPercent] = useState(0)
  const handleSetPercent = useCallback((value) => {
    setPercent(value)
  }, [])
  const [fileSize, setFileSize] = useState(0)
  const handleSetFileSize = useCallback((value) => {
    setFileSize((value / 1000).toFixed(2)) // bytes to kbytes
  }, [])

  const onUploadProgress = useCallback(
    (progressEvent) => {
      handleSetPercent(
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      )
    },
    [handleSetPercent]
  )
  const resetErrors = useCallback(() => {
    handleSetTypeError(false)
    handleSetSizeError(false)
    handleSetQuantityError(false)
  }, [handleSetQuantityError, handleSetSizeError, handleSetTypeError])

  const onDrop = useCallback(
    (acceptedFiles) => {
      resetErrors()

      acceptedFiles.forEach((file) => {
        handleSetUploading(true)
        handleSetFileSize(file.size)
        onUpload(file, onUploadProgress, handleSetUploadSuccess)
      })

      handleSetFile(acceptedFiles[0])
    },
    [
      onUpload,
      resetErrors,
      handleSetFile,
      onUploadProgress,
      handleSetFileSize,
      handleSetUploading,
      handleSetUploadSuccess
    ]
  )

  const { getRootProps, getInputProps, fileRejections, isDragActive, open } =
    useDropzone({
      maxFiles: 1,
      maxSize: 5242880,
      accept: '.csv, .xls, .xlsx',
      onDrop,
      noClick: true,
      noKeyboard: true
    })

  const [errors, setErrors] = useState(<></>)

  const handleCancel = () => {
    cancelPost && cancelPost()
    setFile(null)
    resetErrors()
  }

  useEffect(() => {
    const ErrorCodeMap = {
      'file-invalid-type': t('importFile.fileTypeError'),
      'file-too-large': t('importFile.fileSizeError'),
      'too-many-files': t('importFile.fileQuantityError')
    }

    // eslint-disable-next-line array-callback-return
    fileRejections.map(({ file, errors }) => {
      handleSetTypeError(true)
      handleSetSizeError(true)
      handleSetQuantityError(true)

      setErrors(errors.map((e) => <li key={e.code}>{ErrorCodeMap[e.code]}</li>))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRejections])

  useEffect(() => {
    eventBus.on(CancelPostEvent, () => {
      resetErrors()
      handleSetUploading(false)
    })

    eventBus.on(ResetImportEvent, () => {
      resetErrors()
      handleSetUploading(false)
      setFile(null)
    })

    return () => {
      eventBus.remove(CancelPostEvent)
      eventBus.remove(ResetImportEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasErrors = () => sizeError || typeError || quantityError

  return (
    <Container
      {...getRootProps()}
      error={hasErrors()}
      isDragActive={isDragActive}
    >
      <input {...getInputProps()} />

      <ImageBox>
        {uploading ? (
          <>
            {uploadSuccess ? (
              <>
                <Image cloud src={cloudsImg} alt="Uploading file clouds" />

                <Image success src={successImg} alt="Uploading file clouds" />
              </>
            ) : (
              <>
                <Image cloud src={cloudsImg} alt="Uploading file clouds" />

                <Arrow src={arrowImg} alt="Uploading file arrow" />
              </>
            )}
          </>
        ) : (
          <Image
            src={hasErrors() ? errorImg : defaultImg}
            alt={hasErrors() ? 'Import file with error' : 'Import file'}
          />
        )}
      </ImageBox>

      <Title>
        {!file && !isDragActive && t('importFile.title')}
        {!file && isDragActive && t('importFile.dropFile')}

        {file && !uploading && file.path}
      </Title>

      <Description error={hasErrors()}>
        {hasErrors() && errors}

        {!file && !hasErrors() && t('importFile.description')}

        {file && !uploadSuccess && (
          <UploadLabel>{t('importFile.uploadLabel')}</UploadLabel>
        )}
        {file && uploadSuccess && (
          <UploadLabel>{t('importFile.uploadLabelOnSuccess')}</UploadLabel>
        )}

        {!uploadSuccess && file && (
          <ProgressLabel>
            {t('importFile.progress', { percent, fileSize })}
          </ProgressLabel>
        )}

        {uploadSuccess && (
          <ProgressLabel>{t('importFile.pleaseWait')}</ProgressLabel>
        )}

        {file && <BorderLinearProgress variant="determinate" value={percent} />}
      </Description>

      {!file && (
        <BigButton onClick={open} icon={<Add />} title={t('importFile.btn')} />
      )}

      {!uploadSuccess && file && uploading && (
        <Button color="primary" onClick={handleCancel}>
          {t('importFile.cancel')}
        </Button>
      )}
    </Container>
  )
}

export default withTranslation()(ImportFile)
