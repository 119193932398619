import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import { useStyles } from './style'
import {
  FormControl,
  ListItemIcon,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import { ViewQuilt, ViewList, BorderOuter } from '@material-ui/icons'
import { BASE_PATH } from 'router'
import ViewMode from '../../dictionaries/view-mode'

const MenuItemWithIcon = ({ type, viewType }) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const ItemMap = {
    list: {
      icon: (
        <ViewList
          color={viewType === 'list' ? 'primary' : 'disabled'}
          fontSize="small"
        />
      ),
      title: t('nps-reports.viewType.list'),
      color: viewType === 'list' ? 'primary' : 'disabled'
    },
    tree: {
      icon: (
        <ViewQuilt
          color={viewType === 'tree' ? 'primary' : 'disabled'}
          fontSize="small"
        />
      ),
      title: t('nps-reports.viewType.tree'),
      color: viewType === 'tree' ? 'primary' : 'disabled'
    },
    matrix: {
      icon: (
        <BorderOuter
          color={viewType === 'matrix' ? 'primary' : 'disabled'}
          style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
          fontSize="small"
        />
      ),
      title: t('nps-reports.viewType.matrix'),
      color: viewType === 'matrix' ? 'primary' : 'disabled'
    }
  }

  return (
    <div className={classes.menuItem}>
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        {ItemMap[type].icon}
      </ListItemIcon>
      <div className={classes.typography}>
        <Typography color={ItemMap[type].color} variant="inherit">
          {ItemMap[type].title}
        </Typography>
      </div>
    </div>
  )
}

const ViewTypeSelector = () => {
  const classes = useStyles()
  const { search } = useLocation()
  const { push } = useHistory()
  const params = useParams()

  const makeRoute = (route) => BASE_PATH + route + search

  const RouteMap = {
    list: makeRoute('reports/nps/' + ViewMode.list),
    tree: makeRoute('reports/nps/' + ViewMode.tree),
    matrix: makeRoute('reports/nps/' + ViewMode.matrix)
  }

  const handleRouteChange = (chart) => push(RouteMap[chart])

  const viewType = useMemo(() => {
    return params.view ?? ViewMode.list
  }, [params])

  const handleChangeViewType = (event) => {
    handleRouteChange(event.target.value)
  }

  return (
    <div>
      <FormControl
        data-testid="view-type-chart-form-control"
        className={classes.formControl}
      >
        <Select
          classes={{
            root: classes.select,
            selectMenu: classes.selectMenu,
            icon: classes.icon
          }}
          data-testid="view-type-chart-select"
          labelId="view-type-chart-select"
          id="view-type-chart-select"
          value={viewType}
          onChange={handleChangeViewType}
          disableUnderline
          color="primary"
        >
          <MenuItem value="list" data-testid="view-type-chart-list-item">
            <MenuItemWithIcon viewType={viewType} type="list" />
          </MenuItem>

          <MenuItem value="tree" data-testid="view-type-chart-list-item">
            <MenuItemWithIcon viewType={viewType} type="tree" />
          </MenuItem>

          <MenuItem value="matrix" data-testid="view-type-chart-list-item">
            <MenuItemWithIcon viewType={viewType} type="matrix" />
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}

export default ViewTypeSelector
