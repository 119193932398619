import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;

  text-align: center;
  align-items: center;

  ${({ bgImage }) => `background-image: url("${bgImage}") !important;`}
  ${({ bgColor }) => `background-color: ${bgColor};`}
  ${({ color }) => `color: ${color ?? '#333'};`}

  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
`
export const Logo = styled.img`
  max-height: 70px;
  margin-top: 30px;
`
export const Header = styled.img`
  margin: -20px -20px 20px -20px;
  max-width: 629.72px;
  width: calc(100% + 40px);
`
export const Title = styled.h2`
  margin: 10px 0px;
  ${({ color }) => `color: ${color ?? '#000'};`}

  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
`
export const Legend = styled.p`
  ${({ color }) => `color: ${color ?? '#000'};`}
  margin: 10px 0px;
  max-width: 450px;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
`

export const FormBox = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;

  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  background-color: #fff;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`
export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;

  margin: 10px 10px;
  border-radius: 20px;
  background-color: #fff;
`

export const SkeletonBox = styled.div`
  @media screen and (max-width: 359px) {
    min-width: 100px;
  }

  @media screen and (min-width: 360px) and (max-width: 767px) {
    min-width: 300px;
  }

  @media screen and (min-width: 768px) {
    min-width: 630px;
  }
`
