import { createContext, useCallback, useContext, useState } from 'react'

const MatrixContext = createContext()

export const MatrixProvider = ({ children }) => {
  const [quadrant, setQuadrant] = useState({})
  const handleSetQuadrant = useCallback((data) => {
    setQuadrant(data)
  }, [])

  const cleanQuadrantContext = () => {
    handleSetQuadrant({})
  }

  return (
    <MatrixContext.Provider
      value={{
        quadrant,
        setQuadrant,
        cleanQuadrantContext
      }}
    >
      {children}
    </MatrixContext.Provider>
  )
}

export const useMatrix = () => {
  const context = useContext(MatrixContext)

  const { quadrant, handleSetQuadrant, cleanQuadrantContext } = context

  return {
    quadrant,
    handleSetQuadrant,
    cleanQuadrantContext
  }
}
