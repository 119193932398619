import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'

import Rating from 'react-rating'

export const RatingTable = ({ classes, t, data }) => {
  return (
    <Grid item xs>
      <Box display="flex" justifyContent="center" alignItems="center">
        <TableContainer className={classes.ratingTable} component={Paper}>
          <Table stickyHeader className={classes.ratingTable} size="small">
            <TableHead>
              <TableRow>
                <TableCell>{''}</TableCell>
                <TableCell align="right">
                  {t('dimension.table.answers')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.Values.map((x, index) => (
                <Tooltip title={`${x?.DimensionValue}`}>
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <Rating
                        readonly
                        initialRating={x.DimensionValueAsInt}
                        fullSymbol={
                          <StarIcon color="primary" fontSize="inherit" />
                        }
                        emptySymbol={
                          <StarBorderIcon color="primary" fontSize="inherit" />
                        }
                      />
                    </TableCell>
                    <TableCell align="right">{x.Count}</TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  )
}
