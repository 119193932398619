import { QS_ID_DIMENSION_LIST } from 'core/constants/query-strings'
import { QS_DIMENSION } from 'core/constants/query-strings'
import { QS_ID_DIMENSION } from 'core/constants/query-strings'
import queryString from 'query-string'

export function getSearchWithoutDimensionId(search) {
  const newSearch = queryString.parse(search)

  delete newSearch[QS_ID_DIMENSION]
  delete newSearch[QS_ID_DIMENSION_LIST]
  delete newSearch[QS_DIMENSION]

  return queryString.stringify(newSearch)
}

export function getSearchWithDimensionId(search, dimensionKey, dimensions) {
  const newSearch = queryString.parse(search)

  const dimensionId = dimensions?.find(
    (d) => d.Dimension === dimensionKey
  )?.DimensionId

  if (dimensionId) {
    newSearch[QS_ID_DIMENSION] = dimensionId
    newSearch[QS_ID_DIMENSION_LIST] = dimensionId
    newSearch[QS_DIMENSION] = dimensionKey
  }

  return queryString.stringify(newSearch)
}
