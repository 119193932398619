import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 100,
    color: '#fff'
  }
}))

export default useStyles
