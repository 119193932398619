import styled from 'styled-components'

export const Title = styled.h4`
  display: flex;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.7);

  margin-top: 12px;
  margin-bottom: 12px;
`
export const Subtitle = styled.span`
  display: flex;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.7);
`
export const Picture = styled.img`
  margin-top: 100px;
  width: 500px;
`
