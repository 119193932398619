import Yup from './index'
import i18n from '../../lang'

function passwordPattern() {
  return Yup.string()
    .min(8)
    .max(50)
    .required()
    .matches(/(?=.*[a-z]).*$/g, i18n.t('form.error.passwordTiny'))
    .matches(/(?=.*[A-Z]).*$/g, i18n.t('form.error.passwordUppercase'))
    .matches(/(?=.*\d).*$/g, i18n.t('form.error.passwordNumber'))
    .matches(/(?=.*\W+).*$/g, i18n.t('form.error.passwordCharacter'))
}

function passwordAgainPattern(ref) {
  return Yup.string()
    .required()
    .oneOf([Yup.ref(ref), null], i18n.t('form.error.passwordAgain'))
}

function emailPattern() {
  return Yup.string().email().max(80).required()
}

function namePattern() {
  return Yup.string().min(3).max(80).required()
}

function companyPattern() {
  return Yup.string().min(2).max(240).required()
}

var password = passwordPattern()
var passwordAgain = passwordAgainPattern('password')
var PasswordAgain = passwordAgainPattern('Password')
var email = emailPattern()
var name = namePattern()
var company = companyPattern()

i18n.on('languageChanged', function () {
  password = passwordPattern()
  passwordAgain = passwordAgainPattern('password')
  PasswordAgain = passwordAgainPattern('Password')
  email = emailPattern()
  name = namePattern()
  company = companyPattern()
})

export { password, passwordAgain, PasswordAgain, email, name, company }
