const AdminMenuItems = [
  {
    click: ({ edit }) => edit(),
    title: ({ t }) => t('admin.item.title')
  },
  {
    click: ({ changePassword }) => changePassword(),
    title: ({ t }) => t('admin.item.password')
  },
  {
    click: ({ changeStatus }) => changeStatus(),
    title: ({ admin, t }) =>
      admin.Active ? t('admin.item.inactive') : t('admin.item.active')
  },
  {
    click: ({ setNotifications }) => setNotifications(),
    title: ({ t }) => t('admin.item.notifications')
  }
]

export default AdminMenuItems
