import TypeSuggestionEnum from '../enums/typeSuggestionEnum'

/**
 *  * Set the initial value based on TypeSuggestion
 *
 * @param {number} type TypeSuggestion from current item
 * @param {Function} setState Function to set state based on type
 * @param {number} dimensionId Dimension Id from current item
 * @param {Array<Object>} dimensions Dimension list for find current dimensions
 */
const getCurrentTypeSugestion = (type, dimensionId, dimensions, index) => {
  switch (type) {
    case TypeSuggestionEnum.Name:
      return { Name: 'Nome', Index: index, Key: 'name', DefaultType: true }

    case TypeSuggestionEnum.Email:
      return { Name: 'E-mail', Index: index, Key: 'email', DefaultType: true }

    case TypeSuggestionEnum.Phone:
      return { Name: 'Telefone', Index: index, Key: 'tel', DefaultType: true }

    case TypeSuggestionEnum.Dimension:
      return {
        ...dimensions?.find((x) => x.IdYourviews === dimensionId),
        Index: index
      }

    default:
      return { Index: index }
  }
}

export default getCurrentTypeSugestion
