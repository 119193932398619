import styled from 'styled-components'

export const Modal = styled.div``

export const OptionLabel = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #9e9e9e;
  width: 60px;
  margin-right: 5px;
`

export const OptionValue = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #757575;
  display: flex;
`

export const StatusLabel = styled.div`
  margin-right: 4px;
  font-weight: 500;
  font-size: 14px;
`
