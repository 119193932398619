import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DateRangePicker from '../../../../../components/DateRangePicker'
import { withTranslation } from 'react-i18next'
import useStyles from './style'
import { Field, Form, Formik } from 'formik'
import { getItemText } from 'core/functions/get-qs-value.function'
import { QS_CAMPAIGN } from 'core/constants/query-strings'
import setFilter from 'core/functions/setFilter'
import { useHistory, useLocation } from 'react-router-dom'
import DropdownField from 'components/Form/Option/DropdownField'
import { campaign } from 'core/helpers'

function Filter({ campaigns, filterState, handleFilterState, t }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  return (
    <Drawer
      anchor="right"
      open={filterState}
      className={classes.drawer}
      onClose={handleFilterState}
    >
      <div className={classes.filter}>
        <div className={classes.filterHeader}>
          <IconButton onClick={handleFilterState}>
            <ArrowBackIcon color="primary" />
          </IconButton>
          <h1 className={classes.filterTitle}>
            {t('word-cloud.titles.filter')}
          </h1>
        </div>

        <Formik
          initialValues={{
            [QS_CAMPAIGN]: getItemText(QS_CAMPAIGN)
              ? parseInt(getItemText(QS_CAMPAIGN))
              : '',
            period: ''
          }}
          onSubmit={(values) => {
            if (values.period) {
              values.from = values.period?.from
              values.to = values.period?.to
              delete values.period
            }

            setFilter({ history, location }, values, handleFilterState, true)
          }}
        >
          <Form>
            <div className={classes.field}>
              <Field
                name="period"
                id="period"
                data-testid="period-filter"
                defaultSelection
                component={DateRangePicker}
                label={t('word-cloud.titles.period')}
              />
            </div>

            <div className={classes.field}>
              <Field
                id={QS_CAMPAIGN}
                name={QS_CAMPAIGN}
                component={DropdownField}
                label={t('answer.filter.byCampaign')}
                data={campaign.toDropdown(
                  campaigns,
                  t('component.dropdown.campaignAll')
                )}
                className="yv-test-home"
              />
            </div>

            <div className={classes.field}>
              <div className={classes.stopWordsInput}></div>
            </div>

            <div className={classes.actions}>
              <Button
                color="default"
                variant="contained"
                onClick={handleFilterState}
                className={classes.buttons}
              >
                {t('uiActions.cancel')}
              </Button>

              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={classes.buttons}
              >
                {t('uiActions.apply')}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </Drawer>
  )
}

export default withTranslation()(Filter)
