import _ from 'lodash'

const NULL_UNDEFINED = /(null|undefined)/g

const makeRowString = (string) => {
  if (!string) return '-'

  let newString = ''
  newString += string.map((x) => ` ${x}`)

  if (newString === '') newString = '-'

  return _.trim(
    newString
      .replace(NULL_UNDEFINED, '-')
      .replace(/-,/g, '')
      .replace(/\s\s/g, '')
  )
}

export default makeRowString
