import { useCallback, useState, useEffect, useMemo } from 'react'
import queryString from 'query-string'
import i18next from 'i18next'
import qs from 'query-string'
import { useAppContext } from '../../../App'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import api from 'core/api'
import Header from 'components/Header'
import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'
import Filter from './components/Filter'
import axios from 'core/api'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { FilterList } from '@material-ui/icons'
import useStyles, {
  Column,
  Content,
  Container,
  Divider,
  Dropdowns,
  Empty,
  Field,
  Image,
  Item,
  List,
  ListItem,
  Rating,
  Row,
  SubTitle,
  Title
} from './style'
import Loader from 'components/Loader'
import BigButton from 'components/BigButton'
import empty from 'assets/img/empty.svg'
import HelpModal from './components/HelpModal'
import DimensionChart from './components/DimensionChart'

const Stars = (props) => {
  const { notify } = useAppContext()
  const history = useHistory()
  const classes = useStyles()

  const [campaigns, setCampaigns] = useState([])
  const getCampaigns = useCallback(() => {
    axios.get('campaign/getall').then((response) => {
      setCampaigns(response.data)
    })
  }, [])
  useEffect(() => {
    getCampaigns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { search, pathname } = useLocation()

  const { language } = i18next

  const [filterState, setFilterState] = useState(false)
  const handleFilter = useCallback(() => {
    setFilterState(!filterState)
  }, [filterState])

  const [data, setData] = useState(null)
  const [loadingData, setLoadingData] = useState(false)

  const getData = async (endpoint, setData, setLoading) => {
    setLoading(true)

    try {
      const { data } = await api.get(endpoint)

      if (data) setData(data)
    } catch (error) {
      notify(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  const [dimensionList, setDimensionList] = useState(null)
  const [loadingDimensionList, setLoadingDimensionList] = useState(false)

  const [dimensionRatingList, setDimensionRatingList] = useState(null)
  const [loadingDimensionRatingList, setLoadingDimensionRatingList] =
    useState(false)

  useEffect(() => {
    getData(
      'dimension/getallplain?TypeFilter=Rating',
      setDimensionRatingList,
      setLoadingDimensionRatingList
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const hasValueInsideString = (string) => string && string.length > 0

  const [rating, setRating] = useState('')
  const handleChangeRating = (value) => {
    if (value) {
      setDimension('')
      setRating(value)

      const currentSearch = qs.parse(search)
      delete currentSearch.DimensionKey
      currentSearch.RatingDimensionKey = value

      history.push({
        pathname,
        search: qs.stringify(currentSearch)
      })
    }
  }

  const [dimension, setDimension] = useState('')
  const handleChangeDimension = (value) => {
    if (value) {
      setDimension(value)

      const currentSearch = qs.parse(search)
      currentSearch.DimensionKey = value

      history.push({
        pathname,
        search: qs.stringify(currentSearch)
      })
    }
  }

  useEffect(() => {
    if (rating) {
      getData(
        `dimension/GetRelated?DimensionKey=${rating}&TypeFilter=SingleSelect&TypeFilter=MultiSelect&TypeFilter=Text&NpsIndexingType=referencedate`,
        setDimensionList,
        setLoadingDimensionList
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rating])

  useEffect(() => {
    getData(
      `/dimension/getratingscorebydimension/data${search}`,
      ({ Items }) => setData(Items),
      setLoadingData
    )

    if (rating) {
      const searchObjWithoutRatingDimensionKey = queryString.parse(search)
      searchObjWithoutRatingDimensionKey.DimensionKey =
        searchObjWithoutRatingDimensionKey.RatingDimensionKey
      delete searchObjWithoutRatingDimensionKey.RatingDimensionKey
      const searchStringWithoutRatingDimensionKey =
        '?' + queryString.stringify(searchObjWithoutRatingDimensionKey)

      getData(
        `dimension/getdimensionchartdata${searchStringWithoutRatingDimensionKey}`,
        setChartData,
        setLoadingChartData
      )

      getData(
        `nps/getnpsscoreevolutionbydimension/withtotals${searchStringWithoutRatingDimensionKey}&PageSize=99999`,
        (response) => {
          let total = 0
          response.Items?.forEach((item) => {
            if (item.TotalCount) {
              total += item.TotalCount
            }
          })

          setTotals({
            TotalNpsAnswers: total,
            TotalNpsAnswersWithoutDimensionKey: 0,
            dimensionProps: props
          })
        },
        setLoadingTotals
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [totals, setTotals] = useState(0)
  const [loadingTotals, setLoadingTotals] = useState(false)

  const [chartData, setChartData] = useState(null)
  const [loadingChartData, setLoadingChartData] = useState(false)

  useEffect(() => {
    const currentSearch = qs.parse(search)
    if (currentSearch.RatingDimensionKey) {
      setRating(currentSearch.RatingDimensionKey)
    }
    if (currentSearch.DimensionKey) {
      setDimension(currentSearch.DimensionKey)
    }
  }, [search])

  const [showEmpty, setShowEmpty] = useState(false)
  const [showStars, setShowStars] = useState(false)
  const [showDimensions, setShowDimensions] = useState(false)
  useEffect(() => {
    if (!data || data.length < 1) {
      if (
        chartData === null ||
        chartData?.Average === null ||
        dimension !== ''
      ) {
        setShowEmpty(true)
        setShowDimensions(false)
        setShowStars(false)
      } else if (
        rating !== '' &&
        dimension === '' &&
        chartData?.Average !== null
      ) {
        setShowDimensions(true)
        setShowEmpty(false)
        setShowStars(false)
      }
    } else if (data && data.length > 0) {
      setShowEmpty(false)
      setShowDimensions(false)
      setShowStars(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, chartData])

  const loading = useMemo(() => {
    return (
      loadingChartData ||
      loadingData ||
      loadingDimensionList ||
      loadingDimensionRatingList ||
      loadingTotals
    )
  }, [
    loadingChartData,
    loadingData,
    loadingDimensionList,
    loadingDimensionRatingList,
    loadingTotals
  ])

  return (
    <>
      <Header
        title={props.t('stars.title')}
        type="actions"
        items={
          <Filters justify="flex-end">
            <FilterItem>
              <BigButton
                icon={<FilterList />}
                onClick={handleFilter}
                title={props.t('stars.title-filter')}
                data-testid="add-new-campaign-btn-header"
              />
            </FilterItem>
          </Filters>
        }
      />

      <Content>
        <List data-testid="csat-stars-list">
          <Container>
            <ListItem noHover key={1} index={0} length={data?.length}>
              <Dropdowns>
                <Field>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      {props.t('stars.csat-label')}
                    </InputLabel>

                    <Select
                      value={rating}
                      id="campaign-dropdown-list"
                      labelId="campaign-dropdown-list"
                      onChange={(e) => handleChangeRating(e.target.value)}
                    >
                      {dimensionRatingList &&
                        dimensionRatingList.map((item) => (
                          <MenuItem key={item.IdYourviews} value={item.Name}>
                            {item.Name}
                          </MenuItem>
                        ))}

                      {!dimensionRatingList && (
                        <MenuItem disabled>
                          {props.t('stars.disableFildCsat')}
                        </MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Field>
                <Divider>
                  <SyncAltIcon fontSize="small" color="inherit" />
                </Divider>

                <Field>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      {props.t('stars.dimension-label')}
                    </InputLabel>

                    <Select
                      value={dimension}
                      id="dimension-dropdown-list"
                      labelId="dimension-dropdown-list"
                      disabled={!hasValueInsideString(rating)}
                      onChange={(e) => handleChangeDimension(e.target.value)}
                    >
                      {!dimensionList ||
                        (dimensionList.length < 1 && (
                          <MenuItem disabled>
                            {`${props.t(
                              'stars.disableFildDimension'
                            )} ${rating}`}
                          </MenuItem>
                        ))}

                      {dimensionList &&
                        dimensionList.map((item) => (
                          <MenuItem key={item.IdYourviews} value={item.Name}>
                            {item.Name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Field>
              </Dropdowns>
            </ListItem>

            {!loading && data && data.length > 0 && (
              <Row>
                <Column width="55%">
                  <Item title>{props.t('stars.dimension-label')}</Item>
                </Column>

                <Column width="45%">
                  <Item flexEndTitle>{props.t('stars.csat-label')}</Item>
                </Column>
              </Row>
            )}
            {!loading && showEmpty && (
              <Empty>
                <Image src={empty} />

                <Title>{props.t('stars.emptyTitle')}</Title>
                <SubTitle>{props.t('stars.emptySubtitle')}</SubTitle>
              </Empty>
            )}

            {!loading && showDimensions && (
              <DimensionChart
                data={chartData}
                totals={totals}
                margin={{ top: '48px', bottom: '24px' }}
              />
            )}

            {!loading &&
              showStars &&
              data.map((item, index) => (
                <ListItem key={index} index={index + 1} length={data?.length}>
                  <Row>
                    <Column width="55%">{item.DimensionValue}</Column>

                    <Column width="45%">
                      <Item flexEnd>
                        {intlFloatPoint(item.Rating.toFixed(1), language)}
                      </Item>

                      <Item>
                        <Rating
                          readOnly
                          color="primary"
                          precision={0.5}
                          value={item.Rating}
                          className={classes.rating}
                          emptyIcon={
                            <StarBorderIcon
                              style={{ color: '#00AEEF' }}
                              fontSize="inherit"
                            />
                          }
                        />
                      </Item>
                    </Column>
                  </Row>
                </ListItem>
              ))}

            {!!loading && <Loader />}

            <Item flexCenter exempleMesage>
              <ErrorOutlineIcon
                style={{ color: '#9E9E9E', marginRight: '12px' }}
                fontSize="large"
              />
              {props.t('stars.exempleMesage')}

              <Item
                style={{
                  cursor: 'pointer',
                  display: 'contents',
                  float: 'left',
                  color: '#00AEEF',
                  fontWeight: 500
                }}
                onClick={handleOpen}
              >
                {props.t('stars.linkExemple')}
              </Item>
            </Item>
          </Container>
        </List>

        <HelpModal open={open} handleClose={handleClose} />
        <Filter
          campaigns={campaigns}
          filterState={filterState}
          handleFilterState={handleFilter}
        />
      </Content>
    </>
  )
}

export default withTranslation()(Stars)
