import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles(() => ({
  input: { width: 260 },
  error: { marginTop: 10 },
  first: { fontSize: 22, margin: '20px 0px' },
  second: { fontSize: 20, margin: '20px 0px' },
  third: { fontSize: 18, margin: '20px 0px' },
  warning: { fontSize: 18, margin: '20px 0px' }
}))

export default useStyle
