import { withTranslation } from 'react-i18next'
import i18n from 'i18next'
import { useForm, Controller } from 'react-hook-form'
import { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from '@material-ui/core'
import { Text } from './style'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { useCampaign } from 'modules/Campaign/components/Details/context'
import { uuid } from 'core/helpers'
import AccessTime from '@material-ui/icons/AccessTime'

function DialogDispatch({ open, handleClose, emailList, t }) {
  const { language } = i18n
  const [DateDay] = useState(new Date())
  const [DateHour] = useState(new Date())
  const [selectedValue, setSelectedValue] = useState(2)

  const handleChange = (event) => {
    setSelectedValue(parseInt(event.target.value))
  }

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      Type: selectedValue,
      DateDay: DateDay,
      DateHour: DateHour,
      Status: 0,
      Active: true
    }
  })

  useEffect(() => {
    const subscription = watch((values) => {
      if (values.emailList) {
        setNoListError(false)
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const { dispatch, handleSetDispatch } = useCampaign()

  const [noListError, setNoListError] = useState(false)
  const [noCheckError, setNoCheckError] = useState(false)

  const onSubmit = (data) => {
    
    if (!data.emailList) {
      setNoListError(true)
      return
    }else{

      for (let i = 0; i < emailList.length; i++) {
      if(emailList[i].IdYourviews === data.emailList){
        data.emailList = emailList[i]
      }
        
      }

      setNoListError(false)

      data.Type = parseInt(data.Type)
  
      if (data.Type < 1) {
        data.Date = new Date()
      } else {
        setNoCheckError(false)
  
        data.DateFull = new Date(
          data.DateDay.setHours(
            data.DateHour.getHours(),
            data.DateHour.getMinutes()
          )
        )
  
        data.Date = data.DateFull.toISOString()
        data.DateDay = data.DateDay.toISOString()
        data.DateHour = data.DateHour.toISOString()
      }
  
      data.NameList = data.emailList.Name
      data.IdTargetList = data.emailList.IdYourviews
  
      handleSetDispatch([...dispatch, { ...data, id: uuid.generator() }])
  
      handleClose()
    }

    
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth="md"
      >
        <DialogTitle style={{ width: '600px' }} id="alert-dialog-title">
          <Text
            style={{
              fontSize: '21px'
            }}
          >
            Novo disparo
          </Text>
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: '10px',
              fontSize: '23px',
              top: '10px',
              cursor: 'pointer'
            }}
          />
        </DialogTitle>

        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="emailList"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <FormControl
                  style={{
                    width: '100%'
                  }}
                  variant="outlined"
                  error={noListError}
                >
                  <InputLabel id="demo-simple-select-label">
                    {t('campaign.peopleList.form.title')}
                  </InputLabel>
                  <Select
                    size="small"
                    defaultValue = ""
                    fullWidth
                    id="demo-simple-select"
                    value={value || ''}
                    onChange={onChange}
                    ref={ref}
                    label={t('campaign.peopleList.form.title')}
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'select-list'
                    }}
                  >
                    {emailList &&
                      emailList.map((item, index) => (
                        <MenuItem value={item.IdYourviews} key={index}>
                          {item.Name}
                        </MenuItem>
                      ))}
                  </Select>
                  {noListError && (
                    <div data-tedtid="error-list">
                      <FormHelperText>
                        {t('campaing.edit.dispatch.selectList')}
                      </FormHelperText>
                    </div>
                  )}
                </FormControl>
              )}
            />

            <Text
              style={{
                fontSize: '14px',
                marginTop: '16px',
                marginBottom: '4px'
              }}
            >
              {t('campaing.edit.dispatch.sendDate')}
            </Text>

            <Controller
              name="Type"
              control={control}
              required
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <FormControl component="fieldset" error={noCheckError}>
                  <RadioGroup
                    style={{ display: 'block', flexDirection: 'row' }}
                    onChange={onChange}
                  >
                    <FormControlLabel
                      value={1}
                      style={{ display: 'block' }}
                      control={
                        <Radio color="primary" checked={selectedValue === 1} />
                      }
                      label={t('campaing.edit.dispatch.schedule')}
                      onChange={handleChange}
                    />
                    {selectedValue === 1 && (
                      <>
                        <Controller
                          name="DateDay"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <KeyboardDatePicker
                              disableToolbar
                              variant="inline"
                              margin="normal"
                              id="date-picker-inline"
                              value={value ?? DateDay}
                              autoOk
                              style={{ width: '176px' }}
                              PopoverProps={{
                                anchorOrigin: {
                                  vertical: 'top',
                                  horizontal: 'center'
                                },
                                transformOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'center'
                                }
                              }}
                              onChange={onChange}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                                style: {
                                  padding: 0
                                }
                              }}
                              InputAdornmentProps={{ position: 'start' }}
                              label={t('campaing.edit.dispatch.sendDate')}
                              format={
                                language === 'en' || language === 'en-US'
                                  ? 'MM/dd/yyyy'
                                  : 'dd/MM/yyyy'
                              }
                            />
                          )}
                        />
                        <Controller
                          name="DateHour"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { errors }
                          }) => (
                            <KeyboardTimePicker
                              keyboardIcon={<AccessTime />}
                              disableToolbar
                              variant="inline"
                              margin="normal"
                              value={value ?? DateHour}
                              id="time-picker-inline"
                              autoOk
                              style={{
                                width: '128px'
                              }}
                              PopoverProps={{
                                anchorOrigin: {
                                  vertical: 'top',
                                  horizontal: 'center'
                                },
                                transformOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'center'
                                }
                              }}
                              onChange={onChange}
                              format="HH:mm "
                              InputAdornmentProps={{ position: 'start' }}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                                style: {
                                  padding: 0
                                }
                              }}
                              label={t('campaing.edit.dispatch.hour')}
                            />
                          )}
                        />
                        {/* esse campo esta oculto pois ainda não temos a informação completa no backend */}
                        <Controller
                          name="RepetitionInterval"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, name, ref },
                            fieldState: { invalid, isTouched, isDirty, error },
                            formState: { errors }
                          }) => (
                            <FormControl
                              style={{
                                marginTop: '16px',
                                marginBottom: '8px',
                                float: 'right',
                                width: '269px',
                                visibility: 'hidden'
                              }}
                            >
                              <InputLabel id="demo-simple-select-label">
                                {t('dispatch.repeat')}
                              </InputLabel>
                              <Select
                                id="demo-simple-select"
                                value={value}
                                onChange={onChange}
                                ref={ref}
                                data-testid="select-element"
                              >
                                <MenuItem value={0}>
                                  {t('dispatch.withoutRepetition')}
                                </MenuItem>
                                <MenuItem value={1}>
                                  {t('dispatch.monthly')}
                                </MenuItem>
                                <MenuItem value={2}>
                                  {t('dispatch.quarterly')}
                                </MenuItem>
                                <MenuItem value={3}>
                                  {t('dispatch.semester')}
                                </MenuItem>
                                <MenuItem value={4}>
                                  {t('dispatch.yearly')}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        />
                      </>
                    )}
                    <FormControlLabel
                      value={2}
                      style={{ display: 'block' }}
                      control={
                        <Radio color="primary" checked={selectedValue === 2} />
                      }
                      label={t('dispatch.sendNow')}
                      onChange={handleChange}
                    />
                  </RadioGroup>
                  {error && (
                    <FormHelperText>{t('dispatch.selectType')}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </form>
        </DialogContent>

        <DialogContent style={{ borderTop: '1px solid #E0E0E0' }}>
          <Button
            style={{ float: 'right' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit(onSubmit)}
            data-testid="save-element"
          >
            {t('uiActions.complete')}
          </Button>

          <Button
            style={{ float: 'right' }}
            onClick={handleClose}
            color="primary"
          >
            {t('uiActions.close')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default withTranslation()(DialogDispatch)
