import './style.css'
import { useCallback, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import useInitHotjar from 'core/hooks/useInitHotjar'
import Grid from '@material-ui/core/Grid'
import Header from '../../components/Header'
import DateRangePicker from '../../components/DateRangePicker'
import DropdownCampaign from '../../components/Dropdown/DropdownCampaign'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'
import DashboardTabs from './components/DashboardTabs'
import useGetData from 'core/hooks/useGetData'
import DashboardNoCampaign from './components/DashboardNoCampaign'
import NpsCsatTabs from 'components/NpsCsatTabs'
import { Permissions } from 'modules/AppDrawer/enums/Permissions'
import { useAuthContext } from 'modules/Authentication'
import InfoIcon from '@material-ui/icons/Info'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { QS_TYPES } from 'core/constants/query-strings'

const Home = ({ match, t }) => {
  useInitHotjar()
  const { user } = useAuthContext()
  const location = useLocation()

  const [campaigns, setCampaigns] = useState()
  const [loadingCampaigns, setLoadingCampaigns] = useState(true)
  useGetData({
    endpoint: 'campaign/getpaged',
    setData: setCampaigns,
    setLoading: setLoadingCampaigns,
    noQueryString: true
  })

  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const currentTabValue = useMemo(() => {
    if (currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam) {
      return NPS_CSAT_TAB.csat.typeValue
    }
    return NPS_CSAT_TAB.nps.typeValue
  }, [currentSearch])

  const [npsScore, setNpsScore] = useState({})
  const [loadingNpsScore, setLoadingNpsScore] = useState(true)
  const getNpsScore = useGetData({
    endpoint: 'nps/getnpsscoreconsolidation',
    setData: setNpsScore,
    setLoading: setLoadingNpsScore
  })

  const [csatScore, setCsatScore] = useState({})
  const [loadingCsatScore, setLoadingCsatScore] = useState(true)
  useGetData({
    endpoint: 'nps/getcsatscoreconsolidation',
    setData: setCsatScore,
    setLoading: setLoadingCsatScore
  })

  const [decimal, setDecimal] = useState(0)
  const [loadingDecimal, setLoadingDecimal] = useState(true)
  useGetData({
    endpoint: 'StoreSettings/GetNpsScoreDecimals',
    setData: setDecimal,
    setLoading: setLoadingDecimal
  })

  const [widgetConversion, setWidgetConversion] = useState(0)
  const [loadingWidgetConversion, setLoadingWidgetConversion] = useState(true)
  useGetData({
    endpoint: '/nps/getwidgetconversion',
    setData: setWidgetConversion,
    setLoading: setLoadingWidgetConversion
  })

  const [emailConversion, setEmailConversion] = useState(0)
  const [loadingEmailConversion, setLoadingEmailConversion] = useState(true)
  useGetData({
    endpoint: '/nps/getemailconversion',
    setData: setEmailConversion,
    setLoading: setLoadingEmailConversion
  })

  const loading = useMemo(() => {
    return (
      (loadingCampaigns ||
        loadingDecimal ||
        loadingCsatScore ||
        loadingNpsScore ||
        loadingWidgetConversion ||
        loadingEmailConversion) &&
      (!npsScore.Score || !csatScore.CSATScore)
    )
  }, [
    csatScore.CSATScore,
    loadingCampaigns,
    loadingCsatScore,
    loadingDecimal,
    loadingEmailConversion,
    loadingNpsScore,
    loadingWidgetConversion,
    npsScore.Score
  ])

  const handleNewGoal = useCallback(() => {
    getNpsScore.getData()
  }, [getNpsScore])

  return !loading &&
    !!campaigns &&
    !campaigns.TotalItemCount &&
    Permissions.managerRights.includes(user?.Role) ? (
    <DashboardNoCampaign />
  ) : (
    <Grid container>
      <Header
        type="filters"
        title={t('dashboard.title')}
        data-testid="dashboard-header"
        items={
          <Filters>
            <FilterItem>
              <DateRangePicker select />
            </FilterItem>
            <FilterItem>
              <DropdownCampaign
                campaignAllSelected
                type={currentTabValue}
                className="yv-test-home"
              />
            </FilterItem>
          </Filters>
        }
      />

      <NpsCsatTabs match={match} />

      {!!campaigns &&
        !campaigns.TotalItemCount &&
        !Permissions.managerRights.includes(user?.Role) && (
          <div className="no-campaign-info">
            <InfoIcon fontSize="small" color="primary" />
            <div className="no-campaign-info-text">
              {t('dashboard.noCampaign.info')}
            </div>
          </div>
        )}

      <DashboardTabs
        decimal={decimal}
        npsScore={npsScore}
        csatScore={csatScore}
        loading={loading}
        handleNewGoal={handleNewGoal}
        widgetConversion={widgetConversion}
        emailConversion={emailConversion}
      />
    </Grid>
  )
}

export default withTranslation()(Home)
