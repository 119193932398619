import { useAuthContext } from 'modules/Authentication'
import React, { useEffect, useState } from 'react'
import axios from 'core/api'
import { BASE_PATH } from 'router'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'App'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'
import { CircularProgress } from '@material-ui/core'
import { create } from 'localstorage-helpr'

const HorusCallback = () => {
  const [authenticationCode, setAuthenticationCode] = useState('')

  const { handleSetUser, handleSetToken, handleSetStore } = useAuthContext()
  const history = useHistory()
  const { notify } = useAppContext()

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)

    // Access the authentication_code parameter
    if (queryParams && queryParams.authentication_code) {
      setAuthenticationCode(queryParams.authentication_code)
    }
  }, [])

  useEffect(() => {
    if (!authenticationCode) return

    axios
      .get('Horus/Callback', {
        params: {
          authentication_code: authenticationCode
        },
        withCredentials: true
      })
      .then((res) => {
        handleSetUser(res.data.VoicesAuthResult._admin)
        handleSetStore(res.data.VoicesAuthResult._store)
        handleSetToken(res.data.VoicesAuthResult._jwtToken)

        create('horus_yv_user', JSON.stringify(res.data.UserProfileResult))
        create('horus_apps', JSON.stringify(res.data.HorusAppList))

        history.push(BASE_PATH)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
  }, [authenticationCode])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw'
      }}
    >
      <CircularProgress />
    </div>
  )
}

export default withTranslation()(HorusCallback)
