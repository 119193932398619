import { FaFileCsv, FaFileExcel, FaFile } from 'react-icons/fa'

const IconDictionary = new Map([
  ['csv', <FaFileCsv />],
  ['xls', <FaFileExcel />],
  ['xlsx', <FaFileExcel />]
])

const FileIcon = ({ extension }) => {
  extension = extension.replace('.', '')

  return (
    <span className="file-icon">
      {IconDictionary.has(extension) && IconDictionary.get(extension)}

      {!IconDictionary.has(extension) && <FaFile />}
    </span>
  )
}

export default FileIcon
