import { getIn } from 'formik'
import { makeStyles } from '@material-ui/core/styles'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { event } from 'react-ga'

const useStyles = makeStyles({
  colorsScaleInputs: {
    display: 'flex',
    alignItems: 'center',
    margin: "20px 0"
  },
  colorScaleBox: {
    display: "block",
    width: "20%",
  },
  colorScaleInput: {
    display: "none",
    opacity: "0",
  },
  colorScaleLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    userSelect: "none",
    height: "40px",
    border: "none",
    backgroundColor: "#e1e0e1",
    cursor: "pointer"
  },

  backgroundColor: (props) => ({
    backgroundColor: props.backgroundColor
  })
})

function ColorScaleComponent(props) {  

    const colorsCSAT = {
        0: "#e1e0e1",
        1: "#e94b5c",
        2: "#ff8448",
        3: "#f9e382",
        4: "#81bd54",
        5: "#26ba54",
    }

    const classes = useStyles({backgroundColor: colorsCSAT[`${props.value}`]});

    const isSelectedBackground = props.label === props.value.toString() ? classes.backgroundColor : ""

    return (
        <span className={useStyles().colorScaleBox}>
            <label 
                for={props.name + '-' + props.label} 
                className={`${classes.colorScaleLabel} ${isSelectedBackground}`}>
                {props.label}
            </label>
            <input 
                type="radio"
                onChange={() => {props.onChange(event, props.label)}}
                id={props.name + '-' + props.label} 
                name={props.name} 
                value={props.value} 
                className={useStyles().colorScaleInput}></input>
        </span>
    );
}

const ColorScaleInput = ({
  field,
  activeLabel,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  return (
    <Box
      mb={3}
      component="fieldset"
      borderColor="transparent"
      className={`form-field checkbox-field 
        ${
          !!(getIn(touched, field.name) && getIn(errors, field.name))
            ? 'checkbox-error'
            : ''
        }`}
    >
      {props.label && <Typography component="legend">{props.label}</Typography>}

      <div className={useStyles().colorsScaleInputs}>
        {/* <StyledRating
          size="large"
          name={field.name}
          value={isNaN(field.value) ? 0 : parseInt(field.value)}
          onChange={(event, newValue) => {
            setFieldValue(field.name, newValue)
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover)
          }}
        /> */}

        {
            Object.keys(activeLabel).map((label) => (
                label && 
                <ColorScaleComponent 
                    key={parseInt(label)}
                    label={label}
                    name={field.name}
                    value={isNaN(field.value) || field.value === "" ? 0 : parseInt(field.value)}
                    onChange={(event, newValue) => {
                      setFieldValue(field.name, newValue)
                    }}
                />
            ))
        }
      </div>
    </Box>
  )
}

export default ColorScaleInput
