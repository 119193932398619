import { Typography } from '@material-ui/core'

import useStyles from './style'

export default function FieldAreaTitle({ text, label }) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography variant="h6" component="h2" style={{ fontSize: 18 }}>
        {text}
      </Typography>

      <Typography className={classes.label} component="p">
        {label}
      </Typography>
    </div>
  )
}
