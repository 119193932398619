/* eslint-disable no-useless-escape */
import { useEffect, useState, useRef, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { Formik, Form } from 'formik'
import qs from 'query-string'
import { QS_WRITE_ID, QS_WRITE_NPS } from 'core/constants/query-strings'
import { FIELD_TYPE } from 'core/enums/field-type'
import { NPS_CLASSIFICATION } from 'core/enums/nps-classification'
import axios from 'core/api'
import { useAppContext } from '../../App'
import { useHistory, useLocation } from 'react-router'
import {
  Container,
  Legend,
  Logo,
  Header,
  Title,
  FormBox,
  FormContainer,
  SkeletonBox
} from './style'
import GoogleAnalytics from 'core/constants/google-analytics'
import useEventAnalytic from 'core/hooks/useEventAnalytic'
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Skeleton from '@material-ui/lab/Skeleton'
import DoneIcon from '@material-ui/icons/Done'
import Button from '@material-ui/core/Button'
import 'style/route/write/main.css'

import FormFields from 'modules/Campaign/components/FormFields'
import { URL_API } from 'core/constants/api'

const StepStateMap = {
  alreadyAnswered: 'alreadyAnswered',
  default: 'initial',
  done: 'done',
  error: 'error'
}

const Write = (props) => {
  const location = useLocation()
  const history = useHistory()

  const { notify, queryString } = useAppContext()

  const [stepState, setStepState] = useState(StepStateMap.default)

  const { trackSaveNps, trackAnonymous } = useEventAnalytic()
  const { Actions, Category } = GoogleAnalytics

  const [loading, setLoading] = useState(true)
  const [theme, setTheme] = useState(null)

  const [form, setForm] = useState(null)
  const [writeId, setWriteId] = useState('')
  const [error, setError] = useState({
    message: '',
    has: false,
    shouldTry: false
  })
  const [success, setSuccess] = useState(false)

  const containerRef = useRef(null)

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: queryString
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  useEffect(() => {
    if (props.dataForReadOnly) {
      setForm(props.dataForReadOnly)
      setStepState(StepStateMap.default)
      setLoading(false)
    }
  }, [props.dataForReadOnly])

  useEffect(() => {
    let currentQS = qs.parse(location.search)

    if (!currentQS[QS_WRITE_ID]) {
      setError({
        message: props.t('write.status.invalidForm'),
        has: true,
        shouldTry: false
      })
      return
    }
    setWriteId(currentQS[QS_WRITE_ID])

    setLoading(true)

    axios
      .get(
        'api/pub/form/getbydispatchinfokey?' +
          QS_WRITE_ID +
          '=' +
          currentQS[QS_WRITE_ID]
      )
      .then((res) => {
        if (!res.data.Active) {
          setError({
            message: props.t('write.status.invalidForm'),
            has: true,
            shouldTry: false
          })

          setStepState(StepStateMap.error)
          return
        }

        if (res.data && res.data.AlreadyExistsNpsForDispatchInfo) {
          notify('Este formulário já foi respondido', 'info')
          setStepState(StepStateMap.alreadyAnswered)
        }

        console.log(res.data)

        setForm(res.data)
        setTheme(
          res.data.Theme ?? {
            BackgroundColor: '#ffffff',
            BackgroundImageUrl: '',
            LogoUrl: '',
            MainTitleColor: '#000000',
            MainTitleText: 'Responder Pesquisa',
            SecondaryText: '',
            SecondaryTextColor: '#000000',
            ThemeMainColor: '#00aeef',
            SendButtonColor: '#00aeef'
          }
        )

        if (res.data && !res.data.AlreadyExistsNpsForDispatchInfo) {
          setStepState(StepStateMap.default)
        }
      })
      .catch((error) => {
        setError({
          message: props.t('write.errors.unknow'),
          has: true,
          shouldTry: true
        })

        setStepState(StepStateMap.error)
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const npsDefault = useMemo(() => {
    let currentQS = qs.parse(location.search)

    if (currentQS[QS_WRITE_NPS]) return currentQS[QS_WRITE_NPS]
    return ''
  }, [location.search])

  const getFields = () => {
    let fields = {
      NPS: npsDefault,
      Comment: '',
      PersonEmail: '',
      PersonName: ''
    }
    if (form && form.FormFields && form.FormFields.length) {
      form.FormFields.forEach((item, index) => {
        if (index === 0 && item.Type === 2 && npsDefault <= 5) {
          fields[item?.IdYourviews.toString()] = npsDefault
        } else {
          fields[item?.IdYourviews.toString()] =
            item.Type === FIELD_TYPE.multiSelect ? [] : ''
        }
      })
    }
    return fields
  }

  const checkDependency = (values, field) => {
    let response = false

    if (!field.FormFieldDependencies || field.FormFieldDependencies.length < 1)
      return response

    //Iteração dos campos
    for (let prop in values) {
      //Procura o campo no objeto de form que temos
      // eslint-disable-next-line no-loop-func
      const item = form?.FormFields?.find(
        (f) => f.IdYourviews.toString() === prop
      )

      //Valida se é multi ou single select ou rating
      if (
        item &&
        (item.Type === FIELD_TYPE.multiSelect ||
          item.Type === FIELD_TYPE.singleSelect ||
          item.Type === FIELD_TYPE.rating)
      ) {
        // Itera sobre as possiveis dependencias
        // eslint-disable-next-line no-loop-func
        field.FormFieldDependencies.forEach((single) => {
          if (values[prop]) {
            //Em caso de set multi select
            if (
              values[prop] instanceof Array &&
              values[prop].indexOf(
                single?.IdFormFieldValueDependancy?.toString()
              ) > -1
            )
              response = true

            //Em caso de ser single select
            if (values[prop] === single?.IdFormFieldValueDependancy?.toString())
              response = true

            //Em caso de ser rating
            let fieldValueDependencie = form.FormFields.find((f) => f.IdYourviews === parseInt(prop)).FormFieldValues[values[prop]-1]
            if (fieldValueDependencie?.IdYourviews === single?.IdFormFieldValueDependancy)
              response = true

            //Dou return para parar iteração
            if (response) return
          }
        })
      }
    }

    return response
  }

  const checkNPSDependency = (values, field) => {
    const { NpsClassificationDependancy } = field

    const npscd = NpsClassificationDependancy
    const val = values.NPS

    if (isNaN(val)) return false

    const nps = parseInt(val)

    if (!npscd || npscd.length < 1) {
      return false
    }

    if (nps < 7 && npscd.indexOf(NPS_CLASSIFICATION.detractor) > -1) {
      return true
    }

    if (nps < 9 && nps > 6 && npscd.indexOf(NPS_CLASSIFICATION.neutral) > -1) {
      return true
    }

    if (nps > 8 && npscd.indexOf(NPS_CLASSIFICATION.promoter) > -1) {
      return true
    }
  }

  const checkNPS = (values) => {
    const nps = values?.NPS

    if (isNaN(nps) || (parseInt(nps) < 0 || parseInt(nps) > 10)) {
      return false
    }

    return parseInt(nps)
  }

  const isWithinRange = (detractor, neutral, promoter, nps) => {
    return !!((promoter && (nps === 9 || nps === 10)) ||
      (neutral && (nps === 7 || nps === 8)) ||
      (detractor && (nps >= 0 && nps <= 6)))
  }

  const validate = (values) => {
    let errors = {}

    const requiredMsg = props.t('write.status.required')
    const invalidMsg = props.t('form.error.email')

    if (form.Type === 0 && values.NPS.length < 1) {
      errors.NPS = requiredMsg
    }

    const nps = checkNPS(values)
    const detractor = form.CommentRequiredDetractor
    const neutral = form.CommentRequiredNeutral
    const promoter = form.CommentRequiredPromoter
    const isCommentRequired = isWithinRange(detractor, neutral, promoter, nps)

    if (isCommentRequired && values.Comment.length < 1) {
      errors.Comment = requiredMsg
    }

    if (values.PersonEmail.length < 1 && form.AnonymousPerson)
      errors.PersonEmail = requiredMsg

    // eslint-disable-next-line
    let rEmail =
      // eslint-disable-next-line no-control-regex
      /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i

    if (
      form.AnonymousPerson &&
      values.PersonEmail.length > 0 &&
      values.PersonEmail.search(rEmail) === -1
    )
      errors.PersonEmail = invalidMsg

    if (values.PersonName.length < 1 && form.AnonymousPerson)
      errors.PersonName = requiredMsg

    for (let prop in values) {
      const empty =
        typeof values[prop] === 'undefined' || values[prop].length < 1
      const index = form?.FormFields?.findIndex(
        // eslint-disable-next-line no-loop-func
        (f) => f?.IdYourviews.toString() === prop
      )

      if (
        index > -1 &&
        empty &&
        form?.FormFields[index].Required &&
        (checkDependency(values, form?.FormFields[index]) ||
          checkNPSDependency(values, form?.FormFields[index]) ||
          ((!form?.FormFields[index].FormFieldDependencies ||
            form?.FormFields[index].FormFieldDependencies.length < 1) &&
            (!form?.FormFields[index].NpsClassificationDependancy ||
              form?.FormFields[index].NpsClassificationDependancy.length < 1)))
      )
        errors[prop] = requiredMsg
    }
    window.goToError()
    return errors
  }

  useEffect(() => {
    if (writeId) {
      axios.get(`${URL_API}api/pub/WebBeacon/Track?Key=${writeId}&Type=1`)
    }
  }, [writeId])

  const saveForm = (values, setSubmitting) => {
    if (values.PersonEmail) {
      trackSaveNps(Actions.SaveNps, Category.Interaction, values.PersonEmail)
    } else {
      trackAnonymous(Actions.SaveNps, Category.Interaction)
    }

    let formObj = {
      DispatchInfoKey: writeId,
      Rating: values.NPS ? parseInt(values.NPS) : undefined,
      Comment: values.Comment,
      FormFields: []
    }
    if (form.AnonymousPerson)
      formObj.Person = { Email: values.PersonEmail, Name: values.PersonName }

    for (let val in values) {
      if (isNaN(val)) continue

      // eslint-disable-next-line no-loop-func
      const idx = form?.FormFields?.findIndex(
        (f) => f?.IdYourviews.toString() === val
      )

      if (
        ((form?.FormFields[idx].FormFieldDependencies &&
          form?.FormFields[idx].FormFieldDependencies.length > 0) ||
          (form?.FormFields[idx].NpsClassificationDependancy &&
            form?.FormFields[idx].NpsClassificationDependancy.length > 0)) &&
        !checkDependency(values, form?.FormFields[idx]) &&
        !checkNPSDependency(values, form?.FormFields[idx])
      )
        continue

      if (form?.FormFields[idx].Type === FIELD_TYPE.text) {
        if (values[val].trim().length < 1) continue

        formObj.FormFields.push({
          IdFormField: parseInt(val),
          SelectedValues: [
            {
              Value: values[val]
            }
          ]
        })
        continue
      }

      if (form?.FormFields[idx].Type === FIELD_TYPE.multiSelect) {
        formObj.FormFields.push({
          IdFormField: parseInt(val),
          SelectedValues: values[val].map((item) => ({
            IdFormFieldValue: parseInt(item) || 0,
            IdDimensionValue:
              form?.FormFields[idx].FormFieldValues.find(
                (v) => v?.IdYourviews === parseInt(item)
              )?.IdDimensionValue || 0,
            Value: ''
          }))
        })
        continue
      }

      if (form?.FormFields[idx].Type === FIELD_TYPE.rating) {
        let selectedValue =
          form?.FormFields[idx].FormFieldValues[parseInt(values[val]) - 1]
        formObj.FormFields.push({
          IdFormField: parseInt(val),
          SelectedValues: [
            {
              IdFormFieldValue: selectedValue?.IdYourviews || 0,
              IdDimensionValue: selectedValue?.IdDimensionValue || 0,
              Value: ''
            }
          ]
        })
        continue
      }

      formObj.FormFields.push({
        IdFormField: parseInt(val),
        SelectedValues: [
          {
            IdFormFieldValue: parseInt(values[val]) || 0,
            IdDimensionValue:
              form?.FormFields[idx].FormFieldValues.find(
                (v) => v?.IdYourviews === parseInt(values[val])
              )?.IdDimensionValue || 0,
            Value: ''
          }
        ]
      })
    }

    setLoading(true)

    axios
      .post('api/pub/form/save', formObj)
      .then(({ data }) => {
        /*
          ENUM: NpsFormSaveResult
          Success = 0,
          DispatchInfoNotFound = 1,
          DispatchScheduleNotFound = 2,
          NpsAlreadyExists = 3,
          CampaignNotFound = 4,
          NpsValidations = 5,
        */

        if (data.NpsFormSaveResult === 0) {
          setSuccess(true)
          setStepState(StepStateMap.done)
        } else {
          setError({
            message: props.t('write.errors.' + data.NpsFormSaveResult),
            has: true,
            shouldTry: false
          })

          setStepState(StepStateMap.error)
        }
      })
      .catch((error) => {
        setStepState(StepStateMap.error)
        notify(props.t('write.errors.unknow'), 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)

        containerRef.current.scrollIntoView()
      })
  }

  const HeaderMap = (step) => {
    const map = new Map([
      [
        StepStateMap.alreadyAnswered,
        <>
          {
          theme?.LogoUrl && !theme?.LogoUrl.includes("nubank-header") && 
            <Logo src={theme?.LogoUrl} />
          }
        </>
      ],
      [
        StepStateMap.default,
        <>
          {
          theme?.LogoUrl && !theme?.LogoUrl.includes("nubank-header") && 
            <Logo src={theme?.LogoUrl} />
          }

          {theme?.MainTitleText && (
            <Title color={theme?.MainTitleColor}>{theme?.MainTitleText}</Title>
          )}

          {theme?.SecondaryText && (
            <Legend color={theme?.SecondaryTextColor}>
              {theme?.SecondaryText}
            </Legend>
          )}
        </>
      ],
      [
        StepStateMap.done,
        <>
          {
          theme?.LogoUrl && !theme?.LogoUrl.includes("nubank-header") && 
            <Logo src={theme?.LogoUrl} />
          }

          {theme?.ConfirmationScreenTitleText && (
            <Title color={theme?.MainTitleColor}>
              {theme?.ConfirmationScreenTitleText}
            </Title>
          )}
        </>
      ]
    ])

    return map.has(step) ? map.get(step) : map.get(StepStateMap.default)
  }

  const FormMap = (step) => {
    const map = new Map([
      [
        StepStateMap.alreadyAnswered,
        <>
          <Header src={theme?.LogoUrl} />
          <div style={{ textAlign: 'center', padding: '10px 0px' }}>
            <WarningOutlinedIcon style={{ color: 'orange' }} fontSize="large" />

            <p style={{ marginTop: 10, fontSize: 23 }}>
              {props.t('write.alreadyAnswered')}
            </p>
          </div>
        </>
      ],
      [
        StepStateMap.default,
        <>
          <Header src={theme?.LogoUrl} /> 
          {form && !error.has && !success && (
            <Formik
              initialValues={getFields()}
              onSubmit={(values, { setSubmitting }) =>
                saveForm(values, setSubmitting)
              }
              validate={validate}
              validateOnChange={true}
              validateOnBlur={false}
            >
              {(propsForm) => {
                return (
                  <Form>
                    <FormContainer>
                      <FormFields
                        form={form}
                        formikProps={propsForm}
                        theme={theme}
                      />
                    </FormContainer>
                  </Form>
                )
              }}
            </Formik>
          )}
        </>
      ],
      [
        StepStateMap.done,
        <>
          <Header src={theme?.LogoUrl} />
          <div style={{ textAlign: 'center', padding: '10px 0px' }}>
            <DoneIcon style={{ color: 'green' }} fontSize="large" />

            {theme?.ConfirmationScreenText ? (
              <p style={{ fontSize: 23 }}>{theme?.ConfirmationScreenText}</p>
            ) : (
              <p style={{ fontSize: 23 }}>{props.t('write.status.success')}</p>
            )}

            {theme?.ConfirmationScreenImageUrl && (
              <div style={{ textAlign: 'center', marginTop: 20 }}>
                <img
                  style={{ maxWidth: 600, maxHeight: 600 }}
                  src={theme?.ConfirmationScreenImageUrl}
                  alt="Acknowledgment"
                />
              </div>
            )}
          </div>
        </>
      ],
      [
        StepStateMap.error,
        <>
          {error.has && (
            <div style={{ textAlign: 'center', padding: '10px 0px' }}>
              <ErrorOutlineIcon color="error" fontSize="large" />

              <p className="write-warning-text">{error.message}</p>

              {error.shouldTry && (
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => window.location.reload()}
                >
                  {props.t('write.tryAgainBtn')}
                </Button>
              )}
            </div>
          )}
        </>
      ]
    ])

    return map.has(step) ? map.get(step) : map.get(StepStateMap.default)
  }

  const applyMarginTop = (step) => {
    const map = {
      [StepStateMap.error]: true,
      [StepStateMap.alreadyAnswered]: true,
      [StepStateMap.default]: false,
      [StepStateMap.done]: false
    }

    return map[step] ? 100 : 0
  }

  return (
    <Container
      bgImage={theme?.BackgroundImageUrl}
      bgColor={props.noBackground ? '' : theme?.BackgroundColor}
      ref={containerRef}
      style={{ margin: props.noBackground ? 48 : 0 }}
    >
      {HeaderMap(stepState)}

      <FormBox
        style={{
          marginTop: applyMarginTop(stepState)
        }}
      >
        {loading ? (
          <SkeletonBox>
            <Skeleton width="100%" height={100} />

            <Skeleton width="100%" height={100} />

            <Skeleton width="100%" height={100} />
          </SkeletonBox>
        ) : (
          FormMap(stepState)
        )}
      </FormBox>

      <div style={{ height: '700px' }}></div>
    </Container>
  )
}

export default withTranslation()(Write)
