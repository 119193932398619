import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  flex-direction: column;

  text-align: center;
  align-items: center;

  width: 100%;
  height: 100%;
`

export const FormBox = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;

  padding: 20px;
  margin: 10px;
  border-radius: 8px;
  background-color: #fff;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
`

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;

  margin: 10px 10px;
  border-radius: 20px;
  background-color: #fff;
`
