import { useState } from 'react'

import i18n from '../../../../lang'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'

import spain from '../../../../assets/img/flags/spain.svg'
import brazil from '../../../../assets/img/flags/brazil.svg'
import usa from '../../../../assets/img/flags/usa.svg'

import useStyles, { Flag } from './style'
import { useAppContext } from '../../../../App'

const Language = () => {
  const { currentLang, handleSetCurrentLang } = useAppContext()

  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const handleSelectLang = (lang) => {
    handleSetCurrentLang(lang)
    i18n.changeLanguage(lang)
    handleClose()
  }

  const FlagMap = {
    pt: <Flag src={brazil} />,
    es: <Flag src={spain} />,
    en: <Flag src={usa} />,

    'pt-BR': <Flag src={brazil} />,
    'es-ES': <Flag src={spain} />,
    'en-US': <Flag src={usa} />
  }

  return (
    <>
      <div className={classes.divSelector}>
        <IconButton
          data-testid="language-selector-button"
          onClick={handleClick}
          className={classes.iconSelector}
          style={{ height: '100%' }}
        >
          {FlagMap[currentLang]}
        </IconButton>
      </div>
      <Menu
        keepMounted
        id="language-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        data-testid="language-selector-menu"
      >
        <MenuItem
          data-testid="language-selector-item-pt"
          onClick={() => handleSelectLang('pt')}
        >
          <img className={classes.flag} src={brazil} alt="Brazil flag" />
          Português
        </MenuItem>

        <MenuItem
          data-testid="language-selector-item-es"
          onClick={() => handleSelectLang('es')}
        >
          <img className={classes.flag} src={spain} alt="Spain flag" />
          Español
        </MenuItem>

        <MenuItem
          data-testid="language-selector-item-en"
          onClick={() => handleSelectLang('en')}
        >
          <img className={classes.flag} src={usa} alt="UK flag" />
          English
        </MenuItem>
      </Menu>
    </>
  )
}

export default Language
