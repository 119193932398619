import * as Yup from 'yup'

import i18n from '../../lang'

function setYup() {
  Yup.setLocale({
    mixed: {
      required: i18n.t('form.error.required'),
      notType: i18n.t('form.error.invalid')
    },
    string: {
      email: i18n.t('form.error.email'),
      min: i18n.t('form.error.short'),
      max: i18n.t('form.error.long')
    },
    number: {
      min: i18n.t('form.error.min'),
      max: i18n.t('form.error.max')
    }
  })
}
setYup()

i18n.on('languageChanged', function () {
  setYup()
})

export default Yup
