export const HORUS_APP_KEY_LOCAL = '120923802725303'
export const HORUS_APP_KEY_QA = '467702342223866'
export const HORUS_APP_KEY_PROD = '323346403209990'

const HORUS_LOGOUT_LOCAL =
  'https://qa-horus.seekr.com.br/logout?app_key=' + HORUS_APP_KEY_LOCAL

const HORUS_REDIRECT_LOCAL =
  'https://qa-horus.seekr.com.br/auth?app_key=' + HORUS_APP_KEY_LOCAL

const HORUS_LOGOUT_QA =
  'https://qa-horus.seekr.com.br/logout?app_key=' + HORUS_APP_KEY_QA

const HORUS_REDIRECT_QA =
  'https://qa-horus.seekr.com.br/auth?app_key=' + HORUS_APP_KEY_QA

const HORUS_LOGOUT_PROD =
  'https://horus.hiplatform.com/logout?app_key=' + HORUS_APP_KEY_PROD

const HORUS_REDIRECT_PROD =
  'https://horus.hiplatform.com/auth?app_key=' + HORUS_APP_KEY_PROD

export const URL_HORUS_REDIRECT =
  origin.indexOf('localhost') > -1 || origin.includes('ngrok')
    ? HORUS_REDIRECT_LOCAL
    : origin.includes('npsqa')
    ? HORUS_REDIRECT_QA
    : HORUS_REDIRECT_PROD

export const HORUS_LOGOUT_URL =
  origin.indexOf('localhost') > -1 || origin.includes('ngrok')
    ? HORUS_LOGOUT_LOCAL
    : origin.includes('npsqa')
    ? HORUS_LOGOUT_QA
    : HORUS_LOGOUT_PROD
