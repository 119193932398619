export const APPLY_FULLDATE = [
  'nps/getbyfilter',
  'nps/exportnpslis',
  'nps/getnpsscoreconsolidation',
  'nps/getnpsscoreevolutionbydimension',
  'nps/exportnpsecoreevolutionbydimension',
  'nps/getnpsdatehistogramwithnofiltercomparition',
  'nps/getnpsaggregationbydimension'
]

export const WITHOUT_TOKEN = [
  'signup/signup',
  'authentication/admauthenticate',
  'api/pub/form/getbydispatchinfokey',
  'api/pub/form/save',
  'Horus/Callback'
]

export const THROW_ERROR_MANUALLY = ['form/insertupdate']
