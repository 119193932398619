import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  tooltip: {
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto 0px'
  },
  percentage: {
    margin: 'auto 0px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px'
  },
  values: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#212121',
    margin: 'auto 0px',
    marginLeft: 4
  }
}))
