import { Fragment, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

import moment from 'moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'

import useRequest from 'core/hooks/useRequest'
import axios from 'core/api'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'

import Loader from '../../../../components/Loader'

import { MdRefresh } from 'react-icons/md'
import { useAppContext } from '../../../../App'

const useStyles = makeStyles({
  table: {
    marginTop: '10px'
  },
  cell: {
    borderBottom: 'none',
    paddingBottom: '0px'
  }
})

const ProcessingFiles = ({ i18n, t }) => {
  const { notify } = useAppContext()

  const [loading, setLoading] = useState(false)
  const [sessions, setSessions] = useState([])

  useRequest('ImportSession/GetProcessingImportSessions', (sessions) => {
    setSessions(sessions.filter((session) => session.TotalRowsCount !== 0))
  })

  const getPercentage = (total, amount, invalid) => {
    const raw = ((amount - invalid) * 100) / total
    const percentage = Math.round(raw * 100) / 100
    return percentage < 0 ? 0 : percentage > 100 ? 100 : percentage
  }

  const reload = () => {
    setLoading(true)

    axios
      .get('ImportSession/GetProcessingImportSessions')
      .then((res) => {
        setSessions(res.data.filter((session) => session.TotalRowsCount !== 0))
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const classes = useStyles()

  moment.locale(i18n.language === 'pt' ? 'pt-br' : i18n.language)

  return (
    <div className="processing-files">
      {loading ? (
        <Loader />
      ) : (
        <Paper className={classes.table}>
          <Table rows={[]}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('import.processing.file')}
                  <IconButton
                    aria-label="delete"
                    size="small"
                    color="primary"
                    style={{ marginLeft: '10px' }}
                    onClick={reload}
                  >
                    <MdRefresh />
                  </IconButton>
                </TableCell>

                <TableCell align="center">
                  {t('import.processing.total')}
                </TableCell>

                <TableCell align="center">
                  {t('import.processing.processed')}
                </TableCell>

                <TableCell align="center">
                  {t('import.processing.errors')}
                </TableCell>

                <TableCell align="center">
                  {t('import.processing.date')}
                </TableCell>

                <TableCell align="center">
                  {t('import.processing.percentage')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sessions.length > 0 &&
                sessions.map((session, sidx) => (
                  <Fragment key={sidx}>
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.cell}
                      >
                        {session.FileName}
                      </TableCell>

                      <TableCell align="center" className={classes.cell}>
                        {session.TotalRowsCount}
                      </TableCell>

                      <TableCell align="center" className={classes.cell}>
                        {session.ProcessedItemsCount}
                      </TableCell>

                      <TableCell align="center" className={classes.cell}>
                        {session.ErrorRowsCount}
                      </TableCell>

                      <TableCell align="center" className={classes.cell}>
                        {moment(session.Date).format('L')}
                      </TableCell>

                      <TableCell align="center" className={classes.cell}>
                        {getPercentage(
                          session.TotalRowsCount,
                          session.ProcessedItemsCount,
                          session.ErrorRowsCount
                        )}
                        %
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell colSpan={6} size="small">
                        <LinearProgress
                          variant="determinate"
                          value={getPercentage(
                            session.TotalRowsCount,
                            session.ProcessedItemsCount,
                            session.ErrorRowsCount
                          )}
                        />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}

              {sessions.length < 1 && (
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    {t('import.processing.noData')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  )
}

export default withTranslation()(ProcessingFiles)
