import styled from 'styled-components'

export const SafeArea = styled.div`
  float: left;
  ${({ center }) =>
    center
      ? `
    float: inherit;
`
      : ``}
  ${({ box }) =>
    box
      ? `
        box-shadow: 0px 3px 14px 1px rgba(0, 0, 0, 0.11),
    0px 8px 10px 1px rgba(0, 0, 0, 0.13), 0px 5px 5px -3px rgba(0, 0, 0, 0.02);
  `
      : ``}
`
export const Text = styled.label`
  font-size: 16px;
`
