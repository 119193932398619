import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Image = styled.img`
  width: 100%;
  max-width: 300px;
  margin: 50px;
`
export const Title = styled.h4`
  display: flex;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: rgba(0, 0, 0, 0.54);
`
export const Subtitle = styled.span`
  display: flex;
  align-items: center;
  text-align: center;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  color: rgba(0, 0, 0, 0.54);
`
