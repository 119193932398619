import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import DateRange from '@material-ui/icons/DateRange'

import { useStyles } from './style'

const ReadonlySelect = ({ label, handleClick }) => {
  const classes = useStyles()

  return (
    <div onClick={handleClick} className={classes.root}>
      <DateRange color="primary" onClick={handleClick} />

      <span onClick={handleClick} className={classes.label}>
        {label}
      </span>

      <ArrowDropDown
        color="primary"
        onClick={handleClick}
        className={classes.icon}
      />
    </div>
  )
}

export default ReadonlySelect
