import { withTranslation } from 'react-i18next'

import banner from 'assets/img/banner.svg'
import logo from 'assets/img/logo.svg'

import 'style/route/sign/main.css'

const Skin = ({ children }) => {
  return (
    <div className="logged-out">
      <header className="logged-out-header">
        <img src={logo} alt="Logo Yourviews" className="logged-out-logo" />
        <h1 className="logged-out-title">Voices</h1>
      </header>
      <article className="logged-out-body">
        <section className="logged-out-children">{children}</section>
        <section className="logged-out-banner">
          <img
            src={banner}
            alt="Sign up Yourviews"
            className="logged-out-banner-image"
          />
        </section>
      </article>
    </div>
  )
}

export default withTranslation()(Skin)
