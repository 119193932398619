import '../../../../style/route/person/detail.css'
import { Component } from 'react'
import axios from 'core/api'
import { withTranslation } from 'react-i18next'
import { withSnackbar } from 'notistack'
import { withRouter } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'
import { BASE_PATH } from '../../../../router'
import Tooltip from '@material-ui/core/Tooltip'
import DoneIcon from '@material-ui/icons/Done'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Loader from '../../../../components/Loader'
import Header from '../../../../components/Header'
import AnswerList from '../../../Answer/components/AnswerList'
import SimpleCard from '../../../../components/SimpleCard'
import Filters, { FilterItem } from '../../../AppDrawer/styles/Filter'
// import NpsColor from '../../../../components/NpsColor/NpsColor'
// import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

class PersonDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      person:
        this.props.location.query && this.props.location.query.editing
          ? this.props.location.query.editing
          : null,
      nps: null,
      answer: [],
      pagination: {
        current: 1,
        total: 1
      },
      loading: true
    }
  }

  componentDidMount() {
    if (!this.props.location.query || !this.props.location.query.editing) {
      this.getFullPerson()
      return
    }

    this.getPersonData()
  }

  render() {
    return (
      <>
        <Header
          title={this.props.t('person.detail.title')}
          type="actions"
          items={
            <Filters justify="flex-end">
              <FilterItem>
                {this.state.person && (
                  <div className="person-action">
                    <IconButton
                      color="default"
                      component="span"
                      onClick={() => this.props.history.goBack()}
                    >
                      <Tooltip title={this.props.t('person.detail.backBtn')}>
                        <KeyboardBackspaceIcon />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      color="default"
                      component="span"
                      onClick={() => this.changeStatus()}
                    >
                      <Tooltip
                        title={
                          this.state.person.Active
                            ? this.props.t('person.detail.disableBtn')
                            : this.props.t('person.detail.activateBtn')
                        }
                      >
                        {this.state.person.Active ? (
                          <CloseIcon />
                        ) : (
                          <DoneIcon />
                        )}
                      </Tooltip>
                    </IconButton>
                  </div>
                )}
              </FilterItem>
            </Filters>
          }
        />

        <div style={{ height: 100 }} />
        {this.state.loading ? (
          <Loader />
        ) : (
          <div className="person-detail">
            {this.state.person && (
              <div>
                <header className="person-info">
                  <section
                    className={
                      'person-summary ' +
                      (!this.state.person.Active ? 'inactive' : '')
                    }
                  >
                    <div className="person-image">
                      {this.state.person.PhotoUrl ? (
                        <img
                          src={this.state.person.PhotoUrl}
                          alt="User profile"
                        />
                      ) : (
                        <FaUser />
                      )}
                    </div>

                    <div className="person-name">
                      <h2>
                        {this.state.person.Name ||
                          this.props.t('person.item.anonymous')}
                      </h2>
                    </div>

                    {/* {this.state.nps && this.state.nps.NpsAnswerCount > 0 && (
                      <NpsColor
                        type={
                          this.state.answer.length && this.state.answer[0]
                            ? this.state.answer[0].Type
                            : NPS_CSAT_TAB.nps.typeValue
                        }
                        rating={this.state.nps.NpsRatingAverage}
                        label
                        className="person-type"
                      />
                    )} */}
                  </section>

                  <section className="person-desc">
                    <div className="person-desc-left">
                      <p className="person-desc-single">
                        <strong className="person-desc-single-title">
                          {this.props.t('person.detail.emailLabel')}:
                        </strong>

                        {this.state.person.Email ||
                          this.props.t('error.noInfo')}
                      </p>

                      <p className="person-desc-single">
                        <strong className="person-desc-single-title">
                          {this.props.t('person.detail.phoneLabel')}:
                        </strong>

                        {this.state.person.Phone ||
                          this.props.t('error.noInfo')}
                      </p>

                      <p className="person-desc-single">
                        <strong className="person-desc-single-title">
                          {this.props.t('person.detail.idLabel')}:
                        </strong>

                        {this.state.person.IdPerson ||
                          this.props.t('error.noInfo')}
                      </p>

                      <p className="person-desc-single">
                        <strong className="person-desc-single-title">
                          {this.props.t('person.detail.docLabel')}:
                        </strong>

                        {this.state.person.Document ||
                          this.props.t('error.noInfo')}
                      </p>
                    </div>

                    {this.state.person.Dimensions.length > 0 && (
                      <div className="person-desc-right">
                        <h4>{this.props.t('person.detail.attrLabel')}</h4>

                        {this.state.person.Dimensions.map((dimension) => (
                          <p
                            className="person-desc-single"
                            key={dimension.IdYourviews}
                          >
                            <strong className="person-desc-single-title">
                              {dimension.Name}:
                            </strong>

                            {dimension.Values.map((value) => value.Value).join(
                              ' - '
                            )}
                          </p>
                        ))}
                      </div>
                    )}
                  </section>

                  {this.state.nps && (
                    <section className="person-nps-cards">
                      <SimpleCard
                        title={this.props.t('person.detail.cardQnt')}
                        data={this.state.nps.NpsAnswerCount}
                      />
                      {/* <SimpleCard
                        title={this.props.t('person.detail.cardValue')}
                        data={this.state.nps.NpsRatingAverage}
                      /> */}
                    </section>
                  )}
                </header>

                {this.state.answer.length > 0 && (
                  <AnswerList
                    answers={this.state.answer}
                    pagination={this.state.pagination}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  getFullPerson = () => {
    this.setState({ loading: true })

    axios
      .get('person/get?IdPerson=' + this.props.match.params.item)
      .then((res) => {
        this.setState({ person: res.data }, this.getPersonData())
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })

        this.props.history.push(BASE_PATH + 'person')
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  getPersonData = () => {
    this.setState({ loading: true })

    Promise.all([
      axios.get('person/getnpsresume?IdPerson=' + this.props.match.params.item),
      axios.get('nps/getbyfilter?IdPerson=' + this.props.match.params.item)
    ])
      .then(([resumeRes, npsRes]) => {
        this.setState({
          nps: resumeRes.data,
          answer: npsRes.data.Items,
          pagination: {
            current: npsRes.data.PageNumber,
            total: npsRes.data.PageCount
          }
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  changeStatus = () => {
    this.setState({ loading: true })

    axios
      .post(
        'person/changestatus?IdPerson=' +
          this.state.person.IdYourviews +
          '&Active=' +
          !this.state.person.Active,
        null
      )
      .then((res) => {
        if (!res.data || !res.data.Success) throw new Error('Error on update')

        let newPerson = this.state.person
        newPerson.Active = !newPerson.Active

        this.setState({
          person: newPerson
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }
}

export default withTranslation()(withRouter(withSnackbar(PersonDetail)))
