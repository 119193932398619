import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  field: {
    width: '100%',
    minWidth: 120
  }
}))

export default useStyles
