import { useEffect } from 'react'
import { useAppContext } from '../../App'

import axios from '../api'

/**
 *
 * @param {string} endPoint Endpoint route
 * @param {Function} setData Set data state function
 * @param {Function} callFinally Function for finally execution (like finally from Promise or try/catch)
 * @param {Function} blockExecute For block execution of hook
 * @param {Function} customQS custom query string (if false or undefined, the default is: `window.location.search`)
 * @param {Boolean} notScroll Control if after request should scroll to top
 * @param {Function} setLoading Set loading state function (true when start and false on finally)
 */
const useRequest = (
  endPoint,
  setData,
  callFinally,
  blockExecute,
  customQS,
  notScroll,
  setLoading
) => {
  const { notify } = useAppContext()

  useEffect(() => {
    if (blockExecute) return

    axios
      .get(endPoint + (customQS ? customQS() : window.location.search))
      .then((res) => {
        setData(res.data)
        if (!notScroll) window.scrollTo(0, 0)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        if (callFinally) callFinally()
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search])
}

export default useRequest
