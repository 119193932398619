import { useState, useCallback, useEffect } from 'react'

import { useAppContext } from 'App'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'

import useGetData from 'core/hooks/useGetData'
import { metricTypeNPS, metricTypeCSAT } from 'core/dictionaries/metric-types'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

import axios from 'core/api'

import Options from 'components/Options/Options'
import OptionsItem from 'components/Options/OptionsItem'
import TemplateItem from './components/TemplateItem'

import Loader from 'components/Loader'
import Header from 'components/Header'
import NpsCsatTabs from 'components/NpsCsatTabs'

import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'

import 'style/route/template/main.css'

const Template = ({ history, match, t, location }) => {
  const { notify, showCsat } = useAppContext()
  const [templates, setTemplates] = useState([])
  const [metricType, setMetricType] = useState('nps')

  const [loading, setLoading] = useState(false)
  const handleSetLoading = useCallback((x) => {
    setLoading(x)
  }, [])

  useGetData({
    endpoint: 'template/getall',
    setData: setTemplates,
    setLoading: handleSetLoading
  })

  const newTemplate = (type) => {
    handleSetLoading(true)

    axios
      .post(
        'template/createtemplate?type=' +
          type +
          '&templateName=' +
          t('emailType.' + type)
      )
      .then((res) => {
        history.push(`${match.url}/${res.data.Id}`, res.data)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        handleSetLoading(false)
      })
  }

  useEffect(() => {
    const { type } = queryString.parse(location.search)

    setMetricType(type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return (
    <>
      <Header
        type="actions"
        title={t('template.title')}
        items={
          <Filters justify="flex-end">
            <FilterItem>
              <Options roundedButton>
                {
                  // opçoes de nps
                  metricTypeNPS.map((x, key) => (
                    <OptionsItem
                      onClick={() => newTemplate(x)}
                      title={t('emailType.' + x)}
                      key={key}
                    />
                  ))
                }

                {
                  // opçoes de csat
                  showCsat &&
                    metricTypeCSAT.map((x, key) => (
                      <OptionsItem
                        onClick={() => newTemplate(x)}
                        title={t('emailType.' + x)}
                        key={key}
                      />
                    ))
                }
              </Options>
            </FilterItem>
          </Filters>
        }
      />

      <NpsCsatTabs match={match} />

      <section className="template-list" style={{ marginTop: 10 }}>
        {templates.length < 1 && !loading && <p>{t('template.empty')}</p>}
        <ul>
          {!loading &&
            templates
              .filter((f) => {
                return metricType === NPS_CSAT_TAB.nps.typeParam
                  ? metricTypeNPS.includes(f?.Type)
                  : metricTypeCSAT.includes(f?.Type)
              })
              .map((item) => (
                <TemplateItem key={item?.IdYourviews} template={item} />
              ))}
        </ul>
      </section>

      {loading && <Loader />}
    </>
  )
}

export default withTranslation()(Template)
