import { useEffect, useState } from 'react'

import ReactGA from 'react-ga'

import { read } from 'localstorage-helpr'
import { USER, STORE } from '../constants/local-storage'

import { isHiOrYvEmail } from '../helpers'

const gaEvent = (eventObj) => {
  if (process.env.REACT_APP_STAGE !== 'qa') {
    ReactGA.event(eventObj)
  }
}

const gaSet = (setObj) => {
  if (process.env.REACT_APP_STAGE !== 'qa') {
    ReactGA.set(setObj)
  }
}

const useEventAnalytic = () => {
  const [user] = useState(read(USER))
  const [store] = useState(read(STORE))

  useEffect(() => {
    if (user && store) {
      gaSet({
        dimension1: store.Key,
        dimension2: user.IdYourviews,
        dimension3: user.Email
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * @param {string} action Detalhe do que foi feito
   * @param {string} category Categoria da Ação
   */
  const track = (action, category) => {
    if (user && !isHiOrYvEmail(user.Email)) {
      gaEvent({ action, category })
    }
  }

  /**
   * @param {string} action Detalhe do que foi feito
   * @param {string} category Categoria da Ação
   * @param {string} email Email para verificaçao de dominio
   */
  const trackSaveNps = (action, category, email) => {
    if (user && !isHiOrYvEmail(email)) {
      gaEvent({ action, category })
    }
  }

  /**
   * @param {string} action Detalhe do que foi feito
   * @param {string} category Categoria da Ação
   */
  const trackAnonymous = (action, category) => {
    gaEvent({ action, category })
  }

  /**
   * @param {string} action Detalhe do que foi feito
   * @param {string} category Categoria da Ação
   * @param {string} storeKey Key da Loja criada
   * @param {string} userId Id do Usuário criado
   */
  const trackSignup = (action, category, storeKey, userId, userEmail) => {
    if (!isHiOrYvEmail(userEmail)) {
      gaSet({
        dimension1: storeKey,
        dimension2: userId
      })

      gaEvent({ action, category })
    }
  }

  return { track, trackSignup, isHiOrYvEmail, trackSaveNps, trackAnonymous }
}

export default useEventAnalytic
