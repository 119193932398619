import useRequest from './useRequest'

const useRequestPaged = (endPoint, setData, setPagination) => {
  useRequest(endPoint, (data) => {
    setData(data)
    setPagination({
      current: data.PageNumber,
      total: data.PageCount
    })
  })
}

export default useRequestPaged
