import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const ViewModeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const SuperiorSubtitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
`
export const Title = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #000000;
`
export const AnswerQuantity = styled.h3`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`

export const useStyles = makeStyles((theme) => ({
  divider: {
    margin: ' 4px 0px'
  }
}))
