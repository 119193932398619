import { makeStyles } from '@material-ui/core/styles'

import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(() => ({
  wrap: {
    flexDirection: 'row'
  },
  center: {
    flexDirection: 'row',
    margin: "20px 0"
  }
}))

const RadioboxGroup = ({
  error,
  field,
  value,
  touched,
  label,
  children,
  inBlock,
  index,
  isColorScale
}) => {
  const classes = useStyles()

  return (
    <FormControl
      component="fieldset"
      error={!!(error && touched)}
      className="checkbox-group"
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup
        data-testid={'radio-group-' + index}
        className={
          `${inBlock ? null : classes.wrap}
          ${isColorScale ? classes.center : null}`
        }
        style={{
          justifyContent: field?.FormFieldValues?.length > 5 ? "flex-start" : "center"
        }}
      >
        {children}
      </RadioGroup>
      {!!(error && touched) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default RadioboxGroup
