import { useState } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import { CircularProgress, Tab, Tabs } from '@material-ui/core'

import { Box, Container, Content, Header, Loader, MiniLoader } from './style'

export const TabsMap = {
  active: 'active',
  inactive: 'inactive',
  all: 'all'
}

const TabsView = ({
  onChange,
  all,
  empityAll,
  loading,
  miniLoading,
  active,
  inactive,
  t
}) => {
  const [tab, setTab] = useState(TabsMap.active)

  const handleChange = (event, newValue) => {
    setTab(newValue)
    onChange(newValue)
  }

  const ChildrenMap = {
    all: all,
    active: active,
    inactive: inactive
  }

  return (
    <Container>
      <Header>
        <Box>
          <Tabs
            centered
            value={tab}
            textColor="primary"
            onChange={handleChange}
            indicatorColor="primary"
            data-
            testid="tabs-view-component"
          >
            <Tab
              value={TabsMap.active}
              data-testid="active-tab"
              label={t('campaign.active.title')}
            />
            <Tab
              value={TabsMap.inactive}
              data-testid="inactive-tab"
              label={t('campaign.inactive.title')}
            />
            {!empityAll && (
              <Tab
                value={TabsMap.all}
                data-testid="all-tab"
                label={t('campaign.all.title')}
              />
            )}
          </Tabs>
        </Box>

        <MiniLoader>
          {!loading && miniLoading && <CircularProgress size={20} />}
        </MiniLoader>
      </Header>

      <Content data-testid="tabs-view-content-list">
        {loading && (
          <Loader>
            <CircularProgress data-testid="tabs-view-loader" />
          </Loader>
        )}

        {!loading && ChildrenMap[tab]}
      </Content>
    </Container>
  )
}

const { bool, object, func } = PropTypes

TabsView.propTypes = {
  children: object,
  onChange: func.isRequired,
  loading: bool.isRequired
}

export default withTranslation()(TabsView)
