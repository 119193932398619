import api from 'core/api'

const getData = async (endpoint, setData, setLoading, notify) => {
  if (setLoading) setLoading(true)

  try {
    const { data } = await api.get(endpoint)

    setData(data)
  } catch (error) {
    if (notify) notify(error.message, 'error')
  } finally {
    if (setLoading) setLoading(false)
  }
}

export default getData
