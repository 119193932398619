import { Component } from 'react'

import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import queryString from 'query-string'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import LoaderText from '../../LoaderText'

import { QS_PAGE } from 'core/constants/query-strings'

import '../../../style/component/form/dropdown.css'

class DropdownSearch extends Component {
  constructor() {
    super()
    this.state = {
      current: null
    }
  }

  render() {
    return (
      <Autocomplete
        className="dropdown"
        options={this.props.loading ? [] : this.props.items}
        getOptionLabel={(option) => option.name}
        value={this.getCurrentLabel()}
        clearOnEscape={this.props.canClear}
        onChange={(event, newValue) => {
          this.selectValue(newValue)
        }}
        disableClearable={this.props.blockTrash}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              this.props.loading ? (
                <LoaderText />
              ) : (
                this.props.name || this.props.t('component.dropdown.label')
              )
            }
            fullWidth
          />
        )}
      />
    )
  }

  getCurrentLabel = () => {
    if (this.props.value && this.props.value.name !== '')
      return this.props.value

    let result = null

    if (this.state.current && this.state.current.name === 'none') return result

    if (this.state.current) return this.state.current

    if (!this.props.qs) return result

    let current = queryString.parse(this.props.location.search)

    if (!current[this.props.qs]) return result

    let itemResult = this.props.items.filter((item) => {
      if (item.id.toString() === current[this.props.qs]) return item

      return null
    })

    if (itemResult.length > 0) return itemResult[0]

    return result
  }

  selectValue = (item) => {
    if (item === null)
      item = {
        id: 'none',
        name: ''
      }

    this.setState({
      current: item
    })

    if (this.props.onSelected) this.props.onSelected(item)

    if (!this.props.qs) return

    let current = queryString.parse(this.props.location.search)
    if (current[this.props.qs] === item.id) return

    current[this.props.qs] = item.id
    if (current[this.props.qs] === 'none') delete current[this.props.qs]

    delete current[QS_PAGE]
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: queryString.stringify(current)
    })
  }
}

export default withTranslation()(
  withRouter((props) => <DropdownSearch {...props} />)
)
