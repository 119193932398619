import 'style/route/answer/main.css'
import { useCallback, useState, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import axios from 'core/api'
import exportData from './functions/exportData'
import AnswerList from './components/AnswerList'
import FilterAnswer from './components/FilterAnswer'
import Loader from 'components/Loader'
import SortBySelect from 'components/SortBySelect'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'
import Header from 'components/Header'
import RoundedButton from 'components/Button'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import { isEqual } from 'lodash'
import { QS_TYPES } from 'core/constants/query-strings'
import { QS_ORDENATION } from 'core/constants/query-strings'
import { ORDENATION_TYPE } from 'core/enums/ordenation-type'
import { QS_CAMPAIGN_LIST } from 'core/constants/query-strings'
import NpsCsatTabs from 'components/NpsCsatTabs'
import useGetData from 'core/hooks/useGetData'
import { Permissions } from 'modules/AppDrawer/enums/Permissions'
import { useAuthContext } from 'modules/Authentication'

const Answer = ({ match, t }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { user } = useAuthContext()

  const location = useLocation()
  const history = useHistory()

  const [tags, setTags] = useState([])
  const [answers, setAnswers] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [dimensions, setDimensions] = useState([])

  const [isFiltering, setIsFiltering] = useState(false)
  const [total, setTotal] = useState(0)
  const [pagination, setPagination] = useState({
    current: 1,
    total: 1
  })

  const [ordenationItems] = useState([
    { id: ORDENATION_TYPE.DateDesc, title: 'answer.recent' },
    { id: ORDENATION_TYPE.DateAsc, title: 'answer.older' }
  ])

  const [loadingGetAll, setLoadingGetAll] = useState(true)
  const [loadingGetAnswers, setLoadingGetAnswers] = useState(true)
  const loading = useMemo(
    () => loadingGetAll || loadingGetAnswers,
    [loadingGetAll, loadingGetAnswers]
  )
  const [loadingExport, setLoadingExport] = useState(false)

  const hasAnswers = useMemo(() => answers && answers.length, [answers])

  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const ordenation = useMemo(() => {
    return currentSearch[QS_ORDENATION]
  }, [currentSearch])

  const handleOnTabChange = useCallback((e, value) => {
    //remover parametro de campanha e de dimensão ao trocar tipo de respostas entre csat e nps
    setSelectedCampaignId()
  }, [])

  const [selectedCampaignId, setSelectedCampaignId] = useState()
  useEffect(() => {
    getDimensions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaignId])

  useEffect(() => {
    getAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sort = useCallback(
    (newOrdenation) => {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_ORDENATION]: newOrdenation
        })
      })
    },
    [currentSearch, history, location.pathname]
  )

  useEffect(() => {
    const newSearch = { ...currentSearch }

    if (!newSearch[QS_TYPES]) {
      newSearch[QS_TYPES] = NPS_CSAT_TAB.nps.typeParam
    }
    if (!newSearch[QS_ORDENATION]) {
      newSearch[QS_ORDENATION] = ORDENATION_TYPE.DateDesc
    }

    if (!isEqual(currentSearch, newSearch)) {
      setTimeout(() => {
        history.push({
          pathname: location.pathname,
          search: queryString.stringify(newSearch)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    setLoadingExport(loadingExport)
  }, [loadingExport])

  const getDimensions = useCallback(() => {
    let params = { [QS_CAMPAIGN_LIST]: selectedCampaignId }
    if (!selectedCampaignId || selectedCampaignId === 'all') {
      params = {}
    }

    axios
      .get('dimension/getallplain', {
        params
      })
      .then((response) => {
        setDimensions(response.data)
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      })
  }, [enqueueSnackbar, selectedCampaignId])

  const getAll = useCallback(() => {
    setLoadingGetAll(true)

    Promise.all([axios.get('tag/getall'), axios.get('campaign/getall')])
      .then(([tags, campaigns]) => {
        setTags(tags.data)
        setCampaigns(campaigns.data)
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        setLoadingGetAll(false)
      })
  }, [enqueueSnackbar])

  useGetData({
    endpoint: 'nps/getbyfilter',
    setData: (data) => {
      setTotal(data.TotalItemCount)

      setPagination({
        current: data.PageNumber,
        total: data.PageCount
      })

      setAnswers(data.Items)
    },
    setLoading: setLoadingGetAnswers,
    debounceTime: 500
  })

  // const getAnswers = useCallback(() => {
  //   setLoadingGetAnswers(true)
  //   axios
  //     .get('nps/getbyfilter' + location.search)
  //     .then((res) => {
  //       setTotal(res.data.TotalItemCount)

  //       setPagination({
  //         current: res.data.PageNumber,
  //         total: res.data.PageCount
  //       })

  //       setAnswers(res.data.Items)
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(error.message, { variant: 'error' })
  //     })
  //     .finally(() => {
  //       setLoadingGetAnswers(false)
  //     })
  // }, [enqueueSnackbar, location.search])

  function notify(data) {
    const actions = (key) => (
      <>
        <Link
          color="primary"
          underline="none"
          href={data.link}
          component={Button}
          style={{ color: '#fff' }}
        >
          {t('uiActions.button.download')}
        </Link>

        <IconButton onClick={() => closeSnackbar(key)}>
          <CloseIcon style={{ color: '#fff' }} fontSize="small" />
        </IconButton>
      </>
    )

    const notifyOptions = (variant, action, persist) => ({
      action,
      persist,
      variant,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      }
    })

    const notifyType = {
      start: () => ({
        text: t('uiActions.download.start'),
        options: notifyOptions('default')
      }),
      error: () => ({
        text: data.message,
        options: notifyOptions('error')
      }),
      success: () => ({
        text: t('uiActions.download.success'),
        options: notifyOptions('success', actions, true)
      })
    }

    const { text, options } = notifyType[data.type]()
    enqueueSnackbar(text, options)
  }

  const onCampaignSelected = useCallback((selectedCampaign) => {
    setSelectedCampaignId(selectedCampaign.id)
  }, [])

  return (
    <>
      <Header
        type="both"
        title={t('answer.title')}
        noMarginBottom
        noPaddingBottom
        items={
          <Filters justify="flex-end">
            <FilterItem>
              <RoundedButton
                size="large"
                type="filter"
                whiteTextLabel
                variant="contained"
                onClick={() => setIsFiltering(!isFiltering)}
              />
            </FilterItem>
          </Filters>
        }
      />

      <NpsCsatTabs match={match} onTabChange={handleOnTabChange} />

      <Filters>
        <FilterItem paddingTop="42px">
          <span>
            {!loading && (
              <span
                style={{
                  position: 'relative',
                  bottom: 13
                }}
              >
                {` ${total} ${t('answer.count')}`}
                {!loadingExport &&
                Permissions.managerRights.includes(user?.Role) &&
                  (
                    <>
                      {' - '}
                      <Link
                        onClick={() => {
                          notify({ type: 'start' })
                          exportData(
                            { pagination },
                            undefined,
                            setLoadingExport,
                            notify
                          )
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        {t('uiActions.exportData')}
                      </Link>
                    </>
                  )
                }
              </span>
            )}
          </span>
        </FilterItem>
        <FilterItem>
          <SortBySelect
            key={ordenation}
            changeSorting={(x) => sort(x)}
            default={ordenation}
            items={ordenationItems}
          />
        </FilterItem>
      </Filters>

      <div className="answer">
        <div className="answer-body">
          {loading && <Loader />}

          {!loading && hasAnswers > 0 && (
            <AnswerList
              tags={tags}
              total={total}
              answers={answers}
              pagination={pagination}
              campaigns={campaigns}
            />
          )}

          <Drawer
            anchor="right"
            open={isFiltering}
            onClose={() => setIsFiltering(false)}
          >
            <FilterAnswer
              tags={tags}
              ordenation={answers}
              campaigns={campaigns}
              dimensions={dimensions}
              close={() => setIsFiltering(false)}
              match={match}
              onCampaignSelected={onCampaignSelected}
              location={location}
            />
          </Drawer>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(Answer)
