/**
 * This method gets the checkboxes input and switch toggle with three states: True, False and Null (no one checked)
 * @param {MouseEvent} event Mouse Event from onClick
 * @param {string} id Id for get inputs child
 */
function toggleCheckbox(event, id) {
  let cb = event.target

  if (cb.type !== 'checkbox') {
    return true
  }

  let cbxs = document.getElementById(id).getElementsByTagName('input')

  let i = cbxs.length

  while (i--) {
    if (
      cbxs[i].type &&
      // eslint-disable-next-line eqeqeq
      cbxs[i].type == 'checkbox' &&
      cbxs[i].id !== cb.id
    ) {
      cbxs[i].checked = false
    }
  }
}

export default toggleCheckbox
