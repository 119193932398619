import { createRef, Component } from 'react'

import { withTranslation } from 'react-i18next'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import 'style/component/form/tag-autocomplete.css'

class Tags extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tags: this.props.tags || [],
      suggestions: this.props.suggestions || [],
      allSuggestions: this.props.suggestions || []
    }
    this.inputRef = createRef()
  }

  componentDidMount() {
    this.cleanSuggestions()
  }
  render() {
    return (
    
        <Autocomplete
        
          multiple
          options={
            this.props.useProps
              ? this.props.suggestions
              : this.state.suggestions
          }
          getOptionLabel={(option) => option.name}
          defaultValue={this.props.hideTags ? [] : this.state.tags}
          onChange={(event, values) => {
            this.handleChange(values)
          }}
          ref={this.inputRef}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={
                this.props.placeholder ||
                this.props.t('tagComponent.placeholder')
              }
              fullWidth
              onKeyUp={this.tryToAdd}
            />
          )}
        />
      
    )
  }

  tryToAdd = () => {
    if (!this.props.allowNew) return

    let newSuggestions = this.state.suggestions
    let newVal = this.inputRef.current.getElementsByTagName('input')[0].value

    if (newVal.length < 1) return

    const oldTag = this.state.suggestions.findIndex((s) => s.id === 'newTag')
    if (oldTag > -1) newSuggestions.splice(oldTag, 1)

    newSuggestions.push({
      id: 'newTag',
      name: newVal
    })
    this.setState({
      suggestions: newSuggestions
    })
  }

  handleChange = (items) => {
    this.setState({ tags: items }, () => {
      this.cleanSuggestions()
      this.updateProps()
    })
  }

  updateProps = () => {
    if (this.props.update) this.props.update(this.state.tags)
  }

  cleanSuggestions = () => {
    const newArray = this.state.allSuggestions.filter((item) => {
      if (!this.state.tags.find((x) => x.id === item.id)) return item
      return null
    })
    this.setState({ suggestions: newArray })
  }
}

export default withTranslation()(Tags)
