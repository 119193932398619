const NotificationItems = [
  {
    id: 'promoters',
    title: 'admin.notifications.answer.promoters',
    switchs: [
      {
        name: 'PromoterDailyResumeActive',
        label: 'admin.notifications.notify.daily'
      },
      {
        name: 'PromoterNotificationImmediately',
        label: 'admin.notifications.notify.immediately'
      }
    ]
  },
  {
    id: 'neutrals',
    title: 'admin.notifications.answer.neutrals',
    switchs: [
      {
        name: 'NeutralDailyResumeActive',
        label: 'admin.notifications.notify.daily'
      },
      {
        name: 'NeutralNotificationImmediately',
        label: 'admin.notifications.notify.immediately'
      }
    ]
  },
  {
    id: 'detractors',
    title: 'admin.notifications.answer.detractors',
    switchs: [
      {
        name: 'DetractorDailyResumeActive',
        label: 'admin.notifications.notify.daily'
      },
      {
        name: 'DetractorNotificationImmediately',
        label: 'admin.notifications.notify.immediately'
      }
    ]
  }
]

export default NotificationItems
