const intlFloatPoint = (value, lang) =>
  lang === 'en' || lang === 'en-US'
    ? value
    : value?.toString().replace('.', ',')

const roundNumber = (number, houses, language = 'pt') => {
  return intlFloatPoint((number || 0).toFixed(houses), language)
}

export { intlFloatPoint, roundNumber }
