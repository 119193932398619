import { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Button from '@material-ui/core/Button'
import { MdDragHandle, MdClose, MdAddCircle, MdPlayArrow } from 'react-icons/md'

import { uuid, array } from 'core/helpers/index'
import useInput from 'core/hooks/useInput'

import Dialog from '../../../../components/Modal/Dialog'

const InputText = ({ initial, changed, placeholder }) => {
  const { bind } = useInput(initial, changed)

  return (
    <input
      {...bind}
      type="text"
      placeholder={placeholder}
      className="custom-field-values-editor-field"
    />
  )
}

const CustomFieldValuesEditor = ({ FormFieldValues, onChange, t }) => {
  const [values, setValues] = useState(
    FormFieldValues && FormFieldValues.length > 0
      ? FormFieldValues
      : [
          {
            Id: 1,
            Value: '',
            placeholder: `${t('formEditor.field.values.mock')} 1`,
            Key: uuid.generator()
          },
          {
            Id: 2,
            Value: '',
            placeholder: `${t('formEditor.field.values.mock')} 2`,
            Key: uuid.generator()
          }
        ]
  )
  const [isRemovingValue, setIsRemovingValue] = useState(-1)

  const changeValue = (val, idx) => {
    let newValues = values
    newValues[idx].Value = val
    setValues(newValues)
    onChange(newValues)
  }

  const removeItem = (idx) => {
    values.splice(idx, 1)
    setValues([...values])
    onChange([...values])
    setIsRemovingValue(-1)
  }

  const addItem = () => {
    setValues([
      ...values,
      {
        Id: values.length + 1,
        Value: '',
        placeholder: `${t('formEditor.field.values.mock')} ${
          values.length + 1
        }`,
        Key: uuid.generator()
      }
    ])
    onChange([
      ...values,
      {
        Id: null,
        Value: '',
        placeholder: `${t('formEditor.field.values.mock')} ${
          values.length + 1
        }`,
        Key: uuid.generator()
      }
    ])
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newValues = array.reorder(
      values,
      result.source.index,
      result.destination.index
    )

    setValues(newValues)
    onChange(newValues)
  }

  return (
    <div className="custom-field-values-editor">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`droppable-content custom-field-values-editor-list
                ${snapshot.isDraggingOver ? 'droppable-content-dragging' : ''}`}
            >
              {values.map((field, fidx) => (
                <Draggable key={field.Key} draggableId={field.Key} index={fidx}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`custom-field-values-editor-item droppable-item 
                        ${
                          snapshot.isDragging ? 'droppable-item-dragging' : ''
                        }`}
                    >
                      <MdDragHandle className="custom-field-values-editor-drag" />
                      <MdPlayArrow className="custom-field-values-editor-arrow" />
                      <InputText
                        initial={field.Value || ''}
                        changed={(val) => changeValue(val, fidx)}
                        placeholder={field.placeholder}
                      />
                      {values.length > 2 && (
                        <Button
                          type="button"
                          onClick={() => setIsRemovingValue(fidx)}
                          className="btn-page-action btn-page-action-small cancel btn-page-action-cancel"
                        >
                          <MdClose />
                        </Button>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button color="primary" type="button" onClick={() => addItem()}>
        <MdAddCircle className="custom-field-values-editor-add-icon" />
        {t('formEditor.field.values.addValue')}
      </Button>
      <Dialog
        show={isRemovingValue > -1}
        onClose={() => setIsRemovingValue(-1)}
        onCancel={() => setIsRemovingValue(-1)}
        onAccept={() => removeItem(isRemovingValue)}
      >
        <h3 className="modal-title">
          {t('formEditor.field.values.deletingTitle')}
        </h3>
        <p className="modal-text">
          {t('formEditor.field.values.deletingQuestion')}
          <br />
          <strong>{t('formEditor.field.values.deletingWarning')}</strong>
        </p>
      </Dialog>
    </div>
  )
}

export default withTranslation()(CustomFieldValuesEditor)
