import { Component } from 'react'

import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { Formik, Form, Field } from 'formik'

import Loader from '../../../../components/Loader'

import Yup from 'core/validations/index'
import axios from 'core/api'

import { dimension } from 'core/helpers/index'

import _ from 'lodash'

import RadioboxGroup from '../../../../components/Form/Box/RadioboxGroup'
import Radiobox from '../../../../components/Form/Box/Radiobox'
import TextField from '../../../../components/Form/Text/TextField'
import FileIcon from '../FileIcon'
import SpreadsheetPreview from '../SpreadsheetPreview'

import { BASE_PATH } from '../../../../router'
import { QS_IMPORT_SESSION } from 'core/constants/query-strings'
import { DIMENSION_ORIGIN } from 'core/enums/dimension-origin'
import { withSnackbar } from 'notistack'

class Link extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: this.props.location.state || null,
      dimensions: [],
      targetList: [],
      columns: [],
      errors: [],
      loading: false
    }
  }
  componentDidMount() {
    if (this.state.file === null) {
      this.props.history.push(BASE_PATH + 'import')
      return
    }

    this.getData()
  }
  render() {
    const initialValues = {
      type: '',
      people: '',
      list: ''
    }

    const fieldsValidation = Yup.object().shape({
      type: Yup.string().required(),
      people: Yup.string().when('type', {
        is: 'some',
        then: Yup.string().required()
      }),
      list: Yup.string().when('type', {
        is: 'new',
        then: Yup.string().min(3).max(80).required()
      })
    })

    return (
      this.state.file !== null && (
        <>
          {!this.state.loading && (
            <div className="link">
              <div className="link-header">
                <div className="link-file-info">
                  <span className="link-file-name">
                    <FileIcon extension={this.state.file.FileExtension} />
                    {this.state.file.FileName}
                  </span>
                  <span className="link-file-lines">
                    {this.state.file.ProcessFileResult.RowCount +
                      ' ' +
                      this.props.t('import.link.lines')}
                  </span>
                </div>
                <p className="link-desc">{this.props.t('import.link.desc')}</p>
              </div>

              <article className="link-body">
                <div className="link-spreadsheet-data">
                  <div
                    className={
                      'link-columns ' +
                      (this.state.errors.length > 0 ? 'has-errors' : '')
                    }
                  >
                    {this.state.file.ProcessFileResult.Preview.map(
                      (item, index) => (
                        <SpreadsheetPreview
                          key={index}
                          data={item}
                          setColumn={(dimension, notImport) =>
                            this.setColumn(dimension, notImport, index)
                          }
                          dimensions={this.state.dimensions}
                          addDimension={(name) =>
                            this.addDimension(name, index)
                          }
                        />
                      )
                    )}
                  </div>
                </div>

                <ul className="link-columns-errors">
                  {this.state.errors.map((item, index) => (
                    <li key={index} className="link-columns-single-error">
                      {item}
                    </li>
                  ))}
                </ul>

                <div className="link-people white-form">
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => {
                      this.submit(values, setSubmitting)
                    }}
                    validationSchema={fieldsValidation}
                  >
                    {(props) => {
                      return (
                        <Form>
                          <RadioboxGroup
                            inBlock
                            error={props.errors.type}
                            touched={props.touched.type}
                          >
                            <Field
                              name="type"
                              label={this.props.t('import.link.list.newList')}
                              id="new"
                              valuetype={0}
                              component={Radiobox}
                            />
                            {this.state.targetList.length > 0 && (
                              <Field
                                name="type"
                                label={this.props.t(
                                  'import.link.list.someList'
                                )}
                                id="some"
                                valuetype={0}
                                component={Radiobox}
                              />
                            )}
                          </RadioboxGroup>

                          {this.state.targetList.length > 0 &&
                            props.values.type === 'some' && (
                              <div className="link-checkbox-lists">
                                <RadioboxGroup
                                  inBlock
                                  error={props.errors.people}
                                  touched={props.touched.people}
                                >
                                  {this.state.targetList.map((item) => (
                                    <Field
                                      name="people"
                                      label={item.Name}
                                      id={item.Name}
                                      valuetype={0}
                                      key={item.IdYourviews}
                                      component={Radiobox}
                                    />
                                  ))}
                                </RadioboxGroup>
                              </div>
                            )}
                          {props.values.type === 'new' && (
                            <Field
                              name="list"
                              component={TextField}
                              placeholder={this.props.t(
                                'import.link.list.newListText'
                              )}
                            />
                          )}

                          <button
                            className="btn link-continue-btn"
                            type="submit"
                            disabled={props.isSubmitting}
                          >
                            {this.props.t('import.link.list.continueBtn')}
                          </button>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              </article>
            </div>
          )}

          {this.state.loading && <Loader />}
        </>
      )
    )
  }

  isValid = () => {
    let newErrors = []
    let columns = this.state.columns

    let seen = new Set()
    var hasDuplicates = columns.some((item) => {
      if (item.NotImport) return false
      return seen.size === seen.add(item.DimensionKey).size
    })
    if (hasDuplicates) {
      newErrors.push(this.props.t('import.link.errors.duplicate'))
    }

    let hasKey = columns.find(
      (c) => c.DimensionKey === 'email' || c.DimensionKey === 'tel'
    )
      ? true
      : false
    if (!hasKey) {
      newErrors.push(this.props.t('import.link.errors.noKey'))
    }

    let hasNotSetted = columns.find(
      (c) => c.DimensionKey === '' && !c.NotImport
    )
      ? true
      : false
    if (hasNotSetted) {
      newErrors.push(this.props.t('import.link.errors.noDimension'))
    }

    this.setState({
      errors: newErrors
    })

    if (newErrors.length) return false
    else return true
  }

  parsedColumns = () => {
    return this.state.columns.map((column, index) => {
      const isDefault = typeof column.DimensionKey === 'string'

      index++

      return {
        Column: index,
        Ignore: column.NotImport,
        DefaultType: isDefault,
        Type: isDefault ? this.getDefaultType(column.DimensionKey) : 9,
        IdDimension: isDefault ? null : column.DimensionKey
      }
    })
  }

  getDefaultType = (key) => {
    switch (key) {
      case 'email':
        return 3

      case 'name':
        return 2

      case 'tel':
        return 4

      default:
        return 9
    }
  }

  getList = (values) => {
    switch (values.type) {
      case 'none':
        return null

      case 'some':
        return {
          Name: values.people,
          Active: true
        }

      case 'new':
        return {
          Name: values.list,
          Active: true
        }

      default:
        return null
    }
  }

  submit = (values, setSubmitting) => {
    if (!this.isValid()) {
      setSubmitting(false)
      return
    }

    this.setState({ loading: true })

    axios
      .post(
        `person/executeimportsession?${QS_IMPORT_SESSION}=${this.state.file.SessionKey}`,
        {
          Configurations: this.parsedColumns(),
          TargetList: this.getList(values)
        }
      )
      .then((res) => {
        if (res.data.HasWarnings && !res.data.HasMajorErrors) {
          this.props.history.push(BASE_PATH + 'import/confirmation', {
            success: false,
            message:
              res.data.ImportationMessage &&
              res.data.ImportationMessage.length > 0
                ? res.data.ImportationMessage.map(
                    (item) =>
                      `${this.props.t('import.columnLabel')}: ${
                        item.Column
                      } - ` +
                      `${this.props.t('import.lineLabel')}: ${item.Row} - ` +
                      `${this.props.t('import.messageLabel')}: ${item.Message}`
                  )
                : ['']
          })

          return
        }

        if (res.data.HasMajorErrors) {
          this.props.enqueueSnackbar(
            _.uniq(
              res.data.ProcessingLog.map((item) =>
                !item.Success ? item.Message : ''
              )
            )
              .toString()
              .replace(/,/g, '\n'),
            { variant: 'error' }
          )

          return
        }

        this.props.history.push(BASE_PATH + 'import/confirmation', {
          success: true
        })

        return
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        setSubmitting(false)
        this.setState({ loading: false })
      })
  }

  getData = () => {
    this.setState({ loading: true })

    Promise.all([
      axios.get('targetlist/getall'),
      axios.get('dimension/getallplain')
    ])
      .then(([targetRes, dimensionRes]) => {
        this.setState({
          targetList: targetRes.data,
          dimensions: dimension.toDropdownById(dimensionRes.data)
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  addDimension = (name, idx) => {
    this.setState({ loading: true })

    axios
      .post('dimension/insertupdate', {
        Key: name,
        Name: name,
        Type: 0,
        Values: [],
        Active: true,
        Origin: DIMENSION_ORIGIN.person
      })
      .then((res) => {
        if (res.data.HasErrors) throw new Error('Error on creating dimension')
        if (res.data.HasMajorErrors)
          throw new Error('Error on creating dimension')

        const dimension = {
          id: res.data.Element.IdYourviews,
          name: res.data.Element.Name
        }

        let newFile = this.state.file
        newFile.ProcessFileResult.Preview[idx].Added = dimension

        let newDimensions = this.state.dimensions
        newDimensions.push(dimension)

        this.setColumn(dimension, null, idx)

        this.setState({
          file: newFile,
          dimensions: newDimensions
        })
      })
      .catch((error) => {
        this.props.enqueueSnackbar(error.message, { variant: 'error' })
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  setColumn = (dimension, notImport, index) => {
    let newColumn = this.state.columns
    newColumn[index] = {
      DimensionKey: dimension.id,
      NotImport: this.setNotImport(dimension, notImport, index)
    }

    let newFile = this.state.file
    newFile.ProcessFileResult.Preview[index].Added = {
      id: dimension.id,
      name: dimension.name
    }

    this.setState({
      columns: newColumn,
      file: newFile
    })
  }

  setNotImport = (dimension, notImport, index) => {
    const requiredDimension = ['email', 'name', 'tel']

    if (requiredDimension.some((id) => id === dimension.id)) return false

    return notImport === null ? this.state.columns[index].notImport : notImport
  }
}

export default withTranslation()(withRouter(withSnackbar(Link)))
