import './index.css'
import { withTranslation } from 'react-i18next'
import {
  Send,
  MailOutlined,
  DescriptionOutlined,
  Check
} from '@material-ui/icons'
import { Button, Tooltip } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'
import queryString from 'query-string'
import {
  QS_PAGE,
  QS_PAGE_SIZE,
  QS_DISPATCH_IDS
} from 'core/constants/query-strings'
import Pagination from 'components/Pagination'
import { roundNumber } from 'core/functions/intl-float-point.function'
import { Skeleton } from '@material-ui/lab'
import useGetData from 'core/hooks/useGetData'
import { formatDateToString } from 'core/functions/format-date.function'

const DispatchTable = ({ i18n, t }) => {
  const { language } = i18n
  const { pathname, search } = useLocation()
  const { push } = useHistory()

  const [data, setData] = useState({ Items: [] })
  const [loading, setLoading] = useState(true)
  useGetData({
    endpoint: '/Report/conversion-report/email/bydispatchsending',
    setData,
    setLoading: setLoading,
    debounceTime: 500
  })

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const handleChangePagination = useCallback(
    (newPage, newPageSize) => {
      push({
        pathname: pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_PAGE]: newPage,
          [QS_PAGE_SIZE]: newPageSize
        })
      })
    },
    [currentSearch, pathname, push]
  )

  const handleDispatchDetailsClick = useCallback(
    (dispatchId) => {
      push({
        pathname: pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_DISPATCH_IDS]: dispatchId
        })
      })
    },
    [currentSearch, pathname, push]
  )

  const getIconInfoComponent = useCallback(
    (value, iconComponent, tooltipText) => {
      const withoutTooltip = (
        <div className="conversion-email-dispatchtable-right-icon">
          {loading ? (
            <>
              <Skeleton variant="rect" height={24} width={24} />
              <Skeleton
                variant="rect"
                height={13}
                width={24}
                style={{ marginTop: 8 }}
              />
            </>
          ) : (
            <>
              {iconComponent}
              <div className="conversion-email-dispatchtable-right-icon-text">
                {value}
              </div>
            </>
          )}
        </div>
      )

      return loading ? (
        withoutTooltip
      ) : (
        <Tooltip title={tooltipText}>{withoutTooltip}</Tooltip>
      )
    },
    [loading]
  )

  const getPercentage = useCallback((partial, total) => {
    if (!total) {
      return 0
    }
    return (100 * partial) / total
  }, [])

  return (
    <div className="conversion-email-dispatchtable">
      <div className="conversion-email-dispatchtable-title">
        {t('conversion-report.email.dispatchs.title')}
      </div>
      <div className="conversion-email-dispatchtable-subtitle">
        {t('conversion-report.email.dispatchs.subTitle')}
      </div>

      <div className="conversion-email-dispatchtable-body">
        {(loading ? [-1, -2, -3] : data.Items).map((row, index) => (
          <div
            key={row.IdDispatchSendingSchedule || row}
            className="conversion-email-dispatchtable-row"
          >
            <div className="conversion-email-dispatchtable-left">
              <div className="conversion-email-dispatchtable-left-name">
                {loading ? (
                  <Skeleton variant="rect" height={16} width={350} />
                ) : (
                  row.TargetListName
                )}
              </div>
              <div className="conversion-email-dispatchtable-left-date">
                {loading ? (
                  <Skeleton
                    variant="rect"
                    height={16}
                    width={230}
                    style={{ marginTop: 3 }}
                  />
                ) : (
                  <>
                    <div>{t('conversion-report.email.dispatchs.date')}</div>
                    <div>
                      {formatDateToString({
                        date: row.ScheduleDate,
                        language
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="conversion-email-dispatchtable-right">
              {getIconInfoComponent(
                row.Data?.Sent,
                <Send style={{ color: '#757575' }} />,
                t('conversion-report.email.dispatchs.tooltips.received', {
                  count: row.Data?.Sent
                })
              )}

              {getIconInfoComponent(
                roundNumber(
                  getPercentage(row.Data?.Opened, row.Data?.Sent),
                  0,
                  language
                ) + '%',
                <MailOutlined style={{ color: '#757575' }} />,
                t('conversion-report.email.dispatchs.tooltips.openedEmail', {
                  partial: row.Data?.Opened,
                  count: row.Data?.Sent
                })
              )}

              {getIconInfoComponent(
                roundNumber(
                  getPercentage(row.Data?.Clicked, row.Data?.Sent),
                  0,
                  language
                ) + '%',
                <DescriptionOutlined style={{ color: '#757575' }} />,
                t('conversion-report.email.dispatchs.tooltips.openedForm', {
                  partial: row.Data?.Clicked,
                  count: row.Data?.Sent
                })
              )}

              {getIconInfoComponent(
                roundNumber(
                  getPercentage(row.Data?.Answered, row.Data?.Sent),
                  0,
                  language
                ) + '%',
                <Check style={{ color: '#757575' }} />,
                t('conversion-report.email.dispatchs.tooltips.replied', {
                  partial: row.Data?.Answered,
                  count: row.Data?.Sent
                })
              )}

              <div className="conversion-email-dispatchtable-right-button">
                {loading ? (
                  <Skeleton variant="rect" height={30} width={90} />
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() =>
                      handleDispatchDetailsClick(row.IdDispatchSendingSchedule)
                    }
                  >
                    {t('conversion-report.email.dispatchs.details')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <Pagination
        data={data.MetaData}
        onChangePagination={handleChangePagination}
      />
    </div>
  )
}

export default withTranslation()(DispatchTable)
