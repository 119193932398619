import i18next from 'i18next'

import { withTranslation } from 'react-i18next'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'

import { DialogTitle, DialogContent, DialogActions } from './style'

import useStyles, {
  Column,
  Divider,
  Dropdowns,
  Field,
  Item,
  Rating,
  Row
} from '../../style'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CloseIcon from '@material-ui/icons/Close'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import StarBorderIcon from '@material-ui/icons/StarBorder'

function HelpModal({ open, handleClose, t }) {
  const classes = useStyles()
  const { language } = i18next

  const dataModal = [
    ['São Paulo', 4.5, 4.5, '120'],
    ['Rio de Janeiro', 4.2, 4.2, '30'],
    ['Minas Gerais', 3, 3, '30'],
    ['Bahia', 2, 2, '25'],
    ['Acre', 1, 1, '1']
  ]

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        {t('stars.modalTitle')}
      </DialogTitle>

      <DialogContent dividers>
        <ul>
          <li style={{ height: 72, borderBottom: '1px solid #e0e0e0' }}>
            <Dropdowns>
              <Field>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    {t('stars.csat-label')}
                  </InputLabel>

                  <Select
                    style={{ width: 295 }}
                    id="campaign-dropdown-list"
                    labelId="campaign-dropdown-list"
                    value="Atendimento"
                  >
                    <MenuItem key="Atendimento" value="Atendimento">
                      {t('stars.exempleFildCsat')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Field>
              <Divider style={{ marginRight: 30 }}>
                <CloseIcon fontSize="small" color="inherit" />
              </Divider>

              <Field>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    {t('stars.dimension-label')}
                  </InputLabel>

                  <Select
                    style={{ width: 295, right: 20 }}
                    id="dimension-dropdown-list"
                    labelId="dimension-dropdown-list"
                    value="Estado"
                  >
                    <MenuItem key="estado" value="Estado">
                      {t('stars.exempleFildDimension')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Field>
            </Dropdowns>
          </li>

          {dataModal.map((item, index) => (
            <li
              style={{ height: 56, borderBottom: '1px solid #e0e0e0' }}
              key={index}
            >
              <Row>
                <Column tableStyled width="55%">
                  {item[0]}
                </Column>

                <Column width="45%">
                  <Item style={{ fontSize: 16 }} flexEnd>
                    {intlFloatPoint(item[1], language)}
                  </Item>

                  <Item>
                    <Rating
                      readOnly
                      color="primary"
                      precision={0.5}
                      value={item[2]}
                      className={classes.rating}
                      emptyIcon={
                        <StarBorderIcon
                          style={{ color: '#00AEEF' }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Item>
                  <Item flexEnd>{item[3] + ' ' + t('stars.answer')}</Item>
                </Column>
              </Row>
            </li>
          ))}
        </ul>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} autoFocus color="primary">
          {t('stars.buttonClose')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(HelpModal)
