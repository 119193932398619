import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import MaterialCard from '@material-ui/core/Card'

import useStyles from './style'
import { CardMedia, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

function CardSkelleton() {
  const classes = useStyles()

  return (
    <MaterialCard className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://lordtapes.com/wp-content/uploads/2018/10/Grey-BG.jpg"
          title="Loading"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            <Skeleton animation="wave" width="15%" />
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <Skeleton animation="wave" width="100%" />
          </Typography>
          <Typography
            className={classes.messageBox}
            color="textSecondary"
            variant="body2"
            component="div"
          >
            <Grid container>
              <Skeleton animation="wave" width="100%" />
            </Grid>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container>
          <Grid item xs={6}>
            <Skeleton
              animation="wave"
              height={20}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          </Grid>
          <Grid item xs={6}>
            <Skeleton animation="wave" height={20} width="80%" />
          </Grid>
        </Grid>
      </CardActions>
    </MaterialCard>
  )
}

export default CardSkelleton
