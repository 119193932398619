import { withTranslation } from 'react-i18next'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import {
  Actions,
  Container,
  Dropdown,
  Header,
  PageIndicator,
  PageNumberOffset,
  Title
} from './style'
import { useEffect, useMemo, useState } from 'react'

function Pagination({ data, onChangePagination, t }) {
  const {
    PageNumber,
    PageSize,
    FirstItemOnPage,
    LastItemOnPage,
    IsFirstPage,
    IsLastPage,
    HasNextPage,
    HasPreviousPage,
    TotalItemCount
  } = data || {}

  const handleNextPage = () => {
    onChangePagination(PageNumber + 1, PageSize)
  }

  const handlePreviousPage = () => {
    onChangePagination(PageNumber - 1, PageSize)
  }

  const handleChangePageSize = (e) => {
    onChangePagination(1, e.target.value)
  }

  const [pageSizeKey, setPageSizeKey] = useState(1)
  const initialValue = useMemo(() => {
    return TotalItemCount < PageSize ? TotalItemCount : PageSize
  }, [PageSize, TotalItemCount])

  useEffect(() => {
    setPageSizeKey(pageSizeKey + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <Container data-testid="campaign-pagination">
      <Header>
        <Title>{t('uiActions.pagination.linesPerPage')}</Title>

        <Dropdown>
          <Select
            key={pageSizeKey}
            value={initialValue ?? ''}
            onChange={handleChangePageSize}
            id="campaign-list-quantity-select"
            labelId="campaign-list-quantity-select"
            data-testid="campaign-list-quantity-select"
          >
            {[5, 10, 15, 20, 25, 30, 50].map(
              (value) =>
                value < TotalItemCount && (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid={'campaign-list-quantity-' + value}
                  >
                    {value}
                  </MenuItem>
                )
            )}

            <MenuItem
              value={parseInt(TotalItemCount)}
              data-testid={'campaign-list-quantity-all-' + TotalItemCount}
            >
              {t('uiActions.pagination.all')}
            </MenuItem>
          </Select>
        </Dropdown>
      </Header>

      <PageIndicator>
        {FirstItemOnPage && LastItemOnPage && TotalItemCount && (
          <PageNumberOffset data-testid="campaign-pagination-offset">
            {FirstItemOnPage}-{LastItemOnPage} {t('uiActions.pagination.of')}{' '}
            {TotalItemCount}
          </PageNumberOffset>
        )}
      </PageIndicator>

      <Actions>
        <IconButton
          onClick={handlePreviousPage}
          disabled={IsFirstPage || !HasPreviousPage}
          data-testid="campaign-pagination-previous-page-btn"
        >
          <ChevronLeftIcon />
        </IconButton>

        <IconButton
          onClick={handleNextPage}
          disabled={IsLastPage || !HasNextPage}
          data-testid="campaign-pagination-next-page-btn"
        >
          <ChevronRightIcon />
        </IconButton>
      </Actions>
    </Container>
  )
}

export default withTranslation()(Pagination)
