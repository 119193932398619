import { useState, useEffect } from 'react'

import { create, remove } from 'localstorage-helpr'

import { withTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import { useAppContext } from '../../../../App'
import { useAuthContext } from '../../index'

import { CircularProgress } from '@material-ui/core'

import api from 'core/api'

import Skin from '../Skin'

import { queryString } from 'core/helpers'

import { TOKEN, STORE, USER } from 'core/constants/local-storage'

const SingleSignOn = ({ t }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [state] = useState({
    route: queryString.getQSValue('redir'),
    token: queryString.getQSValue('t')
  })

  const { notify } = useAppContext()
  const { handleSetUser, handleSetToken, handleSetStore } = useAuthContext()

  const authorize = ({ token, route }) => {
    remove(TOKEN)
    remove(USER)
    remove(STORE)

    setLoading(true)

    create(TOKEN, token)

    api
      .post('authentication/getauthinfos', null)
      .then(({ data }) => {
        handleSetUser(data._admin)
        handleSetStore(data._store)
        handleSetToken(data._jwtToken)

        setLoading(false)
        history.push('/admin' + decodeURI(route))
      })
      .catch((error) => {
        notify(error.message, 'error')
        setLoading(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (state.token) authorize(state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <Skin>
      <h2 className="sign-form-title">{t('sso.welcome')}</h2>

      <h4>{t('sso.wait')}</h4>

      <section>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          {loading && <CircularProgress />}
        </div>
      </section>
    </Skin>
  )
}

export default withTranslation()(SingleSignOn)
