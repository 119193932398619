import { withTranslation } from 'react-i18next'
import {
  DimensionHelpItemTitle,
  DimensionHelpText,
  DimensionHelpTitle
} from '../../style'

const Helper = ({ t }) => {
  return (
    <div>
      <DimensionHelpTitle>
        {t('campaign.peopleList.form.help.title')}
      </DimensionHelpTitle>
      <DimensionHelpText>
        {t('campaign.peopleList.form.help.description')}
      </DimensionHelpText>
      <DimensionHelpText>
        <DimensionHelpItemTitle>
          {t('campaign.peopleList.form.help.1.title')}
        </DimensionHelpItemTitle>

        {t('campaign.peopleList.form.help.1.description')}
      </DimensionHelpText>
      <DimensionHelpText>
        <DimensionHelpItemTitle>
          {t('campaign.peopleList.form.help.2.title')}
        </DimensionHelpItemTitle>

        {t('campaign.peopleList.form.help.2.description')}
      </DimensionHelpText>
      <DimensionHelpText>
        <DimensionHelpItemTitle>
          {t('campaign.peopleList.form.help.3.title')}
        </DimensionHelpItemTitle>

        {t('campaign.peopleList.form.help.3.description')}
      </DimensionHelpText>
    </div>
  )
}

export default withTranslation()(Helper)
