import { withTranslation } from 'react-i18next'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinkIcon from '@material-ui/icons/Link'
import CloseIcon from '@material-ui/icons/Close'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined'
import PowerOutlinedIcon from '@material-ui/icons/PowerOutlined'
import AssignmentOutlinedIcon from '@material-ui/icons/Assignment'
import { ListItem, SaveButton, Text, useStyles } from './style'
import { Block, FabWrapper, Label, Subtitle } from '../../style'
import { Button, Fab } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { useCampaign } from '../../context'
import { BASE_PATH } from 'router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import postData from 'core/functions/post-data.function'
import { useAppContext } from 'App'
import createLink from 'core/functions/make-link-campaign'
import { read } from 'localstorage-helpr'
import { STORE } from 'core/constants/local-storage'
import qs from 'query-string'
import getData from 'core/functions/get-data.function'
import Loader from 'components/Loader'
import { Skeleton } from '@material-ui/lab'

function VerifyDialog({ open, handleClose, t }) {
  const history = useHistory()
  const { search } = useLocation()

  const [parsedQS, setParsedQS] = useState(qs.parse(search))

  const { form, campaign, widget, email, handleSetDetails, dispatch } =
    useCampaign()
  const [loading, setLoading] = useState(false)

  const { notify } = useAppContext()

  const [allTargetListProcessingInfo, setAllTargetListProcessingInfo] =
    useState([])
  const [
    loadingAllTargetListProcessingInfo,
    setLoadingAllTargetListProcessingInfo
  ] = useState(true)

  const activeDispatchs = useMemo(() => {
    const activeDispatch = dispatch?.filter(
      (d) => d.Active && d.Status?.toString() === '0'
    )

    return activeDispatch.map((dispatch) => {
      return {
        ...dispatch,
        processingInfo: allTargetListProcessingInfo.find(
          (p) => p.IdTargetList === dispatch.IdTargetList
        )
      }
    })
  }, [allTargetListProcessingInfo, dispatch])

  const scheduledDispatchs = useMemo(() => {
    return activeDispatchs.filter((d) => d.Type.toString() === '1')
  }, [activeDispatchs])

  const scheduledEmailCount = useMemo(() => {
    let scheduledEmailCount = 0
    scheduledDispatchs.forEach((d) => {
      if (d.processingInfo) {
        // Total items processed (except the lines with error)
        scheduledEmailCount += d.processingInfo?.ProcessedItemsCount
      } else if (d.emailList) {
        scheduledEmailCount += d.emailList.PersonsCount
      }
    })
    return scheduledEmailCount
  }, [scheduledDispatchs])

  const immediateDispatchs = useMemo(() => {
    return activeDispatchs.filter((d) => d.Type.toString() === '2')
  }, [activeDispatchs])

  const immediateEmailCount = useMemo(() => {
    let immediateEmailCount = 0
    immediateDispatchs.forEach((d) => {
      if (d.processingInfo) {
        // Total items processed (except the lines with error)
        immediateEmailCount += d.processingInfo?.ProcessedItemsCount
      } else if (d.emailList) {
        immediateEmailCount += d.emailList.PersonsCount
      }
    })
    return immediateEmailCount
  }, [immediateDispatchs])

  useEffect(() => {
    getData(
      '/ImportSession/GetProcessingImportSessions?' +
        qs.stringify({
          importSessionStatusList: ['Started', 'Error', 'Finalized', 'Created']
        }),
      setAllTargetListProcessingInfo,
      setLoadingAllTargetListProcessingInfo
    )
  }, [dispatch, open])

  const submitCampaign = async () => {
    await postData({
      endpoint: 'campaign/insertupdate',
      data: campaign,
      setData: (responseData) => {
        handleSetDetails(responseData)

        history.push(
          BASE_PATH +
            'CampaignSuccess?Id=' +
            (parsedQS?.Id ? parsedQS?.Id : responseData.Element.IdYourviews)
        )
      },
      notify: (e) => {
        let errorMsg = t('campaign.details.dialog.error')
        if (e) {
          errorMsg += ` (${e})`
        }
        return notify(errorMsg, 'error')
      },
      setLoading: setLoading
    })
  }

  const handleSelectForm = useCallback(() => {
    history.push({
      pathname: BASE_PATH + 'campaign/form',
      search
    })
    handleClose()
  }, [handleClose, history, search])

  const [link, setLink] = useState(null)
  const store = read(STORE)

  useEffect(() => {
    const newLink = createLink(store?.Key, campaign.Key)
    setLink(newLink)
    setParsedQS(qs.parse(search))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const classes = useStyles()
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth="md"
      >
        {loading && (
          <div
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.1)',
              zIndex: 1
            }}
          >
            <Loader />
          </div>
        )}
        <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
          <Text
            style={{
              fontSize: '21px'
            }}
          >
            {t('campaign.details.dialog.confirmation')}
          </Text>
          <Label>{t('campaign.details.dialog.youConfirm')}</Label>
          <CloseIcon
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: '10px',
              fontSize: '23px',
              top: '10px',
              cursor: 'pointer'
            }}
          />
        </DialogTitle>
        <DialogContent>
          <ListItem style={{ marginBottom: '24px' }}>
            <Block style={{ width: '800px' }} maxWidth="800px">
              <FabWrapper>
                <Fab
                  color="primary"
                  disableFocusRipple
                  disableRipple
                  classes={{ root: classes.fab }}
                >
                  <AssignmentOutlinedIcon fontSize="large" />
                </Fab>
              </FabWrapper>

              <Block padding="0px 16px" column>
                <Subtitle>{t('campaign.details.form.subtitle')}</Subtitle>
                <Label error={!form?.Name}>
                  {form?.Name ?? t('campaign.newDetails.selectForm')}
                </Label>
              </Block>
            </Block>
            <Button
              style={{ width: '210px' }}
              color="primary"
              onClick={handleSelectForm}
            >
              {t('campaign.details.form.changeForm')}
            </Button>
          </ListItem>

          <ListItem
            style={{ borderRadius: '5px 5px 0px 0px', borderBottom: 'none' }}
          >
            <Block style={{ width: '800px' }} maxWidth="800px">
              <FabWrapper>
                <Fab
                  color={email?.Active ? 'primary' : 'default'}
                  disableFocusRipple
                  disableRipple
                  classes={{ root: classes.fab }}
                >
                  <EmailOutlinedIcon fontSize="large" />
                </Fab>
              </FabWrapper>

              <Block padding="0px 16px" column>
                <Subtitle>{t('campaign.details.channels.email')}</Subtitle>
                <Label>
                  {!campaign?.Active && (
                    <Label>
                      {t('campaign.details.dialog.noShowInactiveEmail')}
                    </Label>
                  )}
                  {campaign?.Active && !email?.Active && (
                    <Label>{t('campaign.details.dialog.disable')}</Label>
                  )}
                  {campaign?.Active &&
                    email?.Active &&
                    (loadingAllTargetListProcessingInfo ? (
                      <Skeleton variant="text" width={200} height={30} />
                    ) : (
                      <Label data-testid="total-emails">
                        {t('campaign.details.dialog.emailPeoplesTotal', {
                          scheduledDispatchCount: scheduledDispatchs.length,
                          scheduledEmailCount,
                          immediateDispatchCount: immediateDispatchs.length,
                          immediateEmailCount
                        })}
                      </Label>
                    ))}
                </Label>
              </Block>
            </Block>
          </ListItem>

          <ListItem style={{ borderRadius: '0px 0px 0px 0px' }}>
            <Block style={{ width: '800px' }} maxWidth="800px">
              <FabWrapper>
                <Fab
                  color={
                    campaign?.Active && widget?.Active ? 'primary' : 'default'
                  }
                  disableFocusRipple
                  disableRipple
                  classes={{ root: classes.fab }}
                >
                  <PowerOutlinedIcon fontSize="large" />
                </Fab>
              </FabWrapper>

              <Block padding="0px 16px" column>
                <Subtitle>
                  {t('campaign.details.dialog.searchForSite')}
                </Subtitle>
                {!campaign?.Active && (
                  <Label>{t('campaign.details.dialog.noShowInactive')}</Label>
                )}
                {campaign?.Active && !widget?.Active && (
                  <Label>{t('campaign.details.dialog.disable')}</Label>
                )}
                {campaign?.Active && widget?.Active && (
                  <Label>
                    {t('campaign.details.dialog.confirmFor') +
                      widget.ShowChance +
                      '% ' +
                      t('campaign.details.dialog.confirmVisits')}
                  </Label>
                )}
              </Block>
            </Block>
          </ListItem>

          <ListItem
            style={{ borderRadius: '0px 0px 5px 5px', borderTop: 'none' }}
          >
            <Block style={{ width: '800px' }} maxWidth="800px">
              <FabWrapper>
                <Fab
                  color={campaign?.Active ? 'primary' : 'default'}
                  disableFocusRipple
                  disableRipple
                  classes={{ root: classes.fab }}
                >
                  <LinkIcon fontSize="large" />
                </Fab>
              </FabWrapper>

              <Block padding="0px 16px" style={{ maxWidth: '80%' }} column>
                <Subtitle>{t('campaign.details.dialog.sendLink')}</Subtitle>

                {!campaign.Active && (
                  <Label>
                    {t('campaign.details.dialog.noShowInactiveLink')}
                  </Label>
                )}
                {campaign.Active && !link && (
                  <Label>{t('campaign.confirmCampanha.linkAcsess')}</Label>
                )}
                {campaign.Active && link && (
                  <Label>{t('campaign.confirmCampanha.thisLink') + link}</Label>
                )}
              </Block>
            </Block>
          </ListItem>

          <SaveButton
            style={{ margin: '0 auto', display: 'block', marginTop: '24px' }}
            data-testid="saveButtonLarge"
            largeSize
            onClick={submitCampaign}
          >
            {t('campaign.details.dialog.buttonConfirm')}
          </SaveButton>
          <Button
            style={{
              margin: '28px auto 12px',
              display: 'block',
              marginTop: '28px'
            }}
            onClick={handleClose}
            color="primary"
          >
            {t('campaing.edit.form.newWidget.configModalCancelButton')}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default withTranslation()(VerifyDialog)
