import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {},
  cloud: {
    position: 'relative'
  },
  icon: {
    position: 'absolute',
    marginLeft: '650px',
    zIndex: 9999
  },
  hide: {
    display: 'none'
  }
}))

export default useStyles
