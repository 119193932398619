import './index.css'
import { useCallback, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import ConversionEmail from './components/ConversionEmail'
import ConversionLink from './components/ConversionLink'
import ConversionWidget from './components/ConversionWidget'
import Header from 'components/Header'
import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'
import DateRangePicker from 'components/DateRangePicker'
import DropdownCampaign from 'components/Dropdown/DropdownCampaign'
import CustomTabs from 'components/CustomTabs'
import queryString from 'query-string'
import { useHistory, useLocation } from 'react-router-dom'
import { QS_DISPATCH_IDS } from 'core/constants/query-strings'
import {
  ArrowBack,
  MailOutline,
  PowerOutlined,
  Link,
  Edit
} from '@material-ui/icons'
import { Button, IconButton } from '@material-ui/core'
import { BASE_PATH } from 'router'
import { Skeleton } from '@material-ui/lab'
import useGetData from 'core/hooks/useGetData'
import { formatDateToString } from 'core/functions/format-date.function'

export const ViewMode = {
  email: 'e-mail',
  widget: 'widget',
  link: 'link'
}

const Conversion = ({ match, i18n, t }) => {
  const { language } = i18n
  const { push } = useHistory()
  const { search, pathname } = useLocation()

  const [campaign, setCampaign] = useState({})
  const [campaignsLoading, setCampaignsLoading] = useState(true)

  const [emailData, setEmailData] = useState({})
  const [loadingEmailData, setLoadingEmailData] = useState(true)
  useGetData({
    endpoint: '/Report/conversion-report/email',
    setData: setEmailData,
    setLoading: setLoadingEmailData,
    debounceTime: 500
  })

  const [widgetData, setWidgetData] = useState({})
  const [loadingWidgetData, setLoadingWidgetData] = useState(true)
  useGetData({
    endpoint: '/Report/conversion-report/widget',
    setData: setWidgetData,
    setLoading: setLoadingWidgetData,
    debounceTime: 500
  })

  const [linkData, setLinkData] = useState({})
  const [loadingLinkData, setLoadingLinkData] = useState(true)
  useGetData({
    endpoint: '/Report/conversion-report/direct-link',
    setData: setLinkData,
    setLoading: setLoadingLinkData,
    debounceTime: 500
  })

  const [dispatchs, setDispatchs] = useState([])
  const [loadingDispatchs, setLoadingDispatchs] = useState(true)
  useGetData({
    endpoint: '/DispatchSendingSchedule/GetByIdCampaign',
    setData: setDispatchs,
    setLoading: setLoadingDispatchs,
    debounceTime: 500
  })

  const [targetLists, setTargetLists] = useState([])
  const [loadingTargetLists, setLoadingTargetLists] = useState(true)
  useGetData({
    endpoint: '/TargetList/GetAll',
    setData: setTargetLists,
    setLoading: setLoadingTargetLists,
    debounceTime: 500
  })

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const currentDispatchId = useMemo(() => {
    return currentSearch[QS_DISPATCH_IDS]
  }, [currentSearch])

  const currentDispatch = useMemo(() => {
    return dispatchs.find((d) => d.IdYourviews.toString() === currentDispatchId)
  }, [currentDispatchId, dispatchs])

  const currentTargetList = useMemo(() => {
    return targetLists.find(
      (t) =>
        t.IdYourviews.toString() === currentDispatch?.IdTargetList.toString()
    )
  }, [currentDispatch, targetLists])

  const currentDispatchTitle = useMemo(() => {
    return t('conversion-report.dispatchTitle', {
      date: formatDateToString({
        date: currentDispatch?.DateTimezone,
        language
      }),
      listName: currentTargetList?.Name
    })
  }, [currentDispatch?.DateTimezone, currentTargetList?.Name, language, t])

  const currentViewBasedOnRoute = useMemo(() => {
    return {
      [ViewMode.email]: (
        <ConversionEmail
          emailData={emailData}
          loadingEmailData={loadingEmailData}
          currentTargetList={currentTargetList}
          currentDispatchTitle={currentDispatchTitle}
        />
      ),
      [ViewMode.widget]: (
        <ConversionWidget
          widgetData={widgetData}
          loadingWidgetData={loadingWidgetData}
        />
      ),
      [ViewMode.link]: (
        <ConversionLink linkData={linkData} loadingLinkData={loadingLinkData} />
      )
    }[match.params.view]
  }, [
    currentDispatchTitle,
    currentTargetList,
    emailData,
    linkData,
    loadingEmailData,
    loadingLinkData,
    loadingWidgetData,
    match.params.view,
    widgetData
  ])

  const handleBack = useCallback(() => {
    delete currentSearch[QS_DISPATCH_IDS]
    push({
      pathname: pathname,
      search: queryString.stringify(currentSearch)
    })
  }, [currentSearch, pathname, push])

  const handleEditCampaign = useCallback(() => {
    push({
      pathname: BASE_PATH + 'campaign/details',
      search: queryString.stringify({ Id: campaign.IdYourviews })
    })
  }, [campaign, push])

  const loadingHeader = useMemo(() => {
    return (
      loadingEmailData ||
      loadingWidgetData ||
      loadingLinkData ||
      loadingDispatchs ||
      loadingTargetLists ||
      campaignsLoading
    )
  }, [
    loadingEmailData,
    loadingWidgetData,
    loadingLinkData,
    loadingDispatchs,
    loadingTargetLists,
    campaignsLoading
  ])

  return (
    <div>
      {!currentDispatchId && (
        <>
          <div className="conversion-subtitle">
            {t('conversion-report.subTitle')}
          </div>

          <Header
            type="filters"
            title={t('conversion-report.title')}
            items={
              <Filters>
                <FilterItem>
                  <DateRangePicker select />
                </FilterItem>
                <FilterItem>
                  <DropdownCampaign
                    onSelectedChange={setCampaign}
                    onLoadingChange={setCampaignsLoading}
                    hideAllOption
                  />
                </FilterItem>
              </Filters>
            }
          />

          <div className="conversion-header-footer">
            <div className="conversion-header-footer-campaign">
              {loadingHeader ? (
                <Skeleton variant="rect" height={30} width={550} />
              ) : (
                <>
                  {campaign?.Name}

                  <Button onClick={handleEditCampaign} color="primary">
                    <Edit color="primary" style={{ margin: 5 }} />
                    {t('conversion-report.editCampaign')}
                  </Button>
                </>
              )}
            </div>
            <div className="conversion-header-footer-answers">
              <div className="conversion-header-footer-answers-label">
                {loadingHeader ? (
                  <Skeleton variant="rect" height={11} width={100} />
                ) : (
                  t('conversion-report.totalAnswersLabel')
                )}
              </div>
              <div className="conversion-header-footer-answers-value">
                {!loadingHeader &&
                  t('conversion-report.totalAnswersValue', {
                    count:
                      emailData.Answered +
                      widgetData.Answered +
                      linkData.Answered
                  })}
              </div>

              {loadingHeader ? (
                <Skeleton variant="rect" height={24} width={24} />
              ) : (
                <MailOutline color="primary" />
              )}
              <div className="conversion-header-footer-answers-value">
                {loadingHeader ? (
                  <Skeleton variant="rect" height={11} width={100} />
                ) : (
                  t('conversion-report.byEmail', {
                    count: emailData.Answered
                  })
                )}
              </div>

              {loadingHeader ? (
                <Skeleton variant="rect" height={24} width={24} />
              ) : (
                <PowerOutlined color="primary" />
              )}
              <div className="conversion-header-footer-answers-value">
                {loadingHeader ? (
                  <Skeleton variant="rect" height={11} width={100} />
                ) : (
                  t('conversion-report.byWidget', {
                    count: widgetData.Answered
                  })
                )}
              </div>

              {loadingHeader ? (
                <Skeleton variant="rect" height={24} width={24} />
              ) : (
                <Link color="primary" />
              )}
              <div className="conversion-header-footer-answers-value">
                {loadingHeader ? (
                  <Skeleton variant="rect" height={11} width={100} />
                ) : (
                  t('conversion-report.byLink', {
                    count: linkData.Answered
                  })
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {currentDispatchId && match.params.view === ViewMode.email ? (
        <div className="conversion-back">
          <IconButton
            onClick={handleBack}
            data-testid="info-bar-handle-back-action"
          >
            <ArrowBack color="primary" />
          </IconButton>
          <div className="conversion-back-text">{currentDispatchTitle}</div>
        </div>
      ) : (
        <CustomTabs
          match={match}
          pathParam="view"
          tabs={Object.values(ViewMode).map((view) => {
            return { value: view, label: view }
          })}
        />
      )}

      {currentViewBasedOnRoute}
    </div>
  )
}

export default withTranslation()(Conversion)
