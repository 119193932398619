import { withTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import { NPS_COLORS } from 'core/enums/nps-color'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import ExportDataButton from '../ExportDataButton'
import { Skeleton } from '@material-ui/lab'
import { QS_PAGE_SIZE } from 'core/constants/query-strings'
import { QS_PAGE } from 'core/constants/query-strings'
import Pagination from 'components/Pagination'

const Answers = ({ data, loading, t }) => {
  const history = useHistory()

  const getNpsColor = useCallback((rating) => {
    let color
    Object.values(NPS_COLORS).forEach((colorObj) => {
      if (rating >= colorObj.min && rating < colorObj.max) {
        color = colorObj
      }
    })
    return color
  }, [])

  const itemsWithColor = useMemo(() => {
    if (!data.Items) {
      return []
    }
    return data.Items.map((item) => {
      return { ...item, color: getNpsColor(item.Rating) }
    })
  }, [data.Items, getNpsColor])

  const itemSkeleton = useMemo(
    () => (
      <div className="evolution-answers-table-item">
        <div className="evolution-answers-table-item-left">
          <div className="evolution-answers-table-item-number">
            <Skeleton variant="circle" width="100%" height={40} />
          </div>
          <div>
            <div className="evolution-answers-table-item-message">
              <Skeleton variant="rect" width="100px" height={10} />
            </div>
            <div className="evolution-answers-table-item-user">
              <div className="evolution-answers-table-item-user-name">
                <Skeleton variant="rect" width="100px" height={10} />
              </div>
              <div className="evolution-answers-table-item-user-mail">
                <Skeleton variant="rect" width="100px" height={10} />
              </div>
            </div>
          </div>
        </div>
        <div className="evolution-answers-table-item-date">
          <Skeleton variant="rect" width="100%" height={10} />
        </div>
      </div>
    ),
    []
  )

  const currentSearch = useMemo(() => {
    return queryString.parse(history.location.search)
  }, [history.location.search])

  const handleChangePagination = useCallback(
    (newPage, newPageSize) => {
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_PAGE]: newPage,
          [QS_PAGE_SIZE]: newPageSize
        })
      })
    },
    [currentSearch, history]
  )

  return loading ? (
    <div className="evolution-answers-wrapper">
      <div className="evolution-answers-header">
        <div>
          <Skeleton variant="rect" width="150px" height={20} />
        </div>
        <div>
          <Skeleton variant="rect" width="150px" height={30} />
        </div>
      </div>
      <div className="evolution-answers-table">
        {itemSkeleton}
        {itemSkeleton}
      </div>
    </div>
  ) : (
    <div className="evolution-answers-wrapper">
      <div className="evolution-answers-header">
        <div>{t('evolution.answers.allAnswers')}</div>
        <ExportDataButton />
      </div>
      <div className="evolution-answers-table">
        {!data.Items || !data.Items.length ? (
          <div className="evolution-answers-empty">
            {t('evolution.noFilteredData')}
          </div>
        ) : (
          <>
            {itemsWithColor.map((item, index) => (
              <div
                key={item.IdYourviews}
                className="evolution-answers-table-item"
              >
                <div className="evolution-answers-table-item-left">
                  <div
                    className="evolution-answers-table-item-number"
                    style={{
                      background: item.color.lightColor,
                      color: item.color.darkColor
                    }}
                  >
                    {item.Rating}
                  </div>
                  <div>
                    <div className="evolution-answers-table-item-message">
                      {item.Comment || t('evolution.answers.noComment')}
                    </div>
                    <div className="evolution-answers-table-item-user">
                      <div className="evolution-answers-table-item-user-name">
                        {item.Person.Name}
                      </div>
                      <div className="evolution-answers-table-item-user-mail">
                        {item.Person.Email}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="evolution-answers-table-item-date">
                  {t('{{val, datetime}}', {
                    val: new Date(item.Date),
                    formatParams: {
                      val: {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      }
                    }
                  })}
                </div>
              </div>
            ))}
          </>
        )}
      </div>

      <Pagination
        data={data?.MetaData}
        onChangePagination={handleChangePagination}
      />
    </div>
  )
}

export default withTranslation()(Answers)
