/* eslint-disable no-loop-func */
import { withTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Alert, AlertTitle } from '@material-ui/lab'

import {
  Container,
  ChangeLanguage,
  Description,
  Header,
  Logo,
  Subtitle,
  Text,
  Ul,
  Title
} from './style'

import logo from 'assets/img/logo.svg'

import qs from 'query-string'
import Language from 'modules/AppDrawer/components/LanguageSelector'
import { useAppContext } from 'App'
import { Li } from 'pages/QaTests/style'
import RenderSyntax from 'modules/Campaign/components/Widget/components/RenderSyntax'
import { Row } from 'modules/Campaign/components/Form/style'

function WidgetScript({ t }) {
  const { queryString } = useAppContext()
  const { push } = useHistory()
  const { search, pathname } = useLocation()

  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = useState(null)
  const handleSetDimensions = useCallback((value) => {
    setDimensions(value)
  }, [])

  const [dimensionsRender, setDiemnsionsRender] = useState([])
  const [storeKey, setStoreKey] = useState()
  var dimension = []

  useEffect(() => {
    handleSetDimensions(qs.parse(search))
    for (var property in dimensions) {
      if (property.includes('.Key')) {
        dimension.push({ Key: dimensions[property] })
        setDiemnsionsRender(dimensionsRender.concat(dimension))
      } else if (property.includes('StoreKey')) {
        setStoreKey({ Key: dimensions[property] })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    push({
      pathname,
      search: queryString
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString])

  let text =
    '//Valores fixos, cliente não deve alterar\n' +
    "<script type='text/javascript'>\n" +
    'window.yvnps=window.yvnps||[];\n' +
    '//Vem por padrão do backend. Cliente não deve alterar\n' +
    'window.yvnps.push({account:' +
    '"' +
    storeKey?.Key +
    '"' +
    '});\n' +
    '//Obrigatório, sistema do cliente deverá preencher\n' +
    "window.yvnps.push({name:'NOME DO CONSUMIDOR'});\n" +
    '//UserId ou E-mail obrigatórios, sistema do cliente deverá preencher\n' +
    "window.yvnps.push({userid:'ID DO USUÁRIO'});\n" +
    "window.yvnps.push({userid:'EMAIL@EMAIL.COM'});\n" +
    '/*Dimensões que o cliente deseja incluir na resposta. \n' +
    'Pode ser valor fixou ou preenchido pelo sistema do cliente*/\n' +
    (dimensionsRender &&
      dimensionsRender
        .map(
          (item, index) =>
            'window.yvnps.push({"dimension_' +
            item.Key +
            '"' +
            ': "substitua aqui o valor"});\n'
        )
        .join('')) +
    '\n(function () {\n' +
    "var yvn = document.createElement('script');\n" +
    "yvn.type = 'text/javascript';\n" +
    'yvn.async = true;\n' +
    "yvn.id = '_yvnps';\n" +
    "yvn.src = 'https://yv-nps-prod-public.s3.amazonaws.com/yvapi.js';\n" +
    "var yvn_script = document.getElementsByTagName('script')[0];\n" +
    'yvn_script.parentNode.insertBefore(yvn, yvn_script);\n' +
    '})();\n' +
    '</script>'

  let textUser =
    '//UserId ou E-mail obrigatórios, sistema do cliente deverá preencher\n' +
    "window.yvnps.push({userid:'ID DO USUÁRIO'});\n" +
    "window.yvnps.push({userid:'EMAIL@EMAIL.COM'});\n"

  let textDimension =
    '\n//Dimensões que o cliente deseja incluir na resposta. \n//Pode ser valor fixou ou preenchido pelo sistema do cliente\n' +
    "window.yvnps.push({dimension_DIMENSAO1:'Valor da Dimensão 1'});\n" +
    "window.yvnps.push({dimension_DIMENSAO2:'Valor da Dimensão 2'});\n"

  return (
    <div>
      <Row fixed>
        <div style={{ margin: 'auto 12px' }}>
          <Logo style={{ float: 'left' }} src={logo} />
          <Title>Voices</Title>
        </div>
        <ChangeLanguage>
          <Language />
        </ChangeLanguage>
      </Row>

      <div style={{ height: 80 }} />

      <Container style={{ width: '600px', margin: '0 auto' }}>
        <Header>{t('campaing.edit.form.WidgetScript.title')}</Header>
        <Description>
          <Text>{t('campaing.edit.form.WidgetScript.firstText')}</Text>

          <Alert severity="warning" style={{ marginBottom: '16px' }}>
            <AlertTitle>
              {t('campaing.edit.form.WidgetScript.alertTitle')}
            </AlertTitle>
            {t('campaing.edit.form.WidgetScript.alertText')}
          </Alert>
        </Description>

        <RenderSyntax
          positionIcon={'relative'}
          floatIcon={'right'}
          marginBottomIcon={'-35px'}
          rightIcon={'10px'}
          Text={text}
          Tema={2}
          tooltipPosition={'bottom-end'}
        />

        <Description>
          <Text>{t('campaing.edit.form.WidgetScript.secondText')}</Text>
          <Text>{t('campaing.edit.form.WidgetScript.thirdText')}</Text>

          <Ul>
            <Li>{t('campaing.edit.form.WidgetScript.listName')}</Li>
            <Li>{t('campaing.edit.form.WidgetScript.listEmail')}</Li>
            <Li>{t('campaing.edit.form.WidgetScript.listIdentify')}</Li>
          </Ul>

          <Text>{t('campaing.edit.form.WidgetScript.fourthText')}</Text>

          <Text>{t('campaing.edit.form.WidgetScript.fifthText')}</Text>
        </Description>

        <RenderSyntax
          positionIcon={'relative'}
          floatIcon={'right'}
          marginBottomIcon={'-35px'}
          rightIcon={'10px'}
          Text={textUser}
          Tema={2}
          tooltipPosition={'bottom-end'}
        />

        <Description>
          <Text>{t('campaing.edit.form.WidgetScript.sixthText')}</Text>
        </Description>

        <Subtitle>{t('campaing.edit.form.WidgetScript.seventhText')}</Subtitle>

        <Description>
          <Text>{t('campaing.edit.form.WidgetScript.eighthText')}</Text>
          <Text>{t('campaing.edit.form.WidgetScript.ninthText')}</Text>
          <Text> {t('campaing.edit.form.WidgetScript.tenthText')}</Text>
          <Text>{t('campaing.edit.form.WidgetScript.eleventhText')}</Text>
        </Description>

        <RenderSyntax
          positionIcon={'relative'}
          floatIcon={'right'}
          marginBottomIcon={'-35px'}
          rightIcon={'10px'}
          Text={textDimension}
          Tema={2}
          tooltipPosition={'bottom-end'}
        />

        <Description>
          <Text>{t('campaing.edit.form.WidgetScript.twelfthText')}</Text>
        </Description>
      </Container>
    </div>
  )
}

export default withTranslation()(WidgetScript)
