import { useState } from 'react'

import { withTranslation } from 'react-i18next'
import { useAppContext } from '../../../../App'
import { Controller, useForm } from 'react-hook-form'

import axios from 'core/api'

import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import useStyle from './style'
import Loader from '../../../../components/Loader'

const ResetPassword = ({ setResetPassword, t }) => {
  const classes = useStyle()
  const { notify } = useAppContext()

  const { errors, handleSubmit, control } = useForm()

  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState('')

  const [loading, setLoading] = useState(false)

  const onSubmit = ({ email }) => sendEmail(email)

  const sendEmail = (email) => {
    setLoading(true)

    axios
      .post(
        'api/pub/PasswordRecovery/SendEmail?Email=' + encodeURIComponent(email)
      )
      .then(() => {
        setSuccess(true)
      })
      .catch((error) => {
        notify(error.message, 'error')
        setSuccess(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return loading ? (
    <Loader />
  ) : (
    <>
      {success ? (
        <div>
          <p className={classes.first}>{t('recovery.first')}</p>
          <p className={classes.second}>{t('recovery.second')}</p>
          <p className={classes.third}>{t('recovery.third')}</p>
          <p className={classes.warning}>{t('recovery.warning')}</p>

          <Button
            color="default"
            variant="contained"
            className="btn-sign"
            onClick={() => setResetPassword(false)}
          >
            {t('uiActions.back')}
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Controller
              control={control}
              name="email"
              rules={{
                required: 'Enter your e-mail',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: t('login.email.invalid')
                }
              }}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  required
                  type="email"
                  name={name}
                  placeholder="E-mail"
                  className={classes.input}
                  value={value}
                  onChange={(event) => {
                    onChange(event)
                    setEmail(event.target.value)
                  }}
                />
              )}
            />
            <div className={classes.error}>
              {errors?.email && <p>{errors?.email.message}</p>}
            </div>
          </div>
          <div>
            <Button
              color="default"
              variant="contained"
              type="submit"
              className="btn-sign"
              onClick={() => setResetPassword(false)}
            >
              {t('uiActions.cancel')}
            </Button>{' '}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="btn-sign"
              disabled={!email}
            >
              {t('recovery.button')}
            </Button>
          </div>
        </form>
      )}
    </>
  )
}

export default withTranslation()(ResetPassword)
