import styled from 'styled-components'

export const QuadrantView = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 58px;
`

export const QuadrantColor = styled.div`
  border-radius: 5px;
  background-image: #000000;
  background-image: radial-gradient(
      at 0% 0%,
      hsla(2, 86%, 59%, 1) 0,
      transparent 70%
    ),
    radial-gradient(at 0% 99%, hsla(38, 93%, 56%, 1) 0, transparent 70%),
    radial-gradient(at 100% 0%, hsla(107, 47%, 51%, 1) 0, transparent 70%),
    radial-gradient(at 100% 100%, hsla(197, 100%, 48%, 1) 0, transparent 70%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`
export const TitleY = styled.div`
  transform: rotate(-90deg);
  color: #757575;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 55px;
`
export const QuadrantY = styled.div`
  display: flex;
  margin-right: 5px;
  justify-content: center;
  width: 50px;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
`
export const QuadrantItem = styled.div`
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  word-break: break-word;
  text-align: center;
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  box-sizing: border-box;

  & > span {
    text-transform: uppercase;
  }

  ${({ blockOne }) =>
    blockOne
      ? ' border-bottom: 4px dashed rgba(255, 255, 255, 0.2); border-right: 4px dashed rgba(255, 255, 255, 0.2)'
      : ''}

  ${({ blockTwo }) =>
    blockTwo ? ' border-bottom: 4px dashed rgba(255, 255, 255, 0.2)' : ''}
   
  ${({ blockTree }) =>
    blockTree ? ' border-right: 4px dashed rgba(255, 255, 255, 0.2)' : ''}
`
export const QuadrantNum = styled.div`
  position: absolute;
  height: 100%;
`

export const QuadrantBox = styled.div`
  width: 100%;
`
export const QuadrantMatrix = styled.div`
  position: relative;
  width: 100%;
  height: 25em;
  margin: 0 auto;
  background-color: #00000014;
`

export const Li = styled.li`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`
export const Ul = styled.ul``

export const MetaNps = styled.div`
  top: 421px;
  position: absolute;
  color: black;
  right: ${({ goal }) => (100 - goal) / 2}%;
  transform: translateX(50%);
  font-size: 12px;
  font-weight: 600;
`
export const QuadrantTitleX = styled.div`
  margin-top: 10px;
  text-align: center;
`

export const QuadrantTitleY = styled.div`
  margin-top: 10px;
  text-align: center;
`

export const QuadrantDescX = styled.ul`
  position: relative;
  display: flex;
  width: 100%;
`
export const Point = styled.button`
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  border: 1px solid #f9f9f9;
  border-radius: 50%;
  padding: 0;
  outline: 0;
  transition: all 0.3s ease-in;
  box-shadow: 0px 3px 16px 2px rgba(0, 0, 0, 0.11),
    0px 9px 12px 1px rgba(0, 0, 0, 0.13), 0px 5px 6px -3px rgba(0, 0, 0, 0.02);
`
