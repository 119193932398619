import '../../style/route/api/main.css'
import { withTranslation } from 'react-i18next'
import Loader from '../../components/Loader'
import Header from '../../components/Header'
import RoundedButton from '../../components/Button'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Filters from 'modules/AppDrawer/styles/Filter'
import { FilterItem } from 'modules/AppDrawer/styles/Filter'
import axios from 'core/api'
import { useAppContext } from 'App'
import { Tabs, Tab } from '@material-ui/core'
import NewApiUserDialog from './Components/NewApiUserDialog'
import UpdateApiUserStatusDialog from './Components/UpdateApiUserStatusDialog'
import EmptyState from './Components/EmptyState'
import ApiTable from './Components/ApiTable'

const TabStates = {
  Active: 0,
  Inactive: 1
}

const DefaultGetParams = {
  Page: 1,
  PageSize: 10,
  Active: true,
  OrderDirection: 'Asc',
  Order: 'Label'
}

const getApiUsers = async (
  params,
  thenCallback,
  catchCallback,
  finallyCallback
) => {
  let response
  axios
    .get(`/BasicAuthUser/Get`, { params })
    .then((res) => {
      thenCallback(res)
    })
    .catch((error) => {
      catchCallback(error)
    })
    .finally(() => {
      finallyCallback()
    })
  return response
}

const Api = ({ t }) => {
  const { notify } = useAppContext()
  const [apiUsers, setApiUsers] = useState({})
  const [loadingHasUsers, setLoadingHasUsers] = useState(false)
  const [loadingGetUsers, setLoadingGetUsers] = useState(false)
  const [getParams, setGetParams] = useState(DefaultGetParams)
  const [hasUsers, setHasUsers] = useState(false)

  const [tabState, setTabState] = useState(TabStates.Active)
  const handleSetTabState = useCallback(
    (event, newTabState) => {
      setTabState(newTabState)
      const currentActiveValue = getParams.Active
      const newActiveValue = newTabState === TabStates.Active
      if (currentActiveValue !== newActiveValue) {
        setGetParams({
          ...getParams,
          Active: newActiveValue,
          Page: DefaultGetParams.Page
        })
      }
    },
    [getParams]
  )

  const [showNewApiUserDialog, setShowNewApiUserDialog] = useState(false)
  const handleOpenNewApiUserDialog = useCallback(() => {
    setShowNewApiUserDialog(true)
  }, [])
  const handleCloseNewApiUserDialog = useCallback(() => {
    setShowNewApiUserDialog(false)
  }, [])

  const [userChangingStatus, setUserChangingStatus] = useState()
  const handleCloseUserStatusDialog = useCallback(() => {
    setUserChangingStatus()
  }, [])
  const [newStatus, setNewStatus] = useState(false)

  const checkIfHasUsers = useCallback(() => {
    setLoadingHasUsers(true)

    const paramsWithoutActive = { ...DefaultGetParams }
    delete paramsWithoutActive.Active

    getApiUsers(
      paramsWithoutActive,
      (res) => {
        setHasUsers(!!res.data && !!res.data.TotalItemCount)
      },
      (error) => {
        notify(error.message, 'error')
      },
      () => {
        setLoadingHasUsers(false)
      }
    )
  }, [notify])

  const loadApiUsers = useCallback(
    (params) => {
      setLoadingGetUsers(true)

      getApiUsers(
        params,
        (res) => {
          setApiUsers(res.data)

          if (!res.data || !res.data.Items || !res.data.Items.length) {
            checkIfHasUsers()
          } else {
            setHasUsers(true)
          }
        },
        (error) => {
          notify(error.message, 'error')
        },
        () => {
          setLoadingGetUsers(false)
        }
      )
    },
    [notify, checkIfHasUsers]
  )

  useEffect(() => {
    loadApiUsers(getParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getParams])

  const handleOnChangeUserStatus = useCallback((apiUser, status) => {
    setUserChangingStatus(apiUser)
    setNewStatus(status)
  }, [])

  const onChangePage = useCallback(
    (event, newPage) => {
      setGetParams({ ...getParams, Page: newPage + 1 })
    },
    [getParams]
  )

  const onChangeRowsPerPage = useCallback(
    (event) => {
      setGetParams({
        ...getParams,
        Page: DefaultGetParams.Page,
        PageSize: event.target.value
      })
    },
    [getParams]
  )

  const isLoading = useMemo(
    () => loadingHasUsers || loadingGetUsers,
    [loadingGetUsers, loadingHasUsers]
  )

  return (
    <div className="api">
      {!!isLoading && (
        <div className="api-loader-wrapper">
          <Loader />
        </div>
      )}

      <Header
        type="actions"
        title={t('api.title')}
        items={
          !!hasUsers && (
            <Filters justify="flex-end">
              <FilterItem>
                <RoundedButton
                  whiteTextLabel
                  type="add"
                  size="large"
                  variant="contained"
                  onClick={handleOpenNewApiUserDialog}
                  text={t('api.newUserDialog.title')}
                />
              </FilterItem>
            </Filters>
          )
        }
      />

      {!!hasUsers && (
        <div className="api-tabs-outer-wrapper">
          <div className="api-tabs-inner-wrapper">
            <Tabs
              value={tabState}
              variant="fullWidth"
              textColor="primary"
              onChange={handleSetTabState}
              indicatorColor="primary"
            >
              <Tab label={t('api.tabs.active')} />
              <Tab label={t('api.tabs.inactive')} />
            </Tabs>
          </div>
        </div>
      )}

      {!!hasUsers ? (
        <ApiTable
          items={apiUsers.Items}
          totalItemCount={apiUsers.TotalItemCount}
          page={getParams.Page}
          pageSize={getParams.PageSize}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangeUserStatus={handleOnChangeUserStatus}
        />
      ) : !isLoading ? (
        <EmptyState onAddNewUser={handleOpenNewApiUserDialog} />
      ) : (
        <div></div>
      )}

      {!!showNewApiUserDialog && (
        <NewApiUserDialog
          open={showNewApiUserDialog}
          onClose={handleCloseNewApiUserDialog}
          onSuccess={() => loadApiUsers(getParams)}
        />
      )}

      {!!userChangingStatus && (
        <UpdateApiUserStatusDialog
          user={userChangingStatus}
          status={newStatus}
          open={!!userChangingStatus}
          onClose={handleCloseUserStatusDialog}
          onSuccess={() => loadApiUsers(getParams)}
        />
      )}
    </div>
  )
}

export default withTranslation()(Api)
