import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useAppContext } from 'App'
import { BASE_PATH } from 'router'
import { Icon } from '@mdi/react'
import { mdiContentCopy, mdiPencil } from '@mdi/js'
import useGetData from 'core/hooks/useGetData'
import getData from 'core/functions/get-data.function'
import postData from 'core/functions/post-data.function'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import {
  Actions,
  ButtonLabel,
  Column,
  Container,
  Content,
  HeaderTitle,
  LeftActions,
  RightActions,
  Row,
  Title,
  Subtitle,
  InsideContent,
  useStyles
} from './style'
import BoxList from '../BoxList'
import FormEditor from 'modules/Form/components/FormEditor'
import FormPreview from '../FormPreview'
import { useCampaign } from '../Details/context'
import { withTranslation } from 'react-i18next'

const CampaignType = {
  0: 'nps',
  1: 'csat'
}

const FormModal = ({ children, openModal, setOpenModal, edit, text }) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="form-dialog-title"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="form-dialog-title">
        {edit ? text?.edit : text?.new}
      </DialogTitle>

      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

function Form({ t }) {
  const location = useLocation()
  const history = useHistory()
  const { notify, boxListCurrentItem, handleSetBoxListCurrentItem } =
    useAppContext()

  const {
    form: formState,
    handleSetForm,
    formBackup,
    handleSetFormBackup,
    campaign: campaignState
  } = useCampaign()

  const elRef = useRef()

  const [openNewModal, setOpenNewModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)

  const handlePrevious = () => {
    handleSetForm({ ...formBackup })
    history.push({
      pathname: BASE_PATH + 'campaign/details',
      search: location.search
    })
  }
  const handleNext = () =>
    history.push({
      pathname: BASE_PATH + 'campaign/details',
      search: location.search
    })

  const [loading, setLoading] = useState(false)

  const [formData, setFormData] = useState(null)
  const handleSetFormData = useCallback(
    (form) => {
      setFormData(form)
      handleSetBoxListCurrentItem(form)
      handleSetForm({ ...formState, ...form })
    },
    [formState, handleSetBoxListCurrentItem, handleSetForm]
  )
  const [selectedFormId, setSelectedFormId] = useState(
    campaignState?.IdForm?.toString()
  )
  const handleSelectedFormId = useCallback(
    (value) => {
      setSelectedFormId(value?.toString())
      handleSetForm({ ...formState, IdForm: value?.toString() })
    },
    [formState, handleSetForm]
  )

  const [forms, setForms] = useState(null)
  useGetData({
    endpoint: 'form/getfiltered?Status=true',
    setData: setForms,
    setLoading: setLoading
  })
  const filteredForms = useMemo(() => {
    return (forms || []).filter((f) => f.Type === campaignState.Type)
  }, [campaignState.Type, forms])

  const handleSaveForm = async (form) => {
    setOpenNewModal(false)
    setOpenEditModal(false)

    await getData('form/getfiltered?Status=true', setForms, setLoading, notify)

    handleSetFormData(form)
    handleSelectedFormId(form?.IdYourviews)
  }

  const handleDuplicate = useCallback((selectedFormId) => {
    postData({
      endpoint: '/Form/Duplicate',
      data: { IdYourviews: selectedFormId },
      setData: async ({ Element }) => {
        await getData(
          'form/getfiltered?Status=true',
          setForms,
          setLoading,
          notify
        )

        handleSetFormData(Element)
        handleSelectedFormId(Element.IdYourviews)
      },
      notify: notify
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    handleSetFormBackup({ ...formState })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const text = {
    edit: t('campaing.edit.form.editForm'),
    new: t('campaing.edit.form.createForm')
  }
  return (
    <Container>
      <Row>
        <LeftActions>
          <IconButton onClick={handlePrevious}>
            <NavigateBefore color="primary" />
          </IconButton>

          <ButtonLabel onClick={handlePrevious}>
            {t('campaing.edit.form.back')}
          </ButtonLabel>
        </LeftActions>

        <HeaderTitle>{t('campaing.edit.form.searchForm')}</HeaderTitle>

        <RightActions>
          <ButtonLabel disabled={!formData} onClick={handleNext}>
            {t('campaing.edit.form.select')}
          </ButtonLabel>

          <IconButton disabled={!formData} onClick={handleNext}>
            <NavigateNext color={!formData ? 'disabled' : 'primary'} />
          </IconButton>
        </RightActions>
      </Row>

      <Content>
        <Column border padding="0px 48px" width="30%">
          <Title marginTop="48px" bold>
            {t('campaing.edit.form.selectForm')}
          </Title>

          <Subtitle>{t('campaing.edit.form.textSubtitle')}</Subtitle>

          <BoxList
            name="form"
            loading={loading}
            items={filteredForms}
            onStart={setFormData}
            onSelect={handleSetFormData}
            selectedAfterUpdate={selectedFormId}
            onCreate={() => setOpenNewModal(true)}
            handleSetBoxListCurrentItem={handleSetBoxListCurrentItem}
          />

          <Actions style={{ marginBottom: 30 }}>
            <Button
              data-testid="select-form-button"
              color="primary"
              variant="contained"
              onClick={handleNext}
              disabled={!formData}
              size="large"
            >
              {t('campaing.edit.form.selectForm')}
            </Button>
          </Actions>
        </Column>

        <Column width="70%" greyBg>
          <Row>
            <LeftActions>
              <Title data-testid="form-name-label" marginLeft="32px" size="21">
                {formData?.Name}
              </Title>
            </LeftActions>

            <RightActions>
              <IconButton
                data-testid="edit-template-button"
                onClick={() => setOpenEditModal(true)}
              >
                <Icon path={mdiPencil} size={1} />
              </IconButton>

              <IconButton
                data-testid="duplicate-template-button"
                onClick={() => handleDuplicate(boxListCurrentItem.IdYourviews)}
              >
                <Icon path={mdiContentCopy} size={1} title="Titulo do Icone" />
              </IconButton>
            </RightActions>
          </Row>

          <InsideContent ref={elRef} id="back-to-top-anchor">
            <FormPreview form={formData} />
          </InsideContent>
        </Column>
      </Content>

      {/* New */}
      <FormModal
        text={text}
        openModal={openNewModal}
        setOpenModal={setOpenNewModal}
      >
        <FormEditor
          noTitle
          save={handleSaveForm}
          cancel={() => setOpenNewModal(false)}
          location={{
            ...location,
            params: { item: 'new' },
            search: 'types=' + CampaignType[campaignState.Type]
          }}
          match={{
            params: { item: 'new', type: CampaignType[campaignState.Type] }
          }}
        />
      </FormModal>

      {/* Edit */}
      <FormModal
        text={text}
        edit
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
      >
        <FormEditor
          noTitle
          save={handleSaveForm}
          location={{
            ...location,
            state: formData,
            search: 'types=' + CampaignType[campaignState.Type]
          }}
          cancel={() => setOpenEditModal(false)}
          match={{
            params: {
              item: selectedFormId
            }
          }}
        />
      </FormModal>
    </Container>
  )
}

Form.propTypes = {
  handleNext: PropTypes.func,
  handlePrevious: PropTypes.func
}

export default withTranslation()(Form)
