import PropTypes from 'prop-types'

import { CircularProgress, LinearProgress } from '@material-ui/core'

import { Container } from './style'

function Loader({ type, size, noMargin, margin }) {
  const LoaderTypeMap = {
    circular: <CircularProgress size={size} />,
    linear: <LinearProgress size={size} />
  }

  return (
    <Container margin={margin} noMargin={noMargin}>
      {LoaderTypeMap[type]}
    </Container>
  )
}

const { oneOf, number, string } = PropTypes

Loader.propTypes = {
  type: oneOf(['circular', 'linear']),
  size: number,
  margin: string
}

Loader.defaultProps = {
  type: 'circular',
  size: 50
}

export default Loader
