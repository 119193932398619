import { withTranslation } from 'react-i18next'

import ErrorIcon from '@material-ui/icons/Error'
import MaterialListItem from 'components/MaterialListItem'

import {
  ErrorContainer,
  Message,
  Error,
  ErrorTitle,
  ErrorQuantity,
  BottomActions,
  ErrorItems
} from '../../style'

import { ImageBox, Image } from 'components/ImportFile/style'

import errorImg from 'assets/img/error.svg'
import Button from '@material-ui/core/Button'
import BigButton from 'components/BigButton'

const Errors = (props) => {
  return (
    <ErrorContainer>
      <ImageBox>
        <Image src={errorImg} alt={'Import file with error'} />
      </ImageBox>

      <ErrorTitle>
        {props.t('campaign.peopleList.errorTitleStart')}{' '}
        <ErrorQuantity>
          {props.data.length} {props.t('campaign.peopleList.errorQuatityLabel')}
        </ErrorQuantity>{' '}
        {props.t('campaign.peopleList.errorTitleEnd')}
      </ErrorTitle>

      <ErrorItems>
        {props.data.map((error, index) => (
          <MaterialListItem
            condensed
            noHover
            key={error.Message}
            index={index}
            id={'error-' + index}
            testId={'error-' + index}
            length={props.data.length}
          >
            <Error>
              <ErrorIcon color="error" />
              <Message>{error.Message}</Message>
            </Error>
          </MaterialListItem>
        ))}
      </ErrorItems>

      {/* {!errorDetails ? ( 
        
         ) : (
           <ErrorItem>
           <Header>
             <div>
               <IconButton onClick={() => setErrorDetails(null)}>
                 <ChevronLeft />
               </IconButton>
               {errorDetails.Message}
             </div>

             <div>
               <ErrorQuantityBadge>
                 {errorDetails.items.lenght}
               </ErrorQuantityBadge>
             </div>
           </Header>

           {errorDetails.items &&
             errorDetails.items.map((detail, index) => (
               <MaterialListItem
                 condensed
                 index={index}
                 id={'error-' + index}
                 testId={'error-' + index}
                 length={errorDetails?.items?.length}
               >
                 <Error>
                   <ErrorIcon color="error" />
                   <Message>{detail?.Message ?? 'Nenhuma informação'}</Message>
                 </Error>
               </MaterialListItem>
             ))}
         </ErrorItem>
       )}
    */}

      <BottomActions>
        <div>
          {props.continue && (
            <BigButton
              onClick={props.handleContinue}
              title={props.t('uiActions.continue')}
            />
          )}
        </div>

        <div>
          <Button onClick={props.resendFile} color="primary">
            {props.t('uiActions.resendFile')}
          </Button>
        </div>
      </BottomActions>
    </ErrorContainer>
  )
}

export default withTranslation()(Errors)
