import { useState } from 'react'
import * as React from 'react'
import axios from 'core/api'
import { useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { queryString } from 'core/helpers'
import makeQueryString from 'core/functions/makeQueryString.function'
import {
  QS_PASSWORD,
  QS_PASSWORD_CONFIRMATION,
  QS_RECOVERY_KEY
} from 'core/constants/query-strings'
import Button from '@material-ui/core/Button'
import {
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import useStyle from './style'
import { useAppContext } from 'App'
import Loader from 'components/Loader'
import AuthProvider from 'modules/Authentication'

const RecoveryPassword = ({ t }) => {
  const { errors, watch, handleSubmit, control } = useForm()

  const { notify } = useAppContext()

  const [loading, setLoading] = useState(false)

  const [success, setSuccess] = useState(false)
  const history = useHistory()
  const classes = useStyle()

  const passwordPattern =
    /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g
  
  const passwordMin = /(?=^.{8,}$).*$/g
  const passwordTiny = /(?=.*[a-z]).*$/g
  const passwordUppercase = /(?=.*[A-Z]).*$/g
  const passwordNumber = /(?=.*\d).*$/g
  const passwordCharacter = /(?=.*\W+).*$/g

  const [recoveryKey] = useState(queryString.getQSValue(QS_RECOVERY_KEY))
  const [showPass, setShowPass] = React.useState(false)
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [password, setPassword] = useState('')
  const [confirm, setConfirm] = useState('')

  const watchPassword = watch('password')
  const watchConfirm = watch('confirmPassword')

  const onSubmit = ({ password, confirmPassword }) =>
    sendEmail(
      makeQueryString([
        { key: QS_PASSWORD, value: encodeURIComponent(password) },
        {
          key: QS_PASSWORD_CONFIRMATION,
          value: encodeURIComponent(confirmPassword)
        },
        { key: QS_RECOVERY_KEY, value: recoveryKey }
      ])
    )

  const sendEmail = (QS) => {
    setLoading(true)

    axios
      .post('api/pub/PasswordRecovery/PasswordRecovery?' + QS)
      .then(({ data }) => {
        if (data.Result !== 7) {
          notify(t('recovery.error.' + data.Result), 'error')
        }

        if (data.Result === 7) {
          notify(t('recovery.error.' + data.Result), 'success')
          setSuccess(true)
        }
      })
      .catch((error) => {
        notify(error.message, 'error')

        setSuccess(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleGoBack = () => history.push('/login')

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <AuthProvider withSkin>
      <h2 className={classes.title}>{t('recovery.new.title')}</h2>

      {loading ? (
        <Loader />
      ) : success ? (
        <div>
          <p className={classes.success}>{t('recovery.success.title')}</p>
          <p className={classes.success}>{t('recovery.success.description')}</p>

          <Button
            color="default"
            variant="contained"
            type="submit"
            className="btn-sign"
            onClick={handleGoBack}
          >
            {t('uiActions.back')}
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className={classes.inputs}>
              <div className="">
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="password">
                    {t('login.form.passwordLabel')}
                  </InputLabel>

                  <Controller
                    id="password"
                    name="password"
                    control={control}
                    rules={{
                      min: 8,
                      required: 'Digite sua senha',
                      pattern: {
                        value: passwordPattern,
                        message: t('form.error.password')
                      }
                    }}
                    render={({ field: { name, value, onChange, onBlur } }) => (
                      <Input
                        required
                        name={name}
                        value={value}
                        onBlur={onBlur}
                        className={classes.input}
                        autoComplete="new-password"
                        type={showPass ? 'text' : 'password'}
                        onChange={(event) => {
                          onChange(event)
                          setPassword(event.target.value)
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPass(!showPass)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>

              <div className={classes.message}>
                {password.length >= 1 &&
                  watchPassword &&
                  (!passwordMin.test(password) ? (
                  <p>{t('form.error.passwordMin')}</p>) : '' ||
                    !passwordTiny.test(password) ? (
                    <p>{t('form.error.passwordTiny')}</p>
                  ) : '' || !passwordUppercase.test(password) ? (
                    <p>{t('form.error.passwordUppercase')}</p>
                  ) : '' || !passwordNumber.test(password) ? (
                    <p>{t('form.error.passwordNumber')}</p>
                  ) : '' || !passwordCharacter.test(password) ? (
                    <p>{t('form.error.passwordCharacter')}</p>
                  ) : (
                    ''
                  ))}
              </div>
            </div>

            <div className={classes.error}>
              {errors?.password && <p>{errors?.password?.message}</p>}
            </div>

            <div className={classes.root}>
              <div>
                <FormControl className={classes.formControl}>
                  <InputLabel htmlFor="password">
                    {t('login.form.confirmPasswordLabel')}
                  </InputLabel>

                  <Controller
                    id="confirmPassword"
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: 'Confirme sua senha',
                      pattern: {
                        value: passwordPattern,
                        message: t('form.error.password')
                      }
                    }}
                    render={({ field: { name, onChange, onBlur } }) => (
                      <Input
                        required
                        name={name}
                        onBlur={onBlur}
                        className={classes.input}
                        autoComplete="new-password"
                        type={showConfirm ? 'text' : 'password'}
                        onChange={(event) => {
                          onChange(event)
                          setConfirm(event.target.value)
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirm(!showConfirm)}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPass ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div style={{ marginTop: '50px' }} className={classes.message}>
                {watchConfirm &&
                  (passwordPattern.test(password) && password === confirm ? (
                    ''
                  ) : (
                    <p>{t('form.error.passwordConfirm')}</p>
                  ))}
              </div>
            </div>

            <div className={classes.error}>
              {errors?.email && <p>{errors?.password?.message}</p>}
            </div>
          </div>

          <div className={classes.buttons}>
            <Button
              type="submit"
              color="default"
              variant="contained"
              className="btn-sign"
              onClick={handleGoBack}
            >
              {t('uiActions.cancel')}
            </Button>{' '}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className="btn-sign"
              disabled={!password || !confirm}
            >
              {t('recovery.button')}
            </Button>
          </div>
        </form>
      )}
    </AuthProvider>
  )
}

export default withTranslation()(RecoveryPassword)
