import { useState } from 'react'

import { getIn } from 'formik'

import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import Button from '@material-ui/core/Button'

import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'

const PasswordField = ({ field, form: { touched, errors }, ...props }) => {
  const [show, setShow] = useState(false)

  return (
    <FormControl
      error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
      className="form-field text-field"
    >
      <InputLabel htmlFor={field.name}>{props.label}</InputLabel>

      <Input
        type={show ? 'text' : 'password'}
        id={field.name}
        {...field}
        {...props}
        endAdornment={
          <InputAdornment position="end">
            <Button
              type="button"
              className="show-password-btn"
              onClick={() => setShow(!show)}
            >
              {show ? <MdVisibilityOff /> : <MdVisibility />}
            </Button>
          </InputAdornment>
        }
      />

      {!!(
        field.name &&
        getIn(touched, field.name) &&
        getIn(errors, field.name)
      ) && <FormHelperText>{getIn(errors, field.name)}</FormHelperText>}
    </FormControl>
  )
}

export default PasswordField
