import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& hr': {
      backgroundColor: '#666'
    },
    height: 64
  },
  margin: {
    margin: theme.spacing(1),
    color: '#666'
  },
  title: {
    fontSize: 22,
    color: '#00aeef',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    margin: 'auto 0px'
  },
  logo: {
    height: '100%',
    '& img': {
      maxWidth: 50,
      margin: 'auto 0px'
    },
    marginLeft: 13,
    paddingLeft: 20
  },
  item: {
    display: 'flex'
  },
  items: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    height: '100%'
  },
  label: {
    width: '222px',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#424242',
    margin: 'auto auto auto 16px',
    textAlign: 'left'
  },
  userName: {
    width: '86px',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    color: '#424242',
    textAlign: 'right',
    marginBottom: '2px'
  },
  storeName: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#00AEEF',
    cursor: 'pointer'
  },
  exitBtn: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  iconDrop: {
    color: '#757575'
  },
  arrowIcon: {
    marginTop: '-5px',
    position: 'absolute'
  },
  dropDown: {
    width: '300px',
    position: 'absolute',
    border: '1px solid #E0E0E0',
    marginTop: '18px',
    marginLeft: '-17px',
    backgroundColor: '#fff',
    height: '208px',
    padding: '8px 16px 16px 8px',
    borderTop: 'none',
    boxShadow:
      '0px 1px 10px rgb(0 0 0 / 11%), 0px 4px 5px rgb(0 0 0 / 13%), 0px 2px 4px -1px rgb(0 0 0 / 2%)'
  },
  li: {
    minHeight: '24px',
    marginLeft: '8px',
    marginBottom: '20px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    cursor: 'pointer'
  },
  ul: {
    overflowX: 'auto',
    maxHeight: '145px'
  }
}))

export default useStyles
