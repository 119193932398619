import { useState } from 'react'

import { withTranslation } from 'react-i18next'

import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import useStyles from './style'

const SortBySelect = (props) => {
  const classes = useStyles()

  const [sorting, setSorting] = useState(props.default ?? 'DateDesc')

  const handleChange = (value) => {
    setSorting(value)
    props.changeSorting(value)
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel>{props.t('answer.sortList')}</InputLabel>

      <Select
        displayEmpty
        value={sorting ?? ''}
        id="sort-select"
        labelId="sort-select-label"
        onChange={({ target: { value } }) => handleChange(value)}
      >
        {props.items &&
          props.items.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.t(item.title)}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

export default withTranslation()(SortBySelect)
