import '../../../style/route/api/main.css'
import { withTranslation } from 'react-i18next'
import Loader from '../../../components/Loader'
import { useCallback, useState } from 'react'
import axios from 'core/api'
import { useAppContext } from 'App'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  DialogContentText
} from '@material-ui/core'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

const NewUserSteps = {
  first: 1,
  second: 2
}

const NewApiUserDialog = ({ open, onClose, onSuccess, t }) => {
  const { notify } = useAppContext()
  const [step, setStep] = useState(NewUserSteps.first)
  const [loading, setLoading] = useState(false)
  const [responseElement, setResponseElement] = useState()
  const [newUserLabel, setNewUserLabel] = useState('')
  const handleSetNewUserLabel = useCallback((event) => {
    setNewUserLabel(event.target.value)
  }, [])

  const handleCreateUser = useCallback(
    async (event) => {
      event.preventDefault()

      setLoading(true)
      await axios
        .post('BasicAuthUser/InsertUpdateV2', {
          Active: true,
          Label: newUserLabel,
          IdYourviews: undefined
        })
        .then((res) => {
          if (res && res.data && !res.data.HasErrors) {
            setResponseElement(res.data.Element)
            setStep(2)
          }
        })
        .catch((error) => {
          notify(error.message, 'error')
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [notify, newUserLabel]
  )

  const handleOnSuccessClose = useCallback(() => {
    onSuccess()
    onClose()
  }, [onSuccess, onClose])

  return (
    <Dialog open={open}>
      {step === NewUserSteps.first && (
        <form onSubmit={handleCreateUser}>
          <DialogTitle>{t('api.newUserDialog.title')}</DialogTitle>
          <DialogContent className="api-dialog-content">
            <TextField
              autoFocus
              label={t('api.newUserDialog.identifier')}
              fullWidth
              onChange={handleSetNewUserLabel}
              value={newUserLabel}
              required
            />
          </DialogContent>
          <DialogActions className="api-dialog-actions">
            <div>
              {loading && (
                <div className="api-loader-wrapper">
                  <Loader size={35} noMargin />
                </div>
              )}
            </div>
            <div className="api-dialog-actions-buttons">
              <Button onClick={onClose} color="primary">
                {t('api.newUserDialog.cancel')}
              </Button>

              <Button variant="contained" color="primary" type="submit">
                {t('api.newUserDialog.confirm')}
              </Button>
            </div>
          </DialogActions>
        </form>
      )}
      {step === NewUserSteps.second && responseElement && (
        <>
          <DialogTitle>{t('api.newUserDialog.title')}</DialogTitle>
          <DialogContent className="api-dialog-content">
            <DialogContentText>
              {t('api.newUserDialog.conclusionText')}
            </DialogContentText>
            <TextField
              autoFocus
              label={t('api.newUserDialog.identifier')}
              fullWidth
              value={responseElement.Label}
              disabled
            />

            <div className="api-dialog-info">
              <div className="api-dialog-info-icon">
                <FileCopyOutlinedIcon />
              </div>
              <div className="api-dialog-info-text">
                <div className="api-dialog-info-text-label">
                  {t('api.newUserDialog.user')}
                </div>
                <div className="api-dialog-info-text-value">
                  {responseElement.UserName}
                </div>
              </div>
            </div>

            <div className="api-dialog-info">
              <div className="api-dialog-info-icon">
                <FileCopyOutlinedIcon />
              </div>
              <div className="api-dialog-info-text">
                <div className="api-dialog-info-text-label">
                  {t('api.newUserDialog.password')}
                </div>
                <div className="api-dialog-info-text-value">
                  {responseElement.Password}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="api-dialog-actions">
            <div></div>
            <Button
              variant="contained"
              onClick={handleOnSuccessClose}
              color="primary"
            >
              {t('api.newUserDialog.close')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default withTranslation()(NewApiUserDialog)
