import { useState, useRef } from 'react'
import { TwitterPicker } from 'react-color'
import { MdColorLens } from 'react-icons/md'

import useOutsideClick from 'core/hooks/useOutsideClick'

import '../../style/component/color-pic/main.css'

const ColorPic = ({ onChange, currentState }) => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => setOpen(false))

  const stopPropagation = (event) => {
    event.stopPropagation()
  }

  const renderModal = () => {
    const { color } = currentState
    return (
      <div
        onClick={(event) => stopPropagation(event)}
        className="color-pic-modal"
      >
        <TwitterPicker
          color={color}
          onChangeComplete={() => {
            onChange('color', color.hex)
          }}
        />
      </div>
    )
  }

  return (
    <div
      ref={wrapperRef}
      aria-haspopup="true"
      aria-expanded={open}
      aria-label="rdw-color-picker"
      className="color-pic rdw-option-wrapper"
      onClick={() => setOpen(!open)}
    >
      <div style={{ color: currentState.color }}>
        <MdColorLens />
      </div>
      {open ? renderModal() : undefined}
    </div>
  )
}

export default ColorPic
