import { read } from 'localstorage-helpr'

const LocaleMap = {
  pt: 'pt-BR',
  en: 'en-US',
  es: 'en-ES'
}

const GetDate = new Date()
  .toLocaleDateString(LocaleMap[read('i18nextLng')], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
  .replace(/\//g, '-')
  .replace(/:/g, '-')
  .replace(/ /g, '--')

export default GetDate
