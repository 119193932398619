import { useState, useEffect, useMemo } from 'react'

import { withTranslation } from 'react-i18next'
import { useAppContext } from '../../../../App'
import { text } from 'core/helpers'

import moment from 'moment'
import 'moment/locale/pt-br'
import 'moment/locale/es'

import axios from 'core/api'

import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

import AnswerTags from '../AnswerTags'
import AnswerStoreComment from '../AnswerStoreComment'
import NpsIcon from '../../../../components/NpsColor/NpsIcon'
import NpsLabel from '../../../../components/NpsColor/NpsLabel'
import CopyToClipboard from '../../../../components/CopyToClipboard'
import Confirmation from '../../../../components/Modal/Confirmation'
import Dialog from '../../../../components/Modal/Dialog'

import IconMap from '../../dictionaries/icon.dictionary'

import DimensionForm from '../DimensionForm'

import { makeModalMessage } from '../../functions/makeModalMessage'
import { CircularProgress } from '@material-ui/core'

import { dateToLocale } from 'core/functions/format-date.function'
import { Permissions } from 'modules/AppDrawer/enums/Permissions'
import { useAuthContext } from 'modules/Authentication'

import '../../../../style/route/answer/answer-item.css'
import Loader from '../../../../components/Loader'

import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

const AnswerItem = (props) => {
  const { notify } = useAppContext()
  const { user } = useAuthContext()

  const [modalMessage, setModalMessage] = useState(
    makeModalMessage(props.t('component.modal.message.generic'))
  )
  const [showModalDimension, setShowModalDimension] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dimension, setDimension] = useState(null)
  const [newDimension, setNewDimension] = useState(null)
  const [newDimensionError, setNewDimensionError] = useState(null)
  const [expanded, setExpanded] = useState(false)
  const [answer, setAnswer] = useState(props.answer)
  const [storeComment, setStoreComment] = useState(false)
  const [isTagging, setIsTagging] = useState(false)
  const [tagsSuggestions] = useState(() => {
    if (!props.tags || props.tags.length < 1) return []

    return props.tags.map((item) => {
      return {
        id: item.IdYourviews,
        name: item.Name
      }
    })
  })

  useEffect(() => {
    setNewDimensionError(null)
  }, [newDimension])

  const validState = () => {
    setLoading(true)

    axios
      .get('nps/changestatus?IdNps=' + answer.IdYourviews)
      .then((res) => {
        if (!res.data || res.data.HasErrors) throw Error('Error on change')

        let newAnswer = answer
        newAnswer.Active = !answer.Active

        setAnswer(newAnswer)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const remove = (value, type) => RemoveMap[type](value)

  const RemoveMap = {
    dimension: (dimension) => {
      const { name, value } = dimension

      setDimension(dimension)
      setModalMessage(
        makeModalMessage(
          {
            start: props.t('answer.modal.removeDimensionStart'),
            end: props.t('answer.modal.removeDimensionEnd')
          },
          { name, value },
          props.t('answer.modal.warning')
        )
      )
      setShowModal(true)
    },
    tag: (tag) => removeTag(tag)
  }

  const removeDimension = ({ id }) => {
    setLoading(true)

    axios
      .post(
        `NpsDimensionValue/RemoveNpsDimensionValue?IdDimensionValue=${id}&IdNps=${answer.IdYourviews}`,
        null
      )
      .then((res) => {
        if (!res.data || res.data.HasErrors) {
          throw Error('Error on delete')
        }

        if (res.data.Success) {
          setAnswer(res.data.Nps)
          notify(props.t('answer.removeDimension.success'), 'success')
        } else {
          notify(props.t('answer.removeDimension.unexpectedError'), 'error')
        }
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setDimension(null)
      })
  }

  const removeTag = ({ id }) => {
    setLoading(true)

    axios
      .post(`tag/removenpstag?IdTag=${id}&IdNps=${answer.IdYourviews}`, null)
      .then((res) => {
        if (!res.data || res.data.HasErrors) throw Error('Error on delete')

        const tagIndex = answer.Tags.findIndex((t) => t.IdYourviews === id)
        let newAnswer = answer

        newAnswer.Tags.splice(tagIndex, 1)

        setAnswer(newAnswer)
        notify('Removido com sucesso', 'success')
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getType = (type) =>
    IconMap.has(type) ? IconMap.get(type) : IconMap.has('default')

  const handleAccept = () => {
    removeDimension(dimension)
    setShowModal(false)
  }
  const handleClose = () => {
    setShowModal(false)
    setDimension(null)
  }

  const getChips = (truncated) => {
    let chips = []

    if (answer.Dimensions && answer.Dimensions.length > 0) {
      answer.Dimensions.forEach((dimension) => {
        dimension.Values.forEach((item) => {
          chips.push({
            text: (
              <div className="answer-item-chip">
                <strong>
                  {`${
                    truncated
                      ? text.maxSize(dimension.Name, 15)
                      : dimension.Name
                  }: 
                    `}
                </strong>
                {truncated ? text.maxSize(item.Value, 15) : item.Value}
              </div>
            ),
            erasable: true,
            id: item.IdYourviews,
            name: dimension.Name,
            value: item.Value,
            type: 'dimension'
          })
        })
      })
    }

    if (answer.Tags && answer.Tags.length > 0)
      answer.Tags.forEach((tag) => {
        chips.push({
          text: `tag: ${truncated ? text.maxSize(tag.Name, 15) : tag.Name}`,
          erasable: true,
          id: tag.IdYourviews,
          type: 'tag'
        })
      })

    return chips
  }

  const setNewTags = (tags) => {
    setIsTagging(false)
    setAnswer({ ...answer, Tags: tags })
  }

  moment.locale(props.i18n.language === 'pt' ? 'pt-br' : props.i18n.language)

  const handleShowDimensionModal = () => {
    setShowModalDimension(true)
    setNewDimensionError(null)
  }
  const handleCloseDimensionModal = () => {
    setShowModalDimension(false)
    setNewDimensionError(null)
  }
  const handleAcceptDimensionModal = () => {
    if (!newDimension.Values || newDimension.Values === []) {
      setNewDimensionError(props.t('answer.fillDimensionValues'))
      return
    }

    setLoading(true)
    setNewDimensionError(null)

    axios
      .post('NpsDimensionValue/CreateNpsNewDimension', {
        dimension: newDimension,
        idNps: answer.IdYourviews
      })
      .then((res) => {
        if (!res.data || res.data.HasErrors) throw Error('Error on save')
        if (!res.data.Success) {
          setLoading(false)

          notify('Ocorreu um erro desconhecido, tente novamente!', 'error')

          return
        }
        setLoading(false)
        handleCloseDimensionModal()
        setNewDimensionError(null)

        setAnswer(res.data.Nps)
      })
      .catch((error) => {
        setLoading(false)

        notify(error.message, 'error')

        setNewDimensionError(null)
      })
      .finally(() => {
        setLoading(false)
        setNewDimensionError(null)
      })
  }

  const campaignName = useMemo(() => {
    return props.campaigns?.find((c) => c.Key === props.answer?.CampaignKey)
      ?.Description
  }, [props.answer.CampaignKey, props.campaigns])

  const hasMediumPermission = Permissions.mediumRights.includes(user?.Role)
  const hasManagerPermission = Permissions.managerRights.includes(user?.Role)

  return (
    <>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={IconMap.get(4)}>
          <section className="answer-item-header">
            <NpsIcon rating={answer.RatingAsDecimal} type={answer.Type} />
            {!expanded ? (
              <div className="answer-item-summary">
                {props.answer.Type !== NPS_CSAT_TAB.csat.typeValue && (
                  <p className="answer-item-summary-comment">
                    {answer.Comment && answer.Comment.length > 1 ? (
                      text.maxSize(answer.Comment, 145)
                    ) : (
                      <em>{props.t('answer.noComment')}</em>
                    )}
                  </p>
                )}
                <div className="answer-item-summary-user">
                  <span className="answer-item-summary-name">
                    {answer.Person.Name || props.t('answer.noUser')}
                  </span>
                  <span className="answer-item-summary-email">
                    <CopyToClipboard
                      onCopySuccessMessage="copyToClipboard.success"
                      tooltip="copyToClipboard.emailTooltip"
                      text={answer.Person.Email}
                      asLink
                    />
                  </span>
                </div>
                {campaignName && (
                  <div className="answer-item-summary-user">
                    <div className="answer-item-summary-name">
                      {props.t('answer.campaign')}
                    </div>
                    <div className="answer-item-summary-email">
                      {campaignName}
                    </div>
                  </div>
                )}

                {getChips(true)
                  .filter((_e, i) => i < 3)
                  .map((chip, index) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      color="primary"
                      clickable={false}
                      size="small"
                      label={chip.text}
                      onDelete={hasMediumPermission && (
                        () => remove(chip, chip.type)
                      )}
                    />
                  ))}
              </div>
            ) : (
              <div className="answer-item-summary">
                <div className="answer-item-summary-user">
                  <div className="answer-item-summary-user-wrapper">
                    <span className="answer-item-summary-name">
                      {answer.Person.Name || props.t('answer.noUser')}
                    </span>
                    <NpsLabel
                      type={answer.Type}
                      rating={answer.RatingAsDecimal}
                    />
                  </div>
                  <div className="answer-item-summary-user-wrapper">
                    <span className="answer-item-summary-email">
                      <CopyToClipboard
                        onCopySuccessMessage="copyToClipboard.success"
                        tooltip="copyToClipboard.emailTooltip"
                        text={answer.Person.Email}
                        asLink
                      />
                    </span>
                    <div className="answer-item-summary-nps">
                      <span className="answer-item-id">
                        {'#'}
                        <CopyToClipboard
                          onCopySuccessMessage="copyToClipboard.success"
                          tooltip="copyToClipboard.idNpsTooltip"
                          text={answer.IdYourviews}
                        />
                      </span>
                      <span className="answer-item-type">
                        {getType(answer.Origin)}
                      </span>
                    </div>
                  </div>
                </div>
                {campaignName && (
                  <div className="answer-item-summary-user">
                    <div className="answer-item-summary-name">
                      {props.t('answer.campaign')}
                    </div>
                    <div className="answer-item-summary-email">
                      {campaignName}
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="answer-item-summary-right">
              <span className="answer-item-date">
                {dateToLocale(new Date(answer.Date + 'Z'))}
              </span>
              {!answer.Active && (
                <Chip
                  size="small"
                  variant="outlined"
                  color="secondary"
                  clickable={false}
                  label="Inválido"
                />
              )}
            </div>
          </section>
        </AccordionSummary>

        <AccordionDetails>
          <section className="answer-item-body">
            {props.answer.Type !== NPS_CSAT_TAB.csat.typeValue && (
              <p className="answer-item-comment">
                {answer.Comment && answer.Comment.length > 1 ? (
                  answer.Comment
                ) : (
                  <em>{props.t('answer.noComment')}</em>
                )}
              </p>
            )}
            {getChips().map((chip, index) => (
              <Chip
                key={index}
                size="small"
                color="primary"
                variant="outlined"
                clickable={false}
                label={chip.text}
                onDelete={hasMediumPermission && (
                  () => remove(chip, chip.type)
                )}
              />
            ))}

            {loading && <Loader size={25} />}

            <div className="answer-item-action">
              {hasMediumPermission && (
                <>
                  {answer.Active ? (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => validState()}
                    >
                      {IconMap.get(5)} {props.t('answer.btnInvalid')}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => validState()}
                    >
                      {IconMap.get(6)} {props.t('answer.btnValid')}
                    </Button>
                  )}

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setStoreComment(!storeComment)}
                  >
                    {IconMap.get(7)} {props.t('answer.btnStoreComment')}
                  </Button>

                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setIsTagging(!isTagging)}
                  >
                    {IconMap.get(8)} {props.t('answer.btnAddTag')}
                  </Button>
                </>
              )}

              {hasManagerPermission && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleShowDimensionModal}
                >
                  {IconMap.get(9)} {props.t('answer.btnAddDimension')}
                </Button>
              )}
            </div>

            <AnswerStoreComment
              showBtn={storeComment}
              comment={answer.AdminNotes}
              id={answer.IdYourviews}
            />
            {isTagging && (
              <AnswerTags
                current={answer.Tags}
                suggestions={tagsSuggestions}
                id={answer.IdYourviews}
                setTags={setNewTags}
              />
            )}
          </section>
        </AccordionDetails>
      </Accordion>

      <Confirmation
        accept={handleAccept}
        close={handleClose}
        message={modalMessage}
        show={showModal}
      />

      <Dialog
        show={showModalDimension}
        onClose={handleCloseDimensionModal}
        onCancel={handleCloseDimensionModal}
        onAccept={handleAcceptDimensionModal}
        title={props.t('answer.modal.addDimension.title')}
      >
        <div
          style={{
            display: loading ? 'flex' : 'none',
            justifyContent: 'center'
          }}
        >
          <CircularProgress />
        </div>
        <div
          style={{
            display: !loading ? 'block' : 'none'
          }}
        >
          <DimensionForm error={newDimensionError} onChange={setNewDimension} />
        </div>
      </Dialog>
    </>
  )
}

export default withTranslation()(AnswerItem)
