import styled from 'styled-components'

export const ListItem = styled.li`
  margin: 5px;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  background-color: #333;
`

export const Li = styled.li`
  margin: 5px;
  padding: 5px;
`

export const InfoItem = styled.span`
  margin: 5px;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  background-color: #222;
`
