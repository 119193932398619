import PropTypes from 'prop-types'

import { Route } from 'react-router-dom'

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest}>{(props) => <Component {...props} />}</Route>
}

const { string, func } = PropTypes

PublicRoute.propTypes = {
  path: string,
  component: func
}

export default PublicRoute
