export function dateToIsoWithTimezone(date) {
  var timezone_offset_min = date.getTimezoneOffset(),
    offset_hrs = parseInt(Math.abs(timezone_offset_min / 60)),
    offset_min = Math.abs(timezone_offset_min % 60),
    timezone_standard
  if (offset_hrs < 10) offset_hrs = '0' + offset_hrs
  if (offset_min < 10) offset_min = '0' + offset_min
  if (timezone_offset_min < 0)
    timezone_standard = '+' + offset_hrs + ':' + offset_min
  else if (timezone_offset_min > 0)
    timezone_standard = '-' + offset_hrs + ':' + offset_min
  else if (timezone_offset_min === 0) timezone_standard = 'Z'

  var dt = date,
    current_date = dt.getDate(),
    current_month = dt.getMonth() + 1,
    current_year = dt.getFullYear(),
    current_hrs = dt.getHours(),
    current_mins = dt.getMinutes(),
    current_secs = dt.getSeconds(),
    current_datetime

  current_date = current_date < 10 ? '0' + current_date : current_date
  current_month = current_month < 10 ? '0' + current_month : current_month
  current_hrs = current_hrs < 10 ? '0' + current_hrs : current_hrs
  current_mins = current_mins < 10 ? '0' + current_mins : current_mins
  current_secs = current_secs < 10 ? '0' + current_secs : current_secs

  current_datetime =
    current_year +
    '-' +
    current_month +
    '-' +
    current_date +
    'T' +
    current_hrs +
    ':' +
    current_mins +
    ':' +
    current_secs

  return current_datetime + timezone_standard
}
