import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ container1 }) =>
    container1
      ? `
      width: 600px;
      float: left;
      margin: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 80px;

      @media(max-width: 900px) {
      position: unset;
      transform: initial;
      margin: 0 auto;
      float: none;
      margin-top: 100px;
    }
     `
      : ``}

  ${({ container2 }) =>
    container2
      ? ` 
       right: 0px;
      width: calc(74% - 600px);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      min-width: 530px;
      @media(max-width: 900px) {
        position: unset;
        transform: initial;
        float: none;
        margin-top: 100px;
      }
      `
      : ``}
`
export const Title = styled.h1`
  font-size: 36px;
  font-weight: 900;
`

export const SaveButton = styled.button`
  color: #fff;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  margin: auto 0px;
  cursor: pointer;
  background-color: #00aeef;
  width: 103px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  border: none;
  ${({ largeSize }) =>
    largeSize
      ? `
  width:300px;
  line-height: 56px;   
    margin: 0 auto;
    border-radius: 36px;
    height: 56px;
  
  `
      : ``}

  ${({ medium }) =>
    medium
      ? `
  width: 90px;
  height: 37px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 37px;
  `
      : ``}

${({ send }) =>
    send
      ? `
width: 55px;
height: 55px;
border-radius: 50%;
float:right;
line-height: 70px;
`
      : ``}
`
export const Button = styled.div`
  margin: 8px;
  color: #00aeef;
  display: block;
  cursor: pointer;
`

export const Image = styled.img``

export const Text = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
`
