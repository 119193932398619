import styled from 'styled-components'

export const Faixa = styled.div`
  width: 235px;
  height: 40px;
  background-color: #00aeef;
  float: left;
  left: -48px;
  text-align: center;
  color: #fff;
  bottom: 80px;
  position: absolute;
  transform: rotate(-35deg);
  line-height: 40px;
  font-size: 18px;
`
