import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 40,
    fontWeight: 700,
    color: '#333',
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5
  },
  actions: {
    marginTop: 5,
    paddingTop: 5
  }
}))

export const Container = styled.div`
  margin-bottom: 56px;
`

export default useStyles
