import { Component } from 'react'
import ReactDOM from 'react-dom'

class Frame extends Component {
  componentDidMount() {
    this.renderFrameContents()
  }

  renderFrameContents = () => {
    let doc = ReactDOM.findDOMNode(this).contentDocument
    if (doc.readyState === 'complete') {
      let reactapp = document.createElement('div')
      reactapp.id = 'frame'
      reactapp.style.pointerEvents = 'none'

      if (doc.getElementById('frame')) {
        let duplicated = doc.getElementById('frame')
        duplicated.parentNode.removeChild(duplicated)
      }

      doc.body.appendChild(reactapp)
      ReactDOM.render(this.props.children, reactapp)
    } else {
      setTimeout(this.renderFrameContents, 0)
    }
  }

  componentDidUpdate() {
    this.renderFrameContents()
  }

  componentWillUnmount() {
    ReactDOM.unmountComponentAtNode(
      ReactDOM.findDOMNode(this).contentDocument.body
    )
  }

  render() {
    return (
      <iframe
        title={this.props.title}
        style={{
          width: this.props.small ? '400px' : '100%',
          height: this.props.height ?? '100%'
        }}
      />
    )
  }
}

export default Frame
