import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ noMargin }) => (noMargin ? '' : 'margin: 300px auto;')}
`
