import { withTranslation } from 'react-i18next'
import {
  Bar,
  Line,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer
} from 'recharts'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { List, ListItem, ListItemText, Popover } from '@material-ui/core'
import { QS_DATE_INTERVAL } from 'core/constants/query-strings'
import { NPS_ZONE } from 'core/enums/nps-zone'
import { DATE_INTERVAL } from 'core/enums/date-interval'
import { Skeleton } from '@material-ui/lab'
import { QS_DATE_FROM } from 'core/constants/query-strings'
import { QS_DATE_TO } from 'core/constants/query-strings'
import { QS_CAMPAIGN } from 'core/constants/query-strings'

const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  } else {
    return 'rgb(' + r + ', ' + g + ', ' + b + ')'
  }
}

const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

const getDateFromDDMMYYYY = (string) => {
  const keySplit = string.split('/')
  return new Date(keySplit[2], parseInt(keySplit[1]) - 1, keySplit[0])
}

const categoryTypesArray = Object.values(NPS_ZONE)
const groupByFilterArray = Object.values(DATE_INTERVAL)

const getCategory = (value) => {
  const type = categoryTypesArray.find(
    (type) => value >= type.min && value < type.max
  )
  return type || {}
}

function toChart(raw, moment, i18n) {
  moment.locale(i18n.language === 'pt' ? 'pt-br' : i18n.language)
  if (
    !raw.UnfilteredResult.Histogram ||
    raw.UnfilteredResult.Histogram.length < 1
  )
    return []

  const hasFilter = raw.FilteredResult !== null
  const data = raw.UnfilteredResult.Histogram.map((item, index) => {
    if (hasFilter) {
      item.CleanValue = item.Value
      item.Value = raw.FilteredResult.Histogram[index].Value
      item.PromoterCount = raw.FilteredResult.Histogram[index].PromoterCount
      item.NeutralCount = raw.FilteredResult.Histogram[index].NeutralCount
      item.DetractorCount = raw.FilteredResult.Histogram[index].DetractorCount
    }

    return {
      CleanValue: hasFilter ? Math.round(item.CleanValue) : null,
      Value: Math.round(item.Value || item.Value),
      PromoterCount: Math.round(item.PromoterCount),
      NeutralCount: Math.round(item.NeutralCount),
      DetractorCount: Math.round(item.DetractorCount),
      Key: moment.utc(item.Key).format('L'),
      CanShow: item.Value !== null
    }
  }).filter((item) => item.CanShow)
  return data
}

const getCellColor = (item, currentAnswersDateFilter) => {
  const color = getCategory(item.Value).color
  let opacity = 1
  if (
    currentAnswersDateFilter.from &&
    currentAnswersDateFilter.to &&
    currentAnswersDateFilter.from !==
      getDateFromDDMMYYYY(item.Key).toISOString()
  ) {
    opacity = 0.2
  }
  return hexToRGB(color, opacity)
}

const Chart = ({
  data,
  decimal,
  loading,
  onChangeGroupBy,
  onUpdateAnswersDateFilter,
  i18n,
  t
}) => {
  const location = useLocation()
  const [filterModalAnchorEl, setFilterModalAnchorEl] = useState(false)

  const searchObj = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const selectedGroupByFilter = useMemo(() => {
    return searchObj[QS_DATE_INTERVAL]
  }, [searchObj])

  const currentFromFilter = useMemo(() => {
    return searchObj[QS_DATE_FROM]
  }, [searchObj])

  const currentToFilter = useMemo(() => {
    return searchObj[QS_DATE_TO]
  }, [searchObj])

  const currentCampaign = useMemo(() => {
    return searchObj[QS_CAMPAIGN]
  }, [searchObj])

  const handleSelectGroup = useCallback(
    (group) => {
      onChangeGroupBy(group)
      setFilterModalAnchorEl()
    },
    [onChangeGroupBy]
  )

  const [answersDateFilter, setAnswersDateFilter] = useState({
    from: '',
    to: ''
  })

  useEffect(() => {
    setAnswersDateFilter({
      from: '',
      to: ''
    })
  }, [
    selectedGroupByFilter,
    currentFromFilter,
    currentToFilter,
    currentCampaign
  ])

  useEffect(() => {
    onUpdateAnswersDateFilter(answersDateFilter)
  }, [answersDateFilter, onUpdateAnswersDateFilter])

  const chartedData = useMemo(() => {
    if (data && data.UnfilteredResult) {
      return toChart(data, moment, i18n)
    }
  }, [data, i18n])

  const chartedDataWithColor = useMemo(() => {
    if (chartedData) {
      chartedData.forEach((item) => {
        item.color = getCellColor(item, answersDateFilter)
      })
      return chartedData
    }
    return []
  }, [answersDateFilter, chartedData])

  const canShow = useMemo(() => {
    return chartedDataWithColor && chartedDataWithColor.length
  }, [chartedDataWithColor])

  const onChartClick = useCallback(
    (event) => {
      const selectedItem = chartedDataWithColor.find(
        (item) => item.Key === event.activeLabel
      )

      if (selectedItem) {
        const startDate = getDateFromDDMMYYYY(selectedItem.Key)
        const newStartDateString = startDate.toISOString()

        let endDate
        let newEndDateString
        if (selectedGroupByFilter === DATE_INTERVAL.day) {
          endDate = new Date(startDate.setDate(startDate.getDate() + 1))
        } else if (selectedGroupByFilter === DATE_INTERVAL.week) {
          endDate = new Date(startDate.setDate(startDate.getDate() + 7))
        } else if (selectedGroupByFilter === DATE_INTERVAL.month) {
          endDate = new Date(startDate.setMonth(startDate.getMonth() + 1))
        } else if (selectedGroupByFilter === DATE_INTERVAL.quarter) {
          endDate = new Date(startDate.setMonth(startDate.getMonth() + 3))
        } else if (selectedGroupByFilter === DATE_INTERVAL.year) {
          endDate = new Date(startDate.setFullYear(startDate.getFullYear() + 1))
        }
        newEndDateString = endDate.toISOString()

        if (
          answersDateFilter.from !== newStartDateString ||
          answersDateFilter.to !== newEndDateString
        ) {
          setAnswersDateFilter({
            from: newStartDateString,
            to: newEndDateString
          })
        } else {
          setAnswersDateFilter({
            from: '',
            to: ''
          })
        }
      }
    },
    [
      answersDateFilter.from,
      answersDateFilter.to,
      chartedDataWithColor,
      selectedGroupByFilter
    ]
  )

  return loading ? (
    <div className="evolution-chart-wrapper">
      <div className="evolution-chart-header">
        <div>
          <Skeleton variant="rect" width="200px" height={50} />
        </div>
        <div className="evolution-chart-filters">
          <Skeleton variant="rect" width="150px" height={30} />
        </div>
      </div>
      <Skeleton variant="rect" width="100%" height={190} />
      <div className="evolution-chart-legend-wrapper">
        <Skeleton variant="rect" width="400px" height={20} />
      </div>
    </div>
  ) : (
    <div className="evolution-chart-wrapper">
      <div className="evolution-chart-header">
        <div>
          <div className="evolution-chart-title-small">
            {t('evolution.chart.titleSmall')}
          </div>
          <div className="evolution-chart-title-big">
            {t('evolution.chart.titleBig')}
          </div>
        </div>

        <div className="evolution-chart-filters">
          <div className="evolution-chart-filters-label">
            {t('evolution.chart.groupBy.label')}
          </div>
          <div
            className="evolution-chart-filters-input"
            onClick={(e) => setFilterModalAnchorEl(e.target)}
          >
            <span>
              {t(
                'evolution.chart.groupBy.' +
                  getKeyByValue(DATE_INTERVAL, selectedGroupByFilter)
              )}
            </span>
            <ArrowDropDown />
          </div>
        </div>
      </div>
      {!canShow ? (
        <div className="evolution-chart-empty">
          {t('evolution.noFilteredData')}
        </div>
      ) : (
        <ResponsiveContainer width="99%" height={230}>
          <ComposedChart onClick={onChartClick} data={chartedDataWithColor}>
            <CartesianGrid vertical={false} />
            <XAxis tickLine={false} axisLine={false} dataKey="Key" />
            <YAxis tickLine={false} axisLine={false} />
            <Tooltip
              formatter={(value, name) => [
                intlFloatPoint(value.toFixed(decimal), i18n.language),
                t('evolution.chartLabels.nps.' + name)
              ]}
            />

            <Bar dataKey="Value" barSize={120}>
              {chartedDataWithColor.map((item, index) => (
                <Cell fill={item.color} key={`cell-${index}`} />
              ))}
            </Bar>
            <Line type="monotone" dataKey="CleanValue" stroke="#666" />
          </ComposedChart>
        </ResponsiveContainer>
      )}

      <div className="evolution-chart-legend-wrapper">
        {categoryTypesArray.map((type) => (
          <div key={type.text} className="evolution-chart-legend">
            <div
              className="evolution-chart-legend-icon"
              style={{ background: type.color }}
            ></div>
            <div className="evolution-chart-legend-text">
              {t('evolution.chart.type.' + type.text)}
            </div>
          </div>
        ))}
      </div>

      {filterModalAnchorEl && (
        <Popover
          open={filterModalAnchorEl}
          anchorEl={filterModalAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={() => setFilterModalAnchorEl()}
        >
          <List>
            {groupByFilterArray.map((group) => (
              <ListItem button onClick={() => handleSelectGroup(group)}>
                <ListItemText>
                  {t(
                    'evolution.chart.groupBy.' +
                      getKeyByValue(DATE_INTERVAL, group)
                  )}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Popover>
      )}
    </div>
  )
}

export default withTranslation()(Chart)
