import '../../../style/route/api/main.css'
import { withTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  Button
} from '@material-ui/core'
import { useMemo } from 'react'

const Api = ({
  items,
  totalItemCount,
  page,
  pageSize,
  onChangePage,
  onChangeRowsPerPage,
  onChangeUserStatus,
  t
}) => {
  const itemsMemo = useMemo(() => {
    if (!items.length) {
      return (
        <TableRow className="api-row">
          <TableCell width="100%" align="center">
            {t('api.table.empty')}
          </TableCell>
        </TableRow>
      )
    }

    return items.map((apiUser) => (
      <TableRow className="api-row" key={apiUser.IdYourviews}>
        <TableCell width="45%">{apiUser.Label}</TableCell>
        <TableCell width="50%">{apiUser.UserName}</TableCell>
        <TableCell width="60px">
          <div className="api-status-button">
            {apiUser.Active ? (
              <Button
                className=""
                variant="text"
                onClick={() => {
                  onChangeUserStatus(apiUser, false)
                }}
                color="secondary"
              >
                Inativar
              </Button>
            ) : (
              <Button
                className=""
                variant="text"
                onClick={() => {
                  onChangeUserStatus(apiUser, true)
                }}
                color="primary"
              >
                Ativar
              </Button>
            )}
          </div>
        </TableCell>
      </TableRow>
    ))
  }, [items, onChangeUserStatus, t])

  return (
    <>
      <TableContainer style={{ maxHeight: 'calc(100vh - 265px)' }}>
        <Table stickyHeader rows={items}>
          <TableHead className="api-head">
            <TableRow>
              {!!items.length ? (
                <>
                  <TableCell>{t('api.table.identifier')}</TableCell>
                  <TableCell>{t('api.table.user')}</TableCell>
                  <TableCell></TableCell>
                </>
              ) : (
                <TableCell>&nbsp;</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>{itemsMemo}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalItemCount}
        page={page - 1}
        onChangePage={onChangePage}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  )
}

export default withTranslation()(Api)
