import './index.css'
import { withTranslation } from 'react-i18next'
import WidgetCards from './Components/WidgetCards'
import WidgetTable from './Components/WidgetTable'
import EmptyState from '../EmptyState'

const ConversionWidget = ({ widgetData, loadingWidgetData, t }) => {
  return !loadingWidgetData &&
    !widgetData.Answered &&
    !widgetData.Viewed &&
    !widgetData.ViewedByPerson ? (
    <EmptyState description={t('conversion-report.empty.descriptionWidget')} />
  ) : (
    <div className="conversion-widget-wrapper">
      <WidgetCards
        widgetData={widgetData}
        loadingWidgetData={loadingWidgetData}
      />

      <WidgetTable />
    </div>
  )
}

export default withTranslation()(ConversionWidget)
