import styled from 'styled-components'

export const Container = styled.div``

export const Header = styled.div``

export const Actions = styled.div`
  margin: 20px 0 0 0;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;

  min-width: 350px;
  max-width: 400px;
`

export const DynamicForm = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormField = styled.div`
  display: flex;
  width: 100%;
  max-width: 400px;
  margin: 10px 0px;
`
