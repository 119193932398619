import React from 'react'

import { Redirect, Switch } from 'react-router-dom'
import PublicRoute from './components/Public'
import PrivateRoute from './components/Private'
import Signup from 'modules/Authentication/components/Signup'
import Recovery from 'modules/Authentication/components/RecoveryPassword'
import SingleSignOn from 'modules/Authentication/components/SingleSignOn'

import Home from 'modules/Home'

import StoreManagement from 'modules/StoreManagement'

import Answer from 'modules/Answer'

import Campaign from 'modules/Campaign'
import Details from 'modules/Campaign/components/Details'

import Person from 'modules/Person'
import PersonDetail from 'modules/Person/components/PersonDetail'

import Wordcloud from 'modules/Reports/Wordcloud'
import Stars from 'modules/Reports/Stars'
import Nps from 'modules/Reports/Nps'

import Conversion from 'modules/Reports/Conversion'
import Import from 'modules/Import'

import FormEditor from 'modules/Form/components/FormEditor'
import Form from 'modules/Form'

import TemplateEdit from 'modules/Template/components/TemplateEdit'
import Template from 'modules/Template'

import Integration from 'modules/Integration'

import Api from 'modules/Api'

import Admin from 'modules/Admin'
import AdminEdit from 'modules/Admin/components/AdminEdit'
import Notifications from 'modules/Admin/components/Notifications'

import Write from 'pages/Write'
import Health from 'pages/Health'
import QaTests from 'pages/QaTests'
import NotFound from 'pages/NotFound'
import WidgetScript from 'pages/WidgetScript'
import CampaignSuccess from 'pages/CampaignSuccess'
import DirectLinkImport from 'pages/DirectLinkImport'
import HorusLogin from 'modules/Authentication/components/HorusLogin'
import PrivateRouteHorus from './components/PrivateRouteHorus'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import HorusCallback from 'modules/Authentication/components/HorusCallback/HorusCallback'
import { URL_HORUS_REDIRECT } from 'core/constants/horus'

export const BASE_PATH = '/admin/'

const HorusLoginRedirect = () => {
  return (
    <Route
      component={() => {
        window.location.href = URL_HORUS_REDIRECT
        return null
      }}
    />
  )
}

const Router = () => (
  <Switch>
    <PublicRoute path={BASE_PATH + 'sso'} component={SingleSignOn} />
    <PublicRoute path={BASE_PATH + 'login'} component={HorusLoginRedirect} />
    <PublicRoute path={BASE_PATH + 'write'} component={Write} />
    <PublicRoute path={BASE_PATH + 'signup'} component={Signup} />
    <PublicRoute path={BASE_PATH + 'health'} component={Health} />
    <PublicRoute path={BASE_PATH + 'recovery'} component={Recovery} />
    <PublicRoute path={BASE_PATH + 'WidgetScript'} component={WidgetScript} />
    <PublicRoute
      path={BASE_PATH + 'directlinkimport'}
      component={DirectLinkImport}
    />
    <PublicRoute
      path={BASE_PATH + 'horus/callback'}
      component={HorusCallback}
    />

    <PrivateRouteHorus
      path={BASE_PATH + 'horus/login'}
      component={HorusLogin}
    />

    <PrivateRoute exact path={BASE_PATH} component={Home} />

    <PrivateRoute path={BASE_PATH + 'qa'} component={QaTests} />
    <PrivateRoute path={BASE_PATH + 'answer'} component={Answer} />

    <PrivateRoute exact path={BASE_PATH + 'campaign'} component={Campaign} />
    <PrivateRoute path={BASE_PATH + 'campaign/:item'} component={Details} />
    <PrivateRoute
      path={BASE_PATH + 'CampaignSuccess'}
      component={CampaignSuccess}
    />

    <PrivateRoute exact path={BASE_PATH + 'person'} component={Person} />
    <PrivateRoute path={BASE_PATH + 'person/:item'} component={PersonDetail} />

    <PrivateRoute exact path={BASE_PATH + 'reports/nps'} component={Nps} />
    <PrivateRoute path={BASE_PATH + 'reports/nps/:view'} component={Nps} />

    <PrivateRoute
      exact
      path={BASE_PATH + 'reports/conversion'}
      component={Conversion}
    />
    <PrivateRoute
      path={BASE_PATH + 'reports/conversion/:view'}
      component={Conversion}
    />

    <PrivateRoute
      path={BASE_PATH + 'reports/word-cloud'}
      component={Wordcloud}
    />
    <PrivateRoute path={BASE_PATH + 'reports/stars'} component={Stars} />

    <PrivateRoute path={BASE_PATH + 'import'} component={Import} />

    <PrivateRoute exact path={BASE_PATH + 'form'} component={Form} />
    <PrivateRoute path={BASE_PATH + 'form/:item'} component={FormEditor} />

    <PrivateRoute exact path={BASE_PATH + 'template'} component={Template} />
    <PrivateRoute
      path={BASE_PATH + 'template/:item'}
      component={TemplateEdit}
    />

    <PrivateRoute path={BASE_PATH + 'integrations'} component={Integration} />

    <PrivateRoute exact path={BASE_PATH + 'administrator'} component={Admin} />
    <PrivateRoute
      path={BASE_PATH + 'administrator/:item'}
      component={AdminEdit}
    />

    <PrivateRoute exact path={BASE_PATH + 'api'} component={Api} />

    <PrivateRoute
      exact
      path={BASE_PATH + 'notifications'}
      component={Notifications}
    />

    <PrivateRoute
      exact
      path={BASE_PATH + 'storemanagement'}
      component={StoreManagement}
    />

    <PrivateRoute path={BASE_PATH + '*'} component={NotFound} />

    <Redirect from="/" to={BASE_PATH} />
  </Switch>
)

export default Router
