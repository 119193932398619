import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '16px 24px',
    width: '100%',
    boxSizing: 'border-box'
  },
  paper: {
    padding: 8,
    margin: '12px 16px'
  },
  flexEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    width: '100%'
  }
}))

export default useStyles
