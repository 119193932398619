import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import Tooltip from '@material-ui/core/Tooltip'
import Link from '@material-ui/core/Link'

const CopyToClipboard = ({
  asLink,
  text,
  onCopySuccessMessage,
  tooltip,
  tooltipPosition,
  icone,
  float,
  position,
  color,
  right,
  paddingTop,
  marginBottom,
  dontText,
  t
}) => {
  const [copySuccess, setCopySuccess] = useState(false)

  const copy = (event, text) => {
    event.preventDefault()
    event.stopPropagation()
    navigator.clipboard.writeText(text)
    setCopySuccess(true)
  }

  useEffect(() => {
    setTimeout(() => setCopySuccess(false), 2000)
  }, [copySuccess])

  return (
    <>
      {asLink && (
        <Link onClick={(event) => copy(event, text)}>
          <Tooltip
            title={copySuccess ? t(onCopySuccessMessage) : t(tooltip)}
            placement={tooltipPosition}
          >
            <span id="text-to-copy">{text}</span>
          </Tooltip>
        </Link>
      )}
      {!asLink && (
        <Tooltip
          title={copySuccess ? t(onCopySuccessMessage) : t(tooltip)}
          placement={tooltipPosition}
        >
          <span onClick={(event) => copy(event, text)}>
            {icone && (
              <div
                style={{
                  width: '100%',
                  backgroundColor: '#485A6C',
                  height: '48px',
                  marginBottom: '-10px',
                  borderRadius: '5px 5px'
                }}
              >
                <span
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    lineHeight: '48px',
                    marginLeft: '16px'
                  }}
                >
                  Java Script
                </span>

                <FileCopyIcon
                  style={{
                    float: float,
                    position: position,
                    color: color,
                    right: right,
                    paddingTop: paddingTop,
                    cursor: 'pointer',
                    marginBottom: marginBottom
                  }}
                />
              </div>
            )}
            {dontText && !icone && (
              <FileCopyIcon
                style={{
                  float: float,
                  position: position,
                  color: color,
                  right: right,
                  paddingTop: paddingTop,
                  cursor: 'pointer',
                  marginBottom: marginBottom
                }}
              />
            )}

            {!icone && !dontText && text}
          </span>
        </Tooltip>
      )}
    </>
  )
}

CopyToClipboard.defaultProps = {
  asLink: false,
  tooltip: 'copyToClipboard.defaultTooltip',
  tooltipPosition: 'top',
  onCopySuccessMessage: 'copyToClipboard.success'
}

const { string, bool, number, oneOfType } = PropTypes

CopyToClipboard.propTypes = {
  asLink: bool,
  text: oneOfType([string, number]),
  tooltip: string,
  tooltipPosition: string,
  onCopySuccessMessage: string
}

export default withTranslation()(CopyToClipboard)
