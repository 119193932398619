/**
 * Get a plan list items of a listing with child nodes
 *
 * @param {Object[]} items Items from a dictionary
 */
export const getItems = (items) => {
  let children = [];
  return items.map(i => {
    if (i.childrens && i.childrens.length) {
      children = [...children, ...i.childrens];
    }
    return i;
  }).concat(children.length ? getItems(children) : children);
}
