import { useState, useRef, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import Dropdown from '../../../../components/Form/Option/Dropdown'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'

import { QS_DIMENSION_VALUE_LIST } from 'core/constants/query-strings'
import { dimension } from 'core/helpers'
import axios from 'core/api'

const FilterDimension = ({ field, form: { setFieldValue }, ...props }) => {
  const [dimensionId, setDimensionId] = useState('')
  const [loading, setLoading] = useState(false)
  const [hasNoOption, setHasNoOption] = useState(false)
  const [dimensionValues, setDimensionValue] = useState([])
  const [valuesFound, setValuesFound] = useState([])
  const [dropdownKey, setDropdownKey] = useState(1)

  const inputRef = useRef(null)
  const timerToClearSomewhere = useRef(false)

  const search = () => {
    let query = inputRef.current.getElementsByTagName('input')[0].value

    clearInterval(timerToClearSomewhere.current)
    setHasNoOption(false)

    timerToClearSomewhere.current = setTimeout(() => {
      if (query.length < 3) return

      setLoading(true)
      axios
        .get(
          'dimensionvalue/getbyiddimensionandvalue?iddimension=' +
            dimensionId +
            '&value=' +
            query
        )
        .then((res) => {
          if (res.data.length < 1) {
            setHasNoOption(true)
            setDimensionValue([])
          }

          setDimensionValue(
            res.data.map((i) => ({
              id: i.IdYourviews.toString(),
              name: i.Value
            }))
          )
          setValuesFound(valuesFound.concat(res.data))
        })
        .catch(() => {
          setDimensionValue([])
        })
        .finally(() => {
          setLoading(false)
        })
    }, 500)
  }

  const removeDimensionValue = (id) => {
    let newValues = field.value
    const idFound = newValues.indexOf(id)
    if (idFound < 0) return

    newValues.splice(idFound, 1)
    setFieldValue(field.name, newValues)
  }

  useEffect(() => {
    let currentQS = queryString.parse(props.location.search)
    let value = currentQS[QS_DIMENSION_VALUE_LIST]
    if (!value || value.length < 1) return

    let selecteds = []
    if (value instanceof Array) selecteds = value
    else selecteds.push(value)

    let reqQS = {
      iddimensionvalue: selecteds
    }

    axios
      .get('DimensionValue/Get?' + queryString.stringify(reqQS))
      .then((res) => {
        if (!res.data || res.data.length < 1) return

        setValuesFound(valuesFound.concat(res.data))

        setFieldValue(
          field.name,
          field.value.concat(
            res.data
              .filter((i) => field.value.indexOf(i.IdYourviews.toString()) < 0)
              .map((i) => i.IdYourviews.toString())
          )
        )
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const selectedDimensionInsideArray = props.dimensions.find(
      (d) => !!d.IdYourviews && d.IdYourviews.toString() === dimensionId
    )

    if (dimensionId && !selectedDimensionInsideArray) {
      setDimensionValue([])
      setDimensionId('')
      setDropdownKey(dropdownKey + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dimensions, dimensionId])

  return (
    <div className="filter-dimension">
      <div className="form-field dropdown-field">
        <Dropdown
          key={dropdownKey}
          testId={field.name}
          items={dimension.toDropdownById(props.dimensions)}
          name={props.t('answer.filter.dimensionName')}
          onSelected={(item) => {
            setDimensionValue([])
            setDimensionId(item.id.toString())
          }}
        />
      </div>

      {dimensionId.length > 0 && (
        <div className="filter-dimension-autocomplete">
          <Autocomplete
            value={null}
            ref={inputRef}
            className="dropdown"
            options={dimensionValues}
            getOptionLabel={(option) => option.name}
            data-testid="autocomplete-dimension"
            onChange={(_event, newValue) => {
              const idFound = field.value.indexOf(newValue.id)

              if (idFound > -1) return

              setFieldValue(field.name, [...field.value, newValue.id])
            }}
            loading={loading}
            clearOnEscape={true}
            noOptionsText={
              hasNoOption
                ? props.t('answer.filter.dimensionNoOption')
                : props.t('answer.filter.dimensionToSearch')
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                onKeyUp={search}
                data-testid="autocomplete-textfield-dimension"
                label={props.t('answer.filter.dimensionValue')}
              />
            )}
          />
        </div>
      )}

      <div className="filter-dimension-chips">
        {field.value
          .map((f) => valuesFound.find((d) => d.IdYourviews.toString() === f))
          .map(
            (d) =>
              d && (
                <Chip
                  key={d.IdYourviews}
                  label={
                    props.dimensions.find(
                      (p) => p.IdYourviews === d.IdDimension
                    ).Name +
                    ': ' +
                    d.Value
                  }
                  data-testid="chips-dimension"
                  onDelete={() =>
                    removeDimensionValue(d.IdYourviews.toString())
                  }
                />
              )
          )}
      </div>
    </div>
  )
}

export default withTranslation()(withRouter(FilterDimension))
