import { useState, useCallback, useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import postData from 'core/functions/post-data.function'
import Loader from 'components/Loader'
import { useAppContext } from 'App'

const DashboardEditGoal = ({ open, onClose, scoreGoal, handleNewGoal, t }) => {
  const [newScoreGoal, setNewScoreGoal] = useState(0)
  const handleSetScoreGoal = useCallback((newVal) => {
    if (parseInt(newVal) < -100) {
      setNewScoreGoal(-100)
    } else if (parseInt(newVal) > 100) {
      setNewScoreGoal(100)
    } else {
      setNewScoreGoal(parseInt(newVal))
    }
  }, [])
  const [loading, setLoading] = useState(false)
  const { notify } = useAppContext()

  useEffect(() => {
    setNewScoreGoal(scoreGoal)
  }, [open, scoreGoal])

  const handleCloseEditGoal = useCallback(() => {
    onClose(false)
  }, [onClose])

  const handleConfirmEditGoal = useCallback(() => {
    postData({
      endpoint: 'nps/changescoregoal?newNpsScore=' + newScoreGoal,
      setLoading,
      setData: (response) => {
        if (response && response.Success) {
          handleCloseEditGoal()
          handleNewGoal()
        } else {
          notify(t('dashboard.editGoal.error'), 'error')
        }
      },
      notify: () => {
        notify(t('dashboard.editGoal.error'), 'error')
      }
    })
  }, [handleCloseEditGoal, handleNewGoal, notify, newScoreGoal, t])

  return (
    <Dialog
      open={open}
      onClose={handleCloseEditGoal}
      aria-labelledby="form-dialog-title"
      data-testid="edit-goal-dialog"
      fullWidth
    >
      <DialogTitle>{t('dashboard.editGoal.title')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          value={newScoreGoal}
          onChange={(e) => handleSetScoreGoal(e.target.value)}
          label={t('dashboard.editGoal.inputLabel')}
          type="number"
          id="dashboard-editgoal-input"
          name="dashboard-editgoal-input"
          fullWidth
        />
        <DialogContentText>
          {t('dashboard.editGoal.recommendable')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEditGoal} color="primary">
          {t('dashboard.editGoal.cancel')}
        </Button>
        {!loading ? (
          <Button
            variant="contained"
            onClick={handleConfirmEditGoal}
            color="primary"
            disabled={!newScoreGoal && newScoreGoal !== 0}
          >
            {t('dashboard.editGoal.submit')}
          </Button>
        ) : (
          <div data-testid="dashboard-editgoal-spinner" style={{ margin: 5 }}>
            <Loader noMargin size={40} />
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default withTranslation()(DashboardEditGoal)
