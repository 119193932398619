import { useState } from 'react'

import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import moment from 'moment'

import { MdMoreVert } from 'react-icons/md'

import axios from 'core/api'

import Options from '../../../../components/Options/Options'
import OptionsItem from '../../../../components/Options/OptionsItem'
import TemplateNewCopy from '../TemplateNewCopy'
import { useAppContext } from '../../../../App'
import Loader from '../../../../components/Loader'

const TemplateItem = ({ i18n, history, match, template, t }) => {
  const { notify } = useAppContext()

  const [loading, setLoading] = useState(false)

  moment.locale(i18n.language === 'pt' ? 'pt-br' : i18n.language)

  const edit = () => {
    history.push(`${match.url}/${template.IdYourviews}`)
  }

  const copy = (values, setSubmitting) => {
    setLoading(true)

    axios
      .post(
        'template/clonetemplate?idtemplate=' +
          template.IdYourviews +
          '&clonename=' +
          values.copyName
      )
      .then((res) => {
        history.push(`${match.url}/${res.data.IdYourviews}`, res.data)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setSubmitting(false)
        setLoading(false)
      })
  }

  const [isNaming, setIsNaming] = useState(false)

  return loading ? (
    <Loader />
  ) : (
    <li className="blue-item white-form">
      <header
        className="blue-item-header"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <strong className="template-item-name">{template.Name}</strong>

        <Options title={<MdMoreVert />}>
          <OptionsItem onClick={() => edit()} title={t('template.item.edit')} />

          <OptionsItem
            onClick={() => setIsNaming(true)}
            title={t('template.item.copy')}
          />
        </Options>
      </header>

      <section>
        <p className="template-item-type">{t('emailType.' + template.Type)}</p>

        <p className="template-item-date">
          {`${t('template.item.lastUpdated')} ${moment(
            template.LastUpdate
          ).format('L')} ${moment(template.LastUpdate).format('HH:mm')}`}
        </p>
      </section>

      <TemplateNewCopy
        isNaming={isNaming}
        setIsNaming={setIsNaming}
        copy={copy}
        templateName={template.Name}
      />
    </li>
  )
}

export default withTranslation()(withRouter(TemplateItem))
