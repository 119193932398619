import { Children, cloneElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

const useStyles = makeStyles(() => ({
  wrap: {
    flexDirection: 'row'
  }
}))

const CheckboxGroup = ({
  onChange,
  onBlur,
  label,
  id,
  csat,
  changeDependency,
  inBlock,
  value,
  error,
  touched,
  children,
  className
}) => {
  const classes = useStyles()

  const handleChange = (event) => {
    const target = event.currentTarget
    let valueArray = [...value] || []

    if (target.checked) {
      valueArray.push(target.id)
      if (csat) changeDependency(Number(target.id), true)
    } else {
      valueArray.splice(valueArray.indexOf(target.id), 1)
      if (csat) changeDependency(Number(target.id), false)
    }

    onChange(id, valueArray)
  }

  const handleBlur = () => {
    onBlur(id, true)
  }

  return (
    <FormControl
      component="fieldset"
      error={!!(error && touched)}
      className={'checkbox-group ' + (className || '')}
    >
      {label && <FormLabel component="legend">{label}</FormLabel>}

      <FormGroup className={inBlock ? null : classes.wrap}>
        {Children.map(children, (child) => {
          if (child)
            return cloneElement(child, {
              field: {
                value: value.includes(child.props.id),
                onChange: handleChange,
                onBlur: handleBlur
              }
            })

          return null
        })}
      </FormGroup>

      {!!(error && touched) && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default CheckboxGroup
