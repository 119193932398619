export const QS_CAMPAIGN = 'idcampaign'
export const QS_CAMPAIGN_LIST = 'idcampaignlist'
export const QS_DATE_FROM = 'from'
export const QS_DATE_TO = 'to'
export const QS_DIMENSION = 'dimensionkey'
export const QS_DIMENSION_VALUE_LIST = 'iddimensionvaluelist'
export const QS_DIMENSION_VALUE = 'iddimensionValue'
export const QS_DATE_INTERVAL = 'dateinterval'
export const QS_ID_DIMENSION = 'iddimension'
export const QS_ID_DIMENSION_LIST = 'iddimensionlist'
export const QS_ID_NPS = 'idnps'
export const QS_IMPORT_SESSION = 'importsessionkey'
export const QS_NPS_CLASS = 'npsclassifications'
export const QS_NPS_COMMENT = 'npscomment'
export const QS_ORDENATION = 'ordenation'
export const QS_PAGE = 'page'
export const QS_PAGE_SIZE = 'PageSize'
export const QS_PASSWORD = 'password'
export const QS_PASSWORD_CONFIRMATION = 'passwordconfirmation'
export const QS_RECOVERY_KEY = 'recoverykey'
export const QS_PERSON_EMAIL = 'personnameoremail'
export const QS_TAG = 'idtaglist'
export const QS_WITH_COMMENT = 'withcomments'
export const QS_WRITE_ID = 'dispatchinfokey'
export const QS_WRITE_NPS = 'rating'
export const QS_ACTIVE = 'active'
export const QS_TYPES = 'types'
export const QS_TYPE = 'type'
export const QS_INDEXING_TYPE = 'IndexingType'
export const QS_NPS_INDEXING_TYPE = 'NpsIndexingType'
export const QS_DIMENSION_TYPE_LIST = 'DimensionTypeList'
export const QS_DISPATCH_IDS = 'DispatchSendingScheduleIds'
