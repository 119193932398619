import './index.css'
import { Tab, Tabs } from '@material-ui/core'
import { useCallback, useEffect, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'

function CustomTabs({
  match,
  tabs,
  queryParams,
  queryParamsToDeleteOnChange,
  onTabChange,
  pathParam
}) {
  const history = useHistory()
  const location = useLocation()

  const handleSetTabState = useCallback(
    (value) => {
      if (queryParams && queryParams.length) {
        const queryParamsAsObject = {}
        queryParams.forEach((queryParam) => {
          queryParamsAsObject[queryParam] = value
        })

        const newSearchObj = {
          ...queryString.parse(location.search),
          ...queryParamsAsObject
        }

        if (queryParamsToDeleteOnChange && queryParamsToDeleteOnChange.length) {
          queryParamsToDeleteOnChange.forEach((param) => {
            delete newSearchObj[param]
          })
        }

        history.push({
          pathname: location.pathname,
          search: queryString.stringify(newSearchObj)
        })
      }

      if (pathParam) {
        if (match.path.includes(':' + pathParam)) {
          history.push({
            pathname: match.path.replace(':' + pathParam, value),
            search: location.search
          })
        } else {
          history.push({
            pathname: match.path + '/' + value,
            search: location.search
          })
        }
      }
    },
    [
      history,
      location.pathname,
      location.search,
      match.path,
      pathParam,
      queryParams,
      queryParamsToDeleteOnChange
    ]
  )

  const handleTabChange = useCallback(
    (e, value) => {
      if (onTabChange) {
        onTabChange(value)
      }

      handleSetTabState(value)
    },
    [handleSetTabState, onTabChange]
  )

  const currentTabValue = useMemo(() => {
    if (queryParams && queryParams.length) {
      return queryString.parse(location.search)[queryParams[0]]
    }
    if (pathParam) {
      return match.params[pathParam]
    }
  }, [location.search, match.params, pathParam, queryParams])

  const tabValueExistInTabsArray = useMemo(() => {
    return !!tabs.find((tab) => tab.value === currentTabValue) && tabs.length
  }, [currentTabValue, tabs])

  useEffect(() => {
    if (!tabValueExistInTabsArray) {
      handleSetTabState(tabs[0].value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key])

  return (
    <div className="custom-tabs-outer-wrapper">
      <div className="custom-tabs-inner-wrapper">
        {tabValueExistInTabsArray && (
          <Tabs
            value={currentTabValue}
            variant="fullWidth"
            textColor="primary"
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            {tabs.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} />
            ))}
          </Tabs>
        )}
      </div>
    </div>
  )
}

export default withTranslation()(CustomTabs)
