import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  input: {
    width: '100%'
  },
  autocomplete: {
    width: 300
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  main: {
    display: 'flex',
    lineHeigh: '64px',
    fontSize: 13,
    marginBottom: 20,
    width: 300
  },
  info: {
    display: 'flex',
    margin: 'auto 5px',
    maxWidth: 300
  },
  values: {
    margin: '20px 0px'
  },
  error: {
    margin: '10px 0px',
    color: 'red'
  }
}))

export default useStyles
