import { getIn } from 'formik'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  error: {
    border: '5px solid blue'
  }
}))
const TextField = ({
  field,
  form: { touched, errors },
  noMaxWidth,
  ...props
}) => {
  const classes = useStyles()
  return (
    <FormControl
      error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
      className={`form-field text-field ${noMaxWidth ? 'noMaxWidth' : ''}`}
    >
      <InputLabel htmlFor={field.name}>{props.label}</InputLabel>

      <Input classes={classes.error} id={field.name} {...field} {...props} />

      {!!(
        field.name &&
        getIn(touched, field.name) &&
        getIn(errors, field.name)
      ) && <FormHelperText>{getIn(errors, field.name)}</FormHelperText>}
    </FormControl>
  )
}

export default TextField
