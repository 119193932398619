import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { isObject } from 'lodash'

import useRequest from 'core/hooks/useRequest'

import { withTranslation } from 'react-i18next'

import { CircularProgress, TextField, Chip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'

import { FIELD_TYPE } from 'core/enums/field-type'

import Autocomplete from '@material-ui/lab/Autocomplete'
import useStyles from './style'

const DimensionForm = ({ onChange, t, error }) => {
  const classes = useStyles()

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [dimension, setDimension] = useState(null)
  const [dimensionValues, setDimensionValues] = useState([])

  useRequest('dimension/getalldimensions', (res) =>
    setData(
      res.filter((item) => {
        if (
          item.Type === FIELD_TYPE.singleSelect ||
          item.Type === FIELD_TYPE.multiSelect
        ) {
          return item
        } else return false
      })
    )
  )

  useEffect(() => {
    data && setLoading(false)
  }, [data])

  useEffect(() => {
    if (dimension && dimension.Values.length > 0) {
      setOptions(dimension?.Values)
    }
  }, [dimension])

  useEffect(() => {
    dimension && setDimensionValues([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension])

  const handleValues = (value) => {
    if (!value || value.length < 1) return

    return Array.isArray(value)
      ? fillMultiValue(value)
      : [fillSingleValue(value)]
  }

  const fillSingleValue = (value) =>
    isObject(value)
      ? value
      : {
          Value: value,
          Active: true,
          IdDimension: dimension.IdYourviews
        }

  const fillMultiValue = (values) =>
    values.map((item) =>
      isObject(item)
        ? item
        : {
            Value: item,
            Active: true,
            IdDimension: dimension.IdYourviews
          }
    )

  useEffect(() => {
    onChange({
      ...dimension,
      Values: handleValues(dimensionValues)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimension, dimensionValues])

  const SelectMap = new Map([
    [
      FIELD_TYPE.singleSelect,
      <Autocomplete
        freeSolo
        value={dimensionValues}
        defaultValue={''}
        id="single-auto-complete"
        options={options ? options : []}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option
          if (option && option.Value) return option.Value

          return ''
        }}
        noOptionsText={t('answer.modal.addDimension.noOptions')}
        onChange={(event, newValue) => setDimensionValues(newValue)}
        onInputChange={(event, newValue) => setDimensionValues(newValue)}
        className={classes.autocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            className={classes.input}
            id="single-autocomplete-input"
            label={t('answer.modal.addDimension.singleValuePlaceholder')}
          />
        )}
      />
    ],
    [
      FIELD_TYPE.multiSelect,
      <Autocomplete
        multiple
        freeSolo
        size="small"
        id="tags-filled"
        value={dimensionValues || []}
        getOptionLabel={(option) => {
          if (typeof option === 'string') return option
          if (option && option.Value) return option.Value

          return ''
        }}
        defaultValue={[]}
        options={options ? options : []}
        renderTags={(value, getTagProps) => {
          if (typeof value === 'string') return

          return value.map((option, index) => {
            if (isObject(option))
              return (
                <Chip
                  size="small"
                  label={option.Value}
                  {...getTagProps({ index })}
                />
              )

            return (
              <Chip size="small" label={option} {...getTagProps({ index })} />
            )
          })
        }}
        onChange={(event, newValue) => setDimensionValues(newValue)}
        className={classes.autocomplete}
        renderInput={(params) => (
          <TextField
            {...params}
            className={classes.input}
            label={t('answer.modal.addDimension.multiValueLabel')}
          />
        )}
      />
    ]
  ])

  const makeDimension = (value) => ({
    Name: value,
    Key: value,
    Type: FIELD_TYPE.multiSelect,
    Active: true,
    Values: []
  })

  return (
    <>
      {loading ? (
        <div className={classes.loader}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={classes.main}>
            <InfoIcon fontSize="small" color="primary" />
            <span className={classes.info}>
              {t('answer.modal.addDimension.multiValuePlaceholder')}
            </span>
          </div>

          {data && (
            <Autocomplete
              freeSolo
              autoComplete
              includeInputInList
              value={dimension || ''}
              noOptionsText={t('answer.modal.addDimension.noOptions')}
              onChange={(event, newValue) => {
                if (typeof newValue === 'string')
                  setDimension(makeDimension(newValue))
                else if (newValue && newValue.inputValue)
                  setDimension(makeDimension(newValue.inputValue))
                else setDimension(newValue)
              }}
              id="single-selection-autocomplete"
              options={data}
              getOptionLabel={(option) => {
                if (!option) return ''
                if (typeof option === 'string') return option
                if (option.inputValue) return option.inputValue

                return option.Name
              }}
              renderOption={(option) => option.Name}
              className={classes.autocomplete}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className={classes.input}
                  label={t('answer.modal.addDimension.placeholder')}
                />
              )}
            />
          )}
        </>
      )}

      {
        <div className={classes.values}>
          {dimension &&
            SelectMap.has(dimension?.Type) &&
            SelectMap.get(dimension?.Type)}
        </div>
      }
      {error && <div className={classes.error}>{error}</div>}
    </>
  )
}

DimensionForm.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string
}

DimensionForm.defaultProps = {
  onChange: () => {}
}

export default withTranslation()(DimensionForm)
