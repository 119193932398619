import { Formik, Form } from 'formik'
import { FIELD_TYPE } from 'core/enums/field-type'
import { Container, FormBox, FormContainer } from './style'
import { useEffect, useMemo, useState } from 'react'
import FormFields from '../FormFields'

function FormPreview({ form }) {
  const [formFields, setFormFields] = useState(null)
  const [theme] = useState({
    BackgroundColor: '#ffffff',
    BackgroundImageUrl: '',
    LogoUrl: '',
    MainTitleColor: '#000000',
    MainTitleText: 'Responder Pesquisa',
    SecondaryText: '',
    SecondaryTextColor: '#000000',
    ThemeMainColor: '#00aeef',
    SendButtonColor: '#00aeef'
  })

  useEffect(() => {
    setFormFields(form)
  }, [form])

  const getFields = useMemo(() => {
    let fields = {
      NPS: '',
      Comment: '',
      PersonEmail: '',
      PersonName: ''
    }
    formFields?.FormFields?.forEach((item) => {
      fields[item?.IdYourviews?.toString()] =
        item.Type === FIELD_TYPE.multiSelect ? [] : ''
    })
    return fields
  }, [formFields?.FormFields])

  return (
    <Container>
      {formFields && (
        <FormBox>
          <Formik
            initialValues={getFields}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(propsForm) => {
              return (
                <Form>
                  <FormContainer>
                    <FormFields
                      form={formFields}
                      formikProps={propsForm}
                      theme={theme}
                      hideSubmitButton
                    />
                  </FormContainer>
                </Form>
              )
            }}
          </Formik>
        </FormBox>
      )}
    </Container>
  )
}

export default FormPreview
