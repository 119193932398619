import { createContext, useContext, useState } from 'react'

const PeopleListContext = createContext({})

export const PeopleListProvider = ({ children }) => {
  const [disableNext, setDisableNext] = useState(true)
  const [isLastStep, setIsLastStep] = useState(false)

  return (
    <PeopleListContext.Provider
      value={{ disableNext, setDisableNext, isLastStep, setIsLastStep }}
    >
      {children}
    </PeopleListContext.Provider>
  )
}

export const usePeopleList = () => {
  const context = useContext(PeopleListContext)

  const { disableNext, setDisableNext, isLastStep, setIsLastStep } = context

  return { disableNext, setDisableNext, isLastStep, setIsLastStep }
}
