const mapObject = (data) => data.Values.map((x) => [x.DimensionValue, x.Count])

const chartMapper = (dataFromAPI, header) => {
  if (!dataFromAPI || dataFromAPI === '') {
    return
  }

  return {
    data: [[header.dimension, header.value], ...mapObject(dataFromAPI)],
    isAllZero: dataFromAPI.Values.every((x) => x.Count === 0)
  }
}

export default chartMapper
