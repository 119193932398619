import styled from 'styled-components'

export const ListItem = styled.div`
  display: flex;

  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;

  align-items: center;
  align-content: center;

  ${({ condensed }) => (condensed ? 'padding: 4px 12px;' : 'padding: 12px;')}

  ${({ noHover }) =>
    noHover
      ? ''
      : `
        &:hover {
          background-color: #f2fbfe;
        }
      `}

  ${({ index, length }) => {
    const first = index === 0
    const last = index === length - 1

    if (!first && !last && index === 0 && length === 1) {
      return `
    border-radius: 4px;

    border: 1px solid #e0e0e0;
    `
    } else if (first) {
      return `
      border-radius: 4px 4px 0px 0px;
      
      border: 1px solid #e0e0e0;
      `
    } else if (last) {
      return `
        border-radius: 0px 0px 4px 4px;
        
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      `
    } else {
      return `
        border-radius: 0px;

        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      `
    }
  }}
`
