import { useState } from 'react'

import { withTranslation } from 'react-i18next'

import MaterialCard from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

import useStyles from './style'
import { Grid, Switch } from '@material-ui/core'

function Card({
  id,
  title,
  image,
  description,
  configure,
  keys,
  active,
  type,
  errors,
  onSwitch,
  t
}) {
  const integration = {
    id,
    title,
    image,
    description,
    configure,
    keys,
    active,
    type,
    errors,
    onSwitch
  }

  const classes = useStyles()

  const [switchState, setSwitchState] = useState(active)
  const handleSwitch = ({ target }) => {
    setSwitchState(target.checked)
    onSwitch(target.checked, integration)
  }

  return (
    <MaterialCard className={classes.root}>
      <CardActionArea>
        <CardMedia className={classes.media} image={image} title={title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
          <Typography
            className={classes.messageBox}
            variant="body2"
            color="textSecondary"
            component="div"
          >
            {errors && (
              <Grid container>
                <Grid item xs={3}>
                  <div className={classes.item}>
                    <ErrorOutlineIcon color="error" />
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div className={classes.errorMessage}>
                    {t('integrations.errorMessage')}
                  </div>
                </Grid>
              </Grid>
            )}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container>
          <Grid item xs={6}>
            <Button
              onClick={() => configure(integration)}
              size="small"
              color="primary"
              data-testid="dinamic-form-card-configure-btn"
            >
              {t('uiActions.configure')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Switch
              size="small"
              color="primary"
              name="active-integration"
              checked={switchState}
              onChange={handleSwitch}
              data-testid="dinamic-form-card-switch"
            />{' '}
            {active ? (
              <span className={classes.activeLabel}>
                {t('integrations.active')}
              </span>
            ) : (
              <span className={classes.activeLabel}>
                {t('integrations.inactive')}
              </span>
            )}
          </Grid>
        </Grid>
      </CardActions>
    </MaterialCard>
  )
}

export default withTranslation()(Card)
