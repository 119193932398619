import styled from 'styled-components'

export const Container = styled.div``
export const Content = styled.div`
  margin-top: 24px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid #e0e0e0;
`
export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 550px;
`

export const Box = styled.div``
export const MiniLoader = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;

  width: 32px;
`

export const Text = styled.div``
export const Title = styled.div``
export const Subtitle = styled.div``
export const Infos = styled.div``
export const InfoItem = styled.div``
export const Actions = styled.div``
