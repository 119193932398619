const GoogleAnalytics = {
  ID: 'UA-56472225-8',
  Category: {
    PageView: 'Pageview',
    Interaction: 'UserInteraction'
  },
  Actions: {
    SaveNps: 'SaveNps',
    Logout: 'Logout',
    CreateStore: 'CreateStore'
  }
}

export default GoogleAnalytics
