import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'

import useStyles from './style'

export default function FieldSafeArea({ children, paper, flexEnd, flex }) {
  const classes = useStyles()

  return (
    <>
      {flex ? (
        <div className={classes.flex}>{children}</div>
      ) : (
        <>
          {paper && (
            <Paper variant="outlined" className={classes.paper}>
              <div className={classes.root}>{children}</div>
            </Paper>
          )}

          {!paper && (
            <div
              className={`
            ${classes.root}
            ${flexEnd ? classes.flexEnd : ''}
          `}
            >
              {children}
            </div>
          )}
        </>
      )}
    </>
  )
}

FieldSafeArea.propTypes = {
  paper: PropTypes.bool
}

FieldSafeArea.defaultProps = {
  paper: false
}
