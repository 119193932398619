import { useEffect } from 'react'

import { read } from 'localstorage-helpr'

/**
 * Hook for get LS data and set to a component state, this hook call the `read` method from `localstorage-helpr` lib on component mount and execution.
 * @param {string} key LS Key for get Data
 * @param {Function} setState Function to set LS data to componente state (ex: reference for `setState` or `setMyData` on component)
 */
export const useGetLocalStorageData = (key, setState) => {
  useEffect(() => {
    setState(read(key))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useGetLocalStorageData
