import { useEffect, useMemo } from 'react'
import Icon from '@mdi/react'
import { mdiContentCopy, mdiPencil } from '@mdi/js'
import { Button, IconButton, TextField } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import axios from 'core/api'
import BoxList from '../BoxList'
import { Actions } from 'modules/Integration/components/Form/style'
import { Container, LeftActions, RightActions, Row, Title } from '../Form/style'
import {
  Column,
  InsideContent,
  DaysLabel,
  FieldBox,
  InputBox,
  Content
} from './style'
import { Fragment, useCallback, useState } from 'react'
import { useAppContext } from 'App'
import { useHistory } from 'react-router-dom'
import useGetData from 'core/hooks/useGetData'
import getData from 'core/functions/get-data.function'
import postData from 'core/functions/post-data.function'
import Frame from 'components/Frame'
import { useCampaign } from '../Details/context'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

const EmailTypeMap = {
  subject: {
    solicitation: 'NpsRequestEmailSubject',
    greetings: 'GratefulEmaiSubject',
    remember: 'RememberEmailSubject'
  },
  form: {
    solicitation: 'NpsRequestEmailIdTemplate',
    greetings: 'GratefulEmailIdTemplate',
    remember: 'RememberEmailIdTemplate'
  }
}

function EmailForm({ type, onChange, next, t }) {
  const history = useHistory()
  const { campaign, email, boxListItem, handleSetBoxListItem } = useCampaign()
  const { notify } = useAppContext()

  const handleEditEmailTemplate = () => {
    history.push(
      '/admin/template/' +
        template.IdYourviews +
        '?fromWizard=true&emailType=' +
        type
    )
  }

  const [loading, setLoading] = useState(false)

  const [HTML, setHTML] = useState(null)

  const getPreview = useCallback(
    async (data) => {
      if (!data?.IdYourviews) return

      const template = await axios.get(
        'template/get?idtemplate=' + data?.IdYourviews
      )

      axios
        .post('template/previewtemplate', template?.data)
        .then((res) => {
          setHTML(res.data)
        })
        .catch((error) => {
          notify(error.message, 'error')
        })
    },
    [notify]
  )

  const [selectedTemplateId, setSelectedTemplateId] = useState(
    email[EmailTypeMap?.form[type]]?.toString() ||
      campaign?.EmailConfiguration[EmailTypeMap?.form[type]]?.toString()
  )
  const handleSelectedTemplateId = useCallback((value) => {
    setSelectedTemplateId(value?.toString())
  }, [])

  const [template, setTemplate] = useState(null)
  const handleSetTemplateData = useCallback(
    (selected) => {
      if (selected && selected !== template) {
        setTemplate(selected)
        getPreview(selected)
        handleSetBoxListItem(selected, type)
        handleSelectedTemplateId(selected?.IdYourviews)
      }
    },
    [getPreview, handleSelectedTemplateId, handleSetBoxListItem, type, template]
  )

  const ReqType = useMemo(() => {
    return {
      solicitation: 'NpsRequestEmail',
      greetings: 'NpsGratefulEmail',
      remember: 'NpsRememberEmail'
    }[type]
  }, [type])

  const ReqTypeParam = useMemo(() => {
    if (campaign.Type === NPS_CSAT_TAB.nps.typeValue) {
      return {
        solicitation: 'NpsRequestEmail',
        greetings: 'NpsGratefulEmail',
        remember: 'NpsRememberEmail'
      }[type]
    }
    if (campaign.Type === NPS_CSAT_TAB.csat.typeValue) {
      return {
        solicitation: 'CsatRequestEmail',
        greetings: 'CsatGratefulEmail',
        remember: 'CsatRememberEmail'
      }[type]
    }
  }, [campaign.Type, type])

  const [templates, setTemplates] = useState(null)
  useGetData({
    endpoint: 'template/getbytype?Type=' + ReqTypeParam,
    setData: setTemplates,
    setLoading: setLoading
  })
  const filteredTemplates = useMemo(() => {
    return templates || []
  }, [templates])

  const handleDuplicate = useCallback((selectedFormId, name) => {
    postData({
      endpoint:
        '/Template/CloneTemplate?IdTemplate=' +
        selectedFormId +
        '&CloneName=Cópia de ' +
        name,
      data: {},
      setData: async (data) => {
        await getData(
          'template/getbytype?Type=' + ReqType,
          setTemplates,
          setLoading,
          notify
        )

        await handleSetTemplateData({ ...data, IdYourviews: data.Id })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const newTemplate = () => {
    let _type
    if (campaign.Type === NPS_CSAT_TAB.csat.typeValue) {
      _type = {
        solicitation: 7,
        greetings: 8,
        remember: 9
      }[type]
    } else {
      _type = {
        solicitation: 0,
        greetings: 1,
        remember: 2
      }[type]
    }

    axios
      .post(
        'template/createtemplate?type=' +
          _type +
          '&templateName=' +
          t('emailType.' + _type)
      )
      .then((res) => {
        history.push(
          `/admin/template/${res.data.Id}?fromWizard=true&emailType=${type}`,
          res.data
        )
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
  }

  const TitleMap = {
    solicitation: campaign.Type === 1 ? t('emailType.7') : t('emailType.0'),
    greetings: campaign.Type === 1 ? t('emailType.8') : t('emailType.1'),
    remember: campaign.Type === 1 ? t('emailType.9') : t('emailType.2')
  }

  const [waitFor, setWaitFor] = useState(
    campaign?.EmailConfiguration?.RememberEmailDaysInterval ?? '15'
  )
  const handleSetWaitFor = useCallback(({ target: { value } }) => {
    if (!isNaN(value)) {
      setWaitFor(parseInt(value))
    }
  }, [])
  const handleOnBlurWaitFor = useCallback((e) => {
    if (e.target.value <= 0) {
      setWaitFor(1)
    }
  }, [])

  const [subject, setSubject] = useState(
    campaign?.EmailConfiguration[EmailTypeMap?.subject[type]] ?? ''
  )
  const handleSubjectChange = useCallback(({ target: { value } }) => {
    setSubject(value)
  }, [])

  useEffect(() => {
    onChange({
      [type]: {
        subject,
        template,
        waitFor
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subject, template, waitFor])

  const disableNext = useMemo(
    () => !subject || !subject.length || !boxListItem[type],
    [boxListItem, type, subject]
  )

  return (
    <Container>
      <Content>
        <Column border padding="0px 48px" width="30%">
          <Title marginTop="48px" bold>
            {TitleMap[type]}
          </Title>

          <TextField
            data-testid="email-subject-input"
            fullWidth
            required
            size="small"
            value={subject}
            variant="outlined"
            label="Assunto do Email"
            onChange={handleSubjectChange}
            style={{ maxWidth: 400, margin: '24px 0px' }}
          />

          <BoxList
            name="template"
            loading={loading}
            onStart={setTemplate}
            onCreate={newTemplate}
            items={filteredTemplates}
            onSelect={handleSetTemplateData}
            selectedAfterUpdate={selectedTemplateId?.toString()}
            handleSetBoxListCurrentItem={(data) =>
              handleSetBoxListItem(data, type)
            }
          />

          {type === 'remember' && (
            <FieldBox>
              <DaysLabel>{t('campaign.fields.email.howMuchTime')}</DaysLabel>

              <InputBox>
                <TextField
                  type="number"
                  defaultValue={
                    campaign?.EmailConfiguration?.RememberEmailDaysInterval
                  }
                  value={waitFor}
                  onChange={handleSetWaitFor}
                  onBlur={handleOnBlurWaitFor}
                  inputProps={{ min: 1 }}
                  style={{ maxWidth: 56, marginTop: 12 }}
                />
                <span
                  style={{
                    marginBottom: '-16px',
                    marginLeft: '16px'
                  }}
                >
                  {t('campaing.edit.form.email.resendDesc')}
                </span>
              </InputBox>
            </FieldBox>
          )}

          <Actions style={{ marginBottom: 30 }}>
            <Button
              data-testid="confirm-email-button"
              disabled={disableNext}
              color="primary"
              variant="contained"
              onClick={next}
              size="large"
            >
              {t('campaing.edit.form.email.advance')}
            </Button>
          </Actions>
        </Column>

        <Column width="70%">
          <Row>
            <LeftActions>
              <Title marginLeft="32px" size="21">
                {template?.Name}
              </Title>
            </LeftActions>

            <RightActions>
              <IconButton
                disabled={!template}
                onClick={handleEditEmailTemplate}
                data-testid="wizard-edit-email-button"
              >
                <Icon path={mdiPencil} size={1} />
              </IconButton>

              <IconButton
                disabled={!template}
                data-testid="wizard-edit-duplicate-button"
                onClick={() =>
                  handleDuplicate(
                    boxListItem[type].IdYourviews,
                    boxListItem[type].Name
                  )
                }
              >
                <Icon path={mdiContentCopy} size={1} title="Titulo do Icone" />
              </IconButton>
            </RightActions>
          </Row>

          <InsideContent noScroll direction="column">
            {HTML && (
              <Fragment>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeihgt: '500px',
                    backgroundColor: '#fff'
                  }}
                >
                  <Frame height="740px" title="template">
                    <div dangerouslySetInnerHTML={{ __html: HTML }}></div>
                  </Frame>
                </div>
              </Fragment>
            )}
          </InsideContent>
        </Column>
      </Content>
    </Container>
  )
}

export default withTranslation()(EmailForm)
