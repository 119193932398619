import Muibadge from '@material-ui/core/Badge'
import { withTranslation } from 'react-i18next'
import useStyles from './style'

const CustomBadge = ({ content, color }) => {
  const classes = useStyles()

  return (
    <Muibadge
      classes={{
        badge: classes.badgeValue
      }}
      className={classes.badge}
      badgeContent={content}
      color={color}
    ></Muibadge>
  )
}

export default withTranslation()(CustomBadge)
