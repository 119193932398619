import DemonstrationTag from 'components/DemonstrationTag'
import PreviewNpsWidget from 'modules/Campaign/components/PreviewNpsWidget'
import CloseIcon from '@material-ui/icons/Close'
import { withTranslation } from 'react-i18next'
import { SafeArea, Text } from './style'

function WidgetNps({ title, color, t }) {
  return (
    <SafeArea
      style={{
        position: 'fixed',
        bottom: '0px',
        width: '100%',
        height: '145px',
        overflow: 'hidden',
        backgroundColor: '#fff'
      }}
      center
      box
    >
      <DemonstrationTag />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '16px'
        }}
      >
        <Text>{title || t('campaing.edit.form.widget.titleDesc')}</Text>
      </div>
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          margin: '0 auto',
          marginTop: '15px'
        }}
      >
        <Text style={{ lineHeight: '45px', marginRight: '15px' }}>
          {t('campaing.edit.form.newWidget.unlikely')}
        </Text>
        <PreviewNpsWidget color={color} />
        <Text style={{ lineHeight: '45px', marginLeft: '15px' }}>
          {t('campaing.edit.form.newWidget.veryLikely')}
        </Text>
      </div>
      <CloseIcon
        style={{
          position: 'absolute',
          right: '10px',
          fontSize: '23px',
          bottom: '110px'
        }}
      />
    </SafeArea>
  )
}
export default withTranslation()(WidgetNps)
