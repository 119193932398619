import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chart from 'react-google-charts'

export const DimensionValueChart = ({ t, data }) => {
  return (
    <Grid item xs>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Chart
          width={'300px'}
          height={'300px'}
          chartType="PieChart"
          loader={<div>{t('dimension-tree.loadingChart')}</div>}
          data={data}
          options={{
            legend: 'none',
            pieSliceText: 'none',
            pieHole: 0.7
          }}
          rootProps={{ 'data-testid': '3' }}
        />
      </Box>
    </Grid>
  )
}
