import CopyToClipboard from 'components/CopyToClipboard'

import { withTranslation } from 'react-i18next'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'

function RenderSyntax({
  Tema,
  positionIcon,
  floatIcon,
  marginBottomIcon,
  rightIcon,
  tooltipPosition,
  Text
}) {
  let tema
  if (Tema === 1) {
    tema = atomDark
  } else if (Tema === 2) {
    tema = vscDarkPlus
  }

  return (
    <>
      <CopyToClipboard
        position={positionIcon}
        float={floatIcon}
        marginBottom={marginBottomIcon}
        color={'white'}
        right={rightIcon}
        paddingTop={'10px'}
        tooltipPosition={tooltipPosition}
        text={Text}
        icone={true}
      />
      <SyntaxHighlighter style={tema} language="javascript">
        {Text}
      </SyntaxHighlighter>
    </>
  )
}
export default withTranslation()(RenderSyntax)
