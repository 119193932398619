import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { ResponsivePie } from '@nivo/pie'
import { Container, Layer1, Layer2 } from './style'
import ScoreTooltipItem from 'modules/Reports/Nps/components/ScoreTooltipItem'
import { useCallback, useMemo } from 'react'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import i18n from 'lang'
import EvolutionPercentage from 'components/EvolutionPercentage'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

const ScoreCircle = ({
  decimal,
  promoterCount,
  neutralCount,
  detractorCount,
  promoterPercentage,
  neutralPercentage,
  detractorPercentage,
  size, //"1", "2", "3"
  value,
  title,
  evolution,
  tooltip = false,
  previousPeriodStart,
  previousPeriodEnd
}) => {
  const total = useMemo(() => {
    return promoterCount + neutralCount + detractorCount
  }, [detractorCount, neutralCount, promoterCount])

  const data = useMemo(() => {
    return total
      ? [
          { id: 'promoter', value: promoterCount },
          { id: 'neutral', value: neutralCount },
          { id: 'detractor', value: detractorCount }
        ]
      : [{ id: 'empty', value: 100 }]
  }, [detractorCount, neutralCount, promoterCount, total])

  const formatDecimals = useCallback(
    (value, total) => {
      return intlFloatPoint(
        ((value * 100) / total).toFixed(decimal),
        i18n.language
      )
    },
    [decimal]
  )

  const percentages = useMemo(() => {
    return {
      promoter: total ? formatDecimals(promoterCount, total) : 0,
      neutral: total ? formatDecimals(neutralCount, total) : 0,
      detractor: total ? formatDecimals(detractorCount, total) : 0
    }
  }, [detractorCount, formatDecimals, neutralCount, promoterCount, total])

  return (
    <HtmlTooltip
      arrow
      placement="left"
      title={
        tooltip ? (
          <>
            <ScoreTooltipItem
              npsType="promoter"
              percentage={
                intlFloatPoint(promoterPercentage, i18n.language) ||
                percentages.promoter
              }
              value={promoterCount}
              data-testid="score-circle-tooltip-item-promoter"
            />
            <ScoreTooltipItem
              npsType="neutral"
              percentage={
                intlFloatPoint(neutralPercentage, i18n.language) ||
                percentages.neutral
              }
              value={neutralCount}
              data-testid="score-circle-tooltip-item-neutral"
            />
            <ScoreTooltipItem
              npsType="detractor"
              percentage={
                intlFloatPoint(detractorPercentage, i18n.language) ||
                percentages.detractor
              }
              value={detractorCount}
              data-testid="score-circle-tooltip-item-detractor"
            />
          </>
        ) : (
          ''
        )
      }
      data-testid="score-circle-tooltip"
    >
      <Container size={size}>
        <Layer1 size={size}>
          <ResponsivePie
            data={data}
            margin={
              size !== 1 ? { top: 10, right: 10, bottom: 10, left: 10 } : {}
            }
            innerRadius={0.8}
            activeOuterRadiusOffset={8}
            colors={
              total
                ? [
                    'hsla(107, 46%, 50%, 1)', // promoter
                    'hsla(37, 93%, 55%, 1)', // neutral
                    'hsla(2, 85%, 58%, 1)' // detractor
                  ]
                : ['#ECEEF0'] // empty
            }
            enableArcLinkLabels={false}
            enableArcLabels={false}
            isInteractive={false}
            data-testid="score-circle-responsive-pie-chart"
          />
        </Layer1>

        <Layer2
          data-testid="score-circle-value-inside-pie"
          decimal={decimal}
          size={size}
        >
          {title && <div style={{ fontSize: 14, marginTop: 10 }}>{title}</div>}
          <div>
            {total
              ? intlFloatPoint((value || 0).toFixed(decimal), i18n.language)
              : '-'}
          </div>
          {typeof evolution !== 'undefined' && (
            <EvolutionPercentage
              evolution={intlFloatPoint(
                evolution.toFixed(decimal),
                i18n.language
              )}
              previousPeriodStart={previousPeriodStart}
              previousPeriodEnd={previousPeriodEnd}
            />
          )}
        </Layer2>
      </Container>
    </HtmlTooltip>
  )
}

export default ScoreCircle
