import { useRef } from 'react'
import { withTranslation } from 'react-i18next'
import { MdClose } from 'react-icons/md'

import Button from '@material-ui/core/Button'

import '../../style/component/modal/main.css'

const Modal = ({ onClose, show, className, children }) => {
  const sectionBody = useRef(null)

  const modalClick = (e) => {
    if (
      e.target !== sectionBody.current &&
      !sectionBody.current.contains(e.target)
    )
      onClose()
  }

  return (
    show && (
      <div
        className={`
        modal ${className ? className : ''}
      `}
      >
        <div className="modal-section-parent" onClick={(e) => modalClick(e)}>
          <div ref={sectionBody} className="modal-section">
            <div className="modal-header">
              <Button
                className="modal-close-btn"
                type="button"
                onClick={() => onClose()}
              >
                <MdClose />
              </Button>
            </div>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </div>
    )
  )
}

export default withTranslation()(Modal)
