import { useCallback, useMemo, useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { withTranslation } from 'react-i18next'
import { BASE_PATH } from 'router'
import {
  Container,
  Title,
  Box,
  ListItemInfo,
  InfoLabel,
  InfoText,
  Steps,
  Step,
  Position,
  StepTitle,
  OptionalLabel
} from './style'
import {
  ButtonLabel,
  HeaderTitle,
  LeftActions,
  RightActions,
  Row
} from '../Form/style'
import { useStyles, ListItem, Block, Subtitle } from '../Details/style'
import postData from 'core/functions/post-data.function'
import EmailForm from './form'
import { getQSValue } from 'core/helpers/functions/queryString'
import { useLocation } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Divider,
  Fab,
  IconButton,
  Switch,
  TextField,
  Tooltip
} from '@material-ui/core'
import { NavigateBefore, NavigateNext, Edit } from '@material-ui/icons'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogActions from '@material-ui/core/DialogActions'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import SendIcon from '@material-ui/icons/Send'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useCampaign } from '../Details/context'
import { useAppContext } from 'App'
import { FormControlLabel } from '@material-ui/core'
import DispatchEmail from '../DispatchEmail'
import BigButton from 'components/BigButton'
import Add from '@material-ui/icons/Add'
import InfoOutlined from '@material-ui/icons/InfoOutlined'
import DialogDispatch from '../Dispatch/components/DialogDispatch'
import LoadingOverlay from 'components/LoadingOverlay'
import queryString from 'query-string'

const EmailTypeMap = {
  subject: {
    solicitation: 'NpsRequestEmailSubject',
    greetings: 'GratefulEmaiSubject',
    remember: 'RememberEmailSubject'
  },
  form: {
    solicitation: 'NpsRequestEmailIdTemplate',
    greetings: 'GratefulEmailIdTemplate',
    remember: 'RememberEmailSubject'
  }
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

const DialogTitle = withStyles(styles)(
  ({ children, classes, onClose, ...other }) => {
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    )
  }
)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions)

function Email({ review, t }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const {
    campaign,
    email: emailState,
    handleSetEmail,
    emailBackup,
    handleSetEmailBackup,
    dispatch,
    boxListItem,
    emailListObj,
    emailListArray
  } = useCampaign()

  const ShowEmailFormOptions = {
    0: 'solicitation',
    1: 'greetings',
    3: 'remember'
  }

  const [hasErrorOnTitle, setHasErrorOnTitle] = useState(false)

  const handleNext = () => {
    if (!campaign?.EmailConfiguration?.SenderName && !showEmailForm) {
      setHasErrorOnTitle(true)

      return
    }

    if (showEmailForm) {
      handleSetShowEmailForm(null)
    } else if (review) {
      handleSetEmailBackup()
      const newSearchObj = queryString.parse(location.search)
      delete newSearchObj.fromTemplateEdit
      history.push({
        pathname: BASE_PATH + 'campaign/details',
        search: queryString.stringify(newSearchObj)
      })
    } else {
      const newSearchObj = queryString.parse(location.search)
      delete newSearchObj.fromTemplateEdit
      history.push({
        pathname: BASE_PATH + 'campaign/import',
        search: queryString.stringify(newSearchObj)
      })
    }
  }

  useEffect(() => {
    const isFromTemplateEdit = getQSValue('fromTemplateEdit')
    const emailType = getQSValue('emailType')

    const type = {
      solicitation: 0,
      greetings: 1,
      remember: 3
    }[emailType]

    if (isFromTemplateEdit) {
      handleSetShowEmailForm(ShowEmailFormOptions[type])

      if (location.state && location.state.id) {
        switch (ShowEmailFormOptions[type]) {
          case ShowEmailFormOptions[0]:
            handleSetEmail({
              ...emailState,
              NpsRequestEmailIdTemplate: location.state.id,
              NpsRequestEmailNameTemplate: location.state.name
            })
            break
          case ShowEmailFormOptions[1]:
            handleSetEmail({
              ...emailState,
              GratefulEmailIdTemplate: location.state.id,
              GratefulEmailNameTemplate: location.state.name
            })
            break
          case ShowEmailFormOptions[3]:
            handleSetEmail({
              ...emailState,
              RememberEmailIdTemplate: location.state.id,
              RememberEmailNameTemplate: location.state.name
            })
            break
          default:
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const [emailFormData, setEmailFormData] = useState({
    solicitation: null,
    greetings: null,
    remember: null
  })
  const handleSetEmailFormData = (value) => {
    setEmailFormData(value)

    switch (showEmailForm) {
      case ShowEmailFormOptions[0]:
        handleSetEmail({
          ...emailState,

          NpsRequestEmailSubject: value?.solicitation?.subject,
          NpsRequestEmailIdTemplate:
            value?.solicitation?.template?.IdYourviews ||
            campaign.EmailConfiguration.NpsRequestEmailIdTemplate,
          NpsRequestEmailNameTemplate:
            value?.solicitation?.template?.Name ||
            campaign?.EmailConfiguration?.NpsRequestEmailNameTemplate
        })
        break
      case ShowEmailFormOptions[1]:
        handleSetEmail({
          ...emailState,

          GratefulEmaiSubject: value?.greetings?.subject,
          GratefulEmailIdTemplate:
            value?.greetings?.template?.IdYourviews ||
            campaign?.EmailConfiguration?.GratefulEmailIdTemplate,
          GratefulEmailNameTemplate:
            value?.greetings?.template?.Name ||
            campaign?.EmailConfiguration?.GratefulEmailNameTemplate
        })
        break
      case ShowEmailFormOptions[3]:
        handleSetEmail({
          ...emailState,

          RememberEmailSubject: value?.remember?.subject,
          RememberEmailIdTemplate:
            value?.remember?.template?.IdYourviews ||
            campaign?.EmailConfiguration?.RememberEmailIdTemplate,
          RememberEmailNameTemplate:
            value?.remember?.template?.Name ||
            campaign?.EmailConfiguration?.RememberEmailNameTemplate,
          RememberEmailDaysInterval:
            value?.remember?.waitFor ??
            campaign?.EmailConfiguration?.RememberEmailDaysInterval
        })
        break
      default:
        break
    }
  }

  const handleChangeSender = useCallback(
    (event) => {
      setHasErrorOnTitle(false)
      handleSetEmail({ ...emailState, SenderName: event.target.value })
    },
    [emailState, handleSetEmail]
  )

  const [email, setEmail] = useState('')
  const handleEmailChange = useCallback((value) => {
    setEmail(value)
  }, [])

  const [name, setName] = useState('')
  const handleNameChange = useCallback((value) => {
    setName(value)
  }, [])

  const [open, setOpen] = useState(false)
  const [type, setType] = useState(null)
  const openSendTestDialog = (type) => {
    if (!campaign?.EmailConfiguration?.SenderName) {
      setHasErrorOnTitle(true)
      return
    }
    setOpen(true)
    setType(type)
  }
  const closeSendTestDialog = () => {
    setOpen(false)
    setType(null)
    setEmail('')
    setName('')
  }

  const [showEmailFormBackup, setShowEmailFormBackup] = useState()
  const [showEmailForm, setShowEmailForm] = useState(null)
  const handleSetShowEmailForm = useCallback(
    (value) => {
      if (value) {
        setShowEmailForm(value)
        setShowEmailFormBackup({ ...emailState })
      } else {
        setShowEmailForm(false)
        setShowEmailFormBackup()
      }
    },
    [emailState]
  )

  const { notify } = useAppContext()

  const [emailTestLoading, setEmailTestLoading] = useState(false)
  const sendEmailTest = () => {
    postData({
      endpoint: 'email/sendtestemail',
      data: {
        EmailReceiver: {
          Name: name,
          Email: email
        },
        Campaign: { ...campaign, Active: true },
        DispatchInfoType: type
      },
      setData: ({ HasErrors }) => {
        if (HasErrors) {
          return notify('Ocorreu um erro, tente novamente! ', 'error')
        } else {
          closeSendTestDialog()
          return notify('Teste de email enviado com sucesso!', 'success')
        }
      },
      setLoading: setEmailTestLoading,
      notify: () => {
        return notify('Ocorreu um erro, tente novamente! ', 'error')
      }
    })
  }

  const handleChangeEmailStatus = (value, type) => {
    switch (type) {
      case 'remember':
        return handleSetEmail({ ...emailState, RememberEmailActive: value })
      case 'greetings':
        return handleSetEmail({ ...emailState, GratefulEmailActive: value })
      default:
        return
    }
  }

  const [hasReview, setHasReview] = useState(null)

  useEffect(() => {
    let url = window.location.href
    if (String(url).includes('review')) {
      setHasReview(true)
    } else {
      setHasReview(false)
    }

    if (!emailBackup) {
      handleSetEmailBackup({ ...emailState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const dispatchCancel = () => {
    alert('cancelou')
  }
  const dispatchExpand = () => {
    alert('expandiu')
  }

  const { pathname } = useLocation()
  const [isReview, setIsReview] = useState(
    pathname === '/admin/campaign/review'
  )

  useEffect(() => {
    setIsReview(pathname === '/admin/campaign/review')
  }, [pathname])

  useEffect(() => {
    if (!campaign?.EmailConfiguration?.NpsRequestEmailSubject) {
      handleSetShowEmailForm(ShowEmailFormOptions[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const disableNext = useMemo(() => {
    if (showEmailForm) {
      return (
        !boxListItem ||
        !boxListItem[showEmailForm] ||
        !emailState ||
        !emailState[EmailTypeMap.subject[showEmailForm]]
      )
    }

    return !emailState[EmailTypeMap.subject.solicitation]
  }, [boxListItem, emailState, showEmailForm])

  const [openAdd, setOpenAdd] = useState(false)
  const handleOpen = () => setOpenAdd(true)
  const handleClose = () => setOpenAdd(false)

  const [showBackToDetailsModal, setShowBackToDetailsModal] = useState(false)

  const backToDetails = useCallback(() => {
    setShowBackToDetailsModal(false)
    handleSetEmail({ ...emailBackup })
    handleSetEmailBackup()
    const newSearchObj = queryString.parse(location.search)
    delete newSearchObj.fromTemplateEdit
    history.push({
      pathname: BASE_PATH + 'campaign/details',
      search: queryString.stringify(newSearchObj)
    })
  }, [
    emailBackup,
    handleSetEmail,
    handleSetEmailBackup,
    history,
    location.search
  ])

  const handlePrevious = useCallback(() => {
    if (showEmailForm) {
      if (
        !showEmailFormBackup.NpsRequestEmailSubject ||
        !showEmailFormBackup.NpsRequestEmailIdTemplate
      ) {
        setShowBackToDetailsModal(true)
      } else {
        handleSetShowEmailForm(null)
        handleSetEmail({ ...showEmailFormBackup })
      }
    } else if (review) {
      const newSearchObj = queryString.parse(location.search)
      delete newSearchObj.fromTemplateEdit
      history.push({
        pathname: BASE_PATH + 'campaign/dispatch',
        search: queryString.stringify(newSearchObj)
      })
    } else {
      setShowBackToDetailsModal(true)
    }
  }, [
    handleSetEmail,
    handleSetShowEmailForm,
    history,
    location.search,
    review,
    showEmailForm,
    showEmailFormBackup
  ])

  const rememberEmailDisabled = useMemo(() => {
    return (
      !campaign?.EmailConfiguration?.RememberEmailSubject ||
      !campaign?.EmailConfiguration?.RememberEmailNameTemplate
    )
  }, [
    campaign?.EmailConfiguration?.RememberEmailNameTemplate,
    campaign?.EmailConfiguration?.RememberEmailSubject
  ])

  const gratefulEmailDisabled = useMemo(() => {
    return (
      !campaign?.EmailConfiguration?.GratefulEmaiSubject ||
      !campaign?.EmailConfiguration?.GratefulEmailNameTemplate
    )
  }, [
    campaign?.EmailConfiguration?.GratefulEmaiSubject,
    campaign?.EmailConfiguration?.GratefulEmailNameTemplate
  ])

  const disableSwitchTooltip = useMemo(() => {
    return (
      <Tooltip
        placement="right"
        title="Clique em editar e configure o e-mail para poder ativar"
      >
        <div
          style={{
            alignSelf: 'center',
            marginRight: 20
          }}
        >
          <InfoOutlined style={{ fill: 'rgba(0, 0, 0, 0.38)' }} />
        </div>
      </Tooltip>
    )
  }, [])

  return (
    <Container>
      <Dialog maxWidth="sm" fullWidth open={showBackToDetailsModal}>
        <DialogTitle id="confirmation-dialog-title">
          {t('campaign.fields.email.backDialogTitle')}
          <CloseIcon
            onClick={() => setShowBackToDetailsModal(false)}
            style={{
              cursor: 'pointer',
              position: 'absolute',
              right: '20px',
              fontSize: '23px',
              top: '20px'
            }}
          />
        </DialogTitle>

        <DialogContent style={{ paddingBottom: 35 }}>
          {t('campaign.fields.email.backDialogContent')}
        </DialogContent>

        <Divider />

        <DialogActions>
          <Button
            autoFocus
            onClick={() => setShowBackToDetailsModal(false)}
            color="primary"
          >
            {t('campaign.fields.email.backDialogCancel')}
          </Button>
          <Button variant="contained" onClick={backToDetails} color="primary">
            {t('campaign.fields.email.backDialogConfirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Row center noBorder>
        <HeaderTitle>{t('campaign.fields.email.configEmailtitle')}</HeaderTitle>
      </Row>

      <Row>
        <LeftActions>
          <IconButton
            data-testid="wizard-back-button-icon"
            onClick={handlePrevious}
          >
            <NavigateBefore color="primary" />
          </IconButton>

          <ButtonLabel
            onClick={handlePrevious}
            data-testid="wizard-back-button"
          >
            {t('person.detail.backBtn')}
          </ButtonLabel>
        </LeftActions>

        <Steps>
          <Step>
            <Position active>1</Position>
            <StepTitle>{t('campaign.fields.email.stepTitlesModel')}</StepTitle>
          </Step>
          <Step>
            <Position active={isReview}>2</Position>
            <StepTitle>{t('campaign.fields.email.stepTitlesList')}</StepTitle>
          </Step>
          <Step>
            <Position active={isReview}>3</Position>
            <StepTitle>{t('campaign.fields.email.stepTitlesSend')}</StepTitle>
          </Step>
          <Step>
            <Position active={isReview}>4</Position>
            <StepTitle>
              {t('campaign.fields.email.stepTitlesRevision')}
            </StepTitle>
          </Step>
        </Steps>

        <RightActions>
          <ButtonLabel
            disabled={disableNext}
            data-testid="wizard-next-button"
            onClick={handleNext}
          >
            {review ? 'Salvar e avançar' : 'Avançar'}
          </ButtonLabel>

          <IconButton
            disabled={disableNext}
            data-testid="wizard-next-button-icon"
            onClick={handleNext}
          >
            <NavigateNext color={disableNext ? 'disabled' : 'primary'} />
          </IconButton>
        </RightActions>
      </Row>

      <Box center>
        {!showEmailForm && campaign.EmailConfiguration ? (
          <Box width="100%" maxWidth="1024px">
            <Title marginTop="24px" marginBottom="24px" bold>
              {t('campaign.fields.email.titlePageEmail')}
            </Title>

            <Box margin="12px 0" maxWidth="600px">
              <TextField
                required
                fullWidth
                size="small"
                error={hasErrorOnTitle}
                label={t('campaign.fields.email.from')}
                defaultValue={campaign.EmailConfiguration.SenderName}
                onChange={handleChangeSender}
                variant="outlined"
                id="campaign-name"
                data-testid="campaign-name"
                helperText={
                  hasErrorOnTitle
                    ? t('campaign.fields.email.inputRequired')
                    : ''
                }
              />
            </Box>

            <Box>
              <ListItem col borderRadius="4px 4px 0px 0px" borderBottomNone>
                <Box row>
                  <Block>
                    <Fab
                      disableRipple
                      disableFocusRipple
                      classes={{ root: classes.fab }}
                      style={{
                        background: campaign?.EmailConfiguration
                          ?.NpsRequestEmailSubject
                          ? '#00aeef'
                          : 'rgba(0, 0, 0, 0.05)'
                      }}
                    >
                      <SendIcon
                        style={{
                          fill: campaign?.EmailConfiguration
                            ?.NpsRequestEmailSubject
                            ? 'white'
                            : 'rgba(0, 0, 0, 0.54)'
                        }}
                      />
                    </Fab>

                    <Block padding="0px 16px" column>
                      <Title size="18">
                        {t('campaign.fields.email.titleRequestEmail')} *
                      </Title>

                      <Subtitle>
                        {t('campaign.fields.email.requestPeopleSearch')}
                      </Subtitle>
                    </Block>
                  </Block>

                  <Block>
                    <IconButton
                      color="primary"
                      data-testid="wizard-edit-solicitation-button"
                      onClick={() =>
                        handleSetShowEmailForm(ShowEmailFormOptions[0])
                      }
                      aria-label="edit solicitation email"
                    >
                      <Edit />
                    </IconButton>
                  </Block>
                </Box>

                {campaign?.EmailConfiguration?.NpsRequestEmailSubject && (
                  <Box>
                    <ListItemInfo>
                      <Box row width="80%">
                        <Box
                          justifyContent="center"
                          maxWidth="180px"
                          minWidth="180px"
                        >
                          <InfoLabel>
                            {t('campaign.fields.email.subjectEmail')}
                          </InfoLabel>
                          <InfoText>
                            {
                              campaign?.EmailConfiguration
                                ?.NpsRequestEmailSubject
                            }
                          </InfoText>
                        </Box>

                        <Box justifyContent="center">
                          <InfoLabel>
                            {t('campaign.fields.email.templateEmail')}
                          </InfoLabel>
                          <InfoText>
                            {
                              campaign?.EmailConfiguration
                                ?.NpsRequestEmailNameTemplate
                            }
                          </InfoText>
                        </Box>
                      </Box>

                      <Box justifyContent="center" center width="20%">
                        <Button
                          onClick={() => openSendTestDialog(0)}
                          color="primary"
                        >
                          {t('campaign.details.sendEmailTest')}
                        </Button>
                      </Box>
                    </ListItemInfo>
                  </Box>
                )}
              </ListItem>

              <ListItem col borderRadius="0px" borderBottomNone>
                <Box row>
                  <Block>
                    <Fab
                      disableFocusRipple
                      disableRipple
                      classes={{ root: classes.fab }}
                      style={{
                        background: emailState?.RememberEmailActive
                          ? '#00aeef'
                          : 'rgba(0, 0, 0, 0.05)'
                      }}
                    >
                      <ThumbUpAltIcon
                        style={{
                          fill: emailState?.RememberEmailActive
                            ? 'white'
                            : 'rgba(0, 0, 0, 0.54)'
                        }}
                      />
                    </Fab>

                    <Block padding="0px 16px" column>
                      <Title size="18">
                        {t('campaign.fields.email.remiderTitleEmail')}
                        <OptionalLabel>
                          {t('campaign.fields.email.optionalEmail')}
                        </OptionalLabel>
                      </Title>

                      <Subtitle>
                        {t('campaign.fields.email.reminderEmail')}
                      </Subtitle>
                    </Block>
                  </Block>

                  <Block>
                    {rememberEmailDisabled && disableSwitchTooltip}

                    <FormControlLabel
                      control={
                        <Switch
                          disabled={rememberEmailDisabled}
                          size="small"
                          color="primary"
                          checked={emailState?.RememberEmailActive ?? false}
                          onChange={(e) =>
                            handleChangeEmailStatus(
                              e.target.checked,
                              'remember'
                            )
                          }
                        />
                      }
                      label={
                        emailState?.RememberEmailActive ? 'Ativo' : 'Inativo'
                      }
                    />

                    <IconButton
                      color="primary"
                      aria-label="edit remember email"
                      data-testid="wizard-edit-remember-button"
                      onClick={() =>
                        handleSetShowEmailForm(ShowEmailFormOptions[3])
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Block>
                </Box>

                {campaign?.EmailConfiguration?.RememberEmailSubject && (
                  <Box>
                    <ListItemInfo>
                      <Box row width="80%">
                        <Box
                          justifyContent="center"
                          maxWidth="180px"
                          minWidth="180px"
                        >
                          <InfoLabel>Assunto</InfoLabel>

                          <InfoText>
                            {campaign?.EmailConfiguration?.RememberEmailSubject}
                          </InfoText>
                        </Box>

                        <Box
                          justifyContent="center"
                          maxWidth="250px"
                          minWidth="250px"
                        >
                          <InfoLabel>
                            {t('campaign.fields.email.templateEmail')}
                          </InfoLabel>
                          <InfoText>
                            {
                              campaign?.EmailConfiguration
                                ?.RememberEmailNameTemplate
                            }
                          </InfoText>
                        </Box>

                        <Box justifyContent="center">
                          <InfoLabel>
                            {t('campaign.fields.email.sendAfter')}
                          </InfoLabel>
                          <InfoText>
                            {
                              campaign?.EmailConfiguration
                                ?.RememberEmailDaysInterval
                            }
                          </InfoText>
                        </Box>
                      </Box>

                      <Box center width="20%">
                        <Button
                          onClick={() => openSendTestDialog(3)}
                          color="primary"
                        >
                          {t('campaign.details.sendEmailTest')}
                        </Button>
                      </Box>
                    </ListItemInfo>
                  </Box>
                )}
              </ListItem>

              <ListItem col zborderRadius="0px 0px 4px 4px ">
                <Box row>
                  <Block>
                    <Fab
                      disableRipple
                      disableFocusRipple
                      classes={{ root: classes.fab }}
                      style={{
                        background: emailState?.GratefulEmailActive
                          ? '#00aeef'
                          : 'rgba(0, 0, 0, 0.05)'
                      }}
                    >
                      <NotificationsActiveIcon
                        style={{
                          fill: emailState?.GratefulEmailActive
                            ? 'white'
                            : 'rgba(0, 0, 0, 0.54)'
                        }}
                      />
                    </Fab>

                    <Block padding="0px 16px" column>
                      <Title size="18">
                        {t('campaign.fields.email.gratefulTitleEmail')}
                        <OptionalLabel>
                          {t('campaign.fields.email.optionalEmail')}
                        </OptionalLabel>
                      </Title>
                      <Subtitle>
                        {t('campaign.fields.email.gratefulEmail')}
                      </Subtitle>
                    </Block>
                  </Block>

                  <Block>
                    {gratefulEmailDisabled && disableSwitchTooltip}

                    <FormControlLabel
                      control={
                        <Switch
                          disabled={gratefulEmailDisabled}
                          size="small"
                          color="primary"
                          checked={emailState?.GratefulEmailActive ?? false}
                          onChange={(e) =>
                            handleChangeEmailStatus(
                              e.target.checked,
                              'greetings'
                            )
                          }
                        />
                      }
                      label={
                        emailState?.GratefulEmailActive ? 'Ativo' : 'Inativo'
                      }
                    />

                    <IconButton
                      color="primary"
                      data-testid="wizard-edit-grettings-button"
                      aria-label="edit greetings email"
                      onClick={() =>
                        handleSetShowEmailForm(ShowEmailFormOptions[1])
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Block>
                </Box>

                {campaign?.EmailConfiguration?.GratefulEmaiSubject && (
                  <Box>
                    <ListItemInfo>
                      <Box row width="80%">
                        <Box
                          justifyContent="center"
                          maxWidth="180px"
                          minWidth="180px"
                        >
                          <InfoLabel>
                            {t('campaign.fields.email.subjectEmail')}
                          </InfoLabel>
                          <InfoText>
                            {campaign?.EmailConfiguration?.GratefulEmaiSubject}
                          </InfoText>
                        </Box>

                        <Box justifyContent="center">
                          <InfoLabel>
                            {t('campaign.fields.email.templateEmail')}
                          </InfoLabel>
                          <InfoText>
                            {
                              campaign?.EmailConfiguration
                                ?.GratefulEmailNameTemplate
                            }
                          </InfoText>
                        </Box>
                      </Box>

                      <Box justifyContent="center" center width="20%">
                        <Button
                          onClick={() => openSendTestDialog(1)}
                          color="primary"
                        >
                          {t('campaign.details.sendEmailTest')}
                        </Button>
                      </Box>
                    </ListItemInfo>
                  </Box>
                )}
              </ListItem>
            </Box>

            {hasReview && (
              <>
                <Title marginTop="48px" bold>
                  {t('campaign.fields.email.dispatchEmail')}
                </Title>
                <Container scrollY>
                  {dispatch &&
                    dispatch?.map((item, index) => (
                      <DispatchEmail
                        list={emailListObj[item.IdTargetList]}
                        expand={dispatchExpand}
                        cancel={dispatchCancel}
                        item={item}
                        key={index}
                      />
                    ))}
                </Container>
                <div style={{ width: '270px', margin: 'auto' }}>
                  <BigButton
                    onClick={handleOpen}
                    icon={<Add />}
                    title={t('dispatch.btn')}
                  />
                </div>
              </>
            )}
          </Box>
        ) : (
          <Box>
            <EmailForm
              next={handleNext}
              type={showEmailForm}
              onChange={(data) =>
                handleSetEmailFormData({
                  ...emailFormData,
                  ...data
                })
              }
              data={campaign?.EmailConfiguration}
              handleSetShowEmailForm={handleSetShowEmailForm}
            />
          </Box>
        )}
      </Box>

      <DialogDispatch
        open={openAdd ? openAdd : false}
        emailList={emailListArray}
        handleClose={handleClose}
      />
      <Dialog
        onClose={closeSendTestDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div style={{ position: 'relative' }}>
          <LoadingOverlay isLoading={emailTestLoading} />
          <form
            onSubmit={(e) => {
              e.preventDefault()
              sendEmailTest()
            }}
          >
            <DialogTitle
              id="customized-dialog-title"
              onClose={closeSendTestDialog}
            >
              {t('campaign.fields.email.sendTestEmail')}
            </DialogTitle>

            <DialogContent dividers>
              <div
                style={{
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#000000',
                  marginBottom: 14
                }}
              >
                {t('campaign.fields.email.beforeDispatch')}
              </div>

              <Alert severity="warning">
                <AlertTitle>{t('campaign.fields.email.important')}</AlertTitle>
                {t('campaign.fields.email.formSearch')}
              </Alert>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  margin: '22px 0px'
                }}
              >
                <TextField
                  id="name"
                  fullWidth
                  label="Nome"
                  size="small"
                  value={name}
                  variant="outlined"
                  defaultValue="Small"
                  onChange={(e) => handleNameChange(e.target.value)}
                  style={{
                    marginBottom: 22
                  }}
                  required
                />
                <TextField
                  id="email"
                  fullWidth
                  size="small"
                  value={email}
                  label="E-mail"
                  variant="outlined"
                  defaultValue="Small"
                  onChange={(e) => handleEmailChange(e.target.value)}
                  required
                />
              </div>
            </DialogContent>

            <DialogActions>
              <Button autoFocus onClick={closeSendTestDialog}>
                {t('campaing.edit.form.cancelBtn')}
              </Button>

              <Button type="submit" autoFocus color="primary">
                {t('recovery.button')}
              </Button>
            </DialogActions>
          </form>
        </div>
      </Dialog>
    </Container>
  )
}

export default withTranslation()(Email)
