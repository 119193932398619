import { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  dimension as dimensionHelper,
  array as arrayHelper,
  uuid
} from 'core/helpers/index'
import useRequest from 'core/hooks/useRequest'
import axios from 'core/api'
import CustomFieldEditorItem, {
  GetDefaultStarFormFieldValues
} from '../CustomFieldEditorItem'
import Dialog from '../../../../components/Modal/Dialog'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import { FIELD_TYPE } from 'core/enums/field-type'
import { FIELD_TEMPLATE } from 'core/enums/field-template'
import queryString from 'query-string'
import { QS_TYPES } from 'core/constants/query-strings'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

const CustomIconButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700]
    }
  }
}))(IconButton)

const CustomFieldsEditor = (props) => {
  const [fields, setFields] = useState(props.fields || [])
  const [dimensions, setDimensions] = useState([])
  const [isRemovingField, setIsRemovingField] = useState(-1)
  const [checkCsatFields, setCheckCsatFields] = useState(true)

  const { location } = props
  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  useRequest(
    'dimension/getallplain',
    (data) => setDimensions(dimensionHelper.toDropdownById(data)),
    false,
    false,
    false,
    true
  )

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const newFields = arrayHelper.reorder(
      fields,
      result.source.index,
      result.destination.index
    )

    setFields(newFields)
    props.onChangeAll(newFields)
  }

  const addDimension = (name, fieldIdx, type, Origin) => {
    axios
      .post('dimension/insertupdate', {
        Key: name,
        Name: name,
        Type: type,
        Origin: Origin,
        Values: [],
        Active: true
      })
      .then((res) => {
        if (res.data.HasErrors) throw new Error('Error on creating dimension')

        setDimensions([
          ...dimensions,
          {
            id: res.data.Element.IdYourviews,
            name: res.data.Element.Name,
            type: res.data.Element.Type
          }
        ])

        let newFields = fields

        newFields[fieldIdx].IdDimension = res.data.Element.IdYourviews
        setFields(newFields)
        props.onChange(newFields[fieldIdx], fieldIdx)
      })
      .catch((error) => {
        props.enqueueSnackbar(error.message, 'error')
      })
  }

  useEffect(() => {
    //if csat, add a default field of type rating and type text (stars)
    if (
      currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam &&
      checkCsatFields
    ) {
      const newFields = fields
      if (!fields.length) {
        newFields.push(
          getNewField({
            title: props.t('form.defaultRatingLabel'),
            type: FIELD_TYPE.rating,
            required: true,
            undeletable: true,
            disableTypeField: true,
            disableRequiredField: true,
            Template: FIELD_TEMPLATE.ratingStars
          })
        )
        newFields.push(
          getNewField({
            title: props.t('form.defaultTextLabel'),
            type: FIELD_TYPE.text,
            required: false,
            undeletable: false,
            disableTypeField: false,
            disableRequiredField: false
          })
        )
      } else {
        const ratingField = newFields.find((f) => f.Type === FIELD_TYPE.rating)
        if (ratingField) {
          ratingField.required = true
          ratingField.undeletable = true
          ratingField.disableTypeField = true
          ratingField.disableRequiredField = true
        } else {
          newFields.push(
            getNewField({
              title: props.t('form.defaultRatingLabel'),
              type: FIELD_TYPE.rating,
              required: true,
              undeletable: true,
              disableTypeField: true,
              disableRequiredField: true,
              Template: FIELD_TEMPLATE.ratingStars
            })
          )
        }
      }
      setFields(newFields)
      props.onChangeAll(newFields)
      setCheckCsatFields(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getNewField = ({
    title,
    type,
    required,
    undeletable,
    disableTypeField,
    disableRequiredField,
    Template,
    hierarchy
  }) => {
    return {
      Required: required,
      Title: title || '',
      Id: fields.length,
      OrderKey: uuid.generator(),
      Type: type || FIELD_TYPE.text,
      FormFieldValues: GetDefaultStarFormFieldValues(props.t),
      NpsClassificationDependancy: [],
      FieldClassificationDependancy: [],
      FormFieldDependencies: [],
      OrderIndex: fields.length + 1,
      undeletable: undeletable,
      disableTypeField: disableTypeField,
      disableRequiredField: disableRequiredField,
      Template: Template,
    }
  }

  const addNewField = (
    title,
    type,
    undeletable,
    disableTypeField,
    disableRequiredField
  ) => {
    let newFields = fields
    newFields.push(
      getNewField({
        title,
        type,
        undeletable,
        disableTypeField,
        disableRequiredField
      })
    )
    setFields(newFields)
    props.onChangeAll(newFields)
  }

  const deleteField = (idx) => {
    let newFields = fields
    newFields.splice(idx, 1)
    setFields(newFields)
    props.onChangeAll(newFields)
    setIsRemovingField(-1)
  }

  const [formDimensionsSelected, setFormDimensionsSelected] = useState([])
  const [formFieldsDependencies, setFormFieldsDependencies] = useState([])

  return (
    <div className="custom-fields-editor">
      {fields.length > 0 &&
        fields[0].OrderKey &&
        fields[0].OrderKey.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`
                    droppable-content 
                    ${
                      snapshot.isDraggingOver
                        ? 'droppable-content-dragging'
                        : ''
                    }`}
                >
                  {fields.map((field, fidx) => (
                    <Draggable
                      key={field.OrderKey}
                      draggableId={field.OrderKey}
                      index={fidx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`form-editor-item form-editor-item-custom droppable-item 
                        ${
                          snapshot.isDragging ? 'droppable-item-dragging' : ''
                        }`}
                        >
                          <CustomFieldEditorItem
                            field={field}
                            bindSubmitForm={props.bindSubmitForm}
                            dimensions={dimensions}
                            formFieldsDependenciesHook={{formFieldsDependencies, setFormFieldsDependencies}}
                            formDimencionsSelectedHook={{formDimensionsSelected, setFormDimensionsSelected}}
                            addDimension={(name) =>
                              addDimension(name, fidx, field.Type, 2)
                            }
                            onChange={(values) => props.onChange(values, fidx)}
                            conditionalOptions={props.conditionalOptions}
                            removeField={() => setIsRemovingField(fidx)}
                            location={location}
                            match={props.match}
                            fields={fields}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

      <CustomIconButton
        data-testid="addCustomForm"
        color="primary"
        onClick={() => addNewField()}
      >
        <AddIcon />
      </CustomIconButton>

      <Dialog
        show={isRemovingField > -1}
        onClose={() => setIsRemovingField(-1)}
        onCancel={() => setIsRemovingField(-1)}
        onAccept={() => deleteField(isRemovingField)}
      >
        <h3 className="modal-title">
          {props.t('formEditor.field.deletingTitle')}
        </h3>
        <p className="modal-text">
          {props.t('formEditor.field.deletingQuestion')} <br />
          <strong>{props.t('formEditor.field.deletingWaring')}</strong>
        </p>
      </Dialog>
    </div>
  )
}

export default withTranslation()(CustomFieldsEditor)
