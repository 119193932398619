import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import FlatPagination from 'material-ui-flat-pagination'

import { QS_PAGE } from 'core/constants/query-strings'

import 'style/component/pagination/main.css'

/**
 * Paginador antigo, depreciado e futuramente será removido
 */
const OldPagination = ({ location, history, total, current }) => {
  const setPage = (page) => {
    page = page + 1
    let qsUrl = queryString.parse(location.search)

    if (page === 1) delete qsUrl[QS_PAGE]
    else qsUrl[QS_PAGE] = page

    history.push({
      pathname: location.pathname,
      search: queryString.stringify(qsUrl)
    })
  }

  return (
    <section className="pagination">
      {total > 1 && (
        <FlatPagination
          limit={1}
          offset={current - 1}
          total={total}
          onClick={(_e, offset) => setPage(offset)}
          currentPageColor="inherit"
        />
      )}
    </section>
  )
}

export default withRouter((props) => <OldPagination {...props} />)
