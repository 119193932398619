import { useTranslation } from 'react-i18next'

import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt'

import { green, orange, red } from '@material-ui/core/colors'

import { useStyles } from './style'

const ScoreTooltipItem = ({ npsType, percentage, value }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const NpsMap = {
    promoter: {
      icon: <SentimentSatisfiedAltIcon style={{ color: green[500] }} />,
      color: green[500],
      title: 'dashboard.nps.promoter'
    },
    neutral: {
      icon: <SentimentSatisfiedIcon style={{ color: orange[500] }} />,
      color: orange[500],
      title: 'dashboard.nps.neutral'
    },
    detractor: {
      icon: <SentimentVeryDissatisfiedIcon style={{ color: red[500] }} />,
      color: red[500],
      title: 'dashboard.nps.detractor'
    }
  }

  return (
    <div className={classes.tooltip}>
      {NpsMap[npsType].icon}
      <span
        className={classes.percentage}
        style={{ color: NpsMap[npsType].color }}
      >
        {percentage}%
      </span>
      <span className={classes.values}>
        {value} - {t(NpsMap[npsType].title)}
      </span>
    </div>
  )
}

export default ScoreTooltipItem
