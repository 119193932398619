import { getIn } from 'formik'
import { FormControlLabel, Switch, Tooltip } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  customTooltip: {
    maxWidth: 230,
    textAlign: 'center'
  }
}))

const Checkbox = ({ field, form: { touched, errors }, ...props }) => {
  const classes = useStyles()

  return (
    <FormGroup
      className={`form-field checkbox-field checkbox-single-field
        ${
          !!(getIn(touched, field.name) && getIn(errors, field.name))
            ? ' checkbox-error'
            : ''
        }`}
    >
      {props?.tooltip || props?.disabledtooltip ? (
        <Tooltip
          title={props?.tooltip || props?.disabledtooltip}
          placement="top"
          classes={{ tooltip: classes.customTooltip }}
        >
          <FormControlLabel
            control={
              <Switch {...field} {...props} checked={field.value} color="primary" />
            }
            label={props.label}
          />
        </Tooltip>
      ) : (
        <FormControlLabel
          control={
            <Switch {...field} {...props} checked={field.value} color="primary" />
          }
          label={props.label}
        />
      )}

      {!!(
        field.name &&
        getIn(touched, field.name) &&
        getIn(errors, field.name)
      ) && <FormHelperText>{getIn(errors, field.name)}</FormHelperText>}
    </FormGroup>
  )
}

export default Checkbox
