import { useCallback, useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Container, Line, Text, LineText } from './style'
import queryString from 'query-string'
import Quadrant from '../Matrix/components/Quadrant/index'
import InfoText from '../InfoText'
import { QS_DIMENSION } from 'core/constants/query-strings'
import { useHistory, useLocation } from 'react-router-dom'
import { getSearchWithDimensionId } from '../../functions/get-search-with-dimension-id'
import { Skeleton } from '@material-ui/lab'

const Matrix = ({
  decimal,
  loadingDecimal,
  dimensionsScoreData,
  loadingDimensionsScoreData,
  valueScoreData,
  loadingValueScoreData,
  npsGoal,
  loadingNpsGoal,
  t
}) => {
  const { search, pathname } = useLocation()
  const { push } = useHistory()

  const parsedSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const dimensionKeyOnQuery = useMemo(() => {
    return parsedSearch[QS_DIMENSION]
  }, [parsedSearch])

  const matrixPoints = useMemo(() => {
    const data = []

    const dataToUse = !!dimensionKeyOnQuery
      ? valueScoreData
      : dimensionsScoreData

    dataToUse?.Items?.forEach((item) => {
      data.push({
        x: item.Score,
        y: item.TotalCount,
        label: item.DimensionValue ?? item.Dimension,
        score: item.Score,
        totalCount: item.TotalCount,
        children: item.Dimension ? true : false
      })
    })

    return data
  }, [dimensionKeyOnQuery, dimensionsScoreData, valueScoreData])

  const [quadrantKey, setQuadrantKey] = useState(1)
  useEffect(() => {
    setQuadrantKey(quadrantKey + 1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matrixPoints])

  const handlePointClick = useCallback(
    (dimension) => {
      push({
        pathname: pathname,
        search: getSearchWithDimensionId(
          search,
          dimension,
          dimensionsScoreData?.Items
        )
      })
    },
    [dimensionsScoreData?.Items, pathname, push, search]
  )

  const loading = useMemo(() => {
    return (
      loadingDecimal ||
      loadingDimensionsScoreData ||
      loadingValueScoreData ||
      loadingNpsGoal
    )
  }, [
    loadingDecimal,
    loadingDimensionsScoreData,
    loadingNpsGoal,
    loadingValueScoreData
  ])

  return (
    <>
      <Container>
        <LineText>
          <Text>{t('nps-reports.matrix.zoneTextBad')}</Text>
          <Text end>{t('nps-reports.matrix.zoneTextGood')}</Text>
        </LineText>

        {loading ? (
          <Skeleton variant="rect" height={10} width="34%" />
        ) : (
          <Line />
        )}

        {loading ? (
          <Skeleton
            style={{ marginTop: 3 }}
            variant="rect"
            height={400}
            width="100%"
          />
        ) : (
          <div
            style={{
              width: '100%',
              marginTop: '3px'
            }}
          >
            {dimensionsScoreData?.Items?.length > 0 &&
            matrixPoints &&
            npsGoal ? (
              <Quadrant
                key={quadrantKey}
                decimal={decimal}
                xAxis={{
                  min: -100,
                  max: 100,
                  title: 'NPS',
                  descQnt: 5
                }}
                yAxis={{
                  min: 0,
                  max: matrixPoints[0]?.y,
                  title: t('nps-reports.matrix.quadrant.yAxisTitle')
                }}
                nps={npsGoal}
                data={matrixPoints}
                quadrantTitle={{
                  topLeft: t('nps-reports.matrix.quadrant.topLeftTitle'),
                  topRight:
                    npsGoal !== 100
                      ? t('nps-reports.matrix.quadrant.topRightTitle')
                      : '',
                  bottomLeft: t('nps-reports.matrix.quadrant.bottomLeftTitle'),
                  bottomRight:
                    npsGoal !== 100
                      ? t('nps-reports.matrix.quadrant.bottomRightTitle')
                      : ''
                }}
                hasChildren={!!dimensionKeyOnQuery}
                onPointClick={handlePointClick}
              />
            ) : (
              t('nps-reports.matrix.noData')
            )}
          </div>
        )}

        <InfoText text={t('nps-reports.matrix.info')} />
      </Container>
    </>
  )
}

export default withTranslation()(Matrix)
