import React, { useState, useRef } from 'react'

import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'

import { Widget } from '@uploadcare/react-widget'
import Button from '@material-ui/core/Button'

import { UPLOAD_CARE_TOKEN } from 'core/constants/keys'
import { TEMPLATE_EDITABLE } from 'core/enums/template-editable'

import Checkbox from 'components/Form/Box/Checkbox'
import NumberField from 'components/Form/Text/NumberField'
import RadioboxGroup from 'components/Form/Box/RadioboxGroup'
import Radiobox from 'components/Form/Box/Radiobox'
import AccordionItem from 'components/AccordionItem'

import TextEditor from '../TextEditor'

const TemplateEdit = (props) => {
  const [block, setBlock] = useState(props.block)
  const widgetApi = useRef()

  window.UPLOADCARE_LOCALE = props.i18n.language

  const setNewValue = (editable, newValue) => {
    let newBlock = block
    let newValueFormated = newValue

    if (newBlock.Editables[editable].Type === TEMPLATE_EDITABLE.json) {
      let possibleValues = JSON.parse(
        newBlock.Editables[editable].PossibleValues
      )
      newValueFormated = JSON.stringify(
        possibleValues.find((x) => x.Key === newValue)
      )
    }

    newBlock.Editables[editable].TargetValue.Value = newValueFormated
    setBlock(newBlock)
  }

  return (
    <li className="template-block">
      <AccordionItem title={block.Title} ignoreOutsideClick>
        {block.Editables.map((editable, eidx) => (
          <div key={eidx} className="template-editable">
            <h4 className="template-editable-title">{editable.Subtitle}</h4>
            {editable.Type === TEMPLATE_EDITABLE.text && (
              <TextEditor
                snippet={editable.TargetValue.Value}
                setSnippet={(newValue) => setNewValue(eidx, newValue)}
              />
            )}
            {editable.Type === TEMPLATE_EDITABLE.number && (
              <Formik
                initialValues={editable.TargetValue}
                validateOnBlur={false}
                validate={(values) => setNewValue(eidx, values.Value)}
              >
                {(propsForm) => {
                  return (
                    <Form>
                      <Field
                        name="Value"
                        label={null}
                        component={NumberField}
                      />
                    </Form>
                  )
                }}
              </Formik>
            )}
            {editable.Type === TEMPLATE_EDITABLE.image && (
              <div>
                <Button
                  onClick={() => {
                    const dialog = widgetApi.current.openDialog()
                    dialog.switchTab('url')
                  }}
                  color="primary"
                  type="button"
                  variant="contained"
                >
                  {props.t('template.edit.block.imageBtn')}
                </Button>
                <Widget
                  ref={widgetApi}
                  publicKey={UPLOAD_CARE_TOKEN}
                  imagesOnly
                  onChange={({ cdnUrl }) => setNewValue(eidx, cdnUrl)}
                  preloader={null}
                />
              </div>
            )}
            {(editable.Type === TEMPLATE_EDITABLE.json ||
              editable.Type === TEMPLATE_EDITABLE.bool) && (
              <Formik
                initialValues={{
                  [editable.TargetValue.TargetModel]:
                    editable.Type === TEMPLATE_EDITABLE.bool
                      ? editable.TargetValue.Value === 'True'
                      : JSON.parse(editable.TargetValue.Value).Key
                }}
                validateOnBlur={false}
                validate={(values) =>
                  setNewValue(eidx, values[editable.TargetValue.TargetModel])
                }
              >
                {(propsForm) => {
                  return (
                    <Form>
                      {editable.Type === TEMPLATE_EDITABLE.json && (
                        <RadioboxGroup
                          error={propsForm.errors.type}
                          touched={propsForm.touched.type}
                        >
                          {JSON.parse(editable.PossibleValues).map(
                            (possible, pidx) => (
                              <Field
                                name={editable.TargetValue.TargetModel}
                                label={possible.Value}
                                key={pidx}
                                id={possible.Key}
                                component={Radiobox}
                              />
                            )
                          )}
                        </RadioboxGroup>
                      )}
                      {editable.Type === TEMPLATE_EDITABLE.bool && (
                        <Field
                          name={editable.TargetValue.TargetModel}
                          label={null}
                          component={Checkbox}
                        />
                      )}
                    </Form>
                  )
                }}
              </Formik>
            )}
          </div>
        ))}
        <div className="template-block-apply">
          <Button
            color="primary"
            type="button"
            onClick={() => props.apply(block)}
          >
            {props.t('template.edit.block.applyBtn')}
          </Button>
        </div>
      </AccordionItem>
    </li>
  )
}

export default withTranslation()(TemplateEdit)
