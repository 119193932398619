import { useTranslation } from 'react-i18next'
import { useCallback, useMemo } from 'react'
import ScoreCircle from 'components/ScoreCircle'
import { FlexContainer } from 'style/components/Flex'
import { FlexItem } from 'style/components/Flex'
import { AnswerQuantity, SuperiorSubtitle, Title } from '../../style'
import ReplayIcon from '@material-ui/icons/Replay'
import ListAltIcon from '@material-ui/icons/ListAlt'
import BarChartIcon from '@material-ui/icons/BarChart'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { Button, IconButton } from '@material-ui/core'
import { useHistory, useLocation, useParams } from 'react-router'
import { BASE_PATH } from 'router'
import { Skeleton } from '@material-ui/lab'
import ViewMode from '../../dictionaries/view-mode'
import queryString from 'query-string'
import { QS_DIMENSION, QS_PAGE } from 'core/constants/query-strings'
import { getSearchWithoutDimensionId } from '../../functions/get-search-with-dimension-id'

const InfoBar = ({
  dimensionsScoreData,
  loadingDimensionsScoreData,
  handleUpdateData,
  decimal,
  viewMode,
  npsScore
}) => {
  const { t } = useTranslation()
  const { view } = useParams()
  const { push } = useHistory()
  const { search, pathname } = useLocation()

  const handleGoToEvolution = useCallback(() => {
    const searchObj = queryString.parse(search)
    delete searchObj[QS_PAGE]
    push(
      BASE_PATH +
        `reports/nps/${ViewMode.evolution}?` +
        queryString.stringify(searchObj)
    )
  }, [search, push])

  const handleGoToDimensionValues = useCallback(() => {
    const searchObj = queryString.parse(search)
    delete searchObj[QS_PAGE]
    push(
      BASE_PATH +
        `reports/nps/${ViewMode.list}?` +
        queryString.stringify(searchObj)
    )
  }, [push, search])

  const selectedDimensionKey = useMemo(() => {
    const currentSearch = queryString.parse(search)
    return currentSearch[QS_DIMENSION]
  }, [search])

  const hasBack = useMemo(() => {
    return !!selectedDimensionKey
  }, [selectedDimensionKey])

  const handleBack = useCallback(() => {
    const newSearchObj = queryString.parse(getSearchWithoutDimensionId(search))

    delete newSearchObj[QS_PAGE]

    push({
      pathname: pathname,
      search: queryString.stringify(newSearchObj)
    })
  }, [pathname, push, search])

  const pageTitle = useMemo(() => {
    return !selectedDimensionKey
      ? t('nps-reports.header.all-dimensions')
      : selectedDimensionKey
  }, [selectedDimensionKey, t])

  const pageSubtitle = useMemo(
    () => (
      <div>
        {t('nps-reports.header.superior-subtitle')}

        {selectedDimensionKey && (
          <>
            {' > '}
            <span style={{ color: '#00AEEF' }}>{selectedDimensionKey}</span>
          </>
        )}
      </div>
    ),
    [selectedDimensionKey, t]
  )

  const processedNpsScore = useMemo(() => {
    const dimensionScoreInfo = dimensionsScoreData?.Items?.find(
      (d) => d.Dimension === selectedDimensionKey
    )

    if (!selectedDimensionKey || !dimensionScoreInfo) {
      return npsScore
    } else {
      const dimensionScoreInfo = dimensionsScoreData.Items.find(
        (d) => d.Dimension === selectedDimensionKey
      )
      const PromoterCount = dimensionScoreInfo.PromoterCount
      const NeutralCount = dimensionScoreInfo.NeutralCount
      const DetractorsCount = dimensionScoreInfo.DetractorsCount

      const TotalCount = dimensionScoreInfo.TotalCount

      const PromoterPercentage = (100 * PromoterCount) / TotalCount
      const NeutralPercentage = (100 * NeutralCount) / TotalCount
      const DetractorsPercentage = (100 * DetractorsCount) / TotalCount

      return {
        PromoterCount,
        NeutralCount,
        DetractorsCount,
        TotalCount,
        PromoterPercentage,
        NeutralPercentage,
        DetractorsPercentage,
        Score: PromoterPercentage - DetractorsPercentage || 0
      }
    }
  }, [dimensionsScoreData.Items, npsScore, selectedDimensionKey])

  return (
    <FlexContainer margin="22px 0px 0px 0px" direction="column">
      <FlexContainer direction="row" justify="space-between">
        <FlexContainer direction="row" justify="flex-start">
          {hasBack && (
            <FlexItem margin="auto 4px">
              <IconButton
                onClick={handleBack}
                data-testid="info-bar-handle-back-action"
              >
                <ArrowBackIcon color="primary" />
              </IconButton>
            </FlexItem>
          )}

          <FlexItem>
            {loadingDimensionsScoreData ? (
              <div style={{ padding: 10 }}>
                <Skeleton
                  data-testid="infobar-skeleton"
                  variant="circle"
                  height={70}
                  width={70}
                />
              </div>
            ) : (
              <ScoreCircle
                tooltip
                size="2"
                decimal={decimal}
                promoterCount={processedNpsScore?.PromoterCount}
                neutralCount={processedNpsScore?.NeutralCount}
                detractorCount={processedNpsScore?.DetractorsCount}
                promoterPercentage={processedNpsScore?.PromoterPercentage?.toFixed(
                  2
                )}
                neutralPercentage={processedNpsScore?.NeutralPercentage?.toFixed(
                  2
                )}
                detractorPercentage={processedNpsScore?.DetractorsPercentage?.toFixed(
                  2
                )}
                value={processedNpsScore?.Score}
                data-testid="info-bar-score-circle"
              />
            )}
          </FlexItem>

          <FlexItem margin="auto 0px">
            <SuperiorSubtitle>
              {loadingDimensionsScoreData ? (
                <Skeleton data-testid="infobar-skeleton" width={100} />
              ) : (
                pageSubtitle
              )}
            </SuperiorSubtitle>
            <Title>
              {loadingDimensionsScoreData ? (
                <Skeleton data-testid="infobar-skeleton" width={200} />
              ) : (
                pageTitle
              )}
            </Title>
            <AnswerQuantity>
              {loadingDimensionsScoreData ? (
                <Skeleton data-testid="infobar-skeleton" width={100} />
              ) : (
                t('nps-reports.header.answer-quantity', {
                  qty: processedNpsScore?.TotalCount
                })
              )}
            </AnswerQuantity>
          </FlexItem>
        </FlexContainer>

        <FlexContainer direction="row" justify="flex-start">
          <FlexItem margin="auto 0px 0px 0px">
            {view === ViewMode.evolution ? (
              <Button
                onClick={handleGoToDimensionValues}
                color="primary"
                startIcon={<ListAltIcon />}
                data-testid="info-bar-go-to-dimensions"
              >
                {t('nps-reports.button.dimensionValues')}
              </Button>
            ) : (
              <Button
                onClick={handleGoToEvolution}
                color="primary"
                startIcon={<BarChartIcon />}
                data-testid="info-bar-go-to-evolution"
              >
                {t('nps-reports.button.evolution')}
              </Button>
            )}
          </FlexItem>

          <FlexItem margin="auto 0px 0px 0px">
            <Button
              onClick={() => handleUpdateData(viewMode)}
              color="primary"
              startIcon={
                <ReplayIcon
                  style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }}
                />
              }
              data-testid="info-bar-update-data"
            >
              {t('nps-reports.button.update')}
            </Button>
          </FlexItem>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  )
}

export default InfoBar
