import { useState } from 'react';
import { withTranslation } from 'react-i18next'
import { useAuthContext } from 'modules/Authentication'
import { useAppContext } from '../../App'
import { Permissions } from 'modules/AppDrawer/enums/Permissions'
import { useHistory } from 'react-router-dom'


import Grid from '@material-ui/core/Grid'
import Header from '../../components/Header'

import { BASE_PATH } from 'router'

import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Loader from '../../components/Loader'

import api from 'core/api'

import { Label, Container, Subtitle, Logo, SubContainer } from './style'

const StoreManagement = ({t}) => {

  const {
    user,
    store,
    handleSetUser,
    handleSetToken,
    handleSetStore
  } = useAuthContext()

  const history = useHistory()
  const { notify } = useAppContext()
  const [storeActive, setStoreActive] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingImage, setLoadingImage] = useState(true)

  const handleChangeStoreStatus = (value) => {
    if (!value && storeActive) {
      setStoreActive(value)
      api
        .post('store/inactivate', null)
        .then(({ data }) => {
          if (data.Authentication) {
            setLoading(true)
            setTimeout(() => {
              handleSetUser(data.Authentication._admin)
              handleSetStore(data.Authentication._store)
              handleSetToken(data.Authentication._jwtToken)
              refreshPage()
            }, 2000)
          } else {
            throw new Error(t('storemanagement.errorMainStore'));
          }
        })
        .catch((error) => {
          setLoading(false)
          setStoreActive(true)
          notify(error.message, 'error')
        })
    }
  }

  const handleLoadImage = () => {
    setLoadingImage(false)
  }

  function sendURL(path) {
    return history.push(path)
  }

  function refreshPage() {
    if (window.location.pathname !== BASE_PATH) {
      sendURL(BASE_PATH)
    }
    window.location.reload()
  }

  return (
    <>
      {
        user && store && Permissions.masterRights.includes(user?.Role) && (
          <Grid container>
            <Header
              type="filters"
              title={t('storemanagement.title')}
              data-testid="dashboard-header"
            />            

            <Container>

              <div style={{display: loadingImage ? "block" : "none"}} >
                <Loader noMargin size={40}></Loader>
              </div>
              <Logo style={{display: loadingImage ? "none" : "block"}} src={store?.Logo ? store?.Logo : "https://s3.amazonaws.com/yv-misc/UnavailableImage.png"} alt="Logo" onLoad={handleLoadImage}></Logo>

              <SubContainer>
                <Subtitle padding="10px">
                  {store?.Name}
                </Subtitle>
                <Label>
                  {store?.Description}
                </Label>
              </SubContainer>
            </Container>
      
            <Container>
              <Label>
              {t('storemanagement.switchStoreText')}
              </Label>

              {
                loading ? 'Redirecionando...'
                : (
                  <FormControlLabel
                    control={
                      <Switch
                        name="storeActiveChange"
                        size="medium"
                        color="primary"
                        checked={storeActive}
                        onChange={(e) =>
                          handleChangeStoreStatus(
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={
                      storeActive
                        ? t('campaing.edit.form.newWidget.switchActive')
                        : t('campaing.edit.form.newWidget.switchInactive')
                    }
                  />
                )
              }
            </Container>
            
            
          </Grid>
        )
      }
    </>
  )
}

export default withTranslation()(StoreManagement)
