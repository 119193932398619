import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'

import useStyles from './style'

const Title = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.title}>{children}</div>
}

const Actions = ({ children }) => {
  const classes = useStyles()

  return <div className={classes.actions}>{children}</div>
}

const HeaderWithActions = ({ title, items }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item xs={12} sm={8} md={6} lg={6}>
      <Title data-testid="header-title">{title}</Title>
    </Grid>
    <Grid item xs={12} sm={4} md={6} lg={6}>
      <Actions data-testid="header-actions">{items}</Actions>
    </Grid>
  </Grid>
)

const HeaderWithActionsAndFilters = ({
  title,
  actions,
  filters,
  noMarginBottom,
  noPaddingBottom
}) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <Title data-testid="header-title">{title}</Title>
    </Grid>
    <Grid item xs={12} sm={6} md={6} lg={6}>
      <Actions data-testid="header-actions">{actions}</Actions>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Actions data-testid="header-filters">{filters}</Actions>
    </Grid>
  </Grid>
)

const HeaderWithFilters = ({ title, items }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    justifyContent="space-between"
  >
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Title data-testid="header-title">{title}</Title>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Actions data-testid="header-actions">{items}</Actions>
    </Grid>
  </Grid>
)

const HeaderTypeMap = new Map([
  [
    'actions',
    (title, items) => <HeaderWithActions title={title} items={items} />
  ],
  [
    'filters',
    (title, items) => <HeaderWithFilters title={title} items={items} />
  ],
  [
    'both',
    (title, actions, filters) => (
      <HeaderWithActionsAndFilters
        title={title}
        actions={actions}
        filters={filters}
      />
    )
  ]
])

const Header = ({ type, title, items, filters }) =>
  HeaderTypeMap.has(type)
    ? HeaderTypeMap.get(type)(title, items, filters)
    : HeaderTypeMap.get('filters')(title, items, filters)

const { oneOf, oneOfType, object, string } = PropTypes

Header.propTypes = {
  type: oneOf(['actions', 'filters', 'both']).isRequired,
  title: oneOfType([object, string]),
  items: object
}

Header.defaultProps = {
  type: 'actions',
  title: <div></div>,
  items: <div></div>
}

export default Header
