import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

export const DimensionValueTable = ({ classes, t, data }) => {
  return (
    <Grid item xs>
      <Box display="flex" justifyContent="center" alignItems="center">
        <TableContainer className={classes.table} component={Paper}>
          <Table rows={[]} stickyHeader className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('dimension.chartViewTable.value')}</TableCell>
                <TableCell align="right">
                  {t('dimension.chartViewTable.quantity')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.Values.map((x, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {x.DimensionValue}
                  </TableCell>
                  <TableCell align="right">{x.Count}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  )
}
