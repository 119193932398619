import './index.css'
import EvolutionPercentage from 'components/EvolutionPercentage'
import React, { useCallback, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import CustomCard from 'components/CustomCard'
import ScoreCircle from 'components/ScoreCircle'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import i18n from 'lang'
import {
  MdSentimentSatisfied,
  MdSentimentNeutral,
  MdSentimentDissatisfied
} from 'react-icons/md'
import { Button } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { BarChart } from '@material-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { BASE_PATH } from 'router'
import queryString from 'query-string'
import { QS_CAMPAIGN } from 'core/constants/query-strings'

const iconSize = 28

const DashboardSatisfactionCard = ({
  decimal,
  loading,
  cardTitle,
  score,
  scoreCircleTitle,
  scoreEvolution,
  detractorCount,
  detractorPercentage,
  detractorEvolution,
  detractorLabel,
  neutralCount,
  neutralPercentage,
  neutralEvolution,
  neutralLabel,
  promoterCount,
  promoterPercentage,
  promoterEvolution,
  promoterLabel,
  showZone,
  previousPeriodStart,
  previousPeriodEnd,
  t
}) => {
  const { push } = useHistory()
  const { search } = useLocation()

  const roundNumber = useCallback(
    (number) => {
      return intlFloatPoint((number || 0).toFixed(decimal), i18n.language)
    },
    [decimal]
  )

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const currentCampaignId = useMemo(() => {
    return currentSearch[QS_CAMPAIGN]
  }, [currentSearch])

  const handleSeeReport = useCallback(() => {
    push(
      BASE_PATH +
        `reports/conversion?idcampaign=${currentCampaignId}&idcampaignlist=${currentCampaignId}`
    )
  }, [currentCampaignId, push])

  const getPercentageComponent = useCallback(
    (
      iconComponent,
      percentage,
      title,
      evolution,
      evolutionDownColor,
      evolutionStableColor,
      evolutionUpColor
    ) => {
      return (
        <div className="dashboard-card-satisfaction">
          {loading ? (
            <Skeleton variant="circle" width={30} height={30} />
          ) : (
            iconComponent
          )}
          {loading ? (
            <Skeleton variant="text" width={40} height={30} />
          ) : (
            <div className="dashboard-card-satisfaction-percentage">
              {roundNumber(percentage)}%
            </div>
          )}
          {loading ? (
            <Skeleton variant="text" width={60} height={30} />
          ) : (
            <div className="dashboard-card-satisfaction-label">{title}</div>
          )}
          {loading ? (
            <Skeleton variant="text" width={30} height={30} />
          ) : (
            <>
              {typeof promoterEvolution !== 'undefined' && (
                <EvolutionPercentage
                  evolution={roundNumber(evolution)}
                  evolutionDownColor={evolutionDownColor}
                  evolutionStableColor={evolutionStableColor}
                  evolutionUpColor={evolutionUpColor}
                  previousPeriodStart={previousPeriodStart}
                  previousPeriodEnd={previousPeriodEnd}
                />
              )}
            </>
          )}
        </div>
      )
    },
    [
      loading,
      previousPeriodEnd,
      previousPeriodStart,
      promoterEvolution,
      roundNumber
    ]
  )

  const currentZoneComponent = useMemo(() => {
    if (showZone && typeof score !== 'undefined') {
      const intScore = parseInt(score)

      if (intScore < 0) {
        return (
          <Button
            style={{
              color: '#EF3E39',
              backgroundColor: 'rgba(239, 62, 57, 0.11)'
            }}
          >
            {t('dashboard.zones.critical')}
          </Button>
        )
      }
      if (intScore >= 0 && intScore < 50) {
        return (
          <Button
            style={{
              color: '#F7A723',
              backgroundColor: 'rgba(247, 167, 35, 0.11)'
            }}
          >
            {t('dashboard.zones.improvement')}
          </Button>
        )
      }
      if (intScore >= 50 && intScore < 80) {
        return (
          <Button
            style={{
              color: '#ACB135',
              backgroundColor: '#F6F7E9'
            }}
          >
            {t('dashboard.zones.quality')}
          </Button>
        )
      }
      return (
        <Button
          style={{
            color: '#60BB46',
            backgroundColor: 'rgba(96, 187, 70, 0.11)'
          }}
        >
          {t('dashboard.zones.excellence')}
        </Button>
      )
    }
  }, [score, showZone, t])

  return (
    <CustomCard padding="24px" titleText={cardTitle}>
      <div className="dashboard-card-satisfaction-circle">
        {loading ? (
          <span data-testid="satisfaction-card-loading">
            <Skeleton variant="circle" width={160} height={160} />
          </span>
        ) : (
          <ScoreCircle
            decimal={decimal}
            promoterCount={promoterCount}
            neutralCount={neutralCount}
            detractorCount={detractorCount}
            size="3"
            value={score}
            tooltip
            title={scoreCircleTitle}
            evolution={scoreEvolution}
            previousPeriodStart={previousPeriodStart}
            previousPeriodEnd={previousPeriodEnd}
          />
        )}
      </div>

      <div className="dashboard-card-satisfaction-zone">
        {loading ? (
          <Skeleton variant="text" width={150} height={50} />
        ) : (
          currentZoneComponent
        )}
      </div>

      <div className="dashboard-card-satisfaction-wrapper">
        {getPercentageComponent(
          <MdSentimentSatisfied size={iconSize} color="#60BB46" />,
          promoterPercentage,
          promoterLabel,
          promoterEvolution,
          '#EF3E39',
          '#9E9E9E',
          '#60BB46'
        )}
        {getPercentageComponent(
          <MdSentimentNeutral size={iconSize} color="#F7A723" />,
          neutralPercentage,
          neutralLabel,
          neutralEvolution,
          '#9E9E9E',
          '#9E9E9E',
          '#9E9E9E'
        )}
        {getPercentageComponent(
          <MdSentimentDissatisfied size={iconSize} color="#EF3E39" />,
          detractorPercentage,
          detractorLabel,
          detractorEvolution,
          '#60BB46',
          '#9E9E9E',
          '#EF3E39'
        )}
      </div>

      {currentCampaignId && (
        <Button
          style={{ marginTop: 18 }}
          color="primary"
          onClick={handleSeeReport}
        >
          <BarChart style={{ marginRight: 5 }} color="primary" />
          {t('dashboard.seeReport')}
        </Button>
      )}
    </CustomCard>
  )
}

export default withTranslation()(DashboardSatisfactionCard)
