import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    marginTop: 20
  },
  box: {
    margin: 20
  },
  pageTitle: {
    fontSize: 44,
    fontStyle: 'normal',
    fontWeight: 900
  },
  pageDescription: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '10px 0px'
  },
  nameDescription: {
    fontSize: 25,
    margin: '20px 0px'
  },
  adminName: {
    fontSize: 25,
    fontStyle: 'normal',
    fontWeight: 900
  },
  answerLabel: {
    margin: '15px 0px',
    fontStyle: 'normal',
    fontWeight: 900,
    fontSize: 15
  }
}))

export default useStyle
