import { getIn } from 'formik'

import Tags from './Tags'

const TagsField = ({
  field,
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  return (
    <div
      className={
        'form-field tags-field' +
        (getIn(errors, field.name) && getIn(touched, field.name)
          ? ' tags-input-error'
          : '')
      }
    >
      {props.data.length > 0 && (
        <Tags
          tags={field.value.map((f) => props.data.find((d) => d.id === f))}
          suggestions={props.data}
          placeholder={props.placeholder}
          update={(itens) => {
            setFieldValue(
              field.name,
              itens.map((i) => i.id.toString())
            )
          }}
        />
      )}

      {field.name &&
        getIn(touched, field.name) &&
        getIn(errors, field.name) && (
          <small className="input-warning">{getIn(errors, field.name)}</small>
        )}
    </div>
  )
}

export default TagsField
