const AdminSubtitle = [
  {
    type: 'adminType.1',
    description: 'admin.edit.adminDesc'
  },
  {
    type: 'adminType.2',
    description: 'admin.edit.managerDesc'
  },
  {
    type: 'adminType.3',
    description: 'admin.edit.clerkDesc'
  },
  {
    type: 'adminType.4',
    description: 'admin.edit.readerDesc'
  }
]

export default AdminSubtitle
