import { getItems } from './get-plan-list-items'
import Items from 'modules/AppDrawer/dictionaries/Items'

/**
 * Return url path match and user permission to route
 *
 * @param {Object{}} user User details
 * @param {string} pathname Pathname from URL location
 */
export const getPermissionsDetails = (user, pathname) => {
  const role = user?.Role

  const path = pathname?.replace(/\/$/, '').replace(/\//, '')
  const segment = path?.substring(path.indexOf('/') + 1)

  const items = getItems(Items)
  const matchPath = items.find((item) => item.path === segment)
  const allowed = matchPath ? matchPath?.permission.includes(role) : null

  return { matchPath, allowed }
}
