import queryString from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useAppContext } from '../../App'
import api from '../api'

const debounceObj = {}

/**
 * Hook for get data and handle loading state between steps
 *
 * @param {string} endpoint enpoint for get data
 * @param {Function} setData Setter for state
 * @param {Function} setLoading Setter for loading state
 * @param {Function} specificParams If you need to override any param that in on the QS
 * @param {Function} paramsFromUrl If you need the endpoint to reload only if specific params are changed on the QS
 * @param {Function} debounceTime If you want the request to be debounced
 * @param {Boolean} noQueryString If you want include url queries strings to the request api
 */
const useGetData = ({
  endpoint,
  setData,
  setLoading,
  specificParams,
  paramsFromUrl,
  debounceTime,
  noQueryString
}) => {
  const { search } = useLocation()
  const { notify } = useAppContext()

  const allParams = useMemo(() => {
    let allParams = paramsFromUrl
    if (specificParams) {
      allParams = [...allParams, ...Object.keys(specificParams)]
    }
    return allParams
  }, [paramsFromUrl, specificParams])

  const getSearchObj = () => {
    let searchObj = queryString.parse(window.location.search) || {}

    if (specificParams) {
      searchObj = { ...searchObj, ...specificParams }
    }

    if (paramsFromUrl) {
      Object.keys(searchObj).forEach((key) => {
        if (!allParams.includes(key)) {
          delete searchObj[key]
        }
      })
    }

    return searchObj
  }

  const getData = () => {
    if (setLoading) {
      setLoading(true)
    }

    const execute = async () => {
      const searchObj = getSearchObj()

      let searchString = queryString.stringify(searchObj)
      if (searchString.length && !searchString[0] !== '?') {
        searchString = '?' + searchString
      }
      if (searchString === '?' || noQueryString === true) {
        searchString = ''
      }

      try {
        const res = await api.get(endpoint + searchString)
        if (res) {
          setData(res.data)
        }
      } catch (error) {
        notify(error.message, 'error')
      } finally {
        if (setLoading) {
          setLoading(false)
        }
      }
    }

    if (!debounceTime) {
      execute()
    } else {
      debounceObj[endpoint] = debounceObj[endpoint]
        ? debounceObj[endpoint] + 1
        : 1
      let currentDebounceValue = debounceObj[endpoint]
      setTimeout(() => {
        if (currentDebounceValue === debounceObj[endpoint]) {
          execute()
        }
      }, debounceTime)
    }
  }

  const [lastSearchObj, setLastSearchObj] = useState()
  useEffect(() => {
    let isMounted = true

    const searchObj = getSearchObj()

    if (isMounted) setLastSearchObj(searchObj)

    if (
      !paramsFromUrl ||
      !lastSearchObj ||
      paramsFromUrl.find((p) => searchObj[p] !== lastSearchObj[p])
    ) {
      getData()
    }

    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, specificParams])

  return { getData }
}

export default useGetData
