import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import makeId from '../../functions/makeId'

import TextField from '@material-ui/core/TextField'

import useStyles from './style'

const SimpleText = ({ value, onChange, item, name, label, id }) => {
  const classes = useStyles()

  const [state, setState] = useState(value ?? '')
  const handleChange = ({ target }) => {
    setState(target.value)
    onChange(target, item)
  }

  useEffect(() => {
    onChange(
      {
        name: item.Name,
        value: item.Value
      },
      item
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TextField
      value={state}
      name={name}
      label={label}
      onChange={handleChange}
      className={classes.field}
      id={makeId(id, name)}
      data-testid="dinamic-form-simpletext"
    />
  )
}

const { string, number, func, oneOfType } = PropTypes

SimpleText.propTypes = {
  id: number,
  name: string,
  label: string,
  onChange: func,
  value: oneOfType([string, number])
}

export default SimpleText
