import './index.css'
import { useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import Chart from './components/Chart'
import { useHistory } from 'react-router'
import queryString from 'query-string'
import { QS_DATE_INTERVAL } from 'core/constants/query-strings'
import Answers from './components/Answers'

const Evolution = ({
  loadingAnswersData,
  answersData,
  decimal,
  loadingDecimal,
  histogramData,
  loadingHistogramData,
  onUpdateAnswersDateFilter
}) => {
  const history = useHistory()

  const onChangeGroupBy = useCallback(
    (groupByFilter) => {
      const currentSearch = queryString.parse(history.location.search)
      currentSearch[QS_DATE_INTERVAL] = groupByFilter
      history.push({
        pathname: history.location.pathname,
        search: queryString.stringify(currentSearch)
      })
    },
    [history]
  )

  return (
    <div className="evolution-wrapper">
      <div style={{ height: 30 }}></div>

      <Chart
        data={histogramData}
        decimal={decimal}
        onChangeGroupBy={onChangeGroupBy}
        loading={loadingHistogramData || loadingDecimal}
        onUpdateAnswersDateFilter={onUpdateAnswersDateFilter}
      />

      <div style={{ height: 30 }}></div>

      <Answers data={answersData} loading={loadingAnswersData} />
    </div>
  )
}

export default withTranslation()(Evolution)
