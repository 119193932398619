import styled from 'styled-components'

export const Container = styled.div`
  ${({ scrollY }) =>
    scrollY
      ? `
    max-height: 300px;
    overflow-y: auto;
  `
      : ''}
`

export const Box = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  text-align: start;

  ${({ center }) =>
    center
      ? `
        justify-content: center;
        align-items: center;
      `
      : 'justify-content: space-between;'}

  height: 100%;
  ${({ width }) => (width ? `width: ${width};` : 'width: 100%;')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth};` : '')}
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ''}
`

export const Title = styled.h2`
  display: flex;
  flex-direction: row;
  font-style: normal;
  ${({ bold }) => (bold ? 'font-weight: bold;' : '')}
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
  ${({ marginBottom }) =>
    marginBottom ? `margin-bottom: ${marginBottom};` : ''}
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft};` : '')}
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
  font-size: ${({ size }) => (size ? `${size}px;` : '24px')};
  line-height: 34px;
  text-align: start;
`

export const OptionalLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 4px;
`

export const DispatchListName = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(0, 0, 0);
  margin-bottom: 8px;
`

export const ListItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  align-content: center;

  height: 56px;

  left: 12px;
  right: 12px;
  bottom: 12px;

  margin-top: 16px;
  padding: 0 8px;

  background: rgba(224, 224, 224, 0.4);
  border-radius: 4px;
`

export const InfoLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: rgba(0, 0, 0, 0.54);
`
export const InfoText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.87);
`

export const DaysLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0px;
`

export const InputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin: 24px 0px;
`

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;

  width: 188px;
`

export const Position = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;

  background: ${({ active }) => (active ? '#00aeef' : '#BDBDBD')};
  color: #fff;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #fafafa;

  width: 24px;
  height: 24px;
  border-radius: 100%;

  margin: 4px 8px 4px 0px;
`
export const StepTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 4px 0px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  position: relative;

  height: 100%;
`

export const InsideContent = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  justify-content: space-between;

  overflow-y: ${({ noScroll }) => (noScroll ? 'hidden' : 'scroll')};

  max-height: calc(100vh - 195px);
  height: 100%;

  ${({ padding }) => (padding ? `padding: ${padding};` : '')}
`

export const Column = styled.div`
  height: ${({ height }) => height ?? `calc(100vh - 130px)`};
  padding: ${({ padding }) => padding ?? '0px'};
  display: flex;
  flex-direction: column;

  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
  ${({ greyBg }) => (greyBg ? `background-color: #ECEEF0;` : '')}
  ${({ scrollY }) => scrollY && `overflow-y: scroll;`}
  ${({ scrollYHidden }) => scrollYHidden && `overflow-y: hidden;`}
  ${({ border }) => (border ? `border-right: 1px solid #E0E0E0;` : '')}
`
export const ItemList = styled.div`
  color: rgba(0, 0, 0, 0.54);
`

export const DispatchList = styled.div``
