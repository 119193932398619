import { useHistory, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { MdKeyboardBackspace } from 'react-icons/md'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import {
  QS_DIMENSION_VALUE_LIST,
  QS_CAMPAIGN_LIST,
  QS_PERSON_EMAIL,
  QS_NPS_COMMENT,
  QS_NPS_CLASS,
  QS_TAG,
  QS_ID_NPS,
  QS_ACTIVE,
  QS_WITH_COMMENT
} from 'core/constants/query-strings'
import { NPS_CLASSIFICATION } from 'core/enums/nps-classification'
import DateRangePicker from '../../../../components/DateRangePicker'
import DropdownField from '../../../../components/Form/Option/DropdownField'
import TagsField from '../../../../components/Form/Option/TagsField'
import TextField from '../../../../components/Form/Text/TextField'
import CheckboxFilter from '../../../../components/CheckboxFilter'
import FilterDimension from '../FilterDimension'
import { campaign } from 'core/helpers'
import {
  getItemText,
  getItemArray
} from 'core/functions/get-qs-value.function.js'
import setFilter from 'core/functions/setFilter'
import '../../../../style/route/answer/filter.css'
import { useMemo, useEffect } from 'react'
import queryString from 'query-string'
import { QS_TYPES } from 'core/constants/query-strings'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'

const FilterAnswer = ({
  tags,
  close,
  campaigns,
  onCampaignSelected,
  dimensions,
  t
}) => {
  const history = useHistory()
  const location = useLocation()

  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const isCsat = useMemo(() => {
    return currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam
  }, [currentSearch])

  const getTagsSuggestions = () => {
    if (tags.length > 0)
      return tags.map((item) => {
        return {
          id: item.IdYourviews.toString(),
          name: item.Name
        }
      })

    return []
  }

  const reset = () => {
    if (location.search) {
      history.push({
        pathname: location.pathname,
        search: ''
      })
    }
    close()
  }

  const filteredCampaigns = useMemo(() => {
    if (isCsat) {
      return campaigns.filter((c) => c.Type === NPS_CSAT_TAB.csat.typeValue)
    }
    return campaigns.filter((c) => c.Type === NPS_CSAT_TAB.nps.typeValue)
  }, [isCsat, campaigns])

  useEffect(() => {
    if (!getItemText(QS_CAMPAIGN_LIST)) {
      onCampaignSelected({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="filter">
      <IconButton
        color="primary"
        className="filter-back-btn"
        onClick={() => close()}
      >
        <MdKeyboardBackspace />
      </IconButton>
      <div className="filter-body">
        <h4 className="subtitle">{t('answer.filter.title')}</h4>
        <Formik
          initialValues={{
            [QS_CAMPAIGN_LIST]: getItemText(QS_CAMPAIGN_LIST)
              ? parseInt(getItemText(QS_CAMPAIGN_LIST))
              : '',
            [QS_NPS_CLASS]: getItemArray(QS_NPS_CLASS),
            [QS_DIMENSION_VALUE_LIST]: getItemArray(QS_DIMENSION_VALUE_LIST),
            [QS_TAG]: getItemArray(QS_TAG),
            [QS_PERSON_EMAIL]: getItemText(QS_PERSON_EMAIL),
            [QS_ID_NPS]: getItemText(QS_ID_NPS),
            [QS_NPS_COMMENT]: getItemText(QS_NPS_COMMENT),
            [QS_WITH_COMMENT]: getItemText(QS_WITH_COMMENT),
            [QS_ACTIVE]: getItemText(QS_ACTIVE),
            date: ''
          }}
          onSubmit={(values) => {
            if (values.date) {
              values.from = values.date?.from
              values.to = values.date?.to
              delete values.date
            }

            setFilter({ history, location }, values, close, true)
          }}
        >
          <Form>
            <div className="filter-item">
              <Field
                name="date"
                defaultSelection
                component={DateRangePicker}
                label={t('answer.filter.byDate')}
              />
            </div>

            <div className="filter-item">
              <Field
                onSelected={onCampaignSelected}
                name={QS_CAMPAIGN_LIST}
                component={DropdownField}
                label={t('answer.filter.byCampaign')}
                data={campaign.toDropdown(
                  filteredCampaigns,
                  t('component.dropdown.campaignAll')
                )}
              />
            </div>

            <div className="filter-item">
              <Field
                name={QS_NPS_CLASS}
                data={[
                  {
                    id: NPS_CLASSIFICATION.promoter.toString(),
                    name: isCsat
                      ? t('answer.filter.satisfiedlabel')
                      : t('answer.filter.promoterLabel')
                  },
                  {
                    id: NPS_CLASSIFICATION.neutral.toString(),
                    name: t('answer.filter.neutralLabel')
                  },
                  {
                    id: NPS_CLASSIFICATION.detractor.toString(),
                    name: isCsat
                      ? t('answer.filter.dissatisfiedlabel')
                      : t('answer.filter.detractorLabel')
                  }
                ]}
                placeholder={t('answer.filter.byType')}
                component={TagsField}
              />
            </div>

            <div className="filter-item">
              <Field
                name={QS_TAG}
                component={TagsField}
                data={getTagsSuggestions()}
                placeholder={t('answer.filter.byTag')}
              />
            </div>

            <div className="filter-item">
              <Field
                component={TextField}
                name={QS_PERSON_EMAIL}
                label={t('answer.filter.byUserText')}
                placeholder={t('answer.filter.userTextLabel')}
              />
            </div>

            <div className="filter-item">
              <Field
                component={TextField}
                name={QS_ID_NPS}
                label={t('answer.filter.byIdNpsText')}
                placeholder={t('answer.filter.byIdNps')}
              />
            </div>

            {!isCsat && (
              <div className="filter-item">
                <Field
                  component={TextField}
                  name={QS_NPS_COMMENT}
                  label={t('answer.filter.byAnswerText')}
                  placeholder={t('answer.filter.answerLabel')}
                />
              </div>
            )}

            {!!dimensions && (
              <div className="filter-item">
                <Field
                  name={QS_DIMENSION_VALUE_LIST}
                  component={FilterDimension}
                  dimensions={dimensions}
                />
              </div>
            )}

            <div className="filter-item">
              <h4 className="label-title label-title-small">
                {t('answer.filter.byComment')}
              </h4>

              <Field
                name={QS_WITH_COMMENT}
                queryString={QS_WITH_COMMENT}
                component={CheckboxFilter}
                options={{
                  whenTrue: t('answer.filter.withComment'),
                  whenFalse: t('answer.filter.withoutComment')
                }}
              />

              <br />

              <Field
                name={QS_ACTIVE}
                queryString={QS_ACTIVE}
                component={CheckboxFilter}
                options={{
                  whenTrue: t('answer.filter.byState.active'),
                  whenFalse: t('answer.filter.byState.inactive')
                }}
              />
            </div>

            <div className="filter-actions">
              <Button
                className="btn filter-action-btn"
                onClick={() => reset()}
                type="reset"
              >
                {t('answer.filter.resetBtn')}
              </Button>
              <Button color="primary" variant="contained" type="submit">
                {t('answer.filter.filterBtn')}
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default withTranslation()(FilterAnswer)
