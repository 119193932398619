import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #e0e0e0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const Box = styled.div`
  display: flex;
  ${({ hide }) =>
    hide
      ? `
        visibility: hidden;
      `
      : `
        visibility: visible;
      `}

  ${({ row }) =>
    row
      ? `
        flex-direction: row;
      `
      : `
        flex-direction: column;
      `}

  ${({ flexEnd }) =>
    flexEnd
      ? `
        justify-content: flex-end;
      `
      : `
        justify-content: center;
      `}
  align-items: center;
  text-align: center;
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop};` : '')}
`
