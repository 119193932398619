import { Button, IconButton } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import axios from 'core/api'
import queryString from 'query-string'
import CloseIcon from '@material-ui/icons/Close'

const ExportDataButton = ({ t }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const notify = useCallback(
    (data) => {
      const actions = (key) => (
        <>
          <Link
            color="primary"
            underline="none"
            href={data.link}
            component={Button}
            style={{ color: '#fff' }}
          >
            {t('uiActions.button.download')}
          </Link>

          <IconButton onClick={() => closeSnackbar(key)}>
            <CloseIcon style={{ color: '#fff' }} fontSize="small" />
          </IconButton>
        </>
      )

      const notifyOptions = (variant, action, persist) => ({
        action,
        persist,
        variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      })

      const notifyType = {
        start: () => ({
          text: t('uiActions.download.start'),
          options: notifyOptions('default')
        }),
        error: () => ({
          text: data.message,
          options: notifyOptions('error')
        }),
        success: () => ({
          text: t('uiActions.download.success'),
          options: notifyOptions('success', actions, true)
        })
      }

      const { text, options } = notifyType[data.type]()
      enqueueSnackbar(text, options)
    },
    [closeSnackbar, enqueueSnackbar, t]
  )

  const handleExport = useCallback(async () => {
    notify({ type: 'start' })

    let currentQS = queryString.parse(window.location.search)
    currentQS.PageSize = 99999
    currentQS.Page = 1
    currentQS = queryString.stringify(currentQS)
    if (!currentQS.startsWith('?')) {
      currentQS = '?' + currentQS
    }

    try {
      const res = await axios.get(`nps/exportnpslist${currentQS}`)
      if (!res || !res.data || !res.data.Success) {
        throw new Error({ message: 'Error on exporting' })
      }

      var link = document.createElement('a')
      link.href = res.data.Url
      link.click()

      if (res.data.Success) {
        notify({ type: 'success', link: res.data.Url })
      }
    } catch (error) {
      if (error.message) {
        notify({ type: 'error', message: error.message })
      }
    }
  }, [notify])

  return (
    <Button onClick={handleExport} color="primary" startIcon={<GetAppIcon />}>
      {t('evolution.answers.export')}
    </Button>
  )
}

export default withTranslation()(ExportDataButton)
