import './index.css'
import { withTranslation } from 'react-i18next'
import {
  Send,
  Check,
  MailOutline,
  DraftsOutlined,
  DescriptionOutlined,
  NotificationsNoneOutlined,
  NotificationsActiveOutlined,
  FileCopyOutlined,
  GetApp
} from '@material-ui/icons'
import { useHistory, useLocation } from 'react-router-dom'
import { useCallback, useMemo, useState } from 'react'
import queryString from 'query-string'
import { QS_PAGE, QS_PAGE_SIZE } from 'core/constants/query-strings'
import Pagination from 'components/Pagination'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableContainer from '@material-ui/core/TableContainer'
import { Button, IconButton, Tooltip } from '@material-ui/core'
import CopyLinkDialog from '../CopyLinkDialog'
import { Skeleton } from '@material-ui/lab'
import useGetData from 'core/hooks/useGetData'
import NoDataRow from 'components/NoDataRow'
import getData from 'core/functions/get-data.function'
import { formatDateToString } from 'core/functions/format-date.function'

const AnswerTable = ({ title, i18n, t }) => {
  const { language } = i18n
  const { pathname, search } = useLocation()
  const { push } = useHistory()

  const [listData, setListData] = useState({})
  const [loadingListData, setLoadingListData] = useState(true)
  useGetData({
    endpoint: '/Report/conversion-report/email/list',
    setData: setListData,
    setLoading: setLoadingListData,
    debounceTime: 500
  })

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const handleChangePagination = useCallback(
    (newPage, newPageSize) => {
      push({
        pathname: pathname,
        search: queryString.stringify({
          ...currentSearch,
          [QS_PAGE]: newPage,
          [QS_PAGE_SIZE]: newPageSize
        })
      })
    },
    [currentSearch, pathname, push]
  )

  const [rowBeingCopied, setRowBeingCopied] = useState(undefined)
  const [lastCopiedLink, setLastCopiedLink] = useState('')

  const handleCopyLink = useCallback((row) => {
    setRowBeingCopied(row)
  }, [])

  const handleCloseCopyLinkModal = useCallback(() => {
    setRowBeingCopied(undefined)
  }, [])

  const handleConfirmCopyLinkModal = useCallback(() => {
    navigator.clipboard.writeText(rowBeingCopied.NpsRequestUrl)
    setLastCopiedLink(rowBeingCopied.NpsRequestUrl)
    handleCloseCopyLinkModal()
  }, [handleCloseCopyLinkModal, rowBeingCopied])

  const handleExport = useCallback(() => {
    getData(
      `/Report/conversion-report/email/export?${queryString.stringify(
        currentSearch
      )}`,
      (response) => {
        if (response.Url) {
          window.open(response.Url)
        }
      }
    )
  }, [currentSearch])

  return (
    <div className="conversion-email-answertable">
      <div className="conversion-email-answertable-title">
        {title}

        <Button color="primary" onClick={handleExport}>
          <GetApp color="primary" />
          {t('uiActions.exportData')}
        </Button>
      </div>

      <TableContainer>
        <Table rows={[]}>
          <TableHead>
            <TableRow>
              <TableCell align="left">
                {t('conversion-report.email.answers.person')}
              </TableCell>
              <TableCell align="left">
                {t('conversion-report.email.answers.stage')}
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {!loadingListData && !listData.TotalItemCount && <NoDataRow />}

            {(loadingListData ? [1, 2, 3, 4] : listData.Items).map(
              (row, index) => (
                <TableRow
                  key={index}
                  className="conversion-email-answertable-row"
                >
                  <TableCell align="left">
                    <div className="conversion-email-answertable-row-name">
                      {loadingListData ? (
                        <Skeleton variant="rect" height={16} width={160} />
                      ) : (
                        row.Person.Name
                      )}
                    </div>
                    <div className="conversion-email-answertable-row-email">
                      {loadingListData ? (
                        <Skeleton
                          variant="rect"
                          height={16}
                          width={280}
                          style={{ marginTop: 8 }}
                        />
                      ) : (
                        row.Person.Email
                      )}
                    </div>
                  </TableCell>

                  <TableCell align="left">
                    <div className="conversion-email-answertable-row-stage">
                      {loadingListData ? (
                        <Skeleton variant="rect" height={24} width={24} />
                      ) : (
                        <Tooltip
                          title={
                            <div className="conversion-email-answertable-row-tooltip">
                              {row.Sent
                                ? t(
                                    'conversion-report.email.answers.tooltips.sent',
                                    {
                                      date: formatDateToString({
                                        date: row.SentDate,
                                        language
                                      })
                                    }
                                  )
                                : t(
                                    'conversion-report.email.answers.tooltips.notSent'
                                  )}
                            </div>
                          }
                        >
                          <Send
                            data-testid="sent-icon"
                            color={row.Sent ? 'primary' : 'disabled'}
                          />
                        </Tooltip>
                      )}

                      {loadingListData ? (
                        <Skeleton variant="rect" height={24} width={24} />
                      ) : (
                        <Tooltip
                          title={
                            <div className="conversion-email-answertable-row-tooltip">
                              {row.Opened
                                ? t(
                                    'conversion-report.email.answers.tooltips.emailOpened'
                                  )
                                : t(
                                    'conversion-report.email.answers.tooltips.emailNotOpened'
                                  )}
                            </div>
                          }
                        >
                          {row.Opened ? (
                            <DraftsOutlined
                              data-testid="opened-icon"
                              color="primary"
                            />
                          ) : (
                            <MailOutline
                              data-testid="opened-icon"
                              color="disabled"
                            />
                          )}
                        </Tooltip>
                      )}

                      {loadingListData ? (
                        <Skeleton variant="rect" height={24} width={24} />
                      ) : (
                        <Tooltip
                          title={
                            <div className="conversion-email-answertable-row-tooltip">
                              {row.Clicked
                                ? t(
                                    'conversion-report.email.answers.tooltips.formOpened'
                                  )
                                : t(
                                    'conversion-report.email.answers.tooltips.formNotOpened'
                                  )}
                            </div>
                          }
                        >
                          <DescriptionOutlined
                            data-testid="clicked-icon"
                            color={row.Clicked ? 'primary' : 'disabled'}
                          />
                        </Tooltip>
                      )}

                      {loadingListData ? (
                        <Skeleton variant="rect" height={24} width={24} />
                      ) : (
                        <Tooltip
                          title={
                            <div className="conversion-email-answertable-row-tooltip">
                              {row.RememberSent
                                ? t(
                                    'conversion-report.email.answers.tooltips.reminderReceived',
                                    {
                                      date: formatDateToString({
                                        date: row.RememberSentDate,
                                        language
                                      })
                                    }
                                  )
                                : t(
                                    'conversion-report.email.answers.tooltips.reminderNotReceived'
                                  )}
                            </div>
                          }
                        >
                          {row.RememberSent ? (
                            <NotificationsActiveOutlined
                              data-testid="remembersent-icon"
                              color="primary"
                            />
                          ) : (
                            <NotificationsNoneOutlined
                              data-testid="remembersent-icon"
                              color="disabled"
                            />
                          )}
                        </Tooltip>
                      )}

                      {loadingListData ? (
                        <Skeleton variant="rect" height={16} width={160} />
                      ) : (
                        <Tooltip
                          title={
                            <div className="conversion-email-answertable-row-tooltip">
                              {row.Answered
                                ? t(
                                    'conversion-report.email.answers.tooltips.replied',
                                    {
                                      date: formatDateToString({
                                        date: row.NpsDate,
                                        language
                                      })
                                    }
                                  )
                                : t(
                                    'conversion-report.email.answers.tooltips.notReplied'
                                  )}
                            </div>
                          }
                        >
                          <div
                            className="conversion-email-answertable-row-status"
                            style={{
                              background: row.Answered ? '#60BB46' : '#E0E0E0',
                              color: row.Answered ? 'white' : '#666666'
                            }}
                          >
                            {row.Answered && (
                              <Check
                                style={{ color: 'white', marginRight: 8 }}
                              />
                            )}
                            {row.Answered
                              ? t('conversion-report.email.answers.replied')
                              : t('conversion-report.email.answers.notReplied')}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>

                  <TableCell>
                    <div className="conversion-email-answertable-row-copy">
                      {!loadingListData && (
                        <Tooltip
                          title={
                            lastCopiedLink === row.Link
                              ? t(
                                  'conversion-report.email.answers.tooltips.copied'
                                )
                              : t(
                                  'conversion-report.email.answers.tooltips.copy'
                                )
                          }
                        >
                          <IconButton onClick={() => handleCopyLink(row)}>
                            <FileCopyOutlined
                              data-testid="copylink-icon"
                              style={{ color: '#757575' }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <Pagination
          data={listData.MetaData}
          onChangePagination={handleChangePagination}
        />
      </TableContainer>

      {rowBeingCopied && (
        <CopyLinkDialog
          rowBeingCopied={rowBeingCopied}
          handleCancel={handleCloseCopyLinkModal}
          handleConfirm={handleConfirmCopyLinkModal}
        />
      )}
    </div>
  )
}

export default withTranslation()(AnswerTable)
