export const Roles = {
  Master: 0,
  Admin: 1,
  Manager: 2,
  Attendant: 3,
  Read: 4
}

export const Permissions = {
  lowerRights: [
    Roles.Master,
    Roles.Admin,
    Roles.Manager,
    Roles.Attendant,
    Roles.Read
  ],
  mediumRights: [Roles.Master, Roles.Admin, Roles.Manager, Roles.Attendant],
  managerRights: [Roles.Master, Roles.Admin, Roles.Manager],
  higherRights: [Roles.Master, Roles.Admin],
  masterRights: [Roles.Master]
}
