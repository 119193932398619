import styled from 'styled-components'

import { makeStyles } from '@material-ui/core'

export const Container = styled.div`
  overflow: auto;

  min-height: 250px;
  max-height: calc(100vh-400px);

  display: flex;
  position: relative;

  flex-direction: column;

  text-align: center;
  align-items: center;
  align-content: center;

  border: 1px solid #e0e0e0;
  border-radius: 4px;
`
export const List = styled.div`
  width: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  text-align: start;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`

export const Actions = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 24px;
  margin-bottom: 16px;
`

export const ListItem = styled.div`
  min-height: 48px;
  &:hover {
    background-color: #f2fbfe;
  }
`

export const useStyles = makeStyles((theme) => ({
  label: {
    whiteSpa: 'nowrap',
    textOverflow: 'ellipsis'
  },
  formControl: {
    width: 'inherit'
  },
  hovered: {
    backgroundColor: 'rgba(0, 174, 239, 0.11)'
  },
  item: {
    paddingLeft: 24
  }
}))
