import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

const Modal = ({ show, onClose, children }) => (
  <Dialog
    open={show}
    onClose={onClose}
    data-testid="dinamic-form-modal"
    aria-labelledby="integration-modal"
    aria-describedby="integration-modal-description"
  >
    <DialogContent>{children}</DialogContent>
  </Dialog>
)

const { func, any, bool } = PropTypes

Modal.propTypes = {
  show: bool,
  onClose: func,
  children: any
}

export default withTranslation()(Modal)
