import { BASE_PATH } from '../../router'

const PublicRoutes = [
  BASE_PATH + 'login',
  BASE_PATH + 'sso',
  BASE_PATH + 'write',
  BASE_PATH + 'signup',
  BASE_PATH + 'health',
  BASE_PATH + 'recovery',
  BASE_PATH + 'horus/callback',
  BASE_PATH + 'horus/login'
]

export default PublicRoutes
