import { useEffect, useState } from 'react'

import useEventAnalytic from 'core/hooks/useEventAnalytic'
import GoogleAnalytics from 'core/constants/google-analytics'

import { Link, useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { remove } from 'localstorage-helpr'

import { useAppContext } from 'App'
import { useAuthContext } from 'modules/Authentication'

import { BASE_PATH } from 'router'

import Button from '@material-ui/core/Button'

import Loader from 'components/Loader'

import * as Pattern from 'core/validations/pattern'

import axios from 'core/api'
import Yup from 'core/validations/index'

import TextField from 'components/Form/Text/TextField'
import PasswordField from 'components/Form/Text/PasswordField'

import { STORE, TOKEN, USER } from 'core/constants/local-storage'

import Skin from '../Skin'

const Signup = ({ t }) => {
  const history = useHistory()

  const { notify } = useAppContext()
  const { handleSetUser, handleSetToken, handleSetStore } = useAuthContext()

  const [loading, setLoading] = useState(false)

  const { trackSignup } = useEventAnalytic()
  const { Actions, Category } = GoogleAnalytics

  const fields = {
    company: '',
    name: '',
    email: '',
    password: ''
  }

  const fieldsValidation = Yup.object().shape({
    company: Pattern.company,
    name: Pattern.name,
    email: Pattern.email,
    password: Pattern.password
  })

  const submitSign = (values, setSubmitting) => {
    setLoading(true)

    axios
      .post('signup/signup', {
        storeName: values.company,
        adminName: values.name,
        adminEmail: values.email,
        password: values.password,
        passwordConfirmation: values.password,
        agreedTerms: true
      })
      .then(({ data }) => {
        const { _admin, _store, _jwtToken } = data.Element.AuthenticationResult

        handleSetUser(_admin)
        handleSetStore(_store)
        handleSetToken(_jwtToken)

        trackSignup(
          Actions.CreateStore,
          Category.Interaction,
          _admin.IdYourviews,
          _store.Key,
          _admin.Email
        )

        history.push(BASE_PATH)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  useEffect(() => {
    remove(STORE)
    remove(USER)
    remove(TOKEN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Skin>
      <aside className="sign-form-area">
        <h2 className="sign-form-title">{t('signup.title')}</h2>

        {loading ? (
          <Loader />
        ) : (
          <section className="sign-form">
            <Formik
              initialValues={fields}
              onSubmit={(values, { setSubmitting }) =>
                submitSign(values, setSubmitting)
              }
              validationSchema={fieldsValidation}
            >
              {(propsForm) => {
                return (
                  <Form>
                    <Field
                      name="email"
                      component={TextField}
                      label={t('signup.form.emailLabel')}
                      placeholder={t('signup.form.emailDesc')}
                    />

                    <Field
                      name="password"
                      component={PasswordField}
                      label={t('signup.form.passwordLabel')}
                      placeholder={t('signup.form.passwordDesc')}
                    />

                    <Field
                      name="name"
                      component={TextField}
                      label={t('signup.form.nameLabel')}
                      placeholder={t('signup.form.nameDesc')}
                    />

                    <Field
                      name="company"
                      component={TextField}
                      label={t('signup.form.companyLabel')}
                      placeholder={t('signup.form.companyDesc')}
                    />

                    <div className="btn-sign-container">
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        className="btn-sign"
                        disabled={propsForm.isSubmitting}
                      >
                        {t('signup.form.submitBtn')}
                      </Button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </section>
        )}

        <p className="sign-redirect">
          {t('signup.redirect')}

          <Link to={BASE_PATH + 'login'}>{t('signup.redirectLink')}</Link>
        </p>
      </aside>
    </Skin>
  )
}

export default withTranslation()(Signup)
