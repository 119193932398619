import { useState } from 'react'
import PropTypes from 'prop-types'

import * as _ from 'lodash'

import { withTranslation } from 'react-i18next'
import { read } from 'localstorage-helpr'

import { ptBR, enUS, esES } from '@material-ui/core/locale'
import { createTheme, ThemeProvider } from '@material-ui/core'

import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableFooter from '@material-ui/core/TableFooter'
import MaterialTable from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'

import useStyles from './style'

function Table({ rows, t }) {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const currentLang = {
    pt: ptBR,
    en: enUS,
    es: esES
  }[read('i18nextLng')]

  return (
    <ThemeProvider theme={(theme) => createTheme(theme, currentLang)}>
      <TableContainer component={Paper} variant="outlined">
        <MaterialTable
          className={classes.table}
          aria-label="custom pagination table"
        >
          {rows && (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>{t('word-cloud.table.headers.text')}</TableCell>
                  <TableCell align="right">
                    {t('word-cloud.table.headers.value')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? _.sortBy(rows, ['text']).slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : _.sortBy(rows, ['text'])
                ).map((row) => (
                  <TableRow className={classes.row} key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.text}
                    </TableCell>
                    <TableCell style={{ width: 160 }} align="right">
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    page={page}
                    colSpan={3}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true
                    }}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: 'All', value: -1 }
                    ]}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}

          {!rows && 'Não há dados para visualização!'}
        </MaterialTable>
      </TableContainer>
    </ThemeProvider>
  )
}

MaterialTable.propTypes = {
  rows: PropTypes.array.isRequired
}

export default withTranslation()(Table)
