import { withTranslation } from 'react-i18next'

import Dialog from '../../../../components/Modal/Dialog'

const EditStatus = ({ active, close, changeStatus, user, t }) => (
  <Dialog
    show={active}
    onClose={() => close()}
    onCancel={() => close()}
    onAccept={() => changeStatus()}
  >
    <h3 className="modal-title">{t('admin.status.title')}</h3>
    <p className="modal-text">
      {t('admin.status.confirmation')}{' '}
      <strong>
        {user.Active ? t('admin.status.inactive') : t('admin.status.active')}
      </strong>{' '}
      {t('admin.status.user')}{' '}
      <strong>
        {user.Name} ({user.Email})
      </strong>
      ?
    </p>
  </Dialog>
)

export default withTranslation()(EditStatus)
