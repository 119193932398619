import './index.css'
import { withTranslation } from 'react-i18next'
import CustomCard from 'components/CustomCard'
import FunnelChart from './components/FunnelChart'
import FunnelInfo from './components/FunnelInfo'

const Funnel = ({ loading, data, currentDispatchTitle }) => {
  return (
    <CustomCard padding="0">
      <div className="conversion-email-funnel">
        <FunnelInfo
          loading={loading}
          data={data}
          currentDispatchTitle={currentDispatchTitle}
        />

        <FunnelChart
          loading={loading}
          data={[data.Sent, data.Opened, data.Clicked, data.Answered]}
          colors={['#014F6C', '#0B79D0', '#2196F3', '#64B6F7']}
        />
      </div>
    </CustomCard>
  )
}

export default withTranslation()(Funnel)
