import './index.css'
import { Skeleton } from '@material-ui/lab'

const TreeViewSkeleton = ({ treeHeight }) => {
  return (
    <div className="tree-skeleton">
      <div className="tree-skeleton-scale-wrapper">
        <Skeleton variant="rect" height={10} width="34%" />
      </div>
      <div
        className="tree-skeleton-chart-wrapper"
        style={{ height: treeHeight }}
      >
        <div>
          <Skeleton variant="rect" height="100%" width="30%" />
          <Skeleton variant="rect" height="100%" width="70%" />
        </div>
        <div>
          <Skeleton variant="rect" height="100%" width="80%" />
          <Skeleton variant="rect" height="100%" width="20%" />
        </div>
      </div>
    </div>
  )
}

export default TreeViewSkeleton
