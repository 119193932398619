import { getIn } from 'formik'
import { makeStyles } from '@material-ui/core/styles'

import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles(() => ({
  label: {
    width: '100%'
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    maxWidth: '60px'
  }
}))

const NumberField = ({ field, form: { touched, errors }, ...props }) => (
  <FormControl
    error={!!(getIn(touched, field.name) && getIn(errors, field.name))}
    className="form-field text-field"
  >
    <div className={useStyles().inputContainer}>
      <Input
        {...field}
        {...props}
        type="number"
        id={field.name}
        min={props.min}
        className={useStyles().input}
        inputProps={{ min: props?.min }}
      />

      <span>{props.measurement}</span>
    </div>

    <InputLabel htmlFor={field.name} className={useStyles().label}>
      {props.label}
    </InputLabel>

    {!!(
      field.name &&
      getIn(touched, field.name) &&
      getIn(errors, field.name)
    ) && <FormHelperText>{getIn(errors, field.name)}</FormHelperText>}
  </FormControl>
)

export default NumberField
