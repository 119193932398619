import './index.css'
import { Tooltip } from '@material-ui/core'
import React, { useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import { format } from 'date-fns'
import i18n from 'i18next'

const EvolutionPercentage = ({
  t,
  evolution = '0',
  evolutionDownColor = '#EF3E39',
  evolutionStableColor = '#9E9E9E',
  evolutionUpColor = '#60BB46',
  previousPeriodStart,
  previousPeriodEnd,
  hideValue = false
}) => {
  const { language } = i18n

  const evolutionAsString = useMemo(() => {
    return evolution.toString()
  }, [evolution])

  const evolutionDown = useMemo(() => {
    return evolutionAsString.includes('-')
  }, [evolutionAsString])

  const evolutionStable = useMemo(() => {
    return (
      !evolutionDown && parseFloat(evolutionAsString.replace(',', '.')) === 0
    )
  }, [evolutionAsString, evolutionDown])

  const evolutionUp = useMemo(() => {
    return !evolutionDown && !evolutionStable
  }, [evolutionDown, evolutionStable])

  return (
    <div
      className="evolution"
      style={
        !hideValue
          ? {
              color: evolutionDown
                ? evolutionDownColor
                : evolutionStable
                ? evolutionStableColor
                : evolutionUpColor
            }
          : {}
      }
    >
      <Tooltip
        title={
          !previousPeriodStart || !previousPeriodEnd
            ? t('dashboard.evolution')
            : t('dashboard.evolution2', {
                previousPeriodStart: format(
                  new Date(previousPeriodStart),
                  language === 'en' || language === 'en-US'
                    ? 'MM/dd/yyyy'
                    : 'dd/MM/yyyy'
                ),
                previousPeriodEnd: format(
                  new Date(previousPeriodEnd),
                  language === 'en' || language === 'en-US'
                    ? 'MM/dd/yyyy'
                    : 'dd/MM/yyyy'
                )
              })
        }
        children={
          <div style={{ display: 'flex' }}>
            {!hideValue && (
              <div className="evolution-icon">
                {evolutionDown && <div>↓</div>}
                {evolutionStable && <div></div>}
                {evolutionUp && <div>↑</div>}
              </div>
            )}

            <div
              className="evolution-value"
              style={{ fontWeight: 'bold', fontSize: 12 }}
            >
              {!hideValue ? evolutionAsString.replace('-', '') + `%` : '-'}
            </div>
          </div>
        }
      ></Tooltip>
    </div>
  )
}

export default withTranslation()(EvolutionPercentage)
