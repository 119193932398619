/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { remove } from 'localstorage-helpr'

import { useAuthContext } from 'modules/Authentication'

import Popover from '@material-ui/core/Popover'

import Grid from '@material-ui/core/Grid'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import IconButton from '@material-ui/core/IconButton'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import SearchIcon from '@material-ui/icons/Search'

import useEventAnalytic from 'core/hooks/useEventAnalytic'

import { BASE_PATH } from 'router'

import { STORE, TOKEN, USER } from 'core/constants/local-storage'
import GoogleAnalytics from 'core/constants/google-analytics'

import LanguageSelector from '../LanguageSelector'

import logo from 'assets/img/logo.svg'

import useStyles from './style'
import { InputAdornment, TextField } from '@material-ui/core'
import getData from 'core/functions/get-data.function'
import postData from 'core/functions/post-data.function'
import DropDown from './dropDown'
import { HORUS_LOGOUT_URL } from 'core/constants/horus'

const Navbar = () => {
  const {
    user,
    store,
    storeList,
    handleSetStoreList,
    handleSetToken,
    handleSetStore
  } = useAuthContext()
  const classes = useStyles()
  const history = useHistory()
  const { track } = useEventAnalytic()

  const { Actions, Category } = GoogleAnalytics

  const handleLogout = () => {
    remove(USER)
    remove(STORE)
    remove(TOKEN)

    track(Actions.Logout, Category.Interaction)
    window.location.href = HORUS_LOGOUT_URL
  }

  const [storeFilter, setStoreFilter] = useState(false)

  const openCloseStores = () => {
    if (storeFilter) setStoreFilter(false)
    if (!storeFilter) setStoreFilter(true)
  }

  const closeStores = () => setStoreFilter(false)
  const [filter, setFilter] = useState('')

  const [loginData, setLoginData] = useState()

  const listPublic = [
    'sso',
    'login',
    'write',
    'signup',
    'health',
    'recovery',
    'nps-wizard',
    'WidgetScript',
    'CampaignSuccess',
    'campaign/new',
    'campaign/email',
    'campaign/form',
    'campaign/widget',
    'campaign/import',
    'campaign/dispatch',
    'campaign/review',
    'reports/evolution/fullscreen',
    'horus/callback'
  ]

  let aux = 0

  useEffect(() => {
    listPublic.map((item) => {
      if (window.location.pathname.includes(item)) aux = aux + 1
    })
    if (aux === 0 || (aux === '0' && storeList !== '')) {
      setTimeout(() => {
        getData(
          'store/getpagedrelatedadmin?SearchTerm=' + filter,
          handleSetStoreList,
          false
        )
      }, 100)
    }
  }, [window.location.pathname, filter])

  function sendURL(path) {
    return history.push(path)
  }

  function checkLocalStorage() {
    const s = localStorage.getItem('__s_')
    return s && s !== '[object Object]' ? JSON.parse(s) : ''
  }

  function refreshPage() {
    if (window.location.pathname !== BASE_PATH) {
      sendURL(BASE_PATH)
    }
    window.location.reload()
  }

  function resetUser(key) {
    if (store !== null) {
      postData({
        endpoint: 'authentication/adminchangeloggedstore',
        data: { StoreKey: key },
        setData: (response) => {
          setLoginData(response)
          refreshPage()
        }
      })
    }
  }

  useEffect(() => {
    if (loginData) {
      localStorage.setItem('__s_', loginData?._store)
      handleSetStore(loginData?._store)
      handleSetToken(loginData?._jwtToken)
      refreshPage()
      closeStores()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData])

  const onStorageUpdate = useCallback(() => {
    const logged = checkLocalStorage()
    if (!logged) {
      return
    }
    if (store?.Name !== logged?.Name) {
      refreshPage()
    }
  })

  useEffect(() => {
    window.addEventListener('focus', onStorageUpdate)
    return () => {
      window.addEventListener('focus', onStorageUpdate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'dropdown-menu' : undefined

  return (
    <Grid
      container
      alignItems="center"
      data-testid="app-navbar"
      className={classes.root}
    >
      <div className={classes.items}>
        <div className={classes.item}>
          <img
            className={classes.logo}
            data-testid="app-logo"
            src={logo}
            alt="Yourviews logo"
          />
          <div className={classes.title}>Voices</div>
        </div>
        <div className={classes.item}>
          <LanguageSelector
            data-testid="lang-selector"
            className={classes.margin}
          />
          <div data-testid="app-store-name" className={classes.label}>
            <span data-testid="app-user-name" className={classes.userName}>
              {user?.Name}
            </span>
            <br />
            <span
              onClick={openCloseStores}
              data-testid="app-user-name-list"
              className={classes.storeName}
            >
              {store?.Name}
              <span className={classes.arrowIcon}>
                {!storeFilter && (
                  <ArrowDropDownIcon
                    className={classes.iconDrop}
                    onClick={openCloseStores}
                  />
                )}
                {storeFilter && (
                  <ArrowDropUpIcon
                    className={classes.iconDrop}
                    onClick={openCloseStores}
                  />
                )}
              </span>
            </span>
            {storeFilter && (
              <div className={classes.dropDown}>
                <TextField
                  id="store-field"
                  label="Lojas"
                  variant="outlined"
                  fullWidth
                  // value={filter}
                  onChange={(event) =>
                    setTimeout(() => {
                      setFilter(event.target.value)
                    }, 1000)
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                  style={{ marginBottom: '12px' }}
                />
                <ul className={classes.ul} data-testid="store-list">
                  {
                    /* {storeList?.Items?.filter( */
                    // (store) => store.Name.includes(filter) || filter === '')
                  }
                  {storeList?.Items?.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => resetUser(item.Key)}
                      className={classes.li}
                    >
                      {item?.Name} - {item?.IdYourviews}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              variant="contained"
              className={classes.margin}
              style={{
                boxShadow: 'none',
                margin: '0px',
                padding: '0px',
                width: '48px',
                height: '48px'
              }}
              onClick={handleClick}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20 18C20 19.1046 19.1046 20 18 20C16.8954 20 16 19.1046 16 18C16 16.8954 16.8954 16 18 16C19.1046 16 20 16.8954 20 18ZM20 24C20 25.1046 19.1046 26 18 26C16.8954 26 16 25.1046 16 24C16 22.8954 16.8954 22 18 22C19.1046 22 20 22.8954 20 24ZM18 32C19.1046 32 20 31.1046 20 30C20 28.8954 19.1046 28 18 28C16.8954 28 16 28.8954 16 30C16 31.1046 16.8954 32 18 32ZM26 18C26 19.1046 25.1046 20 24 20C22.8954 20 22 19.1046 22 18C22 16.8954 22.8954 16 24 16C25.1046 16 26 16.8954 26 18ZM24 26C25.1046 26 26 25.1046 26 24C26 22.8954 25.1046 22 24 22C22.8954 22 22 22.8954 22 24C22 25.1046 22.8954 26 24 26ZM26 30C26 31.1046 25.1046 32 24 32C22.8954 32 22 31.1046 22 30C22 28.8954 22.8954 28 24 28C25.1046 28 26 28.8954 26 30ZM30 20C31.1046 20 32 19.1046 32 18C32 16.8954 31.1046 16 30 16C28.8954 16 28 16.8954 28 18C28 19.1046 28.8954 20 30 20ZM32 24C32 25.1046 31.1046 26 30 26C28.8954 26 28 25.1046 28 24C28 22.8954 28.8954 22 30 22C31.1046 22 32 22.8954 32 24ZM30 32C31.1046 32 32 31.1046 32 30C32 28.8954 31.1046 28 30 28C28.8954 28 28 28.8954 28 30C28 31.1046 28.8954 32 30 32Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <DropDown />
            </Popover>
          </div>

          <div className={classes.margin}>
            <IconButton
              onClick={handleLogout}
              data-testid="app-logout"
              className={classes.exitBtn}
              aria-label="exit icon button"
            >
              <ExitToAppIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default withTranslation()(Navbar)
