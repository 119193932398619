import '../../../style/route/api/main.css'
import { withTranslation } from 'react-i18next'
import RoundedButton from '../../../components/Button'
import noData from 'assets/img/no-data.svg'

const EmptyState = ({ onAddNewUser, t }) => {
  return (
    <div className="api-no-data-outer-wrapper">
      <div className="api-no-data-inner-wrapper">
        <img
          src={noData}
          alt="Facebook logo"
          style={{ marginRight: '40px' }}
          height={258}
        />
        <div className="api-no-data-title">{t('api.empty.title')}</div>
        <div className="api-no-data-content">{t('api.empty.content')}</div>
        <div className="api-no-data-actions">
          <RoundedButton
            whiteTextLabel
            type="add"
            size="large"
            variant="contained"
            onClick={onAddNewUser}
            text={t('api.newUserDialog.title')}
          />
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(EmptyState)
