import styled from 'styled-components'

import { ColorsMap } from './dictionaries/colors.dictionary'

export const List = styled.ul`
  display: flex;
  justify-content: center;
`

export const ListItem = styled.li`
  float: left;
  border: 1px solid black;
  margin: 5px;
  padding: 10px;
  width: 15px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  background-color: ${({ color, index }) => `${ColorsMap[color][index]}`};
  border-color: ${({ color, index }) => `${ColorsMap[color][index]}`};
  color: #fff;
`
