import { useState } from 'react'
import { withTranslation } from 'react-i18next'

import { Formik, Form, Field } from 'formik'

import { WarningOutlined } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

import Yup from 'core/validations/index'

import TextField from 'components/Form/Text/TextField'
import Checkbox from 'components/Form/Box/Checkbox'
import Visible from 'components/Form/Box/Visible'

const NPSDefaultFieldsEditor = ({ data, onChange, t }) => {
  const [showCommentPromoter, toogleShowCommentPromoter] = useState(
    data?.visiblePromoter
  )
  const [showCommentNeutral, toogleShowCommentNeutral] = useState(
    data?.visibleNeutral
  )
  const [showCommentDetractor, toogleShowCommentDetractor] = useState(
    data?.visibleDetractor
  )

  const fields = {
    Title: data.nps || '',
    CommentTitlePromoter: data.promoter || '',
    CommentTitleNeutral: data.neutral || '',
    CommentTitleDetractor: data.detractor || '',
    CommentRequiredPromoter: data?.requiredPromoter,
    CommentRequiredNeutral: data?.requiredNeutral,
    CommentRequiredDetractor: data?.requiredDetractor,
    CommentVisiblePromoter: data?.visiblePromoter,
    CommentVisibleNeutral: data?.visibleNeutral,
    CommentVisibleDetractor: data?.visibleDetractor
  }

  const fieldsValidation = Yup.object().shape({
    Title: Yup.string().max(240).required(),
    CommentTitlePromoter: Yup.string().max(240).required(),
    CommentTitleNeutral: Yup.string().max(240).required(),
    CommentTitleDetractor: Yup.string().max(240).required(),
    CommentRequiredPromoter: Yup.bool(),
    CommentRequiredNeutral: Yup.bool(),
    CommentRequiredDetractor: Yup.bool()
  })

  const dataChanged = (values) => {
    const data = handleDataVisible(values)
    onChange(data)
  }

  const handleDataVisible = (values) => {
    Object.entries(values).forEach((field) => {
      const [key, value] = field

      switch (key) {
        case 'CommentVisibleDetractor':
          toogleShowCommentDetractor(value)
          !value && (values['CommentRequiredDetractor'] = value)
          break
        case 'CommentVisibleNeutral':
          toogleShowCommentNeutral(value)
          !value && (values['CommentRequiredNeutral'] = value)
          break
        case 'CommentVisiblePromoter':
          toogleShowCommentPromoter(value)
          !value && (values['CommentRequiredPromoter'] = value)
          break
        default:
          return
      }
    })

    return values
  }

  return (
    <div className="nps-default-fields-editor">
      <Formik
        initialValues={fields}
        validateOnBlur={false}
        validationSchema={fieldsValidation}
        validate={(values) => dataChanged(values)}
        enableReinitialize={true}
      >
        <Form>
          <div className="form-editor-item">
            <Field
              id="Title"
              name="Title"
              component={TextField}
              placeholder={t('formEditor.nps.npsTitleDesc')}
            />

            <div className="nps-fake-field">
              {[...Array(11).keys()].map((item) => (
                <div className="nps-fake-field-item" key={item}>
                  <i className="nps-fake-field-circle"></i>
                  <span className="nps-fake-field-value">{item}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="form-editor-item nopadding">
            <div className="form-editor-line form-editor-line-detractor">
              <div class="form-editor-fields">
                <Field
                  id="CommentTitleDetractor"
                  name="CommentTitleDetractor"
                  component={TextField}
                  label={t('formEditor.nps.detractorLabel')}
                  placeholder={t('formEditor.nps.detractorDesc')}
                />
                <div className="form-editor-line-actions">
                  <Field
                    id="CommentRequiredDetractor"
                    name="CommentRequiredDetractor"
                    label={t('formEditor.requiredField')}
                    disabledtooltip={
                      !showCommentDetractor
                        ? t('formEditor.field.requiredInfo')
                        : ''
                    }
                    component={Checkbox}
                    disabled={!showCommentDetractor}
                  />
                  <Field
                    id="CommentVisibleDetractor"
                    name="CommentVisibleDetractor"
                    label="toggle comment detractor visibility"
                    tooltip={t('formEditor.field.visibleInfo')}
                    component={Visible}
                  />
                </div>
              </div>
              {!showCommentDetractor && (
                <div class="form-editor-fields-info">
                  <WarningOutlined style={{ color: '#F7A723' }} />
                  <Typography variant="body2" style={{ marginTop: '3px', color: '#63430e' }}>
                  {t('formEditor.field.visibleAlert')}
                  </Typography>
                </div>
              )}
            </div>

            <div className="form-editor-line form-editor-line-neutral">
              <div class="form-editor-fields">
                <Field
                  id="CommentTitleNeutral"
                  name="CommentTitleNeutral"
                  component={TextField}
                  label={t('formEditor.nps.neutralLabel')}
                  placeholder={t('formEditor.nps.neutralDesc')}
                />
                <div className="form-editor-line-actions">
                  <Field
                    id="CommentRequiredNeutral"
                    name="CommentRequiredNeutral"
                    label={t('formEditor.requiredField')}
                    disabledtooltip={
                      !showCommentNeutral
                        ? t('formEditor.field.requiredInfo')
                        : ''
                    }
                    component={Checkbox}
                    disabled={!showCommentNeutral}
                  />
                  <Field
                    id="CommentVisibleNeutral"
                    name="CommentVisibleNeutral"
                    label="toggle comment neutral visibility"
                    tooltip={t('formEditor.field.visibleInfo')}
                    component={Visible}
                  />
                </div>
              </div>
              {!showCommentNeutral && (
                <div class="form-editor-fields-info">
                  <WarningOutlined style={{ color: '#F7A723' }} />
                  <Typography variant="body2" style={{ marginTop: '3px', color: '#63430e' }}>
                  {t('formEditor.field.visibleAlert')}
                  </Typography>
                </div>
              )}
            </div>

            <div className="form-editor-line form-editor-line-promoter">
              <div class="form-editor-fields">
                <Field
                  id="CommentTitlePromoter"
                  name="CommentTitlePromoter"
                  component={TextField}
                  label={t('formEditor.nps.promoterLabel')}
                  placeholder={t('formEditor.nps.promoterDesc')}
                />
                <div className="form-editor-line-actions">
                  <Field
                    id="CommentRequiredPromoter"
                    name="CommentRequiredPromoter"
                    label={t('formEditor.requiredField')}
                    disabledtooltip={
                      !showCommentPromoter
                        ? t('formEditor.field.requiredInfo')
                        : ''
                    }
                    component={Checkbox}
                    disabled={!showCommentPromoter}
                  />
                  <Field
                    id="CommentVisiblePromoter"
                    name="CommentVisiblePromoter"
                    label="toggle comment promoter visibility"
                    tooltip={t('formEditor.field.visibleInfo')}
                    component={Visible}
                  />
                </div>
              </div>
              {!showCommentPromoter && (
                <div class="form-editor-fields-info">
                  <WarningOutlined style={{ color: '#F7A723' }} />
                  <Typography variant="body2" style={{ marginTop: '3px', color: '#63430e' }}>
                  {t('formEditor.field.visibleAlert')}
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default withTranslation()(NPSDefaultFieldsEditor)
