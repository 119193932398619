import './index.css'
import Loader from 'components/Loader'

const LoadingOverlay = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="loading-overlay">
        <Loader noMargin />
      </div>
    )
  )
}

export default LoadingOverlay
