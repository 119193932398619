import { URL_API } from 'core/constants/api'

const createLink = (storeKey, campaignKey) => {
  if (campaignKey && storeKey) {
    return (
      URL_API +
      'api/pub/DirectLink/Redirect?YVStoreKey=' +
      storeKey +
      '&CampaignKey=' +
      campaignKey +
      '&Person.Anonymous=true'
    )
  }

  return URL_API + 'abc-def?storekey=abbbcc'
}
export default createLink
