import PropTypes from 'prop-types'

import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import processingLogFilter from '../../functions/processing-log-filter'
import processingLogMapper from '../../functions/processing-log-mapper'
import makeRowString from '../../functions/make-row-string'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { makeStyles } from '@material-ui/core/styles'

import _ from 'lodash'

const useStyles = makeStyles({
  table: {
    minWidth: 350
  }
})

const ProcessingLog = ({ logs, t }) => {
  const classes = useStyles()

  const data = processingLogFilter(logs)

  const rows = [
    processingLogMapper(data, 0, 'warning'),
    processingLogMapper(data, 2, 'error'),
    processingLogMapper(data, 3, 'majorError')
  ]

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="center">
              {t('import.upload.processingLog.column')}
            </TableCell>
            <TableCell align="center">
              {t('import.upload.processingLog.row')}
            </TableCell>
            <TableCell align="center">
              {t('import.upload.processingLog.note')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.compact(rows).map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.icon}
              </TableCell>
              <TableCell align="center">{makeRowString(row.col)}</TableCell>
              <TableCell align="center">{makeRowString(row.row)}</TableCell>
              <TableCell align="center">{row.message}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

ProcessingLog.propTypes = {
  logs: PropTypes.array.isRequired
}

export default withTranslation()(withRouter(ProcessingLog))
