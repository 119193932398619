import { useState, useEffect, useMemo } from 'react'

import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'
import { useHistory } from 'react-router'
import { useAppContext } from 'App'

import { BASE_PATH } from 'router'
import { FIELD_TYPE } from 'core/enums/field-type'
import Yup from 'core/validations/index'
import axios from 'core/api'
import { uuid } from 'core/helpers/index'
import TextField from 'components/Form/Text/TextField'
import Loader from 'components/Loader'
import Button from '@material-ui/core/Button'
import NPSDefaultFieldsEditor from '../NPSDefaultFieldsEditor'
import CustomFieldsEditor from '../CustomFieldsEditor'
import Header from 'components/Header'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import queryString from 'query-string'
import { QS_TYPES } from 'core/constants/query-strings'

const FormEditor = ({
  save,
  cancel,
  noTitle,
  noButtons,
  location,
  t,
  match
}) => {
  const history = useHistory()
  const { notify } = useAppContext()

  const [form, setForm] = useState({})

  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState([])
  const [conditionalOptions, setConditionalOptions] = useState([])
  const [showCustomFields, setShowCustomFields] = useState(false)

  const getConditional = (fields) => {
    let field = fields.filter(
      (field) =>
        field.Type === FIELD_TYPE.multiSelect ||
        field.Type === FIELD_TYPE.singleSelect
    )

    let conditionals = []
    field.forEach((cond) => {
      const OrderKey = cond.OrderKey
      cond.FormFieldValues.forEach((value) => {
        conditionals.push({
          id: value.Key,
          name: `${cond.Title} ${value.Value}`,
          OrderKey
        })
      })
    })

    return conditionals
  }

  useEffect(() => {
    if (match.params.item === 'new') {
      setShowCustomFields(true)
      return
    }

    if (!location.state) {
      history.push(BASE_PATH + 'form')
      return
    }

    location.state.FormFields = location.state.FormFields.map((item) => {
      item.OrderKey = uuid.generator()
      return item
    })

    setForm(location.state)
    setConditionalOptions(getConditional(location.state.FormFields))
    setShowCustomFields(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkOutForErrors = (data) => {
    let newErrors = []
    data.ErrorList.forEach((item) => {
      if (item.Field === 'Title') newErrors.push(t('formEditor.errors.1'))

      if (item.Field.indexOf('CommentTitle') > -1)
        newErrors.push(t('formEditor.errors.2'))

      if (item.Field.indexOf('FormFields') > -1) {
        let idxs = item.Field.match(/(\[).+?(\])/g)

        if (idxs === null) {
          newErrors.push(t('formEditor.errors.3'))
          return
        }

        if (idxs !== null && idxs.length > 1)
          newErrors.push(`${t('formEditor.errors.4.0')} Nº${
            parseInt(idxs[0].replace('[', '').replace(']', '')) + 1
          } 
            ${t('formEditor.errors.4.1')} Nº${parseInt(idxs[1]) + 1}`)

        if (idxs !== null && item.Field.indexOf('IdDimension') > -1)
          newErrors.push(`${t('formEditor.errors.4.0')} Nº${
            parseInt(idxs[0].replace('[', '').replace(']', '')) + 1
          } 
            ${t('formEditor.errors.4.2')}`)

        if (
          idxs !== null &&
          item.Field.indexOf('Title') > -1 &&
          idxs.length === 1
        )
          newErrors.push(`${t('formEditor.errors.4.0')} Nº${
            parseInt(idxs[0].replace('[', '').replace(']', '')) + 1
          } 
            ${t('formEditor.errors.4.3')}`)
      }
    })

    if (newErrors.length > 0) {
      notify(t('formEditor.errors.0'), 'error')
    }

    setErrors(newErrors)
  }

  const saveForm = (values, setSubmitting) => {
    let toSaveForm = form

    toSaveForm.FormFields = form.FormFields.map((field, fidx) => {
      field.OrderIndex = fidx + 1
      field.NpsClassificationDependancy = field.NpsClassificationDependancy
        ? field.NpsClassificationDependancy.map((item) => parseInt(item))
        : []
      field.FormFieldValues = field.FormFieldValues.map((value, vidx) => {
        value.OrderIndex = vidx + 1
        value.Type = field.Type
        return value
      })
      return field
    })

    toSaveForm.Name = values.Name

    setLoading(true)

    axios
      .post('form/insertupdate', toSaveForm)
      .then((res) => {
        if (res.data.HasErrors) {
          notify(res.data.ErrorList[0].Error, 'error')
          checkOutForErrors(res.data)
          return
        } else {
          notify(t('success.save'), 'success')

          if (save) save(res?.data?.Element)
          else history.push(BASE_PATH + 'form')
        }
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  const NPSdataChanged = (value) => {
    let newForm = form

    for (let key in value) newForm[key] = value[key]

    setForm(newForm)
  }

  const CustomFieldsDataChanged = (value, idx) => {
    let newForm = form
    newForm.FormFields[idx] = value

    setForm(newForm)
    setConditionalOptions(getConditional(newForm.FormFields))
  }

  const CustomAllFieldsDataChanged = (value) => {
    let newForm = form
    newForm.FormFields = value

    setForm(newForm)
    setConditionalOptions(getConditional(newForm.FormFields))
  }

  const handleCancel = () => {
    if (cancel) cancel()
    else history.push(BASE_PATH + 'form')
  }

  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  useEffect(() => {
    if (!location.state) {
      if (currentSearch[QS_TYPES] === NPS_CSAT_TAB.nps.typeParam) {
        setForm({
          Name: t('formEditor.default.name'),
          Title: t('formEditor.default.title'),
          CommentTitlePromoter: t('formEditor.default.commentTitle'),
          CommentTitleNeutral: t('formEditor.default.commentTitle'),
          CommentTitleDetractor: t('formEditor.default.commentTitle'),
          CommentRequiredPromoter: false,
          CommentRequiredNeutral: false,
          CommentRequiredDetractor: false,
          CommentVisiblePromoter: true,
          CommentVisibleNeutral: true,
          CommentVisibleDetractor: true,
          Active: true,
          FormFields: [],
          type: NPS_CSAT_TAB.nps.typeValue
        })
      }
      if (currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam) {
        setForm({
          Name: t('formEditor.default.name'),
          Title: t('formEditor.default.title'),
          CommentTitlePromoter: t('formEditor.default.commentTitle'),
          CommentTitleNeutral: t('formEditor.default.commentTitle'),
          CommentTitleDetractor: t('formEditor.default.commentTitle'),
          CommentRequiredPromoter: false,
          CommentRequiredNeutral: false,
          CommentRequiredDetractor: false,
          CommentVisiblePromoter: true,
          CommentVisibleNeutral: true,
          CommentVisibleDetractor: true,
          Active: true,
          FormFields: [],
          type: NPS_CSAT_TAB.csat.typeValue
        })
      }
    }
  }, [currentSearch, location.state, t])

  return (
    <div className="form-editor white-form">
      {!noTitle && (
        <Header
          title={
            match.params.item === 'new'
              ? t('formEditor.createTitle')
              : t('formEditor.editTitle')
          }
        />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={{
              Name: form.Name
            }}
            enableReinitialize
            onSubmit={(values, { setSubmitting }) => {
              saveForm(values, setSubmitting)
            }}
            validationSchema={Yup.object().shape({
              Name: Yup.string().max(80).required()
            })}
          >
            {(propsForm) => {
              return (
                <Form className="form-editor-info">
                  <Field
                    id="name"
                    name="Name"
                    label={t('formEditor.nameLabel')}
                    component={TextField}
                    placeholder={t('formEditor.nameDesc')}
                  />

                  <div style={{ marginBottom: 20 }}>
                    {!noButtons && (
                      <>
                        <Button
                          id="saveBtn"
                          type="submit"
                          color="primary"
                          variant="contained"
                          disabled={propsForm.isSubmitting}
                        >
                          {t('formEditor.saveBtn')}
                        </Button>

                        <Button
                          id="cancelBtn"
                          type="button"
                          variant="contained"
                          disabled={propsForm.isSubmitting}
                          onClick={handleCancel}
                        >
                          {t('formEditor.cancelBtn')}
                        </Button>
                      </>
                    )}
                  </div>
                </Form>
              )
            }}
          </Formik>

          <section className="form-editor-body">
            {errors.map((error, idx) => (
              <p className="form-editor-error" key={idx}>
                {error}
              </p>
            ))}

            {showCustomFields &&
              currentSearch[QS_TYPES] === NPS_CSAT_TAB.nps.typeParam && (
                <NPSDefaultFieldsEditor
                  onChange={NPSdataChanged}
                  data={{
                    nps: form.Title,
                    promoter: form.CommentTitlePromoter,
                    neutral: form.CommentTitleNeutral,
                    detractor: form.CommentTitleDetractor,
                    requiredPromoter: form.CommentRequiredPromoter,
                    requiredNeutral: form.CommentRequiredNeutral,
                    requiredDetractor: form.CommentRequiredDetractor,
                    visiblePromoter: form.CommentVisiblePromoter,
                    visibleNeutral: form.CommentVisibleNeutral,
                    visibleDetractor: form.CommentVisibleDetractor
                  }}
                />
              )}

            {showCustomFields && (
              <CustomFieldsEditor
                fields={form.FormFields}
                conditionalOptions={conditionalOptions}
                onChange={CustomFieldsDataChanged}
                onChangeAll={CustomAllFieldsDataChanged}
                location={location}
                match={match}
              />
            )}
          </section>
        </>
      )}
    </div>
  )
}

export default withTranslation()(FormEditor)
