import { useState, useEffect, useMemo } from 'react'
import { withTranslation } from 'react-i18next'
import axios from 'core/api'
import queryString from 'query-string'
import { QS_CAMPAIGN, QS_CAMPAIGN_LIST } from 'core/constants/query-strings'
import { campaign } from 'core/helpers'
import Dropdown from '../Form/Option/Dropdown'
import { useHistory, useLocation } from 'react-router-dom'

const campaignQsParams = [QS_CAMPAIGN_LIST, QS_CAMPAIGN]

const DropdownCampaign = ({
  className,
  campaignAllSelected,
  hideAllOption,
  type,
  onSelectedChange,
  onLoadingChange,
  t
}) => {
  const [campaigns, setCampaigns] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const location = useLocation()
  const history = useHistory()

  const filteredCampaigns = useMemo(() => {
    if (typeof type === 'undefined') {
      return campaigns
    }
    return campaigns.filter((item) => item.Type === type)
  }, [campaigns, type])

  useEffect(() => {
    let isMounted = true
    setLoading(true)

    axios
      .get('campaign/getall')
      .then((res) => {
        if (!isMounted) return
        setCampaigns(res.data)
      })
      .catch((error) => {
        if (!isMounted) return
        setError(true)
      })
      .finally(() => {
        if (!isMounted) return
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      isMounted = false
    }
  }, [])

  const currentCampaignId = useMemo(() => {
    return queryString.parse(location.search)[QS_CAMPAIGN]
  }, [location.search])

  const selectedCampaign = useMemo(() => {
    return campaigns.find(
      (c) => c.IdYourviews?.toString() === currentCampaignId?.toString()
    )
  }, [campaigns, currentCampaignId])

  const campaignDropDown = useMemo(() => {
    return campaign.toDropdown(
      filteredCampaigns,
      !hideAllOption ? t('component.dropdown.campaignAll') : ''
    )
  }, [filteredCampaigns, hideAllOption, t])

  useEffect(() => {
    if (onSelectedChange) {
      onSelectedChange(selectedCampaign)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCampaign])

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(loading)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  //CLEAR SELECTOR IF CAMPAIGN TYPE CHANGES
  const [lastCampaignType, setLastCampaignType] = useState(type)
  const [dropDownKey, setDropDownKey] = useState(1)
  useEffect(() => {
    setLastCampaignType(type)

    if (type !== lastCampaignType) {
      const currentSearchObj = queryString.parse(location.search)
      campaignQsParams.forEach((param) => {
        delete currentSearchObj[param]
      })
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(currentSearchObj)
      })
      setDropDownKey(dropDownKey + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    !error && (
      <div key={dropDownKey} data-testid="dropdown-campaign">
        <Dropdown
          value={currentCampaignId}
          className={className}
          campaignAllSelected={campaignAllSelected}
          loading={loading}
          name={t('component.dropdown.campaign')}
          items={campaignDropDown}
          qs={campaignQsParams}
        />
      </div>
    )
  )
}

export default withTranslation()(DropdownCampaign)
