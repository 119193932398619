import { useState } from 'react'
import PropTypes from 'prop-types'

import makeId from '../../functions/makeId'

import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Boolean = ({ value, item, onChange, name, label, id }) => {
  const [state, setState] = useState(value === 'true')
  const handleChange = ({ target }) => {
    setState(target.checked)
    onChange(target, item)
  }

  return (
    <>
      <FormControlLabel
        label={name}
        control={
          <Switch
            size="small"
            checked={state}
            name={name}
            label={label}
            onChange={handleChange}
            id={makeId(id, name)}
            data-testid="dinamic-form-boolean"
          />
        }
      />
    </>
  )
}

const { string, number, func, oneOfType } = PropTypes

Boolean.propTypes = {
  id: number,
  name: string,
  label: string,
  onChange: func,
  value: oneOfType([string, number])
}

Boolean.defaultProps = {
  value: '',
  name: ''
}

export default Boolean
