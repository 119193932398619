export const makeModalMessage = (message, item, warning) => (
  <div>
    <p>
      {message.start}{' '}
      <strong>
        {item &&
          `${item.name ?? ''}${item.name ? ': ' : ''} ${item.value ?? ''}`}
      </strong>{' '}
      {message.end}
    </p>
    <br />
    <p>
      <strong>{warning}</strong>
    </p>
  </div>
)
