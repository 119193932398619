import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left'
  },
  label: {
    fontSize: 14,
    color: '#6D7B89'
  }
}))

export default useStyles
