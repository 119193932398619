import { useEffect } from 'react'

import eventBus from 'core/events'

/**
 * Register an listener to execute a callback after dispatch
 *
 * @param {string | string[]} event Event to be listened (can be a list of events (strings))
 * @param {Function | [Function]} callback Function to be executed after event dispatched (can be a list of functions)
 */
const useExecuteCallbackOnEvent = (event, callback) => {
  useEffect(() => {
    try {
      if (Array.isArray(event)) {
        event.forEach((x, index) => {
          eventBus.on(x, callback[index])
        })
      } else {
        eventBus.on(event, callback)
      }
    } catch (error) {
      return error
    }

    return () => {
      if (Array.isArray(event)) {
        event.forEach((x) => {
          eventBus.remove(x)
        })
      } else {
        eventBus.remove(event)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useExecuteCallbackOnEvent
