import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
`

export const Line = styled.div`
  width: 33.33%;
  height: 8px;
  left: 0px;
  top: 19px;
  border-radius: 0px;
  background: linear-gradient(
    90deg,
    #ef3e39 9.37%,
    #f7a723 48.96%,
    #60bb46 84.9%
  );
  float: right;
  border: 1px #a4a4a4 solid;
`
export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  position: relative;
  top: 5px;
`

export const LineText = styled.div`
  width: 33.33%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`
export const Quadrant = styled.div`
  height: 390px;
  width: 865px;
  margin: 0 auto;
  background-image: #000000;
  background-image: radial-gradient(
      at 0% 0%,
      hsla(2, 86%, 59%, 1) 0,
      transparent 70%
    ),
    radial-gradient(at 0% 99%, hsla(38, 93%, 56%, 1) 0, transparent 70%),
    radial-gradient(at 100% 0%, hsla(107, 47%, 51%, 1) 0, transparent 70%),
    radial-gradient(at 100% 100%, hsla(197, 100%, 48%, 1) 0, transparent 70%);
`
