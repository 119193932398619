import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
    minWidth: 150
  },
  media: {
    height: 140
  },
  activeLabel: {
    fontSize: 13
  },
  errorMessage: {
    textAlign: 'left',
    color: '#f44336',
    fontSize: 11
  },
  item: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center'
  },
  messageBox: {
    minHeight: 80,
    paddingTop: theme.spacing(2),
    textAlign: 'center'
  }
}))

export default useStyles
