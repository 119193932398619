import { useState, useCallback, useMemo, useEffect } from 'react'
import queryString from 'query-string'
import 'style/route/form/main.css'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAppContext } from 'App'
import useGetData from 'core/hooks/useGetData'
import axios from 'core/api'
import Dialog from 'components/Modal/Dialog'
import Loader from 'components/Loader'
import Header from 'components/Header'
import RoundedButton from 'components/Button'
import Filters, { FilterItem } from 'modules/AppDrawer/styles/Filter'
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Tab,
  Tabs
} from '@material-ui/core'
import FormTable from './components/FormTable'
import EmptyState from './components/EmptyState'
import { QS_TYPES } from 'core/constants/query-strings'
import { NPS_CSAT_TAB } from 'core/enums/nps-csat-tab'
import { isEqual } from 'lodash'
import form from 'modules/Campaign/components/Email/form'

const Form = ({ t, match, location }) => {
  const history = useHistory()
  const { notify, showCsat } = useAppContext()

  const [anchorEl, setAnchorEl] = useState()

  const currentSearch = useMemo(() => {
    return queryString.parse(location.search)
  }, [location.search])

  const currentTab = useMemo(() => {
    if (currentSearch[QS_TYPES] === NPS_CSAT_TAB.nps.typeParam) {
      return NPS_CSAT_TAB.nps.tab
    }

    if (currentSearch[QS_TYPES] === NPS_CSAT_TAB.csat.typeParam) {
      return NPS_CSAT_TAB.csat.tab
    }
  }, [currentSearch])

  const handleSetTabState = useCallback(
    (e, value) => {
      const newTypes =
        value === 1 ? NPS_CSAT_TAB.csat.typeParam : NPS_CSAT_TAB.nps.typeParam
      const newSearch = { ...currentSearch, [QS_TYPES]: newTypes }

      history.push({
        pathname: location.pathname,
        search: queryString.stringify(newSearch)
      })
    },
    [currentSearch, history, location.pathname]
  )

  useEffect(() => {
    const newSearch = { ...currentSearch }

    if (!newSearch[QS_TYPES]) {
      newSearch[QS_TYPES] = NPS_CSAT_TAB.nps.typeParam
    }

    if (!isEqual(currentSearch, newSearch)) {
      setTimeout(() => {
        history.push({
          pathname: location.pathname,
          search: queryString.stringify(newSearch)
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  const [modalActive, setModalActive] = useState(0)
  const closeModal = useCallback(() => {
    setModalActive(0)
  }, [])

  const [loading, setLoading] = useState(false)
  const handleSetLoading = useCallback((x) => {
    setLoading(x)
  }, [])

  const goToNpsForm = useCallback(() => {
    history.push({
      pathname: location.pathname + '/new',
      search: queryString.stringify({
        ...currentSearch,
        [QS_TYPES]: NPS_CSAT_TAB.nps.typeParam
      })
    })
  }, [currentSearch, history, location.pathname])

  const goToCsatForm = useCallback(() => {
    history.push({
      pathname: location.pathname + '/new',
      search: queryString.stringify({
        ...currentSearch,
        [QS_TYPES]: NPS_CSAT_TAB.csat.typeParam
      })
    })
  }, [currentSearch, history, location.pathname])

  const updateAnchor = useCallback((e) => {
    setAnchorEl(e.target)
  }, [])

  const [forms, setForms] = useState([])
  useGetData({
    endpoint: 'form/getall',
    setData: setForms,
    setLoading: handleSetLoading
  })

  const hasForms = useMemo(() => !!forms && !!forms.length, [forms])

  const removeForm = useCallback(() => {
    handleSetLoading(true)

    axios
      .post('form/changestatus?IdForm=' + modalActive)
      .then((res) => {
        if (!res.data.Success) throw new Error('Error on delete')

        let newForms = forms
        const removed = newForms.findIndex(
          (form) => form.IdYourviews === modalActive
        )

        newForms.splice(removed, 1)

        setForms(newForms)
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        handleSetLoading(false)
        closeModal()
      })
  }, [closeModal, forms, handleSetLoading, modalActive, notify])

  const npsForms = useMemo(
    () => (forms ? forms.filter((f) => f.Type === 0) : []),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forms, forms.length]
  )
  // formDependencies(forms)
  const csatForms = useMemo(
    () => (forms ? forms.filter((f) => f.Type === 1) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forms, forms.length]
  )

  const formsToShow = useMemo(() => {
    if (currentTab === NPS_CSAT_TAB.nps.tab) {
      return npsForms
    } else if (currentTab === NPS_CSAT_TAB.csat.tab) {
      return csatForms
    }
    return []
  }, [currentTab, npsForms, csatForms])

  return (
    <div className="form">
      {loading && (
        <div className="form-loader-wrapper">
          <Loader />
        </div>
      )}

      <Header
        type="actions"
        title={t('formEditor.list.title')}
        items={
          hasForms && (
            <Filters justify="flex-end">
              <FilterItem>
                <RoundedButton
                  whiteTextLabel
                  type="add"
                  size="large"
                  variant="contained"
                  onClick={updateAnchor}
                />
              </FilterItem>
            </Filters>
          )
        }
      />

      {hasForms && (
        <div className="form-tabs-outer-wrapper">
          <div className="form-tabs-inner-wrapper">
            <Tabs
              value={currentTab}
              variant="fullWidth"
              textColor="primary"
              aria-label="Form Tabs"
              onChange={handleSetTabState}
              indicatorColor="primary"
            >
              <Tab label={t('form.nps')} />
              {showCsat && <Tab label={t('form.csat')} />}
            </Tabs>
          </div>
        </div>
      )}

      {hasForms ? (
        <FormTable
          forms={formsToShow}
          onFormDelete={setModalActive}
          match={match}
        />
      ) : !loading ? (
        <EmptyState onAddNewForm={updateAnchor} />
      ) : (
        <div></div>
      )}

      <Dialog
        show={modalActive > 0}
        onClose={closeModal}
        onCancel={closeModal}
        onAccept={removeForm}
      >
        <h3 className="modal-title">{t('formEditor.list.removeTitle')}</h3>
        <p className="modal-text">{t('formEditor.list.removeDesc')}</p>
      </Dialog>

      {anchorEl && (
        <Popover
          open={anchorEl}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          onClose={() => setAnchorEl()}
        >
          <List>
            <ListItem button onClick={goToNpsForm}>
              <ListItemText>{t('form.npsForm')}</ListItemText>
            </ListItem>
            {showCsat && (
              <ListItem button onClick={goToCsatForm}>
                <ListItemText>{t('form.csatForm')}</ListItemText>
              </ListItem>
            )}
          </List>
        </Popover>
      )}
    </div>
  )
}

export default withTranslation()(Form)
