import IconsDictionary from '../dictionaries/log-icons-dictionary'

import _ from 'lodash'

const createObject = (icon, col, row, message) => {
  if (!row || !message) return

  return { icon, col, row, message }
}

const processingLogMapper = (data, type, field) =>
  createObject(
    IconsDictionary.get(type.toString()),
    data[field].map((x) => x.Column),
    data[field].map((x) => x.Row),
    _.uniq(data[field].map((x) => x.Message)).toString()
  )

export default processingLogMapper
