import HomeIcon from '@material-ui/icons/Home'
import StarIcon from '@material-ui/icons/Star'
import PowerIcon from '@material-ui/icons/Power'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import CloudUploadRoundedIcon from '@material-ui/icons/CloudUploadRounded'
import CloudIcon from '@material-ui/icons/CloudRounded'
import DescriptionIcon from '@material-ui/icons/Description'
import AccountBoxIcon from '@material-ui/icons/AssignmentIndRounded'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import Storefront from '@material-ui/icons/Storefront'
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox'
import CodeIcon from '@material-ui/icons/Code'
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions'
import EmailRoundedIcon from '@material-ui/icons/EmailRounded'

import { Permissions } from '../enums/Permissions'

const Items = [
  {
    id: '',
    permission: Permissions.lowerRights,
    path: '',
    title: 'menu.home',
    icon: <HomeIcon />
  },
  {
    id: 'answer',
    permission: Permissions.lowerRights,
    path: 'answer?ordenation=DateDesc',
    title: 'menu.answer',
    icon: <QuestionAnswerIcon />
  },
  {
    id: 'campaign',
    permission: Permissions.lowerRights,
    path: '',
    title: 'menu.campaign-related',
    childrens: [
      {
        id: 'campaign',
        permission: Permissions.managerRights,
        path: 'campaign',
        title: 'menu.campaign',
        icon: <MarkunreadMailboxIcon />
      },
      {
        id: 'person',
        permission: Permissions.mediumRights,
        path: 'person',
        title: 'menu.person',
        icon: <GroupRoundedIcon />
      },
      {
        id: 'import/upload',
        permission: Permissions.managerRights,
        path: 'import/upload',
        title: 'menu.import',
        icon: <CloudUploadRoundedIcon />,
        childrens: null
      },
      {
        id: 'form',
        permission: Permissions.managerRights,
        path: 'form',
        title: 'menu.form',
        icon: <DescriptionIcon />,
        childrens: null
      },
      {
        id: 'template',
        permission: Permissions.managerRights,
        path: 'template',
        title: 'menu.template',
        icon: <EmailRoundedIcon />,
        childrens: null
      }
    ]
  },
  {
    id: 'reports',
    permission: Permissions.lowerRights,
    path: '',
    title: 'menu.report',
    childrens: [
      {
        id: 'reports/nps',
        permission: Permissions.lowerRights,
        path: 'reports/nps/list',
        title: 'menu.nps-reports',
        icon: <EmojiEmotionsIcon />,
        badge: 'true'
      },

      {
        id: 'reports/stars',
        permission: Permissions.lowerRights,
        path: 'reports/stars',
        title: 'menu.stars',
        icon: <StarIcon />,
        badge: 'true'
      },
      {
        id: 'reports/word-cloud',
        permission: Permissions.lowerRights,
        path: 'reports/word-cloud',
        title: 'menu.word-cloud',
        icon: <CloudIcon />
      }
    ]
  },
  {
    id: 'accounts',
    permission: Permissions.higherRights,
    path: '',
    title: 'menu.accounts',
    childrens: [
      {
        id: 'integrations',
        permission: Permissions.managerRights,
        path: 'integrations',
        title: 'menu.integrations',
        icon: <PowerIcon />,
        rotate: true,
        childrens: null
      },
      {
        id: 'administrator',
        permission: Permissions.higherRights,
        path: 'administrator',
        title: 'menu.admin',
        icon: <AccountBoxIcon />,
        childrens: null
      },
      {
        id: 'api',
        permission: Permissions.managerRights,
        path: 'api',
        title: 'menu.api',
        icon: <CodeIcon />,
        childrens: null
      }
    ]
  },
  {
    id: 'yourviews',
    permission: Permissions.masterRights,
    path: '',
    title: 'menu.yourviews',
    childrens: [
      {
        id: 'storemanagement',
        permission: Permissions.masterRights,
        path: 'storemanagement',
        title: 'menu.storemanagement',
        icon: <Storefront />,
        rotate: true,
        childrens: null
      }
    ]
  },
]

export default Items
