import { useEffect, useState } from 'react'
import { useCampaign } from 'modules/Campaign/components/Details/context'
import { withTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { Container, SaveButton, Title, Button, Image } from './style'

import rocket from 'assets/img/rocket.svg'
import whats from 'assets/img/whats.svg'
import face from 'assets/img/face.svg'
import twitter from 'assets/img/twitter.svg'
import { Text } from 'pages/WidgetScript/style'
import { Block } from 'modules/Campaign/components/Details/style'
import { TextField } from '@material-ui/core'

import CopyToClipboard from 'components/CopyToClipboard'
import qs from 'query-string'

import { BASE_PATH } from 'router'

import createLink from 'core/functions/make-link-campaign'
import postData from 'core/functions/post-data.function'
import { useAppContext } from 'App'

function CampaignSuccess({ t }) {
  const history = useHistory()
  const { campaign, dispatch, cleanCampaignContext } = useCampaign()
  const { notify } = useAppContext()

  const [link, setLink] = useState(createLink())
  const store = JSON.parse(localStorage.getItem('__s_'))

  const { search } = useLocation()
  const [parsedQS, setParsedQS] = useState(qs.parse(search))

  useEffect(() => {
    const newLink = createLink(store.Key, campaign.Key)
    setLink(newLink)
    setParsedQS(qs.parse(search))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const dataId = campaign?.Element?.IdYourviews

    dispatch
      .filter((item) => item.IdCampaign !== dataId)
      .forEach((dispatch) => {
        postData({
          endpoint: 'dispatchsendingschedule/insertupdate',
          data: {
            ...dispatch,
            IdCampaign: dataId,
            Active: true
          },
          setData: ({ HasErrors }) => {
            if (HasErrors) {
              return notify('Ocorreu um erro, tente novamente!', 'error')
            } else {
              return notify('Campanha salva com sucesso!', 'success')
            }
          }
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign])

  const handleNext = (link) => {
    cleanCampaignContext()
    history.push(link)
  }

  return (
    <div>
      <Container container1>
        <Title>
          {t('campaign.confirmCampanha.campaign')}{' '}
          {' ' + campaign.Description ? campaign.Description : ''}
        </Title>
        <Title>{t('campaign.confirmCampanha.subTitle')}</Title>
        {campaign?.Active && (
          <div style={{ marginTop: '24px' }}>
            <Text style={{ fontSize: '20px' }}>
              {t('campaign.confirmCampanha.textLink')}
            </Text>

            <Block>
              {link !== createLink() && (
                <TextField
                  value={link}
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <CopyToClipboard
                        dontText={true}
                        position="end"
                        text={link}
                        icone={false}
                      />
                    )
                  }}
                />
              )}
              {link === createLink() && (
                <TextField
                  value={link}
                  fullWidth
                  disabled
                  size="small"
                  variant="outlined"
                />
              )}
            </Block>

            <Text
              style={{ fontSize: '20px', marginTop: '48px', fontWeight: '500' }}
            >
              {t('campaign.confirmCampanha.share')}
            </Text>
            <Block>
              <a
                href={
                  'https://api.whatsapp.com/send?text=' +
                  encodeURIComponent(link)
                }
                id="whatsapp-share-btt"
                rel="noreferrer"
                target="_blank"
              >
                <Image
                  src={whats}
                  alt="whatssApp logo"
                  style={{ marginRight: '40px' }}
                />
              </a>
              <a
                href={
                  'https://www.facebook.com/sharer/sharer.php?u=' +
                  encodeURIComponent(link)
                }
                id={'facebook-share-btt'}
                rel={'noreferrer'}
                target={'_blank'}
                className={'facebook-share-button'}
              >
                <Image
                  src={face}
                  alt="Facebook logo"
                  style={{ marginRight: '40px' }}
                />
              </a>
              <a
                className="twitter-share-button"
                target="_blanck"
                href={
                  'https://twitter.com/intent/tweet?text=' +
                  encodeURIComponent(link)
                }
              >
                <Image src={twitter} alt="twitter logo" />
              </a>
            </Block>
          </div>
        )}
        <SaveButton
          style={{ margin: 'initial', display: 'block', marginTop: '24px' }}
          data-testid="saveButtonLarge"
          largeSize
          onClick={() => {
            handleNext(BASE_PATH + 'campaign')
          }}
        >
          {t('campaign.confirmCampanha.buttonBack')}
        </SaveButton>
        <Button
          style={{ marginTop: '24px' }}
          onClick={() =>
            handleNext(BASE_PATH + 'campaign/details?Id=' + parsedQS?.Id)
          }
        >
          {t('campaign.confirmCampanha.buttonContinue')}
        </Button>
      </Container>
      <Container container2 style={{}}>
        <Image src={rocket} alt="rocket logo" />
      </Container>
    </div>
  )
}

export default withTranslation()(CampaignSuccess)
