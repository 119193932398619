import {
  MdMailOutline,
  MdPublic,
  MdSms,
  MdLink,
  MdGolfCourse,
  MdExpandMore,
  MdBlock,
  MdCheck,
  MdChat,
  MdLabel,
  MdLibraryBooks
} from 'react-icons/md'

const IconMap = new Map([
  [0, <MdMailOutline />],
  [1, <MdSms />],
  [2, <MdPublic />],
  [3, <MdLink />],
  [4, <MdExpandMore />],
  [5, <MdBlock />],
  [6, <MdCheck />],
  [7, <MdChat />],
  [8, <MdLabel />],
  [9, <MdLibraryBooks />],
  ['default', <MdGolfCourse />]
])

export default IconMap
