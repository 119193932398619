import { useEffect, useState } from 'react'

import { withTranslation } from 'react-i18next'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import ColorPic from 'components/ColorPic'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { ErrorBoundary } from 'react-error-boundary'

function ErrorFallback({ resetErrorBoundary }) {
  useEffect(() => {
    resetErrorBoundary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <div></div>
}

const TextEditor = ({ snippet, setSnippet, i18n }) => {
  const getDefaultEditorState = () => {
    return () => {
      const blocksFromHtml = htmlToDraft(snippet)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      return EditorState.createWithContent(contentState)
    }
  }

  const [editorKey, setEditorKey] = useState(0)
  const [editorState, setEditorState] = useState(getDefaultEditorState())

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    setSnippet(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const locale =
    !i18n.language || i18n.language.length > 2 ? 'pt' : i18n.language

  useEffect(() => {
    setEditorState(getDefaultEditorState())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorKey])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        setEditorKey(editorKey + 1)
      }}
    >
      <Editor
        key={editorKey}
        editorState={editorState}
        toolbarClassName="text-editor-toolbar"
        wrapperClassName="text-editor-wrapper"
        editorClassName="text-editor-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          colorPicker: { component: ColorPic },
          options: [
            'colorPicker',
            'link',
            'emoji',
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'history'
          ]
        }}
        localization={{
          locale: locale
        }}
      />
    </ErrorBoundary>
  )
}

export default withTranslation()(TextEditor)
