import './index.css'
import { withTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import queryString from 'query-string'
import { QS_DISPATCH_IDS } from 'core/constants/query-strings'
import AnswerTable from './components/AnswerTable'
import DispatchTable from './components/DispatchTable'
import Funnel from './components/Funnel'
import EmptyState from '../EmptyState'

const ConversionEmail = ({
  emailData,
  loadingEmailData,
  currentTargetList,
  currentDispatchTitle,
  t
}) => {
  const { search } = useLocation()

  const currentSearch = useMemo(() => {
    return queryString.parse(search)
  }, [search])

  const currentIdDispatch = useMemo(() => {
    return currentSearch[QS_DISPATCH_IDS]
  }, [currentSearch])

  return !loadingEmailData &&
    !emailData.Sent &&
    !emailData.Answered &&
    !emailData.Clicked &&
    !emailData.Opened ? (
    <EmptyState description={t('conversion-report.empty.descriptionEmail')} />
  ) : (
    <div className="conversion-email-wrapper">
      <Funnel
        loading={loadingEmailData}
        data={emailData}
        currentDispatchTitle={currentDispatchTitle}
      />

      {!currentIdDispatch ? (
        <DispatchTable />
      ) : (
        <AnswerTable title={currentTargetList?.Name} />
      )}
    </div>
  )
}

export default withTranslation()(ConversionEmail)
