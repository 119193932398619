export const ColorsMap = {
  0: [
    '#B72224',
    '#D52029',
    '#E95223',
    '#EA6F22',
    '#F6A726',
    '#FDC729',
    '#EBDB0A',
    '#E7E145',
    '#C2D234',
    '#AEC93C',
    '#66B44E'
  ],
  1: [
    '#181818',
    '#282828',
    '#383838',
    '#484848',
    '#585858',
    '#686868',
    '#787878',
    '#888888',
    '#989898',
    '#A8A8A8',
    '#B8B8B8'
  ]
}
