import { getIn } from 'formik'

import Dropdown from './Dropdown'

const DropdownField = ({
  field,
  form: { touched, errors, setFieldValue },
  onSelected,
  ...props
}) => {
  return (
    <div className="form-field dropdown-field">
      {props.data.length > 0 && (
        <Dropdown
          testId={field.name}
          value={field.value}
          items={props.data}
          name={props.label}
          fullWidth={props.fullWidth}
          onSelected={(item) => {
            setFieldValue(field.name, item.id)
            if (onSelected) {
              onSelected(item)
            }
          }}
          className={
            'dropdown-input' +
            (getIn(errors, field.name) && getIn(touched, field.name)
              ? ' dropdown-input-error'
              : '')
          }
        />
      )}

      {!!(
        field.name &&
        getIn(touched, field.name) &&
        getIn(errors, field.name)
      ) && <small className="input-warning">{getIn(errors, field.name)}</small>}
    </div>
  )
}

export default DropdownField
