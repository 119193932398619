import { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'

import { useAppContext } from 'App'

import Button from '@material-ui/core/Button'

import Yup from 'core/validations/index'
import * as Pattern from 'core/validations/pattern'
import axios from 'core/api'

import PasswordField from 'components/Form/Text/PasswordField'
import Modal from 'components/Modal/Modal'
import Loader from 'components/Loader'

const EditPassword = ({ close, active, user, t }) => {
  const { notify } = useAppContext()

  const [loading, setLoading] = useState(false)

  const fieldsValidation = Yup.object().shape({
    password: Pattern.password,
    passwordAgain: Pattern.passwordAgain
  })

  const initialValues = {
    password: '',
    passwordAgain: ''
  }

  const updatePassword = (values, setSubmitting) => {
    setLoading(true)

    axios
      .post('apiadmin/changepassword', {
        IdYourviews: user.IdYourviews,
        NewPassword: values.password,
        PasswordConfirmation: values.passwordAgain
      })
      .then((res) => {
        if (!res.data) throw new Error('Error on change')

        if (res.data.Result !== 4)
          throw new Error(t('admin.password.error.' + res.data.Result))

        notify(t('success.password'), 'success')
        close()
      })
      .catch((error) => {
        close()
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  return (
    <Modal show={active} onClose={() => close()}>
      <h3 className="modal-title">{t('admin.password.title')}</h3>

      <div className="admin-change-password white-form">
        <p className="admin-change-password-label">
          {t('admin.password.changing')}{' '}
          <strong>
            {user.Name} {' - '}
            {user.Email}
          </strong>
        </p>

        {loading ? (
          <Loader />
        ) : (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) =>
              updatePassword(values, setSubmitting)
            }
            validationSchema={fieldsValidation}
          >
            {(propsForm) => {
              return (
                <Form>

                  <Field
                    name="password"
                    label={t('admin.password.passwordLbl')}
                    component={PasswordField}
                    placeholder={t('admin.password.passwordDesc')}
                  />

                  <Field
                    name="passwordAgain"
                    label={t('admin.password.confirmLbl')}
                    component={PasswordField}
                    placeholder={t('admin.password.confirmDesc')}
                  />

                  <Button
                    variant="contained"
                    className="btn-block"
                    type="submit"
                    disabled={propsForm.isSubmitting}
                  >
                    {t('admin.password.btn')}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        )}
      </div>
    </Modal>
  )
}

export default withTranslation()(EditPassword)
