import { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Formik, Form, Field } from 'formik'

import { useAppContext } from 'App'

import { BASE_PATH } from 'router'

import Button from '@material-ui/core/Button'

import Yup from 'core/validations/index'
import axios from 'core/api'

import TextField from 'components/Form/Text/TextField'
import PasswordField from 'components/Form/Text/PasswordField'

import { CircularProgress } from '@material-ui/core'

import ResetPassword from '../ResetPassword'

import AuthProvider, { useAuthContext } from 'modules/Authentication'
import Checkbox from 'components/Form/Box/Checkbox'

const Login = ({ t }) => {
  const history = useHistory()
  const { notify } = useAppContext()

  const [resetPassword, setResetPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const { handleSetUser, handleSetToken, handleSetStore } = useAuthContext()

  const fields = {
    email: '',
    password: '',
    keepLogged: true
  }

  const fieldsValidation = Yup.object().shape({
    email: Yup.string().max(80).required(),
    password: Yup.string().max(50).required(),
    keepLogged: Yup.bool()
  })

  const checkError = (code) => {
    if (code > -1 && code < 10 && code !== 5)
      throw new Error(t('error.' + code.toString()))
  }

  const submitSign = (values, setSubmitting) => {
    setLoading(true)

    axios
      .post('Horus/Login', {
        UserName: values.email,
        Password: values.password,
        RememberMe: values.keepLogged
      })
      .then((res) => {
        if (res.data.Success) {
          window.location.href = res.data.RedirectUrl
        } else {
          notify(res?.data?.ErrorMessages[0], 'error')
        }
      })
      .catch((error) => {
        notify(error.message, 'error')
      })
      .finally(() => {
        setLoading(false)
        setSubmitting(false)
      })
  }

  return (
    <AuthProvider withSkin>
      <div className="sign-form-area">
        {resetPassword ? (
          <>
            <h2 className="sign-form-title">{t('recovery.title')}</h2>
            <br />
            <section className="sign-form">
              <ResetPassword setResetPassword={setResetPassword} />
            </section>
          </>
        ) : (
          <>
            <h2 className="sign-form-title">{t('login.title')}</h2>

            <section className="sign-form">
              <Formik
                initialValues={fields}
                onSubmit={(values, { setSubmitting }) =>
                  submitSign(values, setSubmitting)
                }
                validationSchema={fieldsValidation}
              >
                {(propsForm) => {
                  return (
                    <Form>
                      <Field
                        name="email"
                        label={t('login.form.emailLabel')}
                        component={TextField}
                        placeholder={t('login.form.emailDesc')}
                        className="login-input-form"
                      />

                      <Field
                        name="password"
                        label={t('login.form.passwordLabel')}
                        component={PasswordField}
                        placeholder={t('login.form.passwordDesc')}
                        className="login-input-form"
                      />

                      <Field
                        name="keepLogged"
                        label={t('login.form.keepLoggedLabel')}
                        component={Checkbox}
                      />

                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className="btn-sign"
                        disabled={propsForm.isSubmitting}
                      >
                        {t('login.form.submitBtn')}
                      </Button>
                    </Form>
                  )
                }}
              </Formik>
            </section>

            {loading ?? <CircularProgress />}
          </>
        )}
      </div>
    </AuthProvider>
  )
}

export default withTranslation()(Login)
