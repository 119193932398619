import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { withTranslation } from 'react-i18next'
import {
  Li,
  Ul,
  QuadrantColor,
  QuadrantItem,
  QuadrantNum,
  QuadrantView,
  QuadrantY,
  TitleY,
  QuadrantBox,
  QuadrantMatrix,
  MetaNps,
  QuadrantTitleX,
  QuadrantDescX,
  Point
} from './style'
import { Tooltip, withStyles } from '@material-ui/core'
import ReportTooltip from '../../../ReportTooltip'
import { intlFloatPoint } from 'core/functions/intl-float-point.function'
import i18n from '../../../../../../../lang'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    padding: 0,
    margin: 0,
    borderRadius: '4px'
  }
}))(Tooltip)

const Quadrant = ({
  decimal,
  xAxis,
  yAxis,
  data,
  nps,
  quadrantTitle,
  onPointClick,
  hasChildren,
  t
}) => {
  const [state, setState] = useState({
    xAxis: {
      min: 0,
      max: 100
    },
    yAxis: {
      min: 0,
      max: 100
    }
  })

  useEffect(() => {
    setMinMaxValue()

    if (
      xAxis.min !== state.xAxis.min ||
      xAxis.max !== state.xAxis.max ||
      yAxis.min !== state.yAxis.min ||
      yAxis.max !== state.yAxis.max
    )
      setMinMaxValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMaxValue = useCallback((array, axis) => {
    return Math.max.apply(
      Math,
      array.map((o) => o[axis])
    )
  }, [])

  const getMinValue = useCallback((array, axis) => {
    return Math.min.apply(
      Math,
      array.map((o) => o[axis])
    )
  }, [])

  const setMinMaxValue = useCallback(() => {
    setState({
      xAxis: {
        min: !isNaN(xAxis.min) ? xAxis.min : getMinValue(data, 'x'),
        max: !isNaN(xAxis.max) ? xAxis.max : getMaxValue(data, 'x')
      },
      yAxis: {
        min: !isNaN(yAxis.min) ? yAxis.min : getMinValue(data, 'y'),
        max: !isNaN(yAxis.max) ? yAxis.max : getMaxValue(data, 'y')
      }
    })
  }, [
    data,
    getMaxValue,
    getMinValue,
    xAxis.max,
    xAxis.min,
    yAxis.max,
    yAxis.min
  ])

  const getScaled = useCallback(
    (axis) => {
      //Gera o tamanho da escala atual para uma com 0-(Tamanho)
      //Importante para tranformar em porcentagem
      return state[axis].max - state[axis].min
    },
    [state]
  )

  const getValueInScale = useCallback(
    (axis, value) => {
      //Gera agora o valor na escala gerada acima
      return value + (getScaled(axis) - state[axis].max)
    },
    [getScaled, state]
  )

  const toPercent = useCallback(
    (axis, value) => {
      return (100 * getValueInScale(axis, value)) / getScaled(axis)
    },
    [getScaled, getValueInScale]
  )

  const yAxisArray = useMemo(() => {
    return [getMaxValue(data, 'y'), getMaxValue(data, 'y') / 2, 1]
  }, [data, getMaxValue])

  const xAxisArray = useMemo(() => {
    if (!xAxis.descQnt || xAxis.descQnt.length < 3) return []

    const gap = Math.floor(getScaled('xAxis') / (xAxis.descQnt - 1))

    let desc = []
    for (let i = state['xAxis'].min; i <= state['xAxis'].max; i += gap) {
      desc.push(i)
    }

    return desc
  }, [getScaled, state, xAxis.descQnt])

  const targetRef = useRef()
  const [quadrantSize, setQuandrantSize] = useState(null)
  const [widthQuadrant, setWidthQuadrant] = useState({
    larger: '74%',
    smaller: '25%'
  })

  useLayoutEffect(() => {
    if (targetRef.current) {
      setQuandrantSize(targetRef.current.offsetWidth)
    }
  }, [targetRef?.current?.offsetWidth])

  useEffect(() => {
    if (quadrantSize) {
      let aux = quadrantSize / 2
      setWidthQuadrant({
        larger: (aux + aux * (nps / 100)) / (quadrantSize / 100),
        smaller: (aux - aux * (nps / 100)) / (quadrantSize / 100)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quadrantSize])

  const handlePointClick = useCallback(
    (item) => {
      if (!hasChildren) {
        onPointClick(item.label)
      }
    },
    [hasChildren, onPointClick]
  )

  return (
    <QuadrantView>
      <QuadrantY>
        <TitleY>{t('nps-reports.matrix.quadrant.yAxisTitle')}</TitleY>
        <QuadrantNum style={{ position: 'absolute', height: '100%' }}>
          <Ul>
            {yAxisArray.map((val, index) => {
              return (
                <Li
                  style={{
                    position: index !== 0 ? 'absolute' : 'relative',
                    top: (100 * index) / (yAxisArray.length - 1) + '%',
                    transform: `translateY(-50%)`
                  }}
                >
                  {intlFloatPoint(
                    index === yAxisArray.length - 1 ? val - 1 : val,
                    i18n.language
                  )}
                </Li>
              )
            })}
          </Ul>
        </QuadrantNum>
      </QuadrantY>

      <QuadrantBox>
        <QuadrantMatrix ref={targetRef}>
          <QuadrantColor>
            <QuadrantItem
              blockOne
              style={{
                width: widthQuadrant.larger + '%'
              }}
            >
              <span>{quadrantTitle && quadrantTitle.topLeft}</span>
            </QuadrantItem>

            <QuadrantItem
              blockTwo
              style={{
                width: widthQuadrant.smaller + '%',
                borderBottom: '4px dashed rgba(255, 255, 255, 0.2)'
              }}
            >
              <span>{quadrantTitle && quadrantTitle.topRight}</span>
            </QuadrantItem>

            <QuadrantItem
              blockTree
              style={{
                width: widthQuadrant.larger + '%'
              }}
            >
              <span>{quadrantTitle && quadrantTitle.bottomLeft}</span>
              <MetaNps goal={nps}>
                <ul>
                  <li style={{ height: '20px' }}>
                    <ArrowDropUpIcon />
                  </li>
                  {!xAxisArray.includes(nps) && <li>{nps}</li>}
                  <li>{t('nps-reports.matrix.goal')}</li>
                </ul>
              </MetaNps>
            </QuadrantItem>

            <QuadrantItem style={{ width: widthQuadrant.smaller + '%' }}>
              <span>{quadrantTitle && quadrantTitle.bottomRight}</span>
            </QuadrantItem>
          </QuadrantColor>

          <div className={'dot-list show-labels'}>
            {data.length > 0 &&
              data.map((item, index) => (
                <HtmlTooltip
                  title={
                    <ReportTooltip
                      name={item.label}
                      score={intlFloatPoint(
                        (item.score || 0).toFixed(decimal),
                        i18n.language
                      )}
                      quantity={item.totalCount}
                    />
                  }
                >
                  <Point
                    key={index}
                    style={{
                      bottom: toPercent('yAxis', item.y) + '%',
                      left: toPercent('xAxis', item.x) + '% ',
                      cursor: hasChildren ? 'auto' : 'pointer',
                      transform: 'translateY(50%) translateX(-50%)'
                    }}
                    onClick={() => handlePointClick(item)}
                    data-testid="matrix-point"
                  />
                </HtmlTooltip>
              ))}
          </div>
        </QuadrantMatrix>

        <QuadrantTitleX>
          <QuadrantDescX>
            {xAxisArray.map((item, index) => (
              <li
                style={{
                  position: 'absolute',
                  left: (100 * index) / (xAxisArray.length - 1) + '%',
                  transform: `translateX(-50%)`
                }}
                key={index}
              >
                {intlFloatPoint(item, i18n.language)}
              </li>
            ))}
          </QuadrantDescX>
        </QuadrantTitleX>
      </QuadrantBox>
    </QuadrantView>
  )
}

export default withTranslation()(Quadrant)
