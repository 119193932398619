import { useState, useEffect } from 'react'

import { withTranslation } from 'react-i18next'

import axios from 'core/api'

import Files from 'react-files'
import { FaUpload } from 'react-icons/fa'

import Header from 'components/Header'

import Dropdown from 'components/Form/Option/Dropdown'

import { campaign } from 'core/helpers'
import { CircularProgress } from '@material-ui/core'

function DirectLinkImport(props) {
  const [file, setFile] = useState(null)

  const [loading, setLoading] = useState(false)

  const [campaignId, setCampaignId] = useState(0)
  const [campaigns, setCampaigns] = useState([])
  const type = ['.xlsx']

  const onFilesChange = (files) => {
    setFile(files[0])
  }

  const onFilesError = (error) => {}

  const setError = (errors, checkContinue) => {
    if (checkContinue) {
    } else {
    }
  }

  useEffect(() => {
    axios
      .get('campaign/getall')
      .then((res) => setCampaigns(res.data))
      .catch((error) => {
        setError(true)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendFile = () => {
    axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    let fileData = new FormData()
    fileData.append('list', file)

    setLoading(true)

    axios({
      method: 'POST',
      url: 'directlink/generate?idCampaign=' + campaignId,
      responseType: 'blob',
      data: fileData
    })
      .then((res) => {
        let blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'resultado.xlsx'
        link.click()

        //   url = window.URL.createObjectURL(blob)
        // window.open(url)
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
        axios.defaults.headers['Content-Type'] = 'application/json'
      })
  }

  return (
    <>
      <Header title="Importar arquivo de links" />
      <h3>Primeiro escolha a Campanha</h3>

      <Dropdown
        className={props.className}
        campaignAllSelected={props.campaignAllSelected}
        onSelected={(item) => {
          setCampaignId(item.id)
        }}
        name="campaigns"
        items={campaign.toDropdown(campaigns, 'Campanha')}
      />
      <br />
      <br />

      {loading ? (
        <div
          style={{
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <h3>Agora envie seu arquivo</h3>
          <div id="storeInfo">
            <Files
              className="files-dropzone"
              onChange={onFilesChange}
              onError={onFilesError}
              accepts={type}
              multiple={false}
              maxFileSize={5000000}
              minFileSize={0}
              clickable
            >
              <div className="upload-zone">
                <label className="upload-zone-label">
                  <FaUpload /> {'importar arquivos'}
                </label>
                {file && (
                  <div className="upload-zone-file">
                    <div className="upload-zone-file-item">
                      <div className="upload-zone-file-icon"></div>
                      <label className="upload-zone-file-label">
                        {file.name}
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </Files>

            {file && campaigns.length > 0 && (
              <button className="btn upload-btn" onClick={sendFile}>
                {'Enviar'}
              </button>
            )}
          </div>
        </>
      )}

      <hr />
    </>
  )
}

export default withTranslation()(DirectLinkImport)
