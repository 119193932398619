import { useEffect } from 'react'

import { read } from 'localstorage-helpr'
import { useLocation } from 'react-router-dom'
import { USER, STORE } from '../constants/local-storage'

import PublicRoutes from '../dictionaries/public-routes'

export default function useUserPilot() {
  const { pathname } = useLocation()

  const isPublic = (path) => PublicRoutes.includes(path)

  useEffect(() => {
    if (process.env.REACT_APP_STAGE !== 'qa' && !isPublic(pathname)) {
      const user = read(USER)
      const store = read(STORE)

      window?.userpilot?.identify(user?.IdYourviews, {
        name: user?.Name,
        email: user?.Email,
        created_at: user?.CreationDate,

        company: {
          id: store?.key,
          name: store?.Name,
          created_at: store?.CreationDate
        }
      })

      if (window.userpilot) {
        window.userpilot.reload()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
}
